import { memo } from 'preact/compat';
import { IconButton, Tooltip } from '@mui/material';
import * as styles from '../../styles';
import { SendIcon } from '../../SendIcon';

type Props = { canSend: boolean; disabled: boolean; handleSubmit: () => void };

export const SendButton = memo(
    ({ canSend = false, disabled = false, handleSubmit }: Props) => {
        return (
            <Tooltip title="Send">
                <IconButton
                    sx={canSend ? styles.activeSendButton : undefined}
                    disabled={disabled}
                    onClick={handleSubmit}
                >
                    <SendIcon />
                </IconButton>
            </Tooltip>
        );
    },
);
