import { useEffect } from 'preact/hooks';
import { useSocket } from '../../../websocket';
import { INTEGRATIONS_LIST_QUERY_KEY } from '../../../queries/integrations';
import { queryClient } from '../../../queries/queryClient';

export function useIntegrationUpdateEvent() {
    const socket = useSocket();
    const connection = socket.getSocket();

    useEffect(() => {
        const handleIntegrationUpdate = () => {
            queryClient.refetchQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        };
        if (connection) {
            connection.on('integration:updated', handleIntegrationUpdate);
        }
        return () => {
            if (connection) {
                connection.off('integration:updated', handleIntegrationUpdate);
            }
        };
    }, [connection]);
}
