import cc from 'classcat';
import styles from './Counter.module.scss';

interface CounterProps {
    className?: string;
    value: string | number;
}

export const Counter = (props: CounterProps) => (
    <span className={cc([styles['root'], props.className])}>{props.value}</span>
);
