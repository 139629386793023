import { RenderLeafProps } from 'slate-react';

export function renderLeaf({ children, leaf, attributes }: RenderLeafProps) {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    return <span {...attributes}>{children}</span>;
}
