import { CarrierCredentials, SetupWizardStep } from '../types';
import { InboxProvider } from '../../../api/types';
import { TwilioConnect } from './TwilioConnect';
import { TelnyxConnect } from './TelnyxConnect';

export interface CarrierConnectProps extends SetupWizardStep {
    setCredentials: (credentials: CarrierCredentials) => void;
    carrier: InboxProvider;
}

export const CarrierConnect = ({
    goBackward,
    goForward,
    setCredentials,
    carrier,
}: CarrierConnectProps) => {
    switch (carrier) {
        case InboxProvider.TWILIO:
            return (
                <TwilioConnect
                    setCredentials={setCredentials}
                    carrier={carrier}
                    goForward={goForward}
                    goBackward={goBackward}
                />
            );
        case InboxProvider.TELNYX:
            return (
                <TelnyxConnect
                    setCredentials={setCredentials}
                    carrier={carrier}
                    goForward={goForward}
                    goBackward={goBackward}
                />
            );
        default:
            return false;
    }
};
