import { useCallback, useMemo } from 'preact/hooks';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    CountryCode,
    isValidPhoneNumber,
    parsePhoneNumber,
} from 'libphonenumber-js';
import { useContactsQueryData } from '../../../queries/contacts';
import { useMeQueryData } from '../../../queries/user';
import {
    Item,
    ListItem,
} from '../../ConversationAutocomplete/ConversationAutocompleteItem';
import { useNewConversationContext } from '../NewConversationProvider';
import styles from './SelectedContactsTab.module.scss';
import { ConversationAutocompleteListItem } from '../../ConversationAutocomplete/ConversationAutocompleteListItem';

const LIST: ListItem[] = [{ type: 'splitter', name: 'Contacts' }];

const getItemHeight = (index: number) => {
    if (index === 0) {
        return 40;
    }
    return 36;
};

export const SelectedContactsTab = () => {
    const { state, actions } = useNewConversationContext();
    const profile = useMeQueryData();
    const contactsCache = useContactsQueryData();

    const removeFromSelected = useCallback(
        (item: Item) => {
            const newIds = state.selectedContactIds.filter(
                (id) => id !== item.id,
            );
            actions.setSelectedContacts(newIds);
        },
        [state.selectedContactIds],
    );

    const items = useMemo(() => {
        const contacts = state.selectedContactIds
            .map((selectedContactId) => {
                if (
                    isValidPhoneNumber(
                        selectedContactId,
                        profile?.activeTeam.countryCode as CountryCode,
                    )
                ) {
                    const phoneNumber = parsePhoneNumber(
                        selectedContactId,
                        profile?.activeTeam.countryCode as CountryCode,
                    ).number;

                    return {
                        id: phoneNumber,
                        name: phoneNumber,
                        phone: phoneNumber,
                        data: {},
                        created: new Date().toISOString(),
                        updated: new Date().toISOString(),
                        public: true,
                    };
                }

                return contactsCache.getById(selectedContactId)!;
            })
            .filter((c) => !!c);

        return LIST.concat(contacts);
    }, [state.selectedContactIds]);

    return (
        <div className={styles['root']}>
            {items.length > 0 && (
                <AutoSizer className={styles['root__autosizer']}>
                    {({ height }: { height: number }) => (
                        <List
                            innerElementType="ul"
                            height={height}
                            className={styles['root__outer']}
                            itemCount={items.length}
                            itemData={{
                                countryCode: profile?.activeTeam.countryCode,
                                list: items,
                                selectedContactIds: state.selectedContactIds,
                                onClick: removeFromSelected,
                            }}
                            itemSize={getItemHeight}
                            width="100%"
                        >
                            {ConversationAutocompleteListItem}
                        </List>
                    )}
                </AutoSizer>
            )}
        </div>
    );
};
