export enum Type {
    Url = 'url',
    Pdf = 'pdf',
    Doc = 'doc',
    Csv = 'csv',
    Raw = 'raw',
}

export enum Status {
    Init = 'init',
    Parsed = 'parsed',
    ParseFail = 'parse_fail',
    SplitFail = 'split_fail',
    SplitFinished = 'split_finished',
    EmbedFailed = 'embed_failed',
    Done = 'done',
}

export type Source = {
    title: string;
    id: number;
    type: Type;
    status: Status;
};
