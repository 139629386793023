import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useMeQueryData } from '../../queries/user';
import { useMemo } from 'preact/hooks';
import dayjs from 'dayjs';
import { card, content, fancyPattern, progress, textCenter } from './styles';

export const UsageDetails = () => {
    const me = useMeQueryData();
    const plan = me?.activeTeam.tier;

    const daysLeftInMonth = useMemo(() => {
        const now = dayjs();

        const totalDaysInMonth = now.daysInMonth();

        const currentDayOfMonth = now.date();

        return totalDaysInMonth - currentDayOfMonth;
    }, []);

    const percentUsed = useMemo(() => {
        return (me?.activeTeam.smsUsed / me?.activeTeam.smsLimit) * 100;
    }, [me?.activeTeam]);

    return (
        <Card sx={card}>
            <Box sx={fancyPattern} role="presentation" />
            <CardContent sx={content}>
                <div>
                    <Typography variant="h2">Usage Details</Typography>
                    <Typography variant="body3" mt={4}>
                        Your team is using the {plan} plan
                    </Typography>
                </div>
                <Box display="flex" flexDirection="column" mr={6}>
                    <Box display="flex" gap={4}>
                        <Box sx={textCenter}>
                            <Typography variant="body1">
                                {me?.activeTeam.smsUsed}
                            </Typography>
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                            >
                                used messages
                            </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={textCenter}>
                            <Typography variant="body1">
                                {me?.activeTeam.smsLimit}
                            </Typography>
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                            >
                                In your plan
                            </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={textCenter}>
                            <Typography variant="body1">
                                {daysLeftInMonth}
                            </Typography>
                            <Typography
                                color="custom.gray.super"
                                variant="body4"
                            >
                                days left in mo.
                            </Typography>
                        </Box>
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={percentUsed}
                        sx={progress}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};
