import cc from 'classcat';
// styles
import styles from './ContactsFilterPanel.module.scss';
// components
import AddFilter from '../AddFilter';
import Condition from '../Condition';
import Input from '../../../elements/Inputs/Input';
import UserPropertiesFilterValueDropdown from '../UserPropertiesFilterValueDropdown';
// types
import {
    FilterValue,
    UserPropertyFilterCondition,
} from '../../UserInfoProperty/types';
// icons
import searchIcon from '../../../assets/icons/icons-16/search.svg';
import {
    useCohortCreateQuery,
    useCohortsQuery,
    useCohortUpdateQuery,
} from '../../../queries/cohorts';
import { usePropertiesQueryData } from '../../../queries/properties';
import Button from '../../../elements/Buttons';
import AdvancedSelect, { OptionId } from '../../../elements/AdvancedSelect';
import { getCohortsDropdownOptions, renderCohortOption } from './utils';
import { useMemo, useState } from 'preact/hooks';
import Icon from '../../../icons/Icon';
import Tooltip from '../../../elements/Tooltip';
import Counter from '../../../elements/Counter';
import Badge from '../../../elements/Badge';
import ContactsCount from '../ContactsCount';
import { useModalContext } from '../../../containers/modal/reducer';
import RenameCohortModal from '../../Modals/RenameCohortModal';
import DeleteCohortModal from '../../Modals/DeleteCohortModal';
import { CohortMeta } from '../../../api/types';
import CloseButton from '../../../elements/Buttons/CloseButton';
import { SuggestInput } from './SuggestInput';
import { alternateWithAnd } from './alternate-with-and';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useMeQueryData } from '../../../queries/user';

interface ContactsFilterPanelProps {
    searchTerm: string;
    filterValues: FilterValue[];
    dataIsFetching: boolean;
    selectedCohortId?: number;
    setSearchTerm: (newSearchTerm: string) => void;
    setFilterValues: (newFilterValues: FilterValue[]) => void;
    setSelectedCohortId: (cohortId?: number) => void;
    onImportClick: () => void;
    onResetClick: () => void;
    contactsCount: number;
}

export const ContactsFilterPanel = (props: ContactsFilterPanelProps) => {
    const [showFilters, setShowFilters] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const { spawnModal, removeModal } = useModalContext();
    const { dispatch } = useAnalytics();

    const userProperties = usePropertiesQueryData();
    const cohorts = useCohortsQuery();
    const me = useMeQueryData();
    const { mutateAsync: createCohort } = useCohortCreateQuery();
    const { mutateAsync: updateCohort } = useCohortUpdateQuery();

    const options = useMemo(
        () =>
            getCohortsDropdownOptions(
                cohorts,
                me,
                (cohort: CohortMeta) =>
                    spawnModal(
                        <RenameCohortModal
                            cohort={cohort}
                            close={removeModal}
                        />,
                    ),
                (cohort: CohortMeta) =>
                    spawnModal(
                        <DeleteCohortModal
                            cohort={cohort}
                            close={removeModal}
                        />,
                    ),
                props.onResetClick,
            ),
        [cohorts],
    );

    const getChangeHandler = (index: number) => (newValue: FilterValue) => {
        const newFilterValues = props.filterValues.slice();
        newFilterValues.splice(index, 1, newValue);
        props.setFilterValues(newFilterValues);

        if (props.selectedCohortId) {
            setDirty(true);
        }
    };

    const getClearHandler = (index: number) => () => {
        const newFilterValues = props.filterValues.slice();
        const startIdx = index === 0 ? index : index - 1;
        newFilterValues.splice(startIdx, 2);
        props.setFilterValues(newFilterValues);

        if (props.selectedCohortId) {
            setDirty(true);
        }
    };

    const handleCohortChange = (optionId: OptionId) => {
        const cohortId =
            optionId === ''
                ? undefined
                : Number.parseInt(optionId as string, 10);

        props.setSelectedCohortId(cohortId);
        setDirty(false);
    };

    const couldCreateCohort = props.searchTerm || props.filterValues.length > 0;
    const [isAiSearch, setIsAiSearch] = useState<boolean>(false);

    const onSuggestions = (suggestions: FilterValue[]) => {
        props.setFilterValues(alternateWithAnd(suggestions));
    };
    return (
        <section className={styles['root']}>
            <div className={styles['root__controls']}>
                <div className={styles['root__text-search-wrapper']}>
                    {isAiSearch ? (
                        <SuggestInput onSuggestion={onSuggestions} />
                    ) : (
                        <Input
                            placeholder="Search"
                            prefix={<img src={searchIcon} />}
                            value={props.searchTerm}
                            onChange={props.setSearchTerm}
                            fullWidth
                        />
                    )}
                </div>
                <AdvancedSelect
                    className={styles['root__cohorts-list']}
                    options={options}
                    placeholder="Select a cohort"
                    value={props.selectedCohortId?.toString()}
                    searchable
                    renderOption={renderCohortOption}
                    onChange={handleCohortChange}
                />
                <Button
                    type="default"
                    icon={<Icon name="filter" />}
                    onClick={() => {
                        dispatch({
                            type: AnalyticsActions.TRACK,
                            payload: {
                                eventName:
                                    AnalyticsEventName.CLICKED_TOGGLE_FILTER,
                            },
                        });
                        setShowFilters(!showFilters);
                    }}
                >
                    {props.filterValues.length > 0 ? (
                        <span className={styles['root__filter-btn-text']}>
                            Filter
                            <Counter
                                value={
                                    Math.floor(props.filterValues.length / 2) +
                                    1
                                }
                            />
                        </span>
                    ) : (
                        'Filter'
                    )}
                </Button>

                <div className={styles['root__buttons']}>
                    {couldCreateCohort && (
                        <Tooltip
                            width="164px"
                            text="Clerk will save the entire results as a cohort"
                        >
                            <Button
                                icon={<Icon name="add-cohort" />}
                                type="default"
                                onClick={() => {
                                    dispatch({
                                        type: AnalyticsActions.TRACK,
                                        payload: {
                                            eventName:
                                                AnalyticsEventName.CLICKED_CREATE_COHORT,
                                        },
                                    });
                                    createCohort({
                                        queryTerm: props.searchTerm,
                                        query: props.filterValues,
                                    })
                                        .then((createdCohort) =>
                                            spawnModal(
                                                <RenameCohortModal
                                                    cohort={createdCohort}
                                                    close={removeModal}
                                                />,
                                            ),
                                        )
                                        .catch(console.log);
                                }}
                            >
                                Create cohort
                            </Button>
                        </Tooltip>
                    )}

                    <Tooltip text="Import contacts">
                        <Button
                            type="default"
                            icon={
                                <Icon
                                    className={styles['root__import-icon']}
                                    name="import"
                                />
                            }
                            onClick={() => {
                                dispatch({
                                    type: AnalyticsActions.TRACK,
                                    payload: {
                                        eventName:
                                            AnalyticsEventName.CLICKED_IMPORT_CONTACTS,
                                    },
                                });
                                props.onImportClick();
                            }}
                            iconCenter
                        />
                    </Tooltip>
                </div>
            </div>

            <div
                className={cc([
                    styles['root__filters'],
                    {
                        [styles['root__filters_hidden']]: !showFilters,
                    },
                ])}
            >
                <div className={styles['root__filter-values']}>
                    {props.filterValues.map((fv, i) => {
                        if (typeof fv === 'string') {
                            return (
                                <Condition
                                    key={fv}
                                    value={fv}
                                    onChange={getChangeHandler(i)}
                                />
                            );
                        } else {
                            return (
                                <UserPropertiesFilterValueDropdown
                                    key={fv.id}
                                    userPropertyFilter={fv}
                                    onChange={getChangeHandler(i)}
                                    onClear={getClearHandler(i)}
                                />
                            );
                        }
                    })}
                    <AddFilter
                        initiallyOpen={
                            showFilters &&
                            props.filterValues.length === 0 &&
                            !props.dataIsFetching
                        }
                        userProperties={userProperties}
                        onSelect={(up) => {
                            const newFilters =
                                props.filterValues.length > 0
                                    ? props.filterValues.concat([
                                          UserPropertyFilterCondition.AND,
                                          up,
                                      ])
                                    : props.filterValues.concat(up);
                            props.setFilterValues(newFilters);
                        }}
                    />
                    {showFilters &&
                        props.filterValues.length > 0 &&
                        props.contactsCount > 0 && (
                            <ContactsCount
                                count={props.contactsCount}
                                suffix=" matches"
                            />
                        )}
                </div>

                {couldCreateCohort && (
                    <div className={styles['root__actions']}>
                        {isDirty && (
                            <button
                                className={styles['root__save-btn']}
                                onClick={() =>
                                    updateCohort({
                                        id: props.selectedCohortId,
                                        query: props.filterValues,
                                        queryTerm: props.searchTerm,
                                    })
                                        .then(() => {
                                            const buttonElem =
                                                document.getElementById(
                                                    'save-button-label',
                                                );
                                            if (buttonElem) {
                                                buttonElem.innerText =
                                                    'Query was saved';
                                            }
                                            setTimeout(
                                                () => setDirty(false),
                                                500,
                                            );
                                        })
                                        .catch(console.log)
                                }
                            >
                                <Badge color="#57CF78">
                                    <Icon name="check-filled" size="16px" />
                                    <span id="save-button-label">
                                        Save query
                                    </span>
                                </Badge>
                            </button>
                        )}

                        <CloseButton onClick={props.onResetClick} />

                        {/* <button
                            className={styles['root__clear-btn']}
                            onClick={props.onResetClick}
                        >
                            <Icon name="close" />
                        </button> */}
                    </div>
                )}
            </div>
        </section>
    );
};

export default ContactsFilterPanel;
