import { FilterValue } from '../../components/ContactProperty/types';
import {
    useContactsQuery,
    useContactsSearchQuery,
} from '../../queries/contacts';
import { useEffect, useState } from 'preact/hooks';
import { ContactsCache, ContactsCacheData } from '../../utils/ContactsCache';
import { useSocket } from '../../websocket';
import { CONTACT_UPDATED_EVENT } from '../../websocket/contact-updated-event';
import { Contact } from '../../api/types';
import { useMeQueryData } from '../../queries/user';

export function useContacts(
    searchTerm: string,
    filterValues: FilterValue[],
    selectedCohortId: number | undefined,
    isSearchEnabled: boolean,
) {
    const me = useMeQueryData();
    const [contactsCache, setContactsCache] = useState<ContactsCacheData>(
        new ContactsCache().serialize(),
    );
    const [searchData, setSearchData] = useState<Contact[]>([]);
    const socket = useSocket();
    const connection = socket.getSocket();
    const {
        data: fetchedContacts = new ContactsCache(),
        isFetching: contactsAreFetching,
    } = useContactsQuery();

    const {
        data: fetchedSearchData = [],
        refetch: searchRefetch,
        isFetching: searchIsFetching,
        isRefetching: searchIsRefetching,
    } = useContactsSearchQuery(
        searchTerm,
        filterValues,
        selectedCohortId,
        isSearchEnabled,
    );

    useEffect(() => {
        setSearchData(fetchedSearchData);
    }, [fetchedSearchData]);

    useEffect(() => {
        setContactsCache(fetchedContacts);
    }, [fetchedContacts]);

    // Other hooks
    useEffect(() => {
        searchRefetch();
    }, [searchTerm, filterValues]);

    useEffect(() => {
        if (!connection || !me) {
            return;
        }

        connection.on(CONTACT_UPDATED_EVENT, (contact) => {
            setContactsCache((data) => {
                const contactsCache = new ContactsCache(data);
                if (contact.createdByUserId === me.id) {
                    contactsCache.update(contact);
                    return contactsCache.serialize();
                }

                if (contact.public) {
                    if (!contactsCache.has(contact)) {
                        contactsCache.add(contact);
                    } else {
                        contactsCache.update(contact);
                    }
                } else {
                    contactsCache.removeById(contact.id);
                }

                return contactsCache.serialize();
            });

            setSearchData((searchData) => {
                if (contact.createdByUserId === me.id) {
                    return searchData.map((c) =>
                        c.id === contact.id ? contact : c,
                    );
                }

                if (contact.public) {
                    const hasContact =
                        searchData.findIndex((c) => c.id === contact.id) !== -1;

                    if (!hasContact) {
                        return [...searchData, contact];
                    }

                    return searchData.map((c) =>
                        c.id === contact.id ? contact : c,
                    );
                }

                return searchData.filter((c) => c.id !== contact.id);
            });
        });

        return () => {
            connection.off(CONTACT_UPDATED_EVENT);
        };
    }, [connection, me]);

    return {
        contactsCache,
        contacts: isSearchEnabled ? searchData : contactsCache?.list || [],
        isFetching:
            searchIsRefetching || searchIsFetching || contactsAreFetching,
    };
}
