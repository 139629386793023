import Button from '@mui/material/Button';
import {
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';
import { Source } from '../source.types';
import { SourceIcon } from './SourceIcon';
import { useEffect, useState } from 'preact/hooks';
import { useDeleteSourceMutation } from '../source.query';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { DialogAttentionIcon } from '../../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../../elements/Dialog/DialogTitleTwoLine';

export const SourceItem = ({ source }: { source: Source }) => {
    const deletion = useDeleteSourceMutation();
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    useEffect(() => {
        if (deletion.isSuccess) {
            close();
        }
    }, [deletion.isSuccess]);

    return (
        <>
            <Chip
                variant="outlined"
                icon={<SourceIcon type={source.type} />}
                label={source.title}
                onDelete={() => setOpen(true)}
                sx={{
                    maxWidth: 230,
                }}
            />
            <Dialog
                open={open}
                maxWidth="xs"
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm source deletion"
                    subtitle="You’re trying to delete a file that affects the assistant’s knowledge. Are you sure you want to delete a source?"
                />
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Chip
                        variant="outlined"
                        icon={<SourceIcon type={source.type} />}
                        label={source.title}
                        sx={{
                            maxWidth: 320,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ minWidth: 96 }}
                        disabled={deletion.isPending}
                        variant="outlined"
                        onClick={close}
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <Button
                        color="error"
                        startIcon={
                            deletion.isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        disabled={deletion.isPending}
                        onClick={() => deletion.mutate(source.id)}
                        variant="outlined"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
