import cc from 'classcat';
import { useEffect, useRef, useState } from 'preact/hooks';
import styles from './AddFilter.module.scss';
import Input from '../../../elements/Inputs/Input';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { UserPropertyModel } from '../../UserInfoProperty/types';
import searchIcon from '../../../assets/icons/icons-16/search.svg';
import Button from '../../../elements/Buttons';
import Icon from '../../../icons/Icon';
import { getPropertyIcon } from '../../../utils/contact-properties';

const filter = (
    predefinedUserProperties: UserPropertyModel[],
    filterTerm: string,
) =>
    filterTerm
        ? predefinedUserProperties.filter(({ name }) =>
              name
                  .toLocaleLowerCase()
                  .startsWith(filterTerm.toLocaleLowerCase()),
          )
        : predefinedUserProperties;

export type AddFilterProps = {
    userProperties: Array<UserPropertyModel>;
    className?: string;
    initiallyOpen?: boolean;
    onSelect?: (userProperty: UserPropertyModel) => void;
};

export const AddFilter = ({
    userProperties,
    className,
    initiallyOpen = false,
    onSelect,
}: AddFilterProps) => {
    const [open, setOpen] = useState(initiallyOpen);
    const [filterTerm, setFilterTerm] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => setOpen(initiallyOpen), [initiallyOpen]);

    useOnClickOutside(dropdownRef, () => {
        setOpen(false);
        setFilterTerm('');
    });

    const filteredProps = filter(userProperties, filterTerm);

    return (
        <div
            className={cc([styles['add-filter'], className])}
            ref={dropdownRef}
        >
            {/* Button */}
            <Button
                size="small"
                type="text"
                className={styles['add-filter__add-btn']}
                icon={<Icon size="20px" name="plus" />}
                onClick={() => setOpen(!open)}
            >
                Add Filter
            </Button>

            {/* Dropdown container */}
            {open && (
                <div className={styles['filter-dropdown']}>
                    <div className={styles['filter-dropdown__search']}>
                        <Input
                            className={styles['contacts-search__input']}
                            placeholder="Search property or value"
                            prefix={<img src={searchIcon} />}
                            onChange={setFilterTerm}
                        />
                    </div>

                    <p className={styles['filter-dropdown__label']}>
                        Properties:
                    </p>
                    <ul className={styles['filter-dropdown__list']}>
                        {filteredProps.map((up) => (
                            <li
                                key={up.id}
                                className={styles['filter-dropdown__item']}
                            >
                                <button
                                    className={
                                        styles['filter-dropdown__item-button']
                                    }
                                    onClick={() => {
                                        setOpen(false);
                                        onSelect?.(up);
                                    }}
                                >
                                    <Icon
                                        size="20px"
                                        name={getPropertyIcon(up)}
                                    />

                                    <span>{up.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
