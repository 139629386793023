import Callout from '../../../components/Callout';
import { route } from 'preact-router';
import { useConnectIntegration } from '../../../queries/integrations';
import { useEffect } from 'preact/hooks';
import { availableIntegrations } from './integrations-list';

interface Props {
    source: string;
    code: string;
}

export function SetupIntegration({ source, code }: Props) {
    const integration = availableIntegrations.find((i) => i.source === source);
    const integrationName = integration ? integration.name : source;
    const {
        mutateAsync: connectIntegration,
        error: connectingError,
        status: connectingStatus,
    } = useConnectIntegration();

    useEffect(() => {
        const connect = async function () {
            await connectIntegration({ source, code });
            route(`/settings/integrations`);
        };
        connect();
    }, []);

    return (
        <div>
            {connectingStatus === 'pending' && (
                <Callout style="success">
                    Setting up your {integrationName} integration ...
                </Callout>
            )}

            {connectingStatus === 'success' && (
                <Callout style="success">
                    Your {integrationName} integration has been added!
                </Callout>
            )}

            {connectingStatus === 'error' && (
                <Callout style="warning">
                    Unable to connect to {integrationName}
                    <br />
                    {connectingError?.data?.message ||
                        connectingError?.data ||
                        'Something went wrong'}
                </Callout>
            )}
        </div>
    );
}
