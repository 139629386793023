import { Link } from 'preact-router';
import Icon from '../../../icons/Icon';
import styles from './SideBarInvite.module.scss';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';

export const SideBarInvite = () => {
    const { dispatch } = useAnalytics();
    return (
        <Link
            path="/settings/members"
            href="/settings/members"
            className={styles['root']}
            onClick={() => {
                dispatch({
                    type: AnalyticsActions.TRACK,
                    payload: {
                        eventName: AnalyticsEventName.CLICKED_INVITE_TEAM,
                    },
                });
            }}
        >
            <Icon name="table-role" />
            <span>Invite team</span>
        </Link>
    );
};
