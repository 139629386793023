import logo from './assets/images/logo.png';

export const createNotification = (
    title: string,
    text: string,
    tag?: string,
) => {
    if (window.Notification && Notification.permission === 'granted') {
        return new Notification(title, {
            body: text,
            icon: logo,
            requireInteraction: true,
            tag,
        });
    }
    return null;
};

const checkNotificationPromise = () => {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
};

export const askNotificationPermission = () => {
    // To create a custom UI to ask for permisions, could be used something like:
    // function handlePermission(permission) {
    //     if (permission === 'denied' || permission === 'default') {
    //         draw UI;
    //     } else {
    //         hide UI;
    //     }
    // }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.warn('This browser does not support notifications.');
    } else {
        if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                console.log(permission);
            });
        } else {
            Notification.requestPermission(function (permission) {
                console.log(permission);
            });
        }
    }
};
