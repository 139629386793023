import { route } from 'preact-router';
import { useRef, useState } from 'preact/hooks';
import styles from './ConversationToolbar.module.scss';
import { Icon } from '../../icons/Icon';
import Menu from '../../elements/Menu';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
    useDeleteConversation,
    useMarkConversationAsUnRead,
    useArchiveConversation,
} from '../../queries/conversations';
import { Contact, Conversation, ConversationStatus } from '../../api/types';
import AddToCohortAction from './AddToCohortAction';
import AssignToAction from './AssignToAction';
import SummarizeAction from './SummarizeAction';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import { DialogAttentionIcon } from '../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../elements/Dialog/DialogTitleTwoLine';
import { DeleteIcon } from '../../icons/common/CDeleteIcon';
import deleteConvoIcon from '../../assets/icons/navigation/delete-convo-icon.svg';
import archiveIcon from '../../assets/icons/navigation/archive-icon.svg';
import markAsReadIcon from '../../assets/icons/navigation/mark-as-read-icon.svg';
import mute from '../../assets/icons/navigation/mute.svg';

interface ConversationToolbarProps {
    conversation: Conversation;
    contacts: Contact[];
    onScheduledMessagesClick?: () => void;
    onAssignMemberClick?: () => void;
    onRemindMessageClick?: () => void;
    // Menu
    onMuteClick?: () => void;
    onUnmuteClick?: () => void;
    onMarkUnreadClick?: () => void;
    onArchiveClick?: () => void;
    onDeleteClick?: () => void;
}

export const ConversationToolbar = (props: ConversationToolbarProps) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const menuRef = useRef(null);

    const { mutate: markAsUnRead } = useMarkConversationAsUnRead();
    const { mutateAsync: deleteConvo, isPending } = useDeleteConversation();
    const { mutateAsync: archiveConvo } = useArchiveConversation();

    useOnClickOutside(menuRef, () => setMenuOpen(false));

    return (
        <div className={styles['root']}>
            <AssignToAction conversation={props.conversation} />

            <SummarizeAction conversation={props.conversation} />

            <AddToCohortAction
                contactId={props.contacts[0].id}
                disabled={props.contacts.length > 1}
            />

            <button
                className={styles['root__btn']}
                onClick={() => setMenuOpen(!isMenuOpen)}
            >
                <Icon name="more" />
            </button>

            {isMenuOpen && (
                <Menu className={styles['root__menu']} ref={menuRef}>
                    <MenuItem
                        onClick={() => {
                            setMenuOpen(false);
                            props.onMuteClick?.();
                        }}
                        disabled
                    >
                        <ListItemIcon>
                            <img src={mute} alt="mute" />
                        </ListItemIcon>
                        <ListItemText>Mute</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setMenuOpen(false);
                            markAsUnRead(props.conversation.id);
                        }}
                    >
                        <ListItemIcon>
                            <img src={markAsReadIcon} alt="unread" />
                        </ListItemIcon>
                        <ListItemText>Mark unread</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setMenuOpen(false);
                            archiveConvo({
                                id: props.conversation.id,
                                status:
                                    props.conversation.status ===
                                    ConversationStatus.Archived
                                        ? ConversationStatus.Active
                                        : ConversationStatus.Archived,
                            }).then(() =>
                                route(`/inbox/${props.conversation.inboxId}`),
                            );
                        }}
                    >
                        <ListItemIcon>
                            <img src={archiveIcon} alt="archive" />
                        </ListItemIcon>
                        <ListItemText>
                            {props.conversation.status ===
                            ConversationStatus.Archived
                                ? 'Un-Archive'
                                : 'Archive'}
                        </ListItemText>
                    </MenuItem>
                    <div className={styles['root__delimiter']} />
                    <MenuItem
                        disabled={isPending}
                        onClick={() => setIsDeleteModalOpen(true)}
                        sx={{ color: 'error.main' }}
                    >
                        {isPending ? (
                            <CircularProgress
                                color="error"
                                size={16}
                                sx={{ mr: 3 }}
                            />
                        ) : (
                            <ListItemIcon>
                                <img src={deleteConvoIcon} alt="delete" />
                            </ListItemIcon>
                        )}
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    {/* <MenuItem
                        onClick={() => {
                            setMenuOpen(false);
                            deleteConvo(props.conversation).then(() =>
                                route(`/inbox/${props.conversation.inboxId}`),
                            );
                        }}
                    >
                        <div className={styles['root__menu-item']}>
                            <Icon
                                name="delete"
                                className={styles['root__delete-icon']}
                            />
                            <span className={styles['root__delete-text']}>
                                Delete
                            </span>
                        </div>
                    </MenuItem> */}
                </Menu>
            )}
            <Dialog
                open={isDeleteModalOpen}
                maxWidth="xs"
                onClose={() => setIsDeleteModalOpen(false)}
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm conversation deletion"
                    subtitle="Are you sure you want to delete the conversation?"
                />
                <DialogActions>
                    <Button
                        disabled={isPending}
                        sx={{ minWidth: 100 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        startIcon={
                            isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        onClick={() => {
                            deleteConvo({
                                id: props.conversation.id,
                                inboxId: props.conversation.inboxId,
                                status: props.conversation.status,
                            }).then(() => {
                                setIsDeleteModalOpen(false);
                                route(`/inbox/${props.conversation.inboxId}`);
                            });
                        }}
                        variant="outlined"
                        color="error"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
