import { useState } from 'react';
import {
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { userName } from '../../../../helpers/formatting';
import { Profile, RoleTypes } from '../../../../api/types';
import { TableSelect } from '../../../../components/TableSelect/TableSelect';
import { rolesOptions } from '../utils';
import {
    useAddUserToInbox,
    useInboxAssignmentsForUser,
    useInboxes,
    useRemoveUserFromInbox,
} from '../../../../queries/inboxes';
import { useInactivateUser } from '../../../../queries/user';
import { useUpdateRole } from '../../../../queries/roles';
import { getFullName } from '../../../../utils/user';
import { TrashCanIcon } from '../../../../icons/shared/TrashCanIcon';
import { AttentionIcon } from './AttentionIcon';
import { GenericDialog } from '../../../../components/Modals/GenericDialog/GenericDialog';

export function UserRow({ user, me }: { user: Profile; me?: Profile }) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { data: inboxes = [] } = useInboxes();
    const { data: associations = [] } = useInboxAssignmentsForUser(user.id);
    const { mutate: removeUser } = useInactivateUser();
    const { mutate: updateRole } = useUpdateRole();
    const { mutate: removeFromInbox } = useRemoveUserFromInbox();
    const { mutate: addToInbox } = useAddUserToInbox();

    const role = user.roles.find((r) => r.teamId === me?.activeTeam.id);

    return (
        <>
            <TableRow key={user.id} sx={{ height: 40 }}>
                <TableCell>
                    <Stack spacing={4} direction="row">
                        <UserAvatar size={40} imageUrl={user.avatar?.url} />
                        <div>
                            <Typography variant="body3" color="primary.dark">
                                {userName(user)}
                            </Typography>
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                                component="div"
                            >
                                {user.email}
                            </Typography>
                        </div>
                    </Stack>
                </TableCell>
                <TableCell>
                    {role?.type !== RoleTypes.Admin && (
                        <TableSelect
                            width={200}
                            multiple
                            hasCheckbox
                            searchable
                            placeholder="Not assigned"
                            value={associations.map(({ inboxId }) => inboxId)}
                            onChange={(inboxId, check) => {
                                if (check) {
                                    addToInbox({
                                        inboxId,
                                        userId: user.id,
                                    });
                                } else {
                                    removeFromInbox({
                                        inboxId,
                                        userId: user.id,
                                    });
                                }
                            }}
                            options={inboxes.map((inbox) => ({
                                id: inbox.id,
                                label: inbox.name || inbox.phone,
                            }))}
                        />
                    )}
                </TableCell>
                <TableCell>
                    <TableSelect
                        width={180}
                        placeholder="Not assigned"
                        value={role?.type}
                        onChange={(value) => {
                            updateRole({
                                userId: user.id,
                                role: value as RoleTypes,
                            });
                        }}
                        options={rolesOptions}
                        footer={
                            <a
                                href="https://support.clerk.chat/team-management"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                More about roles ↗
                            </a>
                        }
                    />
                </TableCell>
                <TableCell>
                    <IconButton
                        sx={{
                            padding: 0,
                            color: 'custom.gray.super',
                            '&:hover': { color: 'error.main' },
                        }}
                        onClick={() => setShowConfirmation(true)}
                    >
                        <TrashCanIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                </TableCell>
            </TableRow>

            <GenericDialog
                confirmLabel="Yes, delete"
                confirmButtonProps={{
                    startIcon: <TrashCanIcon />,
                    color: 'error',
                    variant: 'outlined',
                }}
                onConfirm={() => {
                    setShowConfirmation(false);
                    removeUser(user.id);
                }}
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                IconComponent={AttentionIcon}
                title="Delete a member"
                description={
                    <>
                        Are you sure want to delete{' '}
                        <Typography variant="h4" component="span">
                            {getFullName(user)}
                        </Typography>
                        ? This action cannot be undone.
                    </>
                }
            />
        </>
    );
}
