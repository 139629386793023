import AuthLayout from '../../layouts/AuthLayout';
import LoginForm from '../../components/Forms/LoginForm';
import { Redirect } from '../../utils/redirect';
import { Profile } from '../../api/types';

interface LoginPageProps {
    me: Profile;
    matches: {
        next: string;
    };
}

const LoginPage = ({ me, matches }: LoginPageProps) => {
    const nextPage = matches?.next ? matches.next : '/';
    return me?.id && me?.acceptedTermsOfService ? (
        <Redirect to={nextPage} />
    ) : (
        <AuthLayout>
            <LoginForm nextPage={nextPage} />
        </AuthLayout>
    );
};

export default LoginPage;
