import parse, { CountryCode } from 'libphonenumber-js';

/**
 * Function formats phone number in national (domestic) format if it corresponds to the provided team country code.
 * Otherwise it returns phone number in international format.
 *
 * @param phoneNumber phone number
 * @param teamCountry ISO country code. Optinal parameter.
 * @returns formatted phone number
 */
export const formatPhoneNumber = (
    phoneNumber: string | null = '',
    teamCountry?: string,
): string => {
    if (!phoneNumber) {
        return '';
    }

    const parsed = parse(phoneNumber);
    if (!parsed?.isValid()) {
        return phoneNumber;
    }

    if (!teamCountry) {
        parsed.formatInternational();
    }

    return parsed.country === teamCountry
        ? parsed.formatNational()
        : parsed.formatInternational();
};

export const parsePhoneNumber = (phone: string, country: string) => {
    const parsed = parse(phone, country as CountryCode);
    if (parsed?.isValid()) {
        return parsed?.number;
    }
};
