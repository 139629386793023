import { queryClient } from '../queries/queryClient';
import { COHORT_KEY } from '../queries/cohorts';

type DataPayload = {
    cohortId: string;
};

export const COHORT_RECALCULATED = 'COHORT_RECALCULATED';

export const handleCohortRecalculatedEvent = ({ cohortId }: DataPayload) => {
    queryClient.refetchQueries({
        queryKey: [COHORT_KEY, Number.parseInt(cohortId)],
    });
};
