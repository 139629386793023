import { ComponentChildren } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './CollapsiblePropertiesSection.module.scss';
import CaretToggle from '../../elements/CaretToggle/CaretToggle';

interface Props {
    title: string;
    initiallyExpanded?: boolean;
    children: ComponentChildren;
}

export const CollapsiblePropertiesSection = (props: Props) => {
    const [isExpanded, setExpanded] = useState(
        props.initiallyExpanded || false,
    );

    const clickHandler = useCallback(
        () => setExpanded(!isExpanded),
        [isExpanded],
    );

    return (
        <div
            className={cc([
                styles['root'],
                { [styles['root_open']]: isExpanded },
            ])}
        >
            <div
                className={cc([
                    styles['root__head'],
                    { [styles['root__head_open']]: isExpanded },
                ])}
            >
                <button onClick={clickHandler}>
                    <CaretToggle open={isExpanded} />
                </button>

                <h6 className={styles['root__title']}>{props.title}</h6>
            </div>

            {isExpanded && (
                <div className={styles['root__content']}>{props.children}</div>
            )}
        </div>
    );
};
