import { isEmpty, range } from 'lodash';
import { useCampaignPage } from '../../queries/campaigns';
import { UUID } from '../../types/uuid';
import { InboxListItem } from './Item/InboxListItem';
import { ItemSkeleton } from './Item/ItemSkeleton';
import dayjs from 'dayjs';
import Match from 'preact-router/match';
import { AdapterLink } from './AdapterLink';
import { CampaignType } from '../../api/campaign.types';
import { ListErrorIndicator } from './ListErrorIndicator';
import { staticBadgeProps } from './badge.styled';
import { useTrack } from '../../contexts/analytics';
import { EmptyCampaignList } from './EmptyCampaignList';
import { useEffect, useMemo } from 'preact/hooks';
import { Virtuoso } from 'react-virtuoso';

type Props = {
    inboxId: UUID;
    startChat?: () => void;
};

export const InboxCampaignList = ({ inboxId, startChat }: Props) => {
    const track = useTrack();
    const {
        data,
        isError,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useCampaignPage(inboxId);

    const campaigns = useMemo(
        () => data?.pages.flatMap(({ data }) => data) ?? [],
        [data?.pages],
    );

    useEffect(() => {
        track('inbox_campaign_page_fetched', {
            page: (data?.pages.length ?? 0) + 1,
        });
    }, [data?.pages.length ?? 0]);

    if (isLoading) {
        return (
            <>
                {range(7).map((key) => (
                    <ItemSkeleton key={key} />
                ))}
            </>
        );
    }

    if (!data && !isError) {
        return <ListErrorIndicator startChat={startChat} />;
    }

    if (!data || isEmpty(data?.pages)) {
        return <EmptyCampaignList startChat={startChat} />;
    }

    return (
        <Virtuoso
            data={campaigns}
            endReached={() => {
                if (!isFetchingNextPage && hasNextPage) {
                    fetchNextPage();
                }
            }}
            style={{
                height: 'calc(100vh - 145px)',
            }}
            increaseViewportBy={450}
            overscan={450}
            components={{
                Footer: isFetchingNextPage
                    ? () => (
                          <>
                              {range(3).map((key) => (
                                  <ItemSkeleton key={key} />
                              ))}
                          </>
                      )
                    : undefined,
            }}
            itemContent={(_, campaign) => (
                <Match
                    key={campaign.id}
                    path={`/inbox/${campaign.inboxId}/campaigns/${campaign.id}/:rest*`}
                >
                    {({ matches }: { matches: boolean }) => (
                        <InboxListItem
                            BadgeProps={{
                                ...staticBadgeProps,
                                invisible:
                                    [
                                        CampaignType.IMMEDIATE,
                                        CampaignType.INSTANT,
                                    ].includes(campaign.type) ||
                                    !campaign.sendAt ||
                                    dayjs(campaign.sendAt).isBefore(new Date()),
                            }}
                            sx={{
                                height: 72,
                            }}
                            LinkComponent={AdapterLink}
                            href={`/inbox/${campaign.inboxId}/campaigns/${campaign.id}`}
                            title={campaign.name}
                            description={campaign.body}
                            selected={matches}
                            date={dayjs(campaign.created).format('HH:mm')}
                        />
                    )}
                </Match>
            )}
        />
    );
};
