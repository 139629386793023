import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import markAsReadIcon from '../../assets/icons/navigation/mark-as-read-icon.svg';
import {
    ClerkPermissions,
    Conversation,
    ConversationStatus,
} from '../../api/types';
import { route } from 'preact-router';
import archiveIcon from '../../assets/icons/navigation/archive-icon.svg';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import deleteConvoIcon from '../../assets/icons/navigation/delete-convo-icon.svg';
import { useUnreadMessagesForConversation } from '../../queries/messages';
import {
    useDeleteConversation,
    useMarkConversationAsRead,
    useMarkConversationAsUnRead,
    useArchiveConversation,
} from '../../queries/conversations';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { useRef, useState } from 'preact/hooks';
import styles from './ConversationRow.module.scss';
import { memo } from 'preact/compat';
import { DialogAttentionIcon } from '../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../elements/Dialog/DialogTitleTwoLine';
import { DeleteIcon } from '../../icons/common/CDeleteIcon';

export const ContextMenu = memo((props: { conversation: Conversation }) => {
    const anchor = useRef();
    const [open, setOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { mutate: markAsRead } = useMarkConversationAsRead();
    const { mutate: markAsUnRead } = useMarkConversationAsUnRead();
    const { mutateAsync: archiveConvo } = useArchiveConversation();
    const { mutateAsync: deleteConvo, isPending } = useDeleteConversation();

    const { data: unreadMessages = [] } = useUnreadMessagesForConversation(
        props.conversation.id,
    );
    const unread = unreadMessages?.length > 0;

    return (
        <>
            <Box
                sx={{ opacity: open ? 1 : undefined }}
                className={styles.menuButton}
            >
                <IconButton
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        setOpen(true);
                    }}
                    ref={anchor}
                    sx={{ padding: 0, '&:hover': { color: 'blue.main' } }}
                    aria-label="Context menu"
                    size="small"
                    color="info"
                >
                    <BiDotsHorizontalRounded />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchor.current}
                open={open}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    onClick={() => {
                        if (unread) {
                            markAsRead(props.conversation.id);
                        } else {
                            markAsUnRead(props.conversation.id);
                        }
                    }}
                >
                    <ListItemIcon>
                        <img src={markAsReadIcon} alt="read" />
                    </ListItemIcon>
                    <ListItemText>
                        {unread ? 'Mark as Read' : 'Mark as Unread'}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        archiveConvo({
                            id: props.conversation.id,
                            status:
                                props.conversation.status ===
                                ConversationStatus.Archived
                                    ? ConversationStatus.Active
                                    : ConversationStatus.Archived,
                        }).then(() =>
                            route(`/inbox/${props.conversation.inboxId}`),
                        )
                    }
                >
                    <ListItemIcon>
                        <img src={archiveIcon} alt="archive" />
                    </ListItemIcon>
                    <ListItemText>
                        {props.conversation.status ===
                        ConversationStatus.Archived
                            ? 'Un-Archive'
                            : 'Archive'}
                    </ListItemText>
                </MenuItem>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.DeleteConversation]}
                >
                    <Divider />
                    <MenuItem
                        disabled={isPending}
                        onClick={() => setIsDeleteModalOpen(true)}
                        sx={{ color: 'error.main' }}
                    >
                        {isPending ? (
                            <CircularProgress
                                color="error"
                                size={16}
                                sx={{ mr: 3 }}
                            />
                        ) : (
                            <ListItemIcon>
                                <img src={deleteConvoIcon} alt="delete" />
                            </ListItemIcon>
                        )}
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </WithPermission>
            </Menu>
            <Dialog
                open={isDeleteModalOpen}
                maxWidth="xs"
                onClose={() => setIsDeleteModalOpen(false)}
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm conversation deletion"
                    subtitle="Are you sure you want to delete the conversation?"
                />
                <DialogActions>
                    <Button
                        disabled={isPending}
                        sx={{ minWidth: 100 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        startIcon={
                            isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        onClick={() => {
                            deleteConvo({
                                id: props.conversation.id,
                                inboxId: props.conversation.inboxId,
                                status: props.conversation.status,
                            }).then(() => {
                                setIsDeleteModalOpen(false);
                                route(`/inbox/${props.conversation.inboxId}`);
                            });
                        }}
                        variant="outlined"
                        color="error"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
