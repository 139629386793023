import cc from 'classcat';
import dayjs from 'dayjs';
import { UsersThree } from 'phosphor-react';
import { useEffect, useRef, useState } from 'preact/hooks';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import IconButton from '../../elements/Buttons/IconButton';
import { useCampaignRemoveQuery } from '../../queries/campaigns';
import styles from './CampaignRow.module.scss';
import { Campaign } from '../../api/campaign.types';

interface CampaignRowProps {
    campaign: Campaign;
}

export const CampaignRow = ({ campaign }: CampaignRowProps) => {
    const [menuShown, setMenuShown] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const { mutate: remove } = useCampaignRemoveQuery(campaign.id);

    useEffect(() => {
        function closeOnClickOutside(this: Document, e: MouseEvent) {
            if (
                menuRef.current &&
                !menuRef.current.contains(e.target as Node) &&
                menuShown
            ) {
                e.preventDefault();
                setMenuShown(false);
            }
        }

        window.document.addEventListener('click', closeOnClickOutside, true);

        return () => {
            window.document.removeEventListener(
                'click',
                closeOnClickOutside,
                true,
            );
        };
    }, [menuRef, menuShown]);

    return (
        <div className={styles['campaign']}>
            <div className={styles['campaign__avatar']}>
                <UsersThree size={40} color="#8689A4" />
            </div>
            <div className={styles['campaign__info']}>
                <div className={styles['campaign__info-top-line']}>
                    <span className={styles.recipient}>{campaign.name}</span>
                </div>
                <div className={styles['campaign__info-bottom-line']}>
                    <span
                        className={cc([
                            'text-ellipsis',
                            styles['campaign__message'],
                        ])}
                    >
                        {`Planned on ${dayjs(campaign.sendAt).format(
                            'MMM DD',
                        )} at ${dayjs(campaign.sendAt).format('hh:mm A')}`}
                    </span>
                    <IconButton
                        icon={<BiDotsHorizontalRounded />}
                        type="default"
                        needBackground={false}
                        className={styles.menuButton}
                        onClick={() => setMenuShown(!menuShown)}
                    />
                </div>

                {/* Menu popup */}
                <div
                    className={styles.menu}
                    style={{ display: menuShown ? 'block' : 'none' }}
                    ref={menuRef}
                >
                    <div
                        className={styles.deleteConversation}
                        onClick={() => remove()}
                    >
                        Delete
                    </div>
                </div>
            </div>
        </div>
    );
};
