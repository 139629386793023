import { route } from 'preact-router';
import { useCallback, useEffect, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './Contacts.module.scss';
import { NoContacts } from './NoContacts';
import { isSearchActive } from './utils';
import { useContactExport } from '../../queries/contacts';
import ContactsTable from '../../components/ContactsTable';
import { useMeQueryData } from '../../queries/user';
import ContactsHeader from '../../components/ContactsHeader';
import ContactsFilterPanel from '../../components/ContactsFilter/ContactsFilterPanel';
import { FilterValue } from '../../components/UserInfoProperty/types';
import { usePropertiesQuery } from '../../queries/properties';
import ContactPanel from '../../containers/ContactPanel';
import { useModalContext } from '../../containers/modal/reducer';
import ContactsMoveDialog from '../../components/ContactsMoveDialog';
import ContactsDeleteDialog from '../../components/ContactsDeleteDialog';
import { useCohortQuery } from '../../queries/cohorts';
import { UUID } from '../../types/uuid';
import { useContacts } from './useContacts';

interface ContactsProps {
    id: string;
}

export const Contacts = ({ id: activeContactId }: ContactsProps) => {
    const [filterValues, setFilterValues] = useState<FilterValue[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedCohortId, setSelectedCohortId] = useState<number>();
    const [selected, setSelected] = useState<Set<UUID>>(new Set());

    const { dispatch } = useModalContext();

    // Queries
    usePropertiesQuery();
    const profile = useMeQueryData();
    const { cohort, isFetching: cohortIsFetching } = useCohortQuery(
        selectedCohortId || 0,
        typeof selectedCohortId !== 'undefined',
    );

    const { mutateAsync: exportContactData } = useContactExport();

    const isSearchEnabled = isSearchActive(
        searchTerm,
        filterValues,
        cohortIsFetching,
        cohort?.id,
    );

    const {
        contacts,
        contactsCache,
        isFetching: isContactsFetching,
    } = useContacts(
        searchTerm,
        filterValues,
        selectedCohortId,
        isSearchEnabled,
    );

    useEffect(() => {
        setSearchTerm(cohort?.queryTerm || '');
        setFilterValues(cohort?.query || []);
    }, [cohort]);

    const handleContactSelect = useCallback(
        (id: string) => {
            const newSelected = new Set(selected);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }

            setSelected(newSelected);
        },
        [selected],
    );

    const resetModal = useCallback(() => {
        dispatch({ type: 'PUSH', payload: {} });
    }, []);

    const dataIsFetching =
        isContactsFetching || cohortIsFetching || isContactsFetching;

    const exportContacts = () => {
        exportContactData({ contactIds: Array.from(selected) }).then((blob) => {
            const file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        });
    };

    return (
        <section className={styles['root']}>
            <ContactsHeader
                className={cc([
                    styles['root__header'],
                    {
                        [styles['root__header_edit']]: !!activeContactId,
                    },
                ])}
                contactsNumber={contactsCache.list?.length}
            />

            <div
                className={cc([
                    styles['root__search'],
                    {
                        [styles['root__search_edit']]: !!activeContactId,
                    },
                ])}
            >
                <ContactsFilterPanel
                    searchTerm={searchTerm}
                    filterValues={filterValues}
                    selectedCohortId={selectedCohortId}
                    dataIsFetching={dataIsFetching}
                    setSearchTerm={setSearchTerm}
                    setFilterValues={setFilterValues}
                    setSelectedCohortId={(cohortId) => {
                        if (cohortId !== selectedCohortId) {
                            setSelectedCohortId(cohortId);
                            setSearchTerm('');
                            setFilterValues([]);
                            setSelected(new Set());
                        }
                    }}
                    onImportClick={() => route('/settings/integrations')}
                    onResetClick={() => {
                        setSelectedCohortId(undefined);
                        setFilterValues([]);
                    }}
                    contactsCount={contacts.length}
                />
            </div>

            <main
                className={cc([
                    styles['root__content'],
                    {
                        [styles['root__content_edit']]: !!activeContactId,
                    },
                ])}
            >
                {contactsCache?.list && contactsCache?.list.length === 0 && (
                    <NoContacts />
                )}

                {contactsCache?.list && contactsCache?.list.length > 0 && (
                    <ContactsTable
                        selected={selected}
                        activeContactId={activeContactId}
                        selectedCohortId={selectedCohortId}
                        contacts={contacts}
                        countryCode={profile?.activeTeam.countryCode}
                        full={!activeContactId}
                        setSelected={setSelected}
                        onContactSelect={handleContactSelect}
                        onContactClick={(id) => route(`/contacts/${id}`)}
                        onExportContacts={exportContacts}
                        onSelectionMoveClick={() =>
                            dispatch({
                                type: 'PUSH',
                                payload: {
                                    content: () => (
                                        <ContactsMoveDialog
                                            selected={selected}
                                            onClose={resetModal}
                                            onMove={() => {
                                                resetModal();
                                                setSelected(new Set());
                                            }}
                                        />
                                    ),
                                },
                            })
                        }
                        onSelectionDeleteClick={() =>
                            dispatch({
                                type: 'PUSH',
                                payload: {
                                    content: () => (
                                        <ContactsDeleteDialog
                                            selected={selected}
                                            onClose={resetModal}
                                            onDelete={() => {
                                                resetModal();
                                                setSelected(new Set());
                                                route('/contacts');
                                            }}
                                        />
                                    ),
                                },
                            })
                        }
                        onSelectionCancelClick={() => setSelected(new Set())}
                    />
                )}
            </main>

            {!!activeContactId && (
                <ContactPanel
                    className={styles['root__sidebar']}
                    contactId={activeContactId}
                    onBack={() => {
                        route('/contacts');
                    }}
                />
            )}
        </section>
    );
};
