import { route } from 'preact-router';
import { FiX } from 'react-icons/fi';
import currentStyles from './ContactsList.module.scss';
import { Contact } from '../../api/types';
import { getContactName } from '../../utils/contacts';
import { CampaignContact } from '../../api/campaign.types';

interface ContactDisplayNameProps {
    contact: Contact | CampaignContact;
    countryCode?: string;
    removeDraft?: () => void;
}

export const ContactDisplayName = ({
    contact,
    countryCode,
    removeDraft,
}: ContactDisplayNameProps) => {
    if (contact.id === 'draft') {
        return (
            <>
                <div className="tile-content tile-centered">
                    <span className={currentStyles.draft}>Draft: </span>
                    Unnamed
                </div>
                <div className="tile-action">
                    <a
                        className="btn btn-link flex-centered"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (removeDraft) {
                                removeDraft();
                                route('/contacts');
                            }
                        }}
                    >
                        <FiX />
                    </a>
                </div>
            </>
        );
    }

    return (
        <div className="tile-content tile-centered">
            {getContactName(contact, countryCode)}
        </div>
    );
};
