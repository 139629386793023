import { useState } from 'preact/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styles from './BillingInfo.module.scss';
import CreditCardIcon from '../../../assets/icons/payment-flow/credit-card.svg?react';
import NoEntryIcon from '../../../assets/icons/payment-flow/no-entry.svg?react';
import CloseIcon from '../../../assets/icons/different/close_gray.svg?react';
import { PlanItem } from '../plans';
import PaymentStatus from './PaymentStatus';
import { route } from 'preact-router';
import { BillingForm } from './BillingForm';
import StepShell from '../StepShell';
import * as Sentry from '@sentry/react';
import { useTeammates } from '../../../queries/user';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { useInboxesQueryData } from '../../../queries/inboxes';

const stripePromise = loadStripe(
    String(import.meta.env.VITE_STRIPE_API_KEY),
).catch((e) => Sentry.captureException(e));

export interface BillingInfoProps {
    plan: PlanItem;
    goBackward: () => void;
}

export const BillingInfo = ({ plan, goBackward }: BillingInfoProps) => {
    const [isPaymentError, setPaymentError] = useState(false);
    const { dispatch } = useAnalytics();
    const { data: teammates = [] } = useTeammates() || [];
    const inboxes = useInboxesQueryData() || [];

    const urlParams = new URLSearchParams(window.location.search);
    const clientSecret =
        urlParams.get('s') || urlParams.get('setup_intent_client_secret');

    const liscenseCount = Math.max(teammates.length, inboxes.length);
    const totalPrice = plan.price * liscenseCount;

    return (
        <StepShell
            title="Billing details"
            subtitle="Enter your payment information"
            icon={<CreditCardIcon />}
            goBackward={goBackward}
        >
            {isPaymentError && (
                <div className={styles['root__error']}>
                    <NoEntryIcon className={styles['root__error-icon']} />
                    <div className={styles['root__error-content']}>
                        <h6 className={styles['root__error-title']}>
                            We can’t charge your credit card
                        </h6>
                        <p className={styles['root__error-text']}>
                            Please, check the billing info once again and try to
                            confirm the payment.
                        </p>
                    </div>
                    <button
                        className={styles['root__close-error']}
                        onClick={() => setPaymentError(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>
            )}

            <div className={styles['root__billing-info']}>
                <div className={styles['root__plan-details']}>
                    <div className={styles['root__plan-left-side']}>
                        <span className={styles['root__plan-icon']}>
                            {plan.titleIcon}
                        </span>
                        <span className={styles['root__plan-name']}>
                            {plan.title}
                        </span>
                    </div>

                    <div className={styles['root__plan-pricing']}>
                        <span className={styles['root__price']}>
                            ${totalPrice} / month
                        </span>
                        <span className={styles['root__billing-period']}>
                            {liscenseCount} Liscenses
                        </span>
                    </div>
                </div>

                {clientSecret && (
                    <Elements
                        stripe={stripePromise}
                        options={{ clientSecret, loader: 'always' }}
                    >
                        <PaymentStatus
                            onPaymentSuccess={() => {
                                dispatch({
                                    type: AnalyticsActions.TRACK,
                                    payload: {
                                        eventName:
                                            AnalyticsEventName.CLICKED_PURCHASE_PLAN,
                                    },
                                });
                                route('/payment/finish');
                            }}
                            onPaymentFail={() => setPaymentError(true)}
                        />

                        <BillingForm />
                    </Elements>
                )}
            </div>
        </StepShell>
    );
};
