import { useState } from 'preact/hooks';
import styles from './CollapsibleTextSection.module.scss';

type CollapsibleTextSectionProps = {
    visibleComponent: JSX.Element;
    hiddenComponent: JSX.Element;
    expandedByDefault?: boolean;
};

export const CollapsibleTextSection = (props: CollapsibleTextSectionProps) => {
    const [expanded, setExpanded] = useState(props.expandedByDefault ?? false);
    return (
        <div className={styles['root']}>
            {props.visibleComponent}
            {expanded ? props.hiddenComponent : null}
            <button onClick={() => setExpanded(!expanded)}>
                {expanded ? 'Show Less \u2191' : 'Show More \u2193'}
            </button>
        </div>
    );
};
