import { propertyTypeIconNameMap } from '../components/ContactProperty/typeIconMap';
import {
    PropertySources,
    UserPropertyModel,
} from '../components/ContactProperty/types';
import { PropertyIcon } from '../icons/iconsMap';

export const getPropertyIcon = (property: UserPropertyModel): PropertyIcon => {
    if (property.icon?.match(/\//g)) {
        return propertyTypeIconNameMap[property.type];
    }

    if (property.icon) {
        return property.icon;
    }

    switch (property.source) {
        case PropertySources.Clerk:
            return 'clerk-logo';
        case PropertySources.Google:
            return 'google-contacts-logo';
        case PropertySources.Microsoft:
            return 'microsoft-outlook-logo';
        case PropertySources.Salesforce:
            return 'salesforce-logo';
        case PropertySources.Shopify:
            return 'shopify-logo';
    }

    return '';
};
