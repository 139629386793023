import styles from './ContactsCount.module.scss';

type ContactsCountProps = {
    count: number;
    suffix?: string;
};

export const ContactsCount = (props: ContactsCountProps) => (
    <span className={styles['count']}>
        {props.count}
        {props.suffix && props.suffix}
    </span>
);
