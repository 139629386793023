import { CampaignInner } from './CampaignInner';
import Loading from '../../components/Loading/Loading';
import { useCampaignQuery } from '../../queries/campaigns';
import { usePropertiesQuery } from '../../queries/properties';
import { CampaignType } from '../../api/campaign.types';
import { useEffect } from 'preact/hooks';
import { route as navigate } from 'preact-router';
import { useTrack } from '../../contexts/analytics';

interface CampaignProps {
    campaignId: number;
    conversationId: string;
}

export const Campaign = ({ campaignId, conversationId }: CampaignProps) => {
    const track = useTrack();
    const { campaign, isPending } = useCampaignQuery(campaignId);
    usePropertiesQuery();

    useEffect(() => {
        if (
            campaign?.type &&
            [CampaignType.INSTANT, CampaignType.PLANNED].includes(campaign.type)
        ) {
            track('campaign_redirected_to_v3');
            navigate(`/inbox/${campaign.inboxId}/campaigns/${campaign.id}`);
        }
    }, [campaign?.type]);

    if (isPending) {
        return <Loading />;
    }

    return (
        <CampaignInner campaign={campaign} conversationId={conversationId} />
    );
};
