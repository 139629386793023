export const pluralize = (
    count: number,
    singular: string,
    plural: string,
    none?: string,
) => {
    if (count === 0) {
        return none || `0 ${plural}`;
    }
    if (count === 1) {
        return `1 ${singular}`;
    }
    return `${count} ${plural}`;
};
