import IconBase, { IconBaseProps } from '../IconBase';

export const CanceledFilledIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7.58059 6.51992C7.2877 6.22703 6.81283 6.22703 6.51993 6.51992C6.22704 6.81282 6.22704 7.28769 6.51993 7.58058L10.9394 12L6.51993 16.4194C6.22704 16.7123 6.22704 17.1872 6.51993 17.4801C6.81283 17.773 7.2877 17.773 7.58059 17.4801L12 13.0607L16.4194 17.4801C16.7123 17.773 17.1872 17.773 17.4801 17.4801C17.773 17.1872 17.773 16.7123 17.4801 16.4194L13.0607 12L17.4801 7.58058C17.773 7.28769 17.773 6.81282 17.4801 6.51992C17.1872 6.22703 16.7123 6.22703 16.4194 6.51992L12 10.9393L7.58059 6.51992Z"
            fill="currentColor"
        />
    </IconBase>
);
