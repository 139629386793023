/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CountryCode } from 'libphonenumber-js/types';
import { FilterValue } from '../components/UserInfoProperty/types';
import {
    WorkflowSchedule,
    WorkflowSpec,
    WorkflowTrigger,
} from '../components/workflows/types';
import { Column } from '../main/contacts-import/CsvImportWizard/utils';
import { UUID } from '../types/uuid';
import { Campaign } from './campaign.types';

export enum AttributeType {
    date = 'date',
    email = 'email',
    multi_select = 'multi_select',
    phone = 'phone',
    select = 'select',
    text = 'text',
}

type PropertyId = UUID;

type PropertyValue = any;

export type Contact = {
    id: UUID;
    name?: string;
    data: Record<PropertyId, PropertyValue>;
    phone?: string;
    email?: string;
    optOut?: boolean;
    avatarURL?: string;
    updated: string;
    created: string;
    createdByUserId?: string;
    public: boolean;
};

export interface Credentials {
    email: string;
}

export type LoginCredentials = Credentials;

export enum InboxProvider {
    TWILIO = 'twilio',
    BANDWIDTH = 'bandwidth',
    MSTEAMS = 'msteams',
    OTHER = 'other',
    NON_VOIP = 'non_voip',
    TELNYX = 'telnyx',
}

export enum InboxStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    DISABLED = 'disabled',
    PORT_SUBMITTED = 'port_submitted',
    PORT_FAILED = 'port_failed',
}

export interface Inbox {
    id: UUID;
    icon?: string;
    phone: string;
    name?: string;
    status: InboxStatus;
    public: boolean;
    verified: boolean;
    description: string;
    provider: InboxProvider;
    locationType?: 'USER' | 'IVR';
    requestedActivationDate?: Date;
    pin?: string;
    assistantEnabled: boolean;
}

export type InboxAssignment = {
    userId: string;
    inboxId: string;
};

export enum CohortStatus {
    OK = 'ok',
    SCHEDULED = 'scheduled',
    RUNNING = 'running',
    ERROR = 'error',
}

export interface CohortMeta {
    id: number;
    userId: string;
    name: string;
    icon?: string;
    isPublic: boolean;
    updated: Date;
    contactsCount: number;
}

export interface Cohort extends CohortMeta {
    query?: FilterValue[];
    queryTerm?: string;
    contactIds: string[];
    included: string[];
    excluded: string[];
    contacts?: Contact[];
    status: CohortStatus;
}

export interface Invite {
    id: string;
    phone?: string;
    email?: string;
    role: RoleTypes;
    inboxIds: string[];
}

export enum BillingProvider {
    Stripe = 'stripe',
    Shopify = 'shopify',
}

export interface Team {
    id: string;
    name?: string;
    dlcCampaignId?: string;
    slackTeamId: string;
    countryCode: CountryCode;
    teamAvatarURL?: string;
    integrationsHiddenByDefault: boolean;
    contactsPublicByDefault: boolean;
    whitelistedIntegrations: IntegrationSources[];
    billingProvider: BillingProvider;
    smsUsed: number;
    smsLimit: number;
    tier: 'free' | 'starter' | 'unlimited' | 'mfa_nonvoip';
    invites: Invite[];
    users?: Profile[];
    assistantResponseDelaySeconds: number;
    assistantInstruction: string | null;
}

interface PersonalInfo {
    avatar?: { url?: string };
    avatarId?: string;
    firstname: string;
    lastname: string;
    phone: string;
    company: string;
    usecase: string;
}

export enum IntegrationSources {
    Azure = 'azure',
    CSV = 'csv',
    CampaignRegistry = 'campaignregistry',
    Dynamics = 'dynamics',
    GlobalRelay = 'globalrelay',
    Google = 'google',
    Hubspot = 'hubspot',
    Linkedin = 'linkedin',
    Microsoft = 'microsoft',
    Pipedrive = 'pipedrive',
    Purview = 'purview',
    Salesforce = 'salesforce',
    Shopify = 'shopify',
    Slack = 'slack',
    Smarsh = 'smarsh',
    Stripe = 'stripe',
}

export enum IntegrationTypes {
    Contact = 'contact',
    Connection = 'connection',
    Logs = 'logs',
    Application = 'application',
    Data = 'data',
}

export interface IntegrationSettings {
    propertyMap: Column[];
}

export enum IntegrationStatus {
    Fail = 'fail',
    Success = 'success',
    Disconnected = 'disconnected',
    Syncing = 'syncing',
}
export interface Integration {
    id: string;
    integrationSource: IntegrationSources;
    integrationType: IntegrationTypes;
    public: boolean;
    settings: IntegrationSettings;
    lastSyncStatus: IntegrationStatus;
    lastSyncedAt: string;
}

export enum UserTeamSettingType {
    Signature = 'signature',
    InboxesOrder = 'inboxes_order',
}

export type UserTeamSetting = {
    userId: string;
    teamId: string;
    data?: Record<string, any>;
    enabled?: boolean;
    type: UserTeamSettingType;
};

interface BaseProfile {
    activeTeam: Team;
    activeRole?: Role;
    activeSetting: UserTeamSetting;
    id: UUID;
    email: string;
    isActive: boolean;
    roles: Array<Role>;
    microsoftId?: string;
    microsoftTenantId?: string;
    mutedInboxIds: string[];
    acceptedTermsOfService: boolean;
    created: Date;
    updated: Date;
}

export type OnboardingProfile = BaseProfile & Partial<PersonalInfo>;

export type Profile = BaseProfile & PersonalInfo;

export enum ActivityObjects {
    Contact = 'contact',
    Inbox = 'inbox',
    Message = 'message',
    Conversation = 'conversation',
    Integration = 'integration',
    Cohort = 'cohort',
    Campaign = 'campaign',
    Team = 'team',
}

export enum ActivityActions {
    Added = 'added',
    Removed = 'removed',
    Sent = 'sent',
    Joined = 'joined',
    Scheduled = 'scheduled',
}

export type UserActivity = {
    id: number;
    activity: Activity;
    archived: boolean;
    unread: boolean;
};

export type Activity = {
    id: string;
    system: boolean;
    actor?: Profile;
    team?: Team;
    objectType: ActivityObjects;
    objectAction: ActivityActions;
    contact?: Contact;
    cohort?: Cohort;
    campaign?: Campaign;
    conversation?: Conversation;
    inbox?: Inbox;
    message?: Message;
    integration?: Integration;
    created: string;
    unreadUsers: Profile[];
};

export interface Phone {
    externalId: string;
    phone: string;
    inboxesName: string;
}

export interface Attachment {
    id: UUID;
    url: string;
    name: string;
    type: string;
    size: number;
}

/** @deprecated try to use Attachment instead */
export interface PublicFile {
    id: string;
    url: string;
    size: number;
    name?: string;
    type?: string;
}

export interface MessageComment {
    id: number;
    discussionId: number;
    text: string;
    userId: UUID;
    mentionedUserIds?: UUID[];
    created: Date;
    attachments?: PublicFile[];
}

export interface Discussion {
    id: number;
    messageId: number;
    replies: number;
    lastReplyDate?: Date;
    message: Message;
    comments: MessageComment[];
}

export enum SenderType {
    USER = 'user',
    CAMPAIGN = 'campaign',
    CAMPAIGN_MESSAGE = 'campaign_message',
    WORKFLOW = 'workflow',
    ASSISTANT = 'assistant',
}

export enum MessageStatus {
    Sending = 'sending',
    Sent = 'sent',
    Delivered = 'delivered',
    Failed = 'failed',
    Read = 'read',
    Scheduled = 'scheduled',
}

export type Message = {
    id: number;
    conversationId: UUID;
    created: string;
    inbound: boolean;
    body: string;
    user: Profile;
    sender: string;
    status: MessageStatus;
    sentByName: string;
    senderType: SenderType;
    attachments: PublicFile[];
    attached: Attachment[];
    discussion?: Discussion;
    timestamp: string;
};

export enum ConversationStatus {
    Active = 'active',
    Archived = 'archived',
    Unread = 'unread',
    Deleted = 'deleted',
}

export interface Conversation {
    created: number | Date;
    updated: number | Date;
    inboxId: string;
    id: string;
    members: string[];
    hasUnread: boolean;
    assistantDisabled: boolean;
    lastMessageSent?: string;
    lastMessageBody?: string;
    isLastMessageInbound: boolean;
    inbox: Inbox;
    name: string;
    status: ConversationStatus;
    assignee?: Profile[];
    assistantTimestamp: string | null;
    assistantMessageId: number | null;
}

export enum ConversationsFilterType {
    Active = 'active',
    Archived = 'archived',
    Unread = 'unread',
    ByCohorts = 'by_cohorts',
    AssignedToMe = 'assigned_to_me',
}

export interface ConversationsFilter {
    type: ConversationsFilterType;
    cohortsList: CohortMeta[];
}

export interface Verification {
    email: string;
    method_id: string;
    code: string;
    microsoftTenantId?: string;
}

export type BandwidthPhone = Array<string>;

export type UnreadMessageRecord = {
    userId: string;
    conversationId: string;
    inboxId: string;
    messageId: string;
};

export enum ScheduledMessageStatus {
    OK = 'ok',
    SCHEDULED = 'scheduled',
    ERROR = 'error',
}

/** @deprecated use Message with timestamp instead */
export interface ScheduledMessage {
    id: number;
    body?: string;
    sendAt: string;
    status: ScheduledMessageStatus;
    error: string;
    user?: Profile;
    attachments: PublicFile[];
}

export interface CreateScheduledMessageDto {
    body: string;
    conversationId: string;
    sendAt: string;
    files?: string[];
}

export interface UpdateScheduledMessageDto {
    id: number;
    body?: string;
    sendAt: string;
    files?: string[];
}

export enum RoleTypes {
    WorkspaceOwner = 'workspace_owner',
    Admin = 'admin',
    Agent = 'agent',
    Manager = 'manager',
    Member = 'member',
}

export enum ClerkPermissions {
    AddInbox = 'add_inbox',
    AddTeamMembers = 'add_team_members',
    DeleteConversation = 'delete_conversation',
    DeleteMessage = 'delete_message',
    DeleteRestrictedWord = 'delete_restricted_word',
    ExportInboxLogs = 'export_inbox_logs',
    ModifyBilling = 'modify_billing',
    ModifyPrivateInbox = 'modify_private_inbox',
    ModifyPublicInbox = 'modify_public_inbox',
    ModifyTeam = 'modify_team',
    ModifyTeamMember = 'modify_team_member',
    RemoveInbox = 'remove_inbox',
    RemoveTeamMembers = 'remove_team_members',
    SendInboxMessages = 'send_inbox_messages',
    UpdateContact = 'update_contact',
    UpdateProperty = 'update_property',
    ViewAnalytics = 'view_analytics',
    ViewInboxMessages = 'view_inbox_messages',
    ViewPrivateInbox = 'view_private_inbox',
    ViewPublicInbox = 'view_public_inbox',
}

export interface Role {
    type: RoleTypes;
    permissions: Array<ClerkPermissions>;
    teamId: string;
}

export interface CreateMessageTemplateDto {
    name: string;
    body: string;
    isPersonal: boolean;
}

export interface UpdateMessageTemplateDto {
    id: number;
    name: string;
    body: string;
    isPersonal: boolean;
}

export interface MessageTemplate {
    id: number;
    name: string;
    body: string;
    userId: string | null;
}

export interface CreateWorkflowDto {
    name: string;
    spec: WorkflowSpec;
    trigger: WorkflowTrigger;
    schedules?: WorkflowSchedule[];
}

export interface AttachWorkflowDto {
    workflowId: number;
    inboxId: string;
}

export interface RenameWorkflowDto {
    workflowId: number;
    name: string;
}

export interface TeamForAuth {
    id: string;
    name?: string;
    teamAvatarURL?: string;
    members: number;
    memberAvatars: string[];
}

export enum CompanyIdentifyType {
    DUNS = 'DUNS',
    GIIN = 'GIIN',
    LEI = 'LEI',
}

export enum CompanyEntityType {
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
    PRIVATE_PROFIT = 'PRIVATE_PROFIT',
    PUBLIC_PROFIT = 'PUBLIC_PROFIT',
    NON_PROFIT = 'NON_PROFIT',
    GOVERNMENT = 'GOVERNMENT',
}

export enum BrandRegistrationStatus {
    SELF_DECLARED = 'SELF_DECLARED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    VERIFIED = 'VERIFIED',
    VETTED_VERIFIED = 'VETTED_VERIFIED',
}

export enum CompanyVertical {
    PROFESSIONAL = 'PROFESSIONAL',
    REAL_ESTATE = 'REAL_ESTATE',
    HEALTHCARE = 'HEALTHCARE',
    HUMAN_RESOURCES = 'HUMAN_RESOURCES',
    ENERGY = 'ENERGY',
    ENTERTAINMENT = 'ENTERTAINMENT',
    RETAIL = 'RETAIL',
    TRANSPORTATION = 'TRANSPORTATION',
    AGRICULTURE = 'AGRICULTURE',
    INSURANCE = 'INSURANCE',
    POSTAL = 'POSTAL',
    EDUCATION = 'EDUCATION',
    HOSPITALITY = 'HOSPITALITY',
    FINANCIAL = 'FINANCIAL',
    POLITICAL = 'POLITICAL',
    GAMBLING = 'GAMBLING',
    LEGAL = 'LEGAL',
    CONSTRUCTION = 'CONSTRUCTION',
    NGO = 'NGO',
    MANUFACTURING = 'MANUFACTURING',
    GOVERNMENT = 'GOVERNMENT',
    TECHNOLOGY = 'TECHNOLOGY',
    COMMUNICATION = 'COMMUNICATION',
}

export interface Brand {
    id: number;
    brandId?: string;
    companyName?: string;
    brandName?: string;
    registrationCountry?: string;
    organizationLegalForm?: CompanyEntityType;
    ein?: string;
    einCountry?: string;
    organizationIdentifyType: CompanyIdentifyType;
    altBusinessId?: string;
    companyCountry?: string;
    state?: string;
    city?: string;
    companyZip?: string;
    registrationErrors?: string[];
    companyAddress?: string;
    website?: string;
    organizationVertical: CompanyVertical;
    companyInfo?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhone?: string;
    contactEmail?: string;
    status?: BrandRegistrationStatus | null;
    teams: Team[];
}

export type CreateBrandDto = Partial<Brand>;
export type UpdateBrandDto = { id: number; brand: CreateBrandDto };

export interface ProfanityRecord {
    blackList: string[];
}

export enum ContactPropertyType {
    Text = 'text',
    Number = 'number',
    Select = 'select',
    MultiSelect = 'multi_select',
    Date = 'date',
    Person = 'person',
    FilesAndMedia = 'files',
    Boolean = 'boolean',
    URL = 'url',
    Email = 'email',
    Phone = 'phone',
    CreatedTime = 'created_time',
    LastEditedTime = 'last_edited_time',
    LastEditedBy = 'last_edited_by',
    LastContactedBy = 'last_contacted_by',
}

export type CreateConversationDTO = {
    inboxId: UUID;
    fileIds?: string[];
    contactIds: string[];
    message: string;
    sendAt?: string;
};

export type TeamsPhone = {
    phone: string;
    type: string;
    user?: {
        displayName: string;
        objectId: string;
        userPrincipalName: string;
    };
};

export type TeamsPhoneResponse = {
    phones: TeamsPhone[];
    activationAt?: Date;
};

export enum ErrorCodes {
    ProfanityCheckFail = 'PROFANITY_CHECK_FAIL',
    MessageLimitReached = 'SMS_LIMIT_REACHED',
    PaidTeamRequiredForCampaigns = 'PAID_TEAM_REQUIRED_FOR_CAMPAIGNS',
    CampaignBrandRegistrationRequired = 'CAMPAIGN_BRAND_REGISTRATION_REQUIRED',
}
