import PaperIcon from '../../assets/icons/different/paper.svg?react';
import styles from './TermsOfService.module.scss';
import { TOS } from './tos';
import { useUpdateMe } from '../../queries/user';
import Button from '../../elements/Buttons';
import DotLoader from '../../elements/DotLoader';
import { route } from 'preact-router';

interface TermsOfServiceProps {
    matches: {
        next: string;
    };
}

export const TermsOfService = ({ matches }: TermsOfServiceProps) => {
    const nextPage = matches?.next ? matches.next : '/';
    const { mutateAsync, isPending } = useUpdateMe();
    return (
        <div className={styles.root}>
            <div className={styles['root__inner']}>
                <div className={styles['root__main-icon']}>
                    <PaperIcon />
                </div>

                <h1 className={styles['root__title']}>Terms of Service</h1>

                <p className={styles['root__subtitle']}>
                    Please agree to the terms of service before continuing
                </p>
                <div className={styles['root__terms_of_service']}>
                    <TOS />
                </div>
                <Button
                    onClick={() =>
                        mutateAsync({ acceptedTermsOfService: true }).then(() =>
                            route(nextPage),
                        )
                    }
                    fullWidth
                >
                    {isPending ? <DotLoader /> : 'I Agree'}
                </Button>
            </div>
        </div>
    );
};
