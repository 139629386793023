import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './NewPhoneNumber.module.scss';
import { renderCountryOption, renderCountryValue } from './utils';
import { countriesOptions } from '../utils';
import { AllowedCountries, SetupWizardStep } from '../types';
import { Inbox, InboxProvider } from '../../../api/types';
import { usePhones } from '../../../queries/inboxes';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import Button from '../../../elements/Buttons';
import AdvancedSelect from '../../../elements/AdvancedSelect';
import Radio from '../../../elements/Radio';
import PhoneIcon from '../../../assets/icons/add-inbox/phone.svg?react';
import { useMeQueryData } from '../../../queries/user';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import data from '../geo.json';
import StepShell from '../StepShell';
import { Typography } from '@mui/material';

const areaCodes = data.areaCodes as Record<string, Record<string, number[]>>;

export interface NewPhoneNumberProps extends SetupWizardStep {
    setNewInbox: StateUpdater<Partial<Inbox> | undefined>;
    setInboxCountry: StateUpdater<AllowedCountries | undefined>;
}

export const NewPhoneNumber = ({
    setNewInbox,
    setInboxCountry,
    goForward,
    goBackward,
}: NewPhoneNumberProps) => {
    const me = useMeQueryData();
    const analytics = useAnalytics();
    const [country, setCountry] = useState<AllowedCountries>();
    const [state, setStateOption] = useState<string>();
    const [areaCode, setAreaCode] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');

    const { phones, isFetched, isFetching, isError, refetch } = usePhones(
        { areaCode: Number.parseInt(areaCode), quantity: 5 },
        areaCode.length >= 3,
    );

    useEffect(() => {
        if (areaCode) {
            refetch();
        }
    }, [areaCode]);

    const selectedCountry = useMemo(
        () => countriesOptions.find(({ id }) => id === country),
        [country],
    );

    const stateOptions = useMemo(
        () =>
            country
                ? data.states[country].map(({ id, name }) => ({
                      id,
                      label: name,
                  }))
                : [],
        [country],
    );

    const areaCodeOptions = useMemo(
        () =>
            state && country
                ? areaCodes[country][state].map((n) => ({
                      label: String(n),
                      id: String(n),
                  }))
                : [],
        [country, state],
    );

    return (
        <StepShell
            title="Get a new number"
            subtitle="Choose your country and area code."
            icon={<PhoneIcon />}
            goBackward={goBackward}
        >
            <div className={styles['root__content']}>
                <p className={styles['root__country-field']}>
                    <AdvancedSelect
                        placeholder="Country"
                        options={countriesOptions}
                        value={country}
                        onChange={(country) => {
                            setCountry(country as AllowedCountries);
                            setStateOption(undefined);
                            setAreaCode('');
                            analytics.dispatch({
                                type: AnalyticsActions.TRACK,
                                payload: {
                                    eventName:
                                        AnalyticsEventName.ONBOARDING_USER_ADDED_COUNTRY,
                                    eventPayload: {
                                        userId: me?.id,
                                        teamId: me?.activeTeam?.id,
                                        country,
                                    },
                                },
                            });
                        }}
                        renderValue={renderCountryValue}
                        renderOption={renderCountryOption}
                    />
                </p>
                {country && (
                    <p className={styles['root__state-field']}>
                        <AdvancedSelect
                            placeholder="State"
                            options={stateOptions}
                            value={state}
                            onChange={(s) => {
                                setStateOption(s);
                                setAreaCode('');
                                analytics.dispatch({
                                    type: AnalyticsActions.TRACK,
                                    payload: {
                                        eventName:
                                            AnalyticsEventName.ONBOARDING_USER_ADDED_STATE,
                                        eventPayload: {
                                            userId: me?.id,
                                            teamId: me?.activeTeam?.id,
                                            state: s,
                                        },
                                    },
                                });
                            }}
                        />
                    </p>
                )}

                {state && (
                    <p className={styles['root__area-code-field']}>
                        <AdvancedSelect
                            placeholder="Area code (i.e. 455)"
                            className={styles['root__area-code-input']}
                            options={areaCodeOptions}
                            value={areaCode}
                            onChange={(value) => {
                                setAreaCode(value);
                            }}
                        />
                        {isFetched && (isError || phones.length === 0) && (
                            <span className={styles['root__area-code-error']}>
                                Sorry we could not find any phone numbers in
                                that area code. Please select another area code.
                            </span>
                        )}
                    </p>
                )}

                {!isError &&
                    !isFetching &&
                    areaCode.length >= 3 &&
                    phones.length > 0 && (
                        <>
                            <p
                                className={
                                    styles['root__phones-list-description']
                                }
                            >
                                Select a phone number from the available options
                                below.
                            </p>
                            <ul className={styles['root__phones-list']}>
                                {phones.map((phone) => (
                                    <Typography
                                        variant="body3"
                                        component="li"
                                        key={phone.id}
                                        className={cc([
                                            styles['root__phone'],
                                            {
                                                [styles[
                                                    'root__phone_selected'
                                                ]]:
                                                    phone.number ===
                                                    selectedNumber,
                                            },
                                        ])}
                                        onClick={() =>
                                            setSelectedNumber(phone.number)
                                        }
                                    >
                                        {formatPhoneNumber(
                                            `${selectedCountry?.code}${phone.number}`,
                                        )}
                                        <Radio
                                            checked={
                                                phone.number === selectedNumber
                                            }
                                        />
                                    </Typography>
                                ))}
                            </ul>
                            <Button
                                className={styles['root__continue-btn']}
                                onClick={() => {
                                    setNewInbox({
                                        phone: selectedNumber,
                                        provider: InboxProvider.BANDWIDTH,
                                    });
                                    setInboxCountry(country);
                                    goForward();
                                }}
                                disabled={!selectedNumber}
                                fullWidth
                            >
                                Proceed
                            </Button>
                        </>
                    )}
            </div>
        </StepShell>
    );
};
