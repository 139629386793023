import { ComponentChildren } from 'preact';
import { createContext } from 'preact/compat';
import { useContext, useReducer } from 'preact/hooks';
import {
    initialState,
    reducer,
    WorkflowReducerAction,
    WorkflowReducerState,
} from './reducer';

// CONTEXT

interface WorkflowContext {
    state: WorkflowReducerState;
    dispatch: (action: WorkflowReducerAction) => void;
}

const Context = createContext<WorkflowContext>({
    state: initialState,
    dispatch: () => null,
});

// PROVIDER & HOOK

interface WorkflowProviderProps {
    children: ComponentChildren;
}

export const WorkflowProvider = ({ children }: WorkflowProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const useWorkflowContext = () => useContext(Context);
