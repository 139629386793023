import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import TextareaAutosize from '../../../../elements/TextareaAutosize';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';

export const TextValue = ({
    userProperty,
    onChange,
    readonly,
}: ValueProps<string>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useOnClickOutside(inputRef, () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Enter', true, (e) => {
        if (e.shiftKey) {
            return;
        }
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Escape', () => {
        setValue(userProperty.value);
        setEditEnabled(false);
    });

    useEffect(() => {
        setValue(userProperty.value);
    }, [userProperty]);

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
            const position = value?.length || 0;
            inputRef.current?.setSelectionRange(position, position);
        }
    }, [isEditEnabled]);

    if (isEditEnabled && !readonly) {
        return (
            <TextareaAutosize
                ref={inputRef}
                onChange={(val) => setValue(val.trim())}
                wrapperClassName={styles['txtarea-wrapper']}
                textareaClassName={styles['txtarea']}
                disabled={!!userProperty.readonly}
                value={value}
            />
        );
    }
    return (
        <div
            className={cc([styles['value'], styles['value_breakable']])}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                userProperty.value
            ) : (
                <span className={styles['value__empty']}>
                    {readonly ? 'Empty' : '+ Set a value'}
                </span>
            )}
        </div>
    );
};
