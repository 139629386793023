import confetti from 'canvas-confetti';
import styles from './PaymentFinish.module.scss';
import PartyIcon from '../../../assets/icons/add-inbox/party.svg?react';
import { useLayoutEffect } from 'preact/hooks';

export const PaymentFinish = () => {
    useLayoutEffect(() => {
        const canvas = document.getElementById(
            'payment-finish-canvas',
        ) as HTMLCanvasElement;

        if (canvas) {
            const myConfetti = confetti.create(canvas, { resize: true });
            myConfetti({
                particleCount: 300,
                spread: 220,
                origin: { y: 0.1 },
            });
        }
    }, []);

    return (
        <div className={styles['root']}>
            <canvas
                id="payment-finish-canvas"
                className={styles['root__canvas']}
            />
            <div className={styles['root__content-wrapper']}>
                <div className={styles['root__content']}>
                    <div className={styles['root__icon']}>
                        <PartyIcon />
                    </div>

                    <h1 className={styles['root__header']}>
                        Successfully paid!
                    </h1>

                    <p className={styles['root__greeting']}>
                        Now you can use Clerk without any limits!
                    </p>
                </div>
            </div>
        </div>
    );
};
