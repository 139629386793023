import styles from './MSTeamsPhoneNumbers.module.scss';
import Icon from '../../../icons/Icon';
import StepShell from '../StepShell';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';
import { Controller, useForm } from 'react-hook-form';
import Spacer from '../../../components/Spacer';
import FormButton from '../../../elements/FormButton';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import FormInput from '../../../elements/FormInput';
import { useMemo } from 'preact/hooks';

interface ActivationDateFormData {
    activationDate: Date;
}

export const ActivationDate = (props: MSTeamsPhoneNumbersProps) => {
    const { control, handleSubmit, setValue, setError } =
        useForm<ActivationDateFormData>();

    const onSubmit = (data: ActivationDateFormData) => {
        const requestedDate = dayjs(data.activationDate);
        if (requestedDate.day() === 0 || requestedDate.day() === 6) {
            setError('activationDate', {
                type: 'manual',
                message: `Activation date can't be on a weekend`,
            });
        } else if (requestedDate.isBefore(dayjs())) {
            setError('activationDate', {
                type: 'manual',
                message: 'Activation date must be in the future',
            });
        } else {
            props.setActivationDate?.(data.activationDate);
            props.goForward();
        }
    };

    const setDate = (date: Date) => {
        setValue('activationDate', date);
    };

    const defaultDate = useMemo<Date>(
        () => dayjs().add(1, 'day').hour(11).minute(0).second(0).toDate(),
        [],
    );

    return (
        <StepShell
            title="Activation Date"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle="Please indicate when you would like to activate your phone number."
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles['activation-date-form']}
            >
                <Controller
                    name="activationDate"
                    control={control}
                    defaultValue={props.activationDate || defaultDate}
                    render={({ field, fieldState }) => (
                        <>
                            <div className={styles['form-row']}>
                                <ReactDatePicker
                                    showTimeSelect={true}
                                    customInput={<FormInput />}
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    onChange={(date) => setDate(date!)}
                                    selected={field.value}
                                />
                            </div>
                            {fieldState.error && (
                                <div className={styles['error']}>
                                    {fieldState.error?.message}
                                </div>
                            )}
                        </>
                    )}
                />
                <Spacer height={25} width="auto" />
                <div className={styles['form-row']}>
                    <Spacer height="auto" width="50%">
                        <Spacer height="auto" width="96px">
                            <FormButton
                                variant="clear"
                                text="Back"
                                type="button"
                                onClick={props.goBack}
                            />
                        </Spacer>
                    </Spacer>
                    <Spacer height="auto" width="50%">
                        <FormButton variant="primary" text="Proceed" />
                    </Spacer>
                </div>
            </form>
        </StepShell>
    );
};
