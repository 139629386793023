import cc from 'classcat';
import Match from 'preact-router/match';
import { StateUpdater, useState } from 'preact/hooks';
import styles from './SideBar.module.scss';
import SideBarCampaigns from './SideBarCampaigns';
import SideBarCohorts from './SideBarCohorts';
import SideBarInboxes from './SideBarInboxes';
import SideBarMutedInboxes from './SideBarMutedInboxes';
import SideBarNavigation from './SideBarNavigation';
import SideBarHeader from '../../components/SideBarHeader';
import TeamSelector from '../../components/TeamSelector';
import TierUpgradeOverlay from '../../components/TierUpgradeOverlay';
import { useListTeams, useSwitchTeamQuery } from '../../queries/team';
import { useMeQueryData } from '../../queries/user';
import SideBarInvite from './SideBarInvite';
import { Box } from '@mui/material';
import { WithPermission } from '../WithPermission/WithPermission';
import { ClerkPermissions } from '../../api/types';
import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';
import { route } from 'preact-router';
import { isMobile } from '../../utils/mobile';

interface SideBarProps {
    setPromptOpen: StateUpdater<boolean>;
}

function SideBar({ setPromptOpen }: SideBarProps) {
    const [isMenuOpen, setMenuOpen] = useState(false);

    // Queries
    const currentUser = useMeQueryData();
    const { mutateAsync: setTeam } = useSwitchTeamQuery();
    const { data: teams = [] } = useListTeams();

    const toggleMenu = () => setMenuOpen((prev) => !prev);

    return (
        <aside className={styles['root']}>
            {currentUser && (
                <TeamSelector
                    teamId={currentUser.activeTeam.id}
                    teams={teams}
                    onSelect={(team) =>
                        setTeam(team.id).then((data) => {
                            setAuthToStorage(data);
                            queryClient.resetQueries().then(() => {
                                route('/');
                            });
                        })
                    }
                />
            )}
            <SideBarHeader
                isOpen={isMenuOpen}
                onMenuToggle={toggleMenu}
                onSearchClick={() => setPromptOpen(true)}
            />
            <div
                className={cc([
                    styles['root__content'],
                    {
                        [styles['root__content_open']]: isMenuOpen,
                    },
                ])}
            >
                <SideBarNavigation
                    setMenuOpen={setMenuOpen}
                    setPromptOpen={setPromptOpen}
                />

                <section
                    className={`${styles['root__section']} ${styles['root__section_main']}`}
                >
                    {!isMobile() && <SideBarInvite />}
                    <WithPermission
                        requiredPermissions={[
                            ClerkPermissions.ViewInboxMessages,
                        ]}
                    >
                        <SideBarInboxes setMenuOpen={setMenuOpen} />

                        <SideBarCohorts setMenuOpen={setMenuOpen} />

                        <SideBarCampaigns setMenuOpen={setMenuOpen} />

                        <SideBarMutedInboxes setMenuOpen={setMenuOpen} />
                    </WithPermission>

                    <Box mt="auto">
                        {currentUser?.activeTeam.tier === 'free' && (
                            <UpgradeComponent />
                        )}
                    </Box>
                </section>
            </div>
        </aside>
    );
}

const UpgradeComponent = () => {
    return (
        <Match path="/payment/:rest*">
            {({ matches }: { matches: boolean }) =>
                !matches && (
                    <div className={styles['root__upgrade-placeholder']}>
                        <TierUpgradeOverlay />
                    </div>
                )
            }
        </Match>
    );
};

export default SideBar;
