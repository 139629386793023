import {
    Box,
    CircularProgress,
    Divider,
    Fade,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    CampaignMessage,
    CampaignMessageStatus,
    useCampaignMessageRemove,
    useCampaignMessageUpdate,
} from '../use-campaign-messages';
import { DotDivider } from '../../../../components/ConversationRow/DotDivider';
import { BoxOwnProps } from '@mui/system/Box/Box';
import { getName } from '../get-profile-name';
import { ProfileAvatar } from '../ProfileAvatar';
import dayjs from 'dayjs';
import { author, body, container } from './message.styles';
import { Attachments } from '../Attachment/Attachments';
import { useMemo, useState } from 'preact/hooks';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { CopyIcon } from '../../../../icons/shared/CopyIcon';
import { useTrack } from '../../../../contexts/analytics';
import { EditIcon } from '../../../../icons/common/CEditIcon';
import { ScheduledIcon } from '../../../../icons/common/ScheduledIcon';
import { getSquare } from '../../../../theme/style.helpers';
import { useNow } from '../../../../components/ConversationRow/use-now';
import { popup } from './styles';
import { TimestampChangeDialog } from '../../../../components/Modals/TimestampChangeDialog/TimestampChangeDialog';

type Props = BoxOwnProps & { message: CampaignMessage; onEdit?: () => void };

export const Message = ({ message, sx, children, onEdit, ...props }: Props) => {
    const track = useTrack();
    const update = useCampaignMessageUpdate(message.id);
    const [open, setOpen] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const { mutate, isPending } = useCampaignMessageRemove(message.id);
    const remove = () => {
        if (window.confirm('Are you really want to delete campaign message?')) {
            mutate();
        }
    };
    const isPlanned = useMemo(
        () =>
            message.status === CampaignMessageStatus.Planned &&
            dayjs().diff(message.timestamp, 'seconds') < -60,
        [message.status, message.timestamp],
    );
    const now = useNow({
        interval: 30_000,
        disabled: !isPlanned,
    });

    return (
        <Box
            onMouseEnter={() => setShowDialog(true)}
            onMouseLeave={() => setShowDialog(false)}
            {...props}
            sx={{ width: 1, position: 'relative', ...(sx || {}) }}
        >
            <Box>
                <Box sx={container}>
                    <Box sx={{ alignItems: 'flex-start' }}>
                        <Box sx={author} variant="body4">
                            <Typography fontWeight={500} variant="body4">
                                {getName(message.user)}
                            </Typography>
                            <DotDivider />
                            {isPlanned ? (
                                <>
                                    <ScheduledIcon
                                        sx={{
                                            ...getSquare(16),
                                            mr: 1,
                                        }}
                                    />
                                    Scheduled for{' '}
                                    {dayjs(message.timestamp).format('h:mm A')}
                                </>
                            ) : (
                                dayjs(message.timestamp).format('h:mm A')
                            )}
                        </Box>
                        <Box sx={body}>
                            <p>{message.body}</p>
                            <Attachments attachments={message.attachments} />
                        </Box>
                    </Box>
                    <ProfileAvatar profile={message.user} />
                </Box>
                {children}
            </Box>
            <Fade in={showDialog} unmountOnExit timeout={100}>
                <Box className="campaign-message-actions" sx={popup}>
                    {isPlanned && (
                        <>
                            {onEdit && (
                                <Tooltip title="Edit">
                                    <IconButton
                                        data-test="cm_pop_edit"
                                        color="primary"
                                        onClick={() => {
                                            track(
                                                'campaign_message_edit_clicked',
                                                { origin: 'popup' },
                                            );
                                            onEdit();
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Change timestamp">
                                <span>
                                    <IconButton
                                        data-test="cm_pop_change_ts"
                                        disabled={update.isPending}
                                        onClick={() => setOpen(true)}
                                        color="primary"
                                    >
                                        {update.isPending ? (
                                            <CircularProgress size={18} />
                                        ) : (
                                            <ScheduledIcon />
                                        )}
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    )}
                    {!!message.body && (
                        <Tooltip arrow title="Copy">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    track('campaign_message_copied');
                                    navigator.clipboard.writeText(
                                        message.body || '',
                                    );
                                }}
                            >
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {(message.body || isPlanned) && (
                        <Divider
                            flexItem
                            orientation="vertical"
                            sx={{
                                mx: 2,
                                height: 16,
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    <Tooltip title="Delete">
                        <IconButton
                            data-test="cm_pop_delete"
                            disabled={isPending}
                            size="small"
                            color="error"
                            onClick={remove}
                        >
                            {isPending ? (
                                <CircularProgress size={27} color="error" />
                            ) : (
                                <DeleteIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Fade>
            {isPlanned && (
                <TimestampChangeDialog
                    timestamp={message.timestamp}
                    disabled={update.isPending}
                    open={open}
                    minDate={now}
                    onClose={() => setOpen(false)}
                    onChange={(date) => {
                        update
                            .mutateAsync({
                                timestamp: date.toString(),
                            })
                            .then(() => {
                                setOpen(false);
                                track('campaign_message_timestamp_changed', {
                                    origin: 'popup_dialog',
                                });
                            });
                    }}
                >
                    {update?.error?.data?.message?.[0] && (
                        <Typography variant="body2" mt={2} color="error">
                            {update?.error?.data?.message?.[0]}
                        </Typography>
                    )}
                    {update.isPending && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                display: 'flex',
                                width: 1,
                            }}
                        >
                            <LinearProgress sx={{ width: 1 }} />
                        </Box>
                    )}
                </TimestampChangeDialog>
            )}
        </Box>
    );
};
