import { Badge, Box } from '@mui/material';
import { SparklesIcon } from '../../icons/common/SparklesIcon';
import { getBadge } from './styles';
import Avatar from '../Avatar';
import { Contact } from '../../api/types';
import { memo } from 'preact/compat';

type Props = {
    memberContacts: Partial<Contact>[];
    phrase?: string;
    invisible: boolean;
};

export const MembersAvatar = memo(
    ({ memberContacts, phrase, invisible }: Props) => {
        return (
            <Box maxHeight={40}>
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<SparklesIcon />}
                    sx={getBadge(!!phrase)}
                    invisible={invisible}
                >
                    {(memberContacts.length > 0 &&
                        memberContacts
                            .slice(0, 1)
                            .map((m) => (
                                <Avatar
                                    key={m.id}
                                    size="large"
                                    imageUrl={m.avatarURL}
                                    username={m.name}
                                />
                            ))) || <Avatar size="large" />}
                </Badge>
            </Box>
        );
    },
);
