import { route } from 'preact-router';
import { FiPhone, FiUser, FiUserPlus } from 'react-icons/fi';
import { Contact, Conversation, Profile } from '../../api/types';
import { concatContactNames, getContactName } from '../../utils/contacts';
import { ContactsCache } from '../../utils/ContactsCache';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { useInboxesQueryData } from '../../queries/inboxes';

export interface Result {
    icon: JSX.Element;
    title: string;
    action: () => void;
}

const SECTIONS = [
    {
        title: 'Settings',
        icon: <FiUserPlus />,
        action: () => route('/settings/profile'),
    },
    {
        title: 'Contacts',
        icon: <FiUser />,
        action: () => route('/contacts'),
    },
];

export const getFilteredConversations = (
    me: Profile,
    contacts: ContactsCache,
    conversations: Conversation[] = [],
    searchQuery = '',
): Result[] => {
    const { countryCode } = me.activeTeam;
    const query = searchQuery.toLowerCase();

    return conversations
        .map(({ members, inboxId, id }) => {
            const convoContacts = members.map(
                (phone) => contacts.getByPhoneOrEmpty(phone) as Contact,
            );

            return {
                title: concatContactNames(convoContacts, countryCode),
                searchHash: members.join(','),
                icon: <FiUserPlus />,
                action: () => route(`/inbox/${inboxId}/${id}`),
            };
        })
        .filter(
            ({ title, searchHash }) =>
                title.toLowerCase().includes(query) ||
                searchHash.includes(query),
        )
        .slice(0, 3);
};

export const getFilteredInboxes = (me: Profile, searchQuery = ''): Result[] => {
    const { countryCode } = me.activeTeam;
    const inboxes = useInboxesQueryData() || [];
    const query = searchQuery.toLowerCase();

    return inboxes
        .filter(
            ({ name = '', phone }) =>
                name?.toLowerCase().includes(query) ||
                phone.toLowerCase().includes(query),
        )
        .map(({ id, name, phone }) => ({
            title: name || formatPhoneNumber(phone, countryCode),
            icon: <FiPhone />,
            action: () => route(`/inbox/${id}`),
        }));
};

export const getFilteredContacts = (
    me: Profile,
    contacts: ContactsCache,
    searchQuery = '',
): Result[] => {
    const { countryCode } = me.activeTeam;
    const query = searchQuery.toLowerCase();

    return contacts.list
        .filter(
            ({ name = '', phone }) =>
                name?.toLowerCase().includes(query) || phone === query,
        )
        .slice(0, 3)
        .map((contact) => ({
            title: getContactName(contact, countryCode) || '',
            icon: <FiUserPlus />,
            action: () => route(`/contacts/${contact.id}`),
        }));
};

export const getFilteredSection = (searchQuery = '') => {
    const query = searchQuery.toLowerCase();
    return SECTIONS.filter(({ title }) =>
        title.toLowerCase().includes(query.toLowerCase()),
    );
};
