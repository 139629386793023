import { StateUpdater, useState } from 'preact/hooks';
import { CountryCode } from 'libphonenumber-js';
import styles from './ExistingPhoneNumber.module.scss';
import { renderCarrierOption, renderCarrierValue } from './utils';
import StepShell from '../StepShell';
import { Carrier, SetupWizardStep } from '../types';
import { carrierOptions } from '../utils';
import Button from '../../../elements/Buttons';
import AdvancedSelect from '../../../elements/AdvancedSelect';
import RetroPhoneIcon from '../../../assets/icons/add-inbox/phone.svg?react';
import { Inbox, InboxProvider } from '../../../api/types';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { parsePhoneNumber } from '../../../utils/phoneNumber';
import PhoneNumberInput from '../../../elements/PhoneNumberInput';

export interface ExistingPhoneNumberProps extends SetupWizardStep {
    setNewInbox: StateUpdater<Partial<Inbox> | undefined>;
    goToMsTeamsSetup: () => void;
    goToCarrierConnectSetup: (carrier: InboxProvider) => void;
}

export const ExistingPhoneNumber = ({
    setNewInbox,
    goBackward,
    goToCarrierConnectSetup,
    goToMsTeamsSetup,
    goForward,
}: ExistingPhoneNumberProps) => {
    const analytics = useAnalytics();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
    const [carrier, setCarrier] = useState<Carrier>();
    const [country, setCountry] = useState<CountryCode>();

    return (
        <StepShell
            title="Existing phone"
            subtitle="To proceed select your country, choose a carrier and type in your phone number"
            icon={<RetroPhoneIcon />}
            goBackward={goBackward}
        >
            <div className={styles['root']}>
                <p className={styles['root__country-field']}>
                    <AdvancedSelect
                        placeholder="Carrier"
                        options={carrierOptions}
                        value={carrier}
                        onChange={(newCarrier) => {
                            analytics.dispatch({
                                type: AnalyticsActions.TRACK,
                                payload: {
                                    eventName:
                                        AnalyticsEventName.ONBOARDING_USER_SELECTED_CARRIER,
                                    eventPayload: {
                                        carrier: newCarrier,
                                    },
                                },
                            });
                            setCarrier(newCarrier as Carrier);
                        }}
                        renderValue={renderCarrierValue}
                        renderOption={renderCarrierOption}
                    />
                </p>
                {carrier && carrier === 'other' && (
                    <div>
                        <p className={styles['root__country-field']}>
                            <PhoneNumberInput
                                onChange={(result) => {
                                    if (result.countryCode) {
                                        setCountry(result.countryCode);
                                    }
                                    setPhoneNumber(result.phoneNumber);
                                    setPhoneNumberValid(result.isValid);
                                }}
                            />
                            {!isPhoneNumberValid && (
                                <span className={styles['root__error']}>
                                    Use international format for phone number
                                    e.g. +13855267648
                                </span>
                            )}
                        </p>
                    </div>
                )}

                <Button
                    className={styles['root__continue-btn']}
                    onClick={() => {
                        const formattedPhone = parsePhoneNumber(
                            phoneNumber,
                            country!,
                        );
                        setNewInbox({
                            phone: formattedPhone,
                            provider: carrier as InboxProvider,
                        });

                        if (carrier === 'msteams') {
                            goToMsTeamsSetup();
                        } else if (carrier === 'other') {
                            goForward();
                        } else {
                            goToCarrierConnectSetup(carrier as InboxProvider);
                        }
                    }}
                    disabled={
                        carrier === 'twilio'
                            ? false
                            : !(isPhoneNumberValid && carrier)
                    }
                    fullWidth
                >
                    Proceed
                </Button>
            </div>
        </StepShell>
    );
};
