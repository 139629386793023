import { useRef, useState } from 'preact/hooks';
import { MessageTemplate } from '../../api/types';
import styles from './MessageTemplates.module.scss';
import { MessageTemplatesEditor } from './MessageTemplatesEditor';
import { MessageTemplatesList } from './MessageTemplatesList';

type Mode = 'view' | 'create';

interface MessageTemplatesProps {
    onSelect: (text: string) => void;
    withProperties: boolean;
}

export const MessageTemplates = ({
    onSelect,
    withProperties,
}: MessageTemplatesProps) => {
    const [mode, setMode] = useState<Mode>('view');
    const [template, setTemplate] = useState<MessageTemplate | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <section className={styles['tempaltes']} ref={containerRef}>
            {mode === 'create' && (
                <MessageTemplatesEditor
                    withProperties={withProperties}
                    template={template}
                    onDone={() => {
                        setMode('view');
                        setTemplate(null);
                    }}
                />
            )}

            {mode === 'view' && (
                <MessageTemplatesList
                    withProperties={withProperties}
                    onCreateClick={() => setMode('create')}
                    onSelect={onSelect}
                    onEdit={(template) => {
                        setMode('create');
                        setTemplate(template);
                    }}
                />
            )}
        </section>
    );
};
