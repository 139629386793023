import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { useInboxes, useInboxUpdateQuery } from '../../../../queries/inboxes';
import { Inbox } from '../../../../api/types';
import { ListIcon } from '../../../../icons/common/ListIcon';
import { useMemo, useState } from 'preact/hooks';
import { UUID } from '../../../../types/uuid';
import { useTrack } from '../../../../contexts/analytics';
import { AnalyticsEventName } from '../../../../types/AnalyticsEventNames';

export const InboxRow = () => {
    const track = useTrack();
    const [updatingIds, setUpdatingIds] = useState<UUID[]>([]);
    const update = useInboxUpdateQuery();
    const { data: inboxes = [], isLoading } = useInboxes();

    const selected = useMemo<Inbox[]>(
        () => inboxes.filter(({ assistantEnabled }: Inbox) => assistantEnabled),
        [inboxes],
    );

    const onChange = (value: Inbox[]) => {
        const enabling = value.filter(
            ({ assistantEnabled }) => !assistantEnabled,
        );
        const enabledIds = value.map(({ id }) => id);
        const disabling = selected.filter(({ id }) => !enabledIds.includes(id));

        const aggregate: UUID[] = [];
        enabling.concat(disabling).forEach((inbox) => {
            const enable = !inbox.assistantEnabled;
            update
                .mutateAsync(
                    {
                        id: inbox.id,
                        assistantEnabled: enable,
                    },
                    {
                        onSettled: () => {
                            setUpdatingIds([]);
                        },
                    },
                )
                .then(() => {
                    track(
                        enable
                            ? AnalyticsEventName.ASSISTANT_INBOX_ENABLED
                            : AnalyticsEventName.ASSISTANT_INBOX_DISABLED,
                    );
                });

            aggregate.push(inbox.id);
        });
        setUpdatingIds((prev) => prev.concat(aggregate));
    };

    return (
        <>
            <div>
                <Typography
                    variant="body3"
                    component="label"
                    htmlFor="ai-assistant-inbox-select"
                    id="ai-assistant-inbox-label"
                    fontWeight={500}
                >
                    Inboxes
                </Typography>
                <Typography
                    variant="body4"
                    component="div"
                    color="custom.gray.super"
                    mt={1}
                >
                    Select the inboxes that use the assistant
                </Typography>
            </div>

            <Autocomplete
                disabled={isLoading}
                popupIcon={<ListIcon size="20" />}
                multiple
                value={selected}
                getOptionLabel={({ name, phone }) => name || phone}
                onChange={(_, value) => {
                    onChange(value);
                }}
                getOptionDisabled={(option) => updatingIds.includes(option.id)}
                sx={{ width: 260 }}
                id="ai-assistant-inbox-select"
                disableClearable
                disableCloseOnSelect
                options={inboxes}
                renderTags={(value: readonly Inbox[], getTagProps) =>
                    value.map((option: Inbox, index: number) => (
                        <Box
                            mr={0.5}
                            my={2}
                            display="inline-flex"
                            key={getTagProps({ index }).key}
                        >
                            {option.name || option.phone}
                            {index !== value.length - 1 && ', '}
                        </Box>
                    ))
                }
                openOnFocus
                ListboxProps={{
                    sx: (theme) => ({
                        ...theme.typography.body3,
                        p: 0,
                        boxShadow: '0 5px 32px 0 rgba(31, 41, 54, 0.08)',
                    }),
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        color="blue"
                        placeholder={
                            selected.length ? undefined : 'Select inboxes'
                        }
                        variant="outlined"
                        aria-label="Inboxes"
                        sx={{ p: 0 }}
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props} className="_">
                        <Box
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                sx={{ ml: 0 }}
                                size="small"
                                componentsProps={{
                                    typography: { variant: 'body3' },
                                }}
                                checked={selected}
                                control={
                                    updatingIds.includes(option.id) ? (
                                        <Box p={2.5} display="inline-flex">
                                            <CircularProgress size={18} />
                                        </Box>
                                    ) : (
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            inputProps={{
                                                'aria-label':
                                                    option.name || option.phone,
                                            }}
                                        />
                                    )
                                }
                                label={option.name || option.phone}
                            />
                        </Box>
                    </li>
                )}
            />
        </>
    );
};
