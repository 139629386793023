import styles from './DeleteCohortModal.module.scss';
import { CohortMeta } from '../../../api/types';
import Button from '../../../elements/Buttons';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../../elements/Dialog';
import Icon from '../../../icons/Icon';
import {
    COHORTS_BY_CONTACT_KEY,
    useCohortDeleteQuery,
} from '../../../queries/cohorts';
import { queryClient } from '../../../queries/queryClient';

export interface DeleteCohortModalProps {
    cohort: CohortMeta;
    close: () => void;
}

export const DeleteCohortModal = (props: DeleteCohortModalProps) => {
    const { mutateAsync: deleteCohort } = useCohortDeleteQuery();

    return (
        <Dialog width="400px">
            <DialogTitle onClose={props.close}>Delete a cohort</DialogTitle>
            <DialogContent>
                <p className={styles['text']}>
                    Are you sure want to delete{' '}
                    {props.cohort.name ? (
                        <b>{`'${props.cohort.name}'`}</b>
                    ) : (
                        'a'
                    )}{' '}
                    cohort? It consists of {props.cohort.contactsCount}{' '}
                    contacts.
                </p>

                <p className={styles['warning']}>
                    This action will remove a cohort only. Contacts will still
                    be in the table data.
                </p>
            </DialogContent>
            <DialogActions className={styles['actions']}>
                <Button type="default" onClick={props.close}>
                    Cancel
                </Button>
                <Button
                    className={styles['confirm-btn']}
                    type="default"
                    onClick={() =>
                        deleteCohort(props.cohort.id)
                            .then(() => {
                                queryClient.refetchQueries({
                                    queryKey: [COHORTS_BY_CONTACT_KEY],
                                });
                                props.close();
                            })
                            .catch(console.log)
                    }
                >
                    <Icon name="delete" />
                    <span>Yes, delete</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
