import { TargetedEvent } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import styles from './LoginForm.module.scss';
import TextButton from '../../elements/Buttons/Button';
import Icon from '../../icons/Icon';
import { useSendOTP, useVerifyOTP } from '../../queries/auth';
import { get } from '../../utils/local-storage';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { ButtonDotLoader } from './ButtonDotLoader';

const COUNTDOWN_START = 59;

const getCountdownText = (countdown: number) =>
    countdown < 10 ? `0:0${countdown}` : `0:${countdown}`;

export interface EmailStepProps {
    email: string;
    methodId: string;
    setMethodId: (methidId: string) => void;
    next: () => void;
}

export const CodeStep = (props: EmailStepProps) => {
    const [code, setCode] = useState('');
    const [countdown, setCountdown] = useState(COUNTDOWN_START);
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutateAsync: sendOTP } = useSendOTP();
    const {
        mutateAsync: verifyOTP,
        isPending,
        isError,
        reset,
    } = useVerifyOTP();

    // Effects
    useEffect(() => {
        const interval = window.setInterval(() => {
            setCountdown((oldValue) =>
                oldValue > 0 ? oldValue - 1 : oldValue,
            );
        }, 1000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleSubmit = (e: TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();
        const microsoftTenantId = get('MS_TENET_ID');

        verifyOTP({
            email: props.email.trim(),
            method_id: props.methodId,
            code,
            microsoftTenantId,
        })
            .then(() => props.next())
            .catch(console.log);
    };

    return (
        <form className={styles['code-step']} onSubmit={handleSubmit}>
            <Box mb={6}>
                <TextField
                    name="code"
                    color="blue"
                    error={isError}
                    value={code}
                    disabled={isPending}
                    onChange={(event) => {
                        setCode(event.target.value);
                        reset();
                    }}
                    InputProps={{
                        'aria-label': 'Verification code',
                        ref: inputRef,
                        placeholder: 'Verification code',
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon name="lock" />
                            </InputAdornment>
                        ),
                    }}
                    helperText={isError ? 'Could not verify code' : ''}
                    autoFocus
                    fullWidth
                />
            </Box>

            <Button disabled={!code || isPending} fullWidth type="submit">
                {isPending && <ButtonDotLoader />}
                {!isPending && 'Login'}
            </Button>

            <p className={styles['code-step__resend-wrapper']}>
                The code has been sent to your email. You can request the code
                again in —{' '}
                {countdown > 0 ? (
                    <span className={styles['code-step__resend-countdown']}>
                        {getCountdownText(countdown)}
                    </span>
                ) : (
                    <TextButton
                        className={styles['code-step__resend-btn']}
                        type="text"
                        onClick={() => {
                            sendOTP(props.email);
                            setCountdown(COUNTDOWN_START);
                        }}
                    >
                        Resend
                    </TextButton>
                )}
            </p>
        </form>
    );
};

export default CodeStep;
