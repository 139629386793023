import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import {
    addInviteeToInbox,
    addUserToInbox,
    archiveAllForInbox,
    checkHostability,
    createBulkForTeams,
    exportInboxLogs,
    getInboxes,
    getUserInboxAssignments,
    hostInbox,
    listAvailableChannels,
    ListChanneslDto,
    markAllAsReadForInbox,
    portInbox,
    removeInbox,
    removeInviteeFromInbox,
    removeUserFromInbox,
    reservePhone,
    searchPhones,
    SearchPhonesParams,
    updateInbox,
    verifyVerificationCallCode,
} from '../api/inboxes';
import {
    Inbox,
    InboxAssignment,
    InboxProvider,
    TeamsPhoneResponse,
} from '../api/types';
import { CURRENT_USER_KEY, INVITES_KEY, TEAMMATES_KEY } from './user';
import { cloneDeep } from 'lodash';
import { UNREADS_KEY } from './messages';
import { CONVERSATIONS_LIST_KEY } from './conversations';

const INBOX_UPDATE_KEY = 'inbox_update';
export const INBOX_LIST_KEY = 'list_inboxes';
export const INBOX_ASSIGNMENTS_KEY = 'list_inbox_assignments';
const INBOX_INVITE_UPDATE_KEY = 'inbox_invite_update';
const LIST_CHANNELS_KEY = 'list_channels';

export const usePhones = (params: SearchPhonesParams, enabled = true) => {
    const {
        isPending: isPending,
        isSuccess,
        isError,
        isFetched,
        isFetching,
        data,
        refetch,
    } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['phones'],
        queryFn: () => searchPhones(params),
        enabled,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        initialData: [],
    });

    return {
        phones: data || [],
        isFetched,
        isFetching,
        isPending,
        isSuccess,
        isError,
        refetch,
    };
};

export const useInboxes = () =>
    useQuery({
        queryKey: [INBOX_LIST_KEY],
        queryFn: getInboxes,
        staleTime: Infinity,
    });

export const useInboxesQueryData = () =>
    queryClient.getQueryData<Inbox[]>([INBOX_LIST_KEY]);

export const useInboxQueryData = (id: string) =>
    queryClient
        .getQueryData<Inbox[]>([INBOX_LIST_KEY])
        ?.find((i) => i.id === id);

export const useInboxAssignmentsForUser = (id: string) =>
    useQuery({
        queryKey: [INBOX_ASSIGNMENTS_KEY],
        queryFn: getUserInboxAssignments,
        select: (a) => a.filter((i) => i.userId === id),
    });

export const useInboxExport = () =>
    useMutation({
        mutationKey: ['inbox_export'],
        mutationFn: exportInboxLogs,
    });

export const useReservePhone = () =>
    useMutation({
        mutationKey: ['reservePhone'],
        mutationFn: reservePhone,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });

export const useMarkAllAsReadForInbox = () =>
    useMutation({
        mutationFn: markAllAsReadForInbox,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [UNREADS_KEY] });
        },
    });

export const useArchiveAllForInbox = () =>
    useMutation({
        mutationFn: archiveAllForInbox,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
        },
    });

export const useAddUserToInbox = () =>
    useMutation<InboxAssignment, unknown, InboxAssignment, InboxAssignment>({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: addUserToInbox,
        onMutate: (data) => {
            queryClient.setQueryData<InboxAssignment[]>(
                [INBOX_ASSIGNMENTS_KEY],
                (staleData = []): InboxAssignment[] => [...staleData, data],
            );
            return data;
        },
        onError: (error, __, context) => {
            console.warn(error);
            if (context) {
                queryClient.setQueryData<InboxAssignment[]>(
                    [INBOX_ASSIGNMENTS_KEY],
                    (staleData = []): InboxAssignment[] =>
                        staleData.filter(
                            (a) =>
                                a.inboxId != context.inboxId &&
                                a.userId !== context.userId,
                        ),
                );
            }
        },
    });

export const useRemoveUserFromInbox = () =>
    useMutation<InboxAssignment, unknown, InboxAssignment, InboxAssignment>({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: removeUserFromInbox,
        onMutate: (data) => {
            queryClient.setQueryData<InboxAssignment[]>(
                [INBOX_ASSIGNMENTS_KEY],
                (staleData = []) =>
                    staleData.filter(
                        (a) =>
                            a.inboxId !== data.inboxId ||
                            a.userId !== data.userId,
                    ),
            );
            return data;
        },
        onError: (error, __, context) => {
            console.warn(error);
            if (context) {
                queryClient.setQueryData<InboxAssignment[]>(
                    [INBOX_ASSIGNMENTS_KEY],
                    (staleData = []): InboxAssignment[] => [
                        ...staleData,
                        context,
                    ],
                );
            }
        },
    });

export const useInboxUpdateQuery = () =>
    useMutation<unknown, unknown, Partial<Inbox>, Partial<Inbox>>({
        mutationKey: [INBOX_UPDATE_KEY],
        mutationFn: updateInbox,
        onMutate: (inbox: Partial<Inbox>) => {
            queryClient.setQueryData<Inbox[]>(
                [INBOX_LIST_KEY],
                (inboxes = []) => {
                    const idx = inboxes?.findIndex((i) => i.id === inbox.id);

                    if (idx > -1) {
                        const newData = cloneDeep(inboxes);
                        newData[idx] = { ...inboxes[idx], ...inbox };
                        return newData;
                    }
                    return inboxes;
                },
            );

            return inbox;
        },
        onError: (_err, inbox: Partial<Inbox>, context) => {
            if (context) {
                queryClient.setQueryData<Inbox[]>(
                    [INBOX_LIST_KEY],
                    (inboxes = []) => {
                        const idx = inboxes?.findIndex(
                            (i) => i.id === context.id,
                        );
                        if (idx > -1) {
                            const newData = cloneDeep(inboxes);
                            newData[idx] = { ...inboxes[idx], ...context };
                            return newData;
                        }
                        return inboxes;
                    },
                );
            }
        },
    });

// Invites

export const useAddInviteeToInbox = () =>
    useMutation({
        mutationKey: [INBOX_INVITE_UPDATE_KEY],
        mutationFn: addInviteeToInbox,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
        },
    });

export const useRemoveInviteeFromInbox = () =>
    useMutation({
        mutationKey: [INBOX_INVITE_UPDATE_KEY],
        mutationFn: removeInviteeFromInbox,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
        },
    });

export const useDeleteInbox = () =>
    useMutation({
        mutationKey: ['delete_inbox'],
        mutationFn: removeInbox,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });

const defaultListChannelsOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    initialData: [],
};
export function useListChannelsQuery(
    dto: ListChanneslDto,
    isEnabled: boolean,
    options: UseQueryOptions<string[]> = defaultListChannelsOptions,
) {
    return useQuery({
        ...options,
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [LIST_CHANNELS_KEY, dto.provider],
        queryFn: () => listAvailableChannels(dto),
        enabled: isEnabled,
    });
}

export function useListChannelsQueryData(provider: InboxProvider) {
    return (
        queryClient.getQueryData<string[]>([LIST_CHANNELS_KEY, provider]) || []
    );
}

export function useCheckHostabilityQuery(phone: string) {
    return useQuery({
        queryKey: ['check_hostability', phone],
        queryFn: () => checkHostability({ phone }),
    });
}
export function useCheckHostabilityQueryData(phone: string) {
    return queryClient.getQueryData<{ hostable: boolean }>([
        'check_hostability',
        phone,
    ]);
}

export function useHostInboxMutation(inboxId: string) {
    return useMutation({
        mutationKey: ['host', inboxId],
        mutationFn: () => hostInbox({ inboxId }),
    });
}
export function usePortInboxMutation(inboxId: string) {
    return useMutation({
        mutationKey: ['host', inboxId],
        mutationFn: () => portInbox({ inboxId }),
    });
}

export function useVerificationCallCodeMutation(phone: string) {
    return useMutation({
        mutationKey: ['verification_call_code', phone],
        mutationFn: ({ code }: { code: string }) =>
            verifyVerificationCallCode({ phone, code }),
    });
}

export function useBulkCreateMutation() {
    return useMutation({
        mutationKey: ['create_bulk'],
        mutationFn: (payload: TeamsPhoneResponse) =>
            createBulkForTeams(payload),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
        },
    });
}
