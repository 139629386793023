/* eslint-disable @typescript-eslint/no-explicit-any */
import { OnOptionCreateHandler, UserPropertyModel } from '../types';

import { EmailValue } from './EmailValue';
import { NumberValue } from './NumberValue';
import { LimitedTextValue } from './LimitedTextValue';
import { TextValue } from './TextValue';
import { RawValue } from './RawValue';
import { SelectValue } from './SelectValue';
import { MultiselectValue } from './MultiselectValue';
import { DateValue } from './DateValue';
import { PersonValue } from './PersonValue';
import { BooleanValue } from './BooleanValue';
import { ContactPropertyType } from '../../../api/types';

export const renderValue = (
    userProperty: UserPropertyModel,
    contactId?: string,
    valueTransformer?: (value: any) => any,
    onValueChange?: (value: any) => any,
    onOptionCreate?: OnOptionCreateHandler,
) => {
    const readonly =
        userProperty.source != 'clerk' || userProperty.name === 'Source';
    const props = { userProperty, onChange: onValueChange, readonly };

    switch (userProperty.type) {
        case ContactPropertyType.Text:
            return <TextValue {...props} />;

        case ContactPropertyType.Number:
            return <NumberValue {...props} />;

        case ContactPropertyType.Select:
            return (
                <SelectValue
                    {...props}
                    contactId={contactId}
                    onCreate={onOptionCreate}
                />
            );

        case ContactPropertyType.MultiSelect:
            return (
                <MultiselectValue
                    {...props}
                    contactId={contactId}
                    onCreate={onOptionCreate}
                />
            );

        case ContactPropertyType.Person:
            return <PersonValue {...props} />;

        case ContactPropertyType.Email:
            return <EmailValue {...props} />;

        case ContactPropertyType.URL:
            return <LimitedTextValue {...props} />;

        case ContactPropertyType.Phone:
            return (
                <LimitedTextValue {...props} formatDisplay={valueTransformer} />
            );

        case ContactPropertyType.Date:
        case ContactPropertyType.CreatedTime:
        case ContactPropertyType.LastEditedTime:
            return <DateValue {...props} />;

        case ContactPropertyType.Boolean:
            return <BooleanValue {...props} />;

        case ContactPropertyType.FilesAndMedia:
            return <RawValue {...props} />;

        default:
            return <RawValue {...props} />;
    }
};
