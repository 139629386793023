import Router, { Route } from 'preact-router';
import clerklogo from '../../assets/images/clerk-logo.png';
import EmojiTrio from '../../components/EmojiTrio';
import ProgressBar from '../../components/ProgressBar';
import Spacer from '../../components/Spacer';
import { AddressVerificationStep } from './AddressVerificationStep';
import { CustomerVerificationStep } from './CustomerVerificationStep';
import { PhoneCallVerificationStep } from './PhoneCallVerificationStep';
import { PhoneConfigurationStep } from './PhoneConfigurationStep';
import { PhoneTypeVerificationStep } from './PhoneTypeVerificationStep';
import styles from './PhoneVerification.module.scss';
import { SignatureVerificationStep } from './SignatureVerificationStep';

export type PhoneVerificationProps = {
    inboxId: string;
    step:
        | 'customer'
        | 'call'
        | 'address'
        | 'phone-type'
        | 'phone-configuration'
        | 'signature';
};

const steps = [
    'customer',
    'call',
    'address',
    'phone-type',
    'phone-configuration',
    'signature',
];

export const PhoneVerification = (props: PhoneVerificationProps) => {
    return (
        <div className={styles['root']}>
            <div className={styles['root__pb']}>
                <ProgressBar
                    steps={steps}
                    currentStep={steps.indexOf(props.step)}
                />
            </div>
            <div className={styles['root__header']}>
                <img src={clerklogo} />
                <h1>Clerk Chat Inc</h1>
                <h2>Letter Of Authorization (LOA)</h2>
            </div>
            <Spacer width="auto" height={24} />
            <Spacer width="360px" height="auto">
                <EmojiTrio emoji="🔒" />
            </Spacer>
            <div className={styles['root__body']}>
                <Router>
                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/customer"
                        component={CustomerVerificationStep}
                        inboxId={props.inboxId}
                    />
                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/call"
                        component={PhoneCallVerificationStep}
                        inboxId={props.inboxId}
                    />

                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/address"
                        component={AddressVerificationStep}
                        inboxId={props.inboxId}
                    />
                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/phone-type"
                        component={PhoneTypeVerificationStep}
                        inboxId={props.inboxId}
                    />
                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/phone-configuration"
                        component={PhoneConfigurationStep}
                        inboxId={props.inboxId}
                    />
                    <Route<PhoneVerificationProps>
                        path="/inbox/:inboxId/verify/signature"
                        component={SignatureVerificationStep}
                        inboxId={props.inboxId}
                    />
                </Router>
            </div>
        </div>
    );
};
