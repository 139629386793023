import { ChangeEvent, useState } from 'react';
import { Button, Divider, Grid, Stack } from '@mui/material';
import { WithPermission } from '../../../containers/WithPermission/WithPermission';
import { ClerkPermissions } from '../../../api/types';
import InviteUserModal from '../../../components/Modals/InviteUserModal';
import { useTrack } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { AddMemberIcon } from './AddMemberIcon';
import { SearchTextField } from '../../../elements/SearchTextField/SearchTextField';
import { Invites } from './Invites/Invites';
import { UserTable } from './Users/UserTable';

export const Members = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const track = useTrack();

    const addMemberHandler = () => {
        track(AnalyticsEventName.CLICKED_SEND_INVITE);
        setShowInviteDialog(true);
    };

    return (
        <SettingsLayout
            title="Members"
            description="Manage members and users of your workspace and set their access level"
        >
            <Stack spacing={10}>
                <Stack spacing={6}>
                    <Grid container justifyContent="space-between">
                        <Stack
                            spacing={4}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <WithPermission
                                requiredPermissions={[
                                    ClerkPermissions.AddTeamMembers,
                                ]}
                            >
                                <Button
                                    color="info"
                                    size="small"
                                    startIcon={<AddMemberIcon />}
                                    onClick={addMemberHandler}
                                    sx={{ width: 152 }}
                                >
                                    Add member
                                </Button>
                            </WithPermission>
                        </Stack>

                        <SearchTextField
                            placeholder="Search in members"
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setSearchTerm(
                                    event.currentTarget.value
                                        .trim()
                                        .toLocaleLowerCase(),
                                )
                            }
                        />
                    </Grid>

                    <Divider />

                    <WithPermission
                        requiredPermissions={[
                            ClerkPermissions.ModifyPublicInbox,
                            ClerkPermissions.ModifyPrivateInbox,
                        ]}
                    >
                        <Invites searchTerm={searchTerm} />
                    </WithPermission>
                </Stack>

                <WithPermission
                    requiredPermissions={[
                        ClerkPermissions.ModifyPublicInbox,
                        ClerkPermissions.ModifyPrivateInbox,
                    ]}
                >
                    <UserTable searchTerm={searchTerm} />
                </WithPermission>
            </Stack>
            <InviteUserModal
                open={showInviteDialog}
                onClose={() => setShowInviteDialog(false)}
            />
        </SettingsLayout>
    );
};
