import { useMutation, useQuery } from '@tanstack/react-query';
import {
    createTeamApiKey,
    getOrCreateWidgetKey,
    getTeamApiKey,
    listTeams,
    listTeamsForAuth,
    removeTeam,
    setupTeam,
    switchTeam,
    updateTeam,
    updateTeamAvatar,
} from '../api/team';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY } from './user';
import { Profile } from '../api/types';

const TEAM_LIST_KEY = 'team_list_key';
const TEAM_WIDGET_KEY = ['team_widget_key'];
const GET_TEAM_API_KEY = 'GET_TEAM_API_KEY';

export function useListTeams() {
    return useQuery({
        queryKey: [TEAM_LIST_KEY],
        queryFn: listTeams,
    });
}

export function useListTeamsForAuth() {
    return useQuery({
        queryKey: ['teams_list_for_auth_key'],
        queryFn: listTeamsForAuth,
    });
}

export const useUpdateTeam = () =>
    useMutation({
        mutationFn: updateTeam,
        onSuccess: (updated, params) => {
            queryClient.setQueryData<Profile>([CURRENT_USER_KEY], (prev) => {
                if (!prev) {
                    return prev;
                }

                const activeTeam = {
                    ...prev?.activeTeam,
                    name: params?.name ?? prev?.activeTeam.name,
                    assistantResponseDelaySeconds:
                        updated?.assistantResponseDelaySeconds ??
                        prev?.activeTeam.assistantResponseDelaySeconds,
                    assistantInstruction:
                        params.assistantInstruction ??
                        prev?.activeTeam.assistantInstruction,
                };
                return {
                    ...prev,
                    activeTeam,
                };
            });
            /*todo: can be optimized*/
            queryClient.refetchQueries({ queryKey: [TEAM_LIST_KEY] });
        },
    });

export function useUpdateTeamAvatar() {
    return useMutation({
        mutationFn: updateTeamAvatar,
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
        },
    });
}

export function useSwitchTeamQuery() {
    return useMutation({
        mutationKey: ['team_change_key'],
        mutationFn: switchTeam,
        onMutate: async () => {
            await queryClient.cancelQueries();
        },
    });
}

export function removeTeamQuery() {
    return useMutation({
        mutationKey: ['team_remove_key'],
        mutationFn: removeTeam,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [TEAM_LIST_KEY] });
        },
    });
}

export function useSetupTeamQuery() {
    return useMutation({
        mutationKey: ['team_setup_key'],
        mutationFn: setupTeam,
        onMutate: async () => {
            await queryClient.cancelQueries();
        },
        onSuccess: async () => {
            await queryClient.fetchQuery({ queryKey: [CURRENT_USER_KEY] });
        },
    });
}

export function useGetTeamApiKey() {
    return useQuery({
        queryKey: [GET_TEAM_API_KEY],
        queryFn: getTeamApiKey,
    });
}

export function useCreateTeamApiKey() {
    return useMutation({
        mutationKey: ['CREATE_TEAM_API_KEY'],
        mutationFn: createTeamApiKey,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [GET_TEAM_API_KEY] });
            await queryClient.refetchQueries({ queryKey: [GET_TEAM_API_KEY] });
        },
    });
}

export function useTeamWidgetKey() {
    return useQuery({
        queryKey: TEAM_WIDGET_KEY,
        queryFn: getOrCreateWidgetKey,
    });
}
