export const INVITEE_EMAIL_REGEXP = /\w[^\s@]+@\w+(?:[.-]\w+)*\.[a-zA-Z]+/g;

export const addInviteeEmail = (emails: string[], input: string) => {
    const email = input.trim();
    if (
        !email ||
        !email.match(INVITEE_EMAIL_REGEXP) ||
        emails.includes(email)
    ) {
        return emails;
    }
    return emails.concat(email);
};
