import { UUID } from '../types/uuid';
import client from './http';
import { Invite, Profile, RoleTypes, UserTeamSetting } from './types';

export function getMe(): Promise<Profile> {
    return client()
        .get<Profile>('/users/profile')
        .then((r) => r.data);
}
export function getTeammates(): Promise<Profile[]> {
    return client()
        .get<Profile[]>('/users/teammates')
        .then((r) => r.data);
}

export function getSettings(): Promise<UserTeamSetting[]> {
    return client()
        .get<UserTeamSetting[]>('/users/settings')
        .then((r) => r.data);
}

export function updateMe(me: Partial<Profile>) {
    return client()
        .post('/users/profile', me)
        .then<Profile>((r) => r.data);
}

export function updateMeSettings(setting: Partial<UserTeamSetting>) {
    return client()
        .post('/users/settings', setting)
        .then((r) => r.data);
}

export function updateProfileImage({ file }: { file: File }) {
    if (file.size > 10000000) {
        throw Error('Image max size exceeded.');
    }

    const formData = new FormData();
    formData.append('file', file);

    return client().post(`/users/avatar`, formData);
}

export function inactivateUser(id: string) {
    return client().delete(`/users/${id}`);
}

// Invites

export type InviteTeamMemberDto = {
    emails: string[];
    role?: RoleTypes;
    inboxIds: UUID[];
};

export function inviteTeamMembers(dto: InviteTeamMemberDto) {
    return client().post('/invites', dto);
}

export function deleteInvite({ id }: { id: string }) {
    return client().delete(`/invites/${id}`);
}

export type InviteRoleDto = { id: string; role: RoleTypes };

export function setInviteRole({ id, role }: InviteRoleDto) {
    return client()
        .post<Invite>(`/invites/${id}/role`, { role })
        .then((response) => response.data);
}

export function resendInvite({ id }: { id: string }) {
    return client()
        .post<Invite>(`/invites/${id}/resend`)
        .then((response) => response.data);
}

export function listInvites() {
    return client()
        .get<Invite[]>('/invites')
        .then((res) => res.data);
}

export function resetMicrosoftGraphCredentials() {
    return client().get('/users/microsoft/graph/reset');
}
