import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CollapseIcon } from './CollapseIcon';
import { ExpandIcon } from './ExpandIcon';
import * as styles from './styles';

type Props = {
    children: React.ReactNode; // defined to make `children` prop required
    label: string;
    count?: number;
    autoExpand?: boolean;
    showCount?: boolean;
};

export function CompactCollapsible({
    children,
    label,
    count,
    autoExpand = false,
    showCount = false,
}: Props) {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (autoExpand && !!children) {
            setExpanded(true);
        }
    }, [autoExpand, children]);

    return (
        <div>
            <Stack
                spacing={2}
                direction="row"
                component="span"
                sx={styles.toggleButton}
                color={expanded ? 'info.main' : 'custom.gray.super'}
                onClick={() => setExpanded((val) => !val)}
            >
                {expanded ? (
                    <CollapseIcon sx={styles.collapseIcon} />
                ) : (
                    <ExpandIcon sx={styles.expandIcon} />
                )}
                <Box sx={styles.label}>
                    <Typography variant="body4" color="primary.dark">
                        {label}{' '}
                        {showCount && !!count && (
                            <Typography variant="body4" color="info.main">
                                ({count})
                            </Typography>
                        )}
                    </Typography>
                </Box>
            </Stack>

            {expanded && children}
        </div>
    );
}
