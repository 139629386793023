import { SxProps } from '@mui/material';

export const header: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 61,
    gap: 4,
    px: 6,
    py: 4,
    borderBottom: '1px solid',
    borderColor: 'custom.gray.divider',
};
