import { useRef, useState } from 'preact/hooks';
import styles from './SummarizeAction.module.scss';
import { ActionButton } from '../ActionButton';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { Conversation } from '../../../api/types';
import { useSummarizeConversationQuery } from '../../../queries/conversations';
import Loading from '../../Loading/Loading';
import { Box, Button } from '@mui/material';
import { CopyIcon } from '../../../icons/common/CopyIcon';

export interface SummarizeActionProps {
    conversation: Conversation;
}

export const SummarizeAction = (props: SummarizeActionProps) => {
    const [active, setActive] = useState(false);
    const {
        data: summary,
        isPending: summaryLoading,
        isFetching: summaryFetching,
        refetch,
    } = useSummarizeConversationQuery(props.conversation.id, active);

    const actionButtonRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([actionButtonRef, popupRef], () => setActive(false));

    return (
        <div className={styles['root']}>
            <ActionButton
                ref={actionButtonRef}
                iconName="ai-chat"
                tooltipText="Summarize Conversation"
                onClick={() => setActive(!active)}
            />

            {active && (
                <div className={styles['root__popup']} ref={popupRef}>
                    {summaryLoading ? (
                        <Loading />
                    ) : (
                        <div className={styles['root__summary']}>
                            <p className={styles['root__summary-text']}>
                                {summary}
                            </p>
                            <div className={styles['root__summary-actions']}>
                                <Button
                                    variant="outlined"
                                    disabled={summaryFetching}
                                    onClick={() => refetch()}
                                    fullWidth
                                >
                                    {summaryFetching
                                        ? 'Re-Summarizing...'
                                        : 'Re-Summarize'}
                                </Button>
                                <Button
                                    variant="outlined"
                                    disabled={!summary}
                                    startIcon={<CopyIcon />}
                                    onClick={() => {
                                        try {
                                            navigator.clipboard.writeText(
                                                summary || '',
                                            );
                                        } catch (e) {
                                            console.error('Copy error', e);
                                        }
                                    }}
                                    fullWidth
                                >
                                    Copy
                                </Button>
                            </div>
                        </div>
                    )}
                    <Box typography="body3" mt={2}>
                        Content generated by AI may be incorrect
                    </Box>
                </div>
            )}
        </div>
    );
};
