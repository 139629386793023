import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import {
    createMessageTemplate,
    getMessageTemplates,
    removeMessageTemplate,
    updateMessageTemplate,
} from '../api/message-templates';
import {
    CreateMessageTemplateDto,
    MessageTemplate,
    UpdateMessageTemplateDto,
} from '../api/types';
import { useProperties } from '../components/MessageInput/components/Template/useProperties';

export const MESSAGE_TEMPLATES_LIST_KEY = 'message_templates_list';
const createPlaceholder = (dto: CreateMessageTemplateDto): MessageTemplate => ({
    ...dto,
    id: -1,
});

export function useMessageTemplatesQuery({
    withProperties,
}: {
    withProperties: boolean;
}) {
    const { regEx } = useProperties();
    const {
        data = [],
        isPending,
        isError,
    } = useQuery({
        queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
        queryFn: getMessageTemplates,
    });

    if (withProperties) {
        return {
            templates: data,
            isPending,
            isError,
        };
    }

    return {
        templates: data.filter(({ body }) => !regEx.test(body)),
        isPending,
        isError,
    };
}

export function useMessageTemplateCreateQuery() {
    return useMutation<
        MessageTemplate,
        unknown,
        CreateMessageTemplateDto,
        unknown
    >({
        mutationKey: ['message_template_create'],
        mutationFn: createMessageTemplate,
        onMutate: async (createDto) => {
            await queryClient.cancelQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });

            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                (tempaltes = []) =>
                    tempaltes.concat(createPlaceholder(createDto)),
            );
        },
        onSuccess: (messageTemplate) => {
            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                (tempaltes = []) =>
                    tempaltes.slice(0, -1).concat(messageTemplate),
            );
        },
        onError: () => {
            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                (tempaltes = []) => tempaltes.slice(0, -1),
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });
        },
    });
}

export function useMessageTemplateUpdateQuery() {
    return useMutation<
        MessageTemplate,
        unknown,
        UpdateMessageTemplateDto,
        { prevTempaltes: MessageTemplate[] }
    >({
        mutationKey: ['message_template_update'],
        mutationFn: updateMessageTemplate,
        onMutate: async (updateDto) => {
            await queryClient.cancelQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });

            const prevTempaltes =
                queryClient.getQueryData<MessageTemplate[]>([
                    MESSAGE_TEMPLATES_LIST_KEY,
                ]) || [];

            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                (tempaltes = []) => {
                    const idx = tempaltes.findIndex(
                        ({ id }) => id === updateDto.id,
                    );
                    const updatedTempaltes = tempaltes.slice();
                    updatedTempaltes[idx] = {
                        ...updatedTempaltes[idx],
                        ...updateDto,
                    };

                    return updatedTempaltes;
                },
            );

            return { prevTempaltes };
        },
        onError: (_error, _updateDto, context) => {
            const { prevTempaltes } = context || { prevTempaltes: [] };

            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                () => prevTempaltes,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });
        },
    });
}

export function useMessageTemplateRemoveQuery() {
    return useMutation<
        void,
        unknown,
        number,
        { prevTemplates: MessageTemplate[] }
    >({
        mutationKey: ['message_template_delete'],
        mutationFn: removeMessageTemplate,
        onMutate: async (templateId: number) => {
            await queryClient.cancelQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });

            const prevTemplates =
                queryClient.getQueryData<MessageTemplate[]>([
                    MESSAGE_TEMPLATES_LIST_KEY,
                ]) || [];

            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                (templates = []) =>
                    templates.filter(({ id }) => id !== templateId),
            );

            return { prevTemplates };
        },
        onError: (_error, _templateId, context) => {
            const { prevTemplates } = context || { prevTemplates: [] };

            queryClient.setQueryData<MessageTemplate[]>(
                [MESSAGE_TEMPLATES_LIST_KEY],
                () => prevTemplates,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [MESSAGE_TEMPLATES_LIST_KEY],
            });
        },
    });
}
