import styles from './PhonesScrollList.module.scss';
import FormInput from '../../elements/FormInput';
import { useState } from 'preact/hooks';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import CheckBox from '../../elements/CheckBox/CheckBox';
import { TargetedEvent } from 'preact/compat';
import { TeamsPhone } from '../../api/types';

type PhonesScrollListProps = {
    phones: TeamsPhone[];
    onSelect: (teamsPhone: TeamsPhone) => void;
    selected: TeamsPhone[];
};

type PhoneScrollItemProps = {
    onClick: (o: TeamsPhone) => void;
    phone: TeamsPhone;
    checked: boolean;
};

const PhoneScrollItem = (props: PhoneScrollItemProps) => {
    return (
        <li
            className={styles['root__item']}
            onClick={() => props.onClick(props.phone)}
        >
            <div className={styles['root__item-name-container']}>
                <div className={styles['root__item-phone']}>
                    {formatPhoneNumber(props.phone.phone)}
                </div>
                <div className={styles['root__item-name']}>
                    {props.phone.user?.displayName || 'Unnasigned'} •{' '}
                    {props.phone.type === 'IVR' ? 'Resource Account' : 'User'}
                </div>
            </div>
            <CheckBox
                onClick={() => props.onClick(props.phone)}
                checked={props.checked}
            />
        </li>
    );
};

export const PhonesScrollList = (props: PhonesScrollListProps) => {
    const [search, setSearch] = useState<string>('');

    const filteredPhones = search
        ? props.phones.filter(
              (p) =>
                  p.phone.includes(search) ||
                  p.user?.displayName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()),
          )
        : props.phones;

    return (
        <div className={styles['root']}>
            <FormInput
                placeholder={`Search ${props.phones.length} phones`}
                iconName="search"
                value={search}
                onChange={(e: TargetedEvent<HTMLInputElement>) =>
                    setSearch(e.currentTarget?.value)
                }
            />
            <ul className={styles['root__list']}>
                {filteredPhones.map((phone) => (
                    <PhoneScrollItem
                        key={phone.phone}
                        phone={phone}
                        onClick={props.onSelect}
                        checked={props.selected.some(
                            (selectedPhone) =>
                                selectedPhone.phone === phone.phone,
                        )}
                    />
                ))}
            </ul>
        </div>
    );
};
