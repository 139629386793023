import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import { Conversation } from '../../../../api/types';
import { AdapterLink } from '../../../../components/ConversationsList/AdapterLink';
import {
    useRecipientAvatar,
    useRecipientName,
} from '../../../../queries/conversations';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import { TimeAgo } from '../TimeAgo';
import { getSquare } from '../../../../theme/style.helpers';
import { DotDivider } from '../../../../components/ConversationRow/DotDivider';
import { useTrack } from '../../../../contexts/analytics';

type Props = ButtonBaseProps & { conversation: Conversation };

export const ConversationItem = ({ conversation, sx, ...props }: Props) => {
    const track = useTrack();
    const recipient = useRecipientName(conversation);
    const avatar = useRecipientAvatar(conversation);

    return (
        <ButtonBase
            onClick={() => track('campaign_message_conversation_visit')}
            LinkComponent={AdapterLink}
            href={`/inbox/${conversation.inboxId}/${conversation.id}`}
            sx={{
                borderRadius: 2,
                p: 2,
                transition: 'background-color .2s',
                '&:hover': {
                    bgcolor: 'custom.gray.active',
                },
                ...(sx ?? {}),
            }}
            {...props}
        >
            <Box display="flex" gap={2} width={1}>
                <Avatar sx={getSquare(20)} alt={recipient} src={avatar} />
                <div>
                    <Box display="flex" alignItems="center">
                        <Typography fontWeight={500} variant="body4">
                            {recipient}
                        </Typography>
                        <DotDivider />
                        {conversation.lastMessageSent && (
                            <TimeAgo
                                sx={{ color: 'custom.gray.super' }}
                                date={conversation.lastMessageSent}
                            />
                        )}
                    </Box>
                    <Box mt={1}>{conversation.lastMessageBody}</Box>
                </div>
            </Box>
        </ButtonBase>
    );
};
