import sflogo from '../../../assets/images/sf.svg';
import hubspotLogo from '../../../assets/images/hubspot.svg';
import googlelogo from '../../../assets/images/google.svg';
import csvlogo from '../../../assets/images/csv.svg';
import shopifylogo from '../../../assets/images/shopify-icon.svg';
import dynamicslogo from '../../../assets/images/dynamics-logo.svg';
import linkedinlogo from '../../../assets/images/linkedin.svg';
import pipedrivelogo from '../../../assets/images/pipedrive-logo.svg';
import globalrelaylogo from '../../../assets/images/global-relay-logo.png';
import onedrivelogo from '../../../assets/images/onedrive-logo.png';
import outlooklogo from '../../../assets/images/outlook-logo.png';
import slacklogo from '../../../assets/images/slack-logo.png';
import stripelogo from '../../../assets/images/stripe.svg';
import smarshlogo from '../../../assets/images/smarsh-logo.svg';
import campaignRegistryLogo from '../../../assets/images/campaign-registry.png';
import { IntegrationSources, IntegrationTypes } from '../../../api/types';

export type AvailableIntegration = {
    name: string;
    icon: string;
    desc: string;
    source: IntegrationSources | string;
    comingSoon: boolean;
    url?: string;
    extraSettings?: string[];
    hasSettings?: boolean;
    integrationType?:
        | IntegrationTypes.Contact
        | IntegrationTypes.Logs
        | IntegrationTypes.Data
        | IntegrationTypes.Application;
};

// The order of items in the list matters!
export const availableIntegrations: AvailableIntegration[] = [
    {
        name: 'Salesforce CRM',
        icon: sflogo,
        desc: "The world's most popular CRM",
        source: IntegrationSources.Salesforce,
        comingSoon: false,
        url: import.meta.env.VITE_SF_LOGIN_URL,
        hasSettings: true,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'Microsoft Outlook',
        icon: outlooklogo,
        desc: 'One of the largest email clients in the world',
        source: IntegrationSources.Microsoft,
        comingSoon: false,
        url: import.meta.env.VITE_MS_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'Microsoft Dynamics',
        icon: dynamicslogo,
        desc: 'Microsoft Business CRM',
        source: IntegrationSources.Dynamics,
        comingSoon: false,
        url: import.meta.env.VITE_DYNAMICS_LOGIN_URL,
        extraSettings: ['workspaceURL'],
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'HubSpot',
        icon: hubspotLogo,
        desc: 'All-in-one CRM platform for marketing, sales, and customer service',
        source: IntegrationSources.Hubspot,
        comingSoon: !import.meta.env.VITE_HUBSPOT_LOGIN_URL,
        url: import.meta.env.VITE_HUBSPOT_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'CSV Import',
        icon: csvlogo,
        desc: 'Upload a custom CSV and let our team handle the rest!',
        source: IntegrationSources.CSV,
        integrationType: IntegrationTypes.Data,
        comingSoon: false,
    },
    {
        name: 'Shopify',
        icon: shopifylogo,
        desc: 'The online Ecom platform',
        source: IntegrationSources.Shopify,
        comingSoon: false,
        extraSettings: ['shop_url'],
        integrationType: IntegrationTypes.Contact,
        url: import.meta.env.VITE_SHOPIFY_OAUTH_URL,
    },
    {
        name: 'Global Relay',
        icon: globalrelaylogo,
        desc: 'Technology services company providing software-as-a-service electronic message archiving',
        source: IntegrationSources.GlobalRelay,
        comingSoon: false,
        url: 'mailto:support@clerk.chat?subject=Global%20Relay%20Integration%20Request',
        integrationType: IntegrationTypes.Logs,
    },
    {
        name: 'Campaign Registry',
        icon: campaignRegistryLogo,
        desc: 'Register your business with the Campaign Registry to ensure maximum SMS deliverability!',
        source: IntegrationSources.CampaignRegistry,
        comingSoon: false,
        url: 'mailto:support@clerk.chat?subject=Register%20My%20Brand',
    },
    {
        name: 'Microsoft Azure Storage',
        icon: onedrivelogo,
        desc: 'Microsoft Azure Storage is a cloud service for storing large amounts of unstructured data',
        source: IntegrationSources.Azure,
        comingSoon: false,
        integrationType: IntegrationTypes.Logs,
        extraSettings: [''],
    },
    {
        name: 'PipeDrive',
        icon: pipedrivelogo,
        desc: 'The Original Pipeline CRM',
        source: IntegrationSources.Pipedrive,
        comingSoon: false,
        url: import.meta.env.VITE_PIPEDRIVE_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
    },
    // The ones that are coming soon
    {
        name: 'Slack',
        icon: slacklogo,
        desc: 'A powerful, organized team space',
        source: IntegrationSources.Slack,
        integrationType: IntegrationTypes.Application,
        comingSoon: true,
    },
    {
        name: 'Google Contacts',
        icon: googlelogo,
        desc: "Google contacts is Google's native CRM",
        source: IntegrationSources.Google,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'Smarsh',
        icon: smarshlogo,
        desc: 'Helping companies manage the risk in their electronic communications. Cloud-based capture, archiving and supervision solutions across more than 80 channels.',
        source: IntegrationSources.Smarsh,
        comingSoon: true,
        integrationType: IntegrationTypes.Logs,
    },
    {
        name: 'LinkedIn',
        icon: linkedinlogo,
        desc: 'The employee social network',
        source: IntegrationSources.Linkedin,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'Stripe',
        icon: stripelogo,
        desc: 'Import Customers from Stripe',
        source: IntegrationSources.Stripe,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
    },
    {
        name: 'Purview',
        icon: dynamicslogo,
        desc: 'Export logs to Purview',
        source: IntegrationSources.Purview,
        comingSoon: true,
        integrationType: IntegrationTypes.Logs,
    },
];
