import cc from 'classcat';
import { IconBaseProps } from './IconBase';
import {
    CommonIcons,
    commonIconsMap,
    CustomIcons,
    customIconsMap,
    FilesIcons,
    filesIconsMap,
    FilledIcons,
    filledIconsMap,
    FlagsIcons,
    flagsIconsMap,
    GesturesIcons,
    gesturesIconsMap,
    LogosIcons,
    logosIconsMap,
    Icons,
} from './iconsMap';
import styles from './IconBase.module.scss';

export interface IconProps extends IconBaseProps {
    name: Icons;
}

const FallBackIcon = (
    props: Pick<IconBaseProps, 'onClick' | 'className' | 'size'>,
) => (
    <div
        className={cc([styles['fallback'], props.className])}
        style={props.size ? { width: props.size, height: props.size } : {}}
        onClick={props.onClick}
    />
);

/** @deprecated do not use maps of icons to optimize bundle */
export const Icon = ({ name, ...rest }: IconProps) => {
    const ExistingIcon =
        commonIconsMap[name as CommonIcons] ||
        filledIconsMap[name as FilledIcons] ||
        flagsIconsMap[name as FlagsIcons] ||
        filesIconsMap[name as FilesIcons] ||
        gesturesIconsMap[name as GesturesIcons] ||
        logosIconsMap[name as LogosIcons] ||
        customIconsMap[name as CustomIcons];

    if (ExistingIcon) {
        return <ExistingIcon {...rest} />;
    } else {
        return (
            <FallBackIcon
                className={rest.className}
                size={rest.size}
                onClick={rest.onClick}
            />
        );
    }
};

export default Icon;
