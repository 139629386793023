import { getMessageStyle, info, recipient } from './styles';
import { Box, Typography } from '@mui/material';
import { AssistantReplyStatus } from './Assistant/AssistantReplyStatus';
import { ContextMenu } from './ContextMenu';
import { memo } from 'preact/compat';
import { Conversation } from '../../api/types';

type Props = {
    recipientDisplay: string;
    timestampDisplay: string;
    unread: boolean;
    isAssistantReply: boolean;
    phrase?: string;
    draftMessage: string | null;
    lastMessageBody: string | undefined;
    userTyping?: string;
    conversation: Conversation;
};

export const RowInfo = memo(
    ({
        recipientDisplay,
        timestampDisplay,
        unread,
        phrase,
        isAssistantReply,
        userTyping,
        draftMessage,
        lastMessageBody,
        conversation,
    }: Props) => {
        return (
            <Box sx={info}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="body3"
                        component="span"
                        sx={recipient}
                        fontWeight={500}
                    >
                        {recipientDisplay}
                    </Typography>
                    <Typography
                        variant="body4"
                        color="custom.gray.super"
                        whiteSpace="nowrap"
                        ml={2.5}
                    >
                        {timestampDisplay}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box
                        typography="body4"
                        component="span"
                        sx={getMessageStyle(
                            unread,
                            isAssistantReply && !phrase,
                        )}
                    >
                        {userTyping && (
                            <Typography
                                component="span"
                                variant="body4"
                                color="info.main"
                            >
                                {userTyping} is typing ...
                            </Typography>
                        )}
                        {isAssistantReply && !phrase && (
                            <AssistantReplyStatus conversation={conversation} />
                        )}
                        {(!isAssistantReply || !!phrase) &&
                            !userTyping &&
                            (draftMessage ? (
                                <span>
                                    <b>Draft:</b> {draftMessage}
                                </span>
                            ) : (
                                lastMessageBody
                            ))}
                    </Box>
                    <ContextMenu conversation={conversation} />
                </Box>
            </Box>
        );
    },
);
