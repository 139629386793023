export const prettySize = (bytes: number): string => {
    const units = ['B', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb'];
    let index = 0;

    while (bytes >= 1024 && index < units.length - 1) {
        bytes /= 1024;
        index++;
    }

    const size = bytes.toFixed(2);
    const unit = units[index];

    return `${size}${unit}`;
};
