import { CohortMeta } from '../../api/types';
import { ContactsCache } from '../../utils/ContactsCache';
import { isNil } from 'lodash';
import { Item } from './ConversationAutocompleteItem';

const TOP_LIMIT = 5;

const byUpdatedDate = (a: Item, b: Item) => {
    if (a.updated < b.updated) {
        return 1;
    }
    if (a.updated > b.updated) {
        return -1;
    }
    return 0;
};

export const getTopCohorts = (cohorts: CohortMeta[]) =>
    cohorts.slice().sort(byUpdatedDate).slice(0, TOP_LIMIT);

export const getTopContacts = (contacts: ContactsCache) =>
    contacts.list
        .filter(({ phone }) => !isNil(phone))
        .sort(byUpdatedDate)
        .slice(0, TOP_LIMIT);
