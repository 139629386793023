import { useEffect } from 'preact/hooks';

const enabled = import.meta.env.VITE_FF_ASSISTANT_INSTRUCTION === 'true';

const KEY = 'assistant_instruction_enabled';

export const useAssistantInstructionEnabled = () => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search).get(
            '_assistant_instruction_enabled',
        );
        if (typeof params === 'string') {
            window.localStorage.setItem(KEY, `${params !== 'false'}`);
        }
    }, []);

    return window.localStorage.getItem(KEY) === 'true' || enabled;
};
