import { CohortsDropdownOption } from './CohortsDropdownOption';
import { CohortMeta, Profile } from '../../../api/types';
import { OptionBase } from '../../../elements/AdvancedSelect';

type Handler = () => void;

type CohortOption = OptionBase & {
    count?: number;
    defaultOption?: boolean;
    onRename?: Handler;
    onDelete?: Handler;
    onReset?: Handler;
};

const getDefaultOptions = (): CohortOption[] => {
    return [
        {
            id: '',
            label: 'All contacts',
            defaultOption: true,
        },
    ];
};

export const getCohortsDropdownOptions = (
    cohorts: CohortMeta[],
    me: Profile | undefined,
    onRename: (cohort: CohortMeta) => void,
    onDelete: (cohort: CohortMeta) => void,
    reset: Handler,
): CohortOption[] => {
    const options = cohorts.map<CohortOption>((cohort) => {
        const isMyCohort = me && cohort.userId === me.id;
        return {
            id: cohort.id.toString(),
            label: cohort.name,
            count: cohort.contactsCount,
            onRename: isMyCohort ? () => onRename(cohort) : undefined,
            onDelete: isMyCohort ? () => onDelete(cohort) : undefined,
            onReset: reset,
        };
    });

    return getDefaultOptions().concat(options);
};

export const renderCohortOption = (
    option: CohortOption,
    selected: boolean,
    collapse: Handler,
) => (
    <CohortsDropdownOption
        key={option.id}
        id={Number(option.id)}
        label={option.label}
        count={option.count}
        selected={selected}
        defaultOption={option.defaultOption}
        onRename={option.onRename}
        onRemove={option.onDelete}
        onReset={option.onReset}
        collapse={collapse}
    />
);
