import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SxProps,
    Tooltip,
} from '@mui/material';
import { useKnowledgeBaseEnabled } from '../../../../helpers/use-knowledge-base-enabled';
import { AiAssistantIcon } from '../../../ConversationRow/Assistant/AiAssistantIcon';
import { useEffect, useState } from 'preact/hooks';
import { useAskQuestion } from '../../../../main/settings/KnowledgeBase/source.query';
import { UUID } from '../../../../types/uuid';
import { GenerateText } from './CGenerateText';
import { Power } from './CPower';
import {
    useArchiveConversation,
    useGetConversationData,
} from '../../../../queries/conversations';
import { DeleteIcon } from '../../../../icons/common/DeleteIcon';
import { useClearAssistant } from '../../../ConversationRow/use-message.query';
import { useTrack } from '../../../../contexts/analytics';
import { AnalyticsEventName } from '../../../../types/AnalyticsEventNames';
import { JSXInternal } from 'preact/src/jsx';
import { insertText } from '../../utils';
import { CustomEditor } from '../../slate';

const square16: SxProps = {
    width: 16,
    height: 16,
};

export const AssistantMenu = ({
    conversationId,
    editor,
    disabled = false,
}: {
    conversationId: UUID;
    disabled?: boolean;
    editor: CustomEditor;
}) => {
    const track = useTrack();
    const isEnabled = useKnowledgeBaseEnabled();
    const clearance = useClearAssistant();
    const archiver = useArchiveConversation();
    const ask = useAskQuestion('toolbar');
    const conversation = useGetConversationData(conversationId);
    const [isAssistDialogOpen, setIsAssistDialogOpen] =
        useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (
        event: JSXInternal.TargetedMouseEvent<HTMLElement>,
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (ask.isSuccess) {
            insertText(editor, ask.data, true);
            handleClose();
        }
    }, [ask.isSuccess]);

    useEffect(() => {
        if (open) {
            track(AnalyticsEventName.ASSISTANT_MESSAGE_FORM_MENU_OPENED);
        }
    }, [open]);

    if (!isEnabled || !conversation) {
        return null;
    }

    return (
        <>
            <Tooltip title="Assistant" arrow placement="top">
                <IconButton
                    disabled={disabled}
                    id="conversation-ai-control-button"
                    aria-controls={
                        open ? 'conversation-ai-control-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    sx={{
                        borderRadius: 1,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: conversation.assistantDisabled
                            ? 'warning.main'
                            : 'transparent',
                        transition: (theme) =>
                            theme.transitions.create(
                                ['borderColor', 'color', 'bgcolor'],
                                {
                                    duration:
                                        theme.transitions.duration.standard,
                                },
                            ),
                        p: 0.5,
                        '&:hover': {
                            color: 'secondary.main',
                            bgcolor: 'secondary.hover',
                        },
                        svg: square16,
                    }}
                >
                    <AiAssistantIcon sx={square16} />
                </IconButton>
            </Tooltip>

            <Menu
                id="conversation-ai-control"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'conversation-ai-control-button',
                }}
                dense
            >
                <MenuItem
                    disabled={ask.isPending}
                    onClick={() => {
                        ask.mutate({ conversationId });
                    }}
                >
                    {ask.isPending ? (
                        <CircularProgress size={16} sx={{ mr: 5 }} />
                    ) : (
                        <ListItemIcon>
                            <GenerateText />
                        </ListItemIcon>
                    )}

                    <ListItemText>Generate answer with AI</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={archiver.isPending}
                    onClick={() => {
                        const assistantDisabled =
                            !conversation.assistantDisabled;
                        archiver.mutate({
                            id: conversationId,
                            assistantDisabled,
                        });
                        if (assistantDisabled) {
                            setIsAssistDialogOpen(true);
                        }
                    }}
                >
                    {archiver.isPending ? (
                        <CircularProgress size={16} sx={{ mr: 5 }} />
                    ) : (
                        <ListItemIcon>
                            <Power />
                        </ListItemIcon>
                    )}
                    <ListItemText>
                        {conversation.assistantDisabled
                            ? 'Enable '
                            : 'Disable '}
                        for conversation
                    </ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                open={isAssistDialogOpen}
                onClose={() => setIsAssistDialogOpen(false)}
            >
                <DialogTitle>
                    Would you like to remove scheduled assistant messages for
                    this conversation?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() =>
                            clearance
                                .mutateAsync(conversationId)
                                .finally(() => setIsAssistDialogOpen(false))
                        }
                        disabled={clearance.isPending}
                        startIcon={
                            clearance.isPending ? (
                                <CircularProgress size={20} color="error" />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        variant="outlined"
                        color="error"
                    >
                        Delete
                    </Button>
                    <Button
                        disabled={clearance.isPending}
                        onClick={() => {
                            setIsAssistDialogOpen(false);
                            track(
                                AnalyticsEventName.ASSISTANT_CONVERSATION_CLEARANCE_CANCELED,
                            );
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
