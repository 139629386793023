import { ComponentChildren } from 'preact';
import { forwardRef } from 'preact/compat';
import { JSXInternal } from 'preact/src/jsx';
import cc from 'classcat';
import styles from './Droplist.module.scss';

interface DroplistProps {
    children?: ComponentChildren;
    style?: JSXInternal.CSSProperties;
    className?: string;
}

export const Droplist = forwardRef<HTMLDivElement, DroplistProps>(
    (props, ref) => (
        <div
            className={cc([styles['root'], props.className])}
            style={props.style}
            ref={ref}
        >
            {props.children}
        </div>
    ),
);
