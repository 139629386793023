import { useState } from 'preact/hooks';
import { PropsWithChildren, createContext } from 'preact/compat';

interface MessageUpdaterContextValue {
    message: string | undefined;
    setMessage: (message: string | undefined) => void;
}

export const MessageUpdateContext = createContext<MessageUpdaterContextValue>({
    message: undefined,
    setMessage: () => {},
});

export const MessageUpdateProvider = ({ children }: PropsWithChildren) => {
    const [message, setMessage] = useState<string | undefined>(undefined);

    return (
        <MessageUpdateContext.Provider value={{ message, setMessage }}>
            {children}
        </MessageUpdateContext.Provider>
    );
};
