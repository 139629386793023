import {
    Box,
    Button,
    Collapse,
    FormHelperText,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect } from 'preact/hooks';
import { useMeQueryData } from '../../../queries/user';
import { useUpdateTeam } from '../../../queries/team';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';

type FormData = {
    instruction?: string;
};

export const AssistantInstruction = () => {
    const me = useMeQueryData();
    const update = useUpdateTeam();

    const form = useForm<FormData>({
        mode: 'onChange',
        resolver: yupResolver(
            Yup.object({
                instruction: Yup.string().max(4000).optional(),
            }),
        ),
        defaultValues: {
            instruction: me?.activeTeam?.assistantInstruction || '',
        },
    });

    useEffect(() => {
        const instruction = me?.activeTeam?.assistantInstruction || '';
        form.reset({
            instruction,
        });
    }, [me?.activeTeam.assistantInstruction]);

    const onValid = (data: FormData) => {
        update.mutate({
            assistantInstruction: data.instruction?.trim() || '',
        });
    };

    return (
        <Box display="flex" width={1} flexDirection="column" gap={3}>
            <Stack>
                <Typography
                    variant="body3"
                    component="label"
                    htmlFor="ai-assistant-instruction-input"
                    id="ai-assistant-instruction-label"
                    fontWeight={500}
                >
                    Assistant instruction
                </Typography>
                <Typography
                    variant="body4"
                    component="div"
                    color="custom.gray.super"
                    mt={1}
                >
                    Customize your AI assistant with additional instructions
                </Typography>
            </Stack>
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'flex-end',
                }}
                onSubmit={form.handleSubmit(onValid)}
            >
                <Controller
                    name="instruction"
                    control={form.control}
                    render={({ field }) => (
                        <TextField
                            disabled={update.isPending}
                            error={
                                update.isError ||
                                !!form.formState.errors.instruction
                            }
                            id="ai-assistant-instruction-input"
                            placeholder="You are helpful customer service assistant"
                            sx={{ width: 1 }}
                            maxRows={2}
                            multiline
                            {...field}
                        />
                    )}
                />
                <Collapse in={!!form.formState.errors.instruction?.message}>
                    <FormHelperText error sx={{ mr: 0, textAlign: 'right' }}>
                        {form.formState.errors.instruction?.message}
                    </FormHelperText>
                </Collapse>
                <Button
                    disabled={update.isPending}
                    sx={{ minWidth: 140 }}
                    type="submit"
                    size="small"
                >
                    {update.isPending ? <ButtonDotLoader /> : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};
