import { CSSProperties, memo } from 'preact/compat';
import cc from 'classcat';
import type { CountryCode } from 'libphonenumber-js/types';
import styles from './ContactsTable.module.scss';
import { DropContent } from './DropContent';
import type { ColSizes } from './utils';
import Avatar from '../Avatar';
import { TableRow, TableCell } from '../Table';
import Icon from '../../icons/Icon';
import Badge from '../../elements/Badge';
import CheckBox from '../../elements/CheckBox/CheckBox';
import { Contact } from '../../api/types';
import { getContactName } from '../../utils/contacts';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { useCohortsByContactsQueryData } from '../../queries/cohorts';
import { UUID } from '../../types/uuid';

export interface ItemData {
    sizes: ColSizes;
    selected: Set<UUID>;
    contacts: Contact[];
    countryCode?: CountryCode;
    full?: boolean;
    onContactSelect: (id: UUID) => void;
    onContactClick: (id: UUID) => void;
    activeContactId: UUID;
}

interface ContactsTableRowProps {
    data: ItemData;
    index: number;
    // This style prop is used by react-window lib.
    style?: CSSProperties;
}

export const ContactsTableRow = memo((props: ContactsTableRowProps) => {
    const cohortsByContacts = useCohortsByContactsQueryData();

    const contact = props.data.contacts[props.index];

    if (!contact) {
        // Just add a dummy row. It's used in case if some rows are selected
        // and the black toolbar is shown on the bottom of the table.
        // This approach helps to avoid the overlapping of the toolbar and the last row
        return (
            <TableRow style={props.style}>
                <TableCell
                    width={props.data.sizes.name}
                    className={styles['name-cell']}
                />
                <TableCell
                    width={props.data.sizes.cohort}
                    className={styles['cohort-cell']}
                />
                <TableCell
                    width={props.data.sizes.phone}
                    className={styles['phone-cell']}
                />
                {props.data.full && (
                    <TableCell
                        width={props.data.sizes.email}
                        className={styles['email-cell']}
                    />
                )}
            </TableRow>
        );
    }
    const isSelected = props.data.selected.has(contact.id);
    const contactCohorts = cohortsByContacts[contact.id];

    return (
        <TableRow
            style={props.style}
            className={cc([
                styles['contacts-table-row'],
                {
                    [styles['contacts-table-row_selected']]:
                        isSelected || props.data.activeContactId === contact.id,
                },
            ])}
            onClick={(e) => {
                if (e.detail === 2) {
                    props.data.onContactSelect(contact.id);
                }
                props.data.onContactClick(contact.id);
            }}
        >
            <TableCell
                width={props.data.sizes.name}
                className={styles['name-cell']}
            >
                <CheckBox
                    checked={isSelected}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.data.onContactSelect(contact.id);
                    }}
                />

                <Avatar
                    size="medium"
                    imageUrl={contact.avatarURL}
                    username={contact.name}
                />
                <span className={styles['contacts-table-row__name']}>
                    {getContactName(contact)}
                </span>

                {contact.optOut && (
                    <Badge
                        color="#E1655A"
                        className={styles['contacts-table-row__badge']}
                    >
                        <Icon size="16px" name="opt-out" />
                        <span>Opt-out</span>
                    </Badge>
                )}
            </TableCell>

            <TableCell
                className={cc([
                    styles['contacts-table-row__cohort-cell'],
                    styles['cohort-cell'],
                ])}
                width={props.data.sizes.cohort}
            >
                {contactCohorts && contactCohorts.length > 0 && (
                    <>
                        <span
                            className={
                                styles['contacts-table-row__cohort-name']
                            }
                        >
                            {contactCohorts[0].name}
                        </span>
                        {contactCohorts.length > 1 && (
                            <DropContent options={contactCohorts.slice(1)} />
                        )}
                    </>
                )}
            </TableCell>

            <TableCell
                width={props.data.sizes.phone}
                className={styles['phone-cell']}
            >
                <span>
                    {formatPhoneNumber(contact.phone, props.data.countryCode)}
                </span>
            </TableCell>

            {props.data.full && (
                <TableCell
                    width={props.data.sizes.email}
                    className={styles['email-cell']}
                >
                    <span>{contact.email}</span>
                </TableCell>
            )}
        </TableRow>
    );
});
