import cc from 'classcat';
import styles from './ToggleSwitch.module.scss';

type ToggleSwitchProps = {
    onToggle: () => void;
    isChecked: boolean;
    disabled?: boolean;
    className?: string;
};

export const ToggleSwitch = (props: ToggleSwitchProps) => {
    return (
        <label
            className={
                props.className
                    ? cc([styles['switch'], props.className])
                    : styles['switch']
            }
        >
            <input
                type="checkbox"
                checked={props.isChecked}
                disabled={props.disabled}
                onChange={props.onToggle}
            />
            <span className={styles['slider']}></span>
        </label>
    );
};
