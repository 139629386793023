import styles from './CreateCohortStep.module.scss';
import Input from '../../elements/Inputs/Input';
import Button from '../../elements/Buttons';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useCohortCreateQuery } from '../../queries/cohorts';
import { useModalContext } from '../modal/reducer';
import { TargetedEvent } from 'preact/compat';

export const CreateCohortStep = () => {
    const inputRef = useRef<HTMLInputElement | null>();
    const [name, setName] = useState<string>('');
    const { dispatch } = useModalContext();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const { mutateAsync, isPending } = useCohortCreateQuery();

    function createCohort(e: TargetedEvent<HTMLFormElement>) {
        e.preventDefault();
        if (e.currentTarget.reportValidity()) {
            const name = e.currentTarget.cohort_name.value;
            if (name.length > 0) {
                mutateAsync({ name }).then(() =>
                    dispatch({ type: 'REMOVE', payload: {} }),
                );
            }
        }
    }
    return (
        <div className={styles.container}>
            <p>
                Create a cohort where you can <b>group contacts together</b>.
                This cohort can be used in workflows and analytics reports.
            </p>
            <form onSubmit={createCohort}>
                <label>Create Cohort</label>
                <Input
                    value={name}
                    onChange={setName}
                    name="cohort_name"
                    fullWidth={true}
                    type="text"
                    placeholder="Cohort Name"
                    customRef={inputRef}
                />
                <br />
                <br />
                <Button
                    disabled={isPending || name.length < 1}
                    fullWidth={true}
                >
                    Create
                </Button>
            </form>
        </div>
    );
};
