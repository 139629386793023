import { Box, Divider, Skeleton } from '@mui/material';
import { CampaignHeader } from '../CampaignHeader';
import {
    useCampaignMessage,
    useCampaignMessageConversations,
} from '../use-campaign-messages';
import { Message } from './Message';
import { MessageSkeleton } from '../MessageSkeleton';
import { pluralize } from '../pluralize';
import { useMemo } from 'preact/hooks';
import { ConversationItem } from './ConversationItem';
import { last, range } from 'lodash';
import { Virtuoso } from 'react-virtuoso';

type Props = {
    campaignId: number;
    campaignMessageId: number;
};

export const CampaignMessage = ({ campaignId, campaignMessageId }: Props) => {
    const { data: message } = useCampaignMessage(campaignMessageId);
    const {
        data: result,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        isLoading,
    } = useCampaignMessageConversations(campaignMessageId);

    const prevUrl = useMemo(
        () => window.location.pathname.split('/').slice(0, 5).join('/'),
        [window.location.pathname],
    );

    const total = useMemo(
        () => last(result?.pages)?.total || 0,
        [result?.pages],
    );
    const conversations = useMemo(
        () => result?.pages.flatMap(({ data }) => data) ?? [],
        [result?.pages],
    );

    return (
        <Box
            sx={{
                flex: 1,
                display: 'grid',
                gridTemplateRows: '61px 1fr',
                maxHeight: '100vh',
            }}
        >
            <CampaignHeader prevUrl={prevUrl} campaignId={campaignId} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 6,
                    height: 'calc(100vh - 61px)',
                }}
            >
                {message ? (
                    <Message sx={{ my: 4 }} message={message} />
                ) : (
                    <MessageSkeleton />
                )}
                <Divider textAlign="center">
                    {!isLoading ? (
                        pluralize(
                            total || message?.sentCount || 0,
                            'conversation',
                            'conversations',
                            'No conversations',
                        )
                    ) : (
                        <Skeleton width={120} heigh={12} />
                    )}
                </Divider>
                <Virtuoso
                    increaseViewportBy={450}
                    overscan={450}
                    data={conversations}
                    endReached={() => {
                        if (!isFetchingNextPage && hasNextPage) {
                            fetchNextPage();
                        }
                    }}
                    itemContent={(_, conversation) => (
                        <ConversationItem
                            key={conversation.id}
                            conversation={conversation}
                            sx={{ width: 1 }}
                        />
                    )}
                    components={{
                        Footer: isFetchingNextPage
                            ? () =>
                                  /* todo: improve skeleton */
                                  range(3).map((key) => (
                                      <Skeleton
                                          key={key}
                                          width={320}
                                          height={40}
                                      />
                                  ))
                            : undefined,
                    }}
                />
            </Box>
        </Box>
    );
};
