import { useKeywords } from '../../main/settings/KnowledgeBase/Keywords/keywords.query';

export const useMessageStoppedBy = (message: string): string => {
    const { data: phrases, isFetched } = useKeywords();

    if (!isFetched || !phrases?.length) {
        return '';
    }

    return (
        phrases.find(({ phrase }) =>
            message.toLowerCase().includes(phrase.toLowerCase()),
        )?.phrase || ''
    );
};
