import { route } from 'preact-router';
import { useCallback } from 'preact/hooks';
import cc from 'classcat';
import { Icon } from '../../icons/Icon';
import { useContactCreateQuery } from '../../queries/contacts';
import styles from './ContactsHeader.module.scss';
import { Button } from '@mui/material';

interface ContactsHeaderProps {
    className?: string;
    contactsNumber?: number;
}

export const ContactsHeader = (props: ContactsHeaderProps) => {
    const { mutateAsync: createContact } = useContactCreateQuery();

    const createContactHandler = useCallback(
        () =>
            createContact({}).then(({ id }) => {
                route(`/contacts/${id}`);
            }),
        [],
    );

    return (
        <header className={cc([styles['root'], props.className])}>
            <div className={styles['root__block']}>
                <span className={styles['root__heading']}>Contacts</span>
                <span className={styles['root__badge']}>
                    {props.contactsNumber || 0}
                </span>
            </div>

            <div className={styles['root__block']}>
                <Button
                    startIcon={
                        <Icon
                            className={styles['root__add-icon']}
                            name="plus"
                        />
                    }
                    onClick={createContactHandler}
                >
                    Add contact
                </Button>
            </div>
        </header>
    );
};
