import { useCallback, useMemo } from 'preact/hooks';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    CountryCode,
    isValidPhoneNumber,
    parsePhoneNumber,
} from 'libphonenumber-js';
import styles from './SearchResults.module.scss';
import { NoResults } from './NoResults';
import { useNewConversationContext } from '../NewConversationProvider';
import Loading from '../../Loading/Loading';
import {
    Item,
    ListItem,
    Splitter,
} from '../../ConversationAutocomplete/ConversationAutocompleteItem';
import { ConversationAutocompleteListItem } from '../../ConversationAutocomplete/ConversationAutocompleteListItem';
import { useCohortsQuery } from '../../../queries/cohorts';
import { useContactsSearchQuery } from '../../../queries/contacts';
import { useMeQueryData } from '../../../queries/user';
import { CohortMeta, Contact, Conversation } from '../../../api/types';
import { isSearchActive } from '../../../main/contacts/utils';
import { getCohortContactIds } from '../../../utils/cohorts';

const getItemHeight =
    (contacts: Contact[], cohorts: CohortMeta[]) => (index: number) => {
        let height = 36;
        if (index === 0) {
            height = 40;
        } else if (
            contacts.length > 0 &&
            cohorts.length > 0 &&
            index === contacts.length + 1
        ) {
            height = 57;
        }

        return height;
    };

export interface SearchResultsProps {
    existingConversation: Conversation | null;
}

export const SearchResults = (props: SearchResultsProps) => {
    const { state, actions } = useNewConversationContext();

    const profile = useMeQueryData();
    const cohorts = useCohortsQuery();

    const searchEnabled = isSearchActive(
        state.quickSearchTerm,
        state.filters,
        false,
    );

    const { data: foundContacts = [], isFetching: isSearchFetching } =
        useContactsSearchQuery(
            state.quickSearchTerm,
            state.filters,
            undefined,
            searchEnabled,
            true,
        );

    const foundCohorts = useMemo(
        () =>
            cohorts.filter((c) =>
                c.name
                    .toLowerCase()
                    .includes(state.quickSearchTerm.toLowerCase()),
            ),
        [cohorts, state.quickSearchTerm],
    );

    let items: ListItem[] = [];

    if (props.existingConversation) {
        items = items.concat(
            {
                type: 'splitter',
                name: 'Conversation',
                count: 1,
            } as Splitter,
            props.existingConversation,
        );
    }

    if (foundContacts.length > 0) {
        items = items.concat(
            {
                type: 'splitter',
                name: 'Contacts',
                count: foundContacts.length,
                action: () =>
                    actions.setSelectedContacts(foundContacts.map((c) => c.id)),
            } as Splitter,
            ...foundContacts,
        );
    }

    if (foundCohorts.length > 0) {
        items = items.concat(
            { type: 'splitter', name: 'Cohorts', count: foundCohorts.length },
            ...foundCohorts,
        );
    }

    const validPhoneNumber = useMemo(
        () =>
            isValidPhoneNumber(
                state.quickSearchTerm,
                profile?.activeTeam.countryCode as CountryCode,
            ),
        [state.quickSearchTerm, profile?.activeTeam.countryCode],
    );

    if (
        foundContacts.length === 0 &&
        foundCohorts.length === 0 &&
        validPhoneNumber
    ) {
        const phoneNumber = parsePhoneNumber(
            state.quickSearchTerm,
            profile?.activeTeam.countryCode as CountryCode,
        ).number;

        items = [
            { type: 'splitter', name: 'Contacts', count: 1 },
            {
                id: phoneNumber,
                name: phoneNumber,
                phone: phoneNumber,
                data: {},
                created: new Date().toISOString(),
                updated: new Date().toISOString(),
                public: true,
            },
        ];
    }

    const handleContactSelection = useCallback(
        (item: Item) => {
            const contact = item as Contact;
            const newIds = state.selectedContactIds.includes(contact.id)
                ? state.selectedContactIds.filter((id) => id !== contact.id)
                : state.selectedContactIds.concat(contact.id);
            actions.setSelectedContacts(newIds);

            if (newIds.length > 10) {
                actions.setCampaignMode();
            }
        },
        [state.selectedContactIds],
    );

    const handleCohortSelection = useCallback(
        (item: Item) => {
            if (!state.cohortId) {
                actions.setCohort(null);
                actions.setCohortId(item.id);
                return;
            }

            if (state.cohortId && state.cohort) {
                const selectedCohortContacts = getCohortContactIds(
                    state.cohort,
                );

                const filteredContactIds = state.selectedContactIds.filter(
                    (sc) => !selectedCohortContacts.includes(sc),
                );
                actions.setSelectedContacts(filteredContactIds);

                if (state.cohortId === item.id) {
                    actions.setCohort(null);
                    actions.setCohortId(null);
                    return;
                }

                if (state.cohortId !== item.id) {
                    actions.setCohort(null);
                    actions.setCohortId(item.id);
                    return;
                }
            }
        },
        [state.cohortId, state.cohort, state.selectedContactIds],
    );

    const hasResults = useMemo(
        () =>
            foundContacts.length > 0 ||
            foundCohorts.length > 0 ||
            validPhoneNumber,
        [foundContacts, foundCohorts],
    );

    if (searchEnabled && !isSearchFetching && !hasResults) {
        return <NoResults title="Keep going!" text="Magic is brewing" />;
    }

    return (
        <div className={styles['root__results-container']}>
            {isSearchFetching && <Loading />}
            {items.length > 0 && !isSearchFetching && (
                <AutoSizer className={styles['root__autosizer']}>
                    {({ height }: { height: number }) => (
                        <List
                            innerElementType="ul"
                            height={height}
                            className={styles['root__outer']}
                            itemCount={items.length}
                            itemData={{
                                countryCode: profile?.activeTeam.countryCode,
                                list: items,
                                selectedContactIds: state.selectedContactIds,
                                onClick: (selected: Item) => {
                                    const isContact =
                                        typeof selected.id === 'string';
                                    if (isContact) {
                                        handleContactSelection(selected);
                                    } else {
                                        handleCohortSelection(selected);
                                    }
                                },
                            }}
                            itemSize={getItemHeight(
                                foundContacts,
                                foundCohorts,
                            )}
                            width="100%"
                        >
                            {ConversationAutocompleteListItem}
                        </List>
                    )}
                </AutoSizer>
            )}
        </div>
    );
};
