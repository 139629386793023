import {
    COHORT_RECALCULATED,
    handleCohortRecalculatedEvent,
} from './cohort-recalculated-event';
import { handleNewMessageEvent, NEW_MESSAGE_EVENT } from './new-message-event';
import {
    CONVERSATION_CHANGE_EVENT,
    handleConversationChangeEvent,
} from './new-conversation-event';
import {
    handleNewDiscussionCommentEvent,
    NEW_DISCUSSION_COMMENT_MESSAGE,
} from './new-discussion-comment-event';
import {
    CONTACT_CREATED_EVENT,
    handleContactCreatedEvent,
} from './new-contact-event';
import {
    CONTACT_UPDATED_EVENT,
    handleContactUpdatedEvent,
} from './contact-updated-event';
import {
    handleNewActivityEvent,
    NEW_ACTIVITY_EVENT,
} from './new-activity-event';
import {
    CONVERSATION_UPDATED_EVENT,
    ConversationUpdateHandler,
    handleConversationUpdated,
} from './event-handlers/handle-conversation.updated.event';

/** @deprecated please start to write typed handlers */
type Mapping = {
    [CONVERSATION_UPDATED_EVENT]: ConversationUpdateHandler;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (payload: any) => void;
};

/** @deprecated please start to write typed handlers */
export const EVENT_HANDLERS_MAP: Mapping = {
    [NEW_MESSAGE_EVENT]: handleNewMessageEvent,
    [CONVERSATION_CHANGE_EVENT]: handleConversationChangeEvent,
    [COHORT_RECALCULATED]: handleCohortRecalculatedEvent,
    [NEW_DISCUSSION_COMMENT_MESSAGE]: handleNewDiscussionCommentEvent,
    [CONTACT_CREATED_EVENT]: handleContactCreatedEvent,
    [CONTACT_UPDATED_EVENT]: handleContactUpdatedEvent,
    [NEW_ACTIVITY_EVENT]: handleNewActivityEvent,
    [CONVERSATION_UPDATED_EVENT]: handleConversationUpdated,
};
