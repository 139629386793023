import { InboxProvider } from '../api/types';

export enum AnalyticsEventName {
    ASSISTANT_ASKED = 'assistant_asked',
    ASSISTANT_MESSAGE_FORM_MENU_OPENED = 'assistant_message_form_menu_opened',
    ASSISTANT_CONVERSATION_CLEARANCE_CANCELED = 'assistant_conversation_clearance_canceled',
    ASSISTANT_CLEARED = 'assistant_cleared',
    ASSISTANT_DELAY_CHANGED = 'assistant_delay_changed',
    ASSISTANT_INBOX_DISABLED = 'assistant_inbox_disabled',
    ASSISTANT_INBOX_ENABLED = 'assistant_inbox_enabled',
    ASSISTANT_KEYWORD_ADDED = 'assistant_keyword_added',
    ASSISTANT_KEYWORD_DELETED = 'assistant_keyword_deleted',
    ASSISTANT_MESSAGE_EDIT = 'assistant_message_edit',
    ASSISTANT_MESSAGE_REGENERATED = 'assistant_message_regenerated',
    ASSISTANT_MESSAGE_SENT_IMMEDIATELY = 'assistant_message_sent_immediately',
    ASSISTANT_POPOVER_CLICKED = 'assistant_popover_clicked',
    ASSISTANT_SOURCE_ADDED = 'assistant_source_added',
    ASSISTANT_SOURCE_ADD_FAILED = 'assistant_source_add_failed',
    ASSISTANT_SOURCE_DELETED = 'assistant_source_deleted',
    CLICKED_ADD_TEAMS_PHONES = 'clicked_add_teams_phones',
    CLICKED_AUTHENTICATE_TO_MS = 'clicked_authenticate_to_ms',
    CLICKED_BULK_IMPORT_TEAMS_PHONES = 'clicked_bulk_import_teams_phones',
    CLICKED_CREATE_COHORT = 'clicked_create_cohort',
    CLICKED_CREATE_CONVERSATION = 'clicked_new_conversation',
    CLICKED_CREATE_NEW_INBOX = 'clicked_create_new_inbox',
    CLICKED_EXPORT_LOGS = 'clicked_export_logs',
    CLICKED_IMPORT_CONTACTS = 'clicked_import_contacts',
    CLICKED_INVITE_TEAM = 'clicked_invite_team',
    CLICKED_PURCHASE_PLAN = 'clicked_purchase_plan',
    CLICKED_SEND_INVITE = 'clicked_send_invite',
    CLICKED_TOGGLE_FILTER = 'clicked_toggle_filter',
    CONVERSATION_ARCHIVED = 'conversation_archived',
    CONVERSATION_DELETED = 'conversation_deleted',
    INTEGRATION_DISCONNECTED = 'integration_disconnected',
    INTEGRATION_SHARE_CHANGED = 'integration_share_changed',
    INTEGRATION_SYNC_INITIATED = 'integration_sync_initiated',
    MESSAGE_COPIED = 'message_copied',
    MESSAGE_DELETED = 'message_deleted',
    MESSAGE_TIMESTAMP_CHANGED = 'message_timestamp_updated',
    MESSAGE_TIMESTAMP_CHANGE_CANCELED = 'message_timestamp_change_canceled',
    MESSAGE_UPDATED = 'message_updated',
    ONBOARDING_PROVISIONED_PHONE_NUMBER = 'onboarding_provisioned_phone_number',
    ONBOARDING_USER_ADDED_COUNTRY = 'onboarding_user_added_country',
    ONBOARDING_USER_ADDED_STATE = 'onboarding_user_added_state',
    ONBOARDING_USER_IMPORTED_NUMBER = 'onboarding_user_imported_number',
    ONBOARDING_USER_SELECTED_CARRIER = 'onboarding_user_selected_carrier',
    ONBOARDING_USER_SELECTED_NEW_NUMBER = 'onboarding_user_selected_new_number',
    REQUESTED_INTEGRATION = 'requested_integration',
    USER_ADD_EDIT_CONTACT = 'user_add_edit_contact',
    USER_CLICKED_CONVERSATION_SUMMARY = 'user_clicked_conversation_summary',
    USER_CLICKED_PAYMENT_PLAN = 'user_clicked_payment_plan',
    USER_CLICKED_PURCHASE = 'user_clicked_purchase',
    USER_SENT_MESSAGE = 'user_sent_message',
    VERIFICATION_CALL_COMPLETED = 'verification_call_completed',
    VERIFICATION_CUSTOMER_INFO = 'verification_customer_info',
    VERIFICATION_PHONE_LOCATION_TYPE = 'verification_phone_location_type',
    VERIFICATION_PHONE_SIGNATURE = 'verification_phone_signature',
}

export interface BaseEventPayload {
    email: string;
    teamId: string;
    userId: string;
}

export interface OnboardingUserProvisionedPhoneNumber extends BaseEventPayload {
    provider?: InboxProvider;
    phone?: string;
    microsoftTenantId?: string;
}
