import styles from './RectangleFrame.module.scss';

type RectangleFrameProps = {
    children?: JSX.Element;
    variant?: 'dark' | 'light';
};

export const RectangleFrame = (props: RectangleFrameProps) => {
    return (
        <div data-variant={props.variant || 'dark'} className={styles['root']}>
            {props.children}
        </div>
    );
};
