import Match from 'preact-router/match';
import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { navItemActiveStyle, navItemStyle } from './styles';

const NavigationItem = ({
    icon,
    href,
    label,
    path,
    onClick,
}: {
    onClick?: () => void;
    icon: ReactNode;
    href: string;
    path?: string;
    label: string;
}) => (
    <Match path={path || href}>
        {({ matches }: { matches: boolean }) => (
            <Button
                disableRipple
                variant="text"
                onClick={onClick}
                href={href}
                sx={matches ? navItemActiveStyle : navItemStyle}
                startIcon={icon}
            >
                {label}
            </Button>
        )}
    </Match>
);

export default NavigationItem;
