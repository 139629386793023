/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useReducer } from 'preact/compat';
import Analytics from 'analytics';
// @ts-ignore
import segmentPlugin from '@analytics/segment';

// Types
import type { ComponentChildren } from 'preact';
import type { AnalyticsEventName } from '../types/AnalyticsEventNames';
import { useMeQueryData } from '../queries/user';
import { Profile } from '../api/types';

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
        segmentPlugin({
            writeKey: import.meta.env.VITE_SEGMENT_KEY,
        }),
    ],
});

function trackEvent(event: EventAction) {
    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
        analytics.track(event.eventName, event.eventPayload);
    } else {
        console.log('Tracking Disabled', event.eventName, event.eventPayload);
    }
}

function identify(user: Profile) {
    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
        analytics.identify(user.id, {
            email: user.email,
            firstName: user.firstname,
            lastName: user.lastname,
            teamId: user.activeTeam?.id,
            tier: user.activeTeam?.tier,
            phone: user.phone,
            createdAt: user.created,
            avatar: user.avatar?.url,
            username: user.email,
        });
    } else {
        console.log('Identify Disabled');
    }
}

export enum AnalyticsActions {
    TRACK = 'track',
    IDENTIFY = 'identify',
}

type AnalyticsState = {
    lastEvent: any;
};

type EventAction = {
    eventName: AnalyticsEventName | string;
    eventPayload?: any;
};

type AnalyticsDispatch = {
    type?: AnalyticsActions;
    payload?: EventAction;
    user?: Profile;
};
const reducer = (
    state: AnalyticsState,
    action: {
        type?: AnalyticsActions;
        payload?: EventAction;
        user?: Profile;
    },
): AnalyticsState => {
    switch (action.type) {
        case AnalyticsActions.IDENTIFY:
            if (action.user) {
                identify(action.user);
            }
            return state;
        case AnalyticsActions.TRACK:
            if (action.payload) {
                trackEvent(action.payload);
            }
            return {
                ...state,
                lastEvent: action.payload,
            };
        default:
            return state;
    }
};

const initialState: AnalyticsState = {
    lastEvent: undefined,
};

type AnalyticsContextType = {
    state: AnalyticsState;
    dispatch: (action: AnalyticsDispatch) => void;
};
const AnalyticsContext = createContext<AnalyticsContextType>({
    state: initialState,
    dispatch: (_action: AnalyticsDispatch) => null,
});

export const AnalyticsProvider = ({
    children,
}: {
    children: ComponentChildren;
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AnalyticsContext.Provider value={{ state, dispatch }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => useContext(AnalyticsContext);

export const useIdentify = () => {
    const me = useMeQueryData();
    const analytics = useAnalytics();

    return () => {
        analytics.dispatch({
            type: AnalyticsActions.IDENTIFY,
            user: me,
        });
    };
};

export const useTrack = () => {
    const me = useMeQueryData();
    const analytics = useAnalytics();

    return (
        eventName: AnalyticsEventName | string,
        payload: Record<string, string | number> = {},
    ) => {
        analytics.dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventPayload: {
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                    ...payload,
                },
                eventName,
            },
        });
    };
};
