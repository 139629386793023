import { ComponentChildren, VNode } from 'preact';
import styles from './StepShell.module.scss';
import BackButton from '../BackButton';

export interface StepShellProps {
    icon: VNode;
    title: string;
    subtitle: string;
    children: ComponentChildren;
    goBackward?: () => void;
}

export const StepShell = ({
    icon,
    title,
    subtitle,
    children,
    goBackward,
}: StepShellProps) => (
    <div className={styles['root']}>
        {goBackward && <BackButton onClick={goBackward} />}

        <div className={styles['root_inner']}>
            <div className={styles['root__main-icon']}>{icon}</div>

            <h1 className={styles['root__title']}>{title}</h1>

            <p className={styles['root__subtitle']}>{subtitle}</p>
        </div>

        {children}
    </div>
);
