import cc from 'classcat';
import styles from './MSTeamsPhoneNumbers.module.scss';
import { useState } from 'preact/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Icon from '../../../icons/Icon';
import { useBrandQuery } from '../../../queries/settings';
import StepShell from '../StepShell';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';
import { PortDisclaimer } from '../../PhoneVerification/helpers';
import Spacer from '../../../components/Spacer';
import RectangleFrame from '../../../components/RectangleFrame';
import CheckBox from '../../../elements/CheckBox/CheckBox';
import dayjs from 'dayjs';
import FormButton from '../../../elements/FormButton';
import { useBulkCreateMutation } from '../../../queries/inboxes';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';

interface SignatureVerificationStepFormData {
    hasAgreedToTransferPolicy?: boolean;
    hasTeamsCallingPlan?: boolean;
}

export const ConfirmStep = (props: MSTeamsPhoneNumbersProps) => {
    const { control, handleSubmit } =
        useForm<SignatureVerificationStepFormData>();
    const { dispatch } = useAnalytics();

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const { mutateAsync, isPending: createLoading } = useBulkCreateMutation();

    const brand = useBrandQuery();

    const onSubmit: SubmitHandler<
        SignatureVerificationStepFormData
    > = async () => {
        try {
            dispatch({
                type: AnalyticsActions.TRACK,
                payload: {
                    eventName:
                        AnalyticsEventName.CLICKED_BULK_IMPORT_TEAMS_PHONES,
                },
            });
            setLoading(true);
            await mutateAsync({
                phones: props.selectedPhones!,
                activationAt: props.activationDate,
            });
            setLoading(false);
            props.goForward();
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <StepShell
            title="Clerk Chat Inc"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle="Letter of Authorization (LOA)"
        >
            <div className={styles['form-row']}>
                <PortDisclaimer />
            </div>
            <Spacer height={25} width="auto" />
            <form
                className={styles['information-form']}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={styles['form-row']}>
                    <RectangleFrame variant="light">
                        <Controller
                            name="hasAgreedToTransferPolicy"
                            control={control}
                            defaultValue={false}
                            rules={{ required: true, validate: (v) => v }}
                            render={({ field }) => (
                                <CheckBox
                                    checked={field.value}
                                    size="large"
                                    label={
                                        <span>
                                            I agree to the{' '}
                                            <a
                                                href="https://clerk.chat/legal/terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                terms and conditions
                                            </a>
                                        </span>
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </RectangleFrame>
                </div>
                <div className={styles['form-row']}>
                    <RectangleFrame variant="light">
                        <Controller
                            name="hasTeamsCallingPlan"
                            control={control}
                            defaultValue={false}
                            rules={{ required: true, validate: (v) => v }}
                            render={({ field }) => (
                                <CheckBox
                                    checked={field.value}
                                    size="large"
                                    label={
                                        <span>
                                            <b>TO MAINTAIN CALLING</b>, I will
                                            make sure to re-assign Teams Phone
                                            Standard License to my users.{' '}
                                            <a
                                                href="https://www.youtube.com/watch?v=VKpqlGNsZL4"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Not sure? Get help here.
                                            </a>
                                        </span>
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </RectangleFrame>
                </div>
                <div className={styles['form-row__grid']}>
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="user-signature" size="16px" />{' '}
                                Authorized Person
                            </div>
                            <span>
                                {`${brand.data?.contactFirstName} ${brand.data?.contactLastName}`}
                            </span>
                        </div>
                    </RectangleFrame>
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="calendar" size="16px" /> Date
                            </div>
                            <span>
                                {dayjs().format('MMMM D, YYYY - hh:mm a')}
                            </span>
                        </div>
                    </RectangleFrame>
                </div>
                {isError && (
                    <div className={styles['form-row']}>
                        Sorry, something went astray with your request. Please
                        contact support
                    </div>
                )}
                <div
                    className={cc([
                        styles['form-row'],
                        styles['form-row__grid'],
                    ])}
                >
                    <FormButton
                        variant="clear"
                        text="Back"
                        type="button"
                        isLoading={isLoading || createLoading}
                        onClick={() => props.goBack && props.goBack()}
                        disabled={isLoading || createLoading}
                    />
                    <FormButton
                        variant="primary"
                        text="Complete the verification"
                        isLoading={isLoading || createLoading}
                        disabled={isLoading || createLoading}
                    />
                </div>
            </form>
        </StepShell>
    );
};
