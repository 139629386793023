import cc from 'classcat';
import { useCallback, useMemo } from 'preact/hooks';
import { Contact, Conversation } from '../../../api/types';
import { useCohortsQuery } from '../../../queries/cohorts';
import { useContactsQueryData } from '../../../queries/contacts';
import { useMeQueryData } from '../../../queries/user';
import { getCohortContactIds } from '../../../utils/cohorts';
import {
    ConversationAutocompleteItem,
    Item,
} from '../../ConversationAutocomplete/ConversationAutocompleteItem';
import {
    getTopCohorts,
    getTopContacts,
} from '../../ConversationAutocomplete/utils';
import { useNewConversationContext } from '../NewConversationProvider';
import { NoResults } from './NoResults';
import styles from './QuickSuggestions.module.scss';

export interface QuickSuggestionsProps {
    existingConversation: Conversation | null;
}

export const QuickSuggestions = (props: QuickSuggestionsProps) => {
    const { state, actions } = useNewConversationContext();

    const profile = useMeQueryData();
    const cohorts = useCohortsQuery();
    const contacts = useContactsQueryData();

    const topContacts = useMemo(() => getTopContacts(contacts), [contacts]);
    const topCohorts = useMemo(() => getTopCohorts(cohorts), [cohorts]);
    const hasResults = useMemo(
        () => topContacts.length > 0 || topCohorts.length > 0,
        [topContacts, topCohorts],
    );

    const handleContactSelection = useCallback(
        (item: Item) => {
            const contact = item as Contact;
            const newIds = state.selectedContactIds.includes(contact.id)
                ? state.selectedContactIds.filter((id) => id !== contact.id)
                : state.selectedContactIds.concat(contact.id);
            actions.setSelectedContacts(newIds);

            if (newIds.length > 10) {
                actions.setCampaignMode();
            }
        },
        [state.selectedContactIds],
    );

    const handleCohortSelection = useCallback(
        (item: Item) => {
            if (!state.cohortId) {
                actions.setCohort(null);
                actions.setCohortId(item.id);
                return;
            }

            if (state.cohortId && state.cohort) {
                const selectedCohortContacts = getCohortContactIds(
                    state.cohort,
                );

                const filteredContactIds = state.selectedContactIds.filter(
                    (sc) => !selectedCohortContacts.includes(sc),
                );
                actions.setSelectedContacts(filteredContactIds);

                if (state.cohortId === item.id) {
                    actions.setCohort(null);
                    actions.setCohortId(null);
                    return;
                }

                if (state.cohortId !== item.id) {
                    actions.setCohort(null);
                    actions.setCohortId(item.id);
                    return;
                }
            }
        },
        [state.cohortId, state.cohort, state.selectedContactIds],
    );

    if (!hasResults) {
        return (
            <NoResults
                text={
                    contacts.count() === 0
                        ? 'To send a message'
                        : "It looks like you don't have any recent contacts or cohorts. Use search or apply filters to view the information."
                }
                title={
                    contacts.count() === 0
                        ? 'Enter a phone number above'
                        : 'No contacts or cohorts found'
                }
            />
        );
    }

    return (
        <>
            {props.existingConversation && (
                <div
                    className={cc([
                        styles['root__section'],
                        {
                            [styles['root__section_delimiter']]:
                                cohorts.length > 0,
                        },
                    ])}
                >
                    <h6 className={styles['root__heading']}>
                        Existing Conversations
                    </h6>

                    <ul className={styles['root__list']}>
                        <ConversationAutocompleteItem
                            item={props.existingConversation}
                            countryCode={profile?.activeTeam.countryCode}
                            onClick={handleContactSelection}
                        />
                    </ul>
                </div>
            )}
            {topContacts.length > 0 && (
                <div
                    className={cc([
                        styles['root__section'],
                        {
                            [styles['root__section_delimiter']]:
                                cohorts.length > 0,
                        },
                    ])}
                >
                    <h6 className={styles['root__heading']}>Contacts</h6>

                    <ul className={styles['root__list']}>
                        {topContacts.map((contact) => (
                            <ConversationAutocompleteItem
                                key={contact.id}
                                item={contact}
                                countryCode={profile?.activeTeam.countryCode}
                                onClick={handleContactSelection}
                            />
                        ))}
                    </ul>
                </div>
            )}

            {topCohorts.length > 0 && (
                <div className={styles['root__section']}>
                    <h6 className={styles['root__heading']}>Cohorts</h6>
                    <ul className={styles['root__list']}>
                        {topCohorts.map((cohort) => (
                            <ConversationAutocompleteItem
                                key={cohort.id}
                                item={cohort}
                                countryCode={profile?.activeTeam.countryCode}
                                onClick={handleCohortSelection}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};
