import { Components, outlinedInputClasses, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
    defaultProps: {
        notched: false,
    },
    styleOverrides: {
        input: {
            padding: '10px 12px',
        },
        inputSizeSmall: ({ theme }) => ({
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        }),
        inputAdornedStart: {
            paddingLeft: 0,
        },
        inputAdornedEnd: {
            paddingRight: 0,
        },
        adornedStart: {
            paddingLeft: 12,
        },
        adornedEnd: {
            paddingRight: 12,
        },
        root: ({ theme }) => ({
            boxShadow: '0 1px 2px 0 rgba(31, 41, 54, 0.08)',
            padding: 0,

            '& .MuiInputBase-inputMultiline': {
                minHeight: 68,
                alignSelf: 'stretch',
            },

            '&.Mui-disabled': {
                color: theme.palette.custom.gray.disabledText,
                backgroundColor: theme.palette.custom.gray.disabledBackground,

                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.custom.gray.disabledBackground,
                },
            },

            '&:not(.Mui-error):not(.Mui-disabled):hover, &:not(.Mui-error).Mui-focused':
                {
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor:
                            theme.palette.custom.secondary.blue.inputHover,
                    },
                },

            '&.MuiInputBase-colorBlue:hover': {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: theme.palette.info.main,
                },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 1,
            },

            '&.Mui-focused': {
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                    borderWidth: 1,
                },
            },

            '&:not(.Mui-error).Mui-focused': {
                boxShadow: '0 0 0 3px rgba(63, 93, 244, 0.12)',
            },
        }),
    },
};
