import styles from './BackButton.module.scss';
import Button from '../../../elements/Buttons';
import BackIcon from '../../../assets/icons/icons-16/purple/back.svg?react';

export interface BackButtonProps {
    onClick: () => void;
}

export const BackButton = ({ onClick }: BackButtonProps) => (
    <Button
        className={styles['back-btn']}
        type="default"
        onClick={onClick}
        icon={<BackIcon />}
        slim
    >
        Back
    </Button>
);
