import { Box, IconButton, Stack, Typography } from '@mui/material';
import { CloseIcon } from '../../../../icons/common/CCloseIcon';
import { prettySize } from '../../../../main/settings/KnowledgeBase/SourceForm/pretty-bytes';
import { Attachment } from '../../../../api/types';

import { MimeTypeIcon } from '../../../../icons/common/files/MimeTypeIcon';
import { getSquare } from '../../../../theme/style.helpers';

type Props = {
    attachment: Attachment;
    onRemove: (a: Attachment) => void;
};

export const AttachmentItem = ({ attachment, onRemove }: Props) => (
    <Box
        sx={{
            display: 'flex',
            color: attachment.size > 25_000_000 ? 'error.main' : undefined,
            position: 'relative',
            gap: 2,
            p: 2,
            borderRadius: 1.5,
            border: '1px solid ',
            borderColor: 'divider',
            '&:hover': { '.MuiIconButton-root': { opacity: 1 } },
        }}
    >
        {attachment.type.includes('image/') ? (
            <Box sx={getSquare(32)}>
                <Box
                    component="img"
                    src={attachment.url}
                    alt={attachment.name}
                    sx={{
                        ...getSquare(32),
                        maxWidth: 1,
                        maxHeight: 1,
                        objectFit: 'cover',
                        borderRadius: 1,
                    }}
                />
            </Box>
        ) : (
            <MimeTypeIcon type={attachment.type} sx={getSquare(32)} />
        )}
        <Stack justifyContent="space-between">
            <Box
                sx={{
                    maxWidth: 149,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                typography="body4"
            >
                {attachment.name}
            </Box>
            <Typography variant="body5">
                {prettySize(attachment.size)}
            </Typography>
        </Stack>
        <IconButton
            onClick={() => onRemove(attachment)}
            size="small"
            sx={{
                position: 'absolute',
                bgcolor: '#EFF1F6',
                transition: 'opacity boxShadow .2s',
                color: 'custom.gray.super',
                border: '1px solid white',
                opacity: 0,
                top: -8,
                right: -8,
                width: 20,
                height: 20,
                '&:hover': {
                    bgcolor: '#EFF1F6',
                    boxShadow: (theme) => theme.shadows[1],
                },
            }}
            aria-label="remove"
        >
            <CloseIcon sx={getSquare(13)} />
        </IconButton>
    </Box>
);
