import cc from 'classcat';
import { ComponentChildren } from 'preact';
import styles from './Callout.module.scss';

type CalloutProps = {
    children: ComponentChildren;
    style: 'success' | 'warning' | 'error';
};

export const Callout = (props: CalloutProps) => (
    <div className={cc([styles['root'], styles[`root__${props.style}`]])}>
        {props.children}
    </div>
);
