import { useCampaign } from '../../../queries/campaigns';
import { useEffect, useState } from 'preact/hooks';
import { CampaignType } from '../../../api/campaign.types';
import { route as navigate } from 'preact-router';
import { Box } from '@mui/material';
import {
    MessageForm,
    MessageFormData,
} from '../../../components/MessageForm/MessageForm';
import { History } from './History/History';
import { CampaignHeader } from './CampaignHeader';
import {
    CampaignMessage,
    useCampaignMessageUpdate,
    useCreateCampaignMessage,
} from './use-campaign-messages';
import { handleError } from '../../../components/MessageFormContainers/utils';
import { useToastContext } from '../../../containers/toast/reducer';
import { useTrack } from '../../../contexts/analytics';
import { useConfirmSending } from './use-confirm-sending';
import { container, history } from './campaign.styles';

type Props = {
    campaignId?: number;
};

type NullableMessage = undefined | null | MessageFormData;
type Nullable<T> = null | T;

export const Campaign = ({ campaignId }: Props) => {
    const track = useTrack();
    const create = useCreateCampaignMessage();
    const { dispatch: toastDispatch } = useToastContext();

    const { data } = useCampaign(campaignId);
    const [message, setMessage] = useState<NullableMessage>(undefined);
    const [campaignMessage, setCampaignMessage] =
        useState<Nullable<CampaignMessage>>(null);
    const update = useCampaignMessageUpdate(campaignMessage?.id);

    const reset = () =>
        setMessage((prev) => (prev === undefined ? null : undefined));
    const [confirm, ConfirmDialog] = useConfirmSending();

    useEffect(() => {
        if (campaignMessage) {
            setMessage({
                message: campaignMessage.body,
                attachments: campaignMessage.attachments,
                sendAt: campaignMessage.timestamp,
            });
            return;
        }
        reset();
    }, [campaignMessage]);

    useEffect(() => {
        if (
            data?.type &&
            ![CampaignType.INSTANT, CampaignType.PLANNED].includes(data.type)
        ) {
            track('campaign_redirected_to_old');
            navigate(`/campaigns/${data.id}`);
        }
    }, [data?.type]);

    if (!campaignId) {
        return null;
    }

    const handleMessage = async (data: MessageFormData) => {
        if (campaignMessage) {
            return update
                .mutateAsync({
                    body: data.message,
                    attachments: data.attachments?.map(({ id }) => id) ?? [],
                    timestamp: data.sendAt,
                })
                .then(() => {
                    setCampaignMessage(null);
                })
                .catch(handleError(toastDispatch));
        }

        if (!(await confirm())) {
            return Promise.reject();
        }

        return create
            .mutateAsync({
                campaignId,
                attachments: data.attachments?.map(({ id }) => id),
                body: data.message,
                timestamp: data.sendAt ? new Date(data.sendAt) : undefined,
            })
            .then(() => {
                reset();
            })
            .catch(handleError(toastDispatch));
    };

    return (
        <Box sx={container}>
            <CampaignHeader campaignId={campaignId} />
            <Box overflow="hidden">
                <Box sx={history}>
                    <History
                        campaignId={campaignId}
                        onEdit={(message) => {
                            setCampaignMessage(message);
                        }}
                    />
                </Box>
            </Box>
            <div>
                <MessageForm
                    initial={message}
                    onCancel={() => {
                        setCampaignMessage(null);
                    }}
                    onSend={handleMessage}
                    disabled={create.isPending || update.isPending}
                    placeholder={`Send another message to ${data?.name}`}
                    templatesAvailable
                />
            </div>
            <ConfirmDialog contactsCount={data?.contactIds.length ?? 0} />
        </Box>
    );
};
