import { useEffect, useMemo, useState } from 'preact/hooks';
import { cloneDeep } from 'lodash';
import styles from '../WorkflowStepConfigurator.module.scss';
import Button from '../../../../elements/Buttons';
import { WorkflowAction } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import closeIcon from '../../../../assets/icons/icons-24/gray/close.svg';
import AdvancedSelect from '../../../../elements/AdvancedSelect';
import Icon from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';
import Input from '../../../../elements/Inputs/Input';
import { useMeQueryData, useTeammates } from '../../../../queries/user';
import { userName } from '../../../../helpers/formatting';
import { UUID } from '../../../../types/uuid';
import CheckBox from '../../../../elements/CheckBox/CheckBox';

interface AssignTeamMemberStepProps {
    step: WorkflowAction;
}

export const AssignTeamMemberStep = ({ step }: AssignTeamMemberStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [actionStep, setStep] = useState(cloneDeep(step));

    const { data: teammates = [] } = useTeammates();
    const currentUser = useMeQueryData();

    useEffect(() => setStep(cloneDeep(step as WorkflowAction)), [step]);

    const options = useMemo(
        () =>
            teammates.map((user) => ({
                id: user.id,
                label: userName(user, currentUser!.activeTeam?.countryCode),
            })),
        [teammates, currentUser],
    );

    const allSelected = useMemo(
        () =>
            actionStep.assignToConfiguration?.length === 1 &&
            actionStep.assignToConfiguration[0] === 'all',
        [actionStep, teammates],
    );

    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: actionStep,
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.action]} />
                    <span>Assign a team member</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <img src={closeIcon} />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Item name
                    </label>
                    <Input
                        placeholder="Enter name (optional)"
                        value={actionStep?.name || ''}
                        onChange={(name) => setStep({ ...actionStep, name })}
                        uncontrolled
                        fullWidth
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Assign to a team member
                    </label>
                    <AdvancedSelect
                        options={options}
                        onChange={(id) =>
                            setStep({
                                ...actionStep,
                                assignToConfiguration: [
                                    ...(actionStep.assignToConfiguration || []),
                                    id as UUID,
                                ],
                            })
                        }
                        value={actionStep.assignToConfiguration || []}
                        disabled={allSelected}
                        searchable
                        multiselect
                    />
                </section>
                <section className={styles['aside__box']}>
                    <CheckBox
                        checked={allSelected}
                        onChange={(checked) => {
                            if (checked) {
                                setStep({
                                    ...actionStep,
                                    assignToConfiguration: ['all'],
                                });
                            } else {
                                setStep({
                                    ...actionStep,
                                    assignToConfiguration: [],
                                });
                            }
                        }}
                        label="Balance between all team members"
                    />
                </section>
            </div>
            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
