import { Contact } from '../../api/types';

type View = 'full' | 'limited';

export type ColSizes = {
    name: string;
    cohort: string;
    phone: string;
    email: string;
};

export const COL_SIZE: Record<View, ColSizes> = {
    full: {
        name: '31%',
        cohort: '23%',
        phone: '23%',
        email: '23%',
    },
    limited: {
        name: '44%',
        cohort: '27%',
        phone: '27%',
        email: '0%',
    },
};

const isNewContact = ({ name, phone, email }: Contact) =>
    !name && !phone && !email;

export const byNamePhoneEmail = (a: Contact, b: Contact) => {
    //  If any of contacts or both are NEW
    if (isNewContact(a) && isNewContact(b)) {
        return 0;
    }
    if (isNewContact(a)) {
        return -1;
    }
    if (isNewContact(b)) {
        return 1;
    }

    // Sort by name
    if (a.name && b.name) {
        return a.name.localeCompare(b.name);
    }
    if (a.name) {
        return -1;
    }
    if (b.name) {
        return 1;
    }

    // Sort by phone
    if (a.phone && b.phone) {
        if (a.phone > b.phone) {
            return -1;
        }
        if (a.phone < b.phone) {
            return 1;
        }
    }
    if (a.phone) {
        return -1;
    }
    if (b.phone) {
        return 1;
    }

    // Sort by email
    if (a.email && b.email) {
        return a.email.localeCompare(b.email);
    }
    if (a.email) {
        return -1;
    }
    if (b.email) {
        return 1;
    }

    // All parameters are equal
    return 0;
};
