import { Button } from '@mui/material';
import { useInboxes } from '../../../queries/inboxes';
import { useMeQueryData, useUpdateMe } from '../../../queries/user';
import { useMemo } from 'preact/hooks';
import { useTeams } from 'msteams-react-base-component';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';

export const NotificationSettings = () => {
    const currentUser = useMeQueryData();
    const mutedInboxesIds = currentUser?.mutedInboxIds || [];
    const { data: inboxes = [] } = useInboxes();
    const { mutate, isPending } = useUpdateMe();
    const [{ inTeams }] = useTeams();

    const mutedInboxes = useMemo(
        () => inboxes.filter((inbox) => mutedInboxesIds.includes(inbox.id)),
        [inboxes.map(({ id }) => id), mutedInboxesIds],
    );

    const allInboxesMuted: boolean = useMemo(
        () => mutedInboxes.length === inboxes.length,
        [mutedInboxes.length, inboxes.length],
    );

    const muteInboxes = () => {
        mutate({
            mutedInboxIds:
                mutedInboxes.length === inboxes.length
                    ? []
                    : inboxes.map(({ id }) => id),
        });
    };

    return (
        <SettingsLayout title="Notifications">
            {inTeams && (
                <Button
                    disableRipple
                    variant="outlined"
                    onClick={() => {
                        window.open(
                            'https://login.microsoftonline.com/common/adminconsent?client_id=19cfdaa6-b6b3-48b6-8bae-7c17edf1da62&redirect_uri=https://app.clerk.chat',
                            '_blank',
                        );
                    }}
                    sx={{ mr: 2 }}
                >
                    Enable Notifications
                </Button>
            )}
            <Button disableRipple onClick={muteInboxes} disabled={isPending}>
                {allInboxesMuted ? 'Unmute All Inboxes' : 'Mute All Inboxes'}
            </Button>
        </SettingsLayout>
    );
};
