import cc from 'classcat';
import styles from './CohortsDropdownOption.module.scss';
import Icon from '../../../icons/Icon';

interface Props {
    id: number;
    label: string;
    count?: number;
    selected?: boolean;
    defaultOption?: boolean;
    collapse: () => void;
    onRename?: (id: number) => void;
    onRemove?: (id: number) => void;
    onReset?: () => void;
}

export const CohortsDropdownOption = (props: Props) => (
    <div className={styles['root']}>
        <div
            className={cc([
                styles['root__info'],
                {
                    [styles['root__info_default']]: props.defaultOption,
                },
            ])}
        >
            <p
                className={cc([
                    styles['root__label'],
                    {
                        [styles['root__label_default']]: props.defaultOption,
                    },
                ])}
            >
                {props.label}
            </p>
            {!props.defaultOption && (
                <p className={styles['root__count']}>{props.count} contacts</p>
            )}
        </div>
        <div className={styles['root__controls']}>
            {props.selected && (
                <Icon
                    className={styles['root__selected']}
                    name="check"
                    size="20px"
                />
            )}

            {!props.defaultOption && (
                <>
                    {props.onRename && (
                        <button
                            className={cc([
                                styles['root__button'],
                                styles['root__edit'],
                            ])}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.collapse();
                                props.onRename?.(props.id);
                            }}
                        >
                            <Icon name="edit" size="20px" />
                        </button>
                    )}
                    {props.onRemove && (
                        <button
                            className={cc([
                                styles['root__button'],
                                styles['root__delete'],
                            ])}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.collapse();
                                props.onRemove?.(props.id);
                                if (props.selected) {
                                    props.onReset?.();
                                }
                            }}
                        >
                            <Icon name="delete" size="20px" />
                        </button>
                    )}
                </>
            )}
        </div>
    </div>
);
