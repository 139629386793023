import { CampaignMessage, useCampaignMessages } from '../use-campaign-messages';
import { groupBy, isEmpty, toPairs } from 'lodash';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Message } from '../CampaignMessage/Message';
import { HistorySkeleton } from './HistorySkeleton';
import { NoMessages } from '../NoMessages';
import { MessagesLink } from '../CampaignMessage/MessagesLink';

type Props = {
    campaignId: number;
    onEdit?: (c: CampaignMessage) => void;
};

export const History = ({ campaignId, onEdit }: Props) => {
    const {
        data: messages,
        isLoading,
        isError,
    } = useCampaignMessages(campaignId);
    const dated = toPairs(
        groupBy(messages || [], (message) =>
            dayjs(message.timestamp).format('DD MMM'),
        ),
    );

    if (isLoading) {
        return <HistorySkeleton />;
    }

    if (isError && !messages) {
        return <div>Something went wrong</div>;
    }

    if (!messages || isEmpty(messages)) {
        return <NoMessages />;
    }

    return (
        <div>
            {dated.map(([date, messages]) => (
                <div key={date}>
                    <Typography
                        variant="body4"
                        sx={{
                            display: 'block',
                            color: 'custom.gray.super',
                            width: 1,
                            textAlign: 'center',
                        }}
                    >
                        {date}
                    </Typography>
                    {messages.map((message) => (
                        <Message
                            key={message.id}
                            message={message}
                            onEdit={onEdit ? () => onEdit(message) : undefined}
                            sx={{ my: 6 }}
                        >
                            <MessagesLink message={message}></MessagesLink>
                        </Message>
                    ))}
                </div>
            ))}
        </div>
    );
};
