import { CountryIcon } from './common/CountryIcon';
import { ListIcon } from './common/ListIcon';
import { AuIcon } from './flags/AuIcon';
import { BrIcon } from './flags/BrIcon';
import { CaIcon } from './flags/CaIcon';
import { DeIcon } from './flags/DeIcon';
import { MxIcon } from './flags/MxIcon';
import { GbIcon } from './flags/GbIcon';
import { UsIcon } from './flags/UsIcon';
import { OtherCountryIcon } from './common/OtherCountryIcon';
import { SettingsIcon } from './common/SettingsIcon';
import { PlusIcon } from './common/PlusIcon';
import { UserProfileIcon } from './common/UserProfileIcon';
import { TeamInfoIcon } from './common/TeamInfoIcon';
import { MembersIcon } from './common/MembersIcon';
import { CheckIcon } from './common/CheckIcon';
import { PowerOffIcon } from './common/PowerOffIcon';
import { DeleteIcon } from './common/DeleteIcon';
import { ContactsIcon } from './common/ContactsIcon';
import { SearchIcon } from './common/SearchIcon';
import { ActivityIcon } from './common/ActivityIcon';
import { WorkflowsIcon } from './common/WorkflowsIcon';
import { TriangleUpIcon } from './common/TriangleUpIcon';
import { TriangleDownIcon } from './common/TriangleDownIcon';
import { TriangleLeftIcon } from './common/TriangleLeftIcon';
import { TriangleRightIcon } from './common/TriangleRightIcon';
import { CampaignIcon } from './common/CampaignIcon';
import { AddCohortIcon } from './common/AddCohortIcon';
import { AiChatIcon } from './common/AiChatIcon';
import { MessAttachIcon } from './common/MessAttachIcon';
import { MessTemplateIcon } from './common/MessTemplateIcon';
import { MessSnippetIcon } from './common/MessSnippetIcon';
import { MessScheduleIcon } from './common/MessScheduleIcon';
import { AssignMemberIcon } from './common/AssignMemberIcon';
import { RemindMessageIcon } from './common/RemindMessageIcon';
import { MoreIcon } from './common/MoreIcon';
import { ContactInfoIcon } from './common/ContactInfoIcon';
import { MuteIcon } from './common/MuteIcon';
import { UnmuteIcon } from './common/UnmuteIcon';
import { MarkAsUnreadIcon } from './common/MarkAsUnreadIcon';
import { ArchiveIcon } from './common/ArchiveIcon';
import { CopyIcon } from './common/CopyIcon';
import { PdfFileIcon } from './files/PdfFileIcon';
import { DocFileIcon } from './files/DocFileIcon';
import { XlsFileIcon } from './files/XlsFileIcon';
import { OtherFileIcon } from './files/OtherFileIcon';
import { FullArrowDownIcon } from './common/FullArrowDownIcon';
import { AttachedFileIcon } from './common/AttachedFileIcon';
import { CloseIcon } from './common/CloseIcon';
import { MessSmileIcon } from './common/MessSmileIcon';
import { SendIcon } from './common/SendIcon';
import { SendFilledIcon } from './common/SendFilledIcon';
import { EditIcon } from './common/EditIcon';
import { PrivateIcon } from './common/PrivateIcon';
import { ChevronRightIcon } from './common/ChevronRightIcon';
import { ChevronLeftIcon } from './common/ChevronLeftIcon';
import { ChevronDownIcon } from './common/ChevronDownIcon';
import { ChevronUpIcon } from './common/ChevronUpIcon';
import { MailIcon } from './common/MailIcon';
import { Mail2Icon } from './common/MailI2con';
import { LockIcon } from './common/LockIcon';
import { UserSignatureIcon } from './common/UserSignatureIcon';
import { TableUsernameIcon } from './common/TableUsernameIcon';
import { TableStepsIcon } from './common/TableStepsIcon';
import { TableRoleIcon } from './common/TableRoleIcon';
import { TableNameIcon } from './common/TableNameIcon';
import { TableInboxesIcon } from './common/TableInboxesIcon';
import { PhoneIcon } from './common/PhoneIcon';
import { OptOutIcon } from './common/OptOutIcon';
import { ImportIcon } from './common/ImportIcon';
import { FilterIcon } from './common/FilterIcon';
import { RefreshIcon } from './common/RefreshIcon';
import { MoveToIcon } from './common/MoveToIcon';
import { AddCohortActionIcon } from './common/AddCohortActionIcon';
import { RemoveCohortActionIcon } from './common/RemoveCohortActionIcon';
import { CheckFilledIcon } from './filled/CheckFilledIcon';
import { CommentIcon } from './common/CommentIcon';
import { FileDownloadIcon } from './common/FileDownloadIcon';
import { IndexPointingUpIcon } from './gestures/IndexPointingUpIcon';
import { OkHandIcon } from './gestures/OkHandIcon';
import { AddPhotoIcon } from './common/AddPhoto';
import { CompanyIcon } from './common/CompanyIcon';
import { CalendarIcon } from './common/CalendarIcon';
import { CanceledFilledIcon } from './filled/CanceledFilledIcon';
import { TextIcon } from './common/TextIcon';
import { NumberIcon } from './common/NumberIcon';
import { SelectIcon } from './common/SelectIcon';
import { UrlIcon } from './common/UrlIcon';
import { CreatedTimeIcon } from './common/CreatedTimeIcon';
import { UpdatedIcon } from './common/UpdatedIcon';
import { GlobalRelayLogoIcon } from './logos/GlobalRelayLogoIcon';
import { ClerkLogoIcon } from './logos/ClerkLogoIcon';
import { GoogleContactsLogoIcon } from './logos/GoogleContactsLogoIcon';
import { CustomCopyIcon } from './custom-icons/CustomCopyIcon';
import { CustomCloudIcon } from './custom-icons/CustomCloudIcon';
import { CustomCheckIcon } from './custom-icons/CustomCheckIcon';
import { CustomBlankIcon } from './custom-icons/CustomBlankIcon';
import { CustomBagIcon } from './custom-icons/CustomBagIcon';
import { CustomBadgeIcon } from './custom-icons/CustomBadgeIcon';
import { CustomAddFileIcon } from './custom-icons/CustomAddFileIcon';
import { CustomCrownIcon } from './custom-icons/CustomCrownIcon';
import { CustomCubeIcon } from './custom-icons/CustomCubeIcon';
import { CustomDataIcon } from './custom-icons/CustomDataIcon';
import { CustomDownloadIcon } from './custom-icons/CustomDownloadIcon';
import { CustomFolderDownIcon } from './custom-icons/CustomFolderDownIcon';
import { CustomGridIcon } from './custom-icons/CustomGridIcon';
import { CustomHashIcon } from './custom-icons/CustomHashIcon';
import { CustomLightIcon } from './custom-icons/CustomLightIcon';
import { CustomLocationIcon } from './custom-icons/CustomLocationIcon';
import { CustomNoteIcon } from './custom-icons/CustomNoteIcon';
import { CustomPowerIcon } from './custom-icons/CustomPowerIcon';
import { CustomSandTimerIcon } from './custom-icons/CustomSandTimerIcon';
import { CustomSendIcon } from './custom-icons/CustomSendIcon';
import { CustomShieldIcon } from './custom-icons/CustomShieldIcon';
import { CustomStarIcon } from './custom-icons/CustomStarIcon';
import { CustomSwitchIcon } from './custom-icons/CustomSwitchIcon';
import { CustomTableIcon } from './custom-icons/CustomTableIcon';
import { CustomTimerIcon } from './custom-icons/CustomTimerIcon';
import { CustomTrophyIcon } from './custom-icons/CustomTrophyIcon';
import { CustomUploadIcon } from './custom-icons/CustomUploadIcon';
import { CustomUserEditIcon } from './custom-icons/CustomUserEditIcon';
import { CustomUserLockedIcon } from './custom-icons/CustomUserLockedIcon';
import { CustomWalletIcon } from './custom-icons/CustomWalletIcon';
import { LinkedIcon } from './custom-icons/LinkedIcon';
import { MobilePhoneIcon } from './custom-icons/MobilePhoneIcon';
import { OtherCarrierIcon } from './custom-icons/OtherCarrierIcon';
import { SupportIcon } from './custom-icons/SupportIcon';
import { WebsiteIcon } from './custom-icons/WebsiteIcon';
import { LinkedInLogoIcon } from './logos/LinkedInLogoIcon';
import { MicrosoftOutlookLogoIcon } from './logos/MicrosoftOutlookLogoIcon';
import { SalesforceLogoIcon } from './logos/SalesforceLogoIcon';
import { ShopifyLogoIcon } from './logos/ShopifyLogoIcon';
import { MicrosoftDynamicsLogoIcon } from './logos/MicrosoftDynamicsLogoIcon';
import { MicrosoftOnedriveLogoIcon } from './logos/MicrosoftOnedriveLogoIcon';
import { SmarshLogoIcon } from './logos/SmarshLogoIcon';
import { MessageIcon } from './common/MessageIcon';
import { SleepIcon } from './common/SleepIcon';
import { LastContactedIcon } from './common/LastContactedIcon';
import { ScheduleIcon } from './common/ScheduleIcon';
import { SplitIcon } from './common/SplitIcon';
import { TimeFrameIcon } from './common/TimeFrameIcon';
import { MailFilledIcon } from './filled/MailFilledIcon';
import { InfoIcon } from './common/InfoIcon';
import { ZipcodeIcon } from './common/ZipcodeIcon';
import { LegalIcon } from './common/LegalIcon';
import { AddressIcon } from './common/AddressIcon';
import { StateIcon } from './common/StateIcon';
import { SparklesIcon } from './common/SparklesIcon';
import { EmptySearchFilledIcon } from './filled/EmptySearchFilledIcon';
import { MicrosoftTeamsLogoIcon } from './logos/MicrosoftTeamsLogoIcon';
import { MicrosoftLogoIcon } from './logos/MicrosoftLogoIcon';

export const commonIconsMap = {
    ['zipcode']: ZipcodeIcon,
    ['legal']: LegalIcon,
    ['address']: AddressIcon,
    ['state']: StateIcon,
    ['country']: CountryIcon,
    ['other-country']: OtherCountryIcon,
    ['list']: ListIcon,
    ['settings']: SettingsIcon,
    ['plus']: PlusIcon,
    ['user-profile']: UserProfileIcon,
    ['team-info']: TeamInfoIcon,
    ['file-download']: FileDownloadIcon,
    ['members']: MembersIcon,
    ['check']: CheckIcon,
    ['power-off']: PowerOffIcon,
    ['delete']: DeleteIcon,
    ['contacts']: ContactsIcon,
    ['search']: SearchIcon,
    ['activity']: ActivityIcon,
    ['workflows']: WorkflowsIcon,
    ['triangle-up']: TriangleUpIcon,
    ['triangle-down']: TriangleDownIcon,
    ['triangle-left']: TriangleLeftIcon,
    ['info']: InfoIcon,
    ['triangle-right']: TriangleRightIcon,
    ['campaign']: CampaignIcon,
    ['add-cohort']: AddCohortIcon,
    ['ai-chat']: AiChatIcon,
    ['mess-attach']: MessAttachIcon,
    ['mess-template']: MessTemplateIcon,
    ['mess-snippet']: MessSnippetIcon,
    ['mess-schedule']: MessScheduleIcon,
    ['assign-member']: AssignMemberIcon,
    ['remind-message']: RemindMessageIcon,
    ['more']: MoreIcon,
    ['contact-info']: ContactInfoIcon,
    ['mute']: MuteIcon,
    ['unmute']: UnmuteIcon,
    ['mark-as-unread']: MarkAsUnreadIcon,
    ['archive']: ArchiveIcon,
    ['copy']: CopyIcon,
    ['full-arrow-down']: FullArrowDownIcon,
    ['attached-file']: AttachedFileIcon,
    ['close']: CloseIcon,
    ['mess-smile']: MessSmileIcon,
    ['send']: SendIcon,
    ['send-filled-icon']: SendFilledIcon,
    ['edit']: EditIcon,
    ['private']: PrivateIcon,
    ['chevron-right']: ChevronRightIcon,
    ['chevron-left']: ChevronLeftIcon,
    ['chevron-down']: ChevronDownIcon,
    ['chevron-up']: ChevronUpIcon,
    ['comment']: CommentIcon,
    ['mail']: MailIcon,
    ['email-2']: Mail2Icon,
    ['lock']: LockIcon,
    ['user-signature']: UserSignatureIcon,
    ['table-username']: TableUsernameIcon,
    ['table-steps']: TableStepsIcon,
    ['table-role']: TableRoleIcon,
    ['table-name']: TableNameIcon,
    ['table-inboxes']: TableInboxesIcon,
    ['phone']: PhoneIcon,
    ['opt-out']: OptOutIcon,
    ['import']: ImportIcon,
    ['filter']: FilterIcon,
    ['refresh']: RefreshIcon,
    ['move-to']: MoveToIcon,
    ['add-cohort-action']: AddCohortActionIcon,
    ['remove-cohort-action']: RemoveCohortActionIcon,
    ['add-photo']: AddPhotoIcon,
    ['company']: CompanyIcon,
    ['calendar']: CalendarIcon,
    ['text']: TextIcon,
    ['number']: NumberIcon,
    ['select']: SelectIcon,
    ['url']: UrlIcon,
    ['created-time']: CreatedTimeIcon,
    ['updated']: UpdatedIcon,
    ['message']: MessageIcon,
    ['sleep']: SleepIcon,
    ['website']: WebsiteIcon,
    ['last-contacted']: LastContactedIcon,
    ['schedule']: ScheduleIcon,
    ['split']: SplitIcon,
    ['time-frame']: TimeFrameIcon,
    ['sparkles']: SparklesIcon,
};

export type CommonIcons = keyof typeof commonIconsMap;

export const filledIconsMap = {
    ['check-filled']: CheckFilledIcon,
    ['canceled-filled']: CanceledFilledIcon,
    ['mail-filled']: MailFilledIcon,
    ['empty-search-filled']: EmptySearchFilledIcon,
};

export type FilledIcons = keyof typeof filledIconsMap;

export const flagsIconsMap = {
    ['AU']: AuIcon,
    ['BR']: BrIcon,
    ['CA']: CaIcon,
    ['DE']: DeIcon,
    ['GB']: GbIcon,
    ['MX']: MxIcon,
    ['US']: UsIcon,
};

export type FlagsIcons = keyof typeof flagsIconsMap;

export const filesIconsMap = {
    ['pdf']: PdfFileIcon,
    ['doc']: DocFileIcon,
    ['docx']: DocFileIcon,
    ['xls']: XlsFileIcon,
    ['xlsx']: XlsFileIcon,
    ['other']: OtherFileIcon,
};

export type FilesIcons = keyof typeof filesIconsMap;

export const gesturesIconsMap = {
    ['index-pointing-up']: IndexPointingUpIcon,
    ['ok-hand']: OkHandIcon,
};

export type GesturesIcons = keyof typeof gesturesIconsMap;

export const logosIconsMap = {
    ['clerk-logo']: ClerkLogoIcon,
    ['global-relay-logo']: GlobalRelayLogoIcon,
    ['google-contacts-logo']: GoogleContactsLogoIcon,
    ['linked-in-logo']: LinkedInLogoIcon,
    ['microsoft-dynamics-logo']: MicrosoftDynamicsLogoIcon,
    ['microsoft-logo']: MicrosoftLogoIcon,
    ['microsoft-onedrive-logo']: MicrosoftOnedriveLogoIcon,
    ['microsoft-outlook-logo']: MicrosoftOutlookLogoIcon,
    ['microsoft-teams-logo']: MicrosoftTeamsLogoIcon,
    ['salesforce-logo']: SalesforceLogoIcon,
    ['shopify-logo']: ShopifyLogoIcon,
    ['smarsh-logo']: SmarshLogoIcon,
};

export type LogosIcons = keyof typeof logosIconsMap;

// This icons set is mostly used for Contact Properties
export const customIconsMap = {
    ['']: null,
    ['text']: TextIcon,
    ['number']: NumberIcon,
    ['select']: SelectIcon,
    ['created-time']: CreatedTimeIcon,
    ['private']: PrivateIcon,
    ['email-2']: Mail2Icon,
    ['phone']: PhoneIcon,
    ['updated']: UpdatedIcon,
    ['url']: UrlIcon,

    ['custom-copy']: CustomCopyIcon,
    ['custom-cloud']: CustomCloudIcon,
    ['custom-check']: CustomCheckIcon,
    ['custom-blank']: CustomBlankIcon,
    ['custom-bag']: CustomBagIcon,
    ['custom-badge']: CustomBadgeIcon,
    ['custom-add-file']: CustomAddFileIcon,
    ['company']: CompanyIcon,
    ['campaign']: CampaignIcon,
    ['archive']: ArchiveIcon,
    ['mess-schedule']: MessScheduleIcon,
    ['custom-crown']: CustomCrownIcon,
    ['custom-cube']: CustomCubeIcon,
    ['custom-data']: CustomDataIcon,
    ['custom-download']: CustomDownloadIcon,
    ['custom-folder-down']: CustomFolderDownIcon,
    ['custom-grid']: CustomGridIcon,
    ['custom-hash']: CustomHashIcon,
    ['custom-light']: CustomLightIcon,
    ['custom-location']: CustomLocationIcon,
    ['custom-note']: CustomNoteIcon,
    ['custom-power']: CustomPowerIcon,
    ['custom-sand-timer']: CustomSandTimerIcon,
    ['custom-send']: CustomSendIcon,
    ['custom-shield']: CustomShieldIcon,
    ['custom-star']: CustomStarIcon,
    ['custom-switch']: CustomSwitchIcon,
    ['custom-table']: CustomTableIcon,
    ['custom-timer']: CustomTimerIcon,
    ['custom-trophy']: CustomTrophyIcon,
    ['custom-upload']: CustomUploadIcon,
    ['custom-user-edit']: CustomUserEditIcon,
    ['custom-user-locked']: CustomUserLockedIcon,
    ['custom-wallet']: CustomWalletIcon,
    ['linked']: LinkedIcon,
    ['mobile-phone']: MobilePhoneIcon,
    ['other-carrier']: OtherCarrierIcon,
    ['support']: SupportIcon,
    ['website']: WebsiteIcon,
};

export type CustomIcons = keyof typeof customIconsMap;

export type PropertyIcon = CustomIcons | LogosIcons;

export type Icons =
    | CommonIcons
    | FlagsIcons
    | FilesIcons
    | FilledIcons
    | GesturesIcons
    | CustomIcons
    | LogosIcons
    | '';
