import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import Input from '../../../../elements/Inputs/Input';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';

export const LimitedTextValue = ({
    userProperty,
    onChange,
    formatDisplay,
}: ValueProps<string>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(inputRef, () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Enter', () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Escape', () => {
        setValue(userProperty.value);
        setEditEnabled(false);
    });

    useEffect(() => {
        setValue(userProperty.value);
    }, [userProperty]);

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
        }
    }, [isEditEnabled]);

    if (isEditEnabled) {
        return (
            <Input
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                value={value}
                ref={inputRef}
                fullWidth
                onChange={setValue}
            />
        );
    }

    return (
        <div
            className={cc([styles['value'], styles['limited-value']])}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                formatDisplay ? (
                    formatDisplay(userProperty.value)
                ) : (
                    <span>{userProperty.value}</span>
                )
            ) : (
                <span className={styles['value__empty']}>+ Set a value</span>
            )}
        </div>
    );
};
