import { Box, BoxProps } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import 'date-fns/locale/en-US';
import { useEffect, useState } from 'preact/hooks';

type Props = BoxProps & {
    date: string | Date;
};
export const TimeAgo = ({ date, ...props }: Props) => {
    const [timeAgo, setTimeAgo] = useState<string>('');
    useEffect(() => {
        setTimeAgo(formatDistanceToNow(new Date(date), { addSuffix: true }));

        const interval = window.setInterval(() => {
            setTimeAgo(
                formatDistanceToNow(new Date(date), { addSuffix: true }),
            );
        }, 60_000);

        return () => {
            window.clearInterval(interval); // Clean up the interval when the component unmounts
        };
    }, []);

    return (
        <Box component="span" {...props}>
            {timeAgo}
        </Box>
    );
};
