import { Components, Theme } from '@mui/material';
import { CheckboxEmpty } from '../icons/CheckboxEmpty';
import { CheckboxChecked } from '../icons/CheckboxChecked';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
    defaultProps: {
        disableRipple: true,
        icon: <CheckboxEmpty />,
        checkedIcon: <CheckboxChecked />,
    },
    styleOverrides: {
        colorPrimary: ({ theme, ownerState }) => ({
            ':hover': {
                backgroundColor: ownerState.disabled
                    ? undefined
                    : theme.palette.primary.hover,
            },
            ':focus-within': {
                backgroundColor: ownerState.disabled
                    ? undefined
                    : theme.palette.primary.focusVisible,
            },
        }),
        sizeSmall: {
            '.MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
    },
};
