import { useLayoutEffect, useRef } from 'preact/hooks';
import { forwardRef, TargetedEvent } from 'preact/compat';
import cc from 'classcat';
//assets
import { FiCheck, FiMinus } from 'react-icons/fi';
//styles
import styles from './CheckBox.module.scss';
import { Typography } from '@mui/material';

export type CheckBoxProps = {
    size?: 'normal' | 'large';
    checked?: boolean;
    indeterminate?: boolean;
    label?: string | JSX.Element;
    disabled?: boolean;
    className?: string;
    onClick?: (e: TargetedEvent<HTMLInputElement>) => void;
    onChange?: (checked: boolean) => void;
};

const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
    (props: CheckBoxProps, ref) => {
        const checkBoxClass = cc([
            styles.checkbox__wrapper,
            props.className,
            {
                [styles.checkbox__wrapper_checked]:
                    props.checked || props.indeterminate,
                [styles.checkbox__wrapper_disabled]: props.disabled,
                [styles['checkbox__wrapper_size-large']]:
                    props.size === 'large',
            },
        ]);

        const input = ref || useRef<HTMLInputElement>(null);

        useLayoutEffect(() => {
            if (input.current != null && props.indeterminate != null) {
                input.current.indeterminate = props.indeterminate;
            }
        }, [props.indeterminate]);

        return (
            <label className={checkBoxClass}>
                <span className={styles.checkbox}>
                    <input
                        ref={input}
                        type="checkbox"
                        className={styles.checkbox__input}
                        checked={props.checked}
                        disabled={props.disabled}
                        onClick={props.onClick}
                        onChange={() => props.onChange?.(!props.checked)}
                    />
                    <span className={styles.checkbox__inner}>
                        {props.indeterminate ? (
                            <FiMinus className={styles['checkbox__icon']} />
                        ) : (
                            <FiCheck className={styles['checkbox__icon']} />
                        )}
                    </span>
                </span>
                {props.label && (
                    <Typography variant="body3" ml={2.5}>
                        {props.label}
                    </Typography>
                )}
            </label>
        );
    },
);

export default CheckBox;
