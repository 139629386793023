import { useMemo } from 'preact/hooks';
import type { CountryCode } from 'libphonenumber-js/types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styles from './ContactsTable.module.scss';
import { byNamePhoneEmail, COL_SIZE } from './utils';
import { ContactsTableRow, ItemData } from './ContactsTableRow';
import { Table, TableRow, TableCell } from '../Table';
import ContactsTableToolbar from '../ContactsTableToolbar';
import Icon from '../../icons/Icon';
import { Contact } from '../../api/types';
import { useCohortsByContactsQuery } from '../../queries/cohorts';
import { UUID } from '../../types/uuid';
import CheckBox from '../../elements/CheckBox/CheckBox';

const TABLE_HEADING_ROW_HEIGHT = 35;

const itemKey = (index: number, data: ItemData) =>
    data.contacts[index]?.id || index; // Use index in case if dummy row should be created

interface ContactsTableProps {
    activeContactId: UUID;
    selected: Set<UUID>;
    selectedCohortId?: number;
    contacts: Contact[];
    countryCode?: CountryCode;
    full?: boolean;
    setSelected: (contactIds: Set<UUID>) => void;
    onContactSelect: (id: UUID) => void;
    onContactClick: (id: UUID) => void;
    onSelectionMoveClick: () => void;
    onSelectionDeleteClick: () => void;
    onSelectionCancelClick: () => void;
    onExportContacts: () => void;
}

export const ContactsTable = (props: ContactsTableProps) => {
    const { cohortsByContacts } = useCohortsByContactsQuery();
    const sizes = props.full ? COL_SIZE['full'] : COL_SIZE['limited'];

    const itemData = useMemo(
        () => ({
            selected: props.selected,
            contacts: props.contacts.slice().sort(byNamePhoneEmail),
            countryCode: props.countryCode,
            full: props.full,
            sizes,
            onContactSelect: props.onContactSelect,
            onContactClick: props.onContactClick,
            activeContactId: props.activeContactId,
        }),
        [
            props.selected,
            props.contacts,
            props.countryCode,
            props.full,
            props.activeContactId,
            cohortsByContacts,
        ],
    );

    const itemCount =
        props.selected.size > 0
            ? props.contacts.length + 1
            : props.contacts.length;

    return (
        <Table className={styles['contacts-table']}>
            <TableRow isHead>
                <TableCell width={sizes.name} className={styles['name-cell']}>
                    <CheckBox
                        checked={
                            props.selected.size > 0 &&
                            props.contacts.length === props.selected.size
                        }
                        indeterminate={
                            props.selected.size > 0 &&
                            props.contacts.length !== props.selected.size
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.selected.size > 0) {
                                props.setSelected(new Set());
                            } else {
                                props.setSelected(
                                    new Set(props.contacts.map(({ id }) => id)),
                                );
                            }
                        }}
                    />
                    <Icon size="16px" name="table-username" />
                    <span>Contact name</span>
                </TableCell>

                <TableCell
                    width={sizes.cohort}
                    className={styles['cohort-cell']}
                >
                    <Icon size="16px" name="add-cohort" />
                    <span>Cohort</span>
                </TableCell>

                <TableCell width={sizes.phone} className={styles['phone-cell']}>
                    <Icon size="16px" name="phone" />
                    <span>Phone number</span>
                </TableCell>

                {props.full && (
                    <TableCell
                        width={sizes.email}
                        className={styles['email-cell']}
                    >
                        <Icon size="16px" name="mail" />
                        <span>Email</span>
                    </TableCell>
                )}
            </TableRow>

            <AutoSizer disableWidth>
                {({ height }) => (
                    <List
                        width="100%"
                        height={height - TABLE_HEADING_ROW_HEIGHT}
                        className={styles['contacts-table__outer']}
                        itemCount={itemCount}
                        itemData={itemData}
                        itemSize={52}
                        overscanCount={5}
                        itemKey={itemKey}
                    >
                        {ContactsTableRow}
                    </List>
                )}
            </AutoSizer>

            {props.selected.size > 0 && (
                <ContactsTableToolbar
                    className={styles['contacts-table__selected-toolbar']}
                    selected={props.selected}
                    selectedCohortId={props.selectedCohortId}
                    setSelected={props.setSelected}
                    onMove={props.onSelectionMoveClick}
                    onDelete={props.onSelectionDeleteClick}
                    onCancel={props.onSelectionCancelClick}
                    onExport={props.onExportContacts}
                />
            )}
        </Table>
    );
};
