import { useState } from 'react';
import { ListItem, ListItemAvatar, ListItemText, Button } from '@mui/material';
import { AvailableIntegration } from './integrations-list';
import { IntegrationSources, Profile } from '../../../api/types';
import { createIntegrationUrl } from './utils';
import * as styles from './styles';
import { ConnectShopifyIntegrationModal } from '../../../components/Modals/ConnectShopifyIntegrationModal/ConnectShopifyIntegrationModal';
import { ConnectDynamicsIntegrationModal } from '../../../components/Modals/ConnectDynamicsIntegrationModal/ConnectDynamicsIntegrationModal';
import { ConnectAzureIntegrationModal } from '../../../components/Modals/ConnectAzureIntegrationModal/ConnectAzureIntegrationModal';

type Props = AvailableIntegration & {
    me: Profile;
};

export function IntegrationRow({ me, ...integration }: Props) {
    const { icon, name, desc, extraSettings, source } = integration;
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);

    const clickHandler = (e: MouseEvent) => {
        if (
            extraSettings &&
            [
                IntegrationSources.Shopify,
                IntegrationSources.Dynamics,
                IntegrationSources.Azure,
            ].includes(source as IntegrationSources)
        ) {
            e.preventDefault();
            setShowModal(true);
            return;
        }
    };

    return (
        <>
            <ListItem sx={styles.listItem}>
                <ListItemAvatar>
                    <img src={icon} alt={name} />
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                        variant: 'body3',
                        color: 'primary.dark',
                        sx: { marginBottom: 1 },
                    }}
                    secondary={desc}
                    secondaryTypographyProps={{
                        nowrap: true,
                        variant: 'body4',
                        color: 'custom.gray.super',
                    }}
                />
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    target={source !== 'csv' ? '_blank' : undefined}
                    sx={styles.connectButton}
                    onClick={clickHandler}
                    href={
                        source === 'csv'
                            ? '/contacts/import-csv'
                            : createIntegrationUrl(integration, me!)
                    }
                >
                    Connect
                </Button>
            </ListItem>

            {source === IntegrationSources.Azure && (
                <ConnectAzureIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                    integration={integration}
                />
            )}
            {source === IntegrationSources.Shopify && (
                <ConnectShopifyIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                />
            )}

            {source === IntegrationSources.Dynamics && (
                <ConnectDynamicsIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                    integration={integration}
                    me={me!}
                />
            )}
        </>
    );
}
