import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addContactsToCohorts,
    CohortIdName,
    CohortMetaDto,
    CohortModifyContactsDto,
    createCohort,
    getCohort,
    getCohorts,
    getCohortsByContact,
    removeCohort,
    removeContactsFromCohorts,
    updateCohort,
} from '../api/cohorts';
import { Cohort, CohortMeta, Contact } from '../api/types';
import { queryClient } from './queryClient';
import { ACTIVITIES } from './activities';
import { CONTACTS_SEARCH_KEY } from './contacts';
import { UUID } from '../types/uuid';

export const COHORTS_KEY = 'cohorts_list';
export const COHORT_KEY = 'cohort';
export const COHORTS_BY_CONTACT_KEY = 'cohorts_by_contact';

const COHORT_CREATE_KEY = 'cohort_create';
const COHORT_UPDATE_KEY = 'cohort_update';
const COHORTS_ADD_CONTACTS_KEY = 'cohorts_add_contacts';

export function useCohortsQuery() {
    const { data } = useQuery({
        queryKey: [COHORTS_KEY],
        queryFn: getCohorts,
        staleTime: Infinity,
        select: (cohorts = []) =>
            cohorts.map(({ updated, ...rest }) => ({
                ...rest,
                updated: new Date(updated),
            })),
    });

    return data || [];
}

export function useCohortsQueryData() {
    return queryClient.getQueryData<CohortMetaDto[]>([COHORTS_KEY]) || [];
}

export function useCohortQuery(id: number, enabled = true) {
    const { data, isPending, isError, isFetching } = useQuery({
        queryKey: [COHORT_KEY, id],
        queryFn: () => getCohort(id),
        enabled,
    });
    return {
        cohort: data,
        isPending,
        isFetching,
        isError,
    };
}

export function useCohortsByContactsQuery() {
    const { data, isPending: isPending } = useQuery({
        queryKey: [COHORTS_BY_CONTACT_KEY],
        queryFn: getCohortsByContact,
        staleTime: Infinity,
    });

    return {
        cohortsByContacts: data || {},
        isPending,
    };
}

export function useCohortsByContactsQueryData() {
    return (
        queryClient.getQueryData<Record<UUID, CohortIdName[]>>([
            COHORTS_BY_CONTACT_KEY,
        ]) || {}
    );
}

export function useCohortCreateQuery() {
    return useMutation<Cohort, unknown, Partial<Cohort>>({
        mutationKey: [COHORT_CREATE_KEY],
        mutationFn: createCohort,
        onMutate: async (cohort) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) =>
                    cohorts.concat({
                        id: -1,
                        userId: '',
                        name: '',
                        isPublic: true,
                        updated: new Date(),
                        contactsCount: 0,
                        ...cohort,
                    }),
            );
        },
        onSuccess: (data) => {
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => {
                    const newCohorts = cohorts.slice();
                    newCohorts[newCohorts.length - 1] = {
                        id: data.id,
                        userId: data.userId,
                        name: data.name,
                        icon: data.icon,
                        isPublic: data.isPublic,
                        updated: data.updated,
                        contactsCount: data.contactsCount,
                    };
                    return newCohorts;
                },
            );
            queryClient.refetchQueries({ queryKey: [ACTIVITIES] });
        },
        onError: () => {
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => cohorts.slice(0, -1),
            );
        },
    });
}

export function useCohortUpdateQuery() {
    return useMutation({
        mutationKey: [COHORT_UPDATE_KEY],
        mutationFn: updateCohort,
        onMutate: async (updatedCohort) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [COHORT_KEY, updatedCohort.id],
            });

            const previousCohorts = queryClient.getQueryData<CohortMeta[]>([
                COHORTS_KEY,
            ]);
            const previousActiveCohort = queryClient.getQueryData<Cohort>([
                COHORT_KEY,
                updatedCohort.id,
            ]);

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => {
                    const idx = cohorts.findIndex(
                        ({ id }) => id === updatedCohort.id,
                    );
                    const updatedCohorts = cohorts.slice();
                    updatedCohorts[idx] = {
                        ...updatedCohorts[idx],
                        ...updatedCohort,
                    };

                    return updatedCohorts;
                },
            );

            if (previousActiveCohort) {
                queryClient.setQueryData<Cohort>(
                    [COHORT_KEY, updatedCohort.id],
                    {
                        ...previousActiveCohort,
                        ...updatedCohort,
                    },
                );
            }

            return {
                previousCohorts,
                previousActiveCohort,
            };
        },
        onError: (_error, _updatedCohort, context) => {
            const { previousCohorts = [], previousActiveCohort } =
                context || {};

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                () => previousCohorts,
            );

            if (previousActiveCohort) {
                queryClient.setQueryData<Cohort>(
                    [COHORT_KEY, previousActiveCohort.id],
                    previousActiveCohort,
                );
            }
        },
    });
}

export function useCohortDeleteQuery() {
    return useMutation({
        mutationKey: ['cohort_delete'],
        mutationFn: removeCohort,
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: [COHORTS_KEY] });

            const previousCohorts = queryClient.getQueryData<CohortMeta[]>([
                COHORTS_KEY,
            ]);

            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                (cohorts = []) => cohorts.filter((c) => c.id !== id),
            );

            return {
                previousCohorts,
            };
        },
        onError: (_error, _updatedCohort, context) => {
            const { previousCohorts = [] } = context || {};
            queryClient.setQueryData<CohortMeta[]>(
                [COHORTS_KEY],
                () => previousCohorts,
            );
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [ACTIVITIES] });
        },
    });
}

export function useAddContactsToCohortsMutation() {
    return useMutation<
        void,
        unknown,
        CohortModifyContactsDto,
        { previousCohortsByContacts: Record<UUID, CohortIdName[]> }
    >({
        mutationKey: [COHORTS_ADD_CONTACTS_KEY],
        mutationFn: addContactsToCohorts,
        onMutate: async (dto: CohortModifyContactsDto) => {
            await queryClient.cancelQueries({
                queryKey: [COHORTS_BY_CONTACT_KEY],
            });

            const { contactIds = [], cohortIds = [] } = dto;

            const previousCohortsByContacts =
                queryClient.getQueryData<Record<UUID, CohortIdName[]>>([
                    COHORTS_BY_CONTACT_KEY,
                ]) || {};

            const cohorts =
                queryClient.getQueryData<CohortMetaDto[]>([COHORTS_KEY]) || [];

            queryClient.setQueryData<Record<UUID, CohortIdName[]>>(
                [COHORTS_BY_CONTACT_KEY],
                (cohortsByContacts = {}) => {
                    const newData = { ...cohortsByContacts };
                    contactIds.forEach((contactId) => {
                        newData[contactId] = (newData[contactId] || []).slice();

                        cohortIds.forEach((cohortId) => {
                            const cohort = cohorts.find(
                                (c) => c.id === cohortId,
                            );

                            if (
                                cohort &&
                                !newData[contactId].find(
                                    (record) => record.id === cohort.id,
                                )
                            ) {
                                newData[contactId].push({
                                    id: cohort.id,
                                    name: cohort.name,
                                });
                            }
                        });
                    });

                    return newData;
                },
            );

            return {
                previousCohortsByContacts,
            };
        },
        onError: (_error, _dto, context) => {
            const { previousCohortsByContacts = {} } = context || {};

            queryClient.setQueryData<Record<UUID, CohortIdName[]>>(
                [COHORTS_BY_CONTACT_KEY],
                () => previousCohortsByContacts,
            );
        },
    });
}

export function useRemoveContactsFromCohortsMutation() {
    return useMutation<
        void,
        unknown,
        CohortModifyContactsDto,
        { previousContacts: Contact[] }
    >({
        mutationKey: ['cohorts_remove_contacts'],
        mutationFn: removeContactsFromCohorts,
        onMutate: async (dto: CohortModifyContactsDto) => {
            await queryClient.cancelQueries({
                queryKey: [COHORTS_BY_CONTACT_KEY],
            });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            const { contactIds } = dto;

            const previousContacts =
                queryClient.getQueryData<Contact[]>([CONTACTS_SEARCH_KEY]) ||
                [];

            queryClient.setQueryData<Contact[]>(
                [CONTACTS_SEARCH_KEY],
                (contacts = []) =>
                    contacts.filter((c) => !contactIds.includes(c.id)),
            );

            return {
                previousContacts,
            };
        },
        onError: (_error, _dto, context) => {
            const { previousContacts = [] } = context || {};

            queryClient.setQueryData<Contact[]>(
                [CONTACTS_SEARCH_KEY],
                () => previousContacts,
            );
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [COHORTS_BY_CONTACT_KEY] });
            queryClient.refetchQueries({ queryKey: [] });
        },
    });
}
