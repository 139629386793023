import { VNode } from 'preact';
import styles from './TeamSelectorMenuItem.module.scss';

interface TeamSelectorMenuItemProps {
    icon?: VNode;
    label: string;
    onClick?: () => void;
}

export const TeamSelectorMenuItem = ({
    icon,
    label,
    onClick,
}: TeamSelectorMenuItemProps) => (
    <li className={styles['root']} onClick={onClick}>
        <div className={styles['root__icon']}>{icon}</div>
        <span className={styles['root__label']}>{label}</span>
    </li>
);
