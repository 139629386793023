import { TargetedEvent } from 'preact/compat';
import {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'preact/hooks';
import styles from './AddToCohortAction.module.scss';
import { ActionButton } from '../ActionButton';
import CheckBox from '../../../elements/CheckBox/CheckBox';
import Droplist from '../../../elements/Droplist';
import { DroplistDivider } from '../../../elements/Droplist/DroplistDivider';
import { DroplistHeader } from '../../../elements/Droplist/DroplistHeader';
import { DroplistItem } from '../../../elements/Droplist/DroplistItem';
import { DroplistItems } from '../../../elements/Droplist/DroplistItems';
import { DroplistSearch } from '../../../elements/Droplist/DroplistSearch';
import {
    useAddContactsToCohortsMutation,
    useCohortCreateQuery,
    useCohortsByContactsQuery,
    useCohortsQueryData,
    useRemoveContactsFromCohortsMutation,
} from '../../../queries/cohorts';
import { CohortMetaDto } from '../../../api/cohorts';
import Icon from '../../../icons/Icon';
import { UUID } from '../../../types/uuid';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

export interface AddToCohortActionProps {
    contactId: UUID;
    disabled?: boolean;
}

export const AddToCohortAction = (props: AddToCohortActionProps) => {
    const [active, setActive] = useState(false);
    const [selected, setSelected] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [createNew, setCreateNew] = useState(false);
    const [name, setName] = useState('');

    const cohorts = useCohortsQueryData();
    const { cohortsByContacts } = useCohortsByContactsQuery();
    const { mutateAsync: createCohort } = useCohortCreateQuery();
    const { mutateAsync: addToCohorts } = useAddContactsToCohortsMutation();
    const { mutateAsync: removeFromCohorts } =
        useRemoveContactsFromCohortsMutation();

    const inputRef = useRef<HTMLInputElement>(null);
    const actionButtonRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([actionButtonRef, popupRef], () => setActive(false));

    useEffect(() => {
        const contactCohorts = cohortsByContacts[props.contactId];
        if (contactCohorts) {
            setSelected(contactCohorts.map(({ id }) => id));
        }
    }, [cohortsByContacts, props.contactId]);

    useEffect(() => {
        if (createNew && inputRef.current) {
            inputRef.current.focus();
        }
    }, [createNew]);

    const contains = useCallback(
        (cohort: CohortMetaDto) => selected.includes(cohort.id),
        [selected],
    );

    const toggle = useCallback(
        (cohort: CohortMetaDto) => {
            const newSelected = contains(cohort)
                ? selected.filter((id) => id !== cohort.id)
                : selected.concat(cohort.id);
            setSelected(newSelected);
        },
        [selected, contains],
    );

    const reset = useCallback(() => {
        setCreateNew(false);
        setName('');
    }, []);

    const filteredCohorts = useMemo(
        () =>
            cohorts.filter((cohort) =>
                cohort.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
        [cohorts, searchTerm],
    );

    return (
        <div className={styles['root']}>
            <ActionButton
                ref={actionButtonRef}
                iconName="add-cohort"
                tooltipText="Add to cohort"
                onClick={() => setActive(!active)}
                disabled={props.disabled}
            />

            {active && (
                <div className={styles['root__popup']} ref={popupRef}>
                    <Droplist className={styles['root__popup-droplist']}>
                        <DroplistHeader title="Add to cohort" />
                        <DroplistSearch
                            value={searchTerm}
                            onChange={setSearchTerm}
                        />
                        <DroplistItems>
                            {filteredCohorts.map((cohort) => (
                                <DroplistItem key={cohort.id}>
                                    <CheckBox
                                        checked={contains(cohort)}
                                        onClick={() => {
                                            contains(cohort)
                                                ? removeFromCohorts({
                                                      contactIds: [
                                                          props.contactId,
                                                      ],
                                                      cohortIds: [cohort.id],
                                                  })
                                                : addToCohorts({
                                                      contactIds: [
                                                          props.contactId,
                                                      ],
                                                      cohortIds: [cohort.id],
                                                  });
                                            toggle(cohort);
                                        }}
                                    />
                                    <span>{cohort.name}</span>
                                </DroplistItem>
                            ))}
                        </DroplistItems>
                        <DroplistDivider />
                        <div className={styles['root__editor-container']}>
                            {createNew ? (
                                <div className={styles['root__editor']}>
                                    <input
                                        className={styles['root__name-input']}
                                        ref={inputRef}
                                        value={name}
                                        onChange={(
                                            e: TargetedEvent<HTMLInputElement>,
                                        ) => setName(e.currentTarget.value)}
                                        spellCheck={false}
                                    />
                                    <div className={styles['root__buttons']}>
                                        <button
                                            className={styles['root__save']}
                                            onClick={() => {
                                                createCohort({ name })
                                                    .then(reset)
                                                    .catch(console.log);
                                            }}
                                        >
                                            <Icon name="check" size="20px" />
                                        </button>
                                        <button
                                            className={styles['root__cancel']}
                                            onClick={reset}
                                        >
                                            <Icon name="close" size="20px" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className={styles['root__create-btn']}
                                    onClick={() => setCreateNew(true)}
                                >
                                    <Icon name="plus" /> Add new
                                </button>
                            )}
                        </div>
                    </Droplist>
                </div>
            )}
        </div>
    );
};
