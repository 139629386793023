import { useState } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { usePopper } from 'react-popper';
import styles from './AddNewProperty.module.scss';
import PropertyTypesList from '../PropertyTypesList';
import Icon from '../../../icons/Icon';
import { PopupElement } from '../../../types/PopupElement';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useCreateProperty } from '../../../queries/properties';
import PropertyConfigurator from '../PropertyConfigurator';
import { ContactPropertyType } from '../../../api/types';
import { propertyTypeIconNameMap } from '../typeIconMap';

const AddNewProperty = () => {
    const [expanded, setExpanded] = useState(false);
    const [selectedType, setSelectedType] =
        useState<ContactPropertyType | null>(null);

    const { mutate: createProperty } = useCreateProperty();

    const [refElement, setRefElement] = useState<PopupElement>(null);
    const [popperElement1, setPopperElement1] = useState<PopupElement>(null);
    const [popperElement2, setPopperElement2] = useState<PopupElement>(null);

    const { styles: popperStyles1, attributes: popperAttributes1 } = usePopper(
        refElement,
        popperElement1,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [16, 0],
                    },
                },
            ],
        },
    );

    const { styles: popperStyles2, attributes: popperAttributes2 } = usePopper(
        refElement,
        popperElement2,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [16, 0],
                    },
                },
            ],
        },
    );

    useOnClickOutside([refElement, popperElement1], () => {
        setExpanded(false);
        setSelectedType(null);
    });

    useOnClickOutside([refElement, popperElement2], () => {
        setExpanded(false);
        setSelectedType(null);
    });

    const handleSelectType = (type: ContactPropertyType) => {
        setSelectedType(type);
    };

    return (
        <div ref={setRefElement} className={styles['root']}>
            <button
                className={styles['root__button']}
                onClick={() => setExpanded(true)}
            >
                <Icon
                    name="plus"
                    size="20px"
                    className={styles['root__icon']}
                />
                <span>Add a new property</span>
            </button>
            {expanded &&
                !selectedType &&
                createPortal(
                    <div
                        className={styles['root__popup']}
                        ref={setPopperElement1}
                        style={popperStyles1.popper}
                        {...popperAttributes1.popper}
                    >
                        <PropertyTypesList onClick={handleSelectType} />
                    </div>,
                    document.querySelector('#portal')!,
                )}

            {selectedType &&
                createPortal(
                    <div
                        className={styles['root__popup']}
                        ref={setPopperElement2}
                        style={popperStyles2.popper}
                        {...popperAttributes2.popper}
                    >
                        <PropertyConfigurator
                            type={selectedType}
                            onNameChange={(name) => {
                                setExpanded(false);
                                setSelectedType(null);

                                createProperty({
                                    name,
                                    type: selectedType,
                                    icon: propertyTypeIconNameMap[selectedType],
                                });
                            }}
                            onRemove={() => {
                                setExpanded(false);
                                setSelectedType(null);
                            }}
                        />
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
};

export default AddNewProperty;
