import {
    ConversationAutocompleteItem,
    Item,
    ListItem,
} from './ConversationAutocompleteItem';

interface ConversationAutocompleteListItemProps {
    data: {
        countryCode?: string;
        list: ListItem[];
        activeIdx: number;
        onClick: (contactOrCohort: Item) => void;
    };
    index: number;
    // This style prop is used by react-window lib.
    style: string;
}

export const ConversationAutocompleteListItem = (
    props: ConversationAutocompleteListItemProps,
) => (
    <ConversationAutocompleteItem
        index={props.index}
        item={props.data.list[props.index]}
        countryCode={props.data.countryCode}
        onClick={props.data.onClick}
        style={props.style}
    />
);
