import { useUserTyping } from '../ConversationRow/use-conversation-typing';
import { Box, Typography } from '@mui/material';

type TypingIndicatorProps = {
    conversationId: string;
};

export const TypingIndicator = (props: TypingIndicatorProps) => {
    const userTyping = useUserTyping(props.conversationId);

    if (!userTyping) {
        return null;
    }

    return (
        <Box mb={2} px={7}>
            <Typography variant="body4" color="custom.gray.super">
                {userTyping} is typing ...
            </Typography>
        </Box>
    );
};
