import { CountryCode } from 'libphonenumber-js';
import { CarrierOption, CountryOption } from './types';
import MsteamsIcon from '../../assets/icons/carriers/msteams.svg?react';
import TelnyxIcon from '../../assets/icons/carriers/telnyx.svg?react';
import TwilioIcon from '../../assets/icons/carriers/twilio.svg?react';
import OtherIcon from '../../assets/icons/carriers/other_carrier.svg?react';
import Icon from '../../icons/Icon';

export const getFlagIcon = (countryCode?: CountryCode | 'other') => {
    switch (countryCode) {
        case 'CA':
            return <Icon name="CA" />;

        case 'US':
            return <Icon name="US" />;

        case 'GB':
            return <Icon name="GB" />;

        case 'AU':
            return <Icon name="AU" />;

        case 'DE':
            return <Icon name="DE" />;

        case 'BR':
            return <Icon name="BR" />;

        case 'MX':
            return <Icon name="MX" />;

        case 'other':
            return <Icon name="other-country" />;

        default:
            return null;
    }
};

export const countriesOptions: CountryOption[] = [
    { id: 'CA', label: 'Canada', icon: <Icon name="CA" />, code: '+1' },
    { id: 'US', label: 'USA', icon: <Icon name="US" />, code: '+1' },
    {
        id: 'GB',
        label: 'United Kingdom',
        icon: <Icon name="GB" />,
        code: '+44',
        disabled: true,
    },
    {
        id: 'AU',
        label: 'Australia',
        icon: <Icon name="AU" />,
        code: '+61',
        disabled: true,
    },
    {
        id: 'DE',
        label: 'Germany',
        icon: <Icon name="DE" />,
        code: '+49',
        disabled: true,
    },
    {
        id: 'BR',
        label: 'Brazil',
        icon: <Icon name="BR" />,
        code: '+55',
        disabled: true,
    },
    {
        id: 'MX',
        label: 'Mexico',
        icon: <Icon name="MX" />,
        code: '+52',
        disabled: true,
    },
    {
        id: 'other',
        label: 'Other',
        icon: <Icon name="other-country" />,
        code: '',
        disabled: true,
    },
];

export const carrierOptions: CarrierOption[] = [
    { id: 'twilio', label: 'Twilio', icon: <TwilioIcon /> },
    { id: 'msteams', label: 'Microsoft Teams', icon: <MsteamsIcon /> },
    { id: 'telnyx', label: 'Telnyx', icon: <TelnyxIcon /> },
    { id: 'other', label: 'Other', icon: <OtherIcon /> },
];
