import { Box, Chip, Stack, Typography } from '@mui/material';
import { Brand, BrandRegistrationStatus } from '../../../../api/types';
import { useMemo } from 'preact/hooks';
import { ClockIcon } from './icons/ClockIcon';
import { OkIcon } from './icons/OkIcon';
import { countryFlags, organizationLegalFormOptions } from '../utils';
import { countriesList } from '../../../../utils/countries';
import { DotIcon } from './icons/DotIcon';
import * as styles from './styles';

type Props = {
    brand?: Brand;
};

export function StatusBox({ brand }: Props) {
    const color = useMemo(() => {
        switch (brand?.status) {
            case BrandRegistrationStatus.PENDING:
                return 'warning';
            case BrandRegistrationStatus.FAILED:
                return 'error';
            case BrandRegistrationStatus.VERIFIED:
            case BrandRegistrationStatus.VETTED_VERIFIED:
                return 'success';
            default:
                return 'default';
        }
    }, [brand?.status]);

    const icon = useMemo(() => {
        switch (brand?.status) {
            case BrandRegistrationStatus.PENDING:
                return <ClockIcon sx={styles.chipIcon} />;
            case BrandRegistrationStatus.VERIFIED:
            case BrandRegistrationStatus.VETTED_VERIFIED:
                return <OkIcon sx={styles.chipIcon} />;
            default:
                return undefined;
        }
    }, [brand?.status]);

    const label = useMemo(() => {
        if (!brand?.id) {
            return 'Unverified';
        }

        switch (brand?.status) {
            case BrandRegistrationStatus.PENDING:
                return 'Verification';
            case BrandRegistrationStatus.FAILED:
                return 'Failed';
            case BrandRegistrationStatus.VERIFIED:
            case BrandRegistrationStatus.VETTED_VERIFIED:
                return 'Registered';
            default:
                return 'Unregistered';
        }
    }, [brand?.id, brand?.status]);

    const isRegistered =
        brand?.status === BrandRegistrationStatus.VERIFIED ||
        brand?.status === BrandRegistrationStatus.VETTED_VERIFIED;

    return (
        <Box sx={styles.statusBox}>
            <Box sx={styles.innerBox}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div>
                        <Typography variant="h2" color="primary.dark">
                            {isRegistered
                                ? brand?.brandName || brand?.companyName
                                : 'You Registration Status'}
                        </Typography>
                        {isRegistered ? (
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                alignItems={{ xs: 'flex-start', sm: 'center' }}
                            >
                                {countryFlags[String(brand?.companyCountry)]}

                                <Typography
                                    variant="body3"
                                    color="custom.gray.super"
                                >
                                    {
                                        countriesList.find(
                                            ({ code }) =>
                                                code === brand?.companyCountry,
                                        )?.name
                                    }
                                </Typography>

                                <DotIcon sx={styles.dotIcon} />

                                <Typography
                                    variant="body3"
                                    color="custom.gray.super"
                                >
                                    {
                                        organizationLegalFormOptions.find(
                                            ({ id }) =>
                                                id ===
                                                brand?.organizationLegalForm,
                                        )?.label
                                    }
                                </Typography>
                            </Stack>
                        ) : (
                            <Typography
                                variant="body3"
                                color="custom.gray.super"
                            >
                                Once your information is submitted this page
                                will be updated.
                            </Typography>
                        )}
                    </div>

                    <Chip
                        color={color}
                        label={label}
                        icon={icon}
                        size="small"
                        sx={styles.chip}
                    />
                </Stack>
            </Box>
        </Box>
    );
}
