import { useCallback } from 'preact/hooks';
import styles from './Condition.module.scss';
import { UserPropertyFilterCondition } from '../../UserInfoProperty/types';

interface ConditionProps {
    value: UserPropertyFilterCondition;
    onChange: (filterCondition: UserPropertyFilterCondition) => void;
}

export const Condition = (props: ConditionProps) => {
    const text = props.value === UserPropertyFilterCondition.OR ? 'Or' : 'And';

    const onClickHandler = useCallback(() => {
        const newValue =
            props.value === UserPropertyFilterCondition.OR
                ? UserPropertyFilterCondition.AND
                : UserPropertyFilterCondition.OR;
        props.onChange(newValue);
    }, [props.value, props.onChange]);

    return (
        <button className={styles['condition']} onClick={onClickHandler}>
            <span>{text}</span>
        </button>
    );
};

export default Condition;
