import {
    Components,
    listItemIconClasses,
    selectClasses,
    Theme,
} from '@mui/material';
import { SelectComponentIcon } from '../icons/common/SelectComponentIcon';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
    defaultProps: {
        variant: 'outlined',
        IconComponent: SelectComponentIcon,
        disableUnderline: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body4,
            color: theme.palette.primary.dark,
        }),
        outlined: ({ theme }) => ({
            height: 40,
            display: 'flex',
            padding: theme.spacing(0, 3),
            alignItems: 'center',

            [`& .${listItemIconClasses.root}`]: {
                marginRight: theme.spacing(2),
                minWidth: 20,
                color: theme.palette.custom.gray.super,

                '& svg': {
                    width: 20,
                },
            },
        }),
        icon: ({ theme }) => ({
            width: 20,
            marginRight: theme.spacing(2),
            color: 'inherit',

            [`&.${selectClasses.iconOutlined}`]: {
                color: theme.palette.custom.gray.super,
                transform: 'none',
            },
        }),
        standard: {
            border: 'none',

            '&:focus': {
                backgroundColor: 'inherit',
            },
        },
    },
};
