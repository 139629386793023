import styles from './PhoneNumberType.module.scss';
import RocketIcon from '../../../assets/icons/add-inbox/rocket.svg?react';
import NewPhoneIcon from '../../../assets/icons/different/new_phone.svg?react';
import ExistingPhoneIcon from '../../../assets/icons/different/existing_phone.svg?react';
import ArrowRightIcon from '../../../assets/icons/icons-24/dark/arrow_right.svg?react';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useMeQueryData } from '../../../queries/user';
import StepShell from '../StepShell';
import { route } from 'preact-router';

export interface PhoneNumberTypeProps {
    goToNumberProposal: () => void;
    goToExistingNumber: () => void;
}

export const PhoneNumberType = ({
    goToNumberProposal,
    goToExistingNumber,
}: PhoneNumberTypeProps) => {
    const analytics = useAnalytics();
    const me = useMeQueryData();

    const onClick = () => {
        analytics.dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName:
                    AnalyticsEventName.ONBOARDING_USER_SELECTED_NEW_NUMBER,
                eventPayload: {
                    userId: me?.id,
                    teamId: me?.activeTeam?.id,
                },
            },
        });
        if (me?.activeTeam.tier != 'free') {
            goToNumberProposal();
        } else {
            route('/payment');
        }
    };
    return (
        <StepShell
            title="Setup your number"
            subtitle={
                me?.activeTeam.tier != 'free'
                    ? 'Would you like to get a new phone number or activate your existing one?'
                    : 'Activate your existing phone number'
            }
            icon={<RocketIcon />}
        >
            <ul className={styles['root']}>
                <li className={styles['root__option']} onClick={onClick}>
                    <span className={styles['root__icon-halo']}>
                        <NewPhoneIcon />
                    </span>
                    <div className={styles['root__text']}>
                        <p className={styles['root__main-text']}>
                            Get a new phone number
                        </p>
                        <p className={styles['root__secondary-text']}>
                            Perfect for demos or to get started quickly
                        </p>
                    </div>
                    <ArrowRightIcon />
                </li>
                <li
                    className={styles['root__option']}
                    onClick={goToExistingNumber}
                >
                    <span className={styles['root__icon-halo']}>
                        <ExistingPhoneIcon />
                    </span>
                    <div className={styles['root__text']}>
                        <p className={styles['root__main-text']}>
                            Activate existing phone number
                        </p>
                        <p className={styles['root__secondary-text']}>
                            Connect any carrier with no porting required
                        </p>
                    </div>
                    <ArrowRightIcon />
                </li>
            </ul>
        </StepShell>
    );
};
