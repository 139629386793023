import Router, {
    Route,
    route,
    RouterOnChangeArgs,
    getCurrentUrl,
} from 'preact-router';
import { useCallback, useEffect } from 'preact/hooks';
import { useMeQuery, useMeQueryData } from './queries/user';
import { Main } from './main';
import LoginPage from './pages/auth/LoginPage';
import { parseJwt, set } from './utils/local-storage';
import Loading from './components/Loading/Loading';
import { AnalyticsActions, useAnalytics } from './contexts/analytics';
import { Settings } from './main/settings/Settings';
import { useCampaignV3Enabled } from './helpers/use-campaign-v3-enabled';
import { RoleTypes } from './api/types';

/*todo: move to separate component on react migration*/
const useOnlyGuestGuard = () => {
    const me = useMeQueryData();

    useEffect(() => {
        if (me && /^(\/login|\/signup|\/forgot).*$/.test(getCurrentUrl())) {
            const shouldSkipTerms =
                me?.acceptedTermsOfService ||
                me?.activeRole?.type !== RoleTypes.WorkspaceOwner;
            route(shouldSkipTerms ? '/' : '/setup/terms-of-service');
        }
    }, [!!me]);
};

export function App() {
    useOnlyGuestGuard();
    const { status, data: me, refetch } = useMeQuery();
    const analytics = useAnalytics();

    useEffect(() => {
        if (me) {
            analytics.dispatch({
                type: AnalyticsActions.IDENTIFY,
                userId: me.id,
            });
        }
    }, [me]);

    useCampaignV3Enabled();

    const checkAuth = useCallback(
        (e: RouterOnChangeArgs) => {
            const pathParams: Record<string, string> = e.current
                .props as Record<string, string>;

            if (pathParams.msUserId) {
                set('MS_TEAMS_USER_ID', pathParams.msUserId);
            }

            if (pathParams.tid) {
                set('MS_TENET_ID', pathParams.tid);
            }
            if (pathParams.k) {
                set('auth', pathParams.k);
                const { teamId } = parseJwt(pathParams.k);
                set('teamId', teamId);
                refetch();
            }
            if (
                status === 'error' &&
                !e.url.match('/login') &&
                !e.url.match('/signup') &&
                !e.url.match('/forgot')
            ) {
                route('/login');
            }

            if (status === 'success') {
                if (!me?.id) {
                    if (!e.url.startsWith('/login')) {
                        route('/login');
                    }
                } else if (
                    !me?.acceptedTermsOfService &&
                    me?.activeRole?.type === RoleTypes.WorkspaceOwner
                ) {
                    const url = new URL('http://any.com');
                    url.pathname = '/setup/terms-of-service';
                    if (pathParams.next) {
                        url.searchParams.append('next', pathParams.next);
                    }
                    route(url.href.substring(url.origin.length));
                }
            }
        },
        [status, me],
    );

    if (['idle', 'pending'].includes(status)) {
        return <Loading />;
    }

    return (
        <Router onChange={checkAuth}>
            <Route path="/login/:rest*" component={LoginPage} me={me} />
            <Route path="/settings/:rest*" component={Settings} />
            <Route path="/:rest*" component={Main} />
        </Router>
    );
}
