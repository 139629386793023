import { Profile } from '../api/types';
import { formatPhoneNumber } from '../utils/phoneNumber';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(relativeTime);
dayjs.extend(isToday);

export const phoneNumberFormatting = (
    phoneNumber: string | undefined,
): string => {
    if (!phoneNumber) {
        return '';
    }
    const phoneNumberParts = phoneNumber.match(
        /(\+\d)(\d{0,3})(\d{0,3})(\d{0,4})/,
    );

    return phoneNumberParts
        ? `(${phoneNumberParts[2]}) ${phoneNumberParts[3]} ${phoneNumberParts[4]}`
        : '';
};

export const userName = (
    { firstname, lastname, email, phone }: Profile,
    countryCode?: string,
) => {
    if (firstname || lastname) {
        return [firstname, lastname].join(' ');
    }
    if (email) {
        return email;
    }
    return formatPhoneNumber(phone, countryCode);
};

export const dateFormat = (
    date?: Date,
    options?: { [key: string]: string | boolean | number },
): string => {
    return date
        ? new Intl.DateTimeFormat('en-US', {
              ...options,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }).format(date)
        : '';
};

export const dateFormatRelative = (date: Date): string => dayjs(date).fromNow();

export const dateFormatFullDay = (date: Date): string => {
    const djs = dayjs(date);
    return djs.isToday()
        ? `today ${djs.format('h:mm A')}`
        : djs.format('MMMM D, YYYY');
};

export const getPrettyTargetTime = (target: Date): string => {
    if (dayjs(target).isBefore(dayjs())) {
        return `soon`;
    }

    return `in ${dayjs(target).fromNow(true)}`;
};
