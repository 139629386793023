import { useMemo } from 'preact/hooks';
import styles from './ContactPropertiesPanel.module.scss';
import { getInitialVlue, getTitle } from './utils';
import { Contact } from '../../api/types';
import ComplianceProperties from '../../components/ComplianceProperties';
import { PropertySources } from '../../components/UserInfoProperty/types';
import { usePropertiesQueryData } from '../../queries/properties';
import { ContactPropertiesGroup } from './ContactPropertiesGroup';
import { CohortsGroup } from './CohortsGroup';

interface ContactPropertiesPanelProps {
    contact: Partial<Contact>;
}

export const ContactPropertiesPanel = (props: ContactPropertiesPanelProps) => {
    const properties = usePropertiesQueryData();

    const groupedProps = useMemo(() => {
        return properties.reduce((acc, property) => {
            acc[property.source].push(property);
            return acc;
        }, getInitialVlue());
    }, [properties]);

    return (
        <section className={styles['root']}>
            {props.contact.id && (
                <ComplianceProperties contact={props.contact} />
            )}

            <ContactPropertiesGroup
                title={getTitle(PropertySources.Clerk)}
                contact={props.contact}
                properties={groupedProps.clerk}
                canCreateProps
                initiallyExpanded
            />

            {props.contact.id && <CohortsGroup contact={props.contact} />}

            {Object.keys(groupedProps)
                .filter((key) => key !== PropertySources.Clerk)
                .map((key) => {
                    const source = key as PropertySources;
                    const propertiesGroup = groupedProps[source];

                    return (
                        propertiesGroup.length > 0 && (
                            <ContactPropertiesGroup
                                title={getTitle(source)}
                                contact={props.contact}
                                properties={propertiesGroup}
                                initiallyExpanded
                            />
                        )
                    );
                })}
        </section>
    );
};
