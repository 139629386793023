import * as Sentry from '@sentry/react';
import { useState } from 'preact/hooks';
import { parsePhoneNumber } from 'libphonenumber-js';
import { route } from 'preact-router';
import { usePopper } from 'react-popper';
import { createPortal } from 'preact/compat';
import styles from './InboxCustomization.module.scss';
import StepShell from '../StepShell';
import { getFlagIcon } from '../utils';
import {
    AllowedCountries,
    CarrierCredentials,
    SetupWizardStep,
    TelnyxCredentials,
    TwilioCredentials,
} from '../types';
import { Inbox, InboxProvider } from '../../../api/types';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import Button from '../../../elements/Buttons';
import Input from '../../../elements/Inputs/Input';
import PencilIcon from '../../../assets/icons/add-inbox/pencil.svg?react';
import { useReservePhone } from '../../../queries/inboxes';
import { useMeQueryData } from '../../../queries/user';
import DotLoader from '../../../elements/DotLoader';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import {
    AnalyticsEventName,
    OnboardingUserProvisionedPhoneNumber,
} from '../../../types/AnalyticsEventNames';
import EmojiPicker from '../../../components/EmojiPicker';
import { renderInboxIcon } from '../../../utils/inboxes';

export interface InboxCustomizationProps extends SetupWizardStep {
    inbox: Partial<Inbox>;
    inboxCountry: AllowedCountries;
    carrierCredentials?: CarrierCredentials;
}

export const InboxCustomization = ({
    inbox,
    inboxCountry,
    carrierCredentials,
    goBackward,
}: InboxCustomizationProps) => {
    const me = useMeQueryData();
    const analytics = useAnalytics();
    const [inboxObj, setInboxObj] = useState<Partial<Inbox>>(inbox);
    const [isIconEditorActive, setIconEditorActive] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // mumtations
    const { mutateAsync: reservePhone } = useReservePhone();

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null,
    );
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
        null,
    );
    const { styles: popperStyles, attributes } = usePopper(
        containerRef,
        popperElement,
        {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [160, 8],
                    },
                },
            ],
        },
    );

    const country =
        inboxCountry ||
        (inboxObj.phone && parsePhoneNumber(inboxObj.phone).country);

    return (
        <StepShell
            title="Invite your Team"
            subtitle="Rename your inbox and invite your team members."
            icon={<PencilIcon />}
            goBackward={goBackward}
        >
            <div className={styles['root__content']}>
                <div className={styles['root__inbox-header']}>
                    {getFlagIcon(country)}
                    {formatPhoneNumber(
                        inboxObj.provider === InboxProvider.BANDWIDTH
                            ? `+1${inboxObj.phone}`
                            : inboxObj.phone,
                    )}
                </div>
                <div className={styles['root__icon-name']}>
                    <p className={styles['root__section-title']}>Icon & name</p>
                    <p className={styles['root__section-description']}>
                        Give your Inbox a name and icon.
                    </p>
                    <div className={styles['root__controls']}>
                        <div
                            className={styles['root__icon-placeholder']}
                            ref={setContainerRef}
                        >
                            <button
                                className={styles['root__icon-btn']}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIconEditorActive(true);
                                }}
                            >
                                {renderInboxIcon(inboxObj.icon)}
                            </button>
                            {isIconEditorActive &&
                                createPortal(
                                    <div
                                        ref={setPopperElement}
                                        style={popperStyles.popper}
                                        {...attributes.popper}
                                    >
                                        <EmojiPicker
                                            onClick={(val) => {
                                                if (val) {
                                                    const hex =
                                                        val.codePointAt(0);

                                                    if (hex) {
                                                        setInboxObj({
                                                            ...inboxObj,
                                                            icon: hex.toString(
                                                                16,
                                                            ),
                                                        });
                                                    }
                                                    setIconEditorActive(false);
                                                }
                                            }}
                                            onClose={() =>
                                                setIconEditorActive(false)
                                            }
                                        />
                                    </div>,
                                    document.querySelector('#portal')!,
                                )}
                        </div>

                        <Input
                            value={inboxObj.name}
                            placeholder="Inbox name"
                            onChange={(newName) => {
                                setInboxObj({
                                    ...inboxObj,
                                    name: newName,
                                });
                            }}
                            fullWidth
                        />
                    </div>
                </div>
            </div>

            <Button
                disabled={isLoading}
                onClick={async () => {
                    setLoading(true);
                    if (inboxObj.phone) {
                        try {
                            let createdInbox: Inbox | null = null;

                            if (inboxObj.provider === InboxProvider.BANDWIDTH) {
                                createdInbox = await reservePhone({
                                    phone: inboxObj.phone,
                                    provider: inboxObj.provider,
                                    name: inboxObj.name,
                                });
                            } else if (
                                inboxObj.provider === InboxProvider.TWILIO
                            ) {
                                createdInbox = await reservePhone({
                                    phone: inboxObj.phone,
                                    provider: inboxObj.provider,
                                    name: inboxObj.name,
                                    twilioSid: (
                                        carrierCredentials as TwilioCredentials
                                    ).accountSID,
                                    twilioToken: (
                                        carrierCredentials as TwilioCredentials
                                    ).authToken,
                                });
                            } else if (
                                inboxObj.provider === InboxProvider.TELNYX
                            ) {
                                createdInbox = await reservePhone({
                                    phone: inboxObj.phone,
                                    provider: inboxObj.provider,
                                    name: inboxObj.name,
                                    telnyxApiKey: (
                                        carrierCredentials as TelnyxCredentials
                                    ).apiKey,
                                });
                            } else if (
                                inboxObj.provider === InboxProvider.MSTEAMS ||
                                inboxObj.provider === InboxProvider.OTHER
                            ) {
                                createdInbox = await reservePhone({
                                    phone: inboxObj.phone,
                                    provider: inboxObj.provider,
                                    name: inboxObj.name,
                                });
                                const eventPayload: OnboardingUserProvisionedPhoneNumber =
                                    {
                                        email: me!.email,
                                        phone: inboxObj.phone,
                                        provider: inboxObj.provider,
                                        userId: me!.id,
                                        teamId: me!.activeTeam.id,
                                        microsoftTenantId:
                                            me!.microsoftTenantId,
                                    };
                                analytics.dispatch({
                                    type: AnalyticsActions.TRACK,
                                    payload: {
                                        eventName:
                                            AnalyticsEventName.ONBOARDING_USER_IMPORTED_NUMBER,
                                        eventPayload,
                                    },
                                });
                            }

                            if (createdInbox) {
                                const eventPayload: OnboardingUserProvisionedPhoneNumber =
                                    {
                                        email: me!.email,
                                        phone: createdInbox.phone,
                                        provider: createdInbox.provider,
                                        userId: me!.id,
                                        teamId: me!.activeTeam.id,
                                        microsoftTenantId:
                                            me!.microsoftTenantId,
                                    };
                                analytics.dispatch({
                                    type: AnalyticsActions.TRACK,
                                    payload: {
                                        eventName:
                                            AnalyticsEventName.ONBOARDING_PROVISIONED_PHONE_NUMBER,
                                        eventPayload,
                                    },
                                });

                                if (
                                    inboxObj.provider ===
                                        InboxProvider.MSTEAMS ||
                                    inboxObj.provider === InboxProvider.OTHER
                                ) {
                                    route(
                                        `/inbox/${createdInbox?.id}/verify/customer`,
                                    );
                                } else {
                                    route(`/inbox/${createdInbox.id}/finish`);
                                }
                            } else {
                                throw new Error(
                                    'Unable to create an inbox. Please check the provider and credentials',
                                );
                            }
                        } catch (error) {
                            if (error instanceof Error) {
                                Sentry.captureException(error);
                            }
                        } finally {
                            setLoading(false);
                        }
                    }
                }}
                fullWidth
            >
                {isLoading ? <DotLoader /> : 'Done'}
            </Button>
        </StepShell>
    );
};
