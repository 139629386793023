import { Backdrop, BackdropProps } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { AttachmentPreview } from './AttachmentPreview';

type Props = {
    attachment: Attachment;
};

export const AttachmentBackdrop = ({
    attachment,
    ...props
}: Props & BackdropProps) => (
    <Backdrop {...props} sx={{ zIndex: 1, ...(props.sx || {}) }}>
        <AttachmentPreview attachment={attachment} />
    </Backdrop>
);
