import { useEffect, useRef, useState } from 'preact/hooks';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';
// styles
import styles from './MultiselectSuggestionOption.module.scss';
import { Check, PencilSimple, X } from 'phosphor-react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import CheckBox from '../../../../elements/CheckBox/CheckBox';

interface MultiselectSuggestionOptionProps {
    option: string;
    isSelected: boolean;
    onEdit: (value: string) => void;
    onSave: (value: string) => Promise<unknown>;
    onRemove: () => void;
}

export const MultiselectSuggestionOption = ({
    option,
    isSelected,
    onEdit,
    onSave,
    onRemove,
}: MultiselectSuggestionOptionProps) => {
    const [value, setValue] = useState(option);
    const [isEditMode, setEditMode] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isEditMode) {
            inputRef.current?.focus();
        }
    }, [isEditMode]);

    useOnClickOutside(inputRef, (e) => {
        e?.stopPropagation();

        onEdit('');
        setEditMode(false);
        onSave(value);
    });

    useOnKeyDown(inputRef, 'Enter', () => {
        onEdit('');
        setEditMode(false);
        onSave(value);
    });

    useOnKeyDown(inputRef, 'Escape', () => {
        onEdit('');
        setEditMode(false);
        setValue(option);
    });

    return (
        <div className={styles['select-option']}>
            <CheckBox
                className={styles['select-option__checkbox']}
                checked={isSelected}
            />
            {isEditMode && (
                <input
                    ref={inputRef}
                    className={styles['select-option__input']}
                    onFocus={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)}
                />
            )}
            {!isEditMode && (
                <span className={styles['select-option__value']} title={value}>
                    {value}
                </span>
            )}

            <div className={styles['select-option__actions']}>
                {isEditMode && (
                    <button
                        className={styles['select-option__action-btn']}
                        title="Save an option"
                        onClick={(e) => {
                            e.stopPropagation();
                            onSave(value).finally(() => {
                                onEdit('');
                                setEditMode(false);
                            });
                        }}
                    >
                        <Check size={16} />
                    </button>
                )}

                {!isEditMode && (
                    <>
                        <button
                            className={styles['select-option__action-btn']}
                            title="Remove an option"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove();
                            }}
                        >
                            <X size={16} />
                        </button>
                        <button
                            className={styles['select-option__action-btn']}
                            title="Edit an option"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(option);
                                setEditMode(true);
                            }}
                        >
                            <PencilSimple size={16} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
