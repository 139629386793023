import styles from './InboxItemActions.module.scss';
import { useRef } from 'preact/hooks';
import checkIcon from '../../assets/icons/navigation/mark-as-read-icon.svg';
import settingsIcon from '../../assets/icons/navigation/settings.svg';
import muteIcon from '../../assets/icons/navigation/mute.svg';
import unmuteIcon from '../../assets/icons/navigation/un-mute.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Icon from '../../icons/Icon';
import { Typography } from '@mui/material';

interface InboxItemActionsProps {
    isMuted: boolean;
    onOutsideClick: () => void;
    onMarkReadClick: () => void;
    onSettingsClick: () => void;
    onArchiveAllClick: () => void;
    onMute: () => void;
    onUnmute: () => void;
}

/** @deprecated needs to be rewritten to mui/menu */
const InboxItemActions = ({
    isMuted,
    onOutsideClick,
    onMarkReadClick,
    onSettingsClick,
    onArchiveAllClick,
    onMute,
    onUnmute,
}: InboxItemActionsProps) => {
    const ulRef = useRef<HTMLUListElement>(null);

    useOnClickOutside(ulRef, onOutsideClick);

    return (
        <div className={styles['inbox-actions']}>
            <ul className={styles['inbox-actions__list']} ref={ulRef}>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onSettingsClick();
                        }}
                        type="button"
                    >
                        <img
                            className={styles['inbox-actions__icon']}
                            src={settingsIcon}
                        />
                        <Typography variant="body3">Settings</Typography>
                    </button>
                </li>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onMarkReadClick();
                        }}
                        type="button"
                    >
                        <img
                            className={styles['inbox-actions__icon']}
                            src={checkIcon}
                        />
                        <Typography variant="body3">Mark All Read</Typography>
                    </button>
                </li>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onArchiveAllClick();
                        }}
                        type="button"
                    >
                        <Icon
                            name="archive"
                            className={styles['inbox-actions__icon']}
                        />
                        <Typography variant="body3">Archive All</Typography>
                    </button>
                </li>
                {isMuted ? (
                    <li className={styles['inbox-actions__list-item']}>
                        <button
                            className={styles['inbox-actions__btn']}
                            onClick={(e) => {
                                e.preventDefault();
                                onUnmute();
                            }}
                            type="button"
                        >
                            <img
                                src={unmuteIcon}
                                className={styles['inbox-actions__icon']}
                            />
                            <Typography variant="body3">Unmute</Typography>
                        </button>
                    </li>
                ) : (
                    <li className={styles['inbox-actions__list-item']}>
                        <button
                            className={styles['inbox-actions__btn']}
                            onClick={(e) => {
                                e.preventDefault();
                                onMute();
                            }}
                            type="button"
                        >
                            <img
                                src={muteIcon}
                                className={styles['inbox-actions__icon']}
                            />
                            <Typography variant="body3">Mute</Typography>
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default InboxItemActions;
