import { ToggleSwitch } from '../../ToggleSwitch/ToggleSwitch';
import styles from './AIToggle.module.scss';
import cc from 'classcat';

type Props = {
    checked: boolean;
    toggle: () => void;
    high?: boolean;
    className?: string;
};

export const AIToggle = ({
    checked = false,
    toggle,
    high = false,
    className,
}: Props) => (
    <div
        className={cc([styles['root'], high && styles['root_high'], className])}
    >
        Ask AI
        <ToggleSwitch isChecked={checked} onToggle={toggle}></ToggleSwitch>
    </div>
);
