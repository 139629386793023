import { Card, CardContent, Typography, Button } from '@mui/material';
import { useContext, useMemo } from 'preact/hooks';
import { planList } from '../../../containers/PaymentFlow/plans';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { Link, route } from 'preact-router';
import { BillingContext } from './Billing';
import { BillingProvider } from '../../../api/types';

export const CurrentPlanCard = () => {
    const me = useMeQueryData();
    const { data: teammates = [] } = useTeammates();
    const state = useContext(BillingContext);

    const plan = useMemo(
        () => planList.find((plan) => plan.type === me?.activeTeam.tier),
        [me?.activeTeam.tier],
    );
    const usersCount = useMemo(() => teammates?.length, [teammates]);

    const planTitle = plan?.title ?? 'Free';

    const onClick = () => {
        if (me?.activeTeam.tier === 'free') {
            route('/payment/plan');
        } else {
            state.planCompareOpen.value = !state.planCompareOpen.value;
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <CardContent sx={{ padding: 0 }}>
                <Typography variant="h2">{planTitle} Plan</Typography>
                <Typography variant="body3">
                    This workspace has{' '}
                    <Typography
                        variant="body3"
                        fontWeight={700}
                        color={({ palette }) => palette.info.main}
                    >
                        <Link href="/settings/members">{usersCount} users</Link>
                    </Typography>
                </Typography>
            </CardContent>
            <div>
                <Button onClick={onClick}>
                    {state.planCompareOpen.value ? 'Close' : 'Change plan'}
                </Button>
                {me?.activeTeam.tier !== 'free' &&
                    me?.activeTeam.billingProvider ===
                        BillingProvider.Stripe && (
                        <Button
                            sx={{ ml: 2 }}
                            variant="outlined"
                            href="https://buy.clerk.chat/p/login/7sI14ceiz3fy4TudQQ"
                            target="_blank"
                        >
                            Update Billing
                        </Button>
                    )}
            </div>
        </Card>
    );
};
