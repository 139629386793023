import styles from './SleepStep.module.scss';

export const SleepStep = () => {
    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>Workflow items</h3>
            </header>

            <div className={styles['aside__content']}></div>
        </div>
    );
};
