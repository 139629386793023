import { Components } from '@mui/material/styles';

export const MuiListItemText: Components['MuiListItemText'] = {
    defaultProps: {
        primaryTypographyProps: {
            variant: 'body3',
        },
    },
    styleOverrides: {},
};
