import IconBase, { IconBaseProps } from '../IconBase';

export const InfoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1.875C3.72183 1.875 1.875 3.72183 1.875 6C1.875 8.27817 3.72183 10.125 6 10.125C8.27817 10.125 10.125 8.27817 10.125 6C10.125 3.72183 8.27817 1.875 6 1.875ZM1.125 6C1.125 3.30761 3.30761 1.125 6 1.125C8.69239 1.125 10.875 3.30761 10.875 6C10.875 8.69239 8.69239 10.875 6 10.875C3.30761 10.875 1.125 8.69239 1.125 6ZM6 3.625C6.20711 3.625 6.375 3.79289 6.375 4V6.5C6.375 6.70711 6.20711 6.875 6 6.875C5.79289 6.875 5.625 6.70711 5.625 6.5V4C5.625 3.79289 5.79289 3.625 6 3.625ZM5.625 8C5.625 7.79289 5.79289 7.625 6 7.625H6.005C6.21211 7.625 6.38 7.79289 6.38 8C6.38 8.20711 6.21211 8.375 6.005 8.375H6C5.79289 8.375 5.625 8.20711 5.625 8Z"
            fill="currentColor"
        />
    </IconBase>
);
