import { useTeams } from 'msteams-react-base-component';
import Loading from '../../components/Loading/Loading';
import useUnmutedSortedInboxes from '../../hooks/useUnmutedSortedInboxes';
import { useMeQueryData } from '../../queries/user';
import { canSetupInbox } from '../../utils/inboxes';
import { Redirect } from '../../utils/redirect';

export const InboxLoader = () => {
    const currentUser = useMeQueryData();
    const { inboxes, unmutedSortedInboxes, isPending } =
        useUnmutedSortedInboxes();

    const [{ inTeams }] = useTeams();

    if (isPending) {
        return <Loading />;
    }

    if (currentUser) {
        const canSetupInboxes = canSetupInbox(currentUser, inboxes);

        if (inboxes.length < 1) {
            const isSlackUser = currentUser.activeTeam.slackTeamId;

            if (!canSetupInboxes || isSlackUser) {
                return <Redirect to="/contacts" />;
            }

            if (inTeams) {
                return <Redirect to="/setup-inbox/ms-teams/auth" />;
            }

            return <Redirect to="/setup-inbox" />;
        }

        if (unmutedSortedInboxes.length < 1) {
            return <Redirect to="/contacts" />;
        }

        return <Redirect to={`/inbox/${unmutedSortedInboxes[0].id}`} />;
    }

    return <></>;
};
