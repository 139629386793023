import { RefObject } from 'preact';
import dayjs from 'dayjs';
import { Message, MessageComment, Profile } from '../../api/types';
import { userName } from '../../helpers/formatting';
import { ContactsCache } from '../../utils/ContactsCache';
import { CountryCode } from 'libphonenumber-js/types';
import { formatPhoneNumber } from '../../utils/phoneNumber';

const SCROLL_THRESHOLD = 10; // in pixels

export interface CommentItem {
    isGroupStart?: boolean;
    isGroupEnd?: boolean;
    avatar?: string;
    author: string;
    comment: MessageComment;
}

const getAuthorFromUser = (sentByName?: string, user?: Profile) => {
    if (sentByName) {
        return sentByName;
    }

    if (user) {
        return userName(user);
    }

    return '';
};

export const getOriginalMessageAvatar = (
    message: Message,
    contacts: ContactsCache,
) => {
    if (message.inbound) {
        const contact = contacts.getByPhoneOrEmpty(message.sender);
        return contact.avatarURL;
    } else {
        const user = message.user;
        return user?.avatar?.url;
    }
};

export const getOriginalMessageAuthor = (
    message: Message,
    contacts: ContactsCache,
    countryCode?: CountryCode,
) => {
    if (message.inbound) {
        const contact = contacts.getByPhoneOrEmpty(message.sender);
        return contact.name || formatPhoneNumber(contact.phone, countryCode);
    } else {
        const user = message.user;
        return getAuthorFromUser(message.sentByName, user);
    }
};

const getInitialEvent = (
    comment: MessageComment,
    users: Profile[],
): CommentItem => {
    const user = users.find(({ id }) => id === comment.userId);
    return {
        avatar: user?.avatar?.url,
        author: user ? userName(user) : '',
        comment,
    };
};

const isSameMinute = (currentMessageDate: Date, prevMessageDate: Date) =>
    dayjs(currentMessageDate).isSame(prevMessageDate, 'minute');

const commentsCompareFn = (a: MessageComment, b: MessageComment) => {
    const aDate = dayjs(a.created);
    const bDate = dayjs(b.created);

    if (aDate.isAfter(bDate)) {
        return 1;
    }
    if (aDate.isBefore(bDate)) {
        return -1;
    }

    return 0;
};

export const transformMessages = (
    comments: MessageComment[] = [],
    users: Profile[] = [],
): CommentItem[] =>
    comments.sort(commentsCompareFn).map((comment, i) => {
        let event: CommentItem = getInitialEvent(comment, users);

        // Detect the start of the chain
        if (i === 0) {
            event = { ...event, isGroupStart: true };
        } else if (!isSameMinute(comment.created, comments[i - 1].created)) {
            // Even if the user is same but minutes are different - mark chain as just started
            event = { ...event, isGroupStart: true };
        } else if (comment.userId && comments[i - 1].userId) {
            if (comment.userId !== comments[i - 1].userId) {
                event = { ...event, isGroupStart: true };
            }
        }

        // Detect the end of the chain

        if (i === comments.length - 1) {
            event = { ...event, isGroupEnd: true };
        } else if (!isSameMinute(comment.created, comments[i + 1].created)) {
            // Even if the user is same but minutes are different - mark chain as ended
            event = { ...event, isGroupEnd: true };
        } else if (comment.userId && comments[i + 1].userId) {
            if (comment.userId !== comments[i + 1].userId) {
                event = { ...event, isGroupEnd: true };
            }
        }

        return event;
    });

export const isScrollAtTheBottom = (scrollRef: RefObject<HTMLDivElement>) => {
    if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        return scrollTop + SCROLL_THRESHOLD >= scrollHeight - clientHeight;
    }
    return false;
};

export const scrollToTop = (scrollRef: RefObject<HTMLDivElement>) => {
    if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0);
    }
};

export const scrollToBottom = (scrollRef: RefObject<HTMLDivElement>) => {
    if (scrollRef.current) {
        const sp =
            scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
        scrollRef.current.scrollTo(0, sp);
    }
};
