import { Contact } from '../api/types';
import { formatPhoneNumber } from './phoneNumber';
import { CampaignContact } from '../api/campaign.types';

export const getContactName = (
    contact: Contact | CampaignContact | Partial<Contact>,
    teamCountry?: string,
) => {
    const { name, phone, email } = contact;
    if (name && name.trim().length > 0) {
        return name;
    }

    if (phone) {
        return formatPhoneNumber(phone, teamCountry);
    }

    return email;
};

export const concatContactNames = (
    contacts: (Contact | CampaignContact)[],
    teamCountry?: string,
) => contacts.map((contact) => getContactName(contact, teamCountry)).join(', ');
