import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

/**
 * Used in checkbox component
 **/
export const CheckboxEmpty = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none">
            <path
                d="M15.8333 4.66667V16.3333H4.16667V4.66667H15.8333ZM15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
