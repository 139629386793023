import { PropertyIcon } from '../../../icons/iconsMap';

export const ICONS_SET: PropertyIcon[] = [
    // line 1
    'custom-bag',
    'custom-star',
    'custom-trophy',
    'custom-hash',
    'custom-download',
    'custom-upload',
    'archive',
    // line 2
    'custom-crown',
    'custom-user-edit',
    'custom-wallet',
    'custom-data',
    'custom-send',
    'custom-folder-down',
    'custom-switch',
    // line 3
    'custom-blank',
    'custom-copy',
    'custom-add-file',
    'campaign',
    'custom-grid',
    'custom-table',
    'custom-location',
    // line 4
    'custom-shield',
    'custom-cube',
    'custom-check',
    'other-carrier',
    'custom-light',
    'custom-cloud',
    'custom-sand-timer',
    // line 5
    'custom-timer',
    'mess-schedule',
    'custom-badge',
    'custom-note',
    'custom-user-locked',
    'mobile-phone',
    'support',
    // line 6
    'custom-power',
    'company',
    'website',
    'linked',
];
