import { Components, svgIconClasses, Theme } from '@mui/material';

export const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
    styleOverrides: {
        root: {
            [`& .${svgIconClasses.root}`]: {
                width: 20,
                height: 20,
            },
        },
    },
};
