import { Box } from '@mui/material';

const style = {
    display: 'flex',
    width: 1,
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
};
export const NoMessages = () => (
    <Box sx={style} typography="h3">
        No messages
    </Box>
);
