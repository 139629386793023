import thumbUpIcon from '../../assets/icons/onboarding/thumb-up.svg';
import postBoxIcon from '../../assets/icons/onboarding/post-box.svg';
import suitcaseIcon from '../../assets/icons/onboarding/suitcase.svg';

export const EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum Step {
    Email = 'email',
    Code = 'code',
    Team = 'team',
}

export const getStepContent = (step: Step) => {
    if (step === Step.Email) {
        return {
            icon: thumbUpIcon,
            header: 'Welcome to Clerk Chat',
            subheader: 'Drop your email to login or register',
        };
    }
    if (step === Step.Code) {
        return {
            icon: postBoxIcon,
            header: 'Welcome to Clerk Chat',
            subheader: 'Drop your email to login or register',
        };
    }
    return {
        icon: suitcaseIcon,
        header: 'Where to go?',
        subheader: 'These are available teams associated with your emails',
    };
};
