import { useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RoleTypes, UserTeamSettingType } from '../../../api/types';
import { useUpdateUserAvatar } from '../../../queries/settings';
import {
    useGetSetting,
    useMeQueryData,
    useUpdateMe,
    useUpdateMeSettings,
} from '../../../queries/user';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { RoleSelect } from './RoleSelect';
import AvatarControl from '../../../components/AvatarControl/AvatarControl';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';

type Inputs = {
    firstname: string;
    lastname: string;
    email: string;
    role: RoleTypes;
    signature: string;
};

export const Profile = () => {
    const currentUser = useMeQueryData();
    const formElementRef = useRef<HTMLFormElement>(null);
    const { data: signature } = useGetSetting(UserTeamSettingType.Signature);

    const { mutate: updateSetting, isPending: isSettingsSavePending } =
        useUpdateMeSettings();
    const { mutate: updateMe, isPending: isMeSavePending } = useUpdateMe();
    const updateUserAvatar = useUpdateUserAvatar();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<Inputs>({
        defaultValues: {
            firstname: currentUser?.firstname,
            lastname: currentUser?.lastname,
            email: currentUser?.email,
            role: currentUser?.activeRole?.type,
            signature: signature?.data?.signature,
        },
    });

    const onReset = () => {
        reset();
    };

    useEffect(() => {
        // needed in case of slow data fetching
        reset({
            firstname: currentUser?.firstname,
            lastname: currentUser?.lastname,
            email: currentUser?.email,
            role: currentUser?.activeRole?.type,
            signature: signature?.data?.signature,
        });
    }, [signature?.data?.signature, currentUser]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (
            data.firstname !== currentUser?.firstname ||
            data.lastname !== currentUser?.lastname
        ) {
            updateMe({ firstname: data.firstname, lastname: data.lastname });
        }

        if (data.signature !== signature?.data?.signature) {
            updateSetting({
                type: UserTeamSettingType.Signature,
                data: {
                    signature: data.signature,
                },
            });
        }
    };

    return (
        <SettingsLayout
            title="Profile"
            description="All of the information about you"
        >
            <form onSubmit={handleSubmit(onSubmit)} ref={formElementRef}>
                <Stack spacing={10}>
                    <Stack spacing={4}>
                        <Typography variant="caption" color="custom.gray.super">
                            Avatar
                        </Typography>
                        <AvatarControl
                            title="User avatar"
                            imageUrl={currentUser?.avatar?.url}
                            uploadImageMutation={updateUserAvatar}
                            uploadImageErrorMessage="There was an error while uploading your avatar"
                        />
                    </Stack>
                    <Stack spacing={4}>
                        <Typography variant="caption" color="custom.gray.super">
                            Personal data
                        </Typography>
                        <Stack spacing={6}>
                            <Stack
                                spacing={4}
                                direction={{ xs: 'column', sm: 'row' }}
                            >
                                <TextField
                                    {...register('firstname', {
                                        required: true,
                                    })}
                                    error={!!errors.firstname}
                                    fullWidth
                                    label="First Name"
                                />

                                <TextField
                                    {...register('lastname', {
                                        required: true,
                                    })}
                                    error={!!errors.lastname}
                                    fullWidth
                                    label="Last Name"
                                />
                            </Stack>
                            <Stack
                                spacing={4}
                                direction={{ xs: 'column', sm: 'row' }}
                            >
                                <TextField
                                    {...register('email')}
                                    error={!!errors.email}
                                    fullWidth
                                    label="Email"
                                    InputProps={{
                                        notched: false,
                                        readOnly: true,
                                    }}
                                />

                                <Controller
                                    control={control}
                                    name="role"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <RoleSelect
                                            disabled
                                            fullWidth
                                            label="Role"
                                            error={!!errors.role}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack spacing={4}>
                        <Typography variant="caption" color="custom.gray.super">
                            Signature
                        </Typography>

                        <Controller
                            control={control}
                            name="signature"
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <TextField
                                    fullWidth
                                    error={!!errors.signature}
                                    name="signature"
                                    placeholder="i.e sent from Alice Gem at Clerk Chat"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    multiline
                                />
                            )}
                        />
                    </Stack>

                    <Stack spacing={4} direction="row">
                        <Button
                            sx={{ width: 152 }}
                            type="submit"
                            disabled={isSettingsSavePending || isMeSavePending}
                        >
                            Save changes
                        </Button>
                        <Button
                            sx={{ width: 96 }}
                            variant="text"
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </SettingsLayout>
    );
};
