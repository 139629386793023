import cc from 'classcat';
import styles from './ContactsTableToolbar.module.scss';
import Button from '../../elements/Buttons';
import Tooltip from '../../elements/Tooltip';
import Icon from '../../icons/Icon';
import { useRemoveContactsFromCohortsMutation } from '../../queries/cohorts';
import { UUID } from '../../types/uuid';
import { useModalContext } from '../../containers/modal/reducer';
import CreateCohortModal from '../Modals/CreateCohortModal';

interface ContactsTableToolbarProps {
    selected: Set<UUID>;
    selectedCohortId?: number;
    className?: string;
    setSelected: (contactIds: Set<UUID>) => void;
    onMove: () => void;
    onExport: () => void;
    onDelete: () => void;
    onCancel: () => void;
}

export const ContactsTableToolbar = (props: ContactsTableToolbarProps) => {
    const { spawnModal, removeModal } = useModalContext();
    const { mutate: removeFromCohort } = useRemoveContactsFromCohortsMutation();

    const handleCreateCohort = () => {
        spawnModal(
            <CreateCohortModal
                selected={props.selected}
                close={() => {
                    props.setSelected(new Set());
                    removeModal();
                }}
            />,
        );
    };

    const handleRemoveFromCohort = () => {
        if (props.selectedCohortId && props.selected.size > 0) {
            removeFromCohort({
                contactIds: Array.from(props.selected),
                cohortIds: [props.selectedCohortId],
            });
            props.setSelected(new Set());
        }
    };

    return (
        <div className={cc([styles['root'], props.className])}>
            <span className={styles['root__selected-text']}>
                {props.selected.size} items selected
            </span>

            {props.selected.size > 0 && (
                <>
                    <div className={styles['root__divider']} />

                    <Tooltip text="Add a cohort with selected contacts" light>
                        <Button
                            className={styles['root__button']}
                            type="icon"
                            icon={<Icon name="add-cohort-action" />}
                            onClick={handleCreateCohort}
                            iconCenter
                        />
                    </Tooltip>
                </>
            )}

            <div className={styles['root__divider']} />

            {typeof props.selectedCohortId !== 'undefined' && (
                <Tooltip text="Remove from a cohort" light>
                    <Button
                        className={styles['root__button']}
                        type="icon"
                        icon={<Icon name="remove-cohort-action" />}
                        onClick={handleRemoveFromCohort}
                        iconCenter
                    />
                </Tooltip>
            )}

            <Tooltip text="Add to..." light>
                <Button
                    className={styles['root__button']}
                    type="icon"
                    icon={<Icon name="move-to" />}
                    onClick={props.onMove}
                    iconCenter
                />
            </Tooltip>

            <div className={styles['root__divider']} />

            <Tooltip text="Export to CSV" light>
                <Button
                    className={styles['root__button']}
                    type="icon"
                    icon={<Icon name="import" />}
                    onClick={props.onExport}
                    iconCenter
                />
            </Tooltip>

            <div className={styles['root__divider']} />

            <Tooltip text="Delete selected contacts" light>
                <Button
                    className={styles['root__button']}
                    type="icon"
                    icon={<Icon name="delete" />}
                    onClick={props.onDelete}
                    iconCenter
                />
            </Tooltip>

            <div className={styles['root__divider']} />

            <Button
                className={styles['root__button']}
                type="text"
                onClick={props.onCancel}
            >
                Cancel
            </Button>
        </div>
    );
};
