import { StateUpdater } from 'preact/hooks';
import styles from './SideBarNavigation.module.scss';
import NavigationItem from '../../../elements/Navigation';
import appleIcon from '../../../assets/icons/other/mac.svg';
import Icon from '../../../icons/Icon';
import { AnalyticsIcon } from './AnalyticsIcon';
import { WithPermission } from '../../WithPermission/WithPermission';
import { ClerkPermissions } from '../../../api/types';
import { isMobile } from '../../../utils/mobile';

const PlatformIcon = () =>
    navigator.platform.indexOf('Mac') > -1 ? (
        <img src={appleIcon} />
    ) : navigator.platform.indexOf('Win') > -1 ? (
        <span>Ctrl</span>
    ) : (
        <span></span>
    );

interface SideBarNavigationProps {
    setMenuOpen: StateUpdater<boolean>;
    setPromptOpen: StateUpdater<boolean>;
}

export const SideBarNavigation = ({
    setMenuOpen,
    setPromptOpen,
}: SideBarNavigationProps) => {
    return (
        <nav className={styles['root']}>
            <div
                className={styles.searchContainer}
                onClick={() => setPromptOpen(true)}
            >
                <span>Search</span>
                <div className={styles['searchContainer__platform_btn']}>
                    <PlatformIcon /> k
                </div>
            </div>
            <NavigationItem
                href="/contacts"
                icon={<Icon name="contacts" size="20px" />}
                path="/contacts/:rest*"
                onClick={() => setMenuOpen(false)}
                label="Contacts"
            />
            <WithPermission
                requiredPermissions={[ClerkPermissions.ViewAnalytics]}
            >
                {!isMobile() && (
                    <NavigationItem
                        href="/analytics"
                        icon={<AnalyticsIcon />}
                        onClick={() => setMenuOpen(false)}
                        label="Analytics"
                    />
                )}
            </WithPermission>
            {!isMobile() && (
                <NavigationItem
                    href="/workflows"
                    icon={<Icon name="workflows" size="20px" />}
                    onClick={() => setMenuOpen(false)}
                    label="Workflows"
                />
            )}
            {!isMobile() && (
                <NavigationItem
                    href="/settings/profile"
                    icon={<Icon name="settings" size="20px" />}
                    onClick={() => setMenuOpen(false)}
                    label="Settings"
                />
            )}
        </nav>
    );
};
