import { isPlainObject } from 'lodash';
import { Contact, ContactPropertyType } from '../../api/types';
import {
    PropertySources,
    UserPropertyModel,
} from '../../components/UserInfoProperty/types';

export type GroupedProperties = Record<PropertySources, UserPropertyModel[]>;

export const getInitialVlue = () =>
    Object.values(PropertySources).reduce<GroupedProperties>((acc, value) => {
        acc[value] = [];
        return acc;
    }, {} as GroupedProperties);

export const getValueFromProperty = (
    contact: Partial<Contact>,
    property: UserPropertyModel,
) => {
    switch (property.type) {
        case ContactPropertyType.LastEditedTime:
            return contact?.updated;
        case ContactPropertyType.CreatedTime:
            return contact?.created;
        default:
            return isPlainObject(contact?.data?.[property.id])
                ? undefined
                : contact?.data?.[property.id];
    }
};

export const getTitle = (source: PropertySources): string => {
    const titles: Record<PropertySources, string> = {
        [PropertySources.Clerk]: 'About',
        [PropertySources.Google]: 'Google',
        [PropertySources.Microsoft]: 'Microsoft',
        [PropertySources.Salesforce]: 'Salesforce',
        [PropertySources.Shopify]: 'Shopify',
        [PropertySources.Hubspot]: 'Hubspot',
        [PropertySources.Pipedrive]: 'Pipedrive',
        [PropertySources.Compliance]: '',
    };

    return titles[source] || '';
};
