import { Contact } from '../api/types';
import { CONTACT, CONTACTS_KEY } from '../queries/contacts';
import { queryClient } from '../queries/queryClient';

export const CONTACT_CREATED_EVENT = 'contact:created';

type DataPayload = Contact;

export const handleContactCreatedEvent = async (data: DataPayload) => {
    await queryClient.refetchQueries({ queryKey: [CONTACT, data.id] });
    await queryClient.invalidateQueries({ queryKey: [CONTACTS_KEY] });
};
