import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ArrowDownIcon } from './ArrowDownIcon';
import * as styles from './styles';
import {
    EnhancedSelect,
    EnhancedSelectProps,
} from '../EnhancedSelect/EnhancedSelect';

type TableSelectProps<Value> = EnhancedSelectProps<Value> & {
    width?: number;
};

export function TableSelect<Value = unknown>({
    width,
    placeholder,
    options,
    ...rest
}: TableSelectProps<Value>) {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <EnhancedSelect
            variant="standard"
            placeholder={placeholder}
            options={options}
            sx={{
                ...styles.select,
                width: width ?? 'auto',
                color: isOpened ? 'info.main' : 'primary.dark',
            }}
            onOpen={() => setIsOpened(true)}
            onClose={() => setIsOpened(false)}
            renderValue={(selected: Value) => {
                if ((!selected || selected.length === 0) && placeholder) {
                    return (
                        <Typography variant="body3" mr={3} noWrap>
                            {placeholder}
                        </Typography>
                    );
                }

                if (Array.isArray(selected)) {
                    const label =
                        options.find((o) => o.id === selected[0])?.label ?? '';
                    const restLength = selected.length - 1;
                    return (
                        <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                        >
                            <Typography variant="body3" mr={3} noWrap>
                                {label}
                            </Typography>
                            {!!restLength && (
                                <Typography
                                    variant="body4"
                                    color="info.main"
                                    sx={styles.plusN}
                                >
                                    {' '}
                                    +{restLength}
                                </Typography>
                            )}
                        </Stack>
                    );
                }

                return (
                    <Typography variant="body3" mr={3} noWrap>
                        {options.find((o) => o.id === selected)?.label}
                    </Typography>
                );
            }}
            IconComponent={ArrowDownIcon}
            menuMaxWidth={300}
            {...rest}
        />
    );
}
