import cc from 'classcat';
import { useRef, useState } from 'preact/hooks';
import { createPortal, CSSProperties } from 'preact/compat';
import { usePopper } from 'react-popper';
import styles from './SelectedCohortsList.module.scss';
import Icon from '../../icons/Icon';
import { ConversationsFilter, ConversationsFilterType } from '../../api/types';
import useOnClickOutside from '../../hooks/useOnClickOutside';

type PopperElem = HTMLElement | null;

interface Props {
    filter: ConversationsFilter;
    onChange: (filter: ConversationsFilter) => void;
}

export const SelectedCohortsList = (props: Props) => {
    const [isExpanded, setExpanded] = useState(false);

    const ulRef = useRef<HTMLUListElement>(null);
    useOnClickOutside(ulRef, () => setExpanded(false));

    const [referenceElement, setReferenceElement] = useState<PopperElem>(null);
    const [popperElement, setPopperElement] = useState<PopperElem>(null);
    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        referenceElement,
        popperElement,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    );

    const removeSelected = (cohortId: number) => {
        if (props.filter.cohortsList.length === 1) {
            props.onChange({
                type: ConversationsFilterType.Active,
                cohortsList: [],
            });
        } else {
            props.onChange({
                type: ConversationsFilterType.ByCohorts,
                cohortsList: props.filter.cohortsList.filter(
                    (c) => c.id !== cohortId,
                ),
            });
        }
    };

    const visibleCohorts = props.filter.cohortsList.slice(0, 3);
    const collapsedCohorts = props.filter.cohortsList.slice(3);

    return (
        <div className={styles['root']}>
            <h6 className={styles['root__title']}>Applied cohorts</h6>

            <ul className={styles['root__list']}>
                {visibleCohorts.map(({ id, name }) => (
                    <li key={id} className={styles['root__item']}>
                        <span>{name}</span>
                        <button
                            className={styles['root__remove-btn']}
                            onClick={() => removeSelected(id)}
                        >
                            <Icon name="close" size="16px" />
                        </button>
                    </li>
                ))}

                {collapsedCohorts.length > 0 && (
                    <li className={styles['root__more-btn-container']}>
                        <button
                            ref={setReferenceElement}
                            className={cc([
                                styles['root__more-btn'],
                                {
                                    [styles['root__more-btn_active']]:
                                        isExpanded,
                                },
                            ])}
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(!isExpanded);
                            }}
                        >
                            {`+${collapsedCohorts.length}`}
                        </button>
                        {isExpanded &&
                            createPortal(
                                <div
                                    className={styles['root__more-container']}
                                    ref={setPopperElement}
                                    style={popperStyles.popper as CSSProperties}
                                    {...popperAttributes.popper}
                                >
                                    <ul
                                        className={styles['root__more-list']}
                                        ref={ulRef}
                                    >
                                        {collapsedCohorts.map((item) => (
                                            <li
                                                key={item.id}
                                                className={
                                                    styles['root__more-item']
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles[
                                                            'root__more-item-text'
                                                        ]
                                                    }
                                                    title={item.name}
                                                >
                                                    {item.name}
                                                </span>
                                                <button
                                                    className={
                                                        styles[
                                                            'root__more-item-remove-btn'
                                                        ]
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        if (
                                                            collapsedCohorts.length ===
                                                            1
                                                        ) {
                                                            setExpanded(false);
                                                        }

                                                        removeSelected(item.id);
                                                    }}
                                                >
                                                    <Icon
                                                        name="close"
                                                        size="16px"
                                                    />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>,
                                document.querySelector('#portal')!,
                            )}
                    </li>
                )}
            </ul>
        </div>
    );
};
