import { OptionId } from '../elements/AdvancedSelect';
import client from './http';
import {
    BandwidthPhone,
    Inbox,
    InboxAssignment,
    InboxProvider,
    TeamsPhoneResponse,
} from './types';

export function markAllAsReadForInbox({ inboxId }: { inboxId: string }) {
    return client().post(`/inbox/${inboxId}/markAllAsRead`);
}
export type SearchPhonesParams = {
    areaCode: number;
    quantity?: number;
};

export function searchPhones({ areaCode, quantity }: SearchPhonesParams) {
    const config = quantity ? { params: { quantity } } : {};

    return client()
        .get<BandwidthPhone>(`/phones/search/${areaCode}`, config)
        .then((r) => r.data.map((p) => ({ id: p, number: p })));
}

export type ReservePhoneDto = {
    phone: string;
    provider: InboxProvider;
    name?: string;
    twilioSid?: string;
    twilioToken?: string;
    telnyxApiKey?: string;
};

export type ListChanneslDto = {
    provider: InboxProvider;
    twilioSid?: string;
    twilioToken?: string;
    telnyxApiKey?: string;
};

export function getInboxes() {
    return client()
        .get<Inbox[]>('/inbox')
        .then(({ data }) => data);
}

export function getUserInboxAssignments() {
    return client()
        .get<InboxAssignment[]>('/inbox/user-inbox-assignments')
        .then(({ data }) => data);
}

export function listAvailableChannels(dto: ListChanneslDto) {
    return client()
        .post<string[]>('/inbox-provisioner/list', dto)
        .then(({ data }) => data);
}

export function reservePhone(dto: ReservePhoneDto) {
    return client()
        .post<Inbox>('/inbox-provisioner/provision', dto)
        .then(({ data }) => data);
}

export function updateInbox(inbox: Partial<Inbox>) {
    return client()
        .put(`/inbox/${inbox.id}`, inbox)
        .then(({ data }) => data);
}

export function addUserToInbox({
    inboxId,
    userId,
}: {
    inboxId: string;
    userId: string;
}) {
    return client()
        .put(`/inbox/${inboxId}/addUser`, { userId })
        .then(({ data }) => data);
}

export function removeUserFromInbox({
    inboxId,
    userId,
}: {
    inboxId: string;
    userId: string;
}) {
    return client()
        .put(`/inbox/${inboxId}/removeUser`, { userId })
        .then(({ data }) => data);
}

type InviteInboxDto = {
    inboxId: string;
    inviteId: string;
};

type ExportInboxLogsDto = {
    inboxIds: OptionId[] | string[];
    startDate: Date;
    endDate: Date;
};

export function addInviteeToInbox({ inboxId, inviteId }: InviteInboxDto) {
    return client()
        .put(`/inbox/${inboxId}/addInvitee`, { inviteId })
        .then(({ data }) => data);
}

export function removeInviteeFromInbox({ inboxId, inviteId }: InviteInboxDto) {
    return client()
        .put(`/inbox/${inboxId}/removeInvitee`, { inviteId })
        .then(({ data }) => data);
}

export function exportInboxLogs({
    inboxIds,
    startDate,
    endDate,
}: ExportInboxLogsDto) {
    return client()
        .post(
            `/conversations/export/all`,
            { inboxIds, startDate, endDate },
            { responseType: 'blob' },
        )
        .then(({ data }) => data);
}

export function removeInbox({ inboxId }: { inboxId: string }) {
    return client().delete(`/inbox/${inboxId}`);
}

export function checkHostability({ phone }: { phone: string }) {
    return client()
        .post<{ hostable: boolean }>(`/inbox-hosting/check-hostability`, {
            phone,
        })
        .then(({ data }) => data);
}

export function hostInbox({ inboxId }: { inboxId: string }) {
    return client()
        .post<{ hostable: boolean }>(`/inbox-hosting/host`, {
            inboxId,
        })
        .then(({ data }) => data);
}

export function sendVerificationCall({ phone }: { phone: string }) {
    return client()
        .post(`/inbox-hosting/send-verification-call`, {
            phone,
        })
        .then(({ data }) => data);
}

export function verifyVerificationCallCode({
    phone,
    code,
}: {
    phone: string;
    code: string;
}) {
    return client()
        .post(`/inbox-hosting/verify-verification-call-code`, {
            phone,
            code,
        })
        .then(({ data }) => data);
}

export function portInbox({ inboxId }: { inboxId: string }) {
    return client()
        .post<{ hostable: boolean }>(`/inbox-hosting/port`, {
            inboxId,
        })
        .then(({ data }) => data);
}

export function createBulkForTeams(payload: TeamsPhoneResponse) {
    return client()
        .post(`/inbox-hosting/create-bulk`, payload)
        .then(({ data }) => data);
}

export function archiveAllForInbox(payload: { inboxId: string }) {
    return client()
        .post(`/inbox/${payload.inboxId}/archiveAll`)
        .then(({ data }) => data);
}
