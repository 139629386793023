import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import client from '../../../api/http';
import { Attachment, Conversation, Profile } from '../../../api/types';
import { queryClient } from '../../../queries/queryClient';
import { UUID } from '../../../types/uuid';
import { useTrack } from '../../../contexts/analytics';
import { AtLeastOne } from '../../../utils/types';

export enum CampaignMessageStatus {
    Created = 'created',
    Planned = 'planned',
    Running = 'running',
    Fulfilled = 'fulfilled',
    SmsExceeded = 'sms_exceeded',
    NoContacts = 'no_contacts',
}

export interface CampaignMessage {
    id: number;
    userId: string;
    body: string;
    campaignId: number;
    timestamp: string;
    sentCount: number;
    status: CampaignMessageStatus;
    attachments: Attachment[];
    user: Profile;
}

const CAMPAIGN_MESSAGES = 'campaign_messages';

export const useCampaignMessages = (campaignId: number | undefined) =>
    useQuery({
        queryKey: [CAMPAIGN_MESSAGES, campaignId],
        queryFn: () =>
            client()
                .get<CampaignMessage[]>(`/campaigns/${campaignId}/messages`)
                .then(({ data }) => data),
        enabled: !!campaignId,
    });

const CONVERSATION_PAGE_LIMIT = 15;

type ConversationPage = {
    data: Conversation[];
    page: number;
    limit: number;
    total: number;
};

export const useCampaignMessageConversations = (campaignMessageId: number) =>
    useInfiniteQuery<ConversationPage>({
        initialPageParam: 1,
        queryKey: ['campaign_message_conversations', campaignMessageId],
        getNextPageParam: (last) => {
            return (last?.data && last.data.length) < CONVERSATION_PAGE_LIMIT
                ? null
                : (last?.page || 0) + 1;
        },
        queryFn: ({ queryKey: [_, id], pageParam }) => {
            return client()
                .get<ConversationPage>(
                    `/campaign-messages/${id}/conversations`,
                    {
                        params: {
                            limit: CONVERSATION_PAGE_LIMIT,
                            page: pageParam,
                        },
                    },
                )
                .then(({ data }) => data);
        },
        enabled: !!campaignMessageId,
    });

const CAMPAIGN_MESSAGE = 'campaign_message';

export const useCampaignMessage = (campaignMessageId: number) =>
    useQuery({
        queryKey: [CAMPAIGN_MESSAGE, campaignMessageId],
        queryFn: ({ queryKey: [_, id] }) =>
            client()
                .get<CampaignMessage>(`/campaign-messages/${id}`)
                .then(({ data }) => data),
        enabled: !!campaignMessageId,
    });

type CampaignCreate = {
    campaignId: number;
    body: string;
    timestamp?: Date;
    attachments?: UUID[];
};

export const useCreateCampaignMessage = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (message: CampaignCreate) =>
            client()
                .post<CampaignMessage>(`/campaign-messages/`, message)
                .then(({ data }) => data),
        onSuccess: (message: CampaignMessage) => {
            track('campaign_message_created', {
                type:
                    message.status === CampaignMessageStatus.Planned
                        ? 'planned'
                        : 'instant',
                campaignId: message.campaignId,
                bodyLength: message.body.length,
                attachmentsCount: message.attachments.length,
            });

            queryClient.invalidateQueries({
                queryKey: [CAMPAIGN_MESSAGES, message.campaignId],
            });
        },
    });
};

export const useCampaignMessageRemove = (id: number) => {
    const track = useTrack();

    return useMutation({
        mutationFn: () =>
            client()
                .delete<void>(`/campaign-messages/${id}`)
                .then(({ data }) => data),
        onSuccess: () => {
            track('campaign_message_deleted');
            queryClient.invalidateQueries({
                queryKey: [CAMPAIGN_MESSAGE, id],
            });
            queryClient.setQueriesData<CampaignMessage[]>(
                { queryKey: [CAMPAIGN_MESSAGES] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return prev.filter((message) => message.id !== id);
                },
            );
        },
    });
};

type UpdateParams = AtLeastOne<{
    attachments: UUID[];
    body: string;
    timestamp: string | undefined;
}>;

export const useCampaignMessageUpdate = (id?: number) =>
    useMutation({
        mutationKey: ['campaign_message_edit', id],
        mutationFn: (params: UpdateParams) =>
            client()
                .patch<
                    Partial<CampaignMessage> & { id: CampaignMessage['id'] }
                >(`/campaign-messages/${id}`, params)
                .then(({ data }) => data),
        onSuccess: (updated) => {
            queryClient.setQueryData<CampaignMessage>(
                [CAMPAIGN_MESSAGE, id],
                (prev) => (prev ? { ...prev, ...updated } : prev),
            );
            queryClient.setQueriesData<CampaignMessage[]>(
                { queryKey: [CAMPAIGN_MESSAGES] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return prev.map((message) =>
                        message.id === id
                            ? { ...message, ...updated }
                            : message,
                    );
                },
            );
        },
    });
