import styles from './MSTeamsPhoneNumbers.module.scss';
import { useEffect } from 'preact/hooks';
import { useGetMicrosoftTeamsPhones } from '../../../queries/integrations';
import StepShell from '../StepShell';
import Loading from '../../../components/Loading/Loading';
import Icon from '../../../icons/Icon';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';
import { UUID } from '../../../types/uuid';
import { useMeQueryData } from '../../../queries/user';
import FormButton from '../../../elements/FormButton';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';

const buildLoginURL = (userId: UUID) => {
    const url = new URL(import.meta.env.VITE_MS_ADMIN_LOGIN_URL);
    url.searchParams.append(
        'state',
        JSON.stringify({
            userId,
            redirectUri: import.meta.env.VITE_MS_ADMIN_REDIRECT_URI,
        }),
    );
    return url.href;
};

export const MSTeamsAuthStep = (props: MSTeamsPhoneNumbersProps) => {
    /* Queries */
    const { error, isPending, refetch, isRefetching } =
        useGetMicrosoftTeamsPhones();
    const me = useMeQueryData();
    const { dispatch } = useAnalytics();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (error?.status !== 401 && !isRefetching && !isPending) {
            props.goForward();
        }
    }, [error, isRefetching, isPending]);

    return (
        <StepShell
            title="Import Microsoft Teams Phones"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle={
                !isPending
                    ? 'Authenticate to Microsoft to add your existing Microsoft Teams phone numbers. You need to be a Microsoft Teams admin to do this.'
                    : 'Select a phone number to import'
            }
        >
            <div className={styles['root']}>
                {(isPending || isRefetching) && <Loading />}
                {!isPending && !isRefetching && (
                    <FormButton
                        onClick={() => {
                            dispatch({
                                type: AnalyticsActions.TRACK,
                                payload: {
                                    eventName:
                                        AnalyticsEventName.CLICKED_AUTHENTICATE_TO_MS,
                                },
                            });
                            window.open(
                                buildLoginURL(me!.id),
                                '_blank',
                                'noopener, noreferrer',
                            );
                        }}
                        text="Authenticate to Microsoft"
                        variant="clear"
                        iconName="microsoft-logo"
                    />
                )}
            </div>
        </StepShell>
    );
};
