import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const DotIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#D5DBE5" />
        </svg>
    </SvgIcon>
);
