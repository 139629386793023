import {
    FilterValue,
    UserPropertyFilterCondition,
} from '../../UserInfoProperty/types';

export const alternateWithAnd = (suggestions: FilterValue[]) =>
    suggestions.reduce((result: FilterValue[], suggestion, index) => {
        if (suggestions.length === 1) {
            return [suggestion];
        }
        if (index < suggestions.length - 1) {
            return result.concat(suggestion, UserPropertyFilterCondition.AND);
        }
        return result.concat(suggestion);
    }, []);
