import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './DroplistItems.module.scss';

interface DroplistItemsProps {
    children?: ComponentChildren;
    className?: string;
}

export const DroplistItems = (props: DroplistItemsProps) => (
    <div className={cc([styles['root'], props.className])}>
        {props.children}
    </div>
);
