import cc from 'classcat';
import styles from './ConversationAutocomplete.module.scss';
import { CohortMeta, Contact, Conversation } from '../../api/types';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import CheckBox from '../../elements/CheckBox/CheckBox';
import Icon from '../../icons/Icon';
import Badge from '../../elements/Badge';
import { useNewConversationContext } from '../NewConversation/NewConversationProvider';
import { useContactsQueryData } from '../../queries/contacts';
import { concatContactNames } from '../../utils/contacts';

export type Splitter = {
    type: 'splitter';
    name: 'Contacts' | 'Cohorts' | 'Conversation';
    count?: number;
    action?: () => void;
};

export type Item = Contact | CohortMeta | Conversation;

export type ListItem = Item | Splitter;

interface ConversationAutocompleteItemProps {
    item: ListItem;
    index?: number;
    countryCode?: string;
    onClick?: (contactOrCohort: Item) => void;
    // This style prop is used by react-window lib.
    style?: string;
}

export const ConversationAutocompleteItem = (
    props: ConversationAutocompleteItemProps,
) => {
    const contactsCache = useContactsQueryData();
    const { state } = useNewConversationContext();

    const isSplitter = (props.item as Splitter).type === 'splitter';
    const isContact = typeof (props.item as Item).id === 'string';
    const isConversation =
        typeof (props.item as Conversation).members === 'object';

    if (isSplitter) {
        const splitter = props.item as Splitter;

        return (
            <li
                style={props.style}
                className={cc([
                    styles['suggestion'],
                    styles['suggestion__splitter'],
                ])}
            >
                <h6
                    className={cc([
                        styles['suggestion__inner'],
                        {
                            [styles['suggestion__splitter_second']]:
                                (props?.index || 0) > 0,
                        },
                    ])}
                >
                    {splitter.name}
                    {splitter.count && (
                        <span
                            className={styles['suggestion__splitter-counter']}
                        >
                            {splitter.count}
                        </span>
                    )}
                    {splitter.action && (
                        <button
                            className={styles['suggestion__splitter-action']}
                            onClick={splitter.action}
                        >
                            Select all
                        </button>
                    )}
                </h6>
            </li>
        );
    }

    if (isConversation) {
        const conversation = props.item as Conversation;
        const convoContacts = conversation.members.map(
            (phone) => contactsCache.getByPhoneOrEmpty(phone) as Contact,
        );

        return (
            <li
                style={props.style}
                className={cc([
                    styles['suggestion'],
                    styles['suggestion__regular'],
                ])}
                onClick={() =>
                    state.onFinish?.(conversation.id, 'conversation')
                }
            >
                <div className={cc([styles['suggestion__inner']])}>
                    <CheckBox checked={false} disabled={true} />

                    <p className={styles['suggestion__name']}>
                        {concatContactNames(convoContacts, props.countryCode)}
                    </p>
                </div>
            </li>
        );
    }

    if (isContact) {
        const contact = props.item as Contact;

        return (
            <li
                style={props.style}
                className={cc([
                    styles['suggestion'],
                    styles['suggestion__regular'],
                    {
                        [styles['suggestion__regular_disabled']]:
                            contact.optOut,
                    },
                ])}
                onClick={() => !contact.optOut && props.onClick?.(contact)}
            >
                <div className={cc([styles['suggestion__inner']])}>
                    <CheckBox
                        checked={state.selectedContactIds.includes(contact.id)}
                        disabled={contact.optOut}
                    />

                    <p className={styles['suggestion__name']}>{contact.name}</p>

                    {contact.phone && (
                        <p className={styles['suggestion__extra-info']}>
                            {formatPhoneNumber(
                                contact.phone,
                                props.countryCode,
                            )}
                        </p>
                    )}
                    {contact.email && (
                        <p
                            className={cc([
                                styles['suggestion__extra-info'],
                                styles['suggestion__extra-info__email'],
                            ])}
                        >
                            {contact.email}
                        </p>
                    )}

                    {contact.optOut && (
                        <Badge
                            color="#E1655A"
                            className={styles['suggestion__badge']}
                        >
                            <Icon size="16px" name="opt-out" />
                            <span>Opt-out</span>
                        </Badge>
                    )}
                </div>
            </li>
        );
    }

    const cohort = props.item as CohortMeta;

    return (
        <li
            style={props.style}
            className={cc([
                styles['suggestion'],
                styles['suggestion__regular'],
            ])}
            onClick={() => props.onClick?.(cohort)}
        >
            <div className={styles['suggestion__inner']}>
                <CheckBox checked={state.cohortId === cohort.id} />

                <p className={styles['suggestion__name']}>{cohort.name}</p>
            </div>
        </li>
    );
};
