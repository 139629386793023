import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ClockIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                d="M7.99992 14.6667C11.6809 14.6667 14.6666 11.681 14.6666 8C14.6666 4.31904 11.6809 1.33333 7.99992 1.33333C4.31896 1.33333 1.33325 4.31904 1.33325 8C1.33325 11.681 4.31896 14.6667 7.99992 14.6667ZM7.52372 4.19048C7.52372 3.92857 7.738 3.71428 7.99992 3.71428C8.26183 3.71428 8.47612 3.92857 8.47612 4.19048V7.77143L10.6785 9.53334C10.8833 9.69763 10.9166 9.99761 10.7523 10.2024C10.6594 10.319 10.5214 10.3809 10.3809 10.3809C10.2761 10.3809 10.1713 10.3476 10.0833 10.2762L7.70232 8.37144C7.59041 8.28095 7.52375 8.14525 7.52375 8V4.19048H7.52372Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
