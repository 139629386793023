import styles from './CarrierConnect.module.scss';
import LockIcon from '../../../assets/icons/icons-24/gray/lock.svg?react';
import ApiKeyIcon from '../../../assets/icons/icons-24/gray/api_key.svg?react';
import TwilioIcon from '../../../assets/icons/carriers/twilio.svg?react';
import DotLoader from '../../../elements/DotLoader';
import { CarrierConnectProps } from './CarrierConnect';
import { useEffect, useState } from 'preact/hooks';
import StepShell from '../StepShell';
import Button from '../../../elements/Buttons';
import Input from '../../../elements/Inputs/Input';
import { useListChannelsQuery } from '../../../queries/inboxes';
import { InboxProvider } from '../../../api/types';

export const TwilioConnect = ({
    goBackward,
    goForward,
    setCredentials,
}: CarrierConnectProps) => {
    const [sid, setSid] = useState('');
    const [token, setToken] = useState('');
    const [isEnabled, setEnabled] = useState(false);

    const { data, isFetching } = useListChannelsQuery(
        {
            provider: InboxProvider.TWILIO,
            twilioSid: sid,
            twilioToken: token,
        },
        isEnabled,
    );

    useEffect(() => {
        if (data && data.length > 0) {
            setCredentials({ accountSID: sid, authToken: token });
            goForward();
            setEnabled(false);
        }
    }, [data]);

    return (
        <StepShell
            title="Twilio setup"
            subtitle="The Account SID, which acts as a username, and the Auth Token which acts as a password."
            icon={<TwilioIcon />}
            goBackward={goBackward}
        >
            <div className={styles['root__content']}>
                <p className={styles['root__country-field']}>
                    <Input
                        prefix={<ApiKeyIcon />}
                        className={styles['root__area-code-input']}
                        placeholder="Account SID"
                        value={sid}
                        onChange={setSid}
                        fullWidth
                        uncontrolled
                    />
                </p>

                <p className={styles['root__country-field']}>
                    <Input
                        prefix={<LockIcon />}
                        className={styles['root__area-code-input']}
                        placeholder="Auth token"
                        value={token}
                        type="password"
                        onChange={setToken}
                        fullWidth
                        uncontrolled
                    />
                </p>

                <Button
                    className={styles['root__continue-btn']}
                    onClick={() => {
                        setEnabled(true);
                    }}
                    disabled={!(sid && token) || isFetching}
                    fullWidth
                >
                    {isFetching ? <DotLoader /> : 'Proceed'}
                </Button>
            </div>
        </StepShell>
    );
};
