import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useMeQueryData, useTeammates } from '../../../../queries/user';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { UserRow } from './UserRow';

export function UserTable({ searchTerm }: { searchTerm: string }) {
    const me = useMeQueryData();
    const { data: users = [] } = useTeammates();

    const filteredUsers = useMemo(() => {
        const withoutCurrentUser = users.filter((u) => u.id !== me?.id);
        const filtered = searchTerm
            ? withoutCurrentUser.filter(
                  ({ email, firstname, lastname }) =>
                      email?.toLocaleLowerCase().includes(searchTerm) ||
                      firstname?.toLocaleLowerCase().includes(searchTerm) ||
                      lastname?.toLocaleLowerCase().includes(searchTerm),
              )
            : withoutCurrentUser;
        return sortBy(filtered, ['firstname', 'lastname', 'email', 'phone']);
    }, [users, searchTerm]);

    return (
        <Table aria-label="member table">
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Assigned inbox(es)</TableCell>
                    <TableCell>Access level</TableCell>
                    <TableCell width={20}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredUsers.map((user) => (
                    <UserRow key={user.id} user={user} me={me} />
                ))}
            </TableBody>
        </Table>
    );
}
