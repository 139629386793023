import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
import Icon from '../../icons/Icon';
import { useDiscussion } from '../../queries/comments';
import { useMeQueryData, useTeammates } from '../../queries/user';
import DiscussionMessageBox from '../DiscussionMessageBox';
import MessageComment from '../MessageComment';
import styles from './ConversationDiscussion.module.scss';
import {
    getOriginalMessageAuthor,
    getOriginalMessageAvatar,
    isScrollAtTheBottom,
    scrollToBottom,
    scrollToTop,
    transformMessages,
} from './utils';
import { SenderType } from '../../api/types';
import { useContactsQueryData } from '../../queries/contacts';

interface ConversationDiscussionProps {
    discussionId: number;
    onClose: () => void;
}

export const ConversationDiscussion = (props: ConversationDiscussionProps) => {
    const [scrollMode, setScrollMode] = useState<'auto' | 'manual'>('manual');

    const { discussion, isFetched } = useDiscussion(props.discussionId);
    const { data: teammates = [] } = useTeammates();
    const currentUser = useMeQueryData();
    const contacts = useContactsQueryData();

    const scrollRef = useRef<HTMLDivElement>(null);

    const events = transformMessages(discussion?.comments, teammates);

    useEffect(() => {
        const scrollHandler = () => {
            if (isScrollAtTheBottom(scrollRef)) {
                setScrollMode('auto');
            } else {
                setScrollMode('manual');
            }
        };

        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', scrollHandler);

            return () =>
                scrollRef.current?.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    useLayoutEffect(() => {
        if (isFetched) {
            scrollToTop(scrollRef);
        }
    }, [props.discussionId, isFetched]);

    useLayoutEffect(() => {
        if (isFetched && scrollMode === 'auto') {
            scrollToBottom(scrollRef);
        }
    }, [isFetched, discussion?.comments]);

    return (
        <section className={styles['root']}>
            <header className={styles['root__header']}>
                <h6 className={styles['root__title']}>Discussion</h6>
                <button
                    className={styles['root__close-btn']}
                    onClick={props.onClose}
                >
                    <Icon name="close" />
                </button>
            </header>

            <main className={styles['root__content']} ref={scrollRef}>
                {discussion?.message && (
                    <MessageComment
                        className={cc([
                            styles['root__original-message'],
                            {
                                [styles['root__inbound']]:
                                    discussion.message.inbound,
                                [styles['root__campaign']]:
                                    discussion.message.senderType ===
                                    SenderType.CAMPAIGN,
                                [styles['root__workflow']]:
                                    discussion.message.senderType ===
                                    SenderType.WORKFLOW,
                            },
                        ])}
                        comment={{
                            id: discussion.message.id,
                            discussionId: discussion.id,
                            text: discussion.message.body,
                            userId: discussion.message.user?.id,
                            created: new Date(discussion.message.created),
                        }}
                        senderName={getOriginalMessageAuthor(
                            discussion.message,
                            contacts,
                            currentUser?.activeTeam.countryCode,
                        )}
                        senderAvatar={getOriginalMessageAvatar(
                            discussion.message,
                            contacts,
                        )}
                    />
                )}

                <div className={styles['root__comments']}>
                    {events.length > 0 && (
                        <div className={styles['message-delimiter']}>
                            <div
                                className={styles['message-delimiter__line']}
                            />
                            <span className={styles['message-delimiter__text']}>
                                {events.length === 1
                                    ? '1 reply'
                                    : `${events.length} replies`}
                            </span>
                        </div>
                    )}

                    <ul className={styles['root__comments-list']}>
                        {events.map((event) => (
                            <li
                                key={event.comment.id}
                                className={styles['root__comments-list-item']}
                            >
                                <MessageComment
                                    comment={event.comment}
                                    senderName={event.author}
                                    senderAvatar={event.avatar}
                                    attachments={event.comment.attachments}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

                <DiscussionMessageBox discussionId={props.discussionId} />
            </main>
        </section>
    );
};
