import { useMemo } from 'preact/hooks';
import { NIL } from 'uuid';
import { QuickSuggestions } from './QuickSuggestions';
import { SearchResults } from './SearchResults';
import { useNewConversationContext } from '../NewConversationProvider';
import { useConversationFindQuery } from '../../../queries/conversations';

const isSuitableForConvo = (members: string[]) =>
    members.length > 0 && members.length < 11;

export const SearchResultsTab = () => {
    const { state } = useNewConversationContext();

    const isSearchActive = state.quickSearchTerm || state.filters.length > 0;

    const conversationSearchEnabled =
        state.inboxId !== NIL && isSuitableForConvo(state.selectedContactIds);

    const { data: existingConversation } = useConversationFindQuery(
        state.inboxId,
        state.selectedContactIds,
        conversationSearchEnabled,
    );

    const conversation = useMemo(
        () =>
            isSuitableForConvo(state.selectedContactIds) && existingConversation
                ? existingConversation
                : null,
        [state.selectedContactIds, existingConversation],
    );

    return (
        <>
            {!isSearchActive && (
                <QuickSuggestions existingConversation={conversation} />
            )}

            {isSearchActive && (
                <SearchResults existingConversation={conversation} />
            )}
        </>
    );
};
