import { queryClient } from './queryClient';
import {
    deleteInvite,
    getMe,
    getSettings,
    getTeammates,
    inactivateUser,
    InviteRoleDto,
    inviteTeamMembers,
    listInvites,
    resendInvite,
    setInviteRole,
    updateMe,
    updateMeSettings,
} from '../api/user';
import {
    BillingProvider,
    Invite,
    Profile,
    UserTeamSetting,
    UserTeamSettingType,
} from '../api/types';
import { useMutation, useQuery } from '@tanstack/react-query';

export const CURRENT_USER_KEY = 'me';
export const CURRENT_USER_SETTINGS = 'me_settings';
export const TEAMMATES_KEY = 'teammates';
export const INVITES_KEY = 'invites';

export function useMeQuery() {
    return useQuery({
        queryKey: [CURRENT_USER_KEY],
        queryFn: getMe,
        retry: 0,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });
}

export function useMeQueryData() {
    return queryClient.getQueryData<Profile>([CURRENT_USER_KEY]);
}

export function useBillingProviderQueryData(): BillingProvider {
    return queryClient.getQueryData<Profile>([CURRENT_USER_KEY])!.activeTeam
        .billingProvider;
}

export function useUpdateMeSettings() {
    return useMutation({
        mutationKey: ['updateMeSettings'],
        mutationFn: updateMeSettings,
        onMutate: async (setting) => {
            await queryClient.cancelQueries({
                queryKey: [CURRENT_USER_SETTINGS, setting.type],
            });

            const previousSetting = queryClient.getQueryData([
                CURRENT_USER_SETTINGS,
                setting.type,
            ]);
            const previousSettings =
                queryClient.getQueryData([CURRENT_USER_SETTINGS]) || [];

            queryClient.setQueryData(
                [CURRENT_USER_SETTINGS, setting.type],
                /*todo: @will check the correctness `settingS` possibly used insted of `settinG` */
                // @ts-ignore
                (_settings: UserTeamSetting) => [...previousSettings, setting],
            );

            return { previousSettings, previousSetting };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(
                // @ts-ignore
                [CURRENT_USER_SETTINGS, context.previousSetting.type],
                () => [...previousSettings, context?.previousSetting],
            );
        },
        onSettled: (setting) => {
            return queryClient.invalidateQueries({
                queryKey: [CURRENT_USER_SETTINGS, setting.type],
            });
        },
    });
}

export function useGetSetting(type: UserTeamSettingType) {
    return useQuery({
        queryKey: [CURRENT_USER_SETTINGS, type],
        select: (data: UserTeamSetting[]) => data.find((d) => d.type === type),
        queryFn: getSettings,
        staleTime: Infinity,
    });
}

export const useUpdateMe = () =>
    useMutation({
        mutationKey: ['updateMe'],
        mutationFn: updateMe,
        onMutate: async (newMe) => {
            await queryClient.cancelQueries({ queryKey: [CURRENT_USER_KEY] });

            const previousMe = queryClient.getQueryData([CURRENT_USER_KEY]);

            queryClient.setQueryData(
                [CURRENT_USER_KEY],
                (me: Profile | undefined) => {
                    return { ...me, ...newMe };
                },
            );

            return { previousMe };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData([CURRENT_USER_KEY], context?.previousMe);
        },
        onSettled: () => {
            return queryClient.invalidateQueries({
                queryKey: [CURRENT_USER_KEY],
            });
        },
    });

export const useInactivateUser = () =>
    useMutation({
        mutationFn: inactivateUser,
        onSettled: () => {
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.refetchQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });

// Teammates

export const useTeammates = () =>
    useQuery({
        queryKey: [TEAMMATES_KEY],
        queryFn: getTeammates,
        staleTime: Infinity,
    });

export const useTeammatesQueryData = () =>
    queryClient.getQueryData<Profile[]>([TEAMMATES_KEY]);

// Invites

export const useInvites = () =>
    useQuery({
        queryKey: [INVITES_KEY],
        queryFn: listInvites,
    });

export const useInviteTeamMembers = () =>
    useMutation({
        mutationFn: inviteTeamMembers,
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });

export const useDeleteInvite = () =>
    useMutation({
        mutationFn: deleteInvite,
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
        },
    });

export const useSetInviteRole = () =>
    useMutation<Invite, unknown, InviteRoleDto>({
        mutationFn: setInviteRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
        },
    });

export const useResendInvite = () => useMutation({ mutationFn: resendInvite });
