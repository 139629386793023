import IntegrationPropertyMap from '../IntegrationPropertyMap';
import styles from './IntegrationSettings.module.scss';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';

type IntegrationSettingsProps = {
    integrationId: string;
};

export const IntegrationSettings = (props: IntegrationSettingsProps) => {
    return (
        <SettingsLayout
            title="Integrations"
            description="Connect other tools that your team uses to Clerk Chat"
        >
            <div className={styles['root']}>
                <IntegrationPropertyMap integrationId={props.integrationId} />
            </div>
        </SettingsLayout>
    );
};
