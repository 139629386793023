import createClient from '../../api/http';
import { AtLeastOne } from '../../utils/types';
import { UUID } from '../../types/uuid';
import { CONVERSATION_MESSAGES_KEY } from '../../queries/messages';
import { Message, MessageStatus, SenderType } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { AnalyticsEventName } from '../../types/AnalyticsEventNames';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../queries/queryClient';
import type { InfiniteData } from '@tanstack/query-core';
import { PagedData } from '../../types/PagedData';

type UpdateDto = AtLeastOne<{ body: string; timestamp: Date }> & {
    conversationId: UUID;
};

type UpdateParams = {
    id: number;
    message: UpdateDto;
};

type CreateParams = {
    conversationId: UUID;
    body: string;
    attachments?: UUID[];
    timestamp?: Date;
};
export const useMessageCreate = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (message: CreateParams) =>
            createClient()
                .post<Message>(
                    `/conversations/${message.conversationId}/messages`,
                    {
                        attachments: message.attachments,
                        body: message.body,
                        timestamp: message.timestamp,
                    },
                )
                .then(({ data }) => data),
        onMutate: (message: CreateParams) => {
            const previousMessages = queryClient.getQueryData<
                PagedData<Message>
            >([CONVERSATION_MESSAGES_KEY, message.conversationId]);

            if (previousMessages) {
                const { pages } = previousMessages;
                const now = new Date().toString();
                const firstPages = pages[0].concat([
                    {
                        id: -1,
                        conversationId: message.conversationId,
                        status: MessageStatus.Sending,
                        created: now,
                        senderType: SenderType.USER,
                        inbound: false,
                        body: message.body,
                        sender: 'You',
                        attached: [],
                        attachments: [],
                        timestamp: now,
                        sentByName: 'You',
                    },
                ]);

                queryClient.setQueryData<PagedData<Message>>(
                    [CONVERSATION_MESSAGES_KEY, message.conversationId],
                    {
                        ...previousMessages,
                        pages: [firstPages, ...pages.slice(1)],
                    },
                );
            }
        },
        onSuccess: (message: Message, vars: CreateParams) => {
            track('message_created', {
                conversationId: vars.conversationId,
                bodyLength: vars.body.length,
                attachmentsCount: vars.attachments?.length || 0,
                status: message.status,
            });

            /*todo: make it better by merging logic with socket handleNewMessageEvent handler*/
            void queryClient.invalidateQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, message.conversationId],
            });
        },
    });
};

export const useUpdateMessage = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: ({ id, message }: UpdateParams) =>
            createClient().patch<void>(`/messages/${id}`, message),
        onSuccess: (_, { id, message }) => {
            track(AnalyticsEventName.MESSAGE_UPDATED);
            queryClient.setQueryData<InfiniteData<Message[]>>(
                [CONVERSATION_MESSAGES_KEY, message.conversationId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page: Message[]) =>
                            page.map((pageItem) => {
                                return pageItem.id === id
                                    ? {
                                          ...pageItem,
                                          timestamp: message.timestamp
                                              ? message.timestamp
                                              : pageItem.timestamp,
                                          body: message.body
                                              ? message.body
                                              : pageItem.body,
                                      }
                                    : pageItem;
                            }),
                        ),
                    };
                },
            );
        },
    });
};
