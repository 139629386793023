import cc from 'classcat';
import { Link } from 'preact-router/match';
import historyIcon from '../../assets/icons/icons-20/history.svg';
import styles from './OptedOutContactsItem.module.scss';

interface OptedOutContactsItemProps {
    onClick?: () => void;
}

export const OptedOutContactsItem = ({
    onClick,
}: OptedOutContactsItemProps) => (
    <Link
        class={`clerk-reset-link ${styles['scheduled-item']}`}
        activeClassName={styles['scheduled-item_active']}
        path="/opted-out/:rest*"
        href="/opted-out/"
        onClick={onClick}
    >
        <div className={styles['scheduled-item__icon_placeholder']}>
            <button className={styles['scheduled-item__icon_btn']}>
                <img
                    className={styles['scheduled-item__icon']}
                    src={historyIcon}
                />
            </button>
        </div>

        <div className={cc([styles['scheduled-item__description']])}>
            <p className={cc([styles['scheduled-item__name']])}>Opted Out</p>
        </div>
    </Link>
);
