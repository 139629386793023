import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: [theme.spacing(0.5), theme.spacing(2)],
            },
        }),
        inputRoot: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
};
