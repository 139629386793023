import { ChangeEvent, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Stack,
    TextField,
    Typography,
    FormHelperText,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyIcon } from './icons/CompanyIcon';
import { AddressIcon } from './icons/AddressIcon';
import { CityIcon } from './icons/CityIcon';
import { EnhancedSelect } from '../../../../components/EnhancedSelect/EnhancedSelect';
import {
    countriesOptions,
    FormData,
    organizationLegalFormOptions,
    validationSchema,
    verticalOptions,
} from '../utils';
import { CountryIcon } from './icons/CountryIcon';
import { ZipIcon } from './icons/ZipIcon';
import { LegalIcon } from './icons/LegalIcon';
import { EinIcon } from './icons/EinIcon';
import {
    useBrandCreateQuery,
    useBrandUpdateQuery,
} from '../../../../queries/settings';
import { Brand } from '../../../../api/types';
import { PhoneIcon } from './icons/PhoneIcon';
import { EmailIcon } from './icons/EmailIcon';

const rowDirection = { xs: 'column', sm: 'row' } as const;

type Props = {
    data?: Brand;
};

export function Form({ data }: Props) {
    const { mutate: createBrand } = useBrandCreateQuery();
    const { mutate: updateBrand } = useBrandUpdateQuery();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (data) {
            const {
                brandName,
                companyName,
                companyAddress,
                companyCountry,
                companyZip,
                city,
                state,
                ein,
                organizationVertical,
                organizationLegalForm,
                contactEmail,
                contactPhone,
            } = data;

            reset({
                brandName: brandName ?? '',
                companyName,
                companyAddress,
                companyCountry,
                companyZip,
                city,
                state,
                ein,
                organizationVertical,
                organizationLegalForm,
                contactEmail,
                contactPhone,
            });
        }
    }, [data]);

    const handleFormSubmit: SubmitHandler<FormData> = (formData) => {
        if (data?.id) {
            updateBrand({ id: data.id, brand: formData });
        } else {
            createBrand(formData);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing={10}>
                <Stack spacing={4}>
                    <Typography variant="caption" color="custom.gray.super">
                        Company details
                    </Typography>

                    <Stack spacing={6}>
                        <Stack
                            spacing={6}
                            direction={{ xs: 'column', sm: 'row' }}
                        >
                            <TextField
                                {...register('companyName', {
                                    required: true,
                                })}
                                error={!!errors.companyName}
                                helperText={
                                    errors.companyName
                                        ? errors.companyName.message
                                        : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                label="Legal company name"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CompanyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                {...register('brandName')}
                                error={!!errors.brandName}
                                helperText={
                                    errors.brandName
                                        ? errors.brandName.message
                                        : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                label="Brand name (if different from legal name)"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CompanyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <TextField
                            {...register('companyAddress', {
                                required: true,
                            })}
                            error={!!errors.companyAddress}
                            helperText={
                                errors.companyAddress
                                    ? errors.companyAddress.message
                                    : null
                            }
                            fullWidth
                            inputProps={{
                                autocomplete: 'off',
                            }}
                            label="Address / Street"
                            InputProps={{
                                notched: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AddressIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Stack spacing={6} direction={rowDirection}>
                            <TextField
                                {...register('city', {
                                    required: true,
                                })}
                                error={!!errors.city}
                                helperText={
                                    errors.city ? errors.city.message : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                label="City"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CityIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                {...register('state', {
                                    required: true,
                                })}
                                error={!!errors.state}
                                helperText={
                                    errors.state ? errors.state.message : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                label="State / Province"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CityIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Stack spacing={6} direction={rowDirection}>
                            <Controller
                                name="companyCountry"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState,
                                }) => (
                                    <FormControl
                                        error={!!fieldState.error}
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="country">
                                            Country
                                        </InputLabel>
                                        <EnhancedSelect
                                            error={!!fieldState.error}
                                            id="country"
                                            searchable
                                            options={countriesOptions}
                                            value={value ?? ''}
                                            onChange={onChange}
                                            startAdornment={
                                                !value && (
                                                    <InputAdornment position="start">
                                                        <CountryIcon />
                                                    </InputAdornment>
                                                )
                                            }
                                        />
                                        {!!fieldState.error && (
                                            <FormHelperText>
                                                {fieldState.error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                            <TextField
                                {...register('companyZip', {
                                    required: true,
                                })}
                                error={!!errors.companyZip}
                                helperText={
                                    errors.companyZip
                                        ? errors.companyZip.message
                                        : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                label="ZIP / Postal Code"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ZipIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Stack spacing={6} direction={rowDirection}>
                            <Controller
                                name="organizationLegalForm"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState,
                                }) => (
                                    <TextField
                                        inputProps={{
                                            autocomplete: 'off',
                                        }}
                                        InputProps={{
                                            notched: false,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LegalIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(event: ChangeEvent) => {
                                            onChange(event.target?.value);
                                        }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        fullWidth
                                        label="Organization legal form"
                                        select
                                        value={value ?? ''}
                                    >
                                        {organizationLegalFormOptions.map(
                                            (option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ),
                                        )}
                                    </TextField>
                                )}
                            />

                            <TextField
                                {...register('ein', {
                                    required: true,
                                })}
                                error={!!errors.ein}
                                helperText={
                                    errors.ein ? errors.ein.message : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                type="number"
                                label="EIN"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EinIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Stack spacing={6} direction={rowDirection}>
                            <TextField
                                {...register('contactPhone', {
                                    required: true,
                                })}
                                error={!!errors.contactPhone}
                                helperText={
                                    errors.contactPhone
                                        ? errors.contactPhone.message
                                        : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                type="phone"
                                label="Contact phone"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                {...register('contactEmail', {
                                    required: true,
                                })}
                                error={!!errors.contactEmail}
                                helperText={
                                    errors.contactEmail
                                        ? errors.contactEmail.message
                                        : null
                                }
                                fullWidth
                                inputProps={{
                                    autocomplete: 'off',
                                }}
                                type="email"
                                label="Contact email"
                                InputProps={{
                                    notched: false,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Controller
                            name="organizationVertical"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState,
                            }) => (
                                <TextField
                                    inputProps={{
                                        autocomplete: 'off',
                                    }}
                                    error={!!fieldState.error}
                                    helperText={
                                        fieldState.error
                                            ? fieldState.error.message
                                            : null
                                    }
                                    label="Vertical type"
                                    select
                                    sx={{
                                        alignSelf: 'flex-start',
                                        width: 'calc(50% - 12px)',
                                    }}
                                    value={value ?? ''}
                                    onChange={(event: ChangeEvent) => {
                                        onChange(event.target?.value);
                                    }}
                                >
                                    {sortBy(verticalOptions, 'label').map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ),
                                    )}
                                </TextField>
                            )}
                        />
                    </Stack>
                </Stack>

                <Button
                    sx={{ alignSelf: 'flex-start', width: 152 }}
                    type="submit"
                >
                    Save
                </Button>
            </Stack>
        </form>
    );
}
