import { route } from 'preact-router';
import BagIcon from '../../../assets/icons/payment-flow/bag.svg?react';
import PaymentPlan from '../../../components/PaymentPlan';
import PaymentPeriodSwitcher from '../../../components/PaymentPeriodSwitcher';
import { getPlansForPeriodByType, PlanItem, PlanPeriod } from '../plans';
import { useMemo, useState } from 'preact/hooks';
import StepShell from '../StepShell';
import { useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useMeQueryData } from '../../../queries/user';
import { setupShopifySubscription } from '../../../api/shopify';
import { Box } from '@mui/material';

export interface PaymentPlansProps {
    selectedPlan: PlanItem | null;
    setSelectedPlan: (plan: PlanItem) => void;
}

export const PaymentPlans = ({
    selectedPlan,
    setSelectedPlan,
}: PaymentPlansProps) => {
    const [period, setPeriod] = useState<PlanPeriod>(
        selectedPlan ? selectedPlan.period : 'yearly',
    );
    const analytics = useAnalytics();
    const me = useMeQueryData();
    const billingProvider = me?.activeTeam?.billingProvider;

    const plans = useMemo(() => getPlansForPeriodByType(period), [period]);

    const dispatchPlanEvent = () =>
        analytics.dispatch({
            payload: {
                eventPayload: {
                    plan: selectedPlan,
                    billing: period,
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                },
                eventName: AnalyticsEventName.USER_CLICKED_PAYMENT_PLAN,
            },
        });

    return (
        <StepShell
            title="Choose your plan"
            subtitle="Select from the following plans. Need something custom? Just reach out."
            icon={<BagIcon />}
        >
            <Box mb={6}>
                <PaymentPeriodSwitcher
                    value={period}
                    onChange={(value) => setPeriod(value)}
                />
            </Box>
            <Box display="flex" justifyConten="center" mx="auto" gap={6}>
                <PaymentPlan
                    planItem={plans['unlimited']}
                    onClick={(clientSecret: string) => {
                        dispatchPlanEvent();
                        setSelectedPlan(plans['unlimited']);

                        if (billingProvider === 'stripe') {
                            route(`/payment/billing-info?s=${clientSecret}`);
                        } else if (billingProvider === 'shopify') {
                            setupShopifySubscription('unlimited', period).then(
                                (response) => {
                                    window.location.href =
                                        response.data.confirmation_url;
                                },
                            );
                        }
                    }}
                />
                <PaymentPlan
                    planItem={plans['starter']}
                    onClick={(clientSecret: string) => {
                        dispatchPlanEvent();
                        setSelectedPlan(plans['starter']);
                        if (billingProvider === 'stripe') {
                            route(`/payment/billing-info?s=${clientSecret}`);
                        } else if (billingProvider === 'shopify') {
                            setupShopifySubscription('starter', period).then(
                                (response) => {
                                    window.location.href =
                                        response.data.confirmation_url;
                                },
                            );
                        }
                    }}
                    basicButton
                />
            </Box>
        </StepShell>
    );
};
