import cc from 'classcat';
import { Link } from 'preact-router/match';
import historyIcon from '../../assets/icons/icons-20/history.svg';
import styles from './ScheduledItem.module.scss';

interface ScheduledItemProps {
    onClick?: () => void;
}

export const ScheduledItem = ({ onClick }: ScheduledItemProps) => (
    <Link
        class={`clerk-reset-link ${styles['scheduled-item']}`}
        activeClassName={styles['scheduled-item_active']}
        path="/scheduled/:rest*"
        href="/scheduled/"
        onClick={onClick}
    >
        <div className={styles['scheduled-item__icon_placeholder']}>
            <button className={styles['scheduled-item__icon_btn']}>
                <img
                    className={styles['scheduled-item__icon']}
                    src={historyIcon}
                />
            </button>
        </div>

        <div className={cc([styles['scheduled-item__description']])}>
            <p className={cc([styles['scheduled-item__name']])}>Scheduled</p>
        </div>
    </Link>
);
