import {
    FilterValue,
    TextTypeValue,
    UserPropertyFilterModel,
} from '../../components/UserInfoProperty/types';

const hasValue = (fv: FilterValue) =>
    !!(fv as UserPropertyFilterModel).value || isExistenceFilter(fv);

const isExistenceFilter = (fv: FilterValue) =>
    (fv as UserPropertyFilterModel<TextTypeValue>).constraint ===
    TextTypeValue.Exists;

export const isSearchActive = (
    searchTerm: string,
    filterValues: FilterValue[],
    cohortIsFetching: boolean,
    cohortId?: number,
) =>
    !!cohortId ||
    ((searchTerm.length > 2 || (filterValues && filterValues.some(hasValue))) &&
        !cohortIsFetching);
