import cc from 'classcat';
import { RefObject } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import Input from '../../../../elements/Inputs/Input';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';
// styles
import styles from './SelectValue.module.scss';
import { FiPlus } from 'react-icons/fi';
import { SelectSuggestionOption } from './SelectSuggestionOption';

interface SelectSuggestionProps {
    options?: Array<string>;
    wrapperRef?: RefObject<HTMLDivElement>;
    onChange?: (id: string) => void;
    onCreate?: (option: string) => void;
    onSave: (oldOption: string, newOption: string) => Promise<unknown>;
    onRemove: (option: string) => void;
}

const getFilteredOptions = (options: Array<string>, filterTerm: string) =>
    options.filter((opt) =>
        opt.toLowerCase().startsWith(filterTerm.toLowerCase()),
    );

export const SelectSuggestion = ({
    options = [],
    wrapperRef,
    onChange,
    onCreate,
    onSave,
    onRemove,
}: SelectSuggestionProps) => {
    const [filterTerm, setFilterTerm] = useState('');
    const [editOption, setEditOption] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    useOnKeyDown(inputRef, 'Enter', () => {
        onCreate?.(filterTerm);
    });

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const filteredOptions = filterTerm
        ? getFilteredOptions(options, filterTerm)
        : options;

    return (
        <div className={styles['select']} ref={wrapperRef}>
            <Input
                className={styles['select__input']}
                value={filterTerm}
                onChange={setFilterTerm}
                ref={inputRef}
            />

            <div className={styles['select__options-container']}>
                {filteredOptions.length > 0 && (
                    <ul className={styles['select__options-list']}>
                        {filteredOptions.map((opt) => (
                            <li
                                key={opt}
                                className={cc([
                                    styles['select__item'],
                                    {
                                        [styles['select__item_transparent']]:
                                            editOption,
                                        [styles['select__item_disabled']]:
                                            editOption && opt !== editOption,
                                    },
                                ])}
                                onClick={() => onChange?.(opt)}
                            >
                                <SelectSuggestionOption
                                    option={opt}
                                    onEdit={setEditOption}
                                    onSave={(value) => onSave(opt, value)}
                                    onRemove={() => onRemove(opt)}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                <div
                    className={cc([
                        styles['select__add-new-wrapper'],
                        {
                            [styles['select__add-new-wrapper_no-border']]:
                                filteredOptions.length === 0,
                        },
                    ])}
                >
                    <button
                        className={styles['add-new-btn']}
                        onClick={() => onCreate?.(filterTerm)}
                    >
                        <FiPlus className={styles['add-new-btn__icon']} />
                        <span>Add new</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
