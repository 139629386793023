import { base } from './styles';
import { Avatar, ButtonBase, Skeleton, Typography } from '@mui/material';
import { getMessageStyle } from '../../ConversationRow/styles';

export const ItemSkeleton = () => (
    <ButtonBase sx={base()} disabled>
        <div>
            <Avatar />
        </div>
        <div>
            <Typography variant="body3">
                <Skeleton width={180} height={14} sx={{ mt: 0.8 }} />
            </Typography>
            <Typography variant="body4" sx={getMessageStyle(false, false)}>
                <Skeleton width={130} height={10} />
                <Skeleton width={100} height={10} />
            </Typography>
        </div>
    </ButtonBase>
);
