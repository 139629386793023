import { useCallback, useEffect } from 'preact/hooks';
import { TargetedEvent } from 'react';
import Tooltip from '../../../../elements/Tooltip';
import { Box, IconButton } from '@mui/material';
import { AttachmentIcon } from './AttachmentIcon';
import * as styles from './styles';
import { memo } from 'preact/compat';
import { CustomEditor } from '../../slate';
import { acceptFileFormats } from '../../../../constants';

type Props = {
    disabled?: boolean;
    uploadRef: React.RefObject<HTMLInputElement>;
    uploadFile: (file: File) => void;
    editor: CustomEditor;
};

export const FileUploaderButton = memo(
    ({ disabled, uploadFile, uploadRef, editor }: Props) => {
        const onFileUpload = useCallback(
            (e: TargetedEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                if (target && target.files) {
                    const file = target.files[0];
                    uploadFile(file);
                }
            },
            [],
        );

        const handleSelectFile = useCallback(() => {
            if (disabled) {
                return;
            }

            if (uploadRef.current) {
                uploadRef.current.click();
            }
        }, [disabled]);

        useEffect(() => {
            const { insertData } = editor;
            editor.insertData = (data) => {
                const file = data?.files?.[0];
                if (file) {
                    uploadFile(file);
                    return;
                }

                insertData(data);
            };
        }, []);

        return (
            <div>
                <Box
                    component="input"
                    sx={styles.fileInput}
                    ref={uploadRef}
                    type="file"
                    accept={acceptFileFormats}
                    onChange={onFileUpload}
                />
                <Tooltip text="Attach File">
                    <IconButton onClick={handleSelectFile} disabled={disabled}>
                        <AttachmentIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    },
);
