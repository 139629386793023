import styles from './WorkflowPlaceholder.module.scss';
import { route } from 'preact-router';
import PlusIcon from '../../../assets/icons/icons-24/white/plus.svg?react';
import placeholderIcon from '../../../assets/icons/workflow_placeholder.svg';
import { Button } from '@mui/material';

export const WorkflowPlaceholder = () => {
    return (
        <div className={styles['workflow-placeholder']}>
            <div className={styles['workflow-placeholder__content']}>
                <div className={styles['workflow-placeholder__text-container']}>
                    <img
                        className={styles['workflow-placeholder__icon']}
                        src={placeholderIcon}
                        alt="workflow"
                    />
                    <h6 className={styles['workflow-placeholder__greeting']}>
                        Welcome to workflows
                    </h6>
                    <p className={styles['workflow-placeholder__description']}>
                        Workflow is the series of activities that are necessary
                        to complete a task.
                    </p>
                    <Button
                        startIcon={<PlusIcon />}
                        onClick={() => route('/workflows/new')}
                    >
                        Create workflow
                    </Button>
                </div>
            </div>
        </div>
    );
};
