import { SxProps } from '@mui/material';

export const avatarHolder: SxProps = {
    width: 20,
    height: 20,
    borderRadius: 0.5,
    overflow: 'hidden',
    flexShrink: 0,
};
export const avatar: SxProps = {
    height: 1,
    width: 1,
    objectFit: 'cover',
};
