import { ComponentChildren, VNode } from 'preact';
import styles from './StepShell.module.scss';
import BackButton from '../BackButton';
import { SetupWizardStep } from '../types';
import { Box, Typography } from '@mui/material';

export interface StepShellProps
    extends Partial<Pick<SetupWizardStep, 'goBackward'>> {
    icon: VNode;
    title: string;
    subtitle: string;
    children: ComponentChildren;
}

export const StepShell = ({
    icon,
    title,
    subtitle,
    children,
    goBackward,
}: StepShellProps) => (
    <div className={styles['root']}>
        {goBackward && <BackButton onClick={goBackward} />}

        <div className={styles['root_inner']}>
            <div className={styles['root__main-icon']}>{icon}</div>
            <Box width={1} textAlign="center" mb={10}>
                <Typography
                    variant="h2bold"
                    component="h2"
                    sx={{ color: 'primary.dark' }}
                >
                    {title}
                </Typography>

                <Typography
                    variant="body3"
                    sx={{ mt: 1 }}
                    className={styles['root__subtitle']}
                >
                    {subtitle}
                </Typography>
            </Box>
            <div className={styles['root__content']}>{children}</div>
        </div>
    </div>
);
