import { useLayoutEffect } from 'preact/hooks';
import { route } from 'preact-router';

export interface RedirectProps {
    to: string;
    replace?: boolean;
}

export function Redirect({ to, replace = true }: RedirectProps) {
    useLayoutEffect(() => {
        route(to, replace);
    }, [to]);

    return null;
}
