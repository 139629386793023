import { Contact, Conversation } from '../../../api/types';
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Divider,
    IconButton,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    actionBar,
    answerPreview,
    popoverAdornment,
    popoverBody,
    popoverMessage,
    square20,
    staticPopoverProps,
} from '../styles';
import { PopoverHeader } from './PopoverHeader';
import { DotDivider } from '../DotDivider';
import { HighlightSubstring } from '../HighlightSubquery';
import dayjs from 'dayjs';
import { useMessageStoppedBy } from '../use-message-stopped-by';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useTrack } from '../../../contexts/analytics';
import { getContactName } from '../../../utils/contacts';
import { useContactsQueryData } from '../../../queries/contacts';
import { useMeQueryData } from '../../../queries/user';
import { SendFilledIcon } from '../SendFilledIcon';
import { RefreshIcon } from '../RefreshIcon';
import { EastRounded } from '@mui/icons-material';
import { MessagePreview } from '../MessagePreview';
import { clearAssistant, useDeleteMessage } from '../../../queries/messages';
import {
    useMessage,
    useReplyRegeneration,
    useSendImmediately,
} from '../use-message.query';
import { saveMessageDraft } from '../../MessageForm/utils';
import { route } from 'preact-router';
import { useInboxContext } from '../../../contexts/InboxContext';
import { useContext } from 'preact/hooks';
import { MessageUpdateContext } from '../../MessageForm/MessageUpdateContext';
import { EditIcon } from '../../../icons/common/CEditIcon';

export const AssistantPopover = (props: {
    conversation: Conversation;
    open: boolean;
    anchorEl: HTMLElement | null;
}) => {
    const track = useTrack();
    const sendNow = useSendImmediately();
    const currentUser = useMeQueryData();
    const contacts = useContactsQueryData();
    const deletion = useDeleteMessage();
    const regeneration = useReplyRegeneration();
    const message = useMessage(props.conversation.assistantMessageId);
    const { actions } = useInboxContext();
    const { setMessage } = useContext(MessageUpdateContext);
    const phrase = props.conversation.lastMessageBody
        ? useMessageStoppedBy(props.conversation.lastMessageBody)
        : '';
    const timestamp = dayjs(
        props.conversation.lastMessageSent ?? props.conversation.created,
    );
    const memberContacts: Partial<Contact>[] = props.conversation.members.map(
        (member) => contacts.getByPhoneOrEmpty(member),
    );

    const recipientDisplay =
        memberContacts.length > 0
            ? memberContacts
                  .map((contact) =>
                      getContactName(
                          contact,
                          currentUser?.activeTeam.countryCode,
                      ),
                  )
                  .join(', ')
            : 'New Conversation';

    const timestampDisplay =
        timestamp.diff(new Date(), 'days') < 0
            ? timestamp.format('MMM DD')
            : timestamp.format('hh:mm');

    const onClick = () => {
        track(AnalyticsEventName.ASSISTANT_POPOVER_CLICKED);
    };

    const regenerate = (e: MouseEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        regeneration.mutate(props.conversation.id);
    };

    const onEdit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (!props.conversation.assistantMessageId || !message.data?.body) {
            return;
        }
        deletion
            .mutateAsync({
                messageId: props.conversation.assistantMessageId,
                conversationId: props.conversation.id,
            })
            .then(() => {
                track(AnalyticsEventName.ASSISTANT_MESSAGE_EDIT);
                saveMessageDraft(
                    props.conversation.id,
                    message.data.body,
                    actions,
                );
                setMessage(message.data.body);
                clearAssistant(
                    props.conversation.id,
                    props.conversation.inboxId,
                );

                route(
                    `/inbox/${props.conversation.inboxId}/${props.conversation.id}`,
                );
            });
    };

    const send = (e: MouseEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (!props.conversation.assistantMessageId) {
            return;
        }

        sendNow
            .mutateAsync({
                messageId: props.conversation.assistantMessageId,
                conversationId: props.conversation.id,
            })
            .then(() => {
                track(AnalyticsEventName.ASSISTANT_MESSAGE_SENT_IMMEDIATELY, {
                    origin: 'assistant_popover',
                });
                clearAssistant(
                    props.conversation.id,
                    props.conversation.inboxId,
                );
            });
    };

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            id={`inbox-message-popover-${props.conversation.id}`}
            {...staticPopoverProps}
        >
            <PopoverHeader isStopped={!!phrase} />
            <Divider />
            <Box p={3}>
                <ButtonBase sx={popoverMessage} onClick={onClick}>
                    <Box display="flex" alignItems="center" typography="body4">
                        <Typography variant="body4" fontWeight="500">
                            {recipientDisplay}
                        </Typography>
                        <DotDivider />
                        <Typography variant="body4" color="custom.gray.super">
                            {timestampDisplay}
                        </Typography>
                    </Box>
                    <Typography variant="body3" sx={popoverBody}>
                        <HighlightSubstring
                            message={props.conversation.lastMessageBody ?? ''}
                            substring={phrase}
                        />
                    </Typography>
                    <Box
                        sx={popoverAdornment}
                        role="presentation"
                        className="cButtonBaseAdornment"
                    >
                        <EastRounded size={20} />
                    </Box>
                </ButtonBase>
            </Box>
            {!phrase && !!props.conversation.assistantMessageId && (
                <>
                    <Divider
                        textAlign="left"
                        sx={({ typography }) => ({ ...typography.body4 })}
                    >
                        Answer
                    </Divider>
                    <Box sx={answerPreview} typography="body3">
                        <MessagePreview
                            id={props.conversation.assistantMessageId}
                        />
                    </Box>

                    {message.isFetched && (
                        <>
                            <Divider />
                            <Box sx={actionBar}>
                                <Button
                                    onClick={regenerate}
                                    disabled={
                                        regeneration.isPending ||
                                        deletion.isPending
                                    }
                                    size="small"
                                    color="primary"
                                    startIcon={
                                        regeneration.isPending ? (
                                            <CircularProgress size={18} />
                                        ) : (
                                            <RefreshIcon />
                                        )
                                    }
                                    variant="outlined"
                                >
                                    Regenerate
                                </Button>
                                <Button
                                    onClick={onEdit}
                                    size="small"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    variant="outlined"
                                    disabled={
                                        !message.isFetched ||
                                        deletion.isPending ||
                                        regeneration.isPending
                                    }
                                >
                                    Edit
                                </Button>
                                <Divider
                                    sx={{ mx: 1 }}
                                    orientation="vertical"
                                    flexItem
                                />
                                <Tooltip title="Send" arrow>
                                    <span>
                                        <IconButton
                                            type="button"
                                            onClick={send}
                                            disabled={
                                                !message.isFetched ||
                                                sendNow.isPending ||
                                                regeneration.isPending
                                            }
                                            color="white-contained"
                                            size="large"
                                        >
                                            {sendNow.isPending ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <SendFilledIcon sx={square20} />
                                            )}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </>
            )}
        </Popover>
    );
};
