import { useMeQueryData } from '../../queries/user';
import { ClerkPermissions } from '../../api/types';
import { FC, PropsWithChildren } from 'preact/compat';

interface WithPermissionProps {
    requiredPermissions: ClerkPermissions[];
}

type Props = PropsWithChildren<WithPermissionProps>;

export const WithPermission: FC<Props> = (props: Props) => {
    const me = useMeQueryData();
    const userPermissions = me?.activeRole?.permissions;

    if (
        !userPermissions ||
        !props.requiredPermissions.every(
            (p) => userPermissions!.indexOf(p) >= 0,
        )
    ) {
        return null;
    }
    return props.children;
};
