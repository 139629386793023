import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './DialogActions.module.scss';

export interface DialogActionsProps {
    className?: string;
    children?: ComponentChildren;
}

/** @deprecated use mui/dialog instead */
export const DialogActions = (props: DialogActionsProps) => {
    return (
        <div className={cc([styles['root'], props.className])}>
            {props.children}
        </div>
    );
};
