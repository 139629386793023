import { useMutation, useQuery } from '@tanstack/react-query';
import {
    createContact,
    createOrUpdateContact,
    deleteContacts,
    exportContacts,
    getContact,
    getContacts,
    searchContacts,
    updateContact,
} from '../api/contacts';
import { Contact } from '../api/types';
import { ContactsCache } from '../utils/ContactsCache';
import { queryClient } from './queryClient';
import { FilterValue } from '../components/UserInfoProperty/types';
import isNumber from 'lodash/isNumber';

export const CONTACTS_KEY = 'contacts_list';
export const CONTACT = 'contact';
const CONTACT_CREATE_KEY = 'contact_create';
export const CONTACTS_SEARCH_KEY = 'contacts_search';

export function useContactsQuery() {
    return useQuery({
        queryKey: [CONTACTS_KEY],
        queryFn: async () => {
            const data = await getContacts();
            return new ContactsCache(data);
        },
        staleTime: Infinity,
    });
}

export function useContactsQueryData() {
    return (
        queryClient.getQueryData<ContactsCache>([CONTACTS_KEY]) ||
        new ContactsCache()
    );
}

export function useContactQuery(id: string | undefined) {
    return useQuery<Contact>({
        queryKey: [CONTACT, id],
        queryFn: () => getContact({ id: id! }),
        enabled: !!id,
    });
}
export function useContactCreateQuery() {
    return useMutation({
        mutationKey: [CONTACT_CREATE_KEY],
        mutationFn: createContact,
        onMutate: async (newContact: Partial<Contact>) => {
            await queryClient.cancelQueries({ queryKey: [CONTACTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.add(newContact as Contact);
                        return contactsCache;
                    }
                    return new ContactsCache();
                },
            );
        },
        onSuccess: (data) => {
            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.list[contactsCache.list.length - 1] =
                            data;
                        return contactsCache;
                    }
                    return new ContactsCache();
                },
            );
        },
        onError: () => {
            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        const contact = contactsCache.list.pop();
                        if (contact && contact.phone) {
                            delete contactsCache.mapByPhone[contact.phone];
                        }

                        return contactsCache;
                    }
                    return new ContactsCache();
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
}

export function useContactCreateOrUpdateQuery() {
    return useMutation({
        mutationKey: [CONTACT_CREATE_KEY],
        mutationFn: createOrUpdateContact,
        onMutate: async (newContact) => {
            await queryClient.cancelQueries({ queryKey: [CONTACTS_KEY] });
            await queryClient.cancelQueries({ queryKey: [CONTACT] });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.add(newContact.payload as Contact);
                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );
            return { previousContact: newContact.where };
        },
        onSuccess: (data) => {
            queryClient.refetchQueries({ queryKey: [CONTACT, data.id] });
            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.update(data);
                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );
        },
        onError: (_err, _contact, context) => {
            const { previousContact } = context || {};

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        if (previousContact) {
                            contactsCache.update(previousContact);
                        }
                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
}

export function useContactUpdateQuery() {
    return useMutation({
        mutationKey: ['contact_update'],
        mutationFn: updateContact,
        onMutate: async (contact: Partial<Contact>) => {
            await queryClient.cancelQueries({ queryKey: [CONTACTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            const contactsCache = queryClient.getQueryData<ContactsCache>([
                CONTACTS_KEY,
            ]);
            const previousContact = contactsCache?.get(contact);

            if (previousContact) {
                queryClient.setQueryData<Contact>([CONTACT, contact.id], {
                    ...previousContact,
                    ...contact,
                });
            }

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.update(contact as Contact);
                        return new ContactsCache(contactsCache);
                    }
                    const contacts = new ContactsCache();
                    contacts.add(contact as Contact);
                    return contacts;
                },
            );

            return { previousContact };
        },
        onError: (_err, contact, context) => {
            const { previousContact } = context!;

            if (previousContact) {
                queryClient.setQueryData<Contact>(
                    [CONTACT, contact.id],
                    previousContact,
                );
            }

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        if (previousContact) {
                            contactsCache.update(previousContact);
                        } else {
                            contactsCache.remove(contact as Contact);
                        }
                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );
        },
        onSuccess: (responseContact) => {
            queryClient.refetchQueries({
                queryKey: [CONTACT, responseContact.id],
            });
            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        contactsCache.update(responseContact);
                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
}

export function useContactDeleteQuery() {
    return useMutation({
        mutationKey: ['contact_delete'],
        mutationFn: deleteContacts,
        onMutate: async (contacts) => {
            await queryClient.cancelQueries({ queryKey: [CONTACTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            const contactsCache = queryClient.getQueryData<ContactsCache>([
                CONTACTS_KEY,
            ]);

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                (contactsCache) => {
                    if (contactsCache) {
                        let idx;
                        for (const id of contacts.ids) {
                            idx = contactsCache.findIndexById(id);
                            if (isNumber(idx)) {
                                contactsCache.removeByIndex(idx);
                            }
                        }

                        return new ContactsCache(contactsCache);
                    }
                    return new ContactsCache();
                },
            );

            return { contactsCache };
        },
        onError: (_err, _contacts, context) => {
            const { contactsCache } = context!;

            queryClient.setQueryData<ContactsCache>(
                [CONTACTS_KEY],
                contactsCache
                    ? new ContactsCache(contactsCache)
                    : new ContactsCache(),
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
}

export function useContactsSearchQuery(
    queryTerm: string,
    filterValues?: FilterValue[],
    cohortId?: number,
    enabled = true,
    isWithPhone = false,
) {
    return useQuery({
        queryKey: [
            CONTACTS_SEARCH_KEY,
            { queryTerm, filterValues, cohortId, isWithPhone },
        ],
        queryFn: () =>
            searchContacts(queryTerm, filterValues, cohortId, isWithPhone),
        // It's necessary to keep `retry: false`. If to remove this setting, react-query will attempt
        // to retry failed search request that may lead to the case when UI and actual query are not synced.
        retry: false,
        enabled,
    });
}

export const useContactExport = () =>
    useMutation({
        mutationKey: ['contact_export'],
        mutationFn: exportContacts,
    });
