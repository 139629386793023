/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentChildren, VNode } from 'preact';
import {
    createContext,
    createPortal,
    useContext,
    useReducer,
} from 'preact/compat';
import { JSXInternal } from 'preact/src/jsx';
import ModalWrapper from './index';

export type ModalState = ModalPayload;

export interface ModalAction {
    type: 'PUSH' | 'REMOVE';
    payload: ModalPayload;
}

export interface ModalPayload {
    content?: () => JSXInternal.Element;
    modalProps?: any;
}

export const PUSH = 'PUSH';
export const REMOVE = 'REMOVE';

export const modalReducer = (state = {}, action: ModalAction) => {
    switch (action.type) {
        case PUSH:
            return {
                content: action.payload.content,
                modalProps: action.payload.modalProps,
            };
        case REMOVE:
            return {};
        default:
            return state;
    }
};

type ModalContextType = {
    state: ModalState;
    dispatch: (action: ModalAction) => void;
};

export const ModalContext = createContext<ModalContextType>({
    state: {},
    dispatch: (_action: ModalAction) => null,
});

export interface ModalProviderProps {
    children: ComponentChildren;
}

export const ModalProvider = (props: ModalProviderProps) => {
    const [state, dispatch] = useReducer(modalReducer, {});
    const modalData = {
        state,
        dispatch,
    };
    return (
        <ModalContext.Provider value={modalData}>
            {props.children}
            {createPortal(<ModalWrapper {...state} />, document.body)}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const { dispatch } = useContext(ModalContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const spawnModal = (component: VNode, modalProps?: any) =>
        dispatch({
            type: 'PUSH',
            payload: {
                content: () => component,
                modalProps,
            },
        });

    const removeModal = () => dispatch({ type: 'REMOVE', payload: {} });

    return {
        dispatch,
        spawnModal,
        removeModal,
    };
};
