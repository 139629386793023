import { useState } from 'preact/hooks';
import styles from './CreateCohortModal.module.scss';
import Dialog, { DialogTitle } from '../../../elements/Dialog';
import Input from '../../../elements/Inputs/Input';
import Icon from '../../../icons/Icon';
import { useCohortCreateQuery } from '../../../queries/cohorts';
import CheckBox from '../../../elements/CheckBox/CheckBox';
import { UUID } from '../../../types/uuid';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';

export interface CreateCohortModalProps {
    selected: Set<UUID>;
    close: () => void;
}

export const CreateCohortModal = (props: CreateCohortModalProps) => {
    const [name, setName] = useState('');
    const [shared, setShared] = useState(false);
    const { mutateAsync: createCohort } = useCohortCreateQuery();

    const handleCreateCohort = () => {
        createCohort({
            name,
            included: Array.from(props.selected),
            isPublic: shared,
        })
            .then(props.close)
            .catch(console.log);
    };

    return (
        <Dialog width="400px">
            <DialogTitle onClose={props.close}>Create new cohort</DialogTitle>
            <DialogContent>
                <p className={styles['editor']}>
                    <label className={styles['label']}>
                        Cohort&apos;s name
                    </label>
                    <Input
                        placeholder="Enter cohort's name"
                        value={name}
                        onChange={setName}
                        fullWidth
                    />
                    <Box mt={2}>
                        <CheckBox
                            checked={shared}
                            onChange={() => setShared(!shared)}
                            label="Public"
                        />
                    </Box>
                </p>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.close}>
                    Cancel
                </Button>
                <Button
                    disabled={!name}
                    startIcon={<Icon name="check" />}
                    onClick={handleCreateCohort}
                    fullWidth
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
