import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
// styles
import styles from './FilterPersonSelectValue.module.scss';
// components
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
// icons
import searchIcon from '../../../../assets/icons/icons-16/search.svg';
// utils
import { UserPropertyFilterModel } from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Profile } from '../../../../api/types';
import { useTeammates } from '../../../../queries/user';
import Avatar from '../../../Avatar';
import { userName } from '../../../../helpers/formatting';

interface FilterPersonSelectValueProps {
    userPropertyFilter: UserPropertyFilterModel<Profile>;
    changeHandler: (value: UserPropertyFilterModel) => void;
    closeHandler: () => void;
}

export const FilterPersonSelectValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterPersonSelectValueProps) => {
    const { value } = userPropertyFilter;
    const { data: users = [] } = useTeammates();
    const [filterTerm, setFilterTerm] = useState<string>('');
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    const filteredOptions = filterTerm
        ? users.filter((u) =>
              userName(u).toLowerCase().includes(filterTerm.toLowerCase()),
          )
        : users;

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div className={styles['filter__top-panel']}>
                <Input
                    wrapperClassName={styles['filter__search-input-wrapper']}
                    className={styles['filter__search-input']}
                    placeholder="Search"
                    prefix={<img src={searchIcon} />}
                    value={filterTerm}
                    onChange={setFilterTerm}
                    customRef={inputRef}
                />
            </div>

            <DropdownList className={styles['filter__list']}>
                {filteredOptions.map((option, i) => (
                    <DropdownItem
                        className={cc([
                            {
                                [styles['filter__item_selected']]:
                                    option.id === value?.id,
                                [styles['filter__item_last']]:
                                    i === filteredOptions.length - 1,
                            },
                        ])}
                        key={option}
                        variant="list-item"
                        type="regular"
                        onClick={() => {
                            changeHandler({
                                ...userPropertyFilter,
                                value: option.id,
                            });
                            closeHandler();
                        }}
                    >
                        <Avatar
                            size="small"
                            username={userName(option)}
                            imageUrl={option?.avatar?.url}
                        />
                        <span>{userName(option)}</span>
                    </DropdownItem>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
