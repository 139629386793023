import {
    createPortal,
    TargetedEvent,
    useCallback,
    useState,
} from 'preact/compat';
import { route } from 'preact-router';
import { usePopper } from 'react-popper';
import styles from './ContactPanel.module.scss';
import Avatar from '../../components/Avatar';
import { EditableField, FieldSize } from '../../components/EditableField';
import { useAnalytics } from '../../contexts/analytics';
import {
    useContactCreateQuery,
    useContactUpdateQuery,
} from '../../queries/contacts';
import { useGetOrCreateConversation } from '../../queries/conversations';
import { AnalyticsEventName } from '../../types/AnalyticsEventNames';
import { useInboxes } from '../../queries/inboxes';
import Icon from '../../icons/Icon';
import { uploadFile } from '../../api/files';
import { Contact, Conversation } from '../../api/types';
import { useMeQueryData } from '../../queries/user';
import { UUID } from '../../types/uuid';
import Droplist from '../../elements/Droplist';
import { DroplistItem } from '../../elements/Droplist/DroplistItem';
import { DroplistItems } from '../../elements/Droplist/DroplistItems';
import { DroplistHeader } from '../../elements/Droplist/DroplistHeader';
import { renderInboxIcon } from '../../utils/inboxes';
import { PopupElement } from '../../types/PopupElement';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Button } from '@mui/material';

interface ContactPanelInfoProps {
    contact: Partial<Contact>;
}

export const ContactPanelInfo = ({ contact }: ContactPanelInfoProps) => {
    const [inboxesExpanded, setInboxesExpanded] = useState(false);

    const me = useMeQueryData();
    /* State */
    const { dispatch: AnalyticsDispatch } = useAnalytics();
    const { data: inboxes = [] } = useInboxes();

    /* Mutations */
    const { mutateAsync: updateContact } = useContactUpdateQuery();
    const { mutateAsync: createContact } = useContactCreateQuery();
    const { mutateAsync: getOrCreate } = useGetOrCreateConversation();

    const [refElement, setRefElement] = useState<PopupElement>(null);
    const [popperElement, setPopperElement] = useState<PopupElement>(null);

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        refElement,
        popperElement,
        {
            placement: 'bottom',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    );

    useOnClickOutside([refElement, popperElement], () => {
        setInboxesExpanded(false);
    });

    /* Component Functions */
    const contactChange = useCallback(
        async (e: TargetedEvent<HTMLInputElement>) => {
            const name = e.currentTarget.name;
            const value = e.currentTarget.value;

            AnalyticsDispatch({
                payload: {
                    eventName: AnalyticsEventName.USER_ADD_EDIT_CONTACT,
                    eventPayload: { contact: { ...contact, [name]: value } },
                },
            });

            if (contact.id && contact.id !== 'draft') {
                // @ts-ignore
                await updateContact({
                    ...contact,
                    [name]: value,
                });
            } else if (contact.phone) {
                await createContact({
                    ...contact,
                    [name]: value,
                });
            } else {
                // @ts-ignore
                await createContact({
                    ...contact.data,
                    ...contact,
                    // @ts-ignore
                    id: undefined,
                    [name]: value,
                });
            }
        },
        [AnalyticsDispatch, contact, updateContact, createContact],
    );

    const sendToContact = useCallback(
        (inboxId: UUID) => {
            if (contact && contact.phone) {
                getOrCreate({
                    inboxId,
                    members: [contact.phone],
                }).then((conversation: Conversation) =>
                    route(`/inbox/${inboxId}/${conversation.id}`),
                );
            }
        },
        [contact, getOrCreate],
    );

    const handleSendMessageButtonClick = useCallback(() => {
        if (inboxes.length > 1) {
            setInboxesExpanded(!inboxesExpanded);
        } else if (inboxes.length === 1) {
            sendToContact(inboxes[0].id);
        }
    }, [inboxes, inboxesExpanded, sendToContact]);

    return (
        <section className={styles['root__description']}>
            <div className={styles['root__avatar-wrapper']}>
                <Avatar
                    className={styles['root__avatar']}
                    imageUrl={contact?.avatarURL}
                    username={contact.name}
                    size="extra-large"
                    editable={contact && contact.id ? true : false}
                    uploadImage={async ({ file }) => {
                        if (contact) {
                            const data = await uploadFile({ file });
                            // @ts-ignore
                            return updateContact({
                                ...contact,
                                avatarURL: data.url,
                            });
                        }
                    }}
                />
                {contact.optOut && (
                    <span className={styles['root__optout-icon']}>
                        <Icon size="16px" name="opt-out" />
                    </span>
                )}
            </div>
            <div className={styles['root__name']}>
                <EditableField
                    size={FieldSize.Large}
                    name="name"
                    value={contact?.name}
                    onChange={contactChange}
                    className={styles.nameButton}
                />
            </div>
            {contact.createdByUserId === me?.id && !contact.public && (
                <div className={styles['root__visibility']}>
                    <Icon name="lock" size="16px" />
                    <span>Personal Contact</span>
                </div>
            )}
            {contact.optOut && (
                <span className={styles['root__optOut']}>
                    User has been unsubscribed from SMS updates
                </span>
            )}
            {!contact.optOut && contact.phone && (
                <div ref={setRefElement}>
                    <Button
                        sx={{ mt: 4 }}
                        size="small"
                        color="info"
                        startIcon={<Icon name="send" size="20px" />}
                        onClick={handleSendMessageButtonClick}
                    >
                        Send a message
                    </Button>
                    {inboxesExpanded &&
                        createPortal(
                            <Droplist
                                className={styles['root__inboxes-list']}
                                ref={setPopperElement}
                                style={popperStyles.popper}
                                {...popperAttributes.popper}
                            >
                                <DroplistHeader
                                    className={
                                        styles['root__inboxes-list-header']
                                    }
                                    title={`You have ${inboxes.length} inboxes.`}
                                    subtitle="Which inbox would you like to use?"
                                />
                                <DroplistItems>
                                    {inboxes.map((inbox) => (
                                        <DroplistItem
                                            key={inbox.id}
                                            className={
                                                styles['root__inboxes-item']
                                            }
                                            onClick={() =>
                                                sendToContact(inbox.id)
                                            }
                                        >
                                            <span>
                                                {renderInboxIcon(inbox.icon)}
                                            </span>

                                            <span>{inbox.name || 'Inbox'}</span>
                                        </DroplistItem>
                                    ))}
                                </DroplistItems>
                            </Droplist>,
                            document.querySelector('#portal')!,
                        )}
                </div>
            )}
        </section>
    );
};
