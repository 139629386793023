import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { MoreIcon } from '../../../icons/common/CMoreIcon';
import { useState } from 'preact/hooks';
import { TargetedEvent } from 'preact/compat';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';

import { EditIcon } from '../../../icons/common/CEditIcon';
import { useCampaignRemoveQuery } from '../../../queries/campaigns';
import { emptyFunction } from '../../../helpers/function.helpers';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';

type Props = {
    campaignId: number;
    onRename: () => void;
    onRemoveSuccess?: () => void;
};

export const CampaignMenu = ({
    campaignId,
    onRename,
    onRemoveSuccess = emptyFunction,
}: Props) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const { mutateAsync: remove, isPending } =
        useCampaignRemoveQuery(campaignId);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: TargetedEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onRemove = () => {
        setIsDeleteModalOpen(true);
    };

    return (
        <>
            <IconButton
                id="campaign-menu-btn"
                aria-controls={open ? 'campaign-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label="menu"
            >
                <MoreIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    sx: { minWidth: 150 },
                    'aria-labelledby': 'campaign-menu-btn',
                }}
                id="campaign-menu"
            >
                <MenuItem
                    onClick={() => {
                        onRename();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    disabled={isPending}
                    onClick={onRemove}
                    sx={{ color: 'error.main' }}
                >
                    {isPending ? (
                        <CircularProgress
                            color="error"
                            size={16}
                            sx={{ mr: 3 }}
                        />
                    ) : (
                        <ListItemIcon>
                            <DeleteIcon sx={{ color: 'error.main' }} />
                        </ListItemIcon>
                    )}
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                open={isDeleteModalOpen}
                maxWidth="xs"
                onClose={() => setIsDeleteModalOpen(false)}
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm campaign deletion"
                    subtitle="Sent messages will stay in the chats. Are you sure you want to delete the campaign?"
                />
                <DialogActions>
                    <Button
                        disabled={isPending}
                        sx={{ minWidth: 100 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        startIcon={
                            isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        onClick={() => {
                            remove().then(() => {
                                setIsDeleteModalOpen(false);
                                onRemoveSuccess();
                            });
                        }}
                        variant="outlined"
                        color="error"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
