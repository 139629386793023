import {
    Stack,
    Box,
    Card,
    CardContent,
    Typography,
    Button,
} from '@mui/material';
import { FreeTitle, PlanItem } from '../../../containers/PaymentFlow/plans';

import { CheckIcon } from '../../../icons/common/CheckIcon';

type PlanCardProps = {
    plan: PlanItem;
    onClick: () => void;
};
export const PlanCard = (props: PlanCardProps) => {
    const price =
        props.plan.price === 0
            ? FreeTitle
            : `${props.plan.price}.00 user / month`;
    return (
        <Card sx={{ backgroundColor: 'white', height: 1 }}>
            <CardContent
                sx={{ display: 'flex', flexDirection: 'column', height: 1 }}
            >
                <Box display="flex">
                    {props.plan.titleIcon}
                    <Typography variant="h3" marginLeft={2} marginBottom={1}>
                        {props.plan.title}
                    </Typography>
                </Box>
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    sx={{ my: 2 }}
                >
                    {props.plan.subtitle}
                </Typography>
                <Stack
                    direction="column"
                    sx={{ mb: 4, flexGrow: 1 }}
                    spacing={1}
                >
                    {props.plan.features.map((feature, index) => (
                        <Box key={index} display="flex" alignItems="center">
                            <CheckIcon />
                            <Typography variant="body4" marginLeft={1}>
                                {feature}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
                <Button
                    variant={props.plan.isPopular ? undefined : 'outlined'}
                    onClick={props.onClick}
                    disableRipple
                    fullWidth
                >
                    <Typography variant="body3">{price}</Typography>
                </Button>
            </CardContent>
        </Card>
    );
};
