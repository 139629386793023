import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useState } from 'preact/hooks';
import { JSXInternal } from 'preact/src/jsx';
import { pluralize } from './pluralize';
import { useGetSetting } from '../../../queries/user';
import { UserTeamSettingType } from '../../../api/types';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import { useTrack } from '../../../contexts/analytics';

type DialogProps = { contactsCount: number };

export const useConfirmSending = (): [
    () => Promise<boolean>,
    (p: DialogProps) => JSXInternal.Element,
] => {
    const track = useTrack();
    const { data } = useGetSetting(UserTeamSettingType.Signature);
    const [open, setOpen] = useState<boolean>(false);
    const [rejector, setRejector] = useState(() => () => setOpen(false));
    const [resolver, setResolver] = useState(() => () => setOpen(false));

    const dialog = ({ contactsCount }: DialogProps) => (
        <Dialog open={open} onClose={() => rejector()} maxWidth="xs">
            <DialogTitle textAlign="center">
                <Typography variant="h3" textAlign="center">
                    Campaign sending
                </Typography>
                <Typography variant="body3" color="custom.text.secondary">
                    Ready to send a campaign message to{' '}
                    {pluralize(contactsCount, 'contact', 'contacts')}? Confirm
                    the message sending process.
                    {data?.enabled && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            <AlertTitle>
                                Your signature will be added to each message.
                            </AlertTitle>
                        </Alert>
                    )}
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button
                    onClick={() => {
                        rejector();
                    }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        resolver();
                    }}
                    sx={{ minWidth: 200 }}
                    fullWidth
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [
        () => {
            setOpen(true);
            return new Promise((resolve, reject) => {
                setResolver(() => () => {
                    setOpen(false);
                    resolve(true);
                });
                setRejector(() => () => {
                    track('campaign_message_dialog_dismiss', {
                        signature: data?.enabled ? 'yes' : 'no',
                    });
                    setOpen(false);
                    reject(false);
                });
            });
        },
        dialog,
    ];
};
