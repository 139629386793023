import { SxProps, Theme } from '@mui/material';

export const root: SxProps<Theme> = {
    margin: 0,
    padding: (theme) => theme.spacing(2, 3),
    border: '1px solid',
    borderColor: 'custom.gray.divider',
    borderRadius: 1.5,
    height: 1,
    outline: 'none',

    '&.error': {
        borderColor: 'error.main',
    },

    '&:hover:not(.disabled):not(.error)': {
        borderColor: 'info.main',
    },

    '&:focus-within:not(.disabled):not(.error)': {
        borderColor: 'info.main',
        boxShadow: '0px 0px 0px 3px rgba(63, 93, 244, 0.12)',
    },

    '&.disabled': {
        backgroundColor: 'custom.gray.disabledBackground',
        borderColor: 'custom.gray.borderEnabled',
        color: 'custom.gray.disabledText',
    },
};

export const editable: SxProps = {
    width: 1,
    height: 1,
    border: 'none',
    outline: 'none',
    resize: 'none',
};

export const editableContainer: SxProps = { maxHeight: 180, overflow: 'auto' };

export const activeSendButton: SxProps<Theme> = (theme) => ({
    borderRadius: 1,
    background: theme.palette.info.main,
    color: 'white',
});

export const divider: SxProps = { height: 16 };
