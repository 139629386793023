import cc from 'classcat';
import styles from './ExistingPhoneNumber.module.scss';
import { OptionId } from '../../../elements/AdvancedSelect';
import TeamInfoIcon from '../../../assets/icons/icons-24/gray/team_info.svg?react';
import { CarrierOption } from '../types';

export const renderCarrierValue = (
    options: CarrierOption[],
    value?: OptionId | OptionId[],
    placeholder?: string,
) => {
    const selectedValue = options.find((option) => option.id === value);

    return selectedValue ? (
        <span className={styles['carrier-value']}>
            {selectedValue.icon}
            <span>{selectedValue.label}</span>
        </span>
    ) : (
        <span className={styles['carrier-placeholder']}>
            <TeamInfoIcon />
            {placeholder}
        </span>
    );
};

export const renderCarrierOption = (option: CarrierOption) => (
    <div
        className={cc([
            styles['carrier-option'],
            {
                [styles['carrier-option_disabled']]: option.disabled,
            },
        ])}
    >
        {option.icon}
        <span>{option.label}</span>
    </div>
);
