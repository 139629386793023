import cc from 'classcat';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import styles from './UserPropertiesFilterValueDropdown.module.scss';
import { getFilter, getFilterConstraintName } from '../Filter';
import { UserPropertyFilterModel } from '../../UserInfoProperty/types';
import Icon from '../../../icons/Icon';
import { getPropertyIcon } from '../../../utils/contact-properties';
import { ContactPropertyType } from '../../../api/types';
import { useTeammates } from '../../../queries/user';
import { userName } from '../../../helpers/formatting';
import { Box } from '@mui/material';

export type UserPropertiesFilterValueDropdownProps = {
    userPropertyFilter: UserPropertyFilterModel;
    className?: string;
    onChange: (value: UserPropertyFilterModel) => void;
    onClear: () => void;
};

export const UserPropertiesFilterValueDropdown = ({
    userPropertyFilter,
    className,
    onChange,
    onClear,
}: UserPropertiesFilterValueDropdownProps) => {
    const [open, setOpen] = useState(false);
    const { data: users = [] } = useTeammates();

    useEffect(() => {
        if (!userPropertyFilter.value) {
            setOpen(true);
        }
    }, []);

    const closeHandler = useCallback(() => setOpen(false), []);

    const constraintLabel = useMemo(() => {
        if (
            userPropertyFilter.type === ContactPropertyType.MultiSelect &&
            Array.isArray(userPropertyFilter.value)
        ) {
            return userPropertyFilter.value.length > 1
                ? `Selected (${userPropertyFilter.value.length})`
                : userPropertyFilter.value;
        }

        if (userPropertyFilter.type === ContactPropertyType.Select) {
            return userPropertyFilter.value;
        }

        if (userPropertyFilter.type === ContactPropertyType.Person) {
            const user = users.find((u) => u.id === userPropertyFilter.value);
            return user ? userName(user) : '';
        }

        if (userPropertyFilter.constraint) {
            return getFilterConstraintName(
                userPropertyFilter.type,
                userPropertyFilter.constraint,
            );
        }

        return 'Choose';
    }, [userPropertyFilter]);

    return (
        <div className={cc([styles['root'], className])}>
            <Box display="flex" alignItems="center" gap="2" typography="body3">
                <Icon size="20px" name={getPropertyIcon(userPropertyFilter)} />
                <Box ml={1} component="span">
                    {userPropertyFilter.name}
                </Box>
            </Box>

            <Box
                component="button"
                typography="body3"
                className={styles['root__condition']}
                title={constraintLabel}
                onClick={() => setOpen(!open)}
            >
                {constraintLabel}
            </Box>

            <button
                className={styles['root__remove']}
                onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                }}
            >
                <Icon size="16px" name="close" />
            </button>

            {open && getFilter(userPropertyFilter, onChange, closeHandler)}
        </div>
    );
};
