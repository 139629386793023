import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { usePopper } from 'react-popper';
import cc from 'classcat';
import { MultiselectSuggestion } from './MultiselectSuggestion';
import currentStyles from './MultiselectValue.module.scss';
import generalStyles from '../values.module.scss';
import {
    OnOptionCreateHandler,
    UserPropertyModel,
    ValueProps,
} from '../../types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import CloseIcon from '../../../../icons/CloseIcon';
import {
    useDeletePropertyOption,
    useUpdatePropertyOption,
} from '../../../../queries/properties';
import { ContactPropertyType } from '../../../../api/types';

const styles = { ...generalStyles, ...currentStyles };

type PopupElement = HTMLElement | null;

interface MultiselectValueProps extends ValueProps<Array<string>> {
    contactId?: string;
    onCreate?: OnOptionCreateHandler;
}

const getLabels = (
    property: UserPropertyModel<Array<string>>,
    value: string | string[],
) => {
    const { options = [] } = property;
    if (!value || !Array.isArray(value)) {
        return [];
    }

    const names = value.reduce<Array<string>>((acc, s) => {
        const name = options.find((id) => id === s);
        return name ? acc.concat(name) : acc;
    }, []);
    return names;
};

const getValueInitState = (property: UserPropertyModel<Array<string>>) => {
    return Array.isArray(property.value) ? property.value : [];
};

const AVAILABLE_COLORS = 4; // see count of __tag-color-# style rules

const getColorIndex = (label: string): number => {
    let index = 0;
    for (let i = 0; i < label.length; i++) {
        index = (index + label.charCodeAt(i)) % AVAILABLE_COLORS;
    }
    return index;
};

export const MultiselectValue = ({
    userProperty,
    contactId,
    onChange,
    onCreate,
    readonly,
}: MultiselectValueProps) => {
    const [value, setValue] = useState(() => getValueInitState(userProperty));
    const [isExpanded, setExpanded] = useState(false);
    const [isEditEnabled, setEditEnabled] = useState(false);

    const [refElement, setRefElement] = useState<PopupElement>(null);
    const [popperElement, setPopperElement] = useState<PopupElement>(null);

    const selectRef = useRef<HTMLDivElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        setValue(getValueInitState(userProperty));
    }, [userProperty]);

    useOnClickOutside(selectRef, () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnClickOutside(ulRef, () => setExpanded(false));

    // Mutations
    const { mutateAsync: updateOption } = useUpdatePropertyOption(
        userProperty.id!,
        contactId,
    );
    const { mutate: deleteOption } = useDeletePropertyOption(
        userProperty.id!,
        contactId,
    );

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        refElement,
        popperElement,
        {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 5],
                    },
                },
            ],
        },
    );

    useEffect(() => {
        const newoptions = Array.isArray(userProperty.value)
            ? userProperty.value
            : [];
        setValue(newoptions);
    }, [contactId]);

    const getNewValue = useCallback(
        (option: string) =>
            value.some((id) => id === option)
                ? value.filter((id) => id !== option)
                : value.concat(option),
        [value],
    );

    const labels = getLabels(userProperty, value);

    if (isEditEnabled && !readonly) {
        return (
            <MultiselectSuggestion
                selected={value}
                options={userProperty.options}
                wrapperRef={selectRef}
                onChange={(option: string) => {
                    setValue(getNewValue(option));
                }}
                onCreate={(option) => {
                    onCreate?.(option)
                        .then((updatedUserProperty) => {
                            onChange?.({
                                ...updatedUserProperty,
                                value: getNewValue(option),
                            });
                        })
                        .catch((e) => console.log(e));
                }}
                onSave={(oldOption, newOption) => {
                    const idx = value.findIndex((v) => v === oldOption);
                    const newValue = value.slice();
                    newValue[idx] = newOption;
                    setValue(newValue);

                    return updateOption({
                        type: ContactPropertyType.MultiSelect,
                        oldOption,
                        newOption,
                    });
                }}
                onRemove={(option) => {
                    setValue(value.filter((v) => v !== option));

                    deleteOption({
                        type: ContactPropertyType.MultiSelect,
                        option,
                    });
                }}
            />
        );
    }

    const restLabels = labels.slice(1);

    return (
        <div
            className={styles['tag-value']}
            ref={setRefElement}
            onClick={() => setEditEnabled(true)}
        >
            {labels.length > 0 && (
                <div
                    className={cc([
                        styles['tag-value__tag'],
                        styles[
                            `tag-value__tag-color-${getColorIndex(labels[0])}`
                        ],
                    ])}
                >
                    <span className={styles['tag-value__tag-text']}>
                        {labels[0]}
                    </span>
                    <button
                        className={styles['tag-value__tag-unselect-btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            const newValue = getNewValue(labels[0]);
                            setValue(newValue);
                            onChange?.({
                                ...userProperty,
                                value: getNewValue(labels[0]),
                            });
                        }}
                    >
                        <CloseIcon size="16px" />
                    </button>
                </div>
            )}

            {restLabels.length > 0 && (
                <button
                    className={styles['tag-value__more-tags-btn']}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded(!isExpanded);
                    }}
                >
                    {`+${restLabels.length}`}
                </button>
            )}

            {labels.length === 0 && (
                <span className={styles['tag-value__empty']}>
                    {readonly ? 'Empty' : '+ Set a value'}
                </span>
            )}

            {isExpanded &&
                restLabels.length > 0 &&
                createPortal(
                    <div
                        className={styles['tag-value__expansion']}
                        ref={setPopperElement}
                        onClick={(e) => {
                            // it's necessary to not act with property elements that lay under the popover.
                            e.stopPropagation();
                        }}
                        style={popperStyles.popper}
                        {...popperAttributes.popper}
                    >
                        <ul
                            className={styles['tag-value__expansion-list']}
                            ref={ulRef}
                        >
                            {restLabels.map((item) => (
                                <li
                                    key={item}
                                    className={
                                        styles['tag-value__expansion-tag']
                                    }
                                >
                                    <span
                                        className={
                                            styles['tag-value__expansion-text']
                                        }
                                        title={item}
                                    >
                                        {item}
                                    </span>
                                    <button
                                        className={
                                            styles[
                                                'tag-value__tag-unselect-btn'
                                            ]
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if (restLabels.length === 1) {
                                                setExpanded(false);
                                            }

                                            const newValue = getNewValue(item);
                                            setValue(newValue);
                                            onChange?.({
                                                ...userProperty,
                                                value: newValue,
                                            });
                                        }}
                                    >
                                        <CloseIcon size="16px" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
};
