import { SxProps } from '@mui/material';
import statusBgImage from '../icons/statusBgImage.svg';

export const statusBox: SxProps = {
    borderRadius: 1.5,
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    backgroundImage: `url(${statusBgImage})`,
    backgroundColor: 'custom.gray.superLight',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
};

export const innerBox: SxProps = {
    borderRadius: 1.5,
    padding: 4,
};

export const linkIcon: SxProps = {
    width: 20,
    height: 20,
    marginRight: 2,
};

export const dotIcon: SxProps = { width: 4, height: 4 };

export const chip: SxProps = { marginLeft: 4 };

export const chipIcon: SxProps = { width: 16, height: 16 };
