import cc from 'classcat';
import { useCallback, useState } from 'preact/hooks';
import Button from '../../elements/Buttons';
import styles from './editablefield.module.scss';
import { isEmpty } from 'lodash';

export enum FieldSize {
    Small,
    Large,
}
export function EditableField({
    name,
    value,
    onChange,
    size = FieldSize.Small,
    className = '',
}: {
    name: string;
    value?: string;
    onChange: (e: JSX.TargetedEvent<HTMLInputElement>) => void;
    size?: FieldSize;
    className?: string;
}) {
    const [isWritable, setWritable] = useState(false);

    const focus = useCallback((e: HTMLInputElement | null) => {
        if (e) {
            e.focus();
        }
    }, []);

    if (isWritable) {
        return (
            <input
                ref={focus}
                name={name}
                placeholder={`Enter a ${name}...`}
                className={styles.input}
                type="text"
                value={isEmpty(value) ? undefined : value}
                onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                        e.preventDefault();

                        e.currentTarget.blur();
                    }
                }}
                style={{ outline: 'none' }}
                onBlur={(e) => {
                    if (
                        e.currentTarget.value != value ||
                        (!value && e.currentTarget.value) ||
                        (value && !e.currentTarget.value)
                    ) {
                        onChange(e);
                    }

                    setWritable(false);
                }}
            />
        );
    }

    return (
        <Button
            size={size === FieldSize.Large ? 'extra-large' : 'normal'}
            type="text"
            className={cc([
                { [styles['clerk-btn__edit-value']]: value },
                styles['clerk-btn'],
                className,
            ])}
            name={name}
            label={value || `Enter a ${name}`}
            onClick={() => setWritable(true)}
        />
    );
}
