import {
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import {
    PlanItem,
    PlanPeriod,
    planList,
} from '../../../containers/PaymentFlow/plans';
import { useCallback, useContext, useMemo, useState } from 'preact/hooks';
import { PlanCard } from './PlanCard';
import { BillingContext } from './Billing';
import {
    useBillingProviderQueryData,
    useMeQueryData,
} from '../../../queries/user';
import { BillingProvider } from '../../../api/types';
import { setupShopifySubscription } from '../../../api/shopify';

function PlanCompare() {
    const billingProvider = useBillingProviderQueryData();
    const tier = useMeQueryData()!.activeTeam.tier;
    const [billing, setBilling] = useState<PlanPeriod>('yearly');
    const state = useContext(BillingContext);

    const plans = useMemo(
        () =>
            planList
                .filter((plan) => plan.type !== tier)
                .filter((plan) => plan.period === billing),
        [billing],
    );

    const onPlanSelect = useCallback(
        (plan: PlanItem) => {
            if (billingProvider === BillingProvider.Stripe) {
                alert('Contact support@clerk.chat to change plan');
            }
            if (billingProvider === BillingProvider.Shopify) {
                setupShopifySubscription(plan.type, plan.period).then((res) => {
                    window.location.href = res.data.confirmation_url;
                });
            }
        },
        [billing],
    );

    return state.planCompareOpen.value ? (
        <Stack direction="column">
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="caption"
                    color={({ palette }) => palette.custom.gray.super}
                >
                    Compare Plans
                </Typography>
                <ToggleButtonGroup
                    value={billing}
                    exclusive
                    aria-label="billing-cycle"
                    onChange={(_e, value) => setBilling(value)}
                >
                    <ToggleButton value="monthly">
                        <Typography variant="body3" textTransform="capitalize">
                            Monthly
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value="yearly">
                        <Typography variant="body3" textTransform="capitalize">
                            Yearly
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Grid
                container
                spacing={2}
                columns={{ xs: 3 }}
                justifyContent={plans.length < 3 ? 'center' : undefined}
                mt={4}
            >
                {plans.map((plan, index) => (
                    <Grid item key={index} xs={1}>
                        <PlanCard
                            plan={plan}
                            onClick={() => onPlanSelect(plan)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    ) : null;
}

export default PlanCompare;
