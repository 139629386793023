import cc from 'classcat';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useCohortQuery } from '../../../queries/cohorts';
import { getCohortContactIds } from '../../../utils/cohorts';
import { useNewConversationContext } from '../NewConversationProvider';
import SearchResultsTab from '../SearchResultsTab';
import SelectedContactsTab from '../SelectedContactsTab';
import styles from './NewConversationContent.module.scss';

export const NewConversationContent = () => {
    const { state, actions } = useNewConversationContext();

    const [selectedTab, setSelectedTab] = useState<'results' | 'selected'>(
        'results',
    );

    const { cohort } = useCohortQuery(state.cohortId!, !!state.cohortId);

    useEffect(() => {
        if (!!state.cohortId && cohort?.id === state.cohortId) {
            const cohortContactIds = getCohortContactIds(cohort);
            const newIds = state.selectedContactIds.concat(cohortContactIds);
            actions.setSelectedContacts(newIds);
            actions.setCohort(cohort);
            if (newIds.length > 10) {
                actions.setCampaignMode();
            }
        }
    }, [state.cohortId, cohort?.id]);

    const selectedLabel = useMemo(
        () =>
            state.selectedContactIds.length > 0
                ? `Selected (${state.selectedContactIds.length})`
                : 'Selected',
        [state.selectedContactIds],
    );

    return (
        <section className={styles['root']}>
            <nav className={styles['root__tabs']}>
                <button
                    className={cc([
                        styles['root__tab'],
                        {
                            [styles['root__tab_active']]:
                                selectedTab === 'results',
                        },
                    ])}
                    onClick={() => setSelectedTab('results')}
                >
                    Results
                </button>
                <button
                    className={cc([
                        styles['root__tab'],
                        {
                            [styles['root__tab_active']]:
                                selectedTab === 'selected',
                        },
                    ])}
                    onClick={() => setSelectedTab('selected')}
                >
                    {selectedLabel}
                </button>
            </nav>

            <div className={styles['root__content']}>
                {selectedTab === 'results' && <SearchResultsTab />}
                {selectedTab === 'selected' && <SelectedContactsTab />}
            </div>
        </section>
    );
};
