import { Slate, Editable, ReactEditor } from 'slate-react';
import { renderLeaf } from './renderLeaf';
import { CustomEditor, Serializer } from './slate';
import { renderElement } from './renderElement';
import { EditableProps } from 'slate-react/dist/components/editable';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'preact/hooks';
import {
    compareValues,
    createInitialValue,
    focusEditor,
    insertNodes,
    isEmpty,
    resetEditorValue,
} from './utils';
import { MessageFormData } from '../MessageForm';
import { Box, Divider, Stack } from '@mui/material';
import { FileUploaderButton } from './components/FileUploader/FileUploaderButton';
import { Attachment, PublicFile } from '../../api/types';
import { EmojiButton } from './components/Emoji/EmojiButton';
import MessageFormFilesList from '../MessageFormFilesList';
import useUploadFile from '../../hooks/useUploadFile';
import { TemplateButton } from './components/Template/TemplateButton';
import { Descendant } from 'slate';
import { SignatureButton } from './components/Signature/SignatureButton';
import { SnippetButton } from './components/Snippet/SnippetButton';
import { ScheduledButton } from './components/Scheduled/ScheduledButton';
import { UUID } from '../../types/uuid';
import * as styles from './styles';
import { TextSerializer } from './serializers/text';
import { AssistantMenu } from './components/AiAssistant/AssistantMenu';
import cc from 'classcat';
import { useProperties } from './components/Template/useProperties';
import { SendButton } from './components/SendButton/SendButton';
import { CancelButton } from './components/CancelButton/CancelButton';
import { insertSnippet } from './components/Snippet/insertSnippet';
import {
    useDeleteAttachment,
    useUploadAttachment,
} from '../MessageForm/use-attachment';
import { AttachmentList } from './components/FileUploader/AttachmentList';

export type EditorData = MessageFormData & {
    value: Descendant[];
};

export type MessageInputProps = Omit<
    EditableProps,
    'renderLeaf' | 'onChange' | 'onValueChange' | 'renderElement'
> & {
    autoFocus?: boolean;
    editor: CustomEditor;
    toolbar?: (
        | 'emojiPicker'
        | 'templates'
        | 'signature'
        | 'snippets'
        | 'schedule'
    )[];
    initialValue?: string | Descendant[];
    initialAttachments?: Attachment[];
    /** @deprecated use attachments instead*/
    initialFiles?: PublicFile[];
    sendAt?: string;
    onChange?: (data: {
        value: Descendant[];
        files?: PublicFile[];
        attachments?: Attachment[];
    }) => void;
    onCancel?: () => void;
    onBlur?: (message: Descendant[]) => void;
    withFileUploader?: boolean;
    withAiAssistant?: boolean;
    edit?: boolean;
    conversationId?: UUID;
    withSendButton?: boolean;
    withCancelButton?: boolean;
    onSend?:
        | ((data: EditorData) => Promise<unknown>)
        | ((data: EditorData) => void);
    serializer?: Serializer;
    height?: number | string;
    // TODO: remove this prop after full migration to campaign v3
    isCampaignV3?: boolean;
};

export function MessageInput({
    editor,
    autoFocus = false,
    toolbar = [],
    withFileUploader = false,
    withAiAssistant = false,
    initialValue,
    initialFiles,
    initialAttachments,
    sendAt,
    conversationId,
    onSend,
    onChange,
    onCancel,
    withSendButton = false,
    withCancelButton = false,
    serializer = TextSerializer,
    height,
    disabled = false,
    onBlur,
    isCampaignV3 = false,
    ...editableProps
}: MessageInputProps) {
    const { mutateAsync: uploadAttachment, isPending: isAttachmentUploading } =
        useUploadAttachment();
    const { mutate: deleteAttachment } = useDeleteAttachment();
    const { regEx: propertiesRegEx } = useProperties();
    const [value, setValue] = useState<Descendant[]>(
        serializer.deserialize(initialValue ?? '', propertiesRegEx),
    );
    const [files, setFiles] = useState<PublicFile[]>(initialFiles ?? []);
    const [attachments, setAttachments] = useState<Attachment[]>(
        initialAttachments ?? [],
    );
    const [hasError, setHasError] = useState(false);
    const uploadRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLElement>(null);

    useEffect(() => {
        setAttachments(initialAttachments ?? []);
    }, [
        initialAttachments
            ?.map(({ id }) => id)
            ?.sort()
            .join('_'),
    ]);

    const {
        uploadingFile,
        uploadingProgress,
        uploadingError,
        uploadFile,
        uploadingRetry,
    } = useUploadFile((attachment) => {
        setFiles([...files, attachment]);

        if (uploadRef.current) {
            uploadRef.current.value = '';
        }
    });

    const removeFile = (id: string) => {
        if (isCampaignV3) {
            deleteAttachment(id);
            setAttachments((prev) => prev.filter((item) => item.id !== id));
        } else {
            setFiles(files.filter((f) => f.id != id));
        }
    };

    useEffect(() => {
        if (!initialValue) {
            return;
        }

        if (
            typeof initialValue === 'string' &&
            initialValue !== serializer?.serialize(value)
        ) {
            insertSnippet(editor, initialValue, propertiesRegEx);
            return;
        }

        if (
            Array.isArray(initialValue) &&
            !compareValues(value, initialValue)
        ) {
            insertNodes(editor, initialValue, true);
        }
    }, [initialValue, editor, propertiesRegEx]);

    useEffect(() => {
        if (autoFocus && !disabled) {
            focusEditor(editor);
        }

        return () => {
            ReactEditor.blur(editor);
        };
    }, [autoFocus, editor]);

    const resetInput = useCallback(() => {
        resetEditorValue(editor);
        setFiles([]);
        setAttachments([]);
        setValue(createInitialValue());
        setHasError(false);
    }, []);

    const canSend = useMemo(
        () =>
            !disabled &&
            (!!files.length || !!attachments.length || !isEmpty(value)),
        [value, disabled, files, attachments],
    );

    const handleSubmit = useCallback(() => {
        if (!canSend) {
            return;
        }

        resetInput();
        if (typeof onSend === 'function') {
            const data = {
                message: serializer.serialize(value),
                value,
                sendAt,
            };

            if (isCampaignV3) {
                onSend({ ...data, attachments });
            } else {
                onSend({ ...data, files });
            }
        }
    }, [value, files, attachments, onSend, sendAt]);

    const handleScheduledSubmit = async (date: Date) => {
        try {
            if (typeof onSend === 'function') {
                const data = {
                    message: serializer.serialize(value),
                    value,
                    sendAt: date.toString(),
                };

                if (isCampaignV3) {
                    await onSend({ ...data, attachments });
                } else {
                    await onSend({ ...data, files });
                }
            }

            resetInput();
        } catch (e) {
            setHasError(true);
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleBlur = () => {
        if (typeof onBlur === 'function') {
            onBlur(value);
        }

        if (isEmpty(value)) {
            resetInput();
        }
    };

    const handleCancel = useCallback(() => {
        onCancel?.();
        resetInput();
    }, [onCancel]);

    const handleChange = useCallback(
        (nextValue: Descendant[]) => {
            setHasError(false);
            setValue(nextValue);
            if (typeof onChange === 'function') {
                onChange({ value: nextValue, files, attachments });
            }
        },
        [files, attachments],
    );

    const handleUpload = useCallback(
        (file: File) => {
            if (isCampaignV3) {
                uploadAttachment(file).then((attachment) => {
                    console.log('concat');
                    setAttachments((prev) => prev.concat(attachment));
                });
            } else {
                uploadFile(file);
            }
        },
        [uploadFile, uploadAttachment, isCampaignV3],
    );

    return (
        <Slate editor={editor} initialValue={value} onChange={handleChange}>
            <Stack
                spacing={3}
                ref={containerRef}
                sx={{
                    ...styles.root,
                    height,
                }}
                className={cc({
                    disabled,
                    error: hasError,
                })}
            >
                <Box sx={styles.editableContainer} flex={1}>
                    <Box
                        {...editableProps}
                        component={Editable}
                        onKeyPress={handleKeyPress}
                        renderLeaf={renderLeaf}
                        renderElement={renderElement}
                        onBlur={handleBlur}
                        sx={styles.editable}
                        readOnly={disabled}
                    />
                </Box>

                {withFileUploader && (
                    <Stack spacing={1}>
                        {isCampaignV3 ? (
                            <AttachmentList
                                attachments={attachments}
                                isAttachmentUploading={isAttachmentUploading}
                                removeAttachment={(attachment) =>
                                    removeFile(attachment.id)
                                }
                            />
                        ) : (
                            (files.length > 0 || uploadingFile) && (
                                <MessageFormFilesList
                                    files={files}
                                    uploadingFile={uploadingFile}
                                    uploadingProgress={uploadingProgress}
                                    uploadingError={uploadingError}
                                    uploadingRetry={uploadingRetry}
                                    onRemoveFile={(file) => removeFile(file.id)}
                                />
                            )
                        )}
                    </Stack>
                )}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        flex={1}
                        alignItems="center"
                    >
                        {withFileUploader && (
                            <>
                                <FileUploaderButton
                                    uploadFile={handleUpload}
                                    uploadRef={uploadRef}
                                    disabled={disabled}
                                    editor={editor}
                                />
                                <Divider
                                    orientation="vertical"
                                    sx={styles.divider}
                                />
                            </>
                        )}

                        {toolbar?.includes('emojiPicker') && (
                            <EmojiButton disabled={disabled} editor={editor} />
                        )}

                        {toolbar?.includes('signature') && (
                            <SignatureButton disabled={disabled} />
                        )}

                        {toolbar?.includes('templates') && (
                            <TemplateButton
                                disabled={disabled}
                                editor={editor}
                            />
                        )}

                        {toolbar?.includes('snippets') && (
                            <SnippetButton
                                withProperties={toolbar.includes('templates')}
                                propertiesRegEx={propertiesRegEx}
                                editor={editor}
                                containerRef={containerRef}
                                disabled={disabled}
                            />
                        )}

                        {toolbar?.includes('schedule') && (
                            <ScheduledButton
                                disabled={disabled || !canSend}
                                sendAt={sendAt ? new Date(sendAt) : null}
                                onScheduledSubmit={handleScheduledSubmit}
                            />
                        )}

                        {withAiAssistant && conversationId && (
                            <>
                                <Divider
                                    orientation="vertical"
                                    sx={styles.divider}
                                />
                                <AssistantMenu
                                    editor={editor}
                                    disabled={disabled}
                                    conversationId={conversationId}
                                />
                            </>
                        )}
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                        {withCancelButton && (
                            <CancelButton handleCancel={handleCancel} />
                        )}
                        {withSendButton && (
                            <SendButton
                                canSend={canSend}
                                disabled={disabled}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Slate>
    );
}
