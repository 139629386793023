import { Box, Container } from '@mui/material';
import { CurrentPlanCard } from './CurrentPlanCard';
import PlanCompare from './PlanCompare';
import { createBillingState } from './state';
import { createContext } from 'preact';
import { Signal } from '@preact/signals';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';

export const BillingContext = createContext<{
    planCompareOpen: Signal<boolean>;
}>(createBillingState());

export const Billing = () => {
    return (
        <SettingsLayout
            title="Billing"
            description="View your plan information or switch plans according to your needs"
        >
            <BillingContext.Provider value={createBillingState()}>
                <Container maxWidth="md">
                    <CurrentPlanCard />
                    <Box height={41} />
                    <PlanCompare />
                </Container>
            </BillingContext.Provider>
        </SettingsLayout>
    );
};
