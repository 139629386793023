import {
    Avatar,
    Badge,
    BadgeProps,
    Box,
    ButtonBase,
    Typography,
} from '@mui/material';
import { getMessageStyle } from '../../ConversationRow/styles';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import { base } from './styles';
import { getInitials } from '../../../main/campaign/v3/string.helpers';
import { useMemo } from 'preact/hooks';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';

type Props = Omit<ButtonBaseProps, 'children'> & {
    title: string;
    selected?: boolean;
    description?: string;
    date?: string;
    BadgeProps?: BadgeProps;
};

export const InboxListItem = ({
    title,
    description,
    date,
    selected = false,
    sx,
    BadgeProps = {},
    ...props
}: Props) => {
    const initials = useMemo(() => getInitials(title), [title]);
    const background = useMemo(() => getAvatarGradient(title), [title]);

    return (
        <ButtonBase sx={{ ...base(selected), ...sx }} {...props}>
            <div>
                <Badge invisible {...BadgeProps}>
                    <Avatar
                        sx={{
                            background,
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 1.5,
                        }}
                        alt={title}
                    >
                        {initials}
                    </Avatar>
                </Badge>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    width: 1,
                    overflow: 'hidden',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'left',
                        width: 1,
                        gap: 2,
                    }}
                >
                    <Typography
                        sx={{
                            display: 'block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            fontWeight: 500,
                        }}
                        variant="body3"
                    >
                        {title}
                    </Typography>
                    {date && (
                        <Typography variant="body4" color="custom.gray.super">
                            {date}
                        </Typography>
                    )}
                </Box>
                {description && (
                    <Typography
                        variant="body4"
                        sx={getMessageStyle(false, false)}
                    >
                        {description}
                    </Typography>
                )}
            </Box>
        </ButtonBase>
    );
};
