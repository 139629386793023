import { JSX } from 'preact';
import cc from 'classcat';
import styles from './DropSpot.module.scss';
import plusIcon from '../../../assets/icons/icons-16/different/plus.svg';
import { useState } from 'preact/hooks';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { createNewStep } from '../utils';
import { WorkflowActionTypes, WorkflowConditionTypes } from '../types';

interface DropSpotProps {
    parentId: string;
    branch?: 'left' | 'right';
}

export const DropSpot = ({ parentId, branch }: DropSpotProps) => {
    const { dispatch } = useWorkflowContext();
    const [isDragOver, setDragOver] = useState(false);

    const dragOverHandler = (e: JSX.TargetedDragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragOver(true);
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
    };
    const dropHandler = (e: JSX.TargetedDragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const label = e.dataTransfer?.getData('text/plain') as
            | WorkflowActionTypes
            | WorkflowConditionTypes;

        if (!label) {
            return;
        }

        const newStep = createNewStep(label);
        if (!newStep) {
            return;
        }

        dispatch({
            type: WorkflowReducerActionTypes.INSERT_WORKFLOW_STEP,
            payload: {
                step: newStep,
                parentId,
                branch,
            },
        });
    };

    return (
        <div
            className={cc([
                styles['drop-spot'],
                {
                    [styles['drop-spot_drag-over']]: isDragOver,
                },
            ])}
        >
            <div
                className={cc([
                    styles['drop-spot__droppable'],
                    {
                        [styles['drop-spot__droppable_drag-over']]: isDragOver,
                    },
                ])}
                onDragOver={dragOverHandler}
                onDrop={dropHandler}
                onDragLeave={() => setDragOver(false)}
            />
            {isDragOver ? (
                <div className={cc([styles['drop-spot__area']])} />
            ) : (
                <div className={cc([styles['drop-spot__circle']])}>
                    <img className={styles['drop-spot__icon']} src={plusIcon} />
                </div>
            )}
        </div>
    );
};
