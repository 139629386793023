import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const EinIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" fill="none">
            <path
                d="M15.95 6H13.05V7.45H15.95V8.9H13.775V10.35H15.95V11.8H13.05V13.25H15.95C16.385 13.25 16.675 13.105 16.965 12.815C17.255 12.525 17.4 12.235 17.4 11.8V7.45C17.4 7.015 17.255 6.725 16.965 6.435C16.675 6.145 16.385 6 15.95 6Z"
                fill="currentColor"
            />
            <path
                d="M10.875 10.25H6.52502V7.35C6.52502 6.915 6.67002 6.625 6.96002 6.335C7.25002 6.045 7.54002 5.9 7.97502 5.9H9.42502V4.45H6.52502V3H9.42502C9.86002 3 10.15 3.145 10.44 3.435C10.73 3.725 10.875 4.015 10.875 4.45V5.9C10.875 6.335 10.73 6.625 10.44 6.915C10.15 7.205 9.86002 7.35 9.42502 7.35H7.97502V8.8H10.875V10.25Z"
                fill="currentColor"
            />
            <path
                d="M2.9 5.8V0H1.45V0.725H0V2.175H1.45V5.8H0V7.25H4.35V5.8H2.9Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
