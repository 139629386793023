import cc from 'classcat';
import { ContactDisplayName } from './ContactDisplayName';
import styles from './ContactsListItemSimple.module.scss';
import Avatar from '../Avatar';
import { Contact } from '../../api/types';
import { CampaignContact } from '../../api/campaign.types';

interface ContactsListItemSimpleProps {
    data: {
        countryCode?: string;
        list: Contact[] | CampaignContact[];
    };
    index: number;
    // This style prop is used by react-window lib.
    style: string;
}

export const ContactsListItemSimple = ({
    data,
    index,
    style,
}: ContactsListItemSimpleProps) => {
    const { countryCode, list } = data;
    const contact = list[index];

    return (
        <li style={style}>
            <a href="#" className={cc([styles.contact, styles.simple])}>
                <Avatar size="small" imageUrl={contact?.avatarURL} />
                <ContactDisplayName
                    contact={contact}
                    countryCode={countryCode}
                />
            </a>
        </li>
    );
};
