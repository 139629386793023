import { SxProps } from '@mui/material';

export const base = (selected = false): SxProps => ({
    width: 1,

    gap: 4,
    px: 4,
    py: 2,

    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    bgcolor: selected ? 'custom.gray.active' : 'transparent',
    maxHeight: 72,

    transition: 'background-color .2s',

    ':hover': {
        bgcolor: 'custom.gray.sideBack',
    },

    ':focus-within': {
        outline: '1px solid',
        outlineColor: 'custom.gray.sideBack',
    },
});
