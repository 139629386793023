import cc from 'classcat';
import styles from './ContactPanel.module.scss';
import { ContactPanelHeader } from './ContactPanelHeader';
import { ContactPanelInfo } from './ContactPanelInfo';
import ContactPropertiesPanel from '../ContactPropertiesPanel';
import { useContactQuery, useContactsQueryData } from '../../queries/contacts';

interface ContactPanelProps {
    className?: string;
    contactId?: string;
    contactPhone?: string;
    onBack?: () => void;
}

function ContactPanel({
    contactId,
    contactPhone,
    className,
    onBack,
}: ContactPanelProps) {
    const contactsCache = useContactsQueryData();

    const contactFromCache = contactsCache.getOrEmpty({
        id: contactId,
        phone: contactPhone,
    });

    const { data: contact = contactFromCache } = useContactQuery(
        contactFromCache.id,
    );

    return (
        <section className={cc([styles['root'], className])}>
            <ContactPanelHeader onBack={onBack} />

            <ContactPanelInfo contact={contact} />

            <ContactPropertiesPanel contact={contact} />
        </section>
    );
}

export default ContactPanel;
