import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const InfoIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6894 5.41666C10.9692 5.40956 11.2408 5.51142 11.447 5.70074C11.4785 5.72821 11.5081 5.75784 11.5355 5.78938C11.8652 6.16803 11.8256 6.74224 11.4469 7.07196C11.0156 7.4578 10.3632 7.4578 9.93179 7.07196C9.89803 7.04286 9.86649 7.01132 9.83739 6.97756C9.51086 6.59888 9.55314 6.02724 9.93179 5.70074C10.1384 5.5121 10.4097 5.41034 10.6894 5.41666ZM10.4848 12.8826C10.632 12.9699 10.8031 13.0083 10.9735 12.9924C11.1057 12.9992 11.2383 12.9877 11.3674 12.9582C11.4695 12.933 11.5695 12.9001 11.6667 12.8598L11.5454 13.3371C11.1868 13.4734 10.9002 13.5782 10.6856 13.6514C10.4408 13.7295 10.1848 13.7666 9.92798 13.7613C9.56323 13.7836 9.20269 13.6733 8.91284 13.4507C8.67209 13.262 8.53221 12.9725 8.53407 12.6666C8.53403 12.5399 8.54289 12.4133 8.5606 12.2878C8.58231 12.1413 8.61138 11.9959 8.64771 11.8522L9.09845 10.3068C9.14012 10.1591 9.1742 10.0189 9.20073 9.88634C9.22801 9.76192 9.24198 9.63494 9.2424 9.50756C9.25877 9.35863 9.21362 9.20948 9.1174 9.09467C8.97573 9.00046 8.80548 8.95893 8.63634 8.97726C8.51577 8.97817 8.39592 8.99604 8.28029 9.03028C8.1591 9.06817 8.05301 9.10226 7.9659 9.13256L8.09468 8.63256C8.39012 8.51638 8.6717 8.41664 8.93937 8.33331C9.18429 8.25431 9.43963 8.21218 9.69696 8.20831C10.056 8.18448 10.4115 8.2922 10.697 8.51134C10.929 8.70766 11.0587 8.9992 11.0492 9.303C11.0492 9.32941 11.0458 9.36321 11.0416 9.40463C11.035 9.47012 11.0265 9.55465 11.0265 9.65906C11.0122 9.81707 10.9817 9.97319 10.9356 10.125L10.4886 11.6629C10.4484 11.8025 10.4156 11.9441 10.3902 12.0871C10.3639 12.2118 10.3487 12.3386 10.3447 12.4659C10.3257 12.6189 10.3773 12.7721 10.4848 12.8826Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
