import {
    Components,
    inputBaseClasses,
    selectClasses,
    Theme,
} from '@mui/material';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
    styleOverrides: {
        root: {
            lineHeight: '20px',
            fontSize: 14,

            [`&.${inputBaseClasses.adornedStart} .${selectClasses.select}`]: {
                paddingLeft: 0,
            },
        },
        input: ({ theme }) => ({
            padding: [theme.spacing(2.5), theme.spacing(3)],
        }),
    },
};
