import { SxProps, Theme } from '@mui/material';

export const navItemStyle: SxProps<Theme> = (theme) => ({
    ...theme.typography.body3,
    color: theme.palette.primary.dark,
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    display: 'flex',

    '&:visited': {
        color: theme.palette.primary.dark,
    },

    '& .MuiButton-startIcon': {
        width: '20px',
        height: '20px',
        marginRight: theme.spacing(4),
        marginLeft: 0,
        color: theme.palette.custom.gray.super,
    },
});

export const navItemActiveStyle: SxProps<Theme> = (theme) => ({
    ...navItemStyle(theme),
    backgroundColor: theme.palette.custom.secondary.label,
});
