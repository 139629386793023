import styles from './MSTeamsPhoneNumbers.module.scss';
import { useState } from 'preact/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CompanyEntityType } from '../../../api/types';
import Spacer from '../../../components/Spacer';
import AdvancedSelect from '../../../elements/AdvancedSelect';
import FormButton from '../../../elements/FormButton';
import FormControl from '../../../elements/FormControl';
import FormInput from '../../../elements/FormInput';
import Icon from '../../../icons/Icon';
import {
    canadaStateOptions,
    countriesOptions,
    organizationLegalFormOptions,
    stateOptions,
} from '../../../main/settings/Compliance/utils';
import {
    useBrandCreateQuery,
    useBrandQuery,
    useBrandUpdateQuery,
} from '../../../queries/settings';
import {
    renderCountryOption,
    renderCountryValue,
} from '../NewPhoneNumber/utils';
import StepShell from '../StepShell';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';

interface InformationStepFormData {
    companyName: string;
    contactFirstName: string;
    contactLastName: string;
    companyAddress: string;
    city: string;
    state: string;
    companyZip: string;
    companyCountry: string;
    organizationLegalForm: CompanyEntityType;
    ein: string;
}

export const InformationStep = (props: MSTeamsPhoneNumbersProps) => {
    const { watch, control, handleSubmit, clearErrors } =
        useForm<InformationStepFormData>();
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutate: createBrand } = useBrandCreateQuery();
    const { mutate: updateBrand } = useBrandUpdateQuery();

    const [isLoading, setLoading] = useState(false);

    const country = watch('companyCountry') || 'US';

    const onSubmit: SubmitHandler<InformationStepFormData> = (
        data: InformationStepFormData,
    ) => {
        setLoading(true);
        if (brand?.id) {
            updateBrand({
                id: brand.id,
                brand: data,
            });
        } else {
            createBrand(data);
        }
        setLoading(false);
        clearErrors();
        props.goForward();
    };

    return (
        <StepShell
            title="Verify Your Business"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle={`Almost done activating SMS on ${
                props.selectedPhones!.length
            } numbers`}
        >
            {brandLoading ? (
                <div>Loading...</div>
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles['information-form']}
                >
                    <div className={styles['form-row']}>
                        <Controller
                            name="contactFirstName"
                            control={control}
                            rules={{
                                required: true,
                                minLength: 3,
                            }}
                            defaultValue={brand?.contactFirstName}
                            render={({ field, fieldState }) => (
                                <FormInput
                                    label="Your first name"
                                    required
                                    error={
                                        fieldState.isDirty && fieldState.invalid
                                    }
                                    iconName="user-signature"
                                    hint={
                                        fieldState.isDirty && fieldState.invalid
                                            ? 'Please enter your first name'
                                            : ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="contactLastName"
                            control={control}
                            rules={{
                                required: true,
                                minLength: 3,
                            }}
                            defaultValue={brand?.contactLastName}
                            render={({ field, fieldState }) => (
                                <FormInput
                                    label="Your last name"
                                    required
                                    error={
                                        fieldState.isDirty && fieldState.invalid
                                    }
                                    iconName="user-signature"
                                    hint={
                                        fieldState.isDirty && fieldState.invalid
                                            ? 'Please enter your last name'
                                            : ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <Controller
                        name="companyName"
                        control={control}
                        rules={{ required: true, minLength: 3 }}
                        defaultValue={brand?.companyName}
                        render={({ field, fieldState }) => (
                            <FormInput
                                label="Legal Company Name"
                                required
                                iconName="company"
                                error={fieldState.isDirty && fieldState.invalid}
                                hint={
                                    fieldState.isDirty && fieldState.invalid
                                        ? 'Company must be 3 characters long'
                                        : ''
                                }
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="companyAddress"
                        control={control}
                        defaultValue={brand?.companyAddress}
                        rules={{ required: true, minLength: 5 }}
                        render={({ field, fieldState }) => (
                            <FormInput
                                label="Address"
                                required
                                autofocus
                                iconName="address"
                                error={fieldState.isDirty && fieldState.invalid}
                                hint={
                                    fieldState.isDirty && fieldState.invalid
                                        ? 'Address is required'
                                        : ''
                                }
                                {...field}
                            />
                        )}
                    />
                    <div className={styles['form-row']}>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true, minLength: 3 }}
                            defaultValue={brand?.city}
                            render={({ field, fieldState }) => (
                                <FormInput
                                    label="City"
                                    required
                                    iconName="state"
                                    error={
                                        fieldState.isDirty && fieldState.invalid
                                    }
                                    hint={
                                        fieldState.isDirty && fieldState.invalid
                                            ? 'City must be 3 characters long'
                                            : ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="state"
                            control={control}
                            rules={{
                                required: true,
                                minLength: 2,
                                maxLength: 2,
                            }}
                            defaultValue={brand?.state}
                            render={({ field, fieldState }) => (
                                <Spacer height={0} width="100%">
                                    <FormControl label="State">
                                        <AdvancedSelect
                                            options={
                                                country === 'US'
                                                    ? stateOptions
                                                    : canadaStateOptions
                                            }
                                            value={field.value}
                                            error={fieldState?.error?.message}
                                            onChange={(value) => {
                                                field.onChange(value as string);
                                            }}
                                            searchable
                                        />
                                    </FormControl>
                                </Spacer>
                            )}
                        />
                    </div>
                    <div className={styles['form-row']}>
                        <Controller
                            name="companyCountry"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={brand?.companyCountry || 'US'}
                            render={({ field, fieldState }) => (
                                <Spacer height={0} width="100%">
                                    <FormControl label="Country">
                                        <AdvancedSelect
                                            options={countriesOptions}
                                            renderValue={renderCountryValue}
                                            value={field.value}
                                            renderOption={renderCountryOption}
                                            error={fieldState?.error?.message}
                                            onChange={(value) => {
                                                field.onChange(value as string);
                                            }}
                                            searchable
                                        />
                                    </FormControl>
                                </Spacer>
                            )}
                        />
                        <Controller
                            name="companyZip"
                            control={control}
                            rules={{ required: true, minLength: 3 }}
                            defaultValue={brand?.companyZip}
                            render={({ field, fieldState }) => (
                                <FormInput
                                    label="Zip / Postal Code"
                                    required
                                    iconName="zipcode"
                                    error={
                                        fieldState.isDirty && fieldState.invalid
                                    }
                                    hint={
                                        fieldState.isDirty && fieldState.invalid
                                            ? 'Company must be 3 characters long'
                                            : ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className={styles['form-row']}>
                        <Controller
                            name="organizationLegalForm"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={
                                brand?.organizationLegalForm ||
                                CompanyEntityType.PRIVATE_PROFIT
                            }
                            render={({ field, fieldState }) => (
                                <Spacer height={0} width="100%">
                                    <FormControl label="Entity Type">
                                        <AdvancedSelect
                                            value={field.value}
                                            options={
                                                organizationLegalFormOptions
                                            }
                                            error={fieldState?.error?.message}
                                            onChange={(value) => {
                                                field.onChange(
                                                    value as CompanyEntityType,
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Spacer>
                            )}
                        />
                        <Controller
                            name="ein"
                            control={control}
                            rules={{ minLength: 3 }}
                            defaultValue={brand?.ein}
                            render={({ field, fieldState }) => (
                                <FormInput
                                    label="EIN / Tax ID"
                                    iconName="number"
                                    error={
                                        fieldState.isDirty && fieldState.invalid
                                    }
                                    hint={
                                        fieldState.isDirty && fieldState.invalid
                                            ? 'Company must be 3 characters long'
                                            : ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <Spacer height={0} width="auto" />
                    <div className={styles['form-row']}>
                        <Spacer height="auto" width="50%">
                            <Spacer height="auto" width="96px">
                                <FormButton
                                    variant="clear"
                                    text="Back"
                                    type="button"
                                    isLoading={isLoading}
                                    onClick={props.goBack}
                                />
                            </Spacer>
                        </Spacer>
                        <Spacer height="auto" width="50%">
                            <FormButton
                                variant="primary"
                                text="Proceed"
                                isLoading={isLoading}
                            />
                        </Spacer>
                    </div>
                </form>
            )}
        </StepShell>
    );
};
