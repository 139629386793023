import Icon from '../../icons/Icon';
import styles from './CaretToggle.module.scss';

// eslint-disable-next-line react/display-name
export default ({ open = false }: { open: boolean }) => (
    <div className={open ? styles.open : styles.closed}>
        {open ? (
            <Icon
                className={styles['open-icon']}
                size="16px"
                name="triangle-down"
            />
        ) : (
            <Icon
                className={styles['closed-icon']}
                size="16px"
                name="triangle-right"
            />
        )}
    </div>
);
