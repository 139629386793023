import { inputBaseClasses, svgIconClasses, SxProps } from '@mui/material';

export const textField: SxProps = {
    [`& .${inputBaseClasses.root}.Mui-disabled`]: {
        backgroundColor: 'custom.gray.superLight',
        borderColor: 'custom.gray.light400',

        '& input': {
            color: 'primary.dark',
            '-webkitTextFillColor': 'inherit',
        },
    },
};

export const endAdornment: SxProps = { color: 'custom.gray.super' };

export const endAdornmentDash: SxProps = {
    ...endAdornment,

    [`& .${svgIconClasses.root}`]: {
        width: 14,
        height: 20,
        marginRight: 4,
    },
};

export const copyButton: SxProps = {
    marginRight: 2,
    color: 'primary.dark',
};
