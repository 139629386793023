import { useEffect, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './OptedOutConversations.module.scss';
import CohortContactsList from '../../components/CohortContactsList';
import { useGetConversation } from '../../queries/conversations';
import { Contact } from '../../api/types';
import { useContactsQueryData } from '../../queries/contacts';
import { route } from 'preact-router';
import { OptedOutConversationsList } from '../../components/ConversationsList/OptedOutConversationsList';
import Chat from '../../components/Chat';

interface OptedOutConversationsProps {
    conversationId: string;
}

export function OptedOutConversations({
    conversationId,
}: OptedOutConversationsProps) {
    const [selectedContact, setSelectedContact] = useState<
        Partial<Contact> | Contact | null
    >(null);

    const { data: conversation } = useGetConversation(conversationId);
    const contacts = useContactsQueryData();

    const memberContacts: Partial<Contact>[] | undefined =
        conversation?.members.map((member) =>
            contacts.getByPhoneOrEmpty(member),
        );

    useEffect(() => {
        if (conversation && conversation.status === 'deleted') {
            route(`/inbox/`);
        } else {
            if (memberContacts && memberContacts.length > 0) {
                setSelectedContact(memberContacts[0]);
            } else {
                setSelectedContact(null);
            }
        }
    }, [conversation, conversationId]);

    return (
        <div className={styles['opt-out']}>
            <div
                className={cc([
                    {
                        [styles['opt-out_mobile-hidden']]: !!conversation,
                    },
                ])}
            >
                <OptedOutConversationsList />
            </div>

            <section
                className={cc([
                    styles['chat'],
                    {
                        [styles['chat_empty']]: !conversation,
                        [styles['opt-out_mobile-hidden']]: !conversation,
                    },
                ])}
            >
                {conversationId && conversation && (
                    <Chat
                        conversation={conversation}
                        onBackClick={() => route('/opted-out')}
                        messageFormDisabled
                    />
                )}
                {!conversationId && (
                    <p className={styles['opt-out__chat-placeholder']}>
                        Choose chat to start conversation
                    </p>
                )}
            </section>

            <CohortContactsList
                contacts={memberContacts}
                selectedContact={selectedContact}
                onContactClick={setSelectedContact}
            />
        </div>
    );
}
