import { IconButton, Tooltip } from '@mui/material';
import { useGetSetting, useUpdateMeSettings } from '../../../../queries/user';
import { UserTeamSettingType } from '../../../../api/types';
import { SignatureIcon } from './SignatureIcon';
import { memo } from 'preact/compat';

export const SignatureButton = memo(
    ({ disabled = false }: { disabled?: boolean }) => {
        const { mutate: updateSettings } = useUpdateMeSettings();
        const { data: setting } = useGetSetting(UserTeamSettingType.Signature);
        const toggleSignature = () =>
            updateSettings({
                enabled: !setting?.enabled,
                type: UserTeamSettingType.Signature,
            });

        return (
            <Tooltip title="Enable/Disable Signature">
                <IconButton onClick={toggleSignature} disabled={disabled}>
                    <SignatureIcon
                        color={
                            setting?.enabled
                                ? 'info'
                                : disabled
                                  ? 'disabled'
                                  : 'primary'
                        }
                    />
                </IconButton>
            </Tooltip>
        );
    },
);
