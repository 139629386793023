import { Descendant } from 'slate';
import { Serializer } from '../slate';
import { TextSerializer } from './text';

export const JSONSerializer: Serializer<string> = {
    serialize: (nodes: Descendant[]) => {
        return JSON.stringify(nodes);
    },

    deserialize: (text): Descendant[] => {
        try {
            const res = JSON.parse(text);
            if (typeof res === 'object' && res !== null) {
                return res;
            }

            return TextSerializer.deserialize(text);
        } catch (e) {
            return TextSerializer.deserialize(text);
        }
    },
};
