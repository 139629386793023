import { IconButton, Paper, Popper, Tooltip } from '@mui/material';
import { SnippetIcon } from './SnippetIcon';
import { Ref, useState } from 'preact/hooks';
import MessageTemplates from '../../../MessageTemplates';
import { insertSnippet } from './insertSnippet';
import * as styles from './styles';
import { memo } from 'preact/compat';
import { CustomEditor } from '../../slate';

type Props = {
    containerRef: Ref<HTMLElement>;
    disabled?: boolean;
    editor: CustomEditor;
    withProperties: boolean;
    propertiesRegEx: RegExp;
};

export const SnippetButton = memo(
    ({
        containerRef,
        disabled = false,
        editor,
        propertiesRegEx,
        withProperties,
    }: Props) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        return (
            <>
                <Tooltip title="Add Snippet">
                    <IconButton
                        disabled={disabled}
                        onClick={() => {
                            setAnchorEl((ref) =>
                                ref ? null : containerRef?.current,
                            );
                        }}
                        ref={anchorEl}
                        color={anchorEl ? 'info' : 'primary'}
                    >
                        <SnippetIcon />
                    </IconButton>
                </Tooltip>

                {!!anchorEl && (
                    <Popper
                        disablePortal
                        open
                        anchorEl={anchorEl}
                        placement="top"
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ]}
                        sx={{
                            width: anchorEl?.clientWidth,
                        }}
                    >
                        <Paper sx={styles.paper}>
                            <MessageTemplates
                                withProperties={withProperties}
                                onSelect={(snippetMessage) => {
                                    insertSnippet(
                                        editor,
                                        snippetMessage,
                                        propertiesRegEx,
                                    );
                                    setAnchorEl(null);
                                }}
                            />
                        </Paper>
                    </Popper>
                )}
            </>
        );
    },
);
