import React, { useRef, useState, JSX } from 'react';
import {
    Button,
    ButtonProps,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { CloseIcon } from '../../../icons/shared/CloseIcon';
import * as styles from './styles';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

type GenericConfirmDialogProps = {
    onConfirm: (() => Promise<void>) | (() => void);
    onSubmit?: undefined;
};

type GenericFormDialogProps = {
    onSubmit: JSX.GenericEventHandler<HTMLFormElement>;
    onConfirm?: undefined;
};

export type GenericDialogProps = React.PropsWithChildren<
    {
        IconComponent?: React.ElementType<Exclude<SvgIconProps, 'component'>>;
        title: React.ReactNode;
        description: React.ReactNode;
        onClose: (() => Promise<void>) | (() => void);
        open: boolean;
        dismissButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
        dismissLabel?: React.ReactNode;
        confirmLabel?: React.ReactNode;
        confirmButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
    } & (GenericConfirmDialogProps | GenericFormDialogProps)
>;

export function GenericDialog({
    onSubmit,
    onClose,
    onConfirm,
    IconComponent,
    title,
    description,
    children,
    open,
    confirmButtonProps,
    dismissButtonProps,
    confirmLabel = 'Submit',
    dismissLabel = 'Dismiss',
}: GenericDialogProps) {
    const formElementRef = useRef<HTMLFormElement>(null);
    const [confirming, setConfirming] = useState(false);
    const [canceling, setCanceling] = useState(false);

    const modalContent = (
        <DialogContent sx={styles.dialogContent}>
            {!!IconComponent && <IconComponent sx={styles.icon} />}

            <Typography variant="h3" color="primary.dark" sx={styles.title}>
                {title}
            </Typography>
            <Typography variant="body3" color="custom.gray.super">
                {description}
            </Typography>

            {!!children && (
                <Stack spacing={4} sx={styles.content}>
                    {children}
                </Stack>
            )}
        </DialogContent>
    );

    const hasForm = typeof onSubmit === 'function';
    const hasConfirm = typeof onConfirm === 'function';
    const handleClose = async () => {
        setCanceling(true);
        await onClose();
        setCanceling(false);
    };

    const modalActions = (
        <DialogActions>
            <Button
                key="dissmiss"
                variant="outlined"
                sx={styles.dissmissButton}
                onClick={handleClose}
                disabled={canceling || confirming}
                {...dismissButtonProps}
            >
                {canceling ? <CircularProgress size={16} /> : dismissLabel}
            </Button>
            <Button
                key="confirm"
                type={hasForm ? 'submit' : 'button'}
                sx={styles.submitButton}
                variant="contained"
                disabled={canceling || confirming}
                onClick={
                    hasConfirm
                        ? async () => {
                              setConfirming(true);
                              await onConfirm();
                              setConfirming(false);
                          }
                        : undefined
                }
                {...confirmButtonProps}
            >
                {confirming ? <CircularProgress size={16} /> : confirmLabel}
            </Button>
        </DialogActions>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={styles.closeButton}
            >
                <CloseIcon />
            </IconButton>

            {hasForm ? (
                <form onSubmit={onSubmit} ref={formElementRef}>
                    {modalContent}
                    {modalActions}
                </form>
            ) : (
                <>
                    {modalContent}
                    {modalActions}
                </>
            )}
        </Dialog>
    );
}
