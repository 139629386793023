import IconBase, { IconBaseProps } from '../IconBase';

export const EmptySearchFilledIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M16.3435 15.6759C16.0364 16.1454 15.6811 16.5873 15.2797 16.9949C14.8783 17.4025 14.4431 17.7633 13.9807 18.075L19.3273 23.5038C19.9787 24.1652 21.0388 24.1652 21.6902 23.5038C22.3416 22.8424 22.3416 21.7661 21.6902 21.1047L16.3435 15.6759Z"
            fill="currentColor"
        />
        <path
            d="M9.37273 5.90796C8.03393 5.90796 6.77519 6.43733 5.82854 7.39856C4.88184 8.35979 4.36047 9.63781 4.36047 10.9972C4.36047 12.3565 4.88184 13.6346 5.82854 14.5958C6.77523 15.557 8.03393 16.0864 9.37273 16.0864C10.7115 16.0864 11.9702 15.557 12.9169 14.5958C13.8636 13.6346 14.385 12.3566 14.385 10.9972C14.385 9.63777 13.8636 8.35974 12.9169 7.39856C11.9702 6.43733 10.7115 5.90796 9.37273 5.90796ZM7.51624 12.8821C7.60946 12.9768 7.60946 13.1302 7.51624 13.2248C7.4696 13.2721 7.40855 13.2958 7.34744 13.2958C7.28634 13.2958 7.22529 13.2721 7.17865 13.2248C6.5926 12.6298 6.26986 11.8386 6.26986 10.9971C6.26986 10.1556 6.5926 9.36445 7.17865 8.7694C7.27193 8.67479 7.42301 8.67484 7.51619 8.7694C7.60942 8.86406 7.60942 9.01751 7.51619 9.11212C7.02031 9.61562 6.74721 10.2851 6.74721 10.9971C6.74721 11.7092 7.02036 12.3787 7.51624 12.8821ZM8.41801 8.57373C8.15437 8.57373 7.94066 8.35674 7.94066 8.08905C7.94066 7.82136 8.15437 7.60436 8.41801 7.60436C8.68166 7.60436 8.89537 7.82136 8.89537 8.08905C8.89537 8.35674 8.68166 8.57373 8.41801 8.57373Z"
            fill="currentColor"
        />
        <path
            d="M16.0937 15.1718C16.0978 15.1643 16.102 15.1569 16.107 15.1498C16.8511 13.9109 17.2491 12.4852 17.2491 10.9972C17.2491 8.86104 16.4298 6.85275 14.9421 5.34227C13.4545 3.83175 11.4765 2.99988 9.37267 2.99988C7.26881 2.99988 5.29088 3.83175 3.80324 5.34222C0.732254 8.46035 0.732254 13.534 3.80324 16.6521C5.29088 18.1626 7.26886 18.9945 9.37267 18.9945C10.8382 18.9945 12.2424 18.5904 13.4627 17.8348C13.4695 17.8298 13.4766 17.8257 13.4838 17.8216C14.0072 17.4952 14.4968 17.1043 14.9421 16.6521C15.3874 16.2001 15.7722 15.7031 16.0937 15.1718ZM13.2543 14.9385C12.2175 15.9913 10.839 16.5711 9.37267 16.5711C7.90637 16.5711 6.52776 15.9913 5.49094 14.9385C4.45412 13.8857 3.88305 12.4861 3.88305 10.9972C3.88305 9.50834 4.45407 8.10862 5.49094 7.05588C6.5278 6.00314 7.90637 5.42331 9.37267 5.42331C10.839 5.42331 12.2176 6.00309 13.2543 7.05588C14.2912 8.10867 14.8623 9.50839 14.8623 10.9972C14.8623 12.486 14.2912 13.8857 13.2543 14.9385Z"
            fill="currentColor"
        />
        <path
            d="M6.98575 21.4181C6.59093 21.4181 6.26971 21.7442 6.26971 22.1451C6.26971 22.546 6.59093 22.8721 6.98575 22.8721C7.38057 22.8721 7.70179 22.546 7.70179 22.1451C7.70179 21.7442 7.38057 21.4181 6.98575 21.4181Z"
            fill="currentColor"
        />
        <path
            d="M18.2038 4.93861C18.467 4.93861 18.6812 4.72118 18.6812 4.45392C18.6812 4.18667 18.467 3.96924 18.2038 3.96924C17.9406 3.96924 17.7264 4.18667 17.7264 4.45392C17.7264 4.72118 17.9406 4.93861 18.2038 4.93861Z"
            fill="currentColor"
        />
        <path
            d="M2.8158 17.9874C2.55258 17.9874 2.33844 18.2048 2.33844 18.4721C2.33844 18.7394 2.55258 18.9568 2.8158 18.9568C3.07901 18.9568 3.29316 18.7394 3.29316 18.4721C3.29316 18.2048 3.07896 17.9874 2.8158 17.9874Z"
            fill="currentColor"
        />
        <path
            d="M21.5453 10.7548C21.0189 10.7548 20.5906 11.1896 20.5906 11.7241C20.5906 12.2586 21.0189 12.6935 21.5453 12.6935C22.0717 12.6935 22.5 12.2586 22.5 11.7241C22.5 11.1896 22.0717 10.7548 21.5453 10.7548Z"
            fill="currentColor"
        />
    </IconBase>
);
