import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
// styles
import styles from './FilterTextValue.module.scss';
// components
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
import Radio from '../../../../elements/Radio';
// types
import {
    TextTypeValue,
    UserPropertyFilterModel,
} from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Fragment } from 'preact';

const LIST = [
    { constraintType: TextTypeValue.Is, label: 'Is' },
    { constraintType: TextTypeValue.IsNot, label: 'Is Not' },
    { constraintType: TextTypeValue.Contains, label: 'Contains' },
    { constraintType: TextTypeValue.DoesNotContain, label: 'Does not contain' },
    { constraintType: TextTypeValue.Exists, label: 'Exists' },
];

interface FilterTextValueProps {
    userPropertyFilter: UserPropertyFilterModel<string>;
    changeHandler: (value: UserPropertyFilterModel) => void;
    closeHandler: () => void;
}

export const FilterTextValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterTextValueProps) => {
    const { value, constraint } = userPropertyFilter;

    const [selectedType, setSelectedType] = useState(
        constraint as TextTypeValue,
    );
    const [selectedValue, setSelectedValue] = useState(value);

    const dropdownContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedType) {
            changeHandler({
                ...userPropertyFilter,
                constraint: selectedType,
                value: selectedValue,
            });
        }
    }, [selectedType, selectedValue]);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div className={styles['filter__top-panel']}>Relative:</div>

            <DropdownList className={styles['filter__list']}>
                {LIST.map(({ constraintType, label }, i) => (
                    <Fragment key={constraintType}>
                        <DropdownItem
                            variant="list-item"
                            type="regular"
                            className={cc([
                                {
                                    [styles['filter__item_last']]:
                                        i === LIST.length - 1 &&
                                        !(
                                            constraintType === selectedType &&
                                            constraintType !==
                                                TextTypeValue.Exists
                                        ),
                                },
                            ])}
                            onClick={() => {
                                setSelectedType(constraintType);
                                setSelectedValue('');
                            }}
                        >
                            <Radio checked={constraintType === selectedType} />
                            <span className={styles['filter__item-name']}>
                                {label}
                            </span>
                            <div></div>
                        </DropdownItem>
                        {constraintType === selectedType &&
                            constraintType !== TextTypeValue.Exists && (
                                <li
                                    className={cc([
                                        {
                                            [styles['filter__item_last']]:
                                                i === LIST.length - 1,
                                        },
                                    ])}
                                >
                                    <Input
                                        wrapperClassName={
                                            styles['filter__input-wrapper']
                                        }
                                        className={styles['filter__input']}
                                        value={selectedValue}
                                        onChange={setSelectedValue}
                                    />
                                </li>
                            )}
                    </Fragment>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
