import { useEffect, useRef, useState } from 'preact/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { route } from 'preact-router';
import styles from './TeamSelector.module.scss';
import teamsSwitcherIcon from '../../assets/icons/navigation/team-switcher.svg';
import { ClerkPermissions, Team } from '../../api/types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { signOut } from '../../api/auth';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { CURRENT_USER_KEY, useMeQueryData } from '../../queries/user';
import TeamSelectorMenuItem from '../TeamSelectorMenuItem';
import Icon from '../../icons/Icon';
import TeamSelectorItem from '../TeamSelectorItem';
import { Avatar, Button, Typography } from '@mui/material';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import { isMobile } from '../../utils/mobile';
import { useTeams } from 'msteams-react-base-component';

const DEFAULT_NAME = 'Team';

const getCurrentTeam = (teams: Team[], teamId: string) =>
    teams.find((team) => team.id === teamId) || teams[0];

interface TeamSelectorProps {
    teamId: string;
    teams: Array<Team>;
    onSelect?: (team: Team) => void;
}

export const TeamSelector = ({
    teamId,
    teams,
    onSelect,
}: TeamSelectorProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [list, setList] = useState<Array<Team>>(teams);
    const [current, setCurrent] = useState<Team>(getCurrentTeam(teams, teamId));
    const [{ inTeams }] = useTeams();

    const queryClient = useQueryClient();
    const me = useMeQueryData();

    useEffect(() => {
        setList(teams);
        setCurrent(getCurrentTeam(teams, teamId));
    }, [teams, teamId]);

    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => setIsOpen(false));

    return (
        <div className={styles['team-selector']} ref={containerRef}>
            <Button
                variant="text"
                onClick={() => setIsOpen(!isOpen)}
                fullWidth
                disableRipple
                startIcon={
                    <Avatar
                        sx={{ width: 32, height: 32 }}
                        alt={current?.name || DEFAULT_NAME}
                        src={me?.activeTeam.teamAvatarURL || logo}
                    />
                }
                endIcon={<img src={teamsSwitcherIcon} alt="team switch icon" />}
                sx={{
                    height: 60,
                    padding: '0 16px',
                    overflow: 'hidden',
                    justifyContent: 'flex-start',
                }}
            >
                <Typography
                    variant="subtitle2"
                    noWrap
                    width="100%"
                    textAlign="left"
                >
                    {current?.name || DEFAULT_NAME}
                </Typography>
            </Button>
            {isOpen && (
                <div className={styles['team-selector__dropdown']}>
                    <section className={styles['team-selector__section']}>
                        <h6 className={styles['team-selector__section-header']}>
                            MY TEAM(S)
                        </h6>
                        <ul className={styles['team-selector__list']}>
                            {list.map((team) => (
                                <TeamSelectorItem
                                    key={team.id}
                                    teamName={team.name}
                                    avatarUrl={team.teamAvatarURL}
                                    isActive={team.id === current.id}
                                    onClick={() => {
                                        if (team.id !== current.id) {
                                            setCurrent(team);
                                            onSelect?.(team);
                                        }
                                        setIsOpen(false);
                                    }}
                                />
                            ))}
                        </ul>
                    </section>

                    {!isMobile() && (
                        <section className={styles['team-selector__section']}>
                            <h6
                                className={
                                    styles['team-selector__section-header']
                                }
                            >
                                ACCOUNT SETTINGS
                            </h6>
                            <ul
                                className={
                                    styles['team-selector__section-list']
                                }
                            >
                                <TeamSelectorMenuItem
                                    icon={<Icon name="user-profile" />}
                                    label="My profile"
                                    onClick={() => {
                                        setIsOpen(false);
                                        route('/settings/profile');
                                    }}
                                />
                                <WithPermission
                                    requiredPermissions={[
                                        ClerkPermissions.ModifyTeam,
                                    ]}
                                >
                                    <TeamSelectorMenuItem
                                        icon={<Icon name="team-info" />}
                                        label="Team info"
                                        onClick={() => {
                                            setIsOpen(false);
                                            route('/settings/team');
                                        }}
                                    />
                                </WithPermission>
                                <WithPermission
                                    requiredPermissions={[
                                        ClerkPermissions.AddTeamMembers,
                                    ]}
                                >
                                    <TeamSelectorMenuItem
                                        icon={<Icon name="members" />}
                                        label="Members"
                                        onClick={() => {
                                            setIsOpen(false);
                                            route('/settings/members');
                                        }}
                                    />
                                </WithPermission>
                            </ul>
                        </section>
                    )}
                    {!inTeams && (
                        <button
                            className={styles['team-selector__logout-btn']}
                            onClick={async () => {
                                await signOut();
                                await queryClient.invalidateQueries({
                                    queryKey: [CURRENT_USER_KEY],
                                });
                            }}
                        >
                            <Icon
                                name="power-off"
                                className={
                                    styles['team-selector__logout-btn-icon']
                                }
                            />
                            <span
                                className={
                                    styles['team-selector__logout-btn-text']
                                }
                            >
                                Log out
                            </span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
