import { usePopper } from 'react-popper';
import { createPortal, useState } from 'preact/compat';
import styles from './DropContent.module.scss';
import { CohortIdName } from '../../api/cohorts';

type PopupElement = HTMLElement | null;

interface DropContentProps {
    options: CohortIdName[];
}

export const DropContent = (props: DropContentProps) => {
    const [hovered, setHovered] = useState(false);
    const [refElement, setRefElement] = useState<PopupElement>(null);
    const [popperElement, setPopperElement] = useState<PopupElement>(null);

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        refElement,
        popperElement,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    );

    return (
        <div className={styles['root']} ref={setRefElement}>
            <button
                className={styles['root__btn']}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {`+${props.options.length}`}
            </button>
            {hovered &&
                createPortal(
                    <ul
                        className={styles['root__list']}
                        ref={setPopperElement}
                        style={popperStyles.popper}
                        {...popperAttributes.popper}
                    >
                        {props.options.map((item) => (
                            <li
                                key={item.id}
                                className={styles['root__list-item']}
                            >
                                <span className={styles['root__text']}>
                                    {item.name}
                                </span>
                            </li>
                        ))}
                    </ul>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
};
