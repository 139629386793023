import { useEffect } from 'preact/hooks';

const enabled = import.meta.env.VITE_FF_CAMPAIGN_V3 === 'true';

const KEY = 'campaign_v3_enabled';

export const useCampaignV3Enabled = () => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search).get(
            '_campaign_v3_enabled',
        );
        if (typeof params === 'string') {
            switch (params) {
                case 'true':
                    window.localStorage.setItem(KEY, 'true');
                    break;
                case 'false':
                    window.localStorage.setItem(KEY, 'false');
                    break;
                default:
                    break;
            }
        }
    }, []);

    return window.localStorage.getItem(KEY) === 'true' || enabled;
};
