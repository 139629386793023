import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    SvgIcon,
    SxProps,
    TextField,
    Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import icon from './ic_attachment.svg?react';
import { ChangeEvent } from 'preact/compat';
import { prettySize } from './pretty-bytes';
import CloseIcon from '@mui/icons-material/Close';
import { getMimeIcon } from './mime-icon.factory';
import { isURL } from './is-url.helper';
import { useSourceFileMutation, useTextSourceMutation } from '../source.query';
import { isAllowed } from './mime-types';
import { attachmentButton } from './styles';
import { WebsiteIcon } from '../List/SourceIcon';
import { TextSourceIcon } from '../../../../icons/common/files/ExtensionIcon';
import { acceptDocFormats, acceptFileFormats } from '../../../../constants';

type Props = { sx?: SxProps };
const ONE_HUNDRED_MEGABYTES = 100_000_000;

/** todo: add dropzone */
export const SourceForm = ({ sx }: Props) => {
    const fileMutation = useSourceFileMutation();
    const textSourceMutation = useTextSourceMutation();
    const fileInput = useRef<HTMLInputElement>();
    const textInput = useRef<HTMLInputElement>();

    const [file, setFile] = useState<File | null>(null);
    const [textSource, setTextSource] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const submitFile = () => {
        setErrorMessage('');
        if (!file) {
            fileInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please add URL, File or text');
            return;
        }
        if (!isAllowed(file.type)) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('File should be PDF, DOC or csv format');
            return;
        }
        if (file.size > ONE_HUNDRED_MEGABYTES) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage(
                `File should be less than ${prettySize(ONE_HUNDRED_MEGABYTES)}`,
            );
            return;
        }
        fileMutation.mutate(file);
    };

    const submitText = () => {
        setErrorMessage('');
        if (!textSource) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please enter a text or add a file');
            return;
        }
        if (
            !isURL(textSource) &&
            (textSource.length < 120 || textSource.length > 65_000)
        ) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage(
                'Please enter a text between 120 and 65000 characters',
            );
            return;
        }
        textSourceMutation.mutate(textSource);
    };

    const updateFile = (file?: File) => {
        setTextSource('');
        setFile(file ?? null);
    };

    const onFileChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        if (!currentTarget) {
            return;
        }
        updateFile(currentTarget?.files?.[0]);
    };

    const textFieldValue = file
        ? `${file.name} (${prettySize(file.size)})`
        : textSource;
    const clear = () => {
        setFile(null);
        setTextSource('');
        setErrorMessage('');
        if (fileInput.current) {
            fileInput.current.value = '';
        }
    };

    const onSubmit = (event: SubmitEvent) => {
        event.preventDefault();
        if (!file && !textSource) {
            textInput.current?.querySelector('input')?.focus();
            setErrorMessage('Please add URL, File or text');
            return;
        }
        if (file) {
            submitFile();
            return;
        }
        submitText();
    };

    useEffect(() => {
        if (fileMutation.isSuccess || textSourceMutation.isSuccess) {
            clear();
        }
    }, [fileMutation.isSuccess, textSourceMutation.isSuccess]);

    const StartIcon = useMemo(
        () => (isURL(textSource) ? WebsiteIcon : TextSourceIcon),
        [isURL(textSource)],
    );
    const MimeIcon = useMemo(() => getMimeIcon(file?.type), [file?.type]);
    const loading = fileMutation.isPending || textSourceMutation.isPending;

    const fileAdornment = file ? (
        <Box mx={1} alignItems="center" displa="flex">
            <MimeIcon />
        </Box>
    ) : (
        <Tooltip title="Attach">
            <IconButton
                component="label"
                htmlFor="source-file-input"
                aria-label="Attach"
                size="small"
                sx={attachmentButton}
            >
                <SvgIcon
                    component={icon}
                    inheritViewBox
                    sx={{ fontSize: 20 }}
                />
            </IconButton>
        </Tooltip>
    );

    const startAdornment = (
        <InputAdornment position="start">
            {textSource ? (
                <Box mx={1} alignItems="center" displa="flex">
                    <StartIcon />
                </Box>
            ) : (
                fileAdornment
            )}
        </InputAdornment>
    );

    const endAdornment = useMemo(() => {
        if (loading) {
            return <CircularProgress color="orange" size={20} />;
        }
        return textFieldValue || errorMessage ? (
            <InputAdornment position="end">
                <Tooltip title="Clear">
                    <span>
                        <IconButton
                            aria-label="Clear"
                            size="small"
                            onClick={clear}
                            disabled={loading}
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </InputAdornment>
        ) : undefined;
    }, [!!textFieldValue, loading]);
    return (
        <Box component="form" width={1} sx={sx} onSubmit={onSubmit}>
            <Box display="flex" gap={6} width={1}>
                <Box
                    ref={fileInput}
                    component="input"
                    display="none"
                    id="source-file-input"
                    type="file"
                    accept={acceptDocFormats}
                    onChange={onFileChange}
                />
                <TextField
                    color="blue"
                    value={textFieldValue}
                    onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                        setTextSource(target?.value || '')
                    }
                    onPaste={(event: ClipboardEvent) => {
                        if (!event.clipboardData?.files.length) {
                            return;
                        }
                        const [first] = Array.from(event.clipboardData?.files);
                        if (first && first instanceof File) {
                            updateFile(first);
                            event.preventDefault();
                        }
                    }}
                    readonly={!!file}
                    disabled={loading}
                    aria-label="Knowledge Source"
                    placeholder="Paste link, article text or attach file"
                    InputProps={{
                        ref: textInput,
                        endAdornment,
                        startAdornment,
                    }}
                    sx={{ flexGrow: 1 }}
                    helperText={
                        errorMessage || 'PDF, doc and csv files are supported'
                    }
                    error={errorMessage.length > 1}
                />
                <div>
                    <Button
                        disabled={loading}
                        sx={{ minWidth: 140 }}
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </Box>
    );
};
