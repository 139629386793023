import { DefaultElement, RenderElementProps } from 'slate-react';
import { Template, TemplateProps } from './components/Template/Template';
import { Paragraph, ParagraphProps } from './components/Paragraph/Paragraph';

export function renderElement({ children, ...props }: RenderElementProps) {
    switch (props.element.type) {
        case 'template':
            return (
                <Template {...(props as TemplateProps)}>{children}</Template>
            );
        case 'paragraph':
            return (
                <Paragraph {...(props as ParagraphProps)}>{children}</Paragraph>
            );
        default:
            return <DefaultElement {...props}>{children}</DefaultElement>;
    }
}
