import avatarPlaceholder from '../../icons/common/avatarPlaceholder.svg';
import styles from './Avatar.module.scss';
import { getGradient } from './avatarUtils';
import { useMemo } from 'preact/hooks';

interface AvatarPlaceholderProps {
    username?: string;
    hideInitials?: boolean;
}

export const AvatarPlaceholder = (props: AvatarPlaceholderProps) => {
    const background = useMemo(
        () =>
            props.username && !props.hideInitials
                ? getGradient(props.username.toLowerCase().charCodeAt(0))
                : 'none',
        [props.username],
    );

    return (
        <div
            className={styles.placeholder}
            style={{
                background,
            }}
        >
            {props.username && !props.hideInitials ? (
                props.username
                    .split(' ')
                    .slice(0, 2)
                    .map((v) => v[0] && v[0].toUpperCase())
                    .join(' ')
            ) : (
                <img src={avatarPlaceholder} />
            )}
        </div>
    );
};
