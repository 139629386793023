import { SxProps } from '@mui/material';

export const getViewSelector = (selected: boolean): SxProps => ({
    p: 1,
    color: selected ? 'primary.dark' : 'custom.gray.super',
    justifyContent: 'center',
    transition: 'color .2s',
    ':hover': {
        color: 'primary.main',
    },
});
export const indicatorContainer: SxProps = {
    display: 'flex',
    width: 1,
    height: 1,
    mt: 4,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    p: 4,
    gap: 3,
    flexDirection: 'column',
};
