import { Box, Divider, Typography } from '@mui/material';
import { UsageDetails } from '../../components/UsageDetails/UsageDetails';
import { useEffect } from 'preact/hooks';
import { CURRENT_USER_KEY } from '../../queries/user';
import { useQueryClient } from '@tanstack/react-query';

export const Analytics = () => {
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey: [CURRENT_USER_KEY],
        });
    }, []);

    return (
        <div>
            <Box py={4} px={8}>
                <Typography color="primary.dark" variant="h2">
                    Analytics
                </Typography>
            </Box>
            <Divider
                component="div"
                role="presentation"
                sx={{
                    borderColor: 'custom.gray.divider',
                }}
            />
            <Box py={4} px={8}>
                <UsageDetails />
            </Box>
        </div>
    );
};
