import { Avatar } from '@mui/material';
import avatarPlaceholder from '../../icons/common/avatarPlaceholder.svg';

type Props = {
    imageUrl?: string;
    // sizes according to the design: https://www.figma.com/file/MQWa8UO5U0Z1ez31yASqt2/Styleguide-(MUI)?node-id=6587%3A47387
    size: 20 | 32 | 40 | 64;
    variant?: 'circular' | 'rounded';
};

export function UserAvatar({
    imageUrl = avatarPlaceholder,
    variant = 'circular',
    size,
}: Props) {
    return (
        <Avatar
            src={imageUrl}
            variant={variant}
            sx={{
                width: size,
                height: size,
            }}
        />
    );
}
