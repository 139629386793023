import client from './http';

export const setupShopifySubscription = (
    tier: 'starter' | 'unlimited',
    billingCycle: 'monthly' | 'yearly',
) =>
    client().post<{ confirmation_url: string }>(
        '/shopify/billing/createRecurringCharge',
        { tier, billingCycle },
    );
