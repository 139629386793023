import styles from '../SideBarInboxes/SideBarInboxes.module.scss';
import { useMemo } from 'preact/hooks';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
} from 'react-beautiful-dnd';
import { Inbox } from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import InboxItem from '../../../elements/InboxItem';
import { useMeQueryData } from '../../../queries/user';
import { useInboxes } from '../../../queries/inboxes';

interface SideBarMutedInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarMutedInboxes = ({
    setMenuOpen,
}: SideBarMutedInboxesProps) => {
    const currentUser = useMeQueryData();

    const mutedInboxesIds = currentUser?.mutedInboxIds || [];
    const { data: inboxes = [] } = useInboxes();

    const mutedInboxes = useMemo(
        () => inboxes.filter((inbox) => mutedInboxesIds.includes(inbox.id)),
        [inboxes, mutedInboxesIds],
    );

    if (mutedInboxes.length > 0) {
        return (
            <CollapsibleSection
                title={`Muted Inboxes (${mutedInboxes.length})`}
            >
                <DragDropContext onDragEnd={() => console.log('not yet')}>
                    <Droppable droppableId="inboxes-list">
                        {(providedDroppable: DroppableProvided) => (
                            /* @ts-ignore*/
                            <ul
                                className={styles['root']}
                                ref={providedDroppable.innerRef}
                                {...providedDroppable}
                            >
                                {mutedInboxes.map((inbox: Inbox, i: number) => (
                                    <InboxItem
                                        index={i}
                                        key={inbox.id}
                                        inbox={inbox}
                                        countryCode={
                                            currentUser?.activeTeam.countryCode
                                        }
                                        onClick={() => setMenuOpen(false)}
                                        isMuted
                                    />
                                ))}
                                {providedDroppable.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </CollapsibleSection>
        );
    }

    return <></>;
};
