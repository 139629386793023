import { linearProgressClasses, SxProps, Theme } from '@mui/material';
import bg from '../../assets/images/minimal-pattern-header-bg.png';

export const fancyPattern: SxProps<Theme> = {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    height: 1,
    width: 0.2,
    maxWidth: 255,
    background: ({ palette }) =>
        `linear-gradient(-265deg, #F7F8FE 0%, rgba(255, 255, 255, 0.00) 69.83%), url(${bg}), ${palette.custom.secondary.blue.light}`,
    backgroundSize: 'cover',
};
export const card: SxProps = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
export const content: SxProps = {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 1,
};
export const progress: SxProps = {
    marginTop: 2,
    height: 6,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'custom.gray.divider',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#01AF2C',
    },
};
export const textCenter: SxProps = {
    textAlign: 'center',
};
