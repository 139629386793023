export const CArrowDropRightFilled = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.23408 13.6697L12.6179 10.7841C13.1274 10.3496 13.1274 9.64768 12.6179 9.21316L9.23408 6.32757C8.411 5.62567 7 6.12702 7 7.1186L7 12.8898C7 13.8814 8.411 14.3716 9.23408 13.6697Z"
            fill="currentColor"
        />
    </svg>
);
