import { Contact } from '../api/types';

type PhoneNumber = string;
type ContactIndex = number;

export interface ContactsCacheData {
    list: Contact[];
    mapByPhone: Record<PhoneNumber, ContactIndex>;
}

export interface ContactsCacheMethods {
    getByPhoneOrEmpty(phone: PhoneNumber): Partial<Contact>;
    get(contact: Partial<Contact>): Contact | null;
    add(contact: Contact): void;
    update(contact: Contact): boolean;
    remove(contact: Contact): boolean;
}

const DEFAULT: ContactsCacheData = { list: [], mapByPhone: {} };

export class ContactsCache implements ContactsCacheMethods, ContactsCacheData {
    list: Contact[];
    mapByPhone: Record<PhoneNumber, ContactIndex>;

    constructor(data: ContactsCacheData | ContactsCache = DEFAULT) {
        this.list = data?.list.slice() || [];
        this.mapByPhone = { ...data?.mapByPhone };
    }

    count() {
        return this.list.length;
    }

    findIndexByPhone(phone: PhoneNumber) {
        const idx = this.mapByPhone[phone];

        if (typeof idx === 'undefined') {
            return null;
        }
        return idx;
    }

    findIndexById(id: string) {
        const idx = this.list.findIndex((contact) => contact.id === id);

        if (idx === -1) {
            return null;
        }
        return idx;
    }

    getByPhone(phone?: PhoneNumber) {
        if (phone) {
            const idx = this.findIndexByPhone(phone);
            if (idx !== null) {
                return this.list[idx];
            }
        }

        return null;
    }

    getById(id?: string) {
        if (id) {
            const idx = this.findIndexById(id);
            if (idx !== null) {
                return this.list[idx];
            }
        }

        return null;
    }

    getEmpty(phone?: PhoneNumber) {
        return {
            id: undefined,
            name: '',
            data: {},
            email: '',
            avatarURL: '',
            phone,
        };
    }

    getByPhoneOrEmpty(phone?: PhoneNumber): Partial<Contact> {
        return this.getByPhone(phone) || this.getEmpty(phone);
    }

    get(contact: Partial<Contact>) {
        const { id, phone } = contact;
        return this.getByPhone(phone) || this.getById(id);
    }

    getOrEmpty(contact: Partial<Contact>): Partial<Contact> {
        const { id, phone } = contact;
        return (
            this.getByPhone(phone) || this.getById(id) || this.getEmpty(phone)
        );
    }

    add(contact: Contact) {
        const length = this.list.push(contact);
        if (contact.phone) {
            this.mapByPhone[contact.phone] = length - 1;
        }
    }

    update(contact: Contact) {
        const { id, phone } = contact;

        if (phone) {
            const idxByPhone = this.findIndexByPhone(phone);
            if (idxByPhone !== null) {
                this.list[idxByPhone] = contact;
                return true;
            }
        }

        const idxById = this.findIndexById(id);
        if (idxById !== null) {
            this.list[idxById] = contact;

            if (contact.phone) {
                this.mapByPhone[contact.phone] = idxById;
            }
            return true;
        }

        return false;
    }

    removeByIndex(idx: ContactIndex) {
        this.list.splice(idx, 1);

        for (let i = idx; i < this.list.length; i++) {
            this.mapByPhone[this.list[i].phone] = i;
        }

        return true;
    }

    removeById(id: string) {
        const idxById = this.findIndexById(id);
        if (idxById !== null) {
            this.removeByIndex(idxById);
            return true;
        }

        return false;
    }

    remove(contact: Contact) {
        const { id, phone } = contact;

        const idxByPhone = this.findIndexByPhone(phone);
        if (idxByPhone !== null) {
            delete this.mapByPhone[phone as string];
            this.removeByIndex(idxByPhone);
            return true;
        }

        const idxById = this.findIndexById(id);
        if (idxById !== null) {
            this.removeByIndex(idxById);
            return true;
        }

        return this.removeById(id);
    }

    has(contact: Contact) {
        return this.findIndexById(contact.id) !== null;
    }

    serialize() {
        return { list: [...this.list], mapByPhone: { ...this.mapByPhone } };
    }
}
