/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { usePopper } from 'react-popper';
import cc from 'classcat';
import styles from './UserProperty.module.scss';
import {
    OnOptionCreateHandler,
    OnPropertyChangeHandler,
    OnPropertyDeleteHandler,
    UserPropertyModel,
} from '../types';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { renderValue } from '../UserPropertyValue';
import Icon from '../../../icons/Icon';
import PropertyIconEditor from '../../ContactProperty/PropertyIconEditor';
import PropertyConfigurator from '../../ContactProperty/PropertyConfigurator';
import { getPropertyIcon } from '../../../utils/contact-properties';

type PopupElement = HTMLElement | null;

interface UserPropertyProps {
    contactId?: string;
    userProperty: UserPropertyModel;
    nameEditMode?: boolean;
    valueTransformer?: (value: any) => any;
    onPropertyChange: OnPropertyChangeHandler;
    onValueChange: (value: any) => void;
    onDelete?: OnPropertyDeleteHandler;
    onOptionCreate?: OnOptionCreateHandler;
}

function UserProperty({
    contactId,
    userProperty,
    nameEditMode = false,
    valueTransformer,
    onPropertyChange,
    onValueChange,
    onDelete,
    onOptionCreate,
}: UserPropertyProps) {
    const [iconEditorActive, setIconEditorActive] = useState(false);
    const [nameEditorActive, setNameEditorActive] = useState(nameEditMode);

    const iconEditorWrapperRef = useRef<HTMLDivElement>(null);
    const nameEditorWrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(iconEditorWrapperRef, () => setIconEditorActive(false));
    useOnClickOutside(nameEditorWrapperRef, () => setNameEditorActive(false));

    const [referenceElement, setReferenceElement] =
        useState<PopupElement>(null);
    const [iconEditorPopperElement, setIconEditorPopperElement] =
        useState<PopupElement>(null);
    const [nameEditorPopperElement, setNameEditorPopperElement] =
        useState<PopupElement>(null);

    const { styles: iconEditorStyles, attributes: iconEditorAttributes } =
        usePopper(referenceElement, iconEditorPopperElement, {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    const { styles: nameEditorStyles, attributes: nameEditorAttributes } =
        usePopper(referenceElement, nameEditorPopperElement);

    return (
        <div className={styles['user-property']} ref={setReferenceElement}>
            <Icon
                className={cc([
                    styles['user-property__icon'],
                    {
                        [styles['user-property__icon_readonly']]:
                            userProperty.readonly,
                    },
                ])}
                size="20px"
                name={getPropertyIcon(userProperty)}
                onClick={() =>
                    userProperty.readonly
                        ? () => false
                        : setIconEditorActive(!iconEditorActive)
                }
            />

            <span
                title={userProperty.name}
                className={cc([
                    styles['user-property__name'],
                    {
                        [styles['user-property__name_readonly']]:
                            userProperty.readonly,
                    },
                ])}
                onClick={() =>
                    userProperty.readonly
                        ? () => false
                        : setNameEditorActive(true)
                }
            >
                {userProperty.name}
            </span>

            {renderValue(
                userProperty,
                contactId,
                valueTransformer,
                onValueChange,
                onOptionCreate,
            )}

            {iconEditorActive &&
                createPortal(
                    <div
                        ref={setIconEditorPopperElement}
                        style={iconEditorStyles.popper}
                        {...iconEditorAttributes.popper}
                    >
                        <div ref={iconEditorWrapperRef}>
                            <PropertyIconEditor
                                currentIcon={userProperty.icon}
                                onSelect={(icon) => {
                                    onPropertyChange({ ...userProperty, icon });
                                    setIconEditorActive(false);
                                }}
                            />
                        </div>
                    </div>,
                    document.querySelector('#portal')!,
                )}

            {nameEditorActive &&
                createPortal(
                    <div
                        ref={setNameEditorPopperElement}
                        style={nameEditorStyles.popper}
                        {...nameEditorAttributes.popper}
                    >
                        <div
                            ref={nameEditorWrapperRef}
                            className={styles['user-property__popup']}
                        >
                            <PropertyConfigurator
                                type={userProperty.type}
                                name={userProperty.name}
                                onNameChange={(name) => {
                                    onPropertyChange({ ...userProperty, name });
                                    setNameEditorActive(false);
                                }}
                                onRemove={() => {
                                    onDelete?.(userProperty);
                                    setNameEditorActive(false);
                                }}
                            />
                        </div>
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
}

export default UserProperty;
