import { route } from 'preact-router';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import {
    ClerkPermissions,
    Inbox,
    UserTeamSettingType,
} from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import InboxItem from '../../../elements/InboxItem';
import Tooltip from '../../../elements/Tooltip';
import useUnmutedSortedInboxes from '../../../hooks/useUnmutedSortedInboxes';
import Icon from '../../../icons/Icon';
import { useMeQueryData, useUpdateMeSettings } from '../../../queries/user';
import { canSetupInbox } from '../../../utils/inboxes';
import { WithPermission } from '../../WithPermission/WithPermission';
import addButtonStyles from '../AddButton.module.scss';
import styles from './SideBarInboxes.module.scss';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useCallback } from 'preact/hooks';

interface SideBarInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarInboxes = ({ setMenuOpen }: SideBarInboxesProps) => {
    const currentUser = useMeQueryData();
    const { inboxes, unmutedSortedInboxes } = useUnmutedSortedInboxes();
    const { dispatch } = useAnalytics();
    const { mutate: updateSettings } = useUpdateMeSettings();

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const newInboxesOrder = Array.from(unmutedSortedInboxes);
            const [reorderedItem] = newInboxesOrder.splice(
                result.source.index,
                1,
            );
            newInboxesOrder.splice(result.destination.index, 0, reorderedItem);

            updateSettings({
                enabled: true,
                type: UserTeamSettingType.InboxesOrder,
                data: {
                    ids: newInboxesOrder.map((inbox) => inbox.id),
                },
            });
        },
        [unmutedSortedInboxes, updateSettings],
    );

    const handleInboxItemClick = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);

    const handleAddButtonClick = useCallback(() => {
        dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: AnalyticsEventName.CLICKED_CREATE_NEW_INBOX,
            },
        });
        canSetupInbox(currentUser!, inboxes)
            ? route('/setup-inbox/number-type')
            : route('/payment/plan');
    }, [currentUser, dispatch, inboxes]);

    return (
        <CollapsibleSection
            title="Inboxes"
            extraButton={
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <Tooltip text="Create new inbox">
                        <button
                            className={addButtonStyles['add-btn']}
                            onClick={handleAddButtonClick}
                        >
                            <Icon
                                className={addButtonStyles['add-btn-icon']}
                                name="plus"
                                size="16px"
                            />
                        </button>
                    </Tooltip>
                </WithPermission>
            }
            initiallyExpanded
        >
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="inboxes-list">
                    {(providedDroppable: DroppableProvided) => (
                        /* @ts-ignore */
                        <ul
                            className={styles['root']}
                            ref={providedDroppable.innerRef}
                            {...providedDroppable.droppableProps}
                        >
                            {unmutedSortedInboxes.map((inbox: Inbox, i) => (
                                <InboxItem
                                    key={inbox.id}
                                    index={i}
                                    inbox={inbox}
                                    countryCode={
                                        currentUser?.activeTeam.countryCode
                                    }
                                    onClick={handleInboxItemClick}
                                />
                            ))}
                            {providedDroppable.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </CollapsibleSection>
    );
};
