import { useEffect, useRef, useState } from 'preact/hooks';
import { route } from 'preact-router';
import cc from 'classcat';
import { FiLock, FiUnlock } from 'react-icons/fi';
import styles from './InboxSettings.module.scss';
import phoneIcon from '../../assets/icons/icons-20/phone.svg';
import redCrossSvg from '../../assets/icons/red-cross.svg';
import { ClerkPermissions } from '../../api/types';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import Button from '../../elements/Buttons';
import { WithPermission } from '../WithPermission/WithPermission';
import {
    useDeleteInbox,
    useInboxes,
    useInboxUpdateQuery,
} from '../../queries/inboxes';
import useOnKeyDown from '../../hooks/useOnKeyDown';
import { useMeQueryData } from '../../queries/user';
import { renderInboxIcon } from '../../utils/inboxes';
import Icon from '../../icons/Icon';
import { useModalContext } from '../modal/reducer';
import { Typography } from '@mui/material';

type InboxSettingsProps = {
    inboxId: string;
};
enum Tabs {
    About = 'About',
}

export const InboxSettings = (props: InboxSettingsProps) => {
    /* Query Data */
    const me = useMeQueryData();
    const { data: inboxes = [] } = useInboxes();
    const inbox = inboxes.find((i) => i.id === props.inboxId);

    if (!inbox) {
        route('/inbox');
        return <></>;
    }

    /* State */
    const [tab, setTab] = useState<Tabs>(Tabs.About);
    const [editName, setEditName] = useState<boolean>(false);
    const [editDescription, setEditDescription] = useState<boolean>(false);
    const [currentName, setCurrentName] = useState<string>(inbox.name || '');
    const [currentDescription, setCurrentDescription] = useState<string>(
        inbox.description,
    );
    const [deleteInbox, setDeleteInbox] = useState(false);

    const { dispatch } = useModalContext();

    /* Mutations */
    const { mutateAsync: updateInbox } = useInboxUpdateQuery();
    const { mutateAsync: removeInbox } = useDeleteInbox();

    /* Refs */
    const nameInput = useRef<HTMLInputElement>(null);
    const descriptionInput = useRef<HTMLTextAreaElement>(null);

    /* Effects */
    useEffect(() => {
        return () => {
            if (
                currentName != inbox.name ||
                currentDescription != inbox.description
            ) {
                updateInbox({
                    ...inbox,
                    name: currentName,
                    description: currentDescription,
                });
            }
        };
    }, [currentName, currentDescription]);

    useEffect(() => {
        if (editName && nameInput.current) {
            nameInput.current.focus();
        }
    }, [editName]);

    useEffect(() => {
        if (editDescription && descriptionInput.current) {
            descriptionInput.current.focus();
        }
    }, [editDescription]);

    useOnKeyDown(nameInput, 'Enter', () => {
        if (currentName?.length > 0) {
            updateInbox({ ...inbox, name: currentName });
            setEditName(false);
        }
    });

    useOnKeyDown(nameInput, 'Escape', () => {
        setCurrentName(inbox.name || '');
        setEditName(false);
    });

    useOnKeyDown(descriptionInput, 'Enter', () => {
        if (currentDescription?.length > 0) {
            updateInbox({ ...inbox, description: currentDescription });
            setEditDescription(false);
        }
    });

    useOnKeyDown(descriptionInput, 'Escape', () => {
        setCurrentDescription(inbox.description || '');
        setEditDescription(false);
    });

    const canEdit = me?.activeRole?.permissions.includes(
        ClerkPermissions.ModifyPrivateInbox,
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.header__inner}>
                    {/.*\/.*/.test(inbox.icon) ? (
                        <img
                            className={styles.icon}
                            src={inbox.icon || phoneIcon}
                        />
                    ) : (
                        renderInboxIcon(inbox.icon)
                    )}

                    <h2>{currentName}</h2>
                </div>
                <div className={styles.actions}>
                    <Button
                        type="default"
                        slim={true}
                        icon={inbox.public ? <FiUnlock /> : <FiLock />}
                        className={styles.header__button}
                        onClick={() =>
                            updateInbox({ ...inbox, public: !inbox.public })
                        }
                        disabled={!canEdit}
                    >
                        {inbox.public ? 'Public' : 'Private'}
                    </Button>
                </div>
                <div className={styles.tabs}>
                    {Object.keys(Tabs).map((t) => (
                        <Button
                            key={t}
                            type="default"
                            onClick={() => setTab(t as Tabs)}
                            className={cc([
                                styles.tab,
                                tab === t && styles.tab__active,
                            ])}
                        >
                            {t}
                        </Button>
                    ))}
                </div>
            </div>
            <div className={styles.sections}>
                <div className={styles.section}>
                    <div className={styles.section__inner}>
                        <WithPermission
                            requiredPermissions={[
                                ClerkPermissions.ModifyPrivateInbox,
                                ClerkPermissions.ModifyPublicInbox,
                            ]}
                        >
                            <Button
                                type="text"
                                className={styles.editButton}
                                onClick={() => setEditName(true)}
                            >
                                Edit
                            </Button>
                        </WithPermission>

                        <Typography
                            variant="body4"
                            color="custom.gray.super"
                            sx={{ mb: 1.5 }}
                        >
                            Inbox Name
                        </Typography>
                        <Typography
                            variant="body3"
                            component="p"
                            color="primary.dark"
                        >
                            {editName ? (
                                <input
                                    ref={nameInput}
                                    value={currentName}
                                    spellCheck={false}
                                    onChange={(e) =>
                                        setCurrentName(e.target.value)
                                    }
                                />
                            ) : (
                                currentName
                            )}
                        </Typography>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.section__inner}>
                        <Typography
                            variant="body4"
                            color="custom.gray.super"
                            sx={{ mb: 1.5 }}
                        >
                            Phone Number
                        </Typography>

                        <Typography
                            variant="body3"
                            component="p"
                            color="primary.dark"
                        >
                            {formatPhoneNumber(inbox.phone)}
                        </Typography>
                    </div>
                    <hr />
                    <div className={styles.section__inner}>
                        <Typography
                            variant="body4"
                            color="custom.gray.super"
                            sx={{ mb: 1.5 }}
                        >
                            Description
                        </Typography>
                        <WithPermission
                            requiredPermissions={[
                                ClerkPermissions.ModifyPrivateInbox,
                                ClerkPermissions.ModifyPublicInbox,
                            ]}
                        >
                            <Button
                                type="text"
                                className={styles.editButton}
                                onClick={() => setEditDescription(true)}
                            >
                                Edit
                            </Button>
                        </WithPermission>
                        <Typography
                            variant="body3"
                            component="p"
                            color="primary.dark"
                        >
                            {editDescription ? (
                                <textarea
                                    ref={descriptionInput}
                                    value={currentDescription}
                                    spellCheck={false}
                                    onChange={(e) =>
                                        setCurrentDescription(e.target.value)
                                    }
                                />
                            ) : (
                                currentDescription
                            )}
                        </Typography>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.section__inner}>
                        <button
                            className={styles['delete-btn']}
                            onClick={() => setDeleteInbox(true)}
                        >
                            <Icon
                                name="delete"
                                className={styles['delete-btn__icon']}
                            />
                            <span className={styles['delete-btn__text']}>
                                Delete this inbox
                            </span>
                        </button>
                    </div>
                </div>

                {deleteInbox && (
                    <div className={styles['delete-inbox']}>
                        <img
                            className={styles['delete-inbox__icon']}
                            src={redCrossSvg}
                        />
                        <h6 className={styles['delete-inbox__title']}>
                            Delete inbox?
                        </h6>
                        <p className={styles['delete-inbox__text']}>
                            All messages, conversations, and phone number will
                            be removed immediately.{' '}
                            <strong>This can NOT be undone.</strong>
                        </p>
                        <div className={styles['delete-inbox__buttons']}>
                            <Button
                                type="default"
                                onClick={() => setDeleteInbox(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    removeInbox({
                                        inboxId: props.inboxId,
                                    }).then(() => {
                                        dispatch({
                                            type: 'REMOVE',
                                            payload: {},
                                        });
                                        route('/');
                                    });
                                }}
                            >
                                <Icon
                                    className={styles['delete-inbox__del-icon']}
                                    name="delete"
                                />
                                <span
                                    className={styles['delete-inbox__del-text']}
                                >
                                    Delete inbox
                                </span>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
