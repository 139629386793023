import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const ArrowBackIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.94194 4.55806C8.18602 4.80214 8.18602 5.19786 7.94194 5.44194L4.00888 9.375H17.5C17.8452 9.375 18.125 9.65482 18.125 10C18.125 10.3452 17.8452 10.625 17.5 10.625H4.00888L7.94194 14.5581C8.18602 14.8021 8.18602 15.1979 7.94194 15.4419C7.69786 15.686 7.30214 15.686 7.05806 15.4419L2.05806 10.4419C1.81398 10.1979 1.81398 9.80214 2.05806 9.55806L7.05806 4.55806C7.30214 4.31398 7.69786 4.31398 7.94194 4.55806Z"
                fill="currentColor"
                fillOpacity="0.8"
            />
        </svg>
    </SvgIcon>
);
