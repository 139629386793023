import {
    Box,
    Divider,
    List,
    ListSubheader,
    SxProps,
    Theme,
} from '@mui/material';
import { ClerkPermissions } from '../../../api/types';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useMeQueryData } from '../../../queries/user';
import { SubscribersOnly } from '../../SubscribersOnly/SubscribersOnly';
import { useKnowledgeBaseEnabled } from '../../../helpers/use-knowledge-base-enabled';
import Icon from '../../../icons/Icon';
import { BrandRegistrationIcon } from './icons/BrandRegistrationIcon';
import { UserIcon } from './icons/UserIcon';
import { IntegrationsIcon } from './icons/IntegrationsIcon';
import { LogsIcon } from './icons/LogsIcon';
import { BlacklistIcon } from './icons/BlacklistIcon';
import { ApiKeyIcon } from './icons/ApiKeyIcon';
import { NotificationsIcon } from './icons/NotificationsIcon';
import { KnowledgeBaseIcon } from './icons/KnowledgeBaseIcon';
import NavigationItem from '../../../elements/Navigation';
import { WidgetIcon } from './icons/WidgetIcon';

const navStyle: SxProps<Theme> = {
    width: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
};

export const Navigation = ({ onClick }: { onClick: () => void }) => {
    const me = useMeQueryData();
    const isKnowledgeBaseEnabled = useKnowledgeBaseEnabled();

    return (
        <Box component="nav" sx={navStyle}>
            <List subheader={<ListSubheader>Account</ListSubheader>}>
                <NavigationItem
                    onClick={onClick}
                    href="/settings/profile"
                    label="Profile"
                    icon={<UserIcon />}
                />
            </List>
            <Divider />

            <List subheader={<ListSubheader>Workspace</ListSubheader>}>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/team"
                        label="Team info"
                        icon={<Icon name="company" />}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <SubscribersOnly>
                        <NavigationItem
                            onClick={onClick}
                            href="/settings/compliance"
                            label="Brand Registration"
                            icon={<BrandRegistrationIcon />}
                        />
                    </SubscribersOnly>
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/members"
                        label="Members"
                        icon={<Icon name="members" />}
                    />
                </WithPermission>

                <WithPermission
                    requiredPermissions={
                        me?.activeTeam.integrationsHiddenByDefault
                            ? [ClerkPermissions.ModifyTeam]
                            : [ClerkPermissions.ViewPublicInbox]
                    }
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/integrations"
                        label="Integrations"
                        icon={<IntegrationsIcon />}
                    />
                </WithPermission>
                <NavigationItem
                    onClick={onClick}
                    href="/settings/notifications"
                    label="Notifications"
                    icon={<NotificationsIcon />}
                />
                {isKnowledgeBaseEnabled && (
                    <WithPermission
                        requiredPermissions={[ClerkPermissions.ModifyTeam]}
                    >
                        <NavigationItem
                            onClick={onClick}
                            href="/settings/knowledge-base"
                            label="Knowledge Base"
                            icon={<KnowledgeBaseIcon />}
                        />
                    </WithPermission>
                )}
            </List>
            <Divider />

            <List subheader={<ListSubheader>Data</ListSubheader>}>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ExportInboxLogs]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/export"
                        label="Logs"
                        icon={<LogsIcon />}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/profanity"
                        label="Blacklist"
                        icon={<BlacklistIcon />}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/billing"
                        label="Billing"
                        icon={<Icon name="custom-wallet" />}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/api"
                        label="API"
                        icon={<ApiKeyIcon />}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermissions.ModifyTeam]}
                >
                    <NavigationItem
                        onClick={onClick}
                        href="/settings/widget"
                        label="Clerk Widget"
                        icon={<WidgetIcon />}
                    />
                </WithPermission>
            </List>
            <Divider />
        </Box>
    );
};
