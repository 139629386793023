import styles from './NewConversationControls.module.scss';
import { useNewConversationContext } from '../NewConversationProvider';
import Icon from '../../../icons/Icon';
import ToggleSwitch from '../../ToggleSwitch';
import { Typography } from '@mui/material';

export const NewConversationControls = () => {
    const { state, actions } = useNewConversationContext();

    return (
        <div className={styles['root']}>
            <div className={styles['root__campaign']}>
                <Icon
                    className={styles['root__campaign-icon']}
                    size="20px"
                    name="send"
                />
                <Typography variant="body4" mr={1}>
                    Send a campaign
                </Typography>
                <ToggleSwitch
                    className={styles['root__campaign-toggle']}
                    isChecked={
                        state.mode === 'campaign' ||
                        state.selectedContactIds.length > 10
                    }
                    disabled={state.selectedContactIds.length > 10}
                    onToggle={actions.toggleMode}
                />
            </div>
        </div>
    );
};
