import IconBase, { IconBaseProps } from '../IconBase';

export const MailFilledIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M13.6715 13.1792C13.1739 13.5479 12.5959 13.7429 12 13.7429C11.4042 13.7429 10.8262 13.5479 10.3286 13.1792L2.13316 8.10501C2.0877 8.07131 2.0434 8.03617 2 8.00004V17.9532C2 19.0943 2.83301 20 3.84114 20H20.1589C21.1854 20 22 19.0739 22 17.9532V8C21.9565 8.03622 21.9121 8.07144 21.8666 8.10518L13.6715 13.1792Z"
            fill="currentColor"
        />
        <path
            d="M2.7832 7.27583L10.9786 12.1719C11.2889 12.377 11.6444 12.4795 12 12.4795C12.3555 12.4795 12.7111 12.377 13.0214 12.1719L21.2168 7.27583C21.7072 6.95189 22 6.40974 22 5.82461C22 4.81849 21.1743 4 20.1595 4H3.84051C2.82566 4.00004 2 4.81853 2 5.82557C2 6.40974 2.29281 6.95189 2.7832 7.27583Z"
            fill="currentColor"
        />
    </IconBase>
);
