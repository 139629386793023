import styles from './NoResults.module.scss';
import Icon from '../../../icons/Icon';

type NoResultsProps = {
    title: string;
    text: string;
};

export const NoResults = (props: NoResultsProps) => (
    <div className={styles['root']}>
        <Icon
            className={styles['root__icon']}
            name="empty-search-filled"
            size="64px"
        />
        <h6 className={styles['root__heading']}>{props.title}</h6>
        <p className={styles['root__description']}>{props.text}</p>
    </div>
);
