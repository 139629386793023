import { Box } from '@mui/material';
import { JSX } from 'preact';
import { highlight } from './styles';

type Props = { message: string; substring: string };

export const HighlightSubstring = ({ message, substring }: Props) => {
    if (!substring) {
        return <>{message}</>;
    }
    const parts = message.split(substring);
    if (parts.length === 1) {
        return <>{message}</>;
    }

    const highlighted = parts.reduce((acc: JSX.Element[], part, index) => {
        if ((index + 1) % 2) {
            return acc.concat(<span key={index}>{part}</span>);
        }
        return acc.concat(
            <Box component="span" sx={highlight} key={`s_${index}`}>
                {substring}
            </Box>,
            <span key={index}>{part}</span>,
        );
    }, []);

    return <>{highlighted}</>;
};
