import { Brand, BrandRegistrationStatus } from '../../../../api/types';
import { Alert, AlertTitle } from '@mui/material';
import { useMemo } from 'preact/hooks';
import { EmojiIcon } from './EmojiIcon';
import { Description } from './Description';

interface CompanyVerificationInfoProps {
    brand?: Pick<Brand, 'status' | 'registrationErrors'>;
}

export const CompanyVerificationInfo = ({
    brand: { status, registrationErrors } = {
        status: BrandRegistrationStatus.PENDING,
        registrationErrors: [],
    },
}: CompanyVerificationInfoProps) => {
    const severity = useMemo(
        () =>
            status === BrandRegistrationStatus.FAILED ||
            BrandRegistrationStatus.PENDING ||
            !status
                ? 'warning'
                : 'success',
        [status],
    );

    const title = useMemo(
        () =>
            status
                ? 'Company verification process'
                : '10DLC / Local A2P Messaging',
        [status],
    );

    return (
        <Alert
            fullWidth
            severity={severity}
            icon={<EmojiIcon sx={{ width: 20, height: 20 }} />}
        >
            <AlertTitle>{title}</AlertTitle>

            <Description
                status={status}
                registrationErrors={registrationErrors}
            />
        </Alert>
    );
};
