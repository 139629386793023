import { DispatchFn, InboxReducerActionType } from './reducer';
import { Inbox } from '../../api/types';
import { UUID } from '../../types/uuid';

export const getActionCreators = (dispatch: DispatchFn) => ({
    setActiveInbox: (inbox: Inbox) =>
        dispatch({
            type: InboxReducerActionType.SET_ACTIVE_INBOX,
            payload: inbox,
        }),
    addHasDraftMessage: (conversationId: UUID) =>
        dispatch({
            type: InboxReducerActionType.ADD_HAS_DRAFT_MESSAGE,
            payload: conversationId,
        }),
    removeHasDraftMessage: (conversationId: UUID) =>
        dispatch({
            type: InboxReducerActionType.REMOVE_HAS_DRAFT_MESSAGE,
            payload: conversationId,
        }),
});

export type ActionCreators = ReturnType<typeof getActionCreators>;
