import { forwardRef } from 'preact/compat';
import styles from './ActionButton.module.scss';
import Icon, { IconProps } from '../../icons/Icon';
import { Tooltip } from '@mui/material';

interface ActionButtonProps {
    tooltipText: string;
    iconName: IconProps['name'];
    disabled?: boolean;
    onClick?: () => void;
}

export const ActionButton = forwardRef<HTMLDivElement, ActionButtonProps>(
    (props, ref) => (
        <div className={styles['root']} ref={ref}>
            <Tooltip title={props.tooltipText} placement="top" arrow>
                <span>
                    <button
                        className={styles['root__btn']}
                        onClick={props.onClick}
                        disabled={props.disabled}
                    >
                        <Icon name={props.iconName} />
                    </button>
                </span>
            </Tooltip>
        </div>
    ),
);
