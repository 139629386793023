/*
 * To implement this component the article below was used:
 * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */

import cc from 'classcat';
import { forwardRef } from 'preact/compat';
import styles from './TextareaAutosize.module.scss';

interface TextareaAutosizeProps {
    value?: string;
    wrapperClassName?: string;
    textareaClassName?: string;
    onChange?: (value: string) => void;
    disabled: boolean;
}

export const TextareaAutosize = forwardRef<
    HTMLTextAreaElement,
    TextareaAutosizeProps
>(
    (
        {
            disabled,
            value = ' ',
            wrapperClassName,
            textareaClassName,
            onChange,
        },
        ref,
    ) => (
        <div
            className={cc([styles['textarea-wrap'], wrapperClassName])}
            data-replicated-value={value}
        >
            <textarea
                ref={ref}
                className={cc([styles['textarea'], textareaClassName])}
                onChange={(e) => onChange?.(e.currentTarget.value)}
                disabled={disabled}
                rows={1}
            >
                {value}
            </textarea>
        </div>
    ),
);
