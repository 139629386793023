/* eslint-disable no-empty */
export function get(key: string) {
    try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : data;
    } catch (e) {}
}

export function set(key: string, value: unknown) {
    try {
        const data = JSON.stringify(value);
        localStorage.setItem(key, data);
    } catch (e) {}
}

export function clear(key: string) {
    try {
        localStorage.removeItem(key);
    } catch (e) {}
}
export function parseJwt(token: string) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}
