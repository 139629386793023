import {
    createContext,
    createPortal,
    useContext,
    useReducer,
} from 'preact/compat';
import Toast from './index';
import { ComponentChildren } from 'preact';

export type ToastModal = {
    id: number;
    content?: (() => JSX.Element) | { message: string };
};

export type ToastModalState = ToastModal[];

const initialState: Array<ToastModal> = [];

export enum ToastActions {
    ADD,
    REMOVE,
    REMOVE_ALL,
}

export type ToastDispatch = {
    type: ToastActions;
    payload: {
        id?: number;
        content?: (() => JSX.Element) | { message: string };
    };
};

export const toastReducer = (
    state: Array<ToastModal>,
    action: ToastDispatch,
) => {
    switch (action.type) {
        case ToastActions.ADD:
            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload.content,
                },
            ];
        case ToastActions.REMOVE:
            return state.filter((t) => t.id !== action.payload.id);
        case ToastActions.REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

type ToastContextType = {
    state: ToastModal[];
    dispatch: (action: ToastDispatch) => void;
};
const ToastContext = createContext<ToastContextType>({
    state: initialState,
    dispatch: (_action) => null,
});
export const ToastProvider = (props: { children: ComponentChildren }) => {
    const [state, dispatch] = useReducer(toastReducer, initialState);
    return (
        <ToastContext.Provider value={{ state, dispatch }}>
            {props.children}

            {createPortal(<Toast toast={state} />, document.body)}
        </ToastContext.Provider>
    );
};

export const useToastContext = () => {
    return useContext(ToastContext);
};
