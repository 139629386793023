import FormButton from '../../../elements/FormButton';
import Icon from '../../../icons/Icon';
import StepShell from '../StepShell';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';

export const SuccessStep = (props: MSTeamsPhoneNumbersProps) => {
    return (
        <StepShell
            title="Activating SMS"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle={`${props.selectedPhones?.length} SMS numbers are being activated`}
        >
            <p>Your SMS numbers are being activated!</p>
            <FormButton
                onClick={props.goForward}
                text="Finish"
                variant="primary"
            />
        </StepShell>
    );
};
