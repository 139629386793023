import { ActionCreators } from '../../contexts/InboxContext/actions';
import { UUID } from '../../types/uuid';
import { Descendant } from 'slate';
import { createInitialValue } from '../MessageInput/utils';
import { JSONSerializer } from '../MessageInput/serializers/json';

export const TYPING_EMIT_DELAY = 1000;
export const DRAFT_SAVE_DELAY = 500;

export const saveMessageDraft = ({
    message,
    actions,
    conversationId,
}: {
    conversationId: UUID;
    message?: Descendant[];
    actions?: ActionCreators;
}) => {
    if (message) {
        actions?.addHasDraftMessage(conversationId);
        window.localStorage.setItem(
            `${conversationId}_draft`,
            JSONSerializer.serialize(message),
        );
    } else {
        actions?.removeHasDraftMessage(conversationId);
        window.localStorage.removeItem(`${conversationId}_draft`);
    }
};

export const getMessageDraft = (
    conversationId?: UUID,
    actions?: ActionCreators,
) => {
    const defaultValue = createInitialValue();
    try {
        if (conversationId) {
            const draft = window.localStorage.getItem(
                `${conversationId}_draft`,
            );
            if (draft) {
                actions?.addHasDraftMessage(conversationId);
            }
            return draft ? JSONSerializer.deserialize(draft) : defaultValue;
        }
    } catch (e) {
        console.error(e);
    }
    return defaultValue;
};
