import styles from './ContactPanel.module.scss';
import Icon from '../../icons/Icon';
import { IconButton } from '@mui/material';

interface ContactPanelHeaderProps {
    onBack?: () => void;
}

export const ContactPanelHeader = (props: ContactPanelHeaderProps) => (
    <div className={styles['root__header']}>
        <span>Contact Information</span>
        <IconButton onClick={props.onBack}>
            <Icon name="close" />
        </IconButton>
    </div>
);
