import cc from 'classcat';
import styles from './CloseButton.module.scss';
import Icon from '../../../icons/Icon';

export interface CloseButtonProps {
    outlined?: boolean;
    onClick?: () => void;
}

export const CloseButton = (props: CloseButtonProps) => {
    return (
        <button
            className={cc([
                styles['root'],
                {
                    [styles['root_outlined']]: props.outlined,
                },
            ])}
            onClick={props.onClick}
        >
            <Icon className={styles['root__icon']} size="18px" name="close" />
        </button>
    );
};
