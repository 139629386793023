import CollapsibleSection from '../../../components/CollapsibleSection';
import CampaignItem from '../../../elements/CampaignItem';
import { useCampaignsQuery } from '../../../queries/campaigns';

interface SideBarCampaignsProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarCampaigns = ({ setMenuOpen }: SideBarCampaignsProps) => {
    const campaigns = useCampaignsQuery();

    if (campaigns.length > 0) {
        return (
            <CollapsibleSection title="Campaigns" initiallyExpanded>
                {campaigns.map((campaign) => (
                    <CampaignItem
                        key={campaign.id}
                        campaign={campaign}
                        onClick={() => setMenuOpen(false)}
                    />
                ))}
            </CollapsibleSection>
        );
    }

    return <></>;
};
