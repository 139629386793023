import { useEffect, useState } from 'preact/hooks';

export const useNow = (settings: { interval?: number; disabled?: boolean }) => {
    const [now, setNow] = useState(new Date());
    const [intervalId, setIntervalId] = useState<number | undefined>();

    useEffect(() => {
        if (intervalId) {
            window.clearInterval(intervalId);
            setIntervalId(undefined);
        }

        if (settings.disabled) {
            return;
        }

        const id = window.setInterval(() => {
            setNow(new Date());
        }, settings.interval || 60_000);

        setIntervalId(id);

        return () => window.clearInterval(intervalId);
    }, [settings.interval, settings.disabled]);

    return now;
};
