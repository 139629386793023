import {
    InputAdornment,
    inputBaseClasses,
    SxProps,
    TextField,
    TextFieldProps,
    Theme,
} from '@mui/material';
import { SearchIcon } from './SearchIcon';

const input: SxProps<Theme> = {
    width: 256,
    [`& .${inputBaseClasses.input}`]: {
        padding: (theme) => theme.spacing(1.5, 3, 1.5, 0),
    },
};

export function SearchTextField(props: TextFieldProps) {
    return (
        <TextField
            {...props}
            name="search"
            placeholder={props.placeholder ?? 'Search'}
            sx={input}
            InputProps={{
                ...props.InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}
