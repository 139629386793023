import styles from './PhoneVerification.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormButton from '../../elements/FormButton';
import Spacer from '../../components/Spacer';
import { useBrandQuery } from '../../queries/settings';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { PhoneVerificationProps } from './PhoneVerification';
import {
    useCheckHostabilityQueryData,
    useHostInboxMutation,
    useInboxQueryData,
    usePortInboxMutation,
} from '../../queries/inboxes';
import RectangleFrame from '../../components/RectangleFrame';
import Icon from '../../icons/Icon';
import dayjs from 'dayjs';
import CheckBox from '../../elements/CheckBox/CheckBox';
import { HostDisclaimer, PortDisclaimer } from './helpers';
import { InboxProvider } from '../../api/types';
import { AnalyticsActions, useAnalytics } from '../../contexts/analytics';
import { AnalyticsEventName } from '../../types/AnalyticsEventNames';

export interface SignatureVerificationStepFormData {
    hasTeamsLicense?: boolean;
    hasRemovedTeamsCallingPlan?: boolean;
    hasAgreedToTransferPolicy?: boolean;
    hasBandwidthOC?: boolean;
}

export const SignatureVerificationStep = (props: PhoneVerificationProps) => {
    const { control, handleSubmit } =
        useForm<SignatureVerificationStepFormData>();

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const brand = useBrandQuery();
    const inbox = useInboxQueryData(props.inboxId);
    const hostCheck = useCheckHostabilityQueryData(inbox!.phone);
    const analytics = useAnalytics();

    const { mutateAsync: hostNumber } = useHostInboxMutation(props.inboxId);
    const { mutateAsync: portNumber } = usePortInboxMutation(props.inboxId);

    const onSubmit: SubmitHandler<
        SignatureVerificationStepFormData
    > = async () => {
        try {
            setLoading(true);
            hostCheck?.hostable ? await hostNumber() : await portNumber();

            analytics.dispatch({
                type: AnalyticsActions.TRACK,
                payload: {
                    eventName: AnalyticsEventName.VERIFICATION_PHONE_SIGNATURE,
                },
            });

            setTimeout(() => {
                route(`/inbox/${props.inboxId}`);
                setLoading(false);
            }, 5000);
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={styles['form-row']}>
                {hostCheck ? <HostDisclaimer /> : <PortDisclaimer />}
            </div>
            {inbox?.provider === InboxProvider.MSTEAMS &&
                !hostCheck?.hostable && (
                    <>
                        <div className={styles['form-row']}>
                            <Spacer height="auto" width="100%">
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasBandwidthOC"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <CheckBox
                                                checked={field.value}
                                                size="large"
                                                label={
                                                    <span>
                                                        I&apos;ve activated
                                                        Bandwidth via{' '}
                                                        <a
                                                            href="https://admin.teams.microsoft.com/operators/6e0bc3a3-43f7-4374-80ee-b2ae0af3c0d5/edit/1591fe31-8acc-4ce1-aaff-09aca16aac95"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Microsoft Operator
                                                            Connect
                                                        </a>{' '}
                                                    </span>
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Spacer>
                        </div>
                        {inbox.locationType != 'IVR' && (
                            <div className={styles['form-row']}>
                                <Spacer height="auto" width="100%">
                                    <RectangleFrame variant="light">
                                        <Controller
                                            name="hasTeamsLicense"
                                            control={control}
                                            defaultValue={false}
                                            rules={{
                                                required: true,
                                                validate: (v) => v,
                                            }}
                                            render={({ field }) => (
                                                <CheckBox
                                                    checked={field.value}
                                                    size="large"
                                                    label="I have a Teams Phone Standard license or E5 license"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </RectangleFrame>
                                </Spacer>
                            </div>
                        )}
                        <div className={styles['form-row']}>
                            <Spacer height="auto" width="100%">
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasRemovedTeamsCallingPlan"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <CheckBox
                                                checked={field.value}
                                                size="large"
                                                label={
                                                    <span>
                                                        I have removed the Teams
                                                        calling plan. Questions?
                                                        Get help{' '}
                                                        <a
                                                            href="https://support.clerk.chat/microsoft-teams"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            here
                                                        </a>
                                                    </span>
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Spacer>
                        </div>
                    </>
                )}
            <div className={styles['form-row']}>
                <Spacer height="auto" width="100%">
                    <RectangleFrame variant="light">
                        <Controller
                            name="hasAgreedToTransferPolicy"
                            control={control}
                            defaultValue={false}
                            rules={{ required: true, validate: (v) => v }}
                            render={({ field }) => (
                                <CheckBox
                                    checked={field.value}
                                    size="large"
                                    label={
                                        <span>
                                            I agree to the{' '}
                                            <a
                                                href="https://clerk.chat"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                terms and conditions
                                            </a>
                                        </span>
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </RectangleFrame>
                </Spacer>
            </div>
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="user-signature" size="16px" />{' '}
                                Authorized Person
                            </div>
                            <span>
                                {`${brand.data?.contactFirstName} ${brand.data?.contactLastName}`}
                            </span>
                        </div>
                    </RectangleFrame>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="calendar" size="16px" /> Date
                            </div>
                            <span>{dayjs().format('MMMM D, YYYY')}</span>
                        </div>
                    </RectangleFrame>
                </Spacer>
            </div>
            {isError && (
                <div className={styles['form-row']}>
                    Sorry, something went astray with your request. Please
                    contact support
                </div>
            )}
            <Spacer height={0} width="auto" />
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <Spacer height="auto" width="96px">
                        <FormButton
                            variant="clear"
                            text="Back"
                            type="button"
                            isLoading={isLoading}
                            onClick={() => history.back()}
                            disabled={isLoading}
                        />
                    </Spacer>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <FormButton
                        variant="primary"
                        text="Complete the verification"
                        isLoading={isLoading}
                        disabled={isLoading}
                    />
                </Spacer>
            </div>
        </form>
    );
};
