import * as storage from '../utils/local-storage';
import axios, { Options } from 'redaxios';

export const createClient = () => {
    const options: Options = {
        baseURL: String(import.meta.env.VITE_API_BASE_URL),
    };

    const token = storage.get('auth');

    if (token) {
        options.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return axios.create(options);
};

export default createClient;
