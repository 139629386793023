import { TargetedEvent } from 'preact/compat';
import cc from 'classcat';
import { PublicFile } from '../../api/types';
import styles from './MessageFormFilesList.module.scss';
import Icon from '../../icons/Icon';

const calcFileUploabBackground = (progress: number) => {
    return `radial-gradient(closest-side, white 69%, transparent 70% 100%),
    conic-gradient(#3f5df4 ${progress}%, white 0)`;
};

export type MessageFormFilesListProps = {
    files: PublicFile[];
    uploadingFile: File | null;
    uploadingProgress: number;
    uploadingError: boolean;
    uploadingRetry: () => void;
    onRemoveFile: (file: PublicFile) => void;
};

export const MessageFormFilesList = (props: MessageFormFilesListProps) => (
    <ul className={styles['root']}>
        {props.files.map((file) => (
            <li key={file.id} className={styles['root__file']}>
                <Icon
                    className={styles['root__file-icon']}
                    name="attached-file"
                />

                <span className={styles['root__name']}>{file.name}</span>

                <button
                    className={styles['root__remove-btn']}
                    onClick={(e: TargetedEvent) => {
                        e.stopPropagation();
                        props.onRemoveFile(file);
                    }}
                >
                    <Icon
                        className={styles['root__remove-icon']}
                        size="18px"
                        name="close"
                    />
                </button>
            </li>
        ))}
        {props.uploadingFile && (
            <li
                className={cc([
                    styles['root__file'],
                    { [styles['root__file_error']]: props.uploadingError },
                ])}
            >
                <Icon
                    className={styles['root__file-icon']}
                    name="attached-file"
                />

                <span className={styles['root__name']}>
                    {props.uploadingFile.name}
                </span>

                {!props.uploadingError && (
                    <div
                        className={styles['root__progress']}
                        style={{
                            background: calcFileUploabBackground(
                                props.uploadingProgress,
                            ),
                        }}
                    />
                )}

                {props.uploadingError && (
                    <button
                        className={styles['root__retry']}
                        onClick={props.uploadingRetry}
                    >
                        <Icon size="18px" name="refresh" />
                    </button>
                )}
            </li>
        )}
    </ul>
);
