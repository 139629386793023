import { Link as RouterLink } from 'preact-router';
import { ToastActions, ToastDispatch } from '../../containers/toast/reducer';
import { ErrorCodes } from '../../api/types';
import { Link } from '@mui/material';
import { AdapterLink } from '../ConversationsList/AdapterLink';

export const handleError =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dispatch?: (action: ToastDispatch) => void) => (error: any) => {
        const errorCode = error?.data?.code;
        let errorContent = <p>An unknown error occured. Please try again</p>;

        if (errorCode === ErrorCodes.MessageLimitReached) {
            errorContent = (
                <p>
                    You are out of messages. Please upgrade by clicking{' '}
                    <RouterLink href="/payment/plan">this link.</RouterLink>
                </p>
            );
        }

        if (errorCode === ErrorCodes.PaidTeamRequiredForCampaigns) {
            errorContent = (
                <p>
                    Upgrade now to send campaigns. Click{' '}
                    <RouterLink href="/payment/plan">here</RouterLink> to
                    upgrade.
                </p>
            );
        }

        if (errorCode === ErrorCodes.CampaignBrandRegistrationRequired) {
            errorContent = (
                <p>
                    Please register your{' '}
                    <RouterLink href="/settings/compliance">
                        company brand
                    </RouterLink>{' '}
                    to send a campaign
                </p>
            );
        }

        if (errorCode === ErrorCodes.ProfanityCheckFail) {
            errorContent = (
                <p>
                    The message you have sent contains words that are not
                    allowed by your admin
                </p>
            );
        }

        if (errorCode === 'MESSAGE_FAILED_TO_SEND') {
            errorContent = (
                <p>The Message has failed to send. Please Try again</p>
            );
        }

        if (
            error?.status === 422 &&
            error?.data?.message === 'body.profanity.detected'
        ) {
            errorContent = (
                <p>
                    The message you have sent contains{' '}
                    <Link component={AdapterLink} href="/settings/profanity">
                        words
                    </Link>{' '}
                    that are not allowed by your admin
                </p>
            );
        }

        dispatch?.({
            type: ToastActions.ADD,
            payload: {
                content: () => errorContent,
            },
        });

        throw new Error();
    };
