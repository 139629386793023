import { memo } from 'preact/compat';
import { IconButton, Tooltip } from '@mui/material';
import { CloseIcon } from '../../../../icons/shared/CloseIcon';

type Props = { handleCancel: () => void };

export const CancelButton = memo(({ handleCancel }: Props) => {
    return (
        <Tooltip arrow title="Cancel edit">
            <IconButton onClick={handleCancel}>
                <CloseIcon />
            </IconButton>
        </Tooltip>
    );
});
