import { ComponentChildren } from 'preact';
//styles
import styles from './Backdrop.module.scss';

interface BackdropProps {
    children?: ComponentChildren;
    onClick?: () => void;
}

export const Backdrop = ({ children, onClick }: BackdropProps) => {
    return (
        <div className={styles['backdrop']} onClick={onClick}>
            {children}
        </div>
    );
};
