import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import Mustache from 'mustache';
import { deleteMessage, getMessages, MessageToSend } from '../api/messages';
import { queryClient } from './queryClient';
import { Conversation, UnreadMessageRecord } from '../api/types';
import { ContactsCache } from '../utils/ContactsCache';
import { CONTACTS_KEY } from './contacts';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { useTrack } from '../contexts/analytics';
import { AnalyticsEventName } from '../types/AnalyticsEventNames';
import { CONVERSATIONS_LIST_KEY } from './conversations';
import client from '../api/http';

export const CONVERSATION_MESSAGES_KEY = 'conversation_messages_list';
export const UNREADS_KEY = 'unreads';

const getMessageText = (message: MessageToSend) => {
    if (message.to.length === 1) {
        const contacts =
            queryClient.getQueryData<ContactsCache>([CONTACTS_KEY]) ||
            new ContactsCache();

        const { name, email, phone, data } =
            contacts.getByPhone(message.to[0]) || {};

        return Mustache.render(message.body, { name, email, phone, ...data });
    }
    return message.body;
};

const useUnreadMessages = (
    select: (x: UnreadMessageRecord[]) => UnreadMessageRecord[],
) =>
    useQuery({
        queryKey: [UNREADS_KEY],
        staleTime: 200,
        gcTime: 5 * 60_000,
        queryFn: () =>
            client()
                .get('/messages/unreads')
                .then((res) => res.data),
        select,
    });

export const useUnreadMessagesForInbox = (inboxId: string) =>
    useUnreadMessages((messages = []) =>
        messages.filter((m) => m.inboxId === inboxId),
    );

export const useUnreadMessagesForConversation = (conversationId: string) => {
    return useUnreadMessages((messages = []) =>
        messages.filter((m) => m.conversationId === conversationId),
    );
};

export const useGetMessages = (conversationId: UUID) => {
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetched,
        isFetchingNextPage,
        isPending,
    } = useInfiniteQuery({
        queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
        queryFn: ({ queryKey: [_, id], pageParam }) =>
            getMessages(id, pageParam),
        initialPageParam: undefined,
        getNextPageParam: (lastPage) => {
            if (lastPage && lastPage[0]) {
                return new Date(lastPage[0].created).getTime();
            }
            return undefined;
        },
        select: (data) => {
            const newData = data.pages.reduce(
                (acc, msgs) => [...msgs, ...acc],
                [],
            );
            return newData;
        },
    });

    return {
        messages: data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
        isPending,
    };
};

export const useDeleteMessage = () => {
    const track = useTrack();

    return useMutation<
        unknown,
        unknown,
        { messageId: number; conversationId: string },
        { messageId: number; conversationId: string }
    >({
        mutationFn: (message) => deleteMessage(message),
        onMutate: async ({ messageId, conversationId }) => {
            await queryClient.cancelQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });

            return {
                conversationId,
                messageId,
            };
        },
        onSuccess: (_, { conversationId }) => {
            track(AnalyticsEventName.MESSAGE_DELETED);
            queryClient.refetchQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
        },
    });
};

export const clearAssistant = (conversationId: UUID, inboxId?: UUID): void => {
    queryClient.setQueriesData<PagedData<Conversation>>(
        {
            queryKey: inboxId
                ? [CONVERSATIONS_LIST_KEY, inboxId]
                : [CONVERSATIONS_LIST_KEY],
        },
        (prev) => {
            if (!prev?.pages) {
                return prev;
            }
            return {
                ...prev,
                pages: prev.pages.map((page) =>
                    page.map((conversation) => {
                        if (conversation.id === conversationId) {
                            return {
                                ...conversation,
                                assistantMessageId: null,
                                assistantTimestamp: null,
                            };
                        }
                        return conversation;
                    }),
                ),
            };
        },
    );
};
