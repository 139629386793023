import { useMemo } from 'react';
import partition from 'lodash/partition';
import { availableIntegrations } from './integrations-list';
import { useMeQueryData } from '../../../queries/user';
import { IntegrationSources } from '../../../api/types';
import { useListIntegrations } from '../../../queries/integrations';

export function useFilteredIntegrations(filter: string, searchTerm?: string) {
    const me = useMeQueryData();
    const { data: myIntegrations = [] } = useListIntegrations();

    return useMemo(() => {
        const whitelistedIntegrations = me?.activeTeam.whitelistedIntegrations;
        const integrationList = (
            whitelistedIntegrations
                ? availableIntegrations.filter((availableIntegration) =>
                      whitelistedIntegrations.includes(
                          availableIntegration.source as IntegrationSources,
                      ),
                  )
                : availableIntegrations
        )
            .filter((availableIntegration) => {
                if (filter === 'all') {
                    return true;
                }

                return availableIntegration.integrationType === filter;
            })
            .filter((availableIntegration) => {
                if (!searchTerm || !searchTerm.length) {
                    return true;
                }

                return availableIntegration.name
                    .toLocaleLowerCase()
                    .includes(searchTerm);
            });

        const [availableList, comingSoonList] = partition(integrationList, {
            comingSoon: false,
        });

        const [connectedList, notConnectedList] = partition(
            availableList,
            (availableIntegration) =>
                myIntegrations.find(
                    (integration) =>
                        integration.integrationSource ===
                            availableIntegration.source &&
                        integration.integrationType ===
                            availableIntegration.integrationType,
                ),
        );

        return {
            comingSoonIntegrations: comingSoonList,
            connectedIntegrations: connectedList,
            availableIntegrations: notConnectedList,
        };
    }, [
        me?.activeTeam.whitelistedIntegrations,
        myIntegrations,
        searchTerm,
        filter,
    ]);
}
