import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { useEffect, useState } from 'preact/hooks';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
import { getScheduleButtonLabel } from './utils';
import { PropsWithChildren } from 'preact/compat';
import { Field } from './DatepickerThemedField';

type Props = PropsWithChildren<{
    open: boolean;
    disabled?: boolean;
    onClose: () => void;
    minDate?: Date;
    timestamp: dayjs.ConfigType;
    onChange: (d: Date) => void;
    title?: string;
}>;

export const TimestampChangeDialog = ({
    open,
    disabled = false,
    onClose,
    minDate,
    children,
    onChange,
    title = 'Change timestamp',
    timestamp = new Date(),
}: Props) => {
    const [date, setDate] = useState(dayjs(timestamp));
    const [hour, setHour] = useState<number>(+date.format('hh'));
    const [minutes, setMinutes] = useState<number>(+date.format('mm'));

    useEffect(() => {
        if (hour >= 0 && hour <= 12) {
            setDate((prev) =>
                prev.hour(prev.format('A') === 'AM' ? hour : 12 + hour),
            );
        }
    }, [hour]);

    useEffect(() => {
        if (minutes >= 0 && minutes <= 59) {
            setDate((prev) => prev.minute(minutes));
        }
    }, [minutes]);

    const setIfAvailable = (maybeDate: Date | null | dayjs.Dayjs) => {
        if (maybeDate) {
            setDate(dayjs.isDayjs(maybeDate) ? maybeDate : dayjs(maybeDate));
        }
    };

    return (
        <Dialog open={open} id="ts-dialog-portal">
            <DialogAttentionIcon color="primary" />
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex" flexDirection="column">
                        <Box gap={4} display="flex" alignItems="flex-end">
                            <FormControl variant="outlined">
                                <Typography
                                    component="label"
                                    variant="body4"
                                    color="custom.gray.super"
                                    htmlFor="timestamp-input-value"
                                >
                                    Scheduled time
                                </Typography>
                                <DatePicker
                                    data-test="tcd-date-input"
                                    disabled={disabled}
                                    dateFormat="MM/dd/yyyy"
                                    selected={date.toDate()}
                                    minDate={minDate}
                                    customInput={<Field />}
                                    onChange={setIfAvailable}
                                    portalId="ts-dialog-portal"
                                />
                            </FormControl>
                            <Stack direction="row" gap={2} alignItems="center">
                                <TextField
                                    data-test="tcd-hour-input"
                                    value={hour}
                                    disabled={disabled}
                                    error={
                                        Number.isNaN(hour) ||
                                        hour > 12 ||
                                        hour < 0
                                    }
                                    InputProps={{
                                        required: true,
                                        maxLength: 2,
                                        onInput: ({ target }) => {
                                            /** preact hack! */
                                            const fresh =
                                                Number.isNaN(+target.value) ||
                                                target.value.length > 2
                                                    ? minutes
                                                    : +target.value;
                                            target.value = fresh;
                                            setHour(fresh);
                                        },
                                    }}
                                    sx={{ width: 48 }}
                                />
                                <div>:</div>
                                <TextField
                                    data-test="tcd-minute-input"
                                    value={minutes}
                                    disabled={disabled}
                                    error={
                                        Number.isNaN(minutes) ||
                                        minutes > 59 ||
                                        minutes < 0
                                    }
                                    InputProps={{
                                        required: true,
                                        maxLength: 2,
                                        onInput: ({ target }) => {
                                            /** preact hack! */
                                            const fresh =
                                                Number.isNaN(+target.value) ||
                                                target.value.length > 2
                                                    ? minutes
                                                    : +target.value;
                                            target.value = fresh;
                                            setMinutes(fresh);
                                        },
                                    }}
                                    sx={{ width: 48 }}
                                />
                                <Select
                                    data-test="tcd-ampm-select"
                                    sx={{ minWidth: 45 }}
                                    disabled={disabled}
                                    value={date.format('A')}
                                    onChange={(e) => {
                                        setDate((prev) =>
                                            prev.hour(
                                                e.target?.value === 'AM'
                                                    ? prev.hour() % 12
                                                    : (prev.hour() + 12) % 24,
                                            ),
                                        );
                                    }}
                                    aria-label="AM/PM"
                                >
                                    <MenuItem value="AM">AM</MenuItem>
                                    <MenuItem value="PM">PM</MenuItem>
                                </Select>
                            </Stack>
                        </Box>
                    </Box>
                </LocalizationProvider>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    data-test="tcd-dismiss"
                    disabled={disabled}
                    onClick={onClose}
                    variant="outlined"
                >
                    Dismiss
                </Button>
                <Button
                    data-test="tcd-submit"
                    disabled={
                        (minDate && date.isBefore(dayjs(minDate))) || disabled
                    }
                    onClick={() => onChange(date.toDate())}
                    sx={{ minWidth: 260 }}
                    color="primary"
                >
                    {getScheduleButtonLabel(date)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
