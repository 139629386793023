import { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { InfoIcon } from './icons/InfoIcon';
import { CopyIcon } from '../../../../icons/shared/CopyIcon';
import { DashIcon } from './icons/DashIcon';
import * as styles from './styles';

type Props = {
    value?: string;
    infoLabel: string;
    placeholder: string;
};

export function IdentifierInput({ value, infoLabel, placeholder }: Props) {
    const [copyTooltipText, setCopyTooltipText] = useState('Copy to clipboard');
    const handleCopy = () => {
        setCopyTooltipText('Copied!');
        navigator.clipboard.writeText(value ?? '');
        setTimeout(() => setCopyTooltipText('Copy to clipboard'), 1000);
    };

    return (
        <TextField
            disabled
            fullWidth
            sx={styles.textField}
            value={placeholder}
            InputProps={{
                startAdornment: (
                    <Tooltip title={infoLabel}>
                        <InputAdornment position="start">
                            <InfoIcon color="info" />
                        </InputAdornment>
                    </Tooltip>
                ),
                endAdornment: (
                    <InputAdornment
                        position="end"
                        sx={
                            value
                                ? styles.endAdornment
                                : styles.endAdornmentDash
                        }
                    >
                        {value ? (
                            <>
                                <Typography
                                    variant="body3"
                                    color="primary.dark"
                                >
                                    {value}
                                </Typography>
                                <Tooltip
                                    title={copyTooltipText}
                                    onClick={handleCopy}
                                >
                                    <IconButton sx={styles.copyButton}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <DashIcon />
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
}
