import { CompanyVerificationInfo } from './CompanyVerificationInfo/CompanyVerificationInfo';
import { useBrandQuery } from '../../../queries/settings';
import { BrandRegistrationStatus } from '../../../api/types';
import Loading from '../../../components/Loading/Loading';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { StatusBox } from './StatusBar/StatusBox';
import { Divider, Stack } from '@mui/material';
import { useMeQueryData } from '../../../queries/user';
import { IdentifierInput } from './IdentifierInput/IdentifierInput';
import { Form } from './Form/Form';

export const Compliance = () => {
    const { data, isFetching } = useBrandQuery();
    const currentUser = useMeQueryData();

    return (
        <SettingsLayout
            title="Brand registration"
            description={
                <>
                    We will register your corporate information for industry
                    messaging compliance.{' '}
                    <a
                        href="https://support.clerk.chat/compliance/10dlc"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Learn more
                    </a>
                    .
                </>
            }
        >
            <Stack spacing={10}>
                <Stack spacing={4}>
                    <StatusBox brand={data} />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <IdentifierInput
                            infoLabel="Uniquely identifies the brand or business entity that is responsible for the messaging campaigns."
                            placeholder="Brand ID"
                            value={data?.brandId}
                        />
                        <IdentifierInput
                            infoLabel="Helps carriers understand the nature of the messages being sent."
                            placeholder="Campaign ID"
                            value={currentUser?.activeTeam?.dlcCampaignId}
                        />
                    </Stack>
                </Stack>

                <Divider />

                {isFetching && <Loading />}

                {!isFetching && (
                    <>
                        {data?.status !== BrandRegistrationStatus.VERIFIED && (
                            <CompanyVerificationInfo brand={data} />
                        )}

                        <Form data={data} />
                    </>
                )}
            </Stack>
        </SettingsLayout>
    );
};
