import { useMemo } from 'preact/hooks';
import { UserTeamSettingType } from '../api/types';
import { useInboxes } from '../queries/inboxes';
import { useGetSetting, useMeQueryData } from '../queries/user';
import { UUID } from '../types/uuid';
import { sortInboxesByPredefinedOrder } from '../utils/inboxes';

const useUnmutedSortedInboxes = () => {
    const currentUser = useMeQueryData();
    const { data: inboxes = [], isPending: isInboxesLoading } = useInboxes();
    const { data: inboxesOrderSetting, isPending: isSettingsLoading } =
        useGetSetting(UserTeamSettingType.InboxesOrder);

    const order = (inboxesOrderSetting?.data?.ids || []) as UUID[];
    const mutedInboxesIds = currentUser?.mutedInboxIds || [];

    const unmutedSortedInboxes = useMemo(() => {
        const unmutedInboxes = inboxes.filter(
            (inbox) => !mutedInboxesIds.includes(inbox.id),
        );
        return sortInboxesByPredefinedOrder(unmutedInboxes, order);
    }, [inboxes, mutedInboxesIds, order]);

    return {
        inboxes,
        unmutedSortedInboxes,
        isPending: isInboxesLoading || isSettingsLoading,
    };
};

export default useUnmutedSortedInboxes;
