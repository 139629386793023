import { Inbox } from '../../api/types';
import { UUID } from '../../types/uuid';

export enum InboxReducerActionType {
    SET_ACTIVE_INBOX = 'SET_ACTIVE_INBOX',
    ADD_HAS_DRAFT_MESSAGE = 'ADD_HAS_DRAFT_MESSAGE',
    REMOVE_HAS_DRAFT_MESSAGE = 'REMOVE_HAS_DRAFT_MESSAGE',
}

export type InboxReducerActionPayload = Inbox | UUID;

export interface InboxReducerAction {
    type: InboxReducerActionType;
    payload?: InboxReducerActionPayload;
}

export interface InboxReducerState {
    inbox?: Inbox;
    hasDraftMessage: UUID[];
}

export const initialState: InboxReducerState = {
    hasDraftMessage: [],
};

export type DispatchFn = (action: InboxReducerAction) => void;

// REDUCER

export const reducer = (
    state: InboxReducerState,
    { type, payload }: InboxReducerAction,
): InboxReducerState => {
    switch (type) {
        case InboxReducerActionType.SET_ACTIVE_INBOX:
            return {
                ...initialState,
                inbox: payload as Inbox,
            };

        case InboxReducerActionType.ADD_HAS_DRAFT_MESSAGE:
            return {
                ...state,
                hasDraftMessage: state.hasDraftMessage.concat(payload as UUID),
            };

        case InboxReducerActionType.REMOVE_HAS_DRAFT_MESSAGE:
            return {
                ...state,
                hasDraftMessage: state.hasDraftMessage.filter(
                    (conversationId) => conversationId !== (payload as UUID),
                ),
            };
        default:
            return state;
    }
};
