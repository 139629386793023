import menuIcon from '../../assets/icons/navigation/menu.svg';
import closeIcon from '../../assets/icons/navigation/close.svg';
import searchIcon from '../../assets/icons/action/search.svg';
import styles from './SideBarHeader.module.scss';

interface SideBarHeaderProps {
    isOpen?: boolean;
    onSearchClick: () => void;
    onMenuToggle: () => void;
}

export const SideBarHeader = ({
    isOpen,
    onSearchClick,
    onMenuToggle,
}: SideBarHeaderProps) => (
    <header className={styles['sidebar-header']}>
        <div className={styles['sidebar-header__buttons']}>
            <button
                className={styles['sidebar-header__btn']}
                onClick={onSearchClick}
            >
                <img src={searchIcon} />
            </button>

            {isOpen ? (
                <button
                    className={styles['sidebar-header__btn']}
                    onClick={onMenuToggle}
                >
                    <img src={closeIcon} />
                </button>
            ) : (
                <button
                    className={styles['sidebar-header__btn']}
                    onClick={onMenuToggle}
                >
                    <img src={menuIcon} />
                </button>
            )}
        </div>
    </header>
);
