import { IconButton, Popper, Tooltip } from '@mui/material';
import { useState } from 'preact/hooks';
import { JSXInternal } from 'preact/src/jsx';
import { EmojiIcon } from './EmojiIcon';
import EmojiPicker from '../../../EmojiPicker';
import { insertText } from '../../utils';
import { memo } from 'preact/compat';
import { CustomEditor } from '../../slate';

export const EmojiButton = memo(
    ({
        disabled = false,
        editor,
    }: {
        disabled?: boolean;
        editor: CustomEditor;
    }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        return (
            <>
                <Tooltip title="Add Emoji">
                    <IconButton
                        disabled={disabled}
                        onClick={(
                            e: JSXInternal.TargetedMouseEvent<HTMLButtonElement>,
                        ) =>
                            setAnchorEl((ref) => (ref ? null : e.currentTarget))
                        }
                        ref={anchorEl}
                    >
                        <EmojiIcon />
                    </IconButton>
                </Tooltip>

                {!!anchorEl && (
                    <Popper
                        open
                        anchorEl={anchorEl}
                        placement="top-start"
                        disablePortal
                    >
                        <EmojiPicker
                            onClick={(emoji) => {
                                setAnchorEl(null);
                                insertText(editor, emoji);
                            }}
                            onClose={() => setAnchorEl(null)}
                        />
                    </Popper>
                )}
            </>
        );
    },
);
