import { useCallback, useState } from 'preact/hooks';
import { useMutation } from '@tanstack/react-query';
import { uploadFile } from '../api/files';
import { PublicFile } from '../api/types';

type SuccessHandler = (attachment: PublicFile) => void;

const useUploadFile = (handleSuccess: SuccessHandler) => {
    const [uploadingFile, setUploadingFile] = useState<File | null>(null);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [uploadingError, setUploadingError] = useState(false);

    const reset = useCallback(() => {
        setUploadingFile(null);
        setUploadingProgress(0);
        setUploadingError(false);
    }, []);

    const retry = useCallback(() => {
        setUploadingProgress(0);
        setUploadingError(false);

        if (uploadingFile) {
            upload({
                file: uploadingFile,
                onUploadProgress: setUploadingProgress,
            });
        }
    }, [uploadingFile]);

    const { mutate: upload } = useMutation({
        mutationFn: uploadFile,
        onSuccess: (data) => {
            reset();
            handleSuccess(data);
        },
        onError: () => {
            setUploadingProgress(0);
            setUploadingError(true);
        },
    });

    return {
        uploadingFile,
        uploadingProgress,
        uploadingError,
        uploadFile: (file: File) => {
            setUploadingFile(file);
            upload({
                file,
                onUploadProgress: setUploadingProgress,
            });
        },
        uploadingReset: reset,
        uploadingRetry: retry,
    };
};

export default useUploadFile;
