// components
import FilterMultiselectValue from './FilterMultiselectValue';
import FilterTextValue from './FilterTextValue';
import FilterNumberValue from './FilterNumberValue';
import FilterSelectValue from './FilterSelectValue';
import FilterDateValue from './FilterDateValue';
// types
import {
    ConstraintType,
    DateTypeValue,
    NumberTypeValue,
    TextTypeValue,
    UserPropertyFilterModel,
} from '../../UserInfoProperty/types';
import FilterPersonSelectValue from './FilterPersonSelectValue';
import { ContactPropertyType } from '../../../api/types';

const textTypeValueMap = {
    [TextTypeValue.Is]: 'Is',
    [TextTypeValue.IsNot]: 'Is Not',
    [TextTypeValue.Contains]: 'Contains',
    [TextTypeValue.DoesNotContain]: 'Does not contain',
    [TextTypeValue.Exists]: 'Exists',
};

const numberTypeValueMap = {
    [NumberTypeValue.Is]: 'Is',
    [NumberTypeValue.MoreThen]: 'More Then',
    [NumberTypeValue.LessThen]: 'Less Then',
};

const dateTypeValueMap = {
    [DateTypeValue.MoreThan]: 'More than',
    [DateTypeValue.Exactly]: 'Exactly',
    [DateTypeValue.LessThan]: 'Less than',
    [DateTypeValue.Period]: 'Period',
    [DateTypeValue.After]: 'After',
    [DateTypeValue.On]: 'On',
    [DateTypeValue.Before]: 'Before',
    [DateTypeValue.Exists]: 'Exists',
};

export const getFilter = (
    userPropertyFilter: UserPropertyFilterModel,
    changeHandler: (value: UserPropertyFilterModel) => void,
    closeHandler: () => void,
) => {
    const { type } = userPropertyFilter;
    const props = { userPropertyFilter, changeHandler, closeHandler };

    switch (type) {
        case ContactPropertyType.Select:
            return <FilterSelectValue {...props} />;

        case ContactPropertyType.MultiSelect:
            return <FilterMultiselectValue {...props} />;

        case ContactPropertyType.Person:
            return <FilterPersonSelectValue {...props} />;

        case ContactPropertyType.Number:
            return <FilterNumberValue {...props} />;

        case ContactPropertyType.Date:
        case ContactPropertyType.LastEditedTime:
        case ContactPropertyType.CreatedTime:
            return <FilterDateValue {...props} />;

        case ContactPropertyType.Email:
        case ContactPropertyType.Phone:
        case ContactPropertyType.Text:
        case ContactPropertyType.URL:
            return <FilterTextValue {...props} />;

        case ContactPropertyType.FilesAndMedia: {
            console.warn('ContactPropertyType fileter is not implemented yet');
            break;
        }
    }
    return null;
};

export const getFilterConstraintName = (
    propertyType: ContactPropertyType,
    constraintType?: ConstraintType,
) => {
    switch (propertyType) {
        case ContactPropertyType.Number:
            return numberTypeValueMap[constraintType as NumberTypeValue] || '';

        case ContactPropertyType.Date:
        case ContactPropertyType.LastEditedTime:
        case ContactPropertyType.CreatedTime:
            return dateTypeValueMap[constraintType as DateTypeValue] || '';

        case ContactPropertyType.Email:
        case ContactPropertyType.Phone:
        case ContactPropertyType.Text:
        case ContactPropertyType.URL:
            return textTypeValueMap[constraintType as TextTypeValue] || '';

        case ContactPropertyType.FilesAndMedia: {
            console.warn('ContactPropertyType fileter is not implemented yet');
            break;
        }
    }
    return '';
};
