import { Type } from '../source.types';
import Website from '../icons/CFileTypeLink.svg?react';
import { Box, SvgIcon } from '@mui/material';
import { base, icon } from './source-icon.styles';
import {
    CSVSourceIcon,
    PdfSourceIcon,
    TextSourceIcon,
    WordSourceIcon,
} from '../../../../icons/common/files/ExtensionIcon';

export const WebsiteIcon = () => (
    <Box sx={base}>
        <SvgIcon sx={icon} component={Website} inheritViewBox />
    </Box>
);

export const SourceIcon = ({ type }: { type: Type }) => {
    return {
        [Type.Url]: <WebsiteIcon />,
        [Type.Pdf]: <PdfSourceIcon />,
        [Type.Doc]: <WordSourceIcon />,
        [Type.Csv]: <CSVSourceIcon />,
        [Type.Raw]: <TextSourceIcon />,
    }[type];
};
