import { route } from 'preact-router';
//components
import { ConversationsList } from './ConversationsList';
import Loading from '../Loading/Loading';
//api
import { Cohort } from '../../api/types';
import { useGetCohortConversations } from '../../queries/conversations';
import phoneIcon from '../../assets/icons/icons-20/phone.svg';

interface CohortConversationsListProps {
    cohort?: Cohort;
}

export const CohortConversationsList = ({
    cohort,
}: CohortConversationsListProps) => {
    if (!cohort) {
        return <></>;
    }

    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    } = useGetCohortConversations(cohort.id);

    if (isPending) {
        return <Loading />;
    }

    return (
        <ConversationsList
            name={cohort.name}
            icon={<img src={cohort.icon || phoneIcon} />}
            conversations={conversations}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            getHref={(conversationId) =>
                `/cohorts/${cohort.id}/${conversationId}`
            }
            onTouchEnd={(conversationId) =>
                route(`/cohorts/${cohort.id}/${conversationId}`)
            }
        />
    );
};
