/* eslint-disable @typescript-eslint/no-explicit-any */
import client from './http';
import {
    FilterValue,
    UserPropertyModel,
} from '../components/UserInfoProperty/types';
import { ContactsCacheData } from '../utils/ContactsCache';
import { Contact, ContactPropertyType } from './types';

export type PropertyUpdateData = {
    id: string;
    params: Partial<UserPropertyModel>;
};

export type PropertyOptionUpdateDTO = {
    type: ContactPropertyType.Select | ContactPropertyType.MultiSelect;
    oldOption: string;
    newOption: string;
};

export type PropertyOptionRemoveDTO = {
    type: ContactPropertyType.Select | ContactPropertyType.MultiSelect;
    option: string;
};

export function getContacts(): Promise<ContactsCacheData> {
    return client()
        .get(`/contacts`)
        .then((res) => res.data);
}

export function getContact({ id }: { id: string }): Promise<Contact> {
    return client()
        .get(`/contacts/${id}`)
        .then((res) => res.data);
}

export function createContact(payload: Partial<Contact>): Promise<Contact> {
    return client()
        .post(`/contacts`, payload)
        .then((res) => res.data);
}

export function createOrUpdateContact({
    where,
    payload,
}: {
    where: { email?: string; phone?: string };
    payload: Partial<Contact>;
}): Promise<Contact> {
    return client()
        .put(`/contacts`, { payload, where })
        .then((res) => res.data);
}

export function searchContacts(
    queryTerm: string,
    filterValues: FilterValue[] = [],
    cohortId?: number,
    isWithPhone = false,
): Promise<Contact[]> {
    return client()
        .post('/search/contacts', {
            queryTerm,
            filterValues,
            cohortId,
            isWithPhone,
        })
        .then((res) => res.data);
}

export function updateContact(payload: Partial<Contact>): Promise<Contact> {
    return client()
        .put(`/contacts/${payload.id}`, payload)
        .then((res) => res.data);
}

export function deleteContacts({ ids }: { ids: string[] }) {
    return client().post(`/contacts/multi-delete`, {
        ids,
    });
}

// Attributes
export function listProperties(): Promise<UserPropertyModel[]> {
    return client()
        .get('/properties')
        .then((res) => res.data as UserPropertyModel[]);
}

export function getProperty(id: string): Promise<UserPropertyModel> {
    return client()
        .get(`/properties/${id}`)
        .then((res) => res.data);
}

export function createProperty(
    params: Partial<UserPropertyModel>,
): Promise<UserPropertyModel> {
    return client()
        .post(`/properties`, params)
        .then((res) => res.data);
}

export function updateProperty({
    id,
    params,
}: PropertyUpdateData): Promise<UserPropertyModel> {
    return client()
        .put(`/properties/${id}`, params)
        .then((res) => res.data);
}

export function deleteProperty(id: string) {
    return client().delete(`/properties/${id}`);
}

export function updatePropertyOption(
    propertyId: string,
    data: PropertyOptionUpdateDTO,
): Promise<UserPropertyModel> {
    return client()
        .put(`/properties/option/${propertyId}`, data)
        .then((res) => res.data);
}

export function deletePropertyOptions(
    propertyId: string,
    data: PropertyOptionRemoveDTO,
) {
    return client().delete(`/properties/option/${propertyId}`, { data });
}

export function importCSV(file: File, schema: any): Promise<Contact[]> {
    const schemaFile = new Blob([JSON.stringify(schema)], {
        type: 'application/json',
    });

    const formData = new FormData();
    formData.append('dataFiles', file);
    formData.append('schemaFiles', schemaFile);

    return client().post('/contacts-importer', formData);
}

export function exportContacts({ contactIds }: { contactIds: string[] }) {
    return client()
        .post(`/contacts/export`, { contactIds }, { responseType: 'blob' })
        .then(({ data }) => data);
}
