import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const DashIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 0.0880681V1.18182H0V0.0880681H14Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
