import cc from 'classcat';
import styles from './MessageRow.module.scss';
import {
    ClerkPermissions,
    Message,
    MessageStatus,
    SenderType,
} from '../../../api/types';
import { dateFormat } from '../../../helpers/formatting';
import AttachmentItem from '../../AttachmentItem';
import { clearAssistant, useDeleteMessage } from '../../../queries/messages';
import DiscussionBlock from './DiscussionBlock';
import { UUID } from '../../../types/uuid';
import { useCreateDiscussion } from '../../../queries/comments';
import Icon from '../../../icons/Icon';
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    Fade,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    container,
    getActionsList,
    getAvatarContainer,
    getBodyStyle,
    getMessageContainer,
    status,
} from './styles';
import { AssistantAvatar } from './AssistantAvatar';
import { memo, useMemo, useState } from 'preact/compat';
import { WithPermission } from '../../../containers/WithPermission/WithPermission';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { useTrack } from '../../../contexts/analytics';
import { GenericDialog } from '../../Modals/GenericDialog/GenericDialog';
import { Attachments } from '../../../main/campaign/v3/Attachment/Attachments';
import { DotDivider } from '../../ConversationRow/DotDivider';
import { isEmpty } from 'lodash';
import { ScheduleTime } from './ScheduleTime';
import { CopyIcon } from '../../../icons/shared/CopyIcon';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { SendFilledIcon } from '../../ConversationRow/SendFilledIcon';
import { useSendImmediately } from '../../ConversationRow/use-message.query';
import { checkIsUntilReview } from '../../ConversationRow/Assistant/check-is-until-review';
import { TimestampChangeDialog } from '../../Modals/TimestampChangeDialog/TimestampChangeDialog';
import dayjs from 'dayjs';
import { getNearestAlignedCurrentDate } from '../../Modals/TimestampChangeDialog/utils';
import {
    loadingSpinner,
    loadingSpinnerProgress,
} from '../../ScheduledMessageRow/styles';
import { useUpdateMessage } from '../../MessageFormContainers/message.query';

export interface MessageRowProps {
    conversationId: UUID;
    senderName: string;
    senderAvatar?: string;
    isGroupStart?: boolean;
    isGroupEnd?: boolean;
    setActiveDiscussion: (discussionId: number | null) => void;
    message: Message;
    onEditClick: (m: Message) => void;
}

const shortFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
};

const getSender = (message: Message, backwardCompatibility: string) => {
    if (message.senderType === SenderType.ASSISTANT) {
        return 'Clerk Assistant';
    }

    if (
        [SenderType.CAMPAIGN, SenderType.CAMPAIGN_MESSAGE].includes(
            message.senderType,
        )
    ) {
        return message.sentByName
            ? `Campaign: ${message.sentByName}`
            : 'Campaign';
    }

    if (SenderType.WORKFLOW === message.senderType) {
        return message.sentByName
            ? `Workflow: ${message.sentByName}`
            : 'Workflow';
    }

    return backwardCompatibility;
};

export const MessageRow = memo((props: MessageRowProps) => {
    const track = useTrack();
    const sendNow = useSendImmediately();
    const [deleteModal, setDeleteModal] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [isActionsShown, setIsActionsShown] = useState(false);
    const [deleteModalStatus, setDeleteModalStatus] = useState<string>('');
    const { mutateAsync: deleteMessage } = useDeleteMessage();
    const { mutateAsync: createDiscussion } = useCreateDiscussion(
        props.conversationId,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const update = useUpdateMessage();

    const showCommentButton = useMemo(
        () =>
            !props.message.discussion || props.message.discussion.replies === 0,
        [props.message.discussion],
    );

    const isScheduled = useMemo(
        () => props.message.status === MessageStatus.Scheduled,
        [props.message.status],
    );
    const isUntilReview = checkIsUntilReview(props.message);

    const send = () => {
        sendNow
            .mutateAsync({
                messageId: props.message.id,
                conversationId: props.message.conversationId,
            })
            .then(() => {
                track(AnalyticsEventName.ASSISTANT_MESSAGE_SENT_IMMEDIATELY, {
                    origin: 'scheduled_messages',
                });
                clearAssistant(props.message.conversationId);
            });
    };

    return (
        <div
            className={cc([
                styles['root'],
                {
                    [styles['root_inbound']]: props.message.inbound,
                    [styles['root_last-of-group']]: props.isGroupEnd,
                },
            ])}
        >
            {/* Main message content */}
            <Box sx={container}>
                {/* Header */}
                {props.isGroupStart && (
                    <div
                        className={cc([
                            styles['root__header'],
                            {
                                [styles['root__header_inbound']]:
                                    props.message.inbound,
                            },
                        ])}
                    >
                        <Typography
                            variant="body5"
                            color={({ palette: { secondary, primary } }) =>
                                props.message.senderType ===
                                SenderType.ASSISTANT
                                    ? secondary.main
                                    : primary.dark
                            }
                        >
                            {getSender(props.message, props.senderName)}
                        </Typography>
                        <DotDivider />
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                color: 'custom.gray.super',
                            }}
                            typography="body5"
                        >
                            {isScheduled ? (
                                <ScheduleTime message={props.message} />
                            ) : (
                                dateFormat(
                                    new Date(props.message.timestamp),
                                    shortFormatOptions,
                                )
                            )}
                        </Box>
                    </div>
                )}

                {/* Message text */}
                {(props.message.body || !isEmpty(props.message.attached)) && (
                    <Box
                        onMouseEnter={() => setIsActionsShown(true)}
                        onMouseLeave={() => setIsActionsShown(false)}
                        sx={getMessageContainer(props.message.inbound)}
                    >
                        <Box
                            sx={getBodyStyle(props.message)}
                            typography="body3"
                        >
                            {props.message.body}
                            <Attachments
                                sx={{ mt: props.message.body ? 4 : undefined }}
                                attachments={props.message.attached}
                            />
                        </Box>
                        <Fade in={isActionsShown} unmountOnExit timeout={50}>
                            <Box sx={getActionsList(props.message.inbound)}>
                                <Tooltip arrow title="Copy message">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            track(
                                                AnalyticsEventName.MESSAGE_COPIED,
                                            );
                                            navigator.clipboard.writeText(
                                                props?.message.body || '',
                                            );
                                        }}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>

                                {!isScheduled && showCommentButton && (
                                    <Tooltip arrow title="Show comments">
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                opacity: 0,
                                                ':hover': {
                                                    color: 'custom.primary.blue',
                                                },
                                            }}
                                            onClick={() => {
                                                if (props.message.discussion) {
                                                    props.setActiveDiscussion(
                                                        props.message.discussion
                                                            .id,
                                                    );
                                                } else {
                                                    createDiscussion({
                                                        messageId:
                                                            props.message.id,
                                                    })
                                                        .then(
                                                            (
                                                                createdDiscussion,
                                                            ) => {
                                                                props.setActiveDiscussion(
                                                                    createdDiscussion.id,
                                                                );
                                                            },
                                                        )
                                                        .catch(console.log);
                                                }
                                            }}
                                        >
                                            <Icon name="comment" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isScheduled && !isUntilReview && (
                                    <>
                                        <Tooltip
                                            title="Change delivery date"
                                            arrow
                                        >
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    setShowSchedule(true)
                                                }
                                            >
                                                <Icon name="mess-schedule" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow title="Edit message">
                                            <IconButton
                                                color="primary"
                                                aria-label="Edit message"
                                                onClick={() =>
                                                    props.onEditClick(
                                                        props.message,
                                                    )
                                                }
                                            >
                                                <Icon name="edit" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                                {isScheduled && isUntilReview && (
                                    <Tooltip title="Send now" arrow>
                                        <span>
                                            <IconButton
                                                color="primary"
                                                type="button"
                                                onClick={send}
                                                disabled={sendNow.isPending}
                                            >
                                                {sendNow.isPending ? (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                ) : (
                                                    <SendFilledIcon />
                                                )}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                                <WithPermission
                                    requiredPermissions={[
                                        ClerkPermissions.DeleteMessage,
                                    ]}
                                >
                                    <Divider
                                        orientation="vertical"
                                        sx={{
                                            mx: 2,
                                            height: 16,
                                            alignSelf: 'center',
                                        }}
                                        flexItem
                                    />
                                    <Tooltip arrow title="Delete">
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                setDeleteModal(true);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </WithPermission>
                            </Box>
                        </Fade>
                    </Box>
                )}

                {/* AI Disclaimer. Remove once Microsoft approves our app. Those fools dont see the beauty... */}
                {props.message.senderType === SenderType.ASSISTANT && (
                    <Box typography="body5" sx={status}>
                        Content generated by AI may be incorrect
                    </Box>
                )}

                {/* Status */}
                {props.message.status === MessageStatus.Failed &&
                    !props.message.inbound && (
                        <Box typography="body5" sx={status}>
                            Message Failed to Send
                        </Box>
                    )}

                {/* Attachments */}
                {props.message.attachments?.length > 0 && (
                    <div
                        className={cc([
                            styles['root__attachments'],
                            {
                                [styles['root__attachments_inbound']]:
                                    props.message.inbound,
                            },
                        ])}
                    >
                        {props.message.attachments.map((attachment) => (
                            <AttachmentItem
                                key={attachment.id}
                                attachment={attachment}
                            />
                        ))}
                    </div>
                )}

                {props.message.discussion &&
                    props.message.discussion.replies > 0 && (
                        <DiscussionBlock
                            isInbound={props.message.inbound}
                            discussion={props.message.discussion}
                            onClick={() =>
                                props.setActiveDiscussion(
                                    props.message.discussion!.id,
                                )
                            }
                        />
                    )}
            </Box>
            {/* Avatar */}
            <Box sx={getAvatarContainer(props.message.inbound)}>
                {props.isGroupEnd &&
                    props.message.senderType !== SenderType.ASSISTANT && (
                        <Avatar
                            src={props.senderAvatar}
                            alt={props.senderName}
                            sx={{ bgcolor: 'custom.gray.super' }}
                        />
                    )}
                {props.isGroupEnd &&
                    props.message.senderType === SenderType.ASSISTANT && (
                        <AssistantAvatar />
                    )}
            </Box>
            {/* Delete modal */}
            <GenericDialog
                open={deleteModal}
                confirmLabel="Delete"
                dismissLabel="Cancel"
                onClose={() => setDeleteModal(false)}
                onConfirm={async () => {
                    try {
                        await deleteMessage({
                            messageId: props.message.id,
                            conversationId: props.conversationId,
                        });
                        setDeleteModal(false);
                    } catch (e) {
                        setDeleteModalStatus(
                            'Failed to delete message. Please try again.',
                        );
                    }
                }}
                title="Delete Message"
                description={
                    deleteModalStatus ? (
                        <Typography
                            sx={{ display: 'block' }}
                            variant="body3"
                            color="error"
                        >
                            {deleteModalStatus}
                        </Typography>
                    ) : (
                        <Typography variant="body3">
                            Are you sure you want to delete this message?
                        </Typography>
                    )
                }
            />
            {showSchedule && (
                <TimestampChangeDialog
                    disabled={isLoading}
                    timestamp={dayjs(props.message.timestamp)}
                    onChange={async (date) => {
                        setIsLoading(true);
                        await update.mutateAsync({
                            id: props.message.id,
                            message: {
                                timestamp: date,
                                conversationId: props.message.conversationId,
                            },
                        });

                        track(AnalyticsEventName.MESSAGE_TIMESTAMP_CHANGED);
                        setIsLoading(false);
                        setShowSchedule(false);
                    }}
                    minDate={getNearestAlignedCurrentDate()?.toDate()}
                    onClose={() => setShowSchedule(false)}
                    title="Schedule a message"
                    open
                >
                    {isLoading && (
                        <Box sx={loadingSpinner}>
                            <LinearProgress sx={loadingSpinnerProgress} />
                        </Box>
                    )}
                </TimestampChangeDialog>
            )}
        </div>
    );
});

export default MessageRow;
