import { useEffect, useState } from 'preact/hooks';
import { useSocket } from '../../websocket';
import { UUID } from '../../types/uuid';
import { useMeQueryData } from '../../queries/user';

export type UserTyping = {
    userId: string;
    name: string;
    conversationId: string;
};

export function useUserTyping(conversationId: UUID): string {
    const me = useMeQueryData()?.id;
    const socket = useSocket();
    const [userTyping, setUserTyping] = useState<string>('');
    const connection = socket.getSocket();

    useEffect(() => {
        let timeout: number;

        const onTyping = (data: UserTyping) => {
            if (data.userId !== me && data.conversationId === conversationId) {
                setUserTyping(data.name);
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                    setUserTyping('');
                }, 5001);
            }
        };
        if (connection) {
            connection.on('typing', onTyping);
        }
        return () => {
            if (connection) {
                connection.off('typing', onTyping);
            }
        };
    }, [conversationId, me, connection]);

    return userTyping;
}
