import client from '../api/http';
import { QueryKey, useMutation, useQuery } from '@tanstack/react-query';
import {
    connectIntegration,
    disconnectIntegration,
    getIntegrationRemoteSchema,
    getMicrosoftTeamsPhones,
    syncIntegration,
    updateIntegration,
    listIntegrations,
    ConnectIntegrationRequest,
} from '../api/integrations';
import { AxiosError, AxiosResponse } from 'axios';
import { TeamsPhoneResponse } from '../api/types';
import { queryClient } from './queryClient';

export const INTEGRATIONS_LIST_QUERY_KEY: QueryKey = ['integrations-list'];

export const useGetIntegrationRemoteSchema = (integrationId: string) => {
    return useQuery({
        queryKey: ['integration', integrationId, 'remote-properties'],
        queryFn: () => getIntegrationRemoteSchema({ id: integrationId }),
    });
};

export const useGetMicrosoftTeamsPhones = () => {
    return useQuery<TeamsPhoneResponse, AxiosError>({
        queryKey: ['microsoft-teams-phones'],
        queryFn: () => getMicrosoftTeamsPhones(),
        retry: false,
    });
};

export const useUpdateIntegration = () =>
    useMutation({
        mutationFn: updateIntegration,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });

export const useDisconnectIntegration = () =>
    useMutation({
        mutationFn: disconnectIntegration,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });

export const useConnectIntegration = () =>
    useMutation<unknown, AxiosResponse, ConnectIntegrationRequest>({
        mutationFn: connectIntegration,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });

export const useListIntegrations = () => {
    return useQuery({
        queryKey: INTEGRATIONS_LIST_QUERY_KEY,
        queryFn: () => listIntegrations(),
    });
};

export const useSyncIntegration = () =>
    useMutation({
        mutationFn: syncIntegration,
    });

export type CreateAzureIntegrationData = {
    storageContainer: string;
    storageAccount: string;
    storageAccessKey: string;
};

export const useCreateAzureIntegration = () =>
    useMutation<
        void,
        AxiosError<{ message: string }>,
        CreateAzureIntegrationData
    >({
        mutationKey: ['create-azure-integration'],
        mutationFn: async (data: {
            storageContainer: string;
            storageAccount: string;
            storageAccessKey: string;
        }) => {
            await client().post<void>('/integrations/setup/azure', data);
        },
    });
