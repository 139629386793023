import cc from 'classcat';
import { useRef } from 'preact/hooks';
import styles from './HeaderMenu.module.scss';
import EditIcon from '../../../assets/icons/icons-24/gray/edit.svg?react';
import DeleteRedIcon from '../../../assets/icons/icons-24/red/delete.svg?react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface HeaderMenuProps {
    onOutsideClick: () => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export const HeaderMenu = ({
    onOutsideClick,
    onEditClick,
    onDeleteClick,
}: HeaderMenuProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, onOutsideClick);

    return (
        <div className={styles['header-menu']} ref={wrapperRef}>
            <ul className={styles['header-menu__items']}>
                <li className={styles['header-menu__item']}>
                    <button
                        className={cc([
                            styles['header-menu__action-btn'],
                            styles['header-menu__edit-btn'],
                        ])}
                        onClick={onEditClick}
                    >
                        <EditIcon />
                        <span>Edit name</span>
                    </button>
                </li>
                <li className={styles['header-menu__item']}>
                    <button
                        className={cc([
                            styles['header-menu__action-btn'],
                            styles['header-menu__delete-btn'],
                        ])}
                        onClick={onDeleteClick}
                    >
                        <DeleteRedIcon />
                        <span>Delete</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
