import Router, { route, Route } from 'preact-router';
import { useState } from 'preact/hooks';
import { Redirect, RedirectProps } from '../../utils/redirect';
import BillingInfo from './BillingInfo';
import { BillingInfoProps } from './BillingInfo/BillingInfo';
import PaymentFinish from './PaymentFinish';
import styles from './PaymentFlow.module.scss';
import PaymentPlans, { PaymentPlansProps } from './PaymentPlans';
import { PlanItem, plansMap } from './plans';

const getSelectedPlan = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const priceId = urlParams.get('pid');
    return priceId ? plansMap[priceId] : null;
};

export const PaymentFlow = () => {
    const [selectedPlan, setSelectedPlan] = useState<PlanItem | undefined>(
        getSelectedPlan() ?? undefined,
    );

    return (
        <div className={styles['root']}>
            <Router>
                <Route<PaymentPlansProps>
                    path="/payment/plan"
                    component={PaymentPlans}
                    setSelectedPlan={setSelectedPlan}
                />
                <Route<BillingInfoProps>
                    path="/payment/billing-info"
                    component={BillingInfo}
                    plan={selectedPlan}
                    goBackward={() => route('/payment/plan')}
                />
                <Route path="/payment/finish" component={PaymentFinish} />
                <Route<RedirectProps>
                    path="/payment"
                    to="/payment/plan"
                    component={Redirect}
                />
            </Router>
        </div>
    );
};
