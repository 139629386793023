import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './MessageTemplates.module.scss';
import { MessageTemplate } from '../../api/types';
import Button from '../../elements/Buttons';
import CheckBox from '../../elements/CheckBox/CheckBox';
import {
    useMessageTemplateCreateQuery,
    useMessageTemplateUpdateQuery,
} from '../../queries/message-templates';
import { MessageInput } from '../MessageInput/MessageInput';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import { withTemplates } from '../MessageInput/components/Template/withTemplates';
import { withParagraphs } from '../MessageInput/components/Paragraph/withParagraph';
import { TextSerializer } from '../MessageInput/serializers/text';
import { isEmpty } from '../MessageInput/utils';
import { useProperties } from '../MessageInput/components/Template/useProperties';

export type MessageTemplatesProps = {
    template: MessageTemplate | null;
    onDone: () => void;
    withProperties: boolean;
};

export const MessageTemplatesEditor = ({
    template,
    onDone,
    withProperties,
}: MessageTemplatesProps) => {
    const [editor] = useState(
        withTemplates(withParagraphs(withReact(withHistory(createEditor())))),
    );

    const { regEx } = useProperties();
    const [name, setName] = useState(template?.name || '');
    const [body, setBody] = useState(
        TextSerializer.deserialize(template?.body || '', regEx),
    );
    const [isPersonal, setPersonal] = useState(!!template?.userId);

    const nameInputRef = useRef<HTMLInputElement>(null);

    // Mutations
    const { mutate: create } = useMessageTemplateCreateQuery();
    const { mutate: update } = useMessageTemplateUpdateQuery();

    useEffect(() => {
        nameInputRef.current?.focus();
    }, []);

    const toolbar = useMemo(
        () => (withProperties ? ['templates', 'emojiPicker'] : ['emojiPicker']),
        [withProperties],
    );

    return (
        <>
            <header className={styles['tempaltes__header']}>
                <h6 className={styles['tempaltes__title']}>Create a snippet</h6>

                <div>
                    <Button
                        type="text"
                        onClick={() => onDone()}
                        className={styles['tempaltes__cancel-btn']}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="normal"
                        onClick={() => {
                            const serialized = TextSerializer.serialize(body);
                            if (template) {
                                update({
                                    id: template.id,
                                    name,
                                    body: serialized,
                                    isPersonal,
                                });
                            } else {
                                create({
                                    name: name.trim(),
                                    body: serialized.trim(),
                                    isPersonal,
                                });
                            }
                            onDone();
                        }}
                        disabled={!name.trim() || isEmpty(body)}
                    >
                        Save
                    </Button>
                </div>
            </header>

            <div className={styles['tempaltes__details']}>
                <input
                    className={cc([
                        styles['tempaltes__input'],
                        styles['tempaltes__name-input'],
                    ])}
                    placeholder="Snippet name"
                    ref={nameInputRef}
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                />
                <CheckBox
                    label="Personal"
                    checked={isPersonal}
                    onChange={() => setPersonal(!isPersonal)}
                />
            </div>
            <div className={styles['tempaltes__content']}>
                <MessageInput
                    editor={editor}
                    onChange={({ value }) => setBody(value)}
                    initialValue={body}
                    toolbar={toolbar}
                    height="100%"
                />
            </div>
        </>
    );
};
