import { useMemo } from 'preact/hooks';
import { ArrowCircleLeft } from 'phosphor-react';
import styles from './WorkflowHeader.module.scss';
import { Contact, Conversation } from '../../api/types';
import { useContactsQueryData } from '../../queries/contacts';
import ConversationTitle from '../ConversationTitle';
import ConversationToolbar from '../ConversationToolbar';
import Icon from '../../icons/Icon';
import { Tooltip, IconButton, Box } from '@mui/material';
import { memo } from 'preact/compat';

interface WorkflowHeaderProps {
    conversation?: Conversation;
    cohortName?: string;
    noToolbar?: boolean;
    onBackClick?: () => void;
    onToggleContactInfo?: () => void;
}

export const WorkflowHeader = memo(
    ({
        conversation,
        cohortName,
        noToolbar,
        onBackClick,
        onToggleContactInfo,
    }: WorkflowHeaderProps) => {
        const contacts = useContactsQueryData();

        const memberContacts = useMemo(() => {
            const members = conversation?.members || [];
            return members.map((m) => contacts.getByPhoneOrEmpty(m) as Contact);
        }, [conversation, contacts]);

        const hasContactInfo =
            !!onToggleContactInfo && memberContacts.length === 1;

        return (
            <header className={styles['workflow-header']}>
                <IconButton
                    sx={{ display: ['block', 'block', 'none'] }}
                    onClick={onBackClick}
                >
                    <ArrowCircleLeft size={16} weight="bold" />
                </IconButton>

                <ConversationTitle
                    members={memberContacts}
                    customName={cohortName}
                    onToggleContactInfo={onToggleContactInfo}
                />

                <div className={styles['workflow-header__controls']}>
                    {!noToolbar && conversation && (
                        <ConversationToolbar
                            conversation={conversation}
                            contacts={memberContacts}
                        />
                    )}

                    {hasContactInfo && (
                        <>
                            {!noToolbar && (
                                <Box
                                    sx={{
                                        height: 20,
                                        width: 0,
                                        borderLeft: '1px solid #e9ebf2',
                                    }}
                                />
                            )}
                            <Tooltip
                                arrow
                                placement="bottom"
                                title="Show more info"
                            >
                                <IconButton onClick={onToggleContactInfo}>
                                    <Icon name="contact-info" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </header>
        );
    },
);
