import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { borderRadius } from './border-radius';

export const MuiButton: Components<Theme>['MuiButton'] = {
    defaultProps: {
        variant: 'contained',
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(2.5, 3),
            borderRadius,
            textTransform: 'initial',
        }),
        sizeSmall: ({ theme }) => ({
            padding: theme.spacing(1.5, 3),
        }),
        sizeLarge: ({ theme }) => ({
            padding: theme.spacing(1.5),
        }),
        contained: ({ theme }) => ({
            '&:not(.Mui-disabled)': {
                boxShadow: theme.shadows[2],
            },
            '&:hover:not(:focus-within)': {
                boxShadow: 'none',
            },
        }),
        containedPrimary: ({ theme }) => ({
            boxShadow: theme.shadows[2],
            '&:hover, &:focus-within': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:hover': {
                boxShadow: 'none',
            },
        }),
        text: ({ theme }) => ({
            color: theme.palette.primary.dark,
        }),
        textInfo: ({ theme }) => ({
            color: theme.palette.info.main,
        }),
        outlinedPrimary: ({ theme }) => ({
            color: theme.palette.custom.text.primary,
            boxShadow: theme.shadows[1],
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'primary.outlined.border',
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                boxShadow: 'none',
                borderColor: theme.palette.custom.outlinedBorder.primary,
                backgroundColor: theme.palette.primary.hover,
            },
        }),
        outlinedError: ({ theme }) => ({
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                boxShadow: 'none',
                borderColor: theme.palette.error.outlinedBorder,
                backgroundColor: theme.palette.error.hover,
            },
            '&.Mui-disabled': {
                color: theme.palette.custom.text.disabled,
                backgroundColor: theme.palette.custom.action.disabledBackground,
            },
        }),
        outlined: ({ theme }) => ({
            borderColor: theme.palette.custom.gray.divider,

            '&:hover': {
                background: 'none',
            },
        }),
    },
};
