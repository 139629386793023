import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
// styles
import styles from './FilterMultiselectValue.module.scss';
// components
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
import CheckBox from '../../../../elements/CheckBox/CheckBox';
// icons
import searchIcon from '../../../../assets/icons/icons-16/search.svg';
// utils
import { filterOptions } from '../utils';
import { UserPropertyFilterModel } from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

interface FilterMultiselectValueProps {
    userPropertyFilter: UserPropertyFilterModel<string[]>;
    changeHandler: (value: UserPropertyFilterModel) => void;
    closeHandler: () => void;
}

export const FilterMultiselectValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterMultiselectValueProps) => {
    const { value = [], options = [] } = userPropertyFilter;

    const [selected, setSelected] = useState<Array<string>>(value);
    const [filterTerm, setFilterTerm] = useState<string>('');
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        changeHandler({
            ...userPropertyFilter,
            value: selected,
        });
    }, [selected]);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    const filteredOptions = filterTerm
        ? filterOptions(options, filterTerm)
        : options;

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div className={styles['filter__top-panel']}>
                <Input
                    wrapperClassName={styles['filter__search-input-wrapper']}
                    className={styles['filter__search-input']}
                    placeholder="Search"
                    prefix={<img src={searchIcon} />}
                    value={filterTerm}
                    onChange={setFilterTerm}
                    customRef={inputRef}
                />
                <div
                    className={styles['filter__select-all']}
                    onClick={() => {
                        if (selected.length === options.length) {
                            setSelected([]);
                        } else {
                            setSelected(options);
                        }
                    }}
                >
                    <CheckBox
                        className={styles['filter__checkbox']}
                        checked={selected.length === options.length}
                    />
                    Select All
                </div>
            </div>

            <DropdownList className={styles['filter__list']}>
                {filteredOptions.map((option, i) => (
                    <DropdownItem
                        key={option}
                        variant="list-item"
                        type="regular"
                        className={cc([
                            {
                                [styles['filter__item_last']]:
                                    i === filteredOptions.length - 1,
                            },
                        ])}
                        onClick={() => {
                            const newSelected = selected.includes(option)
                                ? selected.filter(
                                      (selectedOption) =>
                                          selectedOption !== option,
                                  )
                                : selected.concat(option);

                            setSelected(newSelected);
                        }}
                    >
                        <CheckBox
                            className={styles['filter__checkbox']}
                            checked={selected?.includes(option)}
                        />
                        {option}
                    </DropdownItem>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
