import isEmpty from 'lodash/isEmpty';
import { Box, LinearProgress } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { AttachmentItem } from './AttachmentItem';

export const AttachmentList = ({
    attachments,
    isAttachmentUploading,
    removeAttachment,
}: {
    attachments: Attachment[];
    isAttachmentUploading: boolean;
    removeAttachment: (a: Attachment) => void;
}) => {
    return (
        <>
            {isAttachmentUploading && (
                <LinearProgress
                    sx={{
                        position: 'absolute',
                        bottom: 1,
                        left: 1.5,
                        width: 'calc(100% - 2px)',
                        overflow: 'hidden',
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                    }}
                />
            )}
            {!isEmpty(attachments) && (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        maxWidth: 1,
                    }}
                >
                    {attachments.map((attachment, i) => (
                        <AttachmentItem
                            key={`${attachment.name}/${i}`}
                            attachment={attachment}
                            onRemove={removeAttachment}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};
