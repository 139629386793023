import { AvailableIntegration } from './integrations-list';
import { Stack, Tooltip, Typography } from '@mui/material';
import * as styles from './styles';
import { useState } from 'preact/hooks';
import { useTrack } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';

type Props = Pick<AvailableIntegration, 'icon' | 'name' | 'source'>;

export function ComingSoonItem({ icon, name, source }: Props) {
    const [isRequested, setIsRequested] = useState(false);
    const trackEvent = useTrack();
    const handleRequestIntegration = () => {
        setIsRequested(true);
        setTimeout(() => setIsRequested(false), 2000);
        trackEvent(AnalyticsEventName.REQUESTED_INTEGRATION, {
            source,
        });
    };

    return (
        <Tooltip
            title={
                isRequested
                    ? 'Requested!'
                    : 'Click if you want this integration.\nWe will try to add it as soon as possible'
            }
            placement="top"
        >
            <Stack
                direction="row"
                spacing={2}
                sx={styles.comingSoon}
                alignItems="center"
                onClick={handleRequestIntegration}
            >
                <img src={icon} alt={name} />
                <Typography variant="body4" color="primary.dark">
                    {name}
                </Typography>
            </Stack>
        </Tooltip>
    );
}
