import { TargetedEvent, useRef } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { UploadIcon } from './UploadIcon';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { UseMutationResult } from '@tanstack/react-query';
import { acceptImageFormats } from '../../constants';

export default function AvatarControl({
    imageUrl,
    uploadImageMutation,
    title,
    uploadImageErrorMessage,
    description = 'Min. 64x64 px; File format: .png, .jpg, or .jpeg under 10 Mb',
}: {
    title: string;
    description?: string;
    imageUrl?: string;
    uploadImageMutation?: UseMutationResult<
        unknown,
        Error,
        { file: File },
        unknown
    >;
    uploadImageErrorMessage?: string;
}) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImage = (event: TargetedEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        if (file && uploadImageMutation) {
            uploadImageMutation.mutate({ file });
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploading =
        !!uploadImageMutation && uploadImageMutation.status === 'pending';

    const hasError =
        !!uploadImageMutation &&
        !!uploadImageMutation.error &&
        !!uploadImageErrorMessage;

    return (
        <>
            <Stack spacing={4}>
                <Stack spacing={4} direction="row" alignItems="center">
                    <UserAvatar imageUrl={imageUrl} size={64} />

                    <Stack spacing={1}>
                        <Typography variant="body3" color="primary.dark">
                            {title}
                        </Typography>
                        <Typography variant="body4" color="custom.gray.super">
                            {description}
                        </Typography>
                    </Stack>

                    <Stack flex={1} alignItems="flex-end">
                        <Box
                            sx={{ display: 'none' }}
                            component="input"
                            type="file"
                            accept={acceptImageFormats}
                            ref={fileInputRef}
                            onChange={handleImage}
                        />

                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={uploading}
                            sx={{
                                minWidth: 104,

                                '& .MuiButton-startIcon': {
                                    color: 'info.main',
                                },
                            }}
                            startIcon={<UploadIcon />}
                            onClick={handleUploadClick}
                        >
                            {uploading ? 'Uploading...' : 'Upload'}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            {hasError && (
                <Typography variant="body4" color="error.main">
                    {uploadImageErrorMessage}
                </Typography>
            )}
        </>
    );
}
