import { route } from 'preact-router';
import styles from './NoContacts.module.scss';
import noContactsImg from '../../assets/icons/no-contacts.svg';
import { useContactCreateQuery } from '../../queries/contacts';
import { useCallback } from 'preact/hooks';
import { Button } from '@mui/material';

export const NoContacts = () => {
    const { mutateAsync: createContact } = useContactCreateQuery();

    const createContactHandler = useCallback(
        () =>
            createContact({}).then(({ id }) => {
                route(`/contacts/${id}`);
            }),
        [],
    );

    return (
        <div className={styles['root']}>
            <img
                className={styles['root__img']}
                src={noContactsImg}
                alt="contacts"
            />
            <h6 className={styles['root__title']}>Contacts</h6>
            <p className={styles['root__info']}>Add your first contact</p>
            <Button sx={{ width: 180 }} onClick={createContactHandler}>
                Add contact
            </Button>
        </div>
    );
};
