import { render } from 'preact';
import { QueryClientProvider } from '@tanstack/react-query';
import 'react-datepicker/dist/react-datepicker.css';
import { App } from './app';
import './index.scss';

import { ToastProvider } from './containers/toast/reducer';
import { ModalProvider } from './containers/modal/reducer';
import { AnalyticsProvider } from './contexts/analytics';
import * as Sentry from '@sentry/react';
import { queryClient } from './queries/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import InboxContextProvider from './contexts/InboxContext';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { MessageUpdateProvider } from './components/MessageForm/MessageUpdateContext';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import { ignoredErrors } from './error.utils';

async function startApp() {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_KEY as string,
        environment: import.meta.env.MODE,
        ignoreErrors: ignoredErrors,
        enabled: import.meta.env.PROD,
        tracesSampleRate: 1.0,
        attachStacktrace: true,
    });

    // Record all sessions on localhost, staging stacks and preview URLs
    if (!import.meta.env.PROD) {
        await tryLoadAndStartRecorder({
            projectId: import.meta.env.VITE_METICULOUS_PROJECT_ID,
            isProduction: false,
        });
    }

    // Initalise app after the Meticulous recorder is ready, e.g.
    render(app, document.getElementById('app')!);
}

const app = (
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{ body: { fontSize: 14, lineHeight: '20px' } }}
            />
            <ModalProvider>
                <ToastProvider>
                    <AnalyticsProvider>
                        <InboxContextProvider>
                            <MessageUpdateProvider>
                                <App />
                            </MessageUpdateProvider>
                        </InboxContextProvider>
                    </AnalyticsProvider>
                </ToastProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </ModalProvider>
        </ThemeProvider>
    </QueryClientProvider>
);

startApp();
