import { RefObject } from 'preact';
import { useEffect } from 'preact/hooks';

type Handler = (event?: MouseEvent | TouchEvent | KeyboardEvent) => void;
type Ref<T> = RefObject<T> | HTMLElement | null;

const contains = <T extends HTMLElement>(
    ref: RefObject<T> | HTMLElement,
    target: Node,
) => {
    if (Object.hasOwn(ref, 'current')) {
        const elementRef = ref as RefObject<T>;

        if (!elementRef.current || elementRef.current.contains(target)) {
            return true;
        }
    } else {
        const elementRef = ref as HTMLElement;
        if (elementRef.contains(target)) {
            return true;
        }
    }
    return false;
};

function useOnClickOutside<T extends HTMLElement>(
    ref: Ref<T> | Array<Ref<T>>,
    handler: Handler,
) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (Array.isArray(ref)) {
                const refs = ref as RefObject<T>[] | HTMLElement[];

                if (refs.length === 0) {
                    return;
                }

                if (refs.some((ref) => !ref)) {
                    return;
                }

                if (refs.some((ref) => contains(ref, event.target as Node))) {
                    return;
                }
            } else {
                const elementRef = ref as RefObject<T> | HTMLElement;
                if (contains(elementRef, event.target as Node)) {
                    return;
                }
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}

export default useOnClickOutside;
