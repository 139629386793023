import cc from 'classcat';
import styles from './NewPhoneNumber.module.scss';
import { CountryOption } from '../types';
import { OptionId } from '../../../elements/AdvancedSelect';
import CountryIcon from '../../../assets/icons/icons-24/gray/country.svg?react';

export const renderCountryValue = (
    options: CountryOption[],
    value?: OptionId | OptionId[],
    _placeholder?: string,
) => {
    const selectedValue = options.find((option) => option.id === value);

    return selectedValue ? (
        <span className={styles['country-value']}>
            {selectedValue.icon}
            <span>{selectedValue.label}</span>
            {selectedValue.code}
        </span>
    ) : (
        <span className={styles['country-placeholder']}>
            <CountryIcon />
            Choose country
        </span>
    );
};

export const renderCountryOption = (
    option: CountryOption,
    _selected: boolean,
) => (
    <div
        className={cc([
            styles['country-option'],
            {
                [styles['country-option_disabled']]: option.disabled,
            },
        ])}
    >
        {option.icon}
        <span>{option.label}</span>
        {option.code}
    </div>
);
