import { Button } from '@mui/material';
import { ToastActions, ToastModalState, useToastContext } from './reducer';
import styles from './toast.module.scss';

export default function Toast({ toast }: { toast: ToastModalState }) {
    const { dispatch: toastDispatch } = useToastContext();

    // @ts-ignore
    function renderItem(content) {
        if (typeof content === 'function') {
            return content();
        }
        return (
            <p className={styles[`toast-container-item-text`]}>
                {content.message}
            </p>
        );
    }

    return (
        <div className={styles['toast']}>
            <div className={styles['toast-container']}>
                {/* Displaying each element of the toast */}
                {toast.map((t) => {
                    return (
                        t.content && (
                            <div
                                className={styles[`toast-container-item`]}
                                key={t.id}
                            >
                                {renderItem(t.content)}
                                <Button
                                    variant="outlined"
                                    onClick={() =>
                                        toastDispatch({
                                            type: ToastActions.REMOVE,
                                            payload: { id: t.id },
                                        })
                                    }
                                >
                                    Close
                                </Button>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
}
