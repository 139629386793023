import client from './http';
import { Brand, CreateBrandDto, UpdateBrandDto } from './types';

export function getBrand() {
    return client()
        .get<Brand>('/brand')
        .then((res) => res.data);
}

export function createBrand(brand: CreateBrandDto) {
    return client()
        .post('/brand', brand)
        .then((res) => res.data);
}

export function updateBrand(params: UpdateBrandDto) {
    return client()
        .put(`/brand/${params.id}`, params.brand)
        .then((res) => res.data);
}
