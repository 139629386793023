import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './FormControl.module.scss';

export interface FormControlProps {
    label?: string;
    className?: string;
    children: ComponentChildren;
}

export const FormControl = (props: FormControlProps) => (
    <p className={cc([props.className, styles['root']])}>
        <label className={styles['root__control']}>
            {props.label && (
                <span className={styles['root__label']}>{props.label}</span>
            )}
            {props.children}
        </label>
    </p>
);
