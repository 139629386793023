import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './Dialog.module.scss';

export interface DialogProps {
    width?: string;
    className?: string;
    children?: ComponentChildren;
}

/** @deprecated use mui/dialog */
export const Dialog = (props: DialogProps) => {
    return (
        <div
            style={props.width ? { width: props.width } : {}}
            className={cc([styles['root'], props.className])}
        >
            {props.children}
        </div>
    );
};
