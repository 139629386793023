import cc from 'classcat';
import { Link } from 'preact-router/match';
//assets
//styles
import styles from './Button.module.scss';
import { useState } from 'preact/hooks';
import { ComponentChildren } from 'preact';

//types

export type ButtonProps = {
    children?: ComponentChildren;
    icon?: JSX.Element;
    label?: string | JSX.Element;
    onClick: () => void;
    type?:
        | 'primary'
        | 'default'
        | 'text'
        | 'success'
        | 'warning'
        | 'icon'
        | 'purple';
    shape?: 'circle' | 'round';
    size?: 'small' | 'normal' | 'large' | 'extra-large';
    disabled?: boolean;
    iconCenter?: boolean;
    fullWidth?: boolean;
    iconRight?: boolean;
    className?: string;
    href?: string;
    to?: string;
    align?: 'left' | 'center';
    slim?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

/** @deprecated use material button instead */
function Button(props: ButtonProps) {
    const [pressed, setPressed] = useState(false);
    const {
        children,
        label,
        disabled,
        fullWidth,
        icon,
        iconCenter,
        iconRight,
        className,
        href,
        to,
        slim = false,
        align = 'center',
        size = 'normal',
        type = 'primary',
        shape = 'round',
        onClick,
        ...rest
    } = props;

    const classNameList = cc([
        styles['clerk-btn'],
        className,
        {
            [styles['clerk-btn_purple']]: type === 'purple',
            [styles['clerk-btn_primary']]: type === 'primary',
            [styles['clerk-btn_default']]: type === 'default',
            [styles['clerk-btn_text']]: type === 'text',
            [styles['clerk-btn_circle']]: shape === 'circle',
            [styles['clerk-btn_round']]: shape === 'round',
            [styles['clerk-btn_size-small']]: size === 'small',
            [styles['clerk-btn_size-large']]: size === 'large',
            [styles['clerk-btn_size-extra-large']]: size === 'extra-large',
            [styles['clerk-btn_full-width']]: fullWidth,
            [styles['clerk-btn_with-icon']]: icon && !children && !label,
            [styles['clerk-btn_align_left']]: align === 'left',
            [styles['clerk-btn_slim']]: slim,
            [styles['clerk-btn_success']]: type === 'success',
            [styles['clerk-btn_pressed']]: pressed,
            [styles['clerk-btn_warning']]: type === 'warning',
            [styles['clerk-btn_only-icon']]: type === 'icon',
        },
    ]);

    const content = (
        <>
            {icon && !iconRight && !iconCenter && (
                <span className={styles['clerk-btn__icon']}>{icon}</span>
            )}
            {icon && iconCenter && (
                <span
                    className={cc([
                        styles['clerk-btn__icon'],
                        styles['clerk-btn__icon_center'],
                    ])}
                >
                    {icon}
                </span>
            )}
            {(label || children) && (
                <span className={styles['clerk-btn__label']}>
                    {children ? children : label}
                </span>
            )}
            {icon && !(href || to) && iconRight && (
                <span
                    className={cc([
                        styles['clerk-btn__icon'],
                        styles['clerk-btn__icon_right'],
                    ])}
                >
                    {icon}
                </span>
            )}
        </>
    );

    switch (true) {
        case !!href:
            return (
                <a
                    href={disabled ? '#' : href}
                    className={classNameList}
                    target="_blank"
                    rel="noreferrer"
                    role="button"
                    onClick={(e) => {
                        setPressed(true);
                        onClick && onClick(e);
                    }}
                    onTransitionEnd={() => setPressed(false)}
                    {...rest}
                >
                    {content}
                </a>
            );
        case !!to:
            return (
                <Link
                    href={disabled ? '#' : to}
                    className={classNameList}
                    disabled={disabled}
                    role="button"
                    {...rest}
                >
                    {content}
                </Link>
            );
        default:
            return (
                <button
                    className={classNameList}
                    disabled={disabled}
                    onClick={(e) => {
                        setPressed(true);
                        onClick && onClick(e);
                    }}
                    onTransitionEnd={() => setPressed(false)}
                    {...rest}
                >
                    {content}
                </button>
            );
    }
}

export default Button;
