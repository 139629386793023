import cc from 'classcat';
import { ComponentChildren } from 'preact';
import styles from './IconBase.module.scss';

export interface IconBaseProps {
    size?: string;
    viewBox?: string;
    color?: string;
    className?: string;
    children?: ComponentChildren;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const IconBase = ({
    size = '24px',
    viewBox = '0 0 24 24',
    className,
    children,
    ...rest
}: IconBaseProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        fill="none"
        width={size}
        height={size}
        className={cc([styles['root'], className])}
        {...rest}
    >
        {children}
    </svg>
);

export default IconBase;
