import cc from 'classcat';
import { route } from 'preact-router';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { Cohort, Contact } from '../../api/types';
import Chat from '../../components/Chat';
import CohortContactsList from '../../components/CohortContactsList';
import { CohortConversationsList } from '../../components/ConversationsList';
import { useContactsQueryData } from '../../queries/contacts';
import { useGetConversation } from '../../queries/conversations';
import styles from './Cohorts.module.scss';

const isDisabled = (contacts: Contact[]) =>
    contacts.length === 1 && contacts[0].optOut;

interface CohortInnerProps {
    cohort?: Cohort;
    conversationId: string;
}

export function CohortInner({ cohort, conversationId }: CohortInnerProps) {
    const [selectedContact, setSelectedContact] = useState<Contact | null>(
        null,
    );

    const { data: conversation } = useGetConversation(conversationId);
    const contacts = useContactsQueryData();

    const memberContacts = useMemo(() => {
        return conversation?.members
            ? (conversation?.members.map((member) =>
                  contacts.getByPhoneOrEmpty(member),
              ) as Contact[])
            : [];
    }, [conversation]);

    useEffect(() => {
        if (conversation && conversation.status === 'deleted') {
            route(`/cohorts/${cohort?.id}`);
        }
    }, [conversation, conversationId]);

    return (
        <div className={styles['cohorts']}>
            <div
                className={cc([
                    {
                        [styles['cohorts_mobile-hidden']]: !!conversation,
                    },
                ])}
            >
                <CohortConversationsList cohort={cohort} />
            </div>

            <section
                className={cc([
                    styles['chat'],
                    {
                        [styles['chat_empty']]: !conversation,
                        [styles['cohorts_mobile-hidden']]: !conversation,
                    },
                ])}
            >
                {conversationId && conversation && (
                    <Chat
                        conversation={conversation}
                        onBackClick={() => route(`/cohorts/${cohort?.id}`)}
                        messageFormDisabled={isDisabled(memberContacts)}
                    />
                )}
                {!conversationId && (
                    <p className={styles['cohorts__chat-placeholder']}>
                        Choose chat to start conversation
                    </p>
                )}
            </section>

            <CohortContactsList
                contacts={cohort?.contacts}
                selectedContact={
                    selectedContact || (memberContacts && memberContacts[0])
                }
                renderEmpty={!!conversationId && !conversation}
                onContactClick={(contact) =>
                    setSelectedContact(contact as Contact | null)
                }
            />
        </div>
    );
}
