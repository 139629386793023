import { useCallback, useRef, useState } from 'preact/hooks';
import debounce from 'lodash/debounce';
import cc from 'classcat';
import styles from './SearchPanel.module.scss';
import { useNewConversationContext } from '../NewConversationProvider';
import Button from '../../../elements/Buttons';
import Counter from '../../../elements/Counter';
import Input from '../../../elements/Inputs/Input';
import Icon from '../../../icons/Icon';
import Condition from '../../ContactsFilter/Condition';
import AddFilter from '../../ContactsFilter/AddFilter';
import {
    FilterValue,
    UserPropertyFilterCondition,
} from '../../UserInfoProperty/types';
import UserPropertiesFilterValueDropdown from '../../ContactsFilter/UserPropertiesFilterValueDropdown';
import { usePropertiesQueryData } from '../../../queries/properties';
import CloseButton from '../../../elements/Buttons/CloseButton';
import { useEffect } from 'react';
import { AIToggle } from '../../ContactsFilter/ContactsFilterPanel/AIToggle';
import { SuggestInput } from '../../ContactsFilter/ContactsFilterPanel/SuggestInput';
import { alternateWithAnd } from '../../ContactsFilter/ContactsFilterPanel/alternate-with-and';

export const SearchPanel = () => {
    const { state, actions } = useNewConversationContext();
    const inputRef = useRef<HTMLInputElement>(null);

    const userProperties = usePropertiesQueryData();

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleSearchInput = useCallback(
        debounce(
            (searchTerm: string) => actions.setQuickSearchTerm(searchTerm),
            300,
        ),
        [],
    );

    const getChangeHandler = (index: number) => (newValue: FilterValue) => {
        const newFilterValues = state.filters.slice();
        newFilterValues.splice(index, 1, newValue);
        actions.setFilters(newFilterValues);
    };

    const getClearHandler = (index: number) => () => {
        const newFilterValues = state.filters.slice();
        const startIdx = index === 0 ? index : index - 1;
        newFilterValues.splice(startIdx, 2);
        actions.setFilters(newFilterValues);
    };

    const [isAiMode, setIsAiMode] = useState<boolean>(false);
    const onSuggestions = (suggestions: FilterValue[]) => {
        actions.setFilters(alternateWithAnd(suggestions));
    };
    return (
        <section className={styles['root']}>
            <div className={styles['root__header']}>
                <div className={styles['root__quick-search-wrapper']}>
                    {isAiMode ? (
                        <SuggestInput
                            onSuggestion={onSuggestions}
                            formClassName={
                                styles['root__suggest-input-wrapper']
                            }
                            inputClassName={styles['root__quick-search']}
                            buttonClassName={
                                styles['root__quick-search__button']
                            }
                        />
                    ) : (
                        <Input
                            wrapperClassName={styles['root__input-wrapper']}
                            className={styles['root__quick-search']}
                            placeholder="Contact name, phone number or cohort"
                            prefix={<Icon name="search" />}
                            value={state.quickSearchTerm}
                            ref={inputRef}
                            onChange={handleSearchInput}
                            autoFocus
                            fullWidth
                        />
                    )}

                    <AIToggle
                        className={styles['root__ai-toggle']}
                        checked={isAiMode}
                        toggle={() => setIsAiMode(!isAiMode)}
                    ></AIToggle>
                </div>

                <Button
                    className={cc([
                        styles['root__filters-btn'],
                        {
                            [styles['root__filters-btn_active']]:
                                state.showFilters,
                        },
                    ])}
                    type="default"
                    icon={<Icon name="filter" size="20px" />}
                    onClick={() => actions.toggleFilters()}
                    size="small"
                    slim
                >
                    {state.filters.length > 0 ? (
                        <span className={styles['root__filter-btn-text']}>
                            Filters
                            <Counter
                                value={Math.floor(state.filters.length / 2) + 1}
                            />
                        </span>
                    ) : (
                        'Filters'
                    )}
                </Button>
            </div>

            {state.showFilters && (
                <div className={styles['root__filters']}>
                    <div className={styles['root__filter-values']}>
                        {state.filters.map((fv, i) => {
                            if (typeof fv === 'string') {
                                return (
                                    <Condition
                                        key={fv}
                                        value={fv}
                                        onChange={getChangeHandler(i)}
                                    />
                                );
                            } else {
                                return (
                                    <UserPropertiesFilterValueDropdown
                                        key={fv.id}
                                        userPropertyFilter={fv}
                                        onChange={getChangeHandler(i)}
                                        onClear={getClearHandler(i)}
                                    />
                                );
                            }
                        })}
                        <AddFilter
                            userProperties={userProperties}
                            onSelect={(up) => {
                                const newFilters =
                                    state.filters.length > 0
                                        ? state.filters.concat([
                                              UserPropertyFilterCondition.AND,
                                              up,
                                          ])
                                        : state.filters.concat(up);
                                actions.setFilters(newFilters);
                            }}
                        />
                    </div>

                    {state.filters.length > 0 && (
                        <div className={styles['root__filter-actions']}>
                            <CloseButton
                                onClick={() => actions.setFilters([])}
                            />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};
