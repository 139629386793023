import cc from 'classcat';
import { ComponentChildren } from 'preact';
import styles from './ButtonsGroup.module.scss';

export interface ButtonsGroupProps {
    className?: string;
    children: ComponentChildren;
}

export const ButtonsGroup = (props: ButtonsGroupProps) => (
    <div className={cc([styles['root'], props.className])}>
        {props.children}
    </div>
);
