import { useState } from 'preact/hooks';
import styles from './RenameCohortModal.module.scss';
import { CohortMeta } from '../../../api/types';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../../elements/Dialog';
import Input from '../../../elements/Inputs/Input';
import Icon from '../../../icons/Icon';
import {
    COHORTS_BY_CONTACT_KEY,
    useCohortUpdateQuery,
} from '../../../queries/cohorts';
import CheckBox from '../../../elements/CheckBox/CheckBox';
import { Button } from '@mui/material';
import { queryClient } from '../../../queries/queryClient';

export interface RenameCohortModalProps {
    cohort: CohortMeta;
    close: () => void;
}

export const RenameCohortModal = (props: RenameCohortModalProps) => {
    const [name, setName] = useState(props.cohort.name);
    const [shared, setShared] = useState(props.cohort.isPublic);
    const { mutateAsync: updateCohort } = useCohortUpdateQuery();

    return (
        <Dialog width="400px">
            <DialogTitle onClose={props.close}>Rename a cohort</DialogTitle>
            <DialogContent>
                <p className={styles['text']}>
                    Current name: <b>{props.cohort.name}</b>
                </p>

                <p className={styles['editor']}>
                    <label className={styles['label']}>New name</label>
                    <Input
                        placeholder="Enter new name"
                        value={name}
                        onChange={setName}
                        fullWidth
                    />
                    <CheckBox
                        checked={shared}
                        onChange={() => setShared(!shared)}
                        label="Public"
                    />
                </p>
            </DialogContent>
            <DialogActions className={styles['actions']}>
                <Button
                    variant="text"
                    onClick={props.close}
                    sx={{ minWidth: 120 }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    startIcon={<Icon name="check" />}
                    disabled={!name}
                    onClick={() =>
                        updateCohort({
                            id: props.cohort.id,
                            name,
                            isPublic: shared,
                        })
                            .then(() => {
                                queryClient.refetchQueries({
                                    queryKey: [COHORTS_BY_CONTACT_KEY],
                                });
                                props.close();
                            })
                            .catch(console.log)
                    }
                    fullWidth
                >
                    <span>Rename</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
