import cc from 'classcat';
import styles from './TeamSelectorItem.module.scss';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { Avatar, Box, Typography } from '@mui/material';
import { avatar, avatarHolder } from './styles';

const DEFAULT_NAME = 'Team';

interface TeamSelectorItemProps {
    teamName?: string;
    avatarUrl?: string;
    isActive: boolean;
    onClick: () => void;
}

export const TeamSelectorItem = ({
    teamName,
    avatarUrl,
    isActive,
    onClick,
}: TeamSelectorItemProps) => {
    return (
        <li
            onClick={onClick}
            className={cc([
                styles['root'],
                {
                    [styles['root_active']]: isActive,
                },
            ])}
        >
            <Box sx={avatarHolder}>
                <Avatar
                    sx={avatar}
                    alt={teamName || DEFAULT_NAME}
                    src={avatarUrl || logo}
                />
            </Box>
            <Typography variant="body3" noWrap>
                {teamName || DEFAULT_NAME}
            </Typography>
        </li>
    );
};
