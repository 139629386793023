import { ComponentChildren } from 'preact';

type SpacerProps = {
    height: number | string;
    width: number | string;
    children?: ComponentChildren;
};

export const Spacer = (props: SpacerProps) => {
    return (
        <div style={{ height: props.height, width: props.width }}>
            {props.children}
        </div>
    );
};
