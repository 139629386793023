import { Box, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useCallback, useState } from 'preact/hooks';
import { memo } from 'preact/compat';
import { ScheduledIcon } from '../../../../icons/common/ScheduledIcon';
import { TimestampChangeDialog } from '../../../Modals/TimestampChangeDialog/TimestampChangeDialog';
import * as styles from './styles';
import { getNearestAlignedCurrentDate } from '../../../Modals/TimestampChangeDialog/utils';
import dayjs from 'dayjs';

type Props = {
    sendAt: Date | null;
    onScheduledSubmit: (dateTime: Date) => Promise<void>;
    disabled?: boolean;
};

export const ScheduledButton = memo(
    ({ sendAt, onScheduledSubmit, disabled = false }: Props) => {
        const [showModal, setShowModal] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const closestTime = getNearestAlignedCurrentDate();
        const date = sendAt ? dayjs(sendAt) : closestTime;

        const handleClose = useCallback(() => {
            setShowModal(false);
        }, []);

        const handleSubmit = async (dateTime: Date) => {
            setIsLoading(true);
            await onScheduledSubmit(dateTime);
            setIsLoading(false);
            handleClose();
        };

        return (
            <>
                <Tooltip title="Schedule">
                    <IconButton
                        disabled={disabled}
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <ScheduledIcon />
                    </IconButton>
                </Tooltip>

                {showModal && (
                    <TimestampChangeDialog
                        disabled={isLoading}
                        timestamp={date?.toDate()}
                        onChange={handleSubmit}
                        onClose={handleClose}
                        minDate={closestTime?.toDate()}
                        title="Schedule a message"
                        open
                    >
                        {isLoading && (
                            <Box sx={styles.loadingSpinner}>
                                <LinearProgress
                                    sx={styles.loadingSpinnerProgress}
                                />
                            </Box>
                        )}
                    </TimestampChangeDialog>
                )}
            </>
        );
    },
);
