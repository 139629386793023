import { Campaign } from '../../api/campaign.types';
import { Conversation } from '../../api/types';
import { Link } from 'preact-router/match';
import CampaignRow from '../CampaignRow';
import styles from './ConversationsList.module.scss';
import { ConversationRow } from '../ConversationRow/ConversationRow';
import { Virtuoso } from 'react-virtuoso';
import { useMemo } from 'preact/hooks';

const isConversation = (item: Campaign | Conversation): item is Conversation =>
    item && 'isLastMessageInbound' in item;

const renderEntity = (entity: Campaign | Conversation) =>
    isConversation(entity) ? (
        <Link
            key={entity.id}
            class={styles['conversations__list-item']}
            activeClassName={styles['conversations__list-item_active']}
            href={`/inbox/${entity.inboxId}/${entity.id}`}
        >
            <ConversationRow conversation={entity} />
        </Link>
    ) : (
        <Link
            key={entity.id}
            class={styles['conversations__list-item']}
            activeClassName={styles['conversations__list-item_active']}
            href={`/inbox/${entity.inboxId}/campaigns/${entity.id}`}
        >
            <CampaignRow campaign={entity} />
        </Link>
    );

export const CampaignConversationList = (props: {
    loadMore: () => void;
    hasMore: boolean | undefined;
    campaigns: Campaign[];
    conversations: Conversation[];
}) => {
    const items: (Campaign | Conversation)[] = useMemo(
        () => [...props.campaigns, ...props.conversations].filter(Boolean),
        [props.campaigns, props.conversations],
    );

    return (
        <Virtuoso
            increaseViewportBy={450}
            overscan={450}
            data={items}
            style={{
                height: 'calc(100vh - 145px)',
            }}
            endReached={() => {
                if (props.hasMore) {
                    props.loadMore();
                }
            }}
            itemContent={(_, entity) => renderEntity(entity)}
        />
    );
};
