import Icon from '../../icons/Icon';
import { Icons } from '../../icons/iconsMap';
import DotLoader from '../DotLoader';
import styles from './FormButton.module.scss';

interface FormButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    text: string;
    iconName?: Icons;
    height?: 32 | 40;
    variant: 'primary' | 'blue' | 'clear' | 'destructive';
    isLoading?: boolean;
}

export const FormButton = (props: FormButtonProps) => {
    const {
        height = 40,
        text,
        iconName,
        isLoading,
        variant: style,
        ...rest
    } = props;
    return (
        <div className={styles['root']}>
            <button
                className={styles['root__button']}
                data-variant={style}
                style={{ height }}
                {...rest}
            >
                {iconName && (
                    <div className={styles['root__icon']}>
                        <Icon name={iconName} />
                    </div>
                )}
                {isLoading ? <DotLoader /> : text}
            </button>
        </div>
    );
};
