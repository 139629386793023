import { ComponentChildren, createContext } from 'preact';
import { useContext, useMemo, useReducer } from 'preact/hooks';
import { ActionCreators, getActionCreators } from './actions';
import {
    DispatchFn,
    InboxReducerAction,
    InboxReducerState,
    initialState,
    reducer,
} from './reducer';

// CONTEXT

export type ActionCreator = (action: InboxReducerAction) => void;

export interface InboxContext {
    state: InboxReducerState;
    actions?: ActionCreators;
    dispatch: DispatchFn;
}

export const Context = createContext<InboxContext>({
    state: initialState,
    dispatch: () => null,
});

// PROVIDER & HOOK

interface InboxProviderProps {
    children: ComponentChildren;
}

const InboxContextProvider = ({ children }: InboxProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = useMemo(() => getActionCreators(dispatch), [dispatch]);

    return (
        <Context.Provider value={{ state, actions, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const useInboxContext = () => useContext(Context);
export default InboxContextProvider;
