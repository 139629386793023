import * as storage from '../utils/local-storage';
import client from './http';
import { Team, TeamForAuth } from './types';
import { AtLeastOne } from '../utils/types';

type AuthResponse = { access_token: string };

export const setAuthToStorage = (data: AuthResponse) => {
    storage.set('auth', data.access_token);
    const { teamId } = storage.parseJwt(data.access_token);
    storage.set('teamId', teamId);
};

export function listTeams() {
    return client()
        .get<Team[]>('/teams')
        .then((res) => res.data);
}

export function listTeamsForAuth() {
    return client()
        .get<TeamForAuth[]>('/teams/for-auth')
        .then((res) => res.data);
}

export function switchTeam(teamId: string): Promise<AuthResponse> {
    return client()
        .post<AuthResponse>('/auth/switchTeam', { teamId })
        .then((res) => res.data);
}

export const updateTeam = ({
    name,
    assistantResponseDelayMinutes,
    assistantInstruction,
    contactsPublicByDefault,
}: AtLeastOne<{
    name: string;
    assistantResponseDelayMinutes: number;
    assistantInstruction: string | null;
    contactsPublicByDefault: boolean;
}>) =>
    client()
        .put('/teams', {
            name,
            assistantInstruction,
            assistantResponseDelayMinutes,
            contactsPublicByDefault,
        })
        .then(({ data }) => data);

export function updateTeamAvatar({ file }: { file: File }) {
    if (file.size > 10000000) {
        throw Error('Image max size exceeded.');
    }

    const formData = new FormData();
    formData.append('file', file);

    return client().post(`/teams/avatar`, formData);
}

export function removeTeam(teamId: string) {
    return client().delete('/teams', { params: { teamId } });
}

export function setupTeam() {
    return client()
        .post('/auth/setupTeam')
        .then((res) => setAuthToStorage(res.data));
}

export function getTeamApiKey() {
    return client()
        .get('/teams/api-key')
        .then((res) => res.data);
}

export function createTeamApiKey() {
    return client()
        .post('/teams/api-key')
        .then((res) => res.data);
}

export function getOrCreateWidgetKey() {
    return client()
        .get<string>('/teams/widget-key')
        .then((res) => res.data);
}
