import { StateUpdater, useState } from 'preact/hooks';
import cc from 'classcat';
import styles from './TwilioPhoneNumber.module.scss';
import StepShell from '../StepShell';
import { SetupWizardStep } from '../types';
import Button from '../../../elements/Buttons';
import TwilioIcon from '../../../assets/icons/carriers/twilio.svg?react';
import TelnyxIcon from '../../../assets/icons/carriers/telnyx.svg?react';
import Radio from '../../../elements/Radio';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { Inbox, InboxProvider } from '../../../api/types';
import { useListChannelsQueryData } from '../../../queries/inboxes';
import { VNode } from 'preact';

export interface CarrierPhoneNumberProps extends SetupWizardStep {
    inbox: Partial<Inbox>;
    setNewInbox: StateUpdater<Partial<Inbox> | undefined>;
    carrier: InboxProvider;
}

const iconMap: Record<InboxProvider, VNode> = {
    [InboxProvider.TWILIO]: <TwilioIcon />,
    [InboxProvider.TELNYX]: <TelnyxIcon />,
    [InboxProvider.BANDWIDTH]: <></>,
    [InboxProvider.MSTEAMS]: <></>,
    [InboxProvider.OTHER]: <></>,
    [InboxProvider.NON_VOIP]: <></>,
};

export const CarrierPhoneNumber = ({
    inbox,
    setNewInbox,
    carrier,
    goBackward,
    goForward,
}: CarrierPhoneNumberProps) => {
    const [selectedPhone, setSelectedPhone] = useState(inbox.phone || '');
    const phones = useListChannelsQueryData(carrier);

    return (
        <StepShell
            title="Carrier setup"
            subtitle="Choose phone number(s) that you’d like to connect to Clerk"
            // @ts-ignore
            icon={iconMap[carrier]}
            goBackward={goBackward}
        >
            <div className={styles['root__content']}>
                {phones.length === 0 && (
                    <p>
                        No Phone numbers found in your account. Are you sure you
                        have the right api key?
                    </p>
                )}
                <ul className={styles['root__phones-list']}>
                    {phones.map((phone) => (
                        <li
                            key={phone}
                            className={cc([
                                styles['root__phone'],
                                {
                                    [styles['root__phone_selected']]:
                                        phone === selectedPhone,
                                },
                            ])}
                            onClick={() => setSelectedPhone(phone)}
                        >
                            {formatPhoneNumber(phone)}
                            <Radio checked={phone === selectedPhone} />
                        </li>
                    ))}
                </ul>

                <Button
                    className={styles['root__continue-btn']}
                    onClick={() => {
                        setNewInbox({
                            phone: selectedPhone,
                            provider: inbox.provider,
                        });
                        goForward();
                    }}
                    disabled={!phones.includes(selectedPhone)}
                    fullWidth
                >
                    Proceed
                </Button>
            </div>
        </StepShell>
    );
};
