import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import * as styles from './styles';

type Props = {
    title: string;
    description?: React.ReactNode;
};
export const ContentHeader = ({ title, description }: Props) => (
    <Box sx={styles.containerHeader}>
        <Box sx={styles.layoutContainer}>
            <Typography variant="h1" mb={2}>
                {title}
            </Typography>
            {description && (
                <Typography variant="subtitle2">{description}</Typography>
            )}
        </Box>
    </Box>
);
