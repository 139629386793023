import dayjs from 'dayjs';
import styles from './ConversationRow.module.scss';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'preact/hooks';
import { useContactsQueryData } from '../../queries/contacts';
import { Contact, Conversation } from '../../api/types';
import { useMarkConversationAsRead } from '../../queries/conversations';
import { useUnreadMessagesForConversation } from '../../queries/messages';
import { useMeQueryData } from '../../queries/user';
import { getContactName } from '../../utils/contacts';
import { Box } from '@mui/material';
import { container } from './styles';
import { useInboxContext } from '../../contexts/InboxContext';
import { useMessageStoppedBy } from './use-message-stopped-by';
import { AssistantPopover } from './Assistant/AssistantPopover';
import { useConversationSubscription } from './use-conversation-subscription';
import { memo } from 'preact/compat';
import { useNow } from './use-now';
import { useUserTyping } from './use-conversation-typing';
import { JSXInternal } from 'preact/src/jsx';
import { MembersAvatar } from './MembersAvatar';
import { RowInfo } from './RowInfo';
import { TextSerializer } from '../MessageInput/serializers/text';
import { JSONSerializer } from '../MessageInput/serializers/json';

const DEFAULT_CONVERSATION_NAME = 'New Conversation';

interface ConversationRowProps {
    conversation: Conversation;
}

export const ConversationRow = memo((props: Readonly<ConversationRowProps>) => {
    useConversationSubscription(props.conversation.id);
    const userTyping = useUserTyping(props.conversation.id);

    const now = useNow({
        interval: 30_000,
        disabled: !props.conversation.assistantTimestamp,
    });
    const { actions, state } = useInboxContext();
    const phrase =
        state.inbox?.assistantEnabled && !!props.conversation.lastMessageBody
            ? useMessageStoppedBy(props.conversation.lastMessageBody)
            : '';
    const parentRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const currentUser = useMeQueryData();
    const contacts = useContactsQueryData();
    const { mutate: markAsRead } = useMarkConversationAsRead();
    const { data: unreadMessages = [] } = useUnreadMessagesForConversation(
        props.conversation.id,
    );

    const { lastMessageBody, members } = props.conversation;

    const memberContacts: Partial<Contact>[] = useMemo(
        () => members.map((member) => contacts.getByPhoneOrEmpty(member)),
        [members, contacts],
    );
    const unread = unreadMessages?.length > 0;

    const savedDraft = window.localStorage.getItem(
        `${props.conversation.id}_draft`,
    );
    const draftMessage = useMemo(() => {
        return TextSerializer.serialize(
            JSONSerializer.deserialize(savedDraft ?? ''),
        );
    }, [savedDraft]);

    useEffect(() => {
        if (window.localStorage.getItem(`${props.conversation.id}_draft`)) {
            actions?.addHasDraftMessage(props.conversation.id);
        }
    }, []);

    const timestampDisplay = useMemo(() => {
        const timestamp = dayjs(
            props.conversation.lastMessageSent ?? props.conversation.created,
        );
        return timestamp.diff(new Date(), 'days') < 0
            ? timestamp.format('MMM DD')
            : timestamp.format('hh:mm');
    }, [props.conversation.lastMessageSent, props.conversation.created]);

    const isAssistantReply = useMemo(() => {
        const isScheduled =
            !!props.conversation.assistantMessageId &&
            dayjs(props.conversation.assistantTimestamp).diff(now, 'seconds') >=
                30;
        return (
            props.conversation.isLastMessageInbound &&
            (isScheduled || !!phrase) &&
            !userTyping
        );
    }, [
        props.conversation.isLastMessageInbound &&
            props.conversation.assistantMessageId &&
            props.conversation.assistantTimestamp &&
            now,
        phrase,
    ]);

    const recipientDisplay = useMemo(
        () =>
            memberContacts.length > 0
                ? memberContacts
                      .map((contact) =>
                          getContactName(
                              contact,
                              currentUser?.activeTeam.countryCode,
                          ),
                      )
                      .join(', ')
                : DEFAULT_CONVERSATION_NAME,
        [memberContacts, currentUser?.activeTeam.countryCode],
    );

    const handlePopoverOpen = useCallback(
        (event: JSXInternal.TargetedMouseEvent<HTMLElement>) => {
            if (isAssistantReply && anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
            }
        },
        [isAssistantReply],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback(() => {
        handleClose();
        if (unread) {
            markAsRead(props.conversation.id);
        }
    }, [unread, props.conversation.id]);

    return (
        <Box
            className={styles.conversation}
            sx={container}
            onClick={handleClick}
            ref={parentRef}
            aria-owns={
                open
                    ? `inbox-message-popover-${props.conversation.id}`
                    : undefined
            }
            aria-haspopup="true"
            role="button"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handleClose}
        >
            {unread && <span className={styles.unreadCounter}></span>}

            <MembersAvatar
                memberContacts={memberContacts}
                invisible={!isAssistantReply}
                phrase={phrase}
            />

            <RowInfo
                recipientDisplay={recipientDisplay}
                timestampDisplay={timestampDisplay}
                unread={unread}
                isAssistantReply={isAssistantReply}
                phrase={phrase}
                draftMessage={draftMessage}
                lastMessageBody={lastMessageBody}
                userTyping={userTyping}
                conversation={props.conversation}
            />
            {isAssistantReply && (
                <AssistantPopover
                    conversation={props.conversation}
                    open={isAssistantReply && open}
                    anchorEl={anchorEl}
                />
            )}
        </Box>
    );
});
