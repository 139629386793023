import { useEffect, useRef, useState } from 'preact/hooks';
import { forwardRef } from 'preact/compat';
import cc from 'classcat';
import DatePicker from 'react-datepicker';
// styles
import styles from './FilterDateValue.module.scss';
import '../../../../assets/styles/react-datepicker-theme.scss';
// components
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
import Radio from '../../../../elements/Radio';
// types
import { InputProps } from '../../../../types/ElementsProps';
import {
    DateTypeValue,
    UserPropertyFilterModel,
} from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Fragment } from 'preact';

const RELATIVE_LIST = [
    { constraintType: DateTypeValue.MoreThan, label: 'More than' },
    { constraintType: DateTypeValue.Exactly, label: 'Exactly' },
    { constraintType: DateTypeValue.LessThan, label: 'Less than' },
    { constraintType: DateTypeValue.Period, label: 'Period' },
];

const ABSOLUTE_LIST = [
    { constraintType: DateTypeValue.After, label: 'After' },
    { constraintType: DateTypeValue.On, label: 'On' },
    { constraintType: DateTypeValue.Before, label: 'Before' },
    { constraintType: DateTypeValue.Exists, label: 'Exists' },
];

type SelectedValue = [Date, Date] | Date | string;

interface FilterDateValueProps {
    userPropertyFilter: UserPropertyFilterModel<SelectedValue>;
    changeHandler: (value: UserPropertyFilterModel<SelectedValue>) => void;
    closeHandler: () => void;
}

export const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    ({ value, onClick }, ref) => (
        <Input
            wrapperClassName={styles['filter__date-picker-input-wrapper']}
            className={styles['filter__input']}
            onClick={onClick}
            value={value}
            customRef={ref}
            fullWidth
            readonly
            uncontrolled
        />
    ),
);

export const FilterDateValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterDateValueProps) => {
    const { value, constraint } = userPropertyFilter;

    const [selectedType, setSelectedType] = useState(
        constraint as DateTypeValue,
    );
    const [selectedValue, setSelectedValue] = useState(value);
    const dropdownContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedType && selectedValue) {
            changeHandler({
                ...userPropertyFilter,
                constraint: selectedType,
                value: selectedValue,
            });
        }
    }, [selectedType, selectedValue]);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    const startDate = Array.isArray(selectedValue) && selectedValue[0];
    const endDate = Array.isArray(selectedValue) && selectedValue[1];

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div
                className={cc([
                    styles['filter__top-panel'],
                    styles['filter__top-panel_rounded'],
                ])}
            >
                Relative:
            </div>

            <DropdownList>
                {RELATIVE_LIST.map(({ constraintType, label }) => (
                    <Fragment key={constraintType}>
                        <DropdownItem
                            variant="list-item"
                            type="regular"
                            onClick={() => {
                                setSelectedType(constraintType);
                                setSelectedValue(undefined);
                            }}
                        >
                            <Radio checked={constraintType === selectedType} />
                            <span className={styles['filter__item-name']}>
                                {label}
                            </span>
                        </DropdownItem>
                        {constraintType === selectedType &&
                            constraintType !== DateTypeValue.Period && (
                                <li className={styles['filter__value-item']}>
                                    <Input
                                        wrapperClassName={
                                            styles['filter__input-wrapper']
                                        }
                                        className={styles['filter__input']}
                                        value={selectedValue as string}
                                        onChange={setSelectedValue}
                                    />
                                    <span
                                        className={
                                            styles['filter__value-item-desc']
                                        }
                                    >
                                        Days ago
                                    </span>
                                </li>
                            )}
                        {constraintType === selectedType &&
                            constraintType === DateTypeValue.Period && (
                                <li>
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        selected={startDate || new Date()}
                                        startDate={startDate}
                                        endDate={endDate}
                                        customInput={<DatePickerCustomInput />}
                                        onChange={setSelectedValue}
                                        selectsRange
                                    />
                                </li>
                            )}
                    </Fragment>
                ))}
            </DropdownList>

            <div className={styles['filter__top-panel']}>Absolute:</div>

            <DropdownList className={styles['filter__list']}>
                {ABSOLUTE_LIST.map(({ constraintType, label }, i) => (
                    <Fragment key={constraintType}>
                        <DropdownItem
                            variant="list-item"
                            type="regular"
                            className={cc([
                                {
                                    [styles['filter__item_last']]:
                                        i === ABSOLUTE_LIST.length - 1 &&
                                        !(
                                            constraintType === selectedType &&
                                            constraintType !==
                                                DateTypeValue.Exists
                                        ),
                                },
                            ])}
                            onClick={() => {
                                setSelectedType(constraintType);
                                setSelectedValue('');
                            }}
                        >
                            <Radio checked={constraintType === selectedType} />
                            <span className={styles['filter__item-name']}>
                                {label}
                            </span>
                        </DropdownItem>
                        {constraintType === selectedType &&
                            constraintType !== DateTypeValue.Exists && (
                                <li
                                    className={cc([
                                        {
                                            [styles['filter__item_last']]:
                                                i === ABSOLUTE_LIST.length - 1,
                                        },
                                    ])}
                                >
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        selected={selectedValue || new Date()}
                                        customInput={<DatePickerCustomInput />}
                                        onChange={setSelectedValue}
                                    />
                                </li>
                            )}
                    </Fragment>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
