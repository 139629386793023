import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const OkIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                d="M7.99992 1.33333C4.31992 1.33333 1.33325 4.32 1.33325 8C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8C14.6666 4.32 11.6799 1.33333 7.99992 1.33333ZM6.66659 11.3333L3.33325 8L4.27325 7.06L6.66659 9.44667L11.7266 4.38667L12.6666 5.33333L6.66659 11.3333Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
