import { UUID } from '../types/uuid';
import client from './http';
import { Message, Profile } from './types';

export interface MessageToSend {
    from: string;
    to: string[];
    body: string;
    inboxId: string;
    user: Profile;
    conversationId: string;
    /** @deprecated use attachments */
    fileIds?: string[];
    attachments?: UUID[];
}

export function getMessages(conversationId: string, timestamp?: number) {
    return client()
        .get<Message[]>(`/messages/conversation/${conversationId}`, {
            params: { timestamp },
        })
        .then(({ data }) => data);
}

export function sendMessage({ body, conversationId, fileIds }: MessageToSend) {
    const payload = {
        body,
        conversationId,
        fileIds,
    };
    return client()
        .post('/conversations/send', payload)
        .then((r) => r.data);
}

export function deleteMessage({ messageId }: { messageId: number }) {
    return client()
        .delete(`/messages/${messageId}`)
        .then((r) => r.data);
}

export const getMessage = (id: number) =>
    client()
        .get<Message>(`/messages/${id}`)
        .then(({ data }) => data);
