import { useMemo } from 'preact/hooks';
import Loading from '../../../components/Loading/Loading';
import PhonesScrollList from '../../../components/PhoneScrollList';
import Icon from '../../../icons/Icon';
import { useInboxesQueryData } from '../../../queries/inboxes';
import { useGetMicrosoftTeamsPhones } from '../../../queries/integrations';
import StepShell from '../StepShell';
import { MSTeamsPhoneNumbersProps } from './MSTeamsPhoneNumbers';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { Box, Button, Typography } from '@mui/material';
import { resetMicrosoftGraphCredentials } from '../../../api/user';
import { route } from 'preact-router';

export const MSTeamsPhoneSelectionStep = (props: MSTeamsPhoneNumbersProps) => {
    const inboxes = useInboxesQueryData() || [];
    const { data = { phones: [] }, isPending } = useGetMicrosoftTeamsPhones();
    const { dispatch } = useAnalytics();

    const availablePhones = useMemo(
        () =>
            data.phones.filter((phone) => {
                return !inboxes.find((inbox) => inbox.phone === phone.phone);
            }),
        [inboxes, data],
    );

    return (
        <StepShell
            title="Select Phones to Activate SMS"
            icon={<Icon name="microsoft-teams-logo" />}
            subtitle="Select one or more phone numbers"
        >
            {isPending ? (
                <Loading />
            ) : (
                <>
                    <PhonesScrollList
                        phones={availablePhones}
                        selected={props.selectedPhones!}
                        onSelect={props.setSelectedPhones!}
                    />
                    <Button
                        onClick={() => {
                            dispatch({
                                type: AnalyticsActions.TRACK,
                                payload: {
                                    eventName:
                                        AnalyticsEventName.CLICKED_ADD_TEAMS_PHONES,
                                    eventPayload: {
                                        phones: props.selectedPhones,
                                    },
                                },
                            });
                            props.goForward();
                        }}
                        disabled={props.selectedPhones!.length === 0}
                        fullWidth
                    >
                        Add {props.selectedPhones!.length} Phones
                    </Button>
                    <Box py={6} textAlign="center">
                        <Typography variant="body4" align="center">
                            Or
                        </Typography>
                    </Box>
                    <Button
                        startIcon={<Icon name="microsoft-teams-logo" />}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            resetMicrosoftGraphCredentials().then(() => {
                                route('/setup-inbox/ms-teams/auth');
                            });
                        }}
                    >
                        Use a different Microsoft Account
                    </Button>
                </>
            )}
        </StepShell>
    );
};
