import { Box } from '@mui/material';
import { AttentionFilled } from '../../icons/common/CAttentionFilled';
import { attentionIconHolder, Colors } from './styles';

export const DialogAttentionIcon = ({ color = 'error' }: { color: Colors }) => (
    <Box
        role="presentation"
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Box sx={attentionIconHolder(color)}>
            <AttentionFilled sx={{ width: 22, height: 22 }} />
        </Box>
    </Box>
);
