import cc from 'classcat';
import { useRef, useState } from 'preact/hooks';
import { PersonSuggestion } from './PersonSuggestion';
import currentStyles from './PersonValue.module.scss';
import generalStyles from '../values.module.scss';
import { UserPropertyModel, ValueProps } from '../../types';
import { AvatarWithUsername } from '../../../Avatar/AvatarWithUsername';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { useTeammates } from '../../../../queries/user';
import { Profile } from '../../../../api/types';
import { userName } from '../../../../helpers/formatting';

const styles = { ...generalStyles, ...currentStyles };

type PersonValueProps = ValueProps<string>;

const getLabel = (
    property: UserPropertyModel<string>,
    user: Profile | undefined,
) => {
    if (user) {
        return userName(user);
    }
    return '';
};

export const PersonValue = ({
    userProperty,
    readonly,
    onChange,
}: PersonValueProps) => {
    const [isEditEnabled, setEditEnabled] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(selectRef, () => setEditEnabled(false));

    const { data: teammates = [] } = useTeammates();
    const user = teammates.find((u) => u.id === userProperty.value);

    const label = getLabel(userProperty, user);

    if (isEditEnabled) {
        return (
            <PersonSuggestion
                options={teammates}
                wrapperRef={selectRef}
                onChange={(userId) => {
                    onChange?.({ ...userProperty, value: userId! });
                    setEditEnabled(false);
                }}
            />
        );
    }

    return (
        <div
            className={cc([styles['value'], styles['limited-value']])}
            onClick={() => !readonly && setEditEnabled(true)}
        >
            {label ? (
                <AvatarWithUsername
                    size="small"
                    username={label}
                    imageUrl={user?.avatar?.url}
                />
            ) : (
                <span className={styles['value__empty']}>+ Set a value</span>
            )}
        </div>
    );
};
