import { VNode } from 'preact';
import type { CountryCode } from 'libphonenumber-js';
import Icon from '../../icons/Icon';

export type Option = {
    id: CountryCode | 'other';
    label: string;
    icon: VNode;
    code: string;
    disabled?: boolean;
};

export const options: Option[] = [
    { id: 'CA', label: 'Canada', icon: <Icon name="CA" />, code: '+1' },
    { id: 'US', label: 'USA', icon: <Icon name="US" />, code: '+1' },
    {
        id: 'GB',
        label: 'United Kingdom',
        icon: <Icon name="GB" />,
        code: '+44',
        disabled: true,
    },
    {
        id: 'AU',
        label: 'Australia',
        icon: <Icon name="AU" />,
        code: '+61',
        disabled: true,
    },
    {
        id: 'DE',
        label: 'Germany',
        icon: <Icon name="DE" />,
        code: '+49',
        disabled: true,
    },
    {
        id: 'BR',
        label: 'Brazil',
        icon: <Icon name="BR" />,
        code: '+55',
        disabled: true,
    },
    {
        id: 'MX',
        label: 'Mexico',
        icon: <Icon name="MX" />,
        code: '+52',
        disabled: true,
    },
    {
        id: 'other',
        label: 'Other',
        icon: <Icon name="other-country" />,
        code: '',
        disabled: true,
    },
];
