import { TargetedEvent } from 'preact/compat';
import { useRef } from 'preact/hooks';
import styles from './LoginForm.module.scss';
import Icon from '../../icons/Icon';
import { useSendOTP } from '../../queries/auth';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { ButtonDotLoader } from './ButtonDotLoader';

export interface EmailStepProps {
    email: string;
    setEmail: (email: string) => void;
    next: () => void;
    setMethodId: (methodId: string) => void;
}

export const EmailStep = ({
    email,
    next,
    setEmail,
    setMethodId,
}: EmailStepProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutateAsync, isPending, isError } = useSendOTP();

    const handleSubmit = (e: TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email) {
            inputRef.current?.focus();
        }
        mutateAsync(email)
            .then(({ email_id }) => setMethodId(email_id))
            .then(next)
            .catch(console.log);
    };

    return (
        <form className={styles['email-step']} onSubmit={handleSubmit}>
            <Box mb={6}>
                <TextField
                    name="token"
                    color="blue"
                    error={isError}
                    value={email}
                    onChange={(event) => setEmail(event.target.value ?? '')}
                    InputProps={{
                        'aria-label': 'Email',
                        ref: inputRef,
                        placeholder: 'Email address',
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon name="email-2" />
                            </InputAdornment>
                        ),
                    }}
                    helperText={isError ? 'Please try again' : ''}
                    fullWidth
                    autoFocus
                />
            </Box>

            <Button fullWidth disabled={isPending || !email} type="submit">
                {isPending && <ButtonDotLoader />}
                {!isPending && 'Proceed'}
            </Button>
        </form>
    );
};

export default EmailStep;
