import styles from './ExportLogs.module.scss';
import DatePicker from 'react-datepicker';
import Button from '../../../elements/Buttons';
import AdvancedSelect, {
    OptionBase,
    OptionId,
} from '../../../elements/AdvancedSelect';
import { useMemo, useState } from 'preact/hooks';
import { useInboxes, useInboxExport } from '../../../queries/inboxes';
import { uniqBy } from 'lodash';
import { forwardRef, TargetedEvent } from 'preact/compat';
import Input from '../../../elements/Inputs/Input';
import { InputProps } from '../../../types/ElementsProps';
import FormControl from '../../../elements/FormControl';
import Icon from '../../../icons/Icon';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';

const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => (
        <Input
            onClick={props.onClick}
            value={props.value}
            customRef={ref}
            prefix={<Icon name="calendar" />}
            fullWidth
            readonly
            uncontrolled
        />
    ),
);

export const ExportLogs = () => {
    const [selectedInboxes, setSelectedInboxes] = useState<OptionBase[]>([]);
    const [message, setMessage] = useState<string>('');
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        null,
        null,
    ]);

    const { dispatch } = useAnalytics();

    // Data
    const { data: inboxes = [] } = useInboxes();
    const options = useMemo(
        () =>
            inboxes?.map((i) => ({ id: i.id, label: i.name || i.phone })) || [],
        [inboxes],
    );

    // Mutations
    const { mutateAsync: exportData, isPending: exportDataLoading } =
        useInboxExport();

    const onChange = (i: OptionId) => {
        const inbox = options.find((inbox) => inbox.id === i);
        const existing = selectedInboxes.find((o) => o.id === i);

        if (!existing && inbox) {
            setSelectedInboxes((is) => uniqBy([...is, inbox], (o) => o.id));
        } else {
            setSelectedInboxes((is) =>
                uniqBy(
                    is.filter((b) => b.id != i),
                    (o) => o.id,
                ),
            );
        }
    };

    const onSubmit = (e: TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: AnalyticsEventName.CLICKED_EXPORT_LOGS,
            },
        });

        if (dateRange[0] && dateRange[1]) {
            exportData({
                startDate: dateRange[0],
                endDate: dateRange[1],
                inboxIds: selectedInboxes.map((i) => i.id),
            })
                .then((blob) => {
                    const file = window.URL.createObjectURL(blob);
                    window.location.assign(file);
                })
                .catch(() =>
                    setMessage(
                        'There was an error, please contact Clerk Support',
                    ),
                );
        }
    };

    const btnDisabled =
        exportDataLoading ||
        !dateRange[0] ||
        !dateRange[1] ||
        selectedInboxes.length < 1;

    return (
        <SettingsLayout
            title="Logs"
            description="Export any data from any inboxes"
        >
            <section className={styles['root']}>
                <header className={styles['root__header']}>
                    Select one or several inboxes and download (export) all of
                    the conversations. Clerk will generate a .csv file.
                </header>

                <form onSubmit={onSubmit}>
                    <FormControl>
                        <AdvancedSelect
                            placeholder="Select inboxes"
                            options={options}
                            value={selectedInboxes.map((i) => i.id)}
                            onChange={onChange}
                            multiselect
                        />
                    </FormControl>

                    <FormControl>
                        <Button
                            slim
                            type="default"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedInboxes(
                                    inboxes.map((i) => ({
                                        id: i.id,
                                        label: i.name || i.phone,
                                    })),
                                );
                            }}
                        >
                            Select All Inboxes
                        </Button>
                    </FormControl>

                    <FormControl className={styles['root__last-control']}>
                        <DatePicker
                            dateFormat="MMM d, yyyy"
                            customInput={<DatePickerCustomInput />}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            onChange={(dates) => setDateRange(dates)}
                            selectsRange
                        />
                    </FormControl>
                    <Button
                        disabled={btnDisabled}
                        icon={
                            <Icon
                                className={styles['root__download-icon']}
                                name="file-download"
                            />
                        }
                    >
                        <span>Download .csv</span>
                    </Button>
                    <p className={styles['root__error']}>
                        {message.length > 0 && <p>{message}</p>}
                    </p>
                </form>
            </section>
        </SettingsLayout>
    );
};
