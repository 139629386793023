import { Box } from '@mui/material';
import { popoverContent } from '../styles';
import { AiAssistantIcon } from './AiAssistantIcon';
import { memo } from 'preact/compat';

export const PopoverHeader = memo(
    ({ isStopped = false }: { isStopped: boolean }) => (
        <Box sx={popoverContent} typography="body3" color="secondary.main">
            <AiAssistantIcon fontSize="small" />
            {isStopped ? 'Assistant stop-words detected ' : 'Assistant'}
        </Box>
    ),
);
