import { route } from 'preact-router';
import styles from './LoginForm.module.scss';
import Icon from '../../icons/Icon';
import Loading from '../Loading/Loading';
import {
    useListTeamsForAuth,
    useSetupTeamQuery,
    useSwitchTeamQuery,
} from '../../queries/team';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import Button from '../../elements/Buttons';
import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';

const getMembersCountText = (length: number) => {
    if (length === 0) {
        return 'No members yet';
    }
    if (length === 1) {
        return '1 member';
    }
    return `${length} members`;
};

export interface TeamStepProps {
    nextPage: string | null;
}

export const TeamStep = ({ nextPage }: TeamStepProps) => {
    const {
        data: teams = [],
        isFetching,
        isSuccess,
        isError,
    } = useListTeamsForAuth();
    const { mutateAsync: switchTeam } = useSwitchTeamQuery();
    const { mutateAsync: setupTeam } = useSetupTeamQuery();

    if ((isSuccess && teams.length === 0) || isError) {
        return (
            <>
                <div className={styles['team-step-empty']}>
                    <span className={styles['team-step-empty__icon']}>🧐</span>
                    <div className={styles['team-step-empty__extra']}>
                        <p className={styles['team-step-empty__text']}>
                            Don&apos;t see your team?
                        </p>
                        <p className={styles['team-step-empty__subtext']}>
                            Ask one of your teammates to invite you
                        </p>
                    </div>
                </div>
                <>
                    <div className={styles['root__divider']}>
                        <span>OR</span>
                    </div>
                    <Button
                        type="primary"
                        onClick={() =>
                            setupTeam()
                                .then(() => route(nextPage || '/'))
                                .catch(console.log)
                        }
                        fullWidth
                    >
                        Continue with a new workspace
                    </Button>
                </>
            </>
        );
    }

    return (
        <ul className={styles['team-step']}>
            {isFetching && <Loading />}

            {!isFetching &&
                teams.map((team) => (
                    <li key={team.id} className={styles['team-step__item']}>
                        <button
                            className={styles['team-step__item-btn']}
                            onClick={() => {
                                switchTeam(team.id)
                                    .then((data) => {
                                        setAuthToStorage(data);
                                        queryClient
                                            .refetchQueries()
                                            .then(() => {
                                                route(nextPage || '/');
                                            });
                                    })
                                    .catch(console.log);
                            }}
                        >
                            <div className={styles['team-step__avatar']}>
                                <img
                                    src={team.teamAvatarURL || logo}
                                    alt="avatar"
                                />
                            </div>
                            <div className={styles['team-step__team-info']}>
                                <p className={styles['team-step__team-name']}>
                                    {team.name || 'Team'}
                                </p>
                                <p
                                    className={
                                        styles['team-step__team-members']
                                    }
                                >
                                    {getMembersCountText(team.members)}
                                </p>
                            </div>
                            <Icon
                                className={styles['team-step__select-icon']}
                                name="chevron-right"
                            />
                        </button>
                    </li>
                ))}
        </ul>
    );
};

export default TeamStep;
