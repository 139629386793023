import cc from 'classcat';
import { route } from 'preact-router';
import { useEffect, useMemo } from 'preact/hooks';
import { Contact } from '../../api/types';
import Chat from '../../components/Chat';
import { CampaignConversationsList } from '../../components/ConversationsList';
import { useContactsQueryData } from '../../queries/contacts';
import { useGetConversation } from '../../queries/conversations';
import styles from './Campaign.module.scss';
import { Campaign } from '../../api/campaign.types';

const isDisabled = (contacts: Contact[]) =>
    contacts.length === 1 && contacts[0].optOut;

interface CampaignInnerProps {
    campaign?: Campaign;
    conversationId: string;
}

export const CampaignInner = ({
    campaign,
    conversationId,
}: CampaignInnerProps) => {
    const { data: conversation } = useGetConversation(conversationId);
    const contacts = useContactsQueryData();

    const memberContacts = useMemo(() => {
        return conversation?.members
            ? (conversation?.members.map((member) =>
                  contacts.getByPhoneOrEmpty(member),
              ) as Contact[])
            : [];
    }, [conversation]);

    useEffect(() => {
        if (conversation && conversation.status === 'deleted') {
            route(`/campaigns/${campaign?.id}`);
        }
    }, [conversation, conversationId]);

    return (
        <div className={styles['campaigns']}>
            <div
                className={cc([
                    {
                        [styles['campaigns_mobile-hidden']]: !!conversation,
                    },
                ])}
            >
                <CampaignConversationsList campaign={campaign} />
            </div>

            <section
                className={cc([
                    styles['chat'],
                    {
                        [styles['chat_empty']]: !conversation,
                        [styles['campaigns_mobile-hidden']]: !conversation,
                    },
                ])}
            >
                {conversationId && conversation && (
                    <Chat
                        conversation={conversation}
                        onBackClick={() => route(`/campaigns/${campaign?.id}`)}
                        messageFormDisabled={isDisabled(memberContacts)}
                    />
                )}
                {!conversationId && (
                    <p className={styles['campaigns__chat-placeholder']}>
                        Choose chat to start conversation
                    </p>
                )}
            </section>
        </div>
    );
};
