import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
import { Plus, TrashSimple, PencilSimple } from 'phosphor-react';
import Button from '../../elements/Buttons';
import {
    useMessageTemplateRemoveQuery,
    useMessageTemplatesQuery,
} from '../../queries/message-templates';
import Loading from '../Loading/Loading';
import styles from './MessageTemplates.module.scss';
import { MessageTemplate } from '../../api/types';

export type MessageTemplatesListProps = {
    onCreateClick: () => void;
    onSelect: (text: string) => void;
    onEdit: (template: MessageTemplate) => void;
    withProperties: boolean;
};

export const MessageTemplatesList = ({
    onCreateClick,
    onSelect,
    onEdit,
    withProperties,
}: MessageTemplatesListProps) => {
    const [search, setSearch] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { templates, isPending } = useMessageTemplatesQuery({
        withProperties,
    });
    const { mutate: remove } = useMessageTemplateRemoveQuery();

    useEffect(() => {
        searchInputRef.current?.focus();
    }, [templates]);

    const filteredTemplates = useMemo(() => {
        if (search) {
            const lowerSearch = search.toLowerCase();
            return templates.filter(
                (t) =>
                    t.name.toLowerCase().includes(lowerSearch) ||
                    t.body.toLowerCase().includes(lowerSearch),
            );
        } else {
            return templates;
        }
    }, [templates, search]);

    if (isPending) {
        return <Loading />;
    }

    return (
        <>
            <header className={styles['tempaltes__header']}>
                <h6 className={styles['tempaltes__title']}>Snippets</h6>
                <Button
                    type="text"
                    icon={<Plus size={20} />}
                    onClick={onCreateClick}
                >
                    Create snippet
                </Button>
            </header>
            <input
                className={cc([
                    styles['tempaltes__input'],
                    styles['tempaltes__search-input'],
                ])}
                placeholder="Search"
                ref={searchInputRef}
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
            />

            <ul className={styles['tempaltes__list']}>
                {filteredTemplates.map((template) => (
                    <li
                        key={template.id}
                        className={styles['tempaltes__list-item']}
                        onClick={() => onSelect(template.body)}
                    >
                        <div className={styles['tempaltes__template']}>
                            <p className={styles['tempaltes__name']}>
                                {template.name}
                            </p>
                            <p className={styles['tempaltes__body']}>
                                {template.body}
                            </p>
                        </div>
                        {!template.userId && (
                            <div className={styles['tempaltes__chip']}>
                                <span
                                    className={styles['tempaltes__chip-inner']}
                                >
                                    Team
                                </span>
                            </div>
                        )}
                        <div className={styles['tempaltes__actions']}>
                            <button
                                className={styles['tempaltes__action-button']}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit(template);
                                }}
                            >
                                <PencilSimple
                                    size={20}
                                    weight="bold"
                                    className={styles['tempaltes__action-icon']}
                                />
                            </button>
                            <button
                                className={styles['tempaltes__action-button']}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    remove(template.id);
                                }}
                            >
                                <TrashSimple
                                    size={20}
                                    weight="bold"
                                    className={styles['tempaltes__action-icon']}
                                />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};
