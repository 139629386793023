import { ComponentChildren } from 'preact';
import ClerkLogo from '../../elements/IconComponents/clerk-logo.svg?react';
import styles from './AuthLayout.module.scss';

export type AuthLayoutProps = {
    children: ComponentChildren;
};

export const AuthLayout = (props: AuthLayoutProps) => (
    <section className={styles['root']}>
        <main className={styles['root__main']}>
            <div className={styles['root__background-1']} />
            <div className={styles['root__background-2']} />
            <ClerkLogo className={styles['root__logo']} />

            <div className={styles['root__content']}>{props.children}</div>

            <div className={styles['root__footer']}>
                <p className={styles['root__tos']}>
                    By proceeding you acknowledge that you have read, understood
                    and agree to our{' '}
                    <a
                        href="https://clerk.chat/legal/terms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms and Conditions.
                    </a>
                </p>
                <div className={styles['root__footer-extra']}>
                    <span>© 2023 Clerk Chat</span>
                    <a
                        href="https://clerk.chat/legal/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="https://support.clerk.chat"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Support
                    </a>
                </div>
            </div>
        </main>
        <aside className={styles['root__aside']}>
            <div className={styles['root__feedback']}>
                <p className={styles['root__feedback-text']}>
                    &quot;Clerk is our prefered SMS solution. We absolutely love
                    it!&quot;
                </p>

                <div className={styles['root__feedback-data']}>
                    <div>
                        <p className={styles['root__feedback-person']}>
                            Cristina Lorez
                        </p>
                        <p className={styles['root__feedback-role']}>
                            Microsoft Teams
                        </p>
                    </div>
                </div>
            </div>
        </aside>
    </section>
);
