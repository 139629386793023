import cc from 'classcat';
import styles from './Avatar.module.scss';
import { AvatarPlaceholder } from './AvatarPlaceholder';
import { TargetedEvent } from 'preact/compat';
import { acceptImageFormats } from '../../constants';

const ALT_TAG = 'avatar image';

export type AvatarProps = {
    className?: string;
    username?: string;
    imageUrl?: string;
    hideInitials?: boolean;
    size: 'small' | 'medium' | 'large' | 'extra-large';
    editable?: boolean;
    uploadImage?: ({ file }: { file: File }) => void;
};

/**
 * @deprecated use UserAvatar instead
 */
function Avatar(props: AvatarProps) {
    const avatarClassName = cc([
        styles['clerk-avatar'],
        props.className,
        {
            [styles['clerk-avatar_size-sm']]: props.size === 'small',
            [styles['clerk-avatar_size-md']]: props.size === 'medium',
            [styles['clerk-avatar_size-lg']]: props.size === 'large',
            [styles['clerk-avatar_size-ex-lg']]: props.size === 'extra-large',
        },
    ]);

    function handleContactImage(event: TargetedEvent<HTMLInputElement>) {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        if (file) {
            return props.uploadImage!({ file });
        }
    }

    return (
        <div className={styles['clerk-avatar__wrapper']}>
            <div className={styles['clerk-avatar__img']}>
                <figure className={avatarClassName}>
                    {props.imageUrl ? (
                        <img src={props.imageUrl} alt={ALT_TAG} />
                    ) : (
                        <AvatarPlaceholder
                            username={props.username}
                            hideInitials={props.hideInitials}
                        />
                    )}
                    {props.uploadImage && props.editable && (
                        <input
                            type="file"
                            accept={acceptImageFormats}
                            onChange={handleContactImage}
                            className={styles['clerk-avatar__file']}
                        />
                    )}
                </figure>
            </div>
        </div>
    );
}

export default Avatar;
