import MessageForm, { MessageFormData } from '../MessageForm';
import { useToastContext } from '../../containers/toast/reducer';
import { MessageToSend } from '../../api/messages';
import { useAnalytics } from '../../contexts/analytics';

import { AnalyticsEventName } from '../../types/AnalyticsEventNames';
import {
    useScheduledMessageCreateQuery,
    useScheduledMessageUpdateQuery,
} from '../../queries/scheduled-messages';
import { Conversation, Message, ScheduledMessage } from '../../api/types';
import { useMeQueryData } from '../../queries/user';
import { handleError } from './utils';
import { useInboxContext } from '../../contexts/InboxContext';
import { useMessageCreate, useUpdateMessage } from './message.query';
import { useCampaignV3Enabled } from '../../helpers/use-campaign-v3-enabled';
import { isEmpty } from 'lodash';

export type ConversationMessageFormProps = {
    conversation: Conversation;
    smForEdit?: ScheduledMessage | null;
    messageForEdit?: Message | null;
    onEditCancel?: () => void;
    disabled?: boolean;
};

export function ConversationMessageForm({
    conversation,
    smForEdit,
    disabled,
    messageForEdit,
    onEditCancel,
}: ConversationMessageFormProps) {
    const { state } = useInboxContext();
    const { dispatch: AnalyticsDispatch } = useAnalytics();
    const { dispatch: toastDispatch } = useToastContext();
    const me = useMeQueryData();
    const mutation = useUpdateMessage();
    const creation = useMessageCreate();
    const { mutateAsync: createScheduledMessage } =
        useScheduledMessageCreateQuery(conversation.id);
    const { mutateAsync: updateScheduledMessage } =
        useScheduledMessageUpdateQuery(conversation.id);

    const isV3Enabled = useCampaignV3Enabled();
    const send = (message: MessageToSend) => {
        return creation
            .mutateAsync({
                conversationId: conversation.id,
                body: message.body,
                attachments: message.attachments,
            })
            .catch(handleError(toastDispatch));
    };

    const submitForm = ({
        message,
        sendAt,
        files,
        attachments,
    }: MessageFormData) => {
        const inbox = state.inbox;
        const to = conversation?.members;
        const from = conversation?.inbox?.phone || inbox?.phone;

        if (
            isEmpty(conversation?.members) ||
            !to ||
            (isEmpty(message.trim()) && isEmpty(files) && isEmpty(attachments))
        ) {
            return Promise.reject();
        }

        AnalyticsDispatch({
            payload: {
                eventName: AnalyticsEventName.USER_SENT_MESSAGE,
                eventPayload: {
                    bodyLength: message.length,
                    conversationId: conversation.id,
                    inboxId: conversation?.inbox?.id || inbox?.id,
                    userId: me?.id,
                    email: me?.email,
                },
            },
        });

        if (messageForEdit) {
            return mutation
                .mutateAsync({
                    id: messageForEdit.id,
                    message: {
                        body: message,
                        conversationId: conversation.id,
                    },
                })
                .then(() => onEditCancel?.())
                .catch(handleError(toastDispatch));
        }

        if (smForEdit) {
            return updateScheduledMessage({
                ...smForEdit,
                sendAt: sendAt || smForEdit.sendAt,
                body: message,
            })
                .then(() => onEditCancel?.())
                .catch(handleError(toastDispatch));
        }

        if (sendAt) {
            if (isV3Enabled) {
                return creation
                    .mutateAsync({
                        conversationId: conversation.id,
                        body: message,
                        attachments: attachments?.map(({ id }) => id),
                        timestamp: new Date(sendAt),
                    })
                    .catch(handleError(toastDispatch));
            }
            return createScheduledMessage({
                body: message,
                sendAt,
                files: files.map((f) => f.id),
                conversationId: conversation.id,
            }).catch(handleError(toastDispatch));
        }

        return send({
            from,
            to,
            body: message,
            inboxId: conversation.inboxId || inbox!.id,
            conversationId: conversation.id,
            fileIds: files?.map((f) => f.id),
            attachments: attachments?.map(({ id }) => id),
            user: me!,
        });
    };

    const initial = (() => {
        if (messageForEdit) {
            return {
                message: messageForEdit.body || '',
                files: messageForEdit.attachments,
                sendAt: messageForEdit.timestamp,
            } as MessageFormData;
        }

        if (smForEdit) {
            return {
                message: smForEdit.body || '',
                files: smForEdit.attachments,
                sendAt: smForEdit.sendAt,
            } as MessageFormData;
        }

        return null;
    })();

    return (
        <MessageForm
            key={conversation.id}
            templatesAvailable={conversation?.members.length === 1}
            initial={initial}
            placeholder="Write a message..."
            onSend={submitForm}
            onCancel={onEditCancel}
            conversation={conversation}
            disabled={disabled}
        />
    );
}
