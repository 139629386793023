import styles from './SideBarCohorts.module.scss';
import ScheduledItem from '../../../elements/ScheduledItem';
import { useScheduledConversationsQuery } from '../../../queries/scheduled-messages';
import OptedOutContactsItem from '../../../elements/OptedOutContactsItem';

interface SideBarCohortsProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarCohorts = ({ setMenuOpen }: SideBarCohortsProps) => {
    const { conversations: scheduledConversations } =
        useScheduledConversationsQuery();

    return (
        <div className={styles['root']}>
            <OptedOutContactsItem onClick={() => setMenuOpen(false)} />

            {scheduledConversations.length > 0 && (
                <ScheduledItem onClick={() => setMenuOpen(false)} />
            )}
        </div>
    );
};
