import { useMemo } from 'preact/hooks';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Contact } from '../../api/types';
import addContactIcon from '../../assets/icons/placeholders/add-contact.svg';
import Button from '../../elements/Buttons';
import { ActiveViewState } from '../../main/types';
import { useMeQueryData } from '../../queries/user';
import styles from './ContactsList.module.scss';
import { ContactsListItemSimple } from './ContactsListItemSimple';
import Loading from '../Loading/Loading';
import { UUID } from '../../types/uuid';
import { useContactsQueryData } from '../../queries/contacts';
import { CampaignContact } from '../../api/campaign.types';

const isNewContact = ({ name, phone, email }: Contact | CampaignContact) =>
    !name && !phone && !email;

const byName = (a: Contact, b: Contact) => {
    //  If any of contacts or both are NEW
    if (isNewContact(a) && isNewContact(b)) {
        return 0;
    }
    if (isNewContact(a)) {
        return -1;
    }
    if (isNewContact(b)) {
        return 1;
    }

    // Sort by name
    if (a.name && b.name) {
        return a.name.localeCompare(b.name);
    }
    if (a.name) {
        return -1;
    }
    if (b.name) {
        return 1;
    }

    // Sort by phone
    if (a.phone && b.phone) {
        if (a.phone > b.phone) {
            return -1;
        }
        if (a.phone < b.phone) {
            return 1;
        }
    }
    if (a.phone) {
        return -1;
    }
    if (b.phone) {
        return 1;
    }

    // Sort by email
    if (a.email && b.email) {
        return a.email.localeCompare(b.email);
    }
    if (a.email) {
        return -1;
    }
    if (b.email) {
        return 1;
    }

    // All parameters are equal
    return 0;
};

interface ContactsListProps {
    contactIds: UUID[];
    // the ones that has been selected with checkbox
    selectedContacts?: Contact[];
    // the one that has been clicked
    selectedContactId?: string;
    isLoading: boolean;
    isEmpty: boolean;
    setActiveView: (value: ActiveViewState) => void;
    toogleSelectedContact: (contact: Contact | CampaignContact) => void;
    setDraft: (value: Contact | CampaignContact | undefined) => void;
    onCreateContact?: () => void;
}

export const ContactsList = ({
    contactIds = [],
    selectedContacts = [],
    selectedContactId,
    isEmpty,
    setActiveView,
    toogleSelectedContact,
    isLoading,
    setDraft,
    onCreateContact,
}: ContactsListProps) => {
    const currentUser = useMeQueryData();
    const contactsCache = useContactsQueryData();

    const sortedContacts = useMemo(() => {
        return contactIds
            .map((contactId) => contactsCache.getById(contactId))
            .filter((contact) => contact)
            .sort(byName);
    }, [contactIds, contactsCache]);

    const itemData = {
        countryCode: currentUser?.activeTeam.countryCode,
        list: sortedContacts,
        selectedContacts,
        selectedContactId,
        setActiveView,
        toogleSelectedContact,
        setDraft,
    };

    if (isEmpty) {
        return (
            <div className={styles['contacts-list']}>
                <div className={styles['contacts-list__empty']}>
                    <img
                        className={styles['contacts-list__empty-icon']}
                        src={addContactIcon}
                    />
                    <p className={styles['contacts-list__empty-text']}>
                        Add your first contact
                    </p>
                    <Button onClick={onCreateContact}>Add contact</Button>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={styles['contacts-list']}>
            {sortedContacts.length > 0 && (
                <AutoSizer className={styles['contacts-list__autosizer']}>
                    {({ height }: { height: number }) => (
                        <List
                            innerElementType="ul"
                            height={height}
                            className={styles['contacts-list__outer']}
                            itemCount={contactIds.length}
                            itemData={itemData}
                            itemSize={48}
                            width="100%"
                        >
                            {ContactsListItemSimple}
                        </List>
                    )}
                </AutoSizer>
            )}
        </div>
    );
};
