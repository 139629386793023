import { SxProps, Theme } from '@mui/material';

export const searchInput: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(1.5, 0),
    marginBottom: 1,
    height: 20,
});

export const searchHeader: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(0, 3),
    height: 36,

    '&:hover, &.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
});

export const checkbox: SxProps = { padding: 0, marginRight: 2 };

export const menuItemWithCheckbox: SxProps = {
    '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
};
