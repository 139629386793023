import { useMutation, useQuery } from '@tanstack/react-query';
import { createBrand, getBrand, updateBrand } from '../api/settings';
import {
    BrandRegistrationStatus,
    CreateBrandDto,
    UpdateBrandDto,
} from '../api/types';
import { updateProfileImage } from '../api/user';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY } from './user';

const BRAND_KEY = 'brand';

export function useBrandQuery() {
    return useQuery({
        queryKey: [BRAND_KEY],
        queryFn: getBrand,
    });
}
export const useIsVerified = () => {
    const { data } = useBrandQuery();
    return data?.status === BrandRegistrationStatus.VERIFIED;
};

export function useBrandCreateQuery() {
    return useMutation<void, unknown, CreateBrandDto>({
        mutationKey: ['brand_create'],
        mutationFn: createBrand,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [BRAND_KEY] });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: [BRAND_KEY] });
        },
    });
}

export function useBrandUpdateQuery() {
    return useMutation<void, unknown, UpdateBrandDto>({
        mutationKey: ['brand_update'],
        mutationFn: updateBrand,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [BRAND_KEY] });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: [BRAND_KEY] });
        },
    });
}

export function useUpdateUserAvatar() {
    return useMutation({
        mutationFn: updateProfileImage,
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
        },
    });
}
