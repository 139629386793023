import styles from './CampaignItemActions.module.scss';
import { useRef } from 'preact/hooks';
import renameIcon from '../../assets/icons/content/create.svg';
import deleteIcon from '../../assets/icons/action/delete_outline.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface CampaignItemActionsProps {
    onOutsideClick: () => void;
    onRenameClick: () => void;
    onRemoveClick: () => void;
}

const CampaignItemActions = ({
    onOutsideClick,
    onRenameClick,
    onRemoveClick,
}: CampaignItemActionsProps) => {
    const ulRef = useRef<HTMLUListElement>(null);

    useOnClickOutside(ulRef, onOutsideClick);

    return (
        <div className={styles['inbox-actions']}>
            <ul className={styles['inbox-actions__list']} ref={ulRef}>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onRenameClick();
                        }}
                        type="button"
                    >
                        <img
                            className={styles['inbox-actions__icon']}
                            src={renameIcon}
                        />
                        <span>Rename</span>
                    </button>
                </li>
                <li className={styles['inbox-actions__list-item']}>
                    <button
                        className={styles['inbox-actions__btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            onRemoveClick();
                        }}
                        type="button"
                    >
                        <img
                            className={styles['inbox-actions__icon']}
                            src={deleteIcon}
                        />
                        <span>Remove</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default CampaignItemActions;
