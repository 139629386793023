import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { CalendarIcon } from '../../../icons/common/CСalendarIcon';

type FieldProps = {
    value: Date | string | number;
    onClick: () => void;
    disabled?: boolean;
};
// eslint-disable-next-line react/display-name
export const Field = forwardRef<HTMLInputElement, FieldProps>(
    ({ value, onClick, disabled }, ref) => (
        <TextField
            id="timestamp-input-value"
            onClick={onClick}
            disabled={disabled}
            color="blue"
            sx={{ input: { cursor: 'pointer' } }}
            readonly
            InputProps={{
                sx: { cursor: 'pointer' },
                inputRef: ref,
                startAdornment: (
                    <InputAdornment position="start">
                        <CalendarIcon />
                    </InputAdornment>
                ),
            }}
            value={value}
        />
    ),
);
