import { useMutation, useQuery } from '@tanstack/react-query';
import {
    listActivities,
    markAllAsRead,
    updateActivity,
} from '../api/activities';
import { queryClient } from './queryClient';

export const ACTIVITIES = 'activities';

export const useListActivities = () =>
    useQuery({
        queryKey: [ACTIVITIES],
        queryFn: listActivities,
        staleTime: Infinity,
    });

export const useUnreadActivities = () =>
    useQuery({
        queryKey: [ACTIVITIES],
        queryFn: listActivities,
        select: (data) => data.filter((activity) => activity.unread),
        staleTime: Infinity,
    });

export const useMarkAllAsRead = () =>
    useMutation({
        mutationKey: ['mark_activities_read'],
        mutationFn: markAllAsRead,
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [ACTIVITIES] });
        },
    });

export const useUpdateActivity = () =>
    useMutation({
        mutationKey: ['archive_activity'],
        mutationFn: updateActivity,
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [ACTIVITIES] });
        },
    });
