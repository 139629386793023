import { useCallback, useEffect, useState } from 'preact/hooks';
import Router, { Route } from 'preact-router';
import { Prompt } from './prompt';
import Contacts from './contacts';
import Cohort from './cohorts';
import Scheduled from './scheduled';
import Inbox from './inbox';
import CsvImportWizard from './contacts-import/CsvImportWizard';
import { NotFound } from '../not-found';
import { Redirect } from '../utils/redirect';
import SideBar from '../containers/SideBar';
import AddInbox from '../containers/AddInbox';
import styles from './index.module.scss';
import { askNotificationPermission } from '../notifications';
import { useContactsQuery } from '../queries/contacts';
import Campaign from './campaign';
import OptedOutConversations from './opted-out';
import { useMeQueryData, useUpdateMe } from '../queries/user';
import { get } from '../utils/local-storage';
import Workflows from './workflows';
import { useSocket } from '../websocket';
import TermsOfService from '../containers/TermsOfService';
import PaymentFlow from '../containers/PaymentFlow';
import InboxLoader from '../containers/InboxLoader';
import IntegrationPropertyMap from '../containers/IntegrationPropertyMap';
import PhoneVerification from '../containers/PhoneVerification';
import { Analytics } from './analytics/Analytics';
import { useIdentify } from '../contexts/analytics';

export function Main() {
    const [isPromptOpen, setPromptOpen] = useState(false);

    const profile = useMeQueryData();
    const identify = useIdentify();

    const msId = get('MS_TEAMS_USER_ID');
    const auth = get('auth');
    const msTenantId = get('MS_TENET_ID');

    const socket = useSocket();

    useEffect(() => {
        if (profile) {
            identify();
        }
    }, [profile?.id]);

    useEffect(() => {
        socket.init();
        return () => socket.disconnect();
    }, [auth]);

    useContactsQuery();
    useEffect(() => askNotificationPermission(), []);
    useEffect(() => {
        if (
            msId &&
            msTenantId &&
            (!profile?.microsoftId || !profile?.microsoftTenantId)
        ) {
            updateMe({ microsoftId: msId, microsoftTenantId: msTenantId });
        }
    }, [msId, msTenantId]);

    const { mutateAsync: updateMe } = useUpdateMe();
    useEffect(() => {
        function keyHandler(this: Document, e: KeyboardEvent) {
            if (e.code === 'KeyK' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();

                setPromptOpen((p) => !p);
            }

            if (e.code === 'Escape') {
                e.preventDefault();

                setPromptOpen(false);
            }
        }

        window.document.addEventListener('keydown', keyHandler);

        return () => {
            window.document.removeEventListener('keydown', keyHandler);
        };
    }, []);

    const closePrompt = useCallback(() => {
        setPromptOpen(false);
    }, []);

    return profile ? (
        <div className={styles['main']}>
            <SideBar setPromptOpen={setPromptOpen} />
            <Router>
                <Route
                    path="/setup/terms-of-service"
                    component={TermsOfService}
                />
                <Route path="payment/:rest*" component={PaymentFlow} />

                <Route path="/setup-inbox/:rest*" component={AddInbox} />
                <Route
                    path="/inbox/:inboxId/verify/:step*"
                    component={PhoneVerification}
                />
                <Route path="contacts/import-csv" component={CsvImportWizard} />
                <Route path="/contacts/:id*" component={Contacts} />
                <Route
                    path="/campaigns/:campaignId/:conversationId*"
                    component={Campaign}
                />
                <Route
                    path="/cohorts/:cohortId/:conversationId*"
                    component={Cohort}
                />

                <Route
                    path="/scheduled/:conversationId*"
                    component={Scheduled}
                />

                <Route
                    path="/opted-out/:conversationId*"
                    component={OptedOutConversations}
                />

                <Route
                    path="/integration/:integrationId/settings"
                    component={IntegrationPropertyMap}
                />
                <Route
                    path="/inbox/:inboxId/:actionId?/:campaignId?/:subEntity?/:campaignMessageId?"
                    component={Inbox}
                />
                <Route path="/analytics" component={Analytics} />
                <Route path="/workflows/:workflowId*" component={Workflows} />
                <Route path="/" component={InboxLoader} />
                <Route default component={NotFound} />
            </Router>
            {isPromptOpen && (
                <Prompt
                    className={styles['main__prompt']}
                    close={closePrompt}
                />
            )}
        </div>
    ) : (
        <Redirect to="/login" />
    );
}
