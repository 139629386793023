import IconBase, { IconBaseProps } from '../IconBase';

export const MicrosoftLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M12.6875 11.3125H23V1.6875C23 1.308 22.692 1 22.3125 1H12.6875V11.3125Z"
            fill="#4CAF50"
        />
        <path
            d="M11.3125 11.3125V1H1.6875C1.308 1 1 1.308 1 1.6875V11.3125H11.3125Z"
            fill="#F44336"
        />
        <path
            d="M11.3125 12.6875H1V22.3125C1 22.692 1.308 23 1.6875 23H11.3125V12.6875Z"
            fill="#2196F3"
        />
        <path
            d="M12.6875 12.6875V23H22.3125C22.692 23 23 22.692 23 22.3125V12.6875H12.6875Z"
            fill="#FFC107"
        />
    </IconBase>
);
