import { Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useQuery } from '@tanstack/react-query';
import cc from 'classcat';
import styles from './prompt.module.scss';
import { getConversations } from '../../api/conversation';
import { useContactsQueryData } from '../../queries/contacts';
import {
    getFilteredContacts,
    getFilteredConversations,
    getFilteredInboxes,
    getFilteredSection,
} from './utils';
import { useMeQueryData } from '../../queries/user';
import FormInput from '../../elements/FormInput';

enum Category {
    Conversations = 'Conversations',
    Inboxes = 'Inboxes',
    Workspace = 'Workspace',
    Contacts = 'Contacts',
}

export function Prompt({
    className,
    close,
}: {
    className?: string;
    close: () => void;
}) {
    const { data: conversations } = useQuery({
        queryKey: ['conversations'],
        queryFn: getConversations,
    });

    const me = useMeQueryData();
    const contacts = useContactsQueryData();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [query, setQuery] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current!.focus();
        }
    }, []);

    useEffect(() => {
        function closeOnClickOutside(this: Document, e: MouseEvent) {
            if (
                !this.querySelector(`.${styles.prompt}`)!.contains(
                    e.target! as Node,
                )
            ) {
                e.preventDefault();

                close();
            }
        }

        function handleKeyUp(this: Document, e: KeyboardEvent) {
            if (e.code === 'ArrowDown') {
                e.preventDefault();

                setSelectedIndex((s) => s + 1);
            } else if (e.code === 'ArrowUp') {
                e.preventDefault();

                setSelectedIndex((s) => s - 1);
            } else if (e.code === 'Enter') {
                e.preventDefault();

                const button = this.querySelector(`.${styles.selected}`);
                if (button) {
                    button.dispatchEvent(new Event('click', {}));
                }
            }
        }

        window.document.addEventListener('keydown', handleKeyUp);
        window.document.addEventListener('click', closeOnClickOutside, true);

        return () => {
            window.document.removeEventListener('keydown', handleKeyUp);
            window.document.removeEventListener(
                'click',
                closeOnClickOutside,
                true,
            );
        };
    }, [close]);

    function getItems(category: Category, query: string) {
        if (me) {
            switch (category) {
                case Category.Conversations:
                    return getFilteredConversations(
                        me,
                        contacts,
                        conversations,
                        query,
                    );

                case Category.Inboxes:
                    return getFilteredInboxes(me, query);

                case Category.Contacts:
                    return getFilteredContacts(me, contacts, query);
            }
        }

        return getFilteredSection(query);
    }

    let index = 0;

    return (
        <dialog open className={cc([className, styles.prompt])}>
            <form
                className={cc(['form', styles.form])}
                onSubmit={(e) => e.preventDefault()}
            >
                <FormInput
                    id="query"
                    name="query"
                    ref={inputRef}
                    autoComplete="off"
                    autoFocus
                    type="text"
                    placeholder="What are you looking for?"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target?.value);
                        setSelectedIndex(0);
                    }}
                />
            </form>
            <section className={styles.results}>
                {Object.keys(Category).map(
                    (category) =>
                        getItems(category as Category, query).length > 0 && (
                            <Fragment key={category}>
                                <h4 className={styles.category}>{category}</h4>
                                <ul>
                                    {getItems(category as Category, query).map(
                                        (i) => (
                                            <li key={index}>
                                                <button
                                                    className={cc([
                                                        'btn btn-link text-ellipsis',
                                                        styles.result,
                                                        {
                                                            [styles.selected]:
                                                                selectedIndex ===
                                                                index,
                                                        },
                                                    ])}
                                                    onClick={() => {
                                                        i.action();
                                                        close();
                                                    }}
                                                    data-index={index++}
                                                >
                                                    {i.icon}
                                                    <span
                                                        className={styles.title}
                                                    >
                                                        {i.title}
                                                    </span>
                                                </button>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </Fragment>
                        ),
                )}
            </section>
        </dialog>
    );
}
