import { useRef } from 'preact/hooks';
import styles from './NewConversation.module.scss';
import NewConversationProvider from './NewConversationProvider';
import SearchPanel from './SearchPanel';
import NewConversationContent from './NewConversationContent';
import { NewConversationMessage } from './NewConversationMessage/NewConversationMessage';
import { FinishHandler } from './types';
import { UUID } from '../../types/uuid';
import { NewConversationControls } from './NewConversationControls/NewConversationControls';

interface NewConversationProps {
    inboxId: UUID;
    onFinish: FinishHandler;
    onCancel: () => void;
}

export const NewConversation = (props: NewConversationProps) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <NewConversationProvider
            inboxId={props.inboxId}
            onFinish={props.onFinish}
        >
            <section className={styles['root']} ref={ref}>
                <SearchPanel />

                <NewConversationContent />

                <NewConversationControls />

                <div className={styles['root__message']}>
                    <NewConversationMessage
                        inboxId={props.inboxId}
                        onFinish={props.onFinish}
                    />
                </div>
            </section>
        </NewConversationProvider>
    );
};
