import { ComponentChildren } from 'preact';
import { forwardRef } from 'preact/compat';
import cc from 'classcat';
import styles from './Menu.module.scss';

export interface MenuProps {
    className?: string;
    children: ComponentChildren;
}

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
    ({ className, children }, ref) => (
        <div className={cc([styles['root'], className])} ref={ref}>
            <ul className={styles['root__list']}>{children}</ul>
        </div>
    ),
);
