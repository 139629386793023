import cc from 'classcat';
import styles from './DropdownList.module.scss';
import { ComponentChildren } from 'preact';

export type DropdownListProps = {
    children?: ComponentChildren;
    className?: string;
};

export const DropdownList = ({ children, className }: DropdownListProps) => {
    return (
        <ul className={cc([styles['dropdown-list'], className])}>{children}</ul>
    );
};
