import { ComponentChildren } from 'preact';
import { forwardRef } from 'preact/compat';
import cc from 'classcat';
import styles from './DropdownContainer.module.scss';

export type DropdownContainerProps = {
    children?: ComponentChildren;
    className?: string;
};

export const DropdownContainer = forwardRef<
    HTMLDivElement,
    DropdownContainerProps
>((props, ref) => {
    const { children, className } = props;

    return (
        <div
            className={cc([styles['dropdown-container'], className])}
            ref={ref}
        >
            {children}
        </div>
    );
});
