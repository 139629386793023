import { route } from 'preact-router';
import { createNotification } from '../notifications';
import { PagedData } from '../types/PagedData';
import { queryClient } from '../queries/queryClient';
import { Conversation, ConversationStatus, Message } from '../api/types';
import { useContactsQueryData } from '../queries/contacts';
import { useMeQueryData } from '../queries/user';
import { CONVERSATION_MESSAGES_KEY, UNREADS_KEY } from '../queries/messages';
import { CONVERSATIONS_LIST_KEY } from '../queries/conversations';
import { getLatestQueryByKey } from '../utils/query-utils';
import { throttle, uniq } from 'lodash';

// HANDLE BROWSER NOTIFICATION

type VisibilityChangeHandler = () => void;

const closeNotification = (
    notification: Notification,
    handleVisibilityChange: VisibilityChangeHandler,
) => {
    notification?.close();
    document.removeEventListener('visibilitychange', handleVisibilityChange);
};

const showNotification = (message: Message, conversation: Conversation) => {
    const contacts = useContactsQueryData();
    const contact = contacts.getByPhoneOrEmpty(message.sender);
    const title = contact?.name || contact?.phone || '';

    const notification = createNotification(
        title,
        message.body,
        conversation.id,
    );

    if (notification) {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                closeNotification(notification, handleVisibilityChange);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Activate browser window and tab if user clicks notification
        notification?.addEventListener('click', () => {
            route(`/inbox/${conversation.inboxId}/${conversation.id}`);
            window.focus();
            closeNotification(notification, handleVisibilityChange);
        });
    }
};

// HANDLE APP STATE

const addOrUpdateConversationsState = (conversation: Conversation) => {
    const activeConversations = queryClient.getQueryData<
        PagedData<Conversation>
    >(['conversations', conversation.inboxId, ConversationStatus.Active]);

    const archivedConversations = queryClient.getQueryData<
        PagedData<Conversation>
    >(['conversations', conversation.inboxId, ConversationStatus.Archived]);

    if (!activeConversations) {
        queryClient.setQueryData(
            ['conversations', conversation.inboxId, ConversationStatus.Active],
            {
                pages: [[conversation]],
                pageParams: [conversation.updated],
            },
        );
        return;
    }

    // Looking for conversation in store
    let foundAndUpdated = false;
    let updatedPages;
    updatedPages = activeConversations?.pages.map((page) => {
        return page.map((c) => {
            if (c.id === conversation.id) {
                foundAndUpdated = true;
                return conversation;
            }
            return c;
        });
    });
    if (!foundAndUpdated) {
        updatedPages = archivedConversations?.pages.map((page) => {
            return page.map((c) => {
                if (c.id === conversation.id) {
                    foundAndUpdated = true;
                    return conversation;
                }
                return c;
            });
        });
    }

    // If such a conversation already in store
    if (foundAndUpdated) {
        queryClient.setQueryData(
            ['conversations', conversation.inboxId, ConversationStatus.Active],
            {
                pages: updatedPages,
                pageParams: activeConversations?.pageParams,
            },
        );
        return;
    }

    // If such a conversation is no in store yet
    const [firstPagesBlock, ...others] = activeConversations.pages;
    const pagesWithNewConversation = [
        [conversation, ...firstPagesBlock],
        ...others,
    ];
    queryClient.setQueryData(
        ['conversations', conversation.inboxId, ConversationStatus.Active],
        {
            pages: pagesWithNewConversation,
            pageParams: activeConversations?.pageParams,
        },
    );
};

const addMessageToStore = (message: Message, conversation: Conversation) => {
    const messages = queryClient.getQueryData<PagedData<Message>>([
        CONVERSATION_MESSAGES_KEY,
        conversation.id,
    ]);

    // If no messages in store yet (new conversation)
    if (!messages) {
        queryClient.setQueryData([CONVERSATION_MESSAGES_KEY, conversation.id], {
            pages: [[message]],
            pageParams: [message.created],
        });
        return;
    }

    const [firstMessagesBlock, ...others] = messages.pages;
    const newMessages = [firstMessagesBlock.concat(message), ...others];

    queryClient.setQueryData([CONVERSATION_MESSAGES_KEY, conversation.id], {
        pages: newMessages,
        pageParams: messages?.pageParams,
    });
    queryClient.invalidateQueries({ queryKey: [UNREADS_KEY] });
};

type DataPayload = {
    message: Message;
    conversation: Conversation;
};

export const NEW_MESSAGE_EVENT = 'NEW_MESSAGE';

let pairs: Record<string, [Message, Conversation]> = {};
const throttledUpdates = throttle(() => {
    const values = Object.values(pairs);
    if (values.some(([{ inbound }]) => inbound)) {
        void queryClient.invalidateQueries({ queryKey: [UNREADS_KEY] });
    }
    values.forEach(([message, conversation]) => {
        if (message.inbound) {
            showNotification(message, conversation);
        }

        const me = useMeQueryData()?.id;
        if (me !== message.user?.id) {
            addOrUpdateConversationsState(conversation);
            addMessageToStore(message, conversation);
        }
    });

    uniq(values.map(([_, conversation]) => conversation.inboxId)).forEach(
        (inboxId) => {
            // When a new message arrives, we must refresh the conversation list so that the conversations appear in the correct order
            if (!window.location.pathname.match(/\/inbox\//)) {
                return;
            }
            const activeInboxId = window.location.pathname.split('/')[2];
            if (activeInboxId !== inboxId) {
                return;
            }
            const query = getLatestQueryByKey(queryClient, [
                CONVERSATIONS_LIST_KEY,
                inboxId,
            ]);

            /** todo: update the logic to avoid refreshing the conversations fully */
            queryClient.cancelQueries({ queryKey: query.queryKey });
            queryClient.invalidateQueries({ queryKey: query.queryKey });
        },
    );
    pairs = {};
}, 1_000);

export const handleNewMessageEvent = ({
    message,
    conversation,
}: DataPayload) => {
    pairs[`${message.id}:${conversation.id}`] = [message, conversation];
    throttledUpdates();
};
