import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { UNREADS_KEY } from './messages';
import { TEAMMATES_KEY } from './user';
import { deleteDataInPagedData } from './utils';
import {
    assingUsersToConversation,
    deleteConversation,
    findConversation,
    getCampaignConversations,
    getCohortConversations,
    getConversation,
    getConversationsPaged,
    getOptedOutConversations,
    getOrCreateConversation,
    getOrCreateConversationV2,
    markConversationAsRead,
    markConversationAsUnRead,
    summarizeConversation,
} from '../api/conversation';

import {
    Conversation,
    ConversationsFilter,
    ConversationsFilterType,
    ConversationStatus,
    CreateConversationDTO,
    Inbox,
    Profile,
} from '../api/types';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { getLatestQueryByKey } from '../utils/query-utils';
import { useContactsQueryData } from './contacts';
import { useTrack } from '../contexts/analytics';
import { AnalyticsEventName } from '../types/AnalyticsEventNames';
import client from '../api/http';

export const CONVERSATION_KEY = 'conversation';
export const CONVERSATIONS_LIST_KEY = 'conversations';
const CONVERSATION_SEARCH_KEY = 'conversation_search';

export const useGetConversation = (id: string | undefined) =>
    useQuery({
        queryKey: [CONVERSATION_KEY, id],
        queryFn: () => getConversation(id!),
        enabled: !!id,
    });

export const useGetConversationData = (id: string) =>
    queryClient.getQueryData<Conversation | undefined>([CONVERSATION_KEY, id]);

export const useGetOrCreateConversation = () =>
    useMutation({
        mutationFn: getOrCreateConversation,
    });

export const useDeleteConversation = () => {
    const track = useTrack();
    return useMutation<
        Conversation,
        AxiosError,
        { id: string; inboxId: string; status: ConversationStatus },
        { id: string; inboxId: string; status: ConversationStatus }
    >({
        mutationFn: (p) => deleteConversation(p.id),
        onMutate: (variables) => {
            const queryData = queryClient.getQueryData<PagedData<Conversation>>(
                [CONVERSATIONS_LIST_KEY, variables.inboxId, variables.status],
            );
            if (queryData) {
                const updated = deleteDataInPagedData<Conversation>(
                    queryData,
                    (c) => c.id === variables.id,
                );
                queryClient.setQueryData(
                    [
                        CONVERSATIONS_LIST_KEY,
                        variables.inboxId,
                        variables.status,
                    ],
                    updated,
                );
            }
            return variables;
        },
        onSuccess: (data) => {
            track(AnalyticsEventName.CONVERSATION_DELETED, {
                id: data.id,
                status: data.status,
            });
        },
        onError: (_, context) => {
            queryClient.invalidateQueries({
                queryKey: [
                    CONVERSATIONS_LIST_KEY,
                    context.inboxId,
                    context.status,
                ],
            });
        },
    });
};

export const useNewConversationCreateQuery = () =>
    useMutation<Conversation, AxiosError, CreateConversationDTO, Conversation>({
        mutationFn: getOrCreateConversationV2,
        onSuccess: (conversation) => {
            const queryData = queryClient.getQueryData<PagedData<Conversation>>(
                [
                    CONVERSATIONS_LIST_KEY,
                    conversation.inboxId,
                    ConversationStatus.Active,
                ],
            );
            if (queryData) {
                queryData.pages[0].splice(0, 0, conversation);
                queryClient.setQueryData(
                    [
                        CONVERSATIONS_LIST_KEY,
                        conversation.inboxId,
                        ConversationStatus.Active,
                    ],
                    queryData,
                );
            }
        },
        onSettled: (conversation) => {
            if (conversation) {
                queryClient.invalidateQueries({
                    queryKey: [
                        CONVERSATIONS_LIST_KEY,
                        conversation.inboxId,
                        conversation.status,
                    ],
                });
            }
        },
    });

export const useArchiveConversation = () => {
    const track = useTrack();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<Conversation, AxiosError, Partial<Conversation>, any>({
        mutationKey: ['archive_conversation'],
        mutationFn: (conversation: Partial<Conversation>) =>
            client()
                .post(`/conversations/${conversation.id}`, conversation)
                .then(({ data }) => data),
        onSuccess: (data) => {
            track(AnalyticsEventName.CONVERSATION_ARCHIVED, {
                id: data.id,
                status: data.status,
            });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATION_KEY, data.id],
            });
        },
    });
};

export const useMarkConversationAsRead = () =>
    useMutation<void, unknown, string, unknown>({
        mutationKey: ['markConversationAsRead'],
        mutationFn: markConversationAsRead,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [UNREADS_KEY] });
        },
    });

export const useMarkConversationAsUnRead = () =>
    useMutation<void, unknown, string, unknown>({
        mutationKey: ['markConversationAsUnRead'],
        mutationFn: markConversationAsUnRead,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
            queryClient.invalidateQueries({ queryKey: [UNREADS_KEY] });
        },
    });

export const useConversationsPaged = (
    inbox: Inbox,
    filter: ConversationsFilter,
) => {
    const queryKey =
        filter.type === ConversationsFilterType.ByCohorts
            ? [
                  CONVERSATIONS_LIST_KEY,
                  inbox?.id,
                  filter.type,
                  filter.cohortsList,
              ]
            : [CONVERSATIONS_LIST_KEY, inbox?.id, filter.type];
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending: isPending,
        refetch,
    } = useInfiniteQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey,
        enabled: !!inbox,
        initialPageParam: undefined,
        queryFn: ({ pageParam }) =>
            getConversationsPaged(inbox!.id, filter, pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage && lastPage.length > 0) {
                return new Date(
                    lastPage[lastPage.length - 1].updated,
                ).getTime();
            }
            return undefined;
        },
        select: (data) =>
            data.pages.reduce(
                (acc, conversations) => acc.concat(conversations),
                [],
            ),
    });

    return {
        conversations: data,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
};

export const useGetCohortConversations = (cohortId: number) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery({
            initialPageParam: undefined,
            queryKey: [CONVERSATIONS_LIST_KEY, 'cohort', cohortId],
            queryFn: ({ pageParam }) =>
                getCohortConversations(cohortId, pageParam),
            getNextPageParam: (lastPage) =>
                lastPage &&
                lastPage.length > 0 &&
                new Date(lastPage[lastPage.length - 1].updated).getTime(),
        });

    const conversations =
        data?.pages.reduce((acc, msgs) => acc.concat(msgs), []) || [];

    return {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
};

export const useGetCampaignConversations = (
    campaignId: number,
    enabled = true,
) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery({
            queryKey: [CONVERSATIONS_LIST_KEY, 'campaign', campaignId],
            queryFn: ({ pageParam }) =>
                getCampaignConversations(campaignId, pageParam),
            initialPageParam: undefined,
            enabled,
            refetchIntervalInBackground: false,
            getNextPageParam: (lastPage) =>
                lastPage &&
                lastPage.length > 0 &&
                new Date(lastPage[lastPage.length - 1].updated).getTime(),
        });

    const conversations =
        data?.pages.reduce((acc, msgs) => acc.concat(msgs), []) || [];

    return {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
};

export const useOptedOutConversations = () => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery({
            queryKey: [CONVERSATIONS_LIST_KEY, 'opted-out'],
            queryFn: ({ pageParam }) => getOptedOutConversations(pageParam),
            initialPageParam: undefined,
            getNextPageParam: (lastPage) =>
                lastPage &&
                lastPage.length > 0 &&
                new Date(lastPage[lastPage.length - 1].updated).getTime(),
        });

    const conversations =
        data?.pages.reduce((acc, msgs) => acc.concat(msgs), []) || [];

    return {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
};

export const assingUsers = () =>
    useMutation<
        Conversation,
        unknown,
        { conversationId: UUID; userIds: UUID[] },
        { prevConversation?: Conversation }
    >({
        mutationKey: ['assing_users'],
        mutationFn: ({ conversationId, userIds }) =>
            assingUsersToConversation(conversationId, userIds),
        onMutate: ({ conversationId, userIds = [] }) => {
            const conversation = queryClient.getQueryData<Conversation>([
                CONVERSATION_KEY,
                conversationId,
            ]);

            const users =
                queryClient.getQueryData<Profile[]>([TEAMMATES_KEY]) || [];

            if (conversation) {
                const assignedUsers = users.filter((user) =>
                    userIds.includes(user.id),
                );
                queryClient.setQueryData<Conversation>(
                    [CONVERSATION_KEY, conversationId],
                    { ...conversation, assignee: assignedUsers },
                );
            }
            return { prevConversation: conversation };
        },
        onError: (_, data, context) => {
            const { prevConversation } = context || {};

            if (prevConversation) {
                queryClient.setQueryData<Conversation>(
                    [CONVERSATION_KEY, data.conversationId],
                    prevConversation,
                );
            }
        },
        onSuccess: () => {
            const query = getLatestQueryByKey(queryClient, [
                CONVERSATIONS_LIST_KEY,
            ]);
            queryClient.invalidateQueries({ queryKey: query.queryKey });
        },
    });

export function useConversationFindQuery(
    inboxId: UUID,
    contactIds: (UUID | string)[],
    enabled = true,
) {
    return useQuery({
        queryKey: [CONVERSATION_SEARCH_KEY, { inboxId, contactIds }],
        queryFn: () => findConversation({ inboxId, contactIds }),
        // It's necessary to keep `retry: false`. If to remove this setting, react-query will attempt
        // to retry failed search request that may lead to the case when UI and actual query are not synced.
        retry: false,
        enabled,
    });
}

export function useSummarizeConversationQuery(
    conversationId: UUID,
    enabled: boolean,
) {
    return useQuery({
        queryKey: [CONVERSATION_SEARCH_KEY, { conversationId }],
        queryFn: () => summarizeConversation(conversationId),
        retry: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        gcTime: Infinity,
        enabled,
    });
}

export const useRecipientName = ({ members }: { members?: string[] }) => {
    const contacts = useContactsQueryData();

    return (
        (members || [])
            .map((phone) => contacts.getByPhoneOrEmpty(phone))
            .map((contact) => contact.name || contact.email || contact.phone)
            .join(', ') || 'New recipient'
    );
};

export const useRecipientAvatar = ({ members }: { members?: string[] }) => {
    const contacts = useContactsQueryData();

    return (
        (members || [])
            .map((phone) => contacts.getByPhoneOrEmpty(phone))
            .filter(({ avatarURL }) => avatarURL)
            .map(({ avatarURL }) => avatarURL)
            .slice(0, 1)
            .join('') || undefined
    );
};
