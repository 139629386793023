import styles from './PhoneVerification.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../elements/FormInput';
import Callout from '../../components/Callout';
import FormButton from '../../elements/FormButton';
import Spacer from '../../components/Spacer';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { PhoneVerificationProps } from './PhoneVerification';
import {
    useInboxQueryData,
    useVerificationCallCodeMutation,
} from '../../queries/inboxes';
import { sendVerificationCall } from '../../api/inboxes';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { AnalyticsEventName } from '../../types/AnalyticsEventNames';
import { AnalyticsActions, useAnalytics } from '../../contexts/analytics';

interface PhoneCallVerificationStepFormData {
    code: string;
}

export const PhoneCallVerificationStep = (props: PhoneVerificationProps) => {
    const { control, handleSubmit, clearErrors } =
        useForm<PhoneCallVerificationStepFormData>();

    const [callPlaced, setCallPlaced] = useState(false);
    const [isLoading, setLoading] = useState(false);
    /*todo: review usage*/
    const [_, setCalling] = useState(false);
    const inbox = useInboxQueryData(props.inboxId);
    const { mutateAsync: verifyInbox } = useVerificationCallCodeMutation(
        inbox!.phone,
    );
    const analytics = useAnalytics();

    const onCallClick = async () => {
        setLoading(true);
        setCalling(true);
        await sendVerificationCall({ phone: inbox!.phone });
        setLoading(false);
        setCallPlaced(true);
    };

    const onSubmit: SubmitHandler<PhoneCallVerificationStepFormData> = async (
        data: PhoneCallVerificationStepFormData,
    ) => {
        setLoading(true);
        const payload = {
            code: data.code,
        };

        await verifyInbox(payload);
        setLoading(false);
        setCalling(false);
        clearErrors();

        analytics.dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: AnalyticsEventName.VERIFICATION_CALL_COMPLETED,
            },
        });
        route(`/inbox/${props.inboxId}/verify/address`);
    };

    return (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Callout style="warning">
                <span>📞</span>
                <div>
                    To verify your number, place a call then enter the
                    verification code below.
                </div>
            </Callout>
            {callPlaced && <p>Calling {formatPhoneNumber(inbox?.phone)}...</p>}
            {!callPlaced ? (
                <FormButton
                    type="button"
                    variant="primary"
                    text={`Call ${formatPhoneNumber(inbox?.phone)}`}
                    onClick={onCallClick}
                    isLoading={isLoading}
                />
            ) : (
                <Controller
                    name="code"
                    control={control}
                    render={({ field, fieldState }) => (
                        <FormInput
                            label="Verification Code"
                            required
                            error={fieldState.isDirty && fieldState.invalid}
                            iconName="user-signature"
                            hint={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Enter Verification Code from Call'
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            )}
            <Spacer height={0} width="auto" />
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <Spacer height="auto" width="96px">
                        <FormButton
                            variant="clear"
                            text="Back"
                            type="button"
                            isLoading={isLoading}
                            onClick={() =>
                                route(`/inbox/${props.inboxId}/verify/customer`)
                            }
                        />
                    </Spacer>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <FormButton
                        variant="primary"
                        text="Proceed"
                        disabled={!callPlaced}
                        isLoading={isLoading}
                    />
                </Spacer>
            </div>
        </form>
    );
};
