import { useState } from 'preact/hooks';
import { usePopper } from 'react-popper';

export const usePopperConfig = () => {
    const [refEl, setRefEl] = useState<HTMLDivElement | null>(null);
    const [popEl, setPopEl] = useState<HTMLDivElement | null>(null);
    const { styles: popStyles, attributes: popAttributes } = usePopper(
        refEl,
        popEl,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    );

    return {
        setRefEl,
        setPopEl,
        popStyles,
        popAttributes,
    };
};
