import {
    Box,
    Collapse,
    SvgIcon,
    ToggleButton,
    Typography,
} from '@mui/material';
import { useState } from 'preact/hooks';
import { useSources } from '../source.query';
import { SourceItem } from './SourceItem';
import { CArrowDropRightFilled } from './CArrowDropRightFilledIcon';

export const SourceList = () => {
    const query = useSources();
    const [open, setOpen] = useState<boolean>(false);

    if (query.isFetched && !query.data?.length) {
        return null;
    }

    return (
        <Box mt={4}>
            <ToggleButton
                size="small"
                value={open}
                selected={open}
                color="toggle_gray"
                aria-label="Toggle"
                onChange={() => setOpen((c) => !c)}
                sx={{
                    mr: 2,
                    svg: {
                        transform: open ? 'rotate(90deg)' : undefined,
                        transition: 'all .2s',
                    },
                }}
            >
                <SvgIcon component={CArrowDropRightFilled} inheritViewBox />
            </ToggleButton>
            <Typography variant="body4">Knowledge materials</Typography>

            <Collapse in={open}>
                <Box pt={4} mb={4} display="flex" gap={3} flexWrap="wrap">
                    {query.data?.map((source) => (
                        <SourceItem key={source.id} source={source} />
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};
