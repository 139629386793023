import { useForm, Controller } from 'react-hook-form';
import {
    Button,
    Divider,
    Stack,
    Typography,
    TextField,
    MenuItem,
} from '@mui/material';
import { useUpdateTeam, useUpdateTeamAvatar } from '../../../queries/team';
import { useMeQueryData } from '../../../queries/user';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import AvatarControl from '../../../components/AvatarControl/AvatarControl';

type Inputs = {
    name: string;
    contactsPublicByDefault: boolean;
};

export const TeamInfo = () => {
    const currentUser = useMeQueryData();

    const { mutate: updateTeam } = useUpdateTeam();
    const updateTeamAvatar = useUpdateTeamAvatar();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<Inputs>({
        defaultValues: {
            name: currentUser?.activeTeam.name ?? '',
            contactsPublicByDefault:
                currentUser?.activeTeam.contactsPublicByDefault ?? true,
        },
    });

    const onSubmit = (data: Inputs) => {
        updateTeam({
            name: data.name,
            contactsPublicByDefault: data.contactsPublicByDefault,
        });
    };

    const onReset = () => {
        reset();
    };

    return (
        <SettingsLayout
            title="Team info"
            description="All of the information about your company"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={10}>
                    <Stack spacing={4}>
                        <Typography variant="caption" color="custom.gray.super">
                            Company profile
                        </Typography>
                        <AvatarControl
                            title="Company avatar"
                            imageUrl={currentUser?.activeTeam?.teamAvatarURL}
                            uploadImageMutation={updateTeamAvatar}
                            uploadImageErrorMessage="There was an error while uploading your avatar"
                        />
                        <Divider />

                        <Stack
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            direction={{ xs: 'column', sm: 'row' }}
                        >
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight={500}
                            >
                                Workspace name
                            </Typography>

                            <TextField
                                {...register('name', { required: true })}
                                error={!!errors.name}
                                helperText={
                                    !!errors.name && 'This field is required'
                                }
                                fullWidth
                                sx={{ maxWidth: { xs: 'auto', sm: 264 } }}
                            />
                        </Stack>
                        <Divider />
                    </Stack>

                    <Stack spacing={4}>
                        <div>
                            <Typography
                                variant="caption"
                                color="custom.gray.super"
                            >
                                Workspace settings
                            </Typography>
                            <Typography
                                component="p"
                                variant="body4"
                                color="custom.gray.super"
                                mb={4}
                            >
                                Only team admins can change the global settings.
                                Contact your administrator if you need to make
                                changes.
                            </Typography>
                        </div>

                        <Stack
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            direction={{ xs: 'column', sm: 'row' }}
                        >
                            <div>
                                <Typography
                                    variant="body3"
                                    color="primary.dark"
                                    fontWeight={500}
                                >
                                    Contacts
                                </Typography>
                                <Typography
                                    component="p"
                                    variant="body4"
                                    color="custom.gray.super"
                                >
                                    Choose whether new contacts are set to
                                    private or public by default.
                                </Typography>
                            </div>

                            <Controller
                                control={control}
                                name="contactsPublicByDefault"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        error={!!errors.contactsPublicByDefault}
                                        onChange={onChange}
                                        value={value}
                                        name="contacts"
                                        select
                                        fullWidth
                                        sx={{
                                            maxWidth: { xs: 'auto', sm: 264 },
                                        }}
                                    >
                                        <MenuItem value={true}>Public</MenuItem>
                                        <MenuItem value={false}>
                                            Private
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Stack>
                        <Divider />
                    </Stack>

                    <Stack spacing={4} direction="row">
                        <Button sx={{ width: 152 }} type="submit">
                            Save changes
                        </Button>
                        <Button
                            sx={{ width: 96 }}
                            variant="text"
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </SettingsLayout>
    );
};
