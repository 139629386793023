import Router, { Route } from 'preact-router';
import Api from './Api';
import Billing from './Billing';
import ExportLogs from './ExportLogs';
import Profile from './Profile';
import TeamInfo from './TeamInfo';
import Compliance from './Compliance';
import Profanity from './Profanity';
import Members from './Members';
import Integrations from './Integrations';
import IntegrationSettings from '../../containers/IntegrationSettings';
import { KnowledgeBase } from './KnowledgeBase/KnowledgeBase';
import { NotificationSettings } from './Notifications/NotificationSettings';
import { Widget } from './Widget/Widget';

export const Settings = () => {
    return (
        <Router>
            <Route path="/settings/team" component={TeamInfo} />
            <Route path="/settings/knowledge-base" component={KnowledgeBase} />
            <Route
                path="/settings/notifications"
                component={NotificationSettings}
            />
            <Route path="/settings/members" component={Members} />
            <Route path="/settings/integrations" component={Integrations} />
            <Route
                path="/settings/integrations/connect/:source"
                component={Integrations}
            />
            <Route
                path="/settings/integrations/:integrationId"
                component={IntegrationSettings}
            />
            <Route path="/settings/export" component={ExportLogs} />
            <Route path="/settings/billing" component={Billing} />
            <Route path="/settings/compliance" component={Compliance} />
            <Route path="/settings/api" component={Api} />
            <Route path="/settings/profanity" component={Profanity} />
            <Route path="/settings/widget" component={Widget} />
            <Route path="/settings" component={Profile} default />
        </Router>
    );
};
