import { UUID } from '../types/uuid';
import client from './http';
import {
    Conversation,
    ConversationsFilter,
    ConversationsFilterType,
    CreateConversationDTO,
} from './types';

export function getConversation(conversationId: string) {
    return client()
        .get(`/conversations/${conversationId}`)
        .then<Conversation>(({ data }) => data);
}

export type FindConversationDto = {
    inboxId: UUID;
    contactIds: (UUID | string)[];
};

export function findConversation(
    dto: FindConversationDto,
): Promise<Conversation> {
    return client()
        .post('/conversations/find', dto)
        .then<Conversation>((r) => r.data);
}

export function getOrCreateConversation(d: Partial<Conversation>) {
    return client()
        .post(`/conversations/getOrCreate`, d)
        .then<Conversation>((r) => {
            return r.data;
        });
}

export function getOrCreateConversationV2(data: CreateConversationDTO) {
    return client()
        .post(`/conversations/getOrCreateV2`, data)
        .then<Conversation>((r) => r.data);
}

export function deleteConversation(id: string) {
    return client()
        .delete(`/conversations/${id}`)
        .then<Conversation>((r) => r.data);
}

const CONVERSATIONS_DEFAULT_FILTER: ConversationsFilter = {
    type: ConversationsFilterType.Active,
    cohortsList: [],
};

export function getConversationsPaged(
    inboxId: string,
    filter = CONVERSATIONS_DEFAULT_FILTER,
    timestamp?: number,
): Promise<Conversation[]> {
    const params = new URLSearchParams();
    if (typeof timestamp === 'number' && timestamp > 0) {
        params.append('timestamp', timestamp.toString());
    }
    params.append('type', filter.type);

    if (filter.type === ConversationsFilterType.ByCohorts) {
        filter.cohortsList.forEach(({ id }) =>
            params.append('cohortsList', id.toString()),
        );
    }

    return client()
        .get(`conversations/inbox/${inboxId}`, {
            params,
        })
        .then((r) => r.data);
}

export function getCohortConversations(
    cohortId: number,
    timestamp?: number,
): Promise<Conversation[]> {
    return client()
        .get(`conversations/cohort/${cohortId}`, {
            params: { timestamp },
        })
        .then((r) => r.data);
}

export function getCampaignConversations(
    campaignId: number,
    timestamp?: number,
): Promise<Conversation[]> {
    return client()
        .get(`campaigns/${campaignId}/conversations`, {
            params: { timestamp },
        })
        .then((r) => r.data);
}

export function getOptedOutConversations(
    timestamp?: number,
): Promise<Conversation[]> {
    return client()
        .get('conversations/optedOut', {
            params: { timestamp },
        })
        .then((r) => r.data);
}

export function getConversations(): Promise<Conversation[]> {
    return client()
        .get('conversations/')
        .then((r) => r.data);
}

export function markConversationAsRead(conversationId: string): Promise<void> {
    return client()
        .post(`/conversations/${conversationId}/read`)
        .then((r) => r.data);
}

export function markConversationAsUnRead(
    conversationId: string,
): Promise<void> {
    return client()
        .post(`/conversations/${conversationId}/unread`)
        .then((r) => r.data);
}

export function assingUsersToConversation(
    conversationId: string,
    userIds: string[] = [],
): Promise<Conversation> {
    return client()
        .post(`/conversations/${conversationId}/assign`, { userIds })
        .then((r) => r.data);
}
export function summarizeConversation(conversationId: string): Promise<string> {
    return client()
        .get<string>(`/ai/conversation-summary/${conversationId}`)
        .then((r) => r.data);
}
