import WorkflowProvider from '../../components/workflows/WorkflowProvider';
import { Workflows } from './Workflows';

interface WorkflowsContainerProps {
    workflowId?: string;
}

const WorkflowsContainerProps = ({ workflowId }: WorkflowsContainerProps) => (
    <WorkflowProvider>
        <Workflows workflowId={workflowId} />
    </WorkflowProvider>
);

export default WorkflowsContainerProps;
