import { useCallback, useState } from 'preact/hooks';
import {
    AddressElement,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import styles from './BillingForm.module.scss';
import LockIcon from '../../../assets/icons/payment-flow/lock.svg?react';
import { route } from 'preact-router';
import { useAnalytics } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import { AnalyticsEventName } from '../../../types/AnalyticsEventNames';
import { Box } from '@mui/material';

export const BillingForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState('');
    const analytics = useAnalytics();
    const me = useMeQueryData();

    const dispatchPurchaseEvent = useCallback(() => {
        analytics.dispatch({
            payload: {
                eventPayload: {
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                },
                eventName: AnalyticsEventName.USER_CLICKED_PURCHASE,
            },
        });
    }, [me]);

    const handleSubmit = async (e: JSX.TargetedEvent) => {
        e.preventDefault();
        dispatchPurchaseEvent();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.origin,
            },
        });

        if (error) {
            setError(error.message || '');
        } else {
            setError('');
            route('/payment/finish');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles['root__payment-element']}>
                <PaymentElement
                    options={{
                        paymentMethodOrder: ['card'],
                        wallets: {
                            applePay: 'never',
                            googlePay: 'never',
                        },
                        layout: 'accordion',
                    }}
                />
                <Box mb={2} />
                <AddressElement
                    options={{
                        mode: 'billing',
                    }}
                />

                {error && <p className={styles['root__error']}>{error}</p>}
            </div>

            <button className={styles['root__confirm-btn']} type="submit">
                <LockIcon /> <span>Confirm & pay</span>
            </button>

            <p className={styles['root__security-disclaimer']}>
                Your payment is securely protected with AES 256-bit encryption
            </p>
        </form>
    );
};
