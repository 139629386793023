import { Integration, Profile } from '../../../api/types';
import { AvailableIntegration } from './integrations-list';

export const createIntegrationUrl = (
    availableIntegration: AvailableIntegration,
    user: Profile,
    extraState: Record<string, unknown> = {},
): string => {
    if (!availableIntegration.url) {
        return '#';
    }

    const url = new URL(availableIntegration.url);
    const state = {
        userId: user.id,
        teamId: user.activeTeam.id,
        integrationSource: availableIntegration.source,
        integrationType: 'contact',
        ...extraState,
    };

    url.searchParams.append('state', JSON.stringify(state));
    return url.href;
};

export const findIntegration =
    (integration: AvailableIntegration) => (myIntegration: Integration) =>
        myIntegration.integrationSource === integration.source &&
        myIntegration.integrationType === integration.integrationType;
