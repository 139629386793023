import { useState } from 'react';
import {
    Checkbox,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectProps,
    Typography,
} from '@mui/material';
import { SearchTextField } from '../../elements/SearchTextField/SearchTextField';
import * as styles from './styles';

export type Option = {
    id: string;
    label: string;
    description?: string;
    icon?: React.ReactNode;
};

export type EnhancedSelectProps<Value> = Omit<
    SelectProps<Value>,
    'onChange'
> & {
    options: Option[];
    searchable?: boolean;
    hasCheckbox?: boolean;
    onChange: (value: string, checked: boolean) => void;
    footer?: React.ReactNode;
    menuMaxWidth?: number;
};

export function EnhancedSelect<Value = unknown>({
    value,
    options,
    hasCheckbox,
    placeholder,
    searchable,
    onChange,
    footer,
    menuMaxWidth,
    ...rest
}: EnhancedSelectProps<Value>) {
    const [searchTerm, setSearchTerm] = useState('');
    const filteredOptions =
        searchable && searchTerm.length > 0
            ? options.filter((option) =>
                  option.label.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : options;

    return (
        <Select
            notched={false}
            displayEmpty={!!placeholder}
            value={value}
            placeholder={placeholder}
            renderValue={(selected: Value) => {
                if ((!selected || selected?.length === 0) && placeholder) {
                    return (
                        <Typography
                            variant="body3"
                            mr={3}
                            color="custom.gray.super"
                            noWrap
                        >
                            {placeholder}
                        </Typography>
                    );
                }

                if (Array.isArray(selected)) {
                    return (
                        <Typography variant="body3" mr={3} noWrap>
                            {selected
                                .map(
                                    (id) =>
                                        options.find((o) => o.id === id)
                                            ?.label ?? '',
                                )
                                .filter(Boolean)
                                .join(', ')}
                        </Typography>
                    );
                }

                const selectedItem = options.find((o) => o.id === selected);
                return (
                    <>
                        {!!selectedItem?.icon && (
                            <ListItemIcon>{selectedItem.icon}</ListItemIcon>
                        )}
                        <Typography variant="body3" mr={3} noWrap>
                            {selectedItem?.label ?? ''}
                        </Typography>
                    </>
                );
            }}
            {...rest}
        >
            {searchable && (
                <MenuItem
                    dense
                    divider
                    sx={styles.searchHeader}
                    onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
                >
                    <SearchTextField
                        fullWidth
                        value={searchTerm}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ sx: styles.searchInput }}
                        placeholder="Search"
                        onClick={(event: MouseEvent) => {
                            event.stopPropagation();
                        }}
                        onChange={(event) => {
                            event.stopPropagation();
                            setSearchTerm(event.target?.value);
                        }}
                    />
                </MenuItem>
            )}
            {filteredOptions.map((option: Option) => {
                const isChecked = Array.isArray(value)
                    ? value.includes(option.id)
                    : value === option.id;

                return (
                    <MenuItem
                        autoWidth
                        value={option.id}
                        key={option.id}
                        sx={{
                            maxWidth: menuMaxWidth,
                            ...(hasCheckbox
                                ? styles.menuItemWithCheckbox
                                : undefined),
                        }}
                        onClick={() => {
                            onChange(option.id, !isChecked);
                        }}
                    >
                        {hasCheckbox && (
                            <Checkbox
                                sx={styles.checkbox}
                                color="info"
                                checked={isChecked}
                            />
                        )}

                        {!!option.icon && (
                            <ListItemIcon>{option.icon}</ListItemIcon>
                        )}

                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'body3',
                                color: 'primary.dark',
                            }}
                            secondaryTypographyProps={{
                                variant: 'body4',
                                color: 'custom.gray.super',
                                sx: { whiteSpace: 'break-spaces' },
                            }}
                            primary={option.label}
                            secondary={option.description}
                        />
                    </MenuItem>
                );
            })}
            {footer && (
                <>
                    <Divider />
                    <MenuItem>{footer}</MenuItem>
                </>
            )}
        </Select>
    );
}
