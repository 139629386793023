import { Avatar, AvatarProps, Theme } from '@mui/material';
import { Profile } from '../../../api/types';
import { getName } from './get-profile-name';
import { useMemo } from 'preact/hooks';
import { getInitials } from './string.helpers';

const getPublicFileUrl = (id: string): string => {
    const url = new URL(import.meta.env.VITE_API_BASE_URL);
    url.pathname = `/files/${id}`;
    return url.toString();
};

type Props = { profile: Profile } & AvatarProps<Theme>;

export const ProfileAvatar = ({ profile, size = 40, ...props }: Props) => {
    const name = useMemo(
        () => getName(profile),
        [profile.firstname, profile.lastname, profile.email],
    );
    const initials = getInitials(name);

    const src =
        props.src ||
        (profile.avatarId ? getPublicFileUrl(profile.avatarId) : undefined);
    return (
        <Avatar
            {...props}
            size={size}
            src={src}
            alt={props.alt || getName(profile)}
        >
            {props.children || initials}
        </Avatar>
    );
};
