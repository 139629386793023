import { route } from 'preact-router';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { Message, ScheduledMessage } from '../../api/types';
import { useGetConversation } from '../../queries/conversations';
import { Messages } from '../Chat/Messages';
import { WorkflowHeader } from '../ConversationHeader/WorkflowHeader';
import { ConversationMessageForm } from '../MessageFormContainers/ConversationMessageForm';
import styles from './InboxWorkflow.module.scss';
import Loading from '../Loading/Loading';
import { useContactsQueryData } from '../../queries/contacts';
import { Box } from '@mui/material';
import { TypingIndicator } from '../TypingIndicator/TypingIndicator';

interface InboxConversationWorkflowProps {
    inboxId: string;
    isContactInfoShown?: boolean;
    conversationId?: string;
    toggleContactInfo: () => void;
    setContactForDetails: (contact: string | null) => void;
    setActiveDiscussion: (discussionId: number | null) => void;
}

export const InboxConversationWorkflow = (
    props: InboxConversationWorkflowProps,
) => {
    const contactsCache = useContactsQueryData();
    const [smForEdit, setSmForEdit] = useState<ScheduledMessage | null>(null);
    const [messageForEdit, setMessageForEdit] = useState<Message | null>(null);

    const { data: conversation, isPending } = useGetConversation(
        props.conversationId,
    );

    const isConversationDisabled = useMemo(() => {
        if (conversation && conversation.members.length === 1) {
            const contact = contactsCache.getByPhoneOrEmpty(
                conversation.members[0],
            );
            return !!(contact && contact?.optOut);
        }
        return false;
    }, [conversation?.members?.length, contactsCache, conversation?.id]);

    useEffect(() => {
        if (conversation && conversation.members.length === 1) {
            props.setContactForDetails(conversation.members[0]);
        }
        // Consider removing this if the parent component already handles it
        return () => {
            props.setContactForDetails(null);
        };
    }, [conversation?.members]);

    const onBackClick = useCallback(() => route(`/inbox/${props.inboxId}`), []);

    const handleActiveDiscussion = useCallback(
        (discussionId: number | null) => {
            props.setActiveDiscussion(discussionId);
            if (discussionId && props.isContactInfoShown) {
                props.toggleContactInfo();
            }
        },
        [
            props.isContactInfoShown,
            props.toggleContactInfo,
            props.setActiveDiscussion,
        ],
    );

    if (isPending) {
        return <Loading />;
    }

    return (
        <section className={styles['inbox-workflow']}>
            {conversation && (
                <>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'minmax(0, 1fr)',
                        }}
                    >
                        <WorkflowHeader
                            conversation={conversation}
                            onBackClick={onBackClick}
                            onToggleContactInfo={props.toggleContactInfo}
                        />
                    </Box>

                    <div className={styles['inbox-workflow__content']}>
                        <Messages
                            conversation={conversation}
                            setSmForEdit={setSmForEdit}
                            setMessageForEdit={setMessageForEdit}
                            setActiveDiscussion={handleActiveDiscussion}
                        />
                    </div>

                    <div>
                        <TypingIndicator conversationId={conversation.id} />
                    </div>

                    <div>
                        <ConversationMessageForm
                            conversation={conversation}
                            smForEdit={smForEdit}
                            messageForEdit={messageForEdit}
                            onEditCancel={() => {
                                setSmForEdit(null);
                                setMessageForEdit(null);
                            }}
                            disabled={isConversationDisabled}
                        />
                    </div>
                </>
            )}
        </section>
    );
};
