import { SxProps } from '@mui/material';

export const closeButton: SxProps = {
    position: 'absolute',
    right: 16,
    top: 16,
    color: 'custom.gray.super',
    padding: 0,

    '& svg': {
        width: 20,
        height: 20,
    },
};

export const dialogContent: SxProps = { maxWidth: 420, textAlign: 'center' };

export const content: SxProps = { marginTop: 6, textAlign: 'left' };

export const icon: SxProps = { marginBottom: 4, width: 48, height: 48 };

export const title: SxProps = { marginBottom: 2, textAlign: 'center' };

export const dissmissButton: SxProps = { width: 96 };

export const submitButton: SxProps = { width: 260 };
