import { useMeQueryData } from '../../queries/user';
import { ClerkPermissions } from '../../api/types';
import { ComponentChildren } from 'preact';

interface SubscribersOnlyProps {
    children: ComponentChildren;
    requiredPermissions: ClerkPermissions[];
}

export const SubscribersOnly = (props: SubscribersOnlyProps) => {
    const me = useMeQueryData();

    if (me?.activeTeam.tier === 'free') {
        return null;
    }
    return props.children;
};
