import { route } from 'preact-router';
import { ConversationsList } from './ConversationsList';
import Loading from '../Loading/Loading';
import { useOptedOutConversations } from '../../queries/conversations';
import historyIcon from '../../assets/icons/icons-20/history.svg';

export const OptedOutConversationsList = () => {
    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    } = useOptedOutConversations();

    if (isPending) {
        return <Loading />;
    }

    return (
        <ConversationsList
            name="With Opted Out Contacts"
            icon={<img src={historyIcon} alt="history" />}
            conversations={conversations}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            getHref={(conversationId) => `/opted-out/${conversationId}`}
            onTouchEnd={(conversationId) =>
                route(`/opted-out/${conversationId}`)
            }
            view="Cohort"
            isLoading={isPending}
        />
    );
};
