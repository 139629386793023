import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
// styles
import styles from './FilterSelectValue.module.scss';
// components
import {
    DropdownContainer,
    DropdownList,
    DropdownItem,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
// icons
import searchIcon from '../../../../assets/icons/icons-16/search.svg';
// utils
import { filterOptions } from '../utils';
import { UserPropertyFilterModel } from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

interface FilterSelectValueProps {
    userPropertyFilter: UserPropertyFilterModel<string>;
    changeHandler: (value: UserPropertyFilterModel) => void;
    closeHandler: () => void;
}

export const FilterSelectValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterSelectValueProps) => {
    const { value, options = [] } = userPropertyFilter;

    const [filterTerm, setFilterTerm] = useState<string>('');
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    const filteredOptions = filterTerm
        ? filterOptions(options, filterTerm)
        : options;

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div className={styles['filter__top-panel']}>
                <Input
                    wrapperClassName={styles['filter__search-input-wrapper']}
                    className={styles['filter__search-input']}
                    placeholder="Search"
                    prefix={<img src={searchIcon} />}
                    value={filterTerm}
                    onChange={setFilterTerm}
                    customRef={inputRef}
                />
            </div>

            <DropdownList className={styles['filter__list']}>
                {filteredOptions.map((option, i) => (
                    <DropdownItem
                        className={cc([
                            {
                                [styles['filter__item_selected']]:
                                    option === value,
                                [styles['filter__item_last']]:
                                    i === filteredOptions.length - 1,
                            },
                        ])}
                        key={option}
                        variant="list-item"
                        type="regular"
                        onClick={() => {
                            changeHandler({
                                ...userPropertyFilter,
                                value: option,
                            });
                            closeHandler();
                        }}
                    >
                        {option}
                    </DropdownItem>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
