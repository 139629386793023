import { Brand, BrandRegistrationStatus } from '../../../../api/types';

type Props = Pick<Brand, 'status' | 'registrationErrors'>;

export function Description({ status, registrationErrors }: Props) {
    if (!status) {
        return (
            <>
                Due to the federal messaging regulations, all companies are
                required to verify their business to be in compliance with{' '}
                <a
                    href="https://support.clerk.chat/compliance/10dlc"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    10DLC requirements
                </a>
                .
            </>
        );
    }

    if (status === BrandRegistrationStatus.FAILED) {
        return (
            <>
                Please correct the following errors:
                {registrationErrors?.map((error) => <p key={error}>{error}</p>)}
            </>
        );
    }

    if (status === BrandRegistrationStatus.PENDING) {
        return (
            <span>
                We have received your information. Once our managers review it,
                the company status will change. If we need additional
                information, we will contact you.
            </span>
        );
    }

    return null;
}
