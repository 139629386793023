import Router, { Route, route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { Inbox, InboxProvider } from '../../api/types';
import { useMeQueryData } from '../../queries/user';
import { canSetupInbox } from '../../utils/inboxes';
import { Redirect, RedirectProps } from '../../utils/redirect';
import styles from './AddInbox.module.scss';
import InboxCustomization, {
    InboxCustomizationProps,
} from './InboxCustomization';
import NewPhoneNumber, { NewPhoneNumberProps } from './NewPhoneNumber';
import PhoneNumberType, { PhoneNumberTypeProps } from './PhoneNumberType';
import ExistingPhoneNumber, {
    ExistingPhoneNumberProps,
} from './ExistingPhoneNumber';
import { AllowedCountries, CarrierCredentials } from './types';
import CarrierConnect, { CarrierConnectProps } from './CarrierConnect';
import CarrierPhoneNumber, {
    CarrierPhoneNumberProps,
} from './CarrierPhoneNumber';
import { useInboxes } from '../../queries/inboxes';
import MSTeamsPhoneNumbers from './MSTeamsPhoneNumbers';

export const AddInbox = () => {
    const [newInbox, setNewInbox] = useState<Partial<Inbox>>();
    const [inboxCountry, setInboxCountry] = useState<AllowedCountries>();
    const [carrier, setCarrier] = useState<InboxProvider>();
    const { data: inboxes = [] } = useInboxes();

    const [carrierCredentials, setCarrierCredentials] =
        useState<CarrierCredentials>();

    const me = useMeQueryData();

    useEffect(() => {
        if (!canSetupInbox(me!, inboxes)) {
            route('/contacts');
        }
    }, []);

    return (
        <div className={styles['root']}>
            <Router>
                <Route<PhoneNumberTypeProps>
                    path="/setup-inbox/number-type"
                    goToNumberProposal={() => route('/setup-inbox/new-number')}
                    goToExistingNumber={() =>
                        route('/setup-inbox/existing-number')
                    }
                    component={PhoneNumberType}
                />

                {/* New number setup flow */}
                {me?.activeTeam?.tier != 'free' && (
                    <Route<NewPhoneNumberProps>
                        path="/setup-inbox/new-number"
                        goBackward={() => route('/setup-inbox/number-type')}
                        goForward={() => route('/setup-inbox/customization')}
                        setNewInbox={setNewInbox}
                        setInboxCountry={setInboxCountry}
                        component={NewPhoneNumber}
                    />
                )}

                {/* Existing number setup flow */}
                <Route<ExistingPhoneNumberProps>
                    path="/setup-inbox/existing-number"
                    goBackward={() => route('/setup-inbox/number-type')}
                    goForward={() => route('/setup-inbox/customization')}
                    goToCarrierConnectSetup={(carrier: InboxProvider) => {
                        setCarrier(carrier);
                        route('/setup-inbox/carrier-connect');
                    }}
                    goToMsTeamsSetup={() => route('/setup-inbox/ms-teams/auth')}
                    setNewInbox={setNewInbox}
                    component={ExistingPhoneNumber}
                />

                {/* Teams Setup Flow */}
                <Route
                    path="/setup-inbox/ms-teams/:rest"
                    goBackward={() => route('/setup-inbox/existing-number')}
                    goForward={() => route('/setup-inbox/customization')}
                    setNewInbox={setNewInbox}
                    component={MSTeamsPhoneNumbers}
                />

                {/* Twilio number setup flow */}
                <Route<CarrierConnectProps>
                    path="/setup-inbox/carrier-connect"
                    goBackward={() => route('/setup-inbox/existing-number')}
                    goForward={() => route('/setup-inbox/carrier-number')}
                    setCredentials={setCarrierCredentials}
                    carrier={carrier!}
                    component={CarrierConnect}
                />
                <Route<CarrierPhoneNumberProps>
                    path="/setup-inbox/carrier-number"
                    goBackward={() => route('/setup-inbox/carrier-connect')}
                    goForward={() => route('/setup-inbox/customization')}
                    inbox={newInbox!}
                    carrier={carrier!}
                    setNewInbox={setNewInbox}
                    component={CarrierPhoneNumber}
                />

                <Route<InboxCustomizationProps>
                    path="/setup-inbox/customization"
                    inbox={newInbox!}
                    inboxCountry={inboxCountry!}
                    carrierCredentials={carrierCredentials}
                    carrier={carrier}
                    goBackward={() =>
                        newInbox?.provider === InboxProvider.BANDWIDTH
                            ? route('/setup-inbox/new-number')
                            : route('/setup-inbox/existing-number')
                    }
                    goForward={() => null}
                    component={InboxCustomization}
                />

                {/* Default redirect */}
                <Route<RedirectProps>
                    path="/setup-inbox"
                    to="/setup-inbox/number-type"
                    component={Redirect}
                />
            </Router>
        </div>
    );
};
