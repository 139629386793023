import client from './http';
import { Cohort, CohortMeta } from './types';
import { UUID } from '../types/uuid';

export interface CohortMetaDto {
    id: number;
    name: string;
    icon?: string;
    isPublic: boolean;
    updated: string;
}

export interface CohortModifyContactsDto {
    contactIds: UUID[];
    cohortIds: number[];
}

export interface CohortIdName {
    id: number;
    name: string;
}

export function getCohorts(): Promise<CohortMeta[]> {
    return client()
        .get('/cohorts')
        .then((res) => res.data);
}

export function getCohort(id: number): Promise<Cohort> {
    return client()
        .get(`/cohorts/${id}`)
        .then((res) => res.data);
}

export function getCohortsByContact(): Promise<Record<string, CohortIdName[]>> {
    return client()
        .get(`/cohorts/by-contact`)
        .then((res) => res.data);
}

export function createCohort(payload: Partial<Cohort>): Promise<Cohort> {
    return client()
        .post('/cohorts', payload)
        .then((res) => res.data);
}

export function updateCohort(payload: Partial<Cohort>): Promise<Cohort> {
    return client()
        .put('/cohorts', payload)
        .then((res) => res.data);
}

export function removeCohort(id: number): Promise<void> {
    return client()
        .delete(`/cohorts/${id}`)
        .then((res) => res.data);
}

export function addContactsToCohorts(dto: CohortModifyContactsDto) {
    return client().post('/cohorts/add-contacts', dto);
}

export function removeContactsFromCohorts(dto: CohortModifyContactsDto) {
    return client().post('/cohorts/remove-contacts', dto);
}
