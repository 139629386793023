import { useMemo } from 'preact/hooks';
import { useToastContext } from '../../../containers/toast/reducer';
import {
    mapLegacy,
    useCampaignCreate,
    useCampaignCreateQuery,
} from '../../../queries/campaigns';
import { useNewConversationCreateQuery } from '../../../queries/conversations';
import { UUID } from '../../../types/uuid';
import MessageForm, { MessageFormData } from '../../MessageForm';
import { handleError } from '../../MessageFormContainers/utils';
import { useNewConversationContext } from '../NewConversationProvider';
import { useCampaignV3Enabled } from '../../../helpers/use-campaign-v3-enabled';
import { useMessageCreateFromInbox } from './use-message.query';
import { useConfirmSending } from '../../../main/campaign/v3/use-confirm-sending';

interface NewConversationMessageProps {
    inboxId: UUID;
    onFinish: (
        entityId: string | number,
        view: 'conversation' | 'campaign',
    ) => void;
}

export const NewConversationMessage = (props: NewConversationMessageProps) => {
    const { state } = useNewConversationContext();
    const { dispatch: toastDispatch } = useToastContext();

    const messageCreation = useMessageCreateFromInbox();
    const {
        mutateAsync: createConversation,
        isPending: createConversationLoading,
    } = useNewConversationCreateQuery();
    const { mutateAsync: createCampaign, isPending: createCampaignLoading } =
        useCampaignCreateQuery();
    const { mutateAsync: createCampaignV3 } = useCampaignCreate();

    const placeholder = useMemo(() => {
        if (state.mode === 'campaign') {
            return 'Write a campaign message...';
        }
        if (state.selectedContactIds.length > 1) {
            return 'Write a group message...';
        }
        return 'Write a message...';
    }, [state.selectedContactIds, state.cohortId, state.mode]);

    const isCampaignV3 = useCampaignV3Enabled();
    const [confirm, ConfirmDialog] = useConfirmSending();

    const submitForm = async ({
        message,
        sendAt,
        files,
        attachments,
    }: MessageFormData) => {
        const trimmedMsg = message.trim();

        if (
            trimmedMsg.trim().length < 1 &&
            ((!isCampaignV3 && !files?.length) ||
                (isCampaignV3 && !attachments?.length))
        ) {
            return Promise.reject();
        }

        const data = sendAt
            ? {
                  inboxId: props.inboxId,
                  fileIds: files?.map((f) => f.id),
                  attachments: attachments?.map(({ id }) => id),
                  contactIds: state.selectedContactIds,
                  message,
                  sendAt,
              }
            : {
                  inboxId: props.inboxId,
                  fileIds: files?.map((f) => f.id),
                  attachments: attachments?.map(({ id }) => id),
                  contactIds: state.selectedContactIds,
                  message,
              };

        if (state.mode === 'campaign' || state.selectedContactIds.length > 10) {
            if (isCampaignV3) {
                if (await confirm()) {
                    return createCampaignV3(mapLegacy(data))
                        .then(({ id }) => props.onFinish(id, 'campaign'))
                        .catch(handleError(toastDispatch));
                }
                return Promise.reject();
            }
            return createCampaign(data)
                .then(({ id }) => props.onFinish(id, 'campaign'))
                .catch(handleError(toastDispatch));
        }

        if (isCampaignV3) {
            return messageCreation
                .mutateAsync(mapLegacy(data))
                .then(({ conversationId }) =>
                    props.onFinish(conversationId, 'conversation'),
                )
                .catch(handleError(toastDispatch));
        }
        return createConversation(data)
            .then(({ id }) => props.onFinish(id, 'conversation'))
            .catch(handleError(toastDispatch));
    };

    return (
        <>
            <MessageForm
                initial={null}
                placeholder={placeholder}
                onSend={submitForm}
                templatesAvailable={
                    state.mode === 'campaign' ||
                    state.selectedContactIds.length === 1
                }
                disabled={
                    state.selectedContactIds.length === 0 ||
                    createConversationLoading ||
                    createCampaignLoading
                }
                noAutofocus
            />
            <ConfirmDialog contactsCount={state.selectedContactIds.length} />
        </>
    );
};
