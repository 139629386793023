import styles from './CarrierConnect.module.scss';
import TelnyxIcon from '../../../assets/icons/carriers/telnyx.svg?react';
import { CarrierConnectProps } from './CarrierConnect';
import { useEffect, useState } from 'preact/hooks';
import StepShell from '../StepShell';
import { useListChannelsQuery } from '../../../queries/inboxes';
import { InboxProvider } from '../../../api/types';
import { Button, TextField } from '@mui/material';

export const TelnyxConnect = ({
    goBackward,
    goForward,
    setCredentials,
}: CarrierConnectProps) => {
    const [apiKey, setApiKey] = useState('');
    const [isEnabled, setEnabled] = useState(false);

    const { data, isFetching } = useListChannelsQuery(
        {
            provider: InboxProvider.TELNYX,
            telnyxApiKey: apiKey,
        },
        isEnabled,
    );

    useEffect(() => {
        if (data && data.length > 0) {
            setCredentials({ apiKey });
            goForward();
            setEnabled(false);
        }
    }, [data]);

    return (
        <StepShell
            title="Telnyx setup"
            subtitle="The Api Key can be found in your Telnyx dashboard."
            icon={<TelnyxIcon />}
            goBackward={goBackward}
        >
            <div className={styles['root__content']}>
                <p className={styles['root__country-field']}>
                    <TextField
                        icon="key"
                        placeholder="API Key"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        fullWidth
                        type="password"
                    />
                </p>

                <Button
                    onClick={() => {
                        setEnabled(true);
                    }}
                    disabled={!apiKey || isFetching}
                    type="submit"
                    fullWidth
                >
                    {isFetching ? 'Loading...' : 'Proceed'}
                </Button>
            </div>
        </StepShell>
    );
};
