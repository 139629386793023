import { CohortInner } from './CohortInner';
import Loading from '../../components/Loading/Loading';
import { useCohortQuery } from '../../queries/cohorts';
import { usePropertiesQuery } from '../../queries/properties';

interface CohortProps {
    cohortId: string;
    conversationId: string;
}

export function Cohort({ cohortId, conversationId }: CohortProps) {
    const { cohort, isPending } = useCohortQuery(Number.parseInt(cohortId));
    usePropertiesQuery();

    if (isPending) {
        return <Loading />;
    }

    return <CohortInner cohort={cohort} conversationId={conversationId} />;
}
