import { useState } from 'preact/hooks';
import { MSTeamsAuthStep } from './AuthStep';
import { MSTeamsPhoneSelectionStep } from './PhoneSelectionStep';
import { TeamsPhone } from '../../../api/types';
import { InformationStep } from './InformationStep';
import { ConfirmStep } from './ConfirmStep';
import { SuccessStep } from './SuccessStep';
import Router, { Route, route } from 'preact-router';
import { ActivationDate } from './ActivationDate';

export type MSTeamsPhoneNumbersProps = {
    selectedPhones?: TeamsPhone[];
    setSelectedPhones?: (phone: TeamsPhone) => void;
    setActivationDate?: (date: Date) => void;
    activationDate?: Date;
    goForward: () => void;
    goBack?: () => void;
};

export const MSTeamsPhoneNumbers = () => {
    const [selectedPhones, setSelectedPhones] = useState<TeamsPhone[]>([]);
    const [activationDate, setActivationDate] = useState<Date>();

    const updateSelectedPhones = (phone: TeamsPhone) => {
        if (selectedPhones.find((p) => p.phone === phone.phone)) {
            setSelectedPhones(
                selectedPhones.filter((p) => p.phone !== phone.phone),
            );
        } else {
            setSelectedPhones([...selectedPhones, phone]);
        }
    };

    return (
        <Router>
            <Route
                path="/setup-inbox/ms-teams/auth"
                component={MSTeamsAuthStep}
                goForward={() => route('/setup-inbox/ms-teams/select-phone')}
            />
            <Route
                path="/setup-inbox/ms-teams/select-phone"
                component={MSTeamsPhoneSelectionStep}
                selectedPhones={selectedPhones}
                setSelectedPhones={updateSelectedPhones}
                goForward={() => route('/setup-inbox/ms-teams/information')}
                goBack={() => route('/setup-inbox/ms-teams/auth')}
            />
            <Route
                path="/setup-inbox/ms-teams/information"
                component={InformationStep}
                goForward={() => route('/setup-inbox/ms-teams/activation-date')}
                goBack={() => route('/setup-inbox/ms-teams/select-phone')}
                selectedPhones={selectedPhones}
            />
            <Route
                path="/setup-inbox/ms-teams/activation-date"
                component={ActivationDate}
                goForward={() => route('/setup-inbox/ms-teams/confirm')}
                goBack={() => route('/setup-inbox/ms-teams/information')}
                activationDate={activationDate}
                setActivationDate={setActivationDate}
                selectedPhones={selectedPhones}
            />
            <Route
                path="/setup-inbox/ms-teams/confirm"
                component={ConfirmStep}
                goForward={() => route('/setup-inbox/ms-teams/success')}
                goBack={() => route('/setup-inbox/ms-teams/activation-date')}
                selectedPhones={selectedPhones}
                activationDate={activationDate}
            />
            <Route
                path="/setup-inbox/ms-teams/success"
                component={SuccessStep}
                selectedPhones={selectedPhones}
                goForward={() => route('/')}
            />
        </Router>
    );
};
