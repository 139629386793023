import cc from 'classcat';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import { Inbox, InboxStatus } from '../../api/types';
import { useInboxUpdateQuery } from '../../queries/inboxes';
import styles from './InboxItem.module.scss';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { useUnreadMessagesForInbox } from '../../queries/messages';
import AttentionIcon from '../../assets/icons/different/attention.svg?react';
import GreenCheckIcon from '../../assets/icons/different/greencheck.svg?react';
import Tooltip from '../Tooltip';
import EmojiPicker from '../../components/EmojiPicker';
import { usePopper } from 'react-popper';
import { createPortal } from 'preact/compat';
import { renderInboxIcon } from '../../utils/inboxes';
import dayjs from 'dayjs';

interface InboxItemProps {
    index: number;
    inbox: Inbox;
    isMuted?: boolean;
    countryCode?: string;
    onClick?: () => void;
}

const INBOX_NAME_PALCEHOLDER = 'Inbox';

const getPath = (inbox: Inbox) => {
    switch (inbox.status) {
        case InboxStatus.ACTIVE:
            return `/inbox/${inbox.id}`;
        case InboxStatus.PENDING:
            return `/inbox/${inbox.id}/verify/customer`;
        case InboxStatus.PORT_SUBMITTED:
            return '#';
        default:
            return '#';
    }
};

function getStatusText(status: InboxStatus, inbox: Inbox): string {
    switch (status) {
        case InboxStatus.PENDING:
            return 'Please click to verify phone';
        case InboxStatus.PORT_SUBMITTED:
            return `Verified. Your number will be active on ${
                inbox.requestedActivationDate
                    ? dayjs(inbox.requestedActivationDate).format(
                          'MMMM D, hh:mm A',
                      )
                    : 'the next business day'
            }`;
        case InboxStatus.PORT_FAILED:
            return 'Verification failed. Please schedule onboarding here https://calendly.com/clerkchat/verify';
        default:
            return '';
    }
}

const InboxItem = ({ index, inbox, countryCode, onClick }: InboxItemProps) => {
    const {
        id,
        icon,
        phone = '',
        name = INBOX_NAME_PALCEHOLDER,
        status,
    } = inbox;

    const [isIconEditorActive, setIconEditorActive] = useState(false);

    const { data: unreads = [] } = useUnreadMessagesForInbox(id);
    const { mutate } = useInboxUpdateQuery();

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null,
    );
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
        null,
    );
    const { styles: popperStyles, attributes } = usePopper(
        containerRef,
        popperElement,
        {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [160, 18],
                    },
                },
            ],
        },
    );

    return (
        <Draggable key={inbox.id} draggableId={inbox.id} index={index}>
            {(providedDraggable: DraggableProvided, snapshot) => (
                /*@ts-ignore*/
                <li
                    className={styles['droppable-li']}
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                >
                    <Link
                        class={cc([
                            'clerk-reset-link',
                            styles['inbox-item'],
                            {
                                [styles['inbox-item_dragging']]:
                                    snapshot.isDragging,
                            },
                        ])}
                        activeClassName={styles['inbox-item_active']}
                        onClick={onClick}
                        href={getPath(inbox)}
                        path={`${getPath(inbox)}/:rest*`}
                    >
                        <div
                            className={styles['inbox-item__icon_placeholder']}
                            ref={setContainerRef}
                        >
                            <button
                                className={styles['inbox-item__icon_btn']}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIconEditorActive(true);
                                }}
                            >
                                <div
                                    className={styles['inbox-item__icon_emoji']}
                                >
                                    {renderInboxIcon(icon)}
                                </div>
                            </button>
                            {isIconEditorActive &&
                                createPortal(
                                    <div
                                        ref={setPopperElement}
                                        style={popperStyles.popper}
                                        {...attributes.popper}
                                    >
                                        <EmojiPicker
                                            onClick={(val) => {
                                                if (val) {
                                                    const hex =
                                                        val.codePointAt(0);

                                                    if (hex) {
                                                        mutate({
                                                            icon: hex.toString(
                                                                16,
                                                            ),
                                                            id: inbox.id,
                                                        });
                                                    }
                                                    setIconEditorActive(false);
                                                }
                                            }}
                                            onClose={() =>
                                                setIconEditorActive(false)
                                            }
                                        />
                                    </div>,
                                    document.querySelector('#portal')!,
                                )}
                        </div>

                        <div
                            className={cc([
                                styles['inbox-item__description'],
                                styles['inbox-item__no-overflow'],
                            ])}
                        >
                            <p
                                className={cc([
                                    styles['inbox-item__name'],
                                    [styles['inbox-item__no-overflow']],
                                ])}
                            >
                                <span>{name || INBOX_NAME_PALCEHOLDER}</span>
                            </p>
                            <p className={styles['inbox-item__phone']}>
                                {formatPhoneNumber(phone, countryCode)}
                            </p>
                        </div>
                        <div className={styles['inbox-item__extra']}>
                            {status != InboxStatus.ACTIVE && (
                                <Tooltip
                                    width="150px"
                                    text={getStatusText(status, inbox)}
                                >
                                    <GreenCheckIcon />
                                </Tooltip>
                            )}
                            {status === InboxStatus.DISABLED && (
                                <Tooltip
                                    width="150px"
                                    text="This phone number has been disabled"
                                >
                                    <AttentionIcon />
                                </Tooltip>
                            )}
                            {unreads.length > 0 && (
                                <div className={styles['inbox-item__counter']}>
                                    {unreads?.length >= 10
                                        ? '9+'
                                        : unreads.length}
                                </div>
                            )}
                        </div>
                    </Link>
                </li>
            )}
        </Draggable>
    );
};

export default InboxItem;
