import cc from 'classcat';
import { route } from 'preact-router';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { validate as uuidValidate } from 'uuid';
import { InboxConversationsList } from '../../components/ConversationsList/InboxConversationsList';
import InboxSetupFinish from '../../components/InboxSetupFinish';
import {
    InboxCampaignWorkflow,
    InboxConversationWorkflow,
} from '../../components/InboxWorkflow';
import Loading from '../../components/Loading/Loading';
import { ChatEditContactContainer } from '../../containers/ChatEditContactContainer/ChatEditContactContainer';
import * as styles from './styles';
import { useInboxes } from '../../queries/inboxes';
import { useInboxContext } from '../../contexts/InboxContext';
import ConversationDiscussion from '../../components/ConversationDiscussion';
import NewConversation from '../../components/NewConversation';
import { AnalyticsActions, useAnalytics } from '../../contexts/analytics';
import { AnalyticsEventName } from '../../types/AnalyticsEventNames';
import { isMobile } from '../../utils/mobile';
import { usePropertiesQuery } from '../../queries/properties';
import { useCampaignV3Enabled } from '../../helpers/use-campaign-v3-enabled';
import { Campaign } from '../campaign/v3/Campaign';
import { CampaignMessage } from '../campaign/v3/CampaignMessage/CampaignMessage';
import { useGetConversation } from '../../queries/conversations';
import { Box } from '@mui/material';

type View = 'conversation' | 'campaign' | 'campaigns' | 'finish' | null;

const detectView = (entityId: string): View => {
    if (entityId === 'finish') {
        return 'finish';
    }

    if (entityId === 'campaigns') {
        return 'campaigns';
    }

    if (uuidValidate(entityId)) {
        return 'conversation';
    }

    const campaignId = Number.parseInt(entityId, 10);
    if (
        entityId.length !== 36 && //definitely not a UUID
        Number.isFinite(campaignId) &&
        !Number.isNaN(campaignId) &&
        campaignId > 0
    ) {
        return 'campaign';
    }

    return null;
};

export interface InboxProps {
    inboxId: string;
    actionId: string;
    campaignId?: number;
    campaignMessageId?: string;
}

export function Inbox(props: InboxProps) {
    usePropertiesQuery();

    const { dispatch } = useAnalytics();
    const { data: inboxes = [], isLoading } = useInboxes();
    const [activeDiscussion, setActiveDiscussion] = useState<number | null>(
        null,
    );
    const [isCreationFlow, setCreationFlow] = useState(false);
    const [view, setView] = useState<View>(detectView(props.actionId));
    const [contactForDetails, setContactForDetails] = useState<string | null>(
        null,
    );

    const inbox = useMemo(
        () => inboxes.find(({ id }) => id === props.inboxId),
        [inboxes, props.inboxId],
    );

    const { data: conversation } = useGetConversation(props.actionId);
    const hasContactInfo = useMemo(() => {
        return (
            view === 'conversation' &&
            inbox &&
            conversation?.members.length === 1 &&
            !isMobile()
        );
    }, [view, inbox, conversation?.members.length]);

    const [isContactInfoShown, setContactInfoShown] = useState(hasContactInfo);

    const toggleContactInfo = useCallback(() => {
        if (!isContactInfoShown) {
            setActiveDiscussion(null);
        }
        setContactInfoShown((x) => (hasContactInfo ? !x : false));
    }, [hasContactInfo, isContactInfoShown]);

    useEffect(() => {
        if (activeDiscussion) {
            setContactInfoShown(false);
        }
    }, [activeDiscussion]);

    useEffect(() => {
        setView(detectView(props.actionId));
    }, [props.actionId]);

    const { actions } = useInboxContext();

    useEffect(() => {
        if (inbox) {
            actions?.setActiveInbox(inbox);
        }
        setContactInfoShown(!!hasContactInfo);
        setActiveDiscussion(null);
    }, [inbox, hasContactInfo]);

    const onCreateClick = useCallback(() => {
        dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: AnalyticsEventName.CLICKED_CREATE_CONVERSATION,
                eventPayload: {
                    inbox_id: inbox?.id,
                },
            },
        });
        setCreationFlow(true);
        setView(null);
        route(`/inbox/${inbox?.id}`);
    }, [dispatch, inbox?.id]);

    const isCampaignV3 = useCampaignV3Enabled();
    const onFinishConversation = useCallback(
        (entityId: string | number, view: View) => {
            setCreationFlow(false);
            setView(view);
            if (view === 'conversation') {
                route(`/inbox/${inbox?.id}/${entityId}`);
            }
            if (view === 'campaign') {
                if (isCampaignV3) {
                    route(`/inbox/${inbox?.id}/campaigns/${entityId}`);
                } else {
                    route(`/campaigns/${entityId}`);
                }
            }
        },
        [inbox],
    );

    const onCanceledConversation = useCallback(() => {
        setCreationFlow(false);
    }, [inbox]);

    const onConversationDiscussionClose = useCallback(() => {
        setActiveDiscussion(null);
    }, [setActiveDiscussion]);

    if (isLoading || !inbox) {
        return <Loading />;
    }

    return (
        <Box
            sx={styles.inbox}
            className={cc({
                contactInfoShown: isContactInfoShown,
                discussionShown: activeDiscussion,
            })}
        >
            {inbox && (
                <Box
                    sx={styles.conversationsList}
                    className={cc({
                        mobileHidden:
                            isCreationFlow ||
                            contactForDetails ||
                            (view &&
                                ['conversation', 'campaign'].includes(view)),
                    })}
                >
                    <InboxConversationsList
                        key={inbox?.id}
                        inbox={inbox}
                        campaignId={props.campaignId}
                        onCreateClick={onCreateClick}
                    />
                </Box>
            )}
            {isCreationFlow && inbox && !view && (
                <NewConversation
                    inboxId={inbox?.id}
                    onFinish={onFinishConversation}
                    onCancel={onCanceledConversation}
                />
            )}

            {view === 'finish' && <InboxSetupFinish />}

            {view === 'conversation' && inbox && (
                <InboxConversationWorkflow
                    inboxId={inbox?.id}
                    isContactInfoShown={isContactInfoShown}
                    conversationId={props.actionId as string}
                    setContactForDetails={setContactForDetails}
                    toggleContactInfo={toggleContactInfo}
                    setActiveDiscussion={setActiveDiscussion}
                />
            )}

            {view === 'campaign' && (
                <InboxCampaignWorkflow
                    campaignId={Number.parseInt(props.actionId)}
                    toggleContactInfo={toggleContactInfo}
                />
            )}

            {view === 'campaigns' && !props.campaignMessageId && (
                <Campaign
                    campaignId={
                        props.campaignId ? +props.campaignId : undefined
                    }
                />
            )}

            {view === 'campaigns' &&
                !!props.campaignMessageId &&
                !!props.campaignId && (
                    <CampaignMessage
                        campaignId={+props.campaignId}
                        campaignMessageId={+props.campaignMessageId}
                    />
                )}

            {isContactInfoShown && contactForDetails && (
                <ChatEditContactContainer
                    contactPhone={contactForDetails}
                    isContactInfoShown={isContactInfoShown}
                    onBack={toggleContactInfo}
                />
            )}

            {activeDiscussion !== null && (
                <ConversationDiscussion
                    discussionId={activeDiscussion}
                    onClose={onConversationDiscussionClose}
                />
            )}
        </Box>
    );
}
