import { Contact } from '../api/types';
import { CONTACT } from '../queries/contacts';
import { queryClient } from '../queries/queryClient';

export const CONTACT_UPDATED_EVENT = 'contact:updated';
export type ContactUpdateHandler = (c: Contact) => void;

type DataPayload = Contact;

export const handleContactUpdatedEvent = async (data: DataPayload) => {
    await queryClient.refetchQueries({ queryKey: [CONTACT, data.id] });
};
