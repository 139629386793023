import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CityIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.17142 2.80166C2.35568 2.68778 2.58577 2.67743 2.77951 2.7743L7.5 5.13454L12.2205 2.7743C12.3964 2.68632 12.6036 2.68632 12.7795 2.7743L17.7795 5.2743C17.9912 5.38017 18.125 5.59658 18.125 5.83331V16.6666C18.125 16.8833 18.0128 17.0844 17.8286 17.1983C17.6443 17.3122 17.4142 17.3225 17.2205 17.2257L12.5 14.8654L7.77951 17.2257C7.60355 17.3136 7.39645 17.3136 7.22049 17.2257L2.22049 14.7257C2.00875 14.6198 1.875 14.4034 1.875 14.1666V3.33331C1.875 3.1167 1.98716 2.91554 2.17142 2.80166ZM13.125 13.7804L16.875 15.6554V6.21959L13.125 4.34458V13.7804ZM11.875 4.34458V13.7804L8.125 15.6554V6.21958L11.875 4.34458ZM6.875 6.21958L3.125 4.34458V13.7804L6.875 15.6554V6.21958Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
