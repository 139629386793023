import { useEffect, useState } from 'preact/hooks';
import Input from '../../../elements/Inputs/Input';
import styles from './SuggestInput.module.scss';
import Button from '../../../elements/Buttons';
import { useMutation } from '@tanstack/react-query';
import { UserPropertyFilterModel } from '../../UserInfoProperty/types';
import cc from 'classcat';
import { ContactPropertyType } from '../../../api/types';
import { getFilterSuggestions } from '../../../queries/ai';
import Icon from '../../../icons/Icon';

const dateTypes = [
    ContactPropertyType.Date,
    ContactPropertyType.LastEditedTime,
    ContactPropertyType.CreatedTime,
];

const convertDateValues = (suggestion: UserPropertyFilterModel) =>
    dateTypes.includes(suggestion?.type)
        ? {
              ...suggestion,
              value: new Date(suggestion.value),
          }
        : suggestion;

type Props = {
    onSuggestion: (suggestions: UserPropertyFilterModel[]) => void;
    inputClassName?: string;
    buttonClassName?: string;
    formClassName?: string;
};

export const SuggestInput = ({
    onSuggestion,
    inputClassName = '',
    buttonClassName = '',
    formClassName = '',
}: Props) => {
    const [prompt, setPrompt] = useState<string>('');
    const mutation = useMutation({
        mutationKey: ['ai_suggestion', prompt],
        mutationFn: () => getFilterSuggestions(prompt.trim()),
    });

    const submitPrompt = (e: Event) => {
        e.preventDefault();
        if (prompt.trim()) {
            mutation.mutate();
        }
    };

    useEffect(() => {
        if (mutation.data) {
            onSuggestion(mutation.data.map(convertDateValues));
        }
    }, [mutation.data]);

    return (
        <form
            onSubmit={submitPrompt}
            className={cc([styles['root'], formClassName])}
        >
            <Input
                disabled={mutation.isPending}
                type="text"
                color="purple"
                className={cc([styles['root_input'], inputClassName])}
                placeholder="Type in your prompt..."
                prefix={<Icon name="sparkles" size="16px" />}
                value={prompt}
                onChange={setPrompt}
                fullWidth
            />
            <Button
                disabled={mutation.isPending}
                size="small"
                type="purple"
                slim
                className={cc([styles['root_button'], buttonClassName])}
                onClick={submitPrompt}
            >
                {mutation.isPending ? 'Loading...' : 'Submit'}
            </Button>
        </form>
    );
};
