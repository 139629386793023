import styles from './ContactsDeleteDialog.module.scss';
import Button from '../../elements/Buttons';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../elements/Dialog';
import Icon from '../../icons/Icon';
import { useContactDeleteQuery } from '../../queries/contacts';
import { UUID } from '../../types/uuid';

interface ContactsDeleteDialogProps {
    selected: Set<UUID>;
    onClose: () => void;
    onDelete: () => void;
}

export const ContactsDeleteDialog = (props: ContactsDeleteDialogProps) => {
    const mutation = useContactDeleteQuery();

    return (
        <Dialog className={styles['root']}>
            <DialogTitle onClose={props.onClose}>Delete contacts</DialogTitle>
            <DialogContent className={styles['root__content']}>
                <p className={styles['root__info']}>
                    Are you sure you&apos;d like to delete{' '}
                    <b>{props.selected.size} contacts</b>? This action cannot be
                    undone.
                </p>
            </DialogContent>
            <DialogActions className={styles['root__actions']}>
                <Button type="default" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    className={styles['root__yes-btn']}
                    type="default"
                    icon={<Icon name="delete" />}
                    onClick={() => {
                        mutation.mutate({ ids: Array.from(props.selected) });
                        props.onDelete();
                    }}
                >
                    Yes, delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
