import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './DroplistItem.module.scss';

interface DroplistItemProps {
    children?: ComponentChildren;
    className?: string;
    onClick?: () => void;
}

export const DroplistItem = (props: DroplistItemProps) => (
    <div
        className={cc([styles['root'], props.className])}
        onClick={props.onClick}
    >
        <div className={styles['root__inner']}>{props.children}</div>
    </div>
);
