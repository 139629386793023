import { createPortal, useCallback, useRef, useState } from 'preact/compat';
import cc from 'classcat';
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';
import { usePopperConfig } from './hooks';
import { options, Option } from './options';
import styles from './PhoneNumberInput.module.scss';
import { DropdownContainer, DropdownItem, DropdownList } from '../Dropdown';
import Icon from '../../icons/Icon';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface InputResult {
    countryCode: CountryCode | null;
    phoneNumber: string;
    isValid: boolean;
}

interface PhoneNumberInputProps {
    initValue?: string;
    onChange: (value: InputResult) => void;
}

export const PhoneNumberInput = ({
    initValue = '',
    onChange,
}: PhoneNumberInputProps) => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Option | null>(null);
    const [value, setValue] = useState(initValue);

    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const collapse = useCallback(() => {
        setIsOpen(false);
    }, []);

    useOnClickOutside([containerRef, innerRef], collapse);
    const { setRefEl, setPopEl, popStyles, popAttributes } = usePopperConfig();

    const focusInput = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.selectionStart =
                        inputRef.current.selectionEnd =
                            inputRef.current.value.length;
                }
            }, 0);
        }
    }, [inputRef]);

    return (
        <div
            className={cc([
                styles['root'],
                {
                    [styles['root_active']]: isOpen,
                    [styles['root_error']]: !isValid,
                },
            ])}
            ref={containerRef}
        >
            <div
                className={styles['root__country-value']}
                ref={setRefEl}
                onClick={() => {
                    setIsOpen(true);
                    setIsValid(true);
                }}
            >
                {selected ? selected.icon : <Icon name="country" />}

                <Icon name="list" size="16px" />
            </div>
            <input
                className={styles['root__input']}
                placeholder="Phone number"
                ref={inputRef}
                value={value}
                onChange={(e: JSX.TargetedEvent<HTMLInputElement>) =>
                    setValue(e.currentTarget.value)
                }
                onBlur={(e: JSX.TargetedEvent<HTMLInputElement>) => {
                    console.log(
                        isValidPhoneNumber(e.currentTarget.value, 'US'),
                    );
                    const isNumberValid =
                        selected?.id === 'other'
                            ? isValidPhoneNumber(e.currentTarget.value)
                            : isValidPhoneNumber(
                                  e.currentTarget.value,
                                  selected?.id,
                              );
                    setIsValid(isNumberValid);
                    onChange({
                        countryCode:
                            selected && selected?.id !== 'other'
                                ? selected.id
                                : null,
                        phoneNumber: e.currentTarget.value,
                        isValid: isNumberValid,
                    });
                }}
            />
            {isOpen &&
                createPortal(
                    <div
                        ref={setPopEl}
                        style={{
                            ...popStyles.popper,
                            width: containerRef.current?.getBoundingClientRect()
                                .width,
                        }}
                        {...popAttributes.popper}
                    >
                        <div ref={innerRef}>
                            <DropdownContainer>
                                <DropdownList>
                                    {options.map((option) => (
                                        <DropdownItem
                                            key={option.id}
                                            className={styles['root__option']}
                                            variant="list-item"
                                            disabled={option.disabled}
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                setSelected(option);
                                                setValue(option.code);

                                                collapse();
                                                focusInput();

                                                onChange({
                                                    countryCode:
                                                        option.id !== 'other'
                                                            ? option.id
                                                            : null,
                                                    phoneNumber: option.code,
                                                    isValid: true,
                                                });
                                            }}
                                        >
                                            {option.icon}
                                            <span>{option.label}</span>
                                            {option.code}
                                        </DropdownItem>
                                    ))}
                                </DropdownList>
                            </DropdownContainer>
                        </div>
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
};
