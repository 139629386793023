import styles from './loading.module.scss';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

const Loading = ({ sx, className, ...props }: BoxProps) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            ...sx,
        }}
        className={className}
        {...props}
    >
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle className={styles.afw} cx="16" cy="16" r="16" />

            <mask
                id="a"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="5"
                y="4"
                width="19"
                height="24"
            >
                <path
                    d="M5.88867 4.35181H23.4887V27.6408H5.88867V4.35181Z"
                    fill="#fff"
                />
            </mask>

            <g mask="url(#a)">
                <path
                    className={styles.abw}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.88918 15.9343c.0243-5.768 4.01255-10.45438 9.63732-11.44513 2.4587-.43306 4.6612.20672 6.8295 1.23418.5662.26835.5835.54041.1043.90245-.0525.03969-.3107.25329-.362.29486-.8751.70945-1.1991 1.08381-2.5017.61033-3.6239-1.31708-7.6398.27244-9.71226 3.57391-2.16213 3.4443-1.80049 8.0312.86586 10.9821 2.6535 2.9366 6.7561 3.7466 10.1849 1.9218.7792-.4147 1.0727-.2871 1.7987.2953.2724.2185.3343.3274.4871.4994.3727.4195.3816.7243-.1342 1.0685-4.4558 2.9738-10.7026 2.1174-14.23998-1.9643-1.98299-2.2882-2.9839-4.9378-2.95754-7.9734Z"
                />
            </g>

            <path
                className={styles.abw}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.359 8.92273c-.7073.64522-1.31 1.16187-1.8707 1.72047-.2842.283-.5834.2304-.9166.2127-3.6941-.1954-6.4283 3.2545-5.1849 6.6971.3483.9647.2728 1.6864-.1596 2.5023-.2122.4003-.3808.8234-.5776 1.2547-2.49945-2.2711-2.8577-6.6744-.8404-9.7427 2.0422-3.10614 5.9132-4.25653 9.5498-2.64457Zm.679 13.71567c-3.0996 1.8674-6.9962 1.4377-9.2704-1.0008.8234-.3161 1.6314-.5956 2.4098-.9383.3806-.1676.6441.0143.9435.133 1.0997.436 2.2266.5052 3.3448.1315.479-.16.7935-.1231 1.1299.2514.4379.4876.9338.9266 1.4424 1.4232Z"
            />
        </svg>
    </Box>
);

export default Loading;
