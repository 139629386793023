import { Box, Button, Stack, Typography } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { Download } from '@mui/icons-material';
import { prettySize } from '../../../settings/KnowledgeBase/SourceForm/pretty-bytes';

import { MimeTypeIcon } from '../../../../icons/common/files/MimeTypeIcon';
import { useTrack } from '../../../../contexts/analytics';

type Props = {
    attachment: Attachment;
};

export function AttachmentPreview({ attachment }: Props) {
    const track = useTrack();

    if (attachment.type.includes('image/')) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                }}
            >
                <Box
                    sx={{ maxWidth: '75vw', maxHeight: '75vh', mb: 4 }}
                    component="img"
                    src={attachment.url}
                    alt={attachment.name}
                />

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack>
                        <Typography variant="h2">{attachment.name}</Typography>
                        <Typography variant="body4">
                            {prettySize(attachment.size)}
                        </Typography>
                    </Stack>
                    <Button
                        onClick={() =>
                            track('attachment_downloading', {
                                attachmentType: attachment.type,
                            })
                        }
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 1 }}
                        startIcon={<Download />}
                        download
                    >
                        Download
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    background: 'white',
                    px: 4,
                    py: 2,
                    borderRadius: 2,
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                }}
            >
                <Stack>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <MimeTypeIcon type={attachment.type} />
                        <Typography
                            sx={{
                                maxWidth: '50vh',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                            variant="h2"
                        >
                            {attachment.name}
                        </Typography>
                    </Box>
                    <Typography variant="body2">
                        {prettySize(attachment.size)}
                    </Typography>
                </Stack>
                <div>
                    <Button
                        href={attachment.url}
                        onClick={() =>
                            track('attachment_downloading', {
                                attachmentType: attachment.type,
                            })
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 1 }}
                        startIcon={<Download />}
                        download
                    >
                        Download
                    </Button>
                </div>
            </Box>
        </>
    );
}
