import { useState, useMemo, useCallback, useRef } from 'preact/hooks';
import cc from 'classcat';
import styles from './AssignToAction.module.scss';
import { ActionButton } from '../ActionButton';
import Droplist from '../../../elements/Droplist';
import { DroplistDivider } from '../../../elements/Droplist/DroplistDivider';
import { DroplistHeader } from '../../../elements/Droplist/DroplistHeader';
import { DroplistItem } from '../../../elements/Droplist/DroplistItem';
import { DroplistItems } from '../../../elements/Droplist/DroplistItems';
import { DroplistSearch } from '../../../elements/Droplist/DroplistSearch';
import { AvatarWithUsername } from '../../Avatar/AvatarWithUsername';
import Icon from '../../../icons/Icon';
import { UUID } from '../../../types/uuid';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { Conversation } from '../../../api/types';
import { userName } from '../../../helpers/formatting';
import { assingUsers } from '../../../queries/conversations';

export interface AssignToActionProps {
    conversation: Conversation;
}

export const AssignToAction = (props: AssignToActionProps) => {
    const [active, setActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const currentUser = useMeQueryData();
    const { data: teammates = [] } = useTeammates();
    const { mutate } = assingUsers();

    const actionButtonRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([actionButtonRef, popupRef], () => setActive(false));

    const team = currentUser?.activeTeam;

    const filteredUsers = useMemo(
        () =>
            teammates.filter((user) =>
                userName(user, team?.countryCode)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            ),
        [teammates, searchTerm],
    );

    const selected = useMemo(
        () => props.conversation.assignee?.map(({ id }) => id) || [],
        [props.conversation],
    );

    const assign = useCallback(
        (id: UUID) => {
            const newSelected = selected.concat(id);
            mutate({
                conversationId: props.conversation.id,
                userIds: newSelected,
            });
        },
        [selected],
    );

    const unassign = useCallback(
        (id: UUID) => {
            const newSelected = selected.filter((item) => item !== id);
            mutate({
                conversationId: props.conversation.id,
                userIds: newSelected,
            });
        },
        [selected],
    );

    const assignedUsers = useMemo(
        () => filteredUsers.filter(({ id }) => selected.includes(id)),
        [filteredUsers, selected],
    );

    const freeUsers = useMemo(
        () => filteredUsers.filter(({ id }) => !selected.includes(id)),
        [filteredUsers, selected],
    );

    return (
        <div className={styles['root']}>
            <ActionButton
                ref={actionButtonRef}
                iconName="assign-member"
                tooltipText="Assign a team member"
                onClick={() => setActive(!active)}
            />

            {active && (
                <div className={styles['root__popup']} ref={popupRef}>
                    <Droplist className={styles['root__popup-droplist']}>
                        <DroplistHeader title="Assign a team member" />
                        <DroplistSearch
                            value={searchTerm}
                            onChange={setSearchTerm}
                        />
                        <DroplistItems>
                            {assignedUsers.map((user) => (
                                <DroplistItem key={user.id}>
                                    <div className={styles['root__item']}>
                                        <div
                                            className={
                                                styles['root__check-icon']
                                            }
                                        >
                                            <Icon
                                                name="check-filled"
                                                size="14px"
                                            />
                                        </div>

                                        <AvatarWithUsername
                                            size="small"
                                            imageUrl={user.avatar?.url}
                                            username={userName(
                                                user,
                                                team?.countryCode,
                                            )}
                                        />
                                        <button
                                            className={
                                                styles['root__unassign-btn']
                                            }
                                            onClick={() => unassign(user.id)}
                                        >
                                            <Icon name="close" />
                                        </button>
                                    </div>
                                </DroplistItem>
                            ))}
                            {assignedUsers.length > 0 &&
                                freeUsers.length > 0 && <DroplistDivider />}
                            {freeUsers.map((user) => (
                                <DroplistItem key={user.id}>
                                    <div
                                        className={cc([
                                            styles['root__item'],
                                            styles['root__item_clickable'],
                                        ])}
                                        onClick={() => assign(user.id)}
                                    >
                                        <AvatarWithUsername
                                            size="small"
                                            imageUrl={user.avatar?.url}
                                            username={userName(
                                                user,
                                                team?.countryCode,
                                            )}
                                        />
                                    </div>
                                </DroplistItem>
                            ))}
                        </DroplistItems>
                    </Droplist>
                </div>
            )}
        </div>
    );
};
