import { Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import cc from 'classcat';
// styles
import styles from './FilterNumberValue.module.scss';
// components
import {
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '../../../../elements/Dropdown';
import Input from '../../../../elements/Inputs/Input';
import Radio from '../../../../elements/Radio';
// types
import {
    NumberTypeValue,
    UserPropertyFilterModel,
} from '../../../UserInfoProperty/types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const LIST = [
    { constraintType: NumberTypeValue.Is, label: 'Is' },
    { constraintType: NumberTypeValue.MoreThen, label: 'More Then' },
    { constraintType: NumberTypeValue.LessThen, label: 'Less Then' },
];

interface FilterNumberValueProps {
    userPropertyFilter: UserPropertyFilterModel<number>;
    changeHandler: (value: UserPropertyFilterModel) => void;
    closeHandler: () => void;
}

export const FilterNumberValue = ({
    userPropertyFilter,
    changeHandler,
    closeHandler,
}: FilterNumberValueProps) => {
    const { value, constraint } = userPropertyFilter;

    const [selectedValue, setSelectedValue] = useState(value);
    const [selectedType, setSelectedType] = useState(
        constraint as NumberTypeValue,
    );
    const dropdownContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedType && (selectedValue || selectedValue == 0)) {
            changeHandler({
                ...userPropertyFilter,
                constraint: selectedType,
                value: selectedValue,
            });
        }
    }, [selectedType, selectedValue]);

    useOnClickOutside(dropdownContainerRef, closeHandler);

    return (
        <DropdownContainer
            className={styles['filter']}
            ref={dropdownContainerRef}
        >
            <div className={styles['filter__top-panel']}>Relative:</div>

            <DropdownList className={styles['filter__list']}>
                {LIST.map(({ constraintType, label }, i) => (
                    <Fragment key={constraintType}>
                        <DropdownItem
                            variant="list-item"
                            type="regular"
                            className={cc([
                                {
                                    [styles['filter__item_last']]:
                                        i === LIST.length - 1 &&
                                        constraintType !== selectedType,
                                },
                            ])}
                            onClick={() => {
                                setSelectedType(constraintType);
                                setSelectedValue(undefined);
                            }}
                        >
                            <Radio checked={constraintType === selectedType} />
                            <span className={styles['filter__item-name']}>
                                {label}
                            </span>
                            <div></div>
                        </DropdownItem>
                        {constraintType === selectedType && (
                            <li
                                className={cc([
                                    {
                                        [styles['filter__item_last']]:
                                            i === LIST.length - 1,
                                    },
                                ])}
                            >
                                <Input
                                    wrapperClassName={
                                        styles['filter__input-wrapper']
                                    }
                                    className={styles['filter__input']}
                                    type="number"
                                    value={selectedValue?.toString()}
                                    onChange={(val) =>
                                        setSelectedValue(Number.parseInt(val))
                                    }
                                />
                            </li>
                        )}
                    </Fragment>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};
