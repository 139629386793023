import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'preact/hooks';
import styles from './PropertyConfigurator.module.scss';
import Input from '../../../elements/Inputs/Input';
import useOnKeyDown from '../../../hooks/useOnKeyDown';
import Droplist from '../../../elements/Droplist';
import { typeIconMap } from '../typeIconMap';
import { ContactPropertyType } from '../../../api/types';
import {
    Box,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';

interface PropertyConfiguratorProps {
    type: ContactPropertyType;
    name?: string;
    onNameChange: (name: string) => void;
    onRemove: () => void;
}

export const PropertyConfigurator = (props: PropertyConfiguratorProps) => {
    const [newName, setNewName] = useState(props.name || '');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleEnterKey = useCallback(() => {
        if (newName.length > 0) {
            props.onNameChange(newName);
        }
    }, [newName]);

    useOnKeyDown(inputRef, 'Enter', handleEnterKey);

    const propertyInfo = useMemo(
        () => typeIconMap.find((item) => item.type === props.type),
        [props.type],
    );

    return (
        <Droplist className={styles['root']}>
            <div className={styles['root__info']}>
                <ListItem>
                    <ListItemIcon>{propertyInfo?.icon}</ListItemIcon>
                    <ListItemText>{propertyInfo?.name}</ListItemText>
                </ListItem>
                <Box
                    sx={{
                        width: 1,
                        display: 'flex',
                        px: 2,
                        pb: 2,
                        flexDirection: 'column',
                    }}
                >
                    <Input
                        className={styles['root__input']}
                        ref={inputRef}
                        value={newName}
                        size="small"
                        fullwidth
                        onChange={setNewName}
                    />
                </Box>
            </div>
            <Divider />
            <MenuItem
                sx={{ color: 'error.main' }}
                onClick={props.onRemove}
                color="error"
            >
                <ListItemIcon>
                    <DeleteIcon sx={{ color: 'error.main' }} />
                </ListItemIcon>
                <ListItemText>Remove</ListItemText>
            </MenuItem>
        </Droplist>
    );
};
