import { SxProps, Theme } from '@mui/material';

export const popup: SxProps<Theme> = {
    display: 'flex',
    position: 'absolute',
    top: -20,
    right: 50,
    p: 1,

    boxShadow: ({ shadows }) => shadows[1],
    borderRadius: 1.5,
    bgcolor: 'white',
    border: '1px solid',
    borderColor: 'divider',
};
