import { ComponentChildren } from 'preact';
import cc from 'classcat';
import styles from './Badge.module.scss';

const DEFAULT_COLOR = '#EFA535';

interface BadgeProps {
    className?: string;
    color?: string;
    children: ComponentChildren;
}

/** @deprecated use mui badged instead*/
export const Badge = (props: BadgeProps) => (
    <span
        style={{
            color: props.color || DEFAULT_COLOR,
            borderColor: props.color || DEFAULT_COLOR,
        }}
        className={cc([styles['badge'], props.className])}
    >
        {props.children}
    </span>
);
