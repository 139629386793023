import { useMemo } from 'preact/hooks';
import { getPrettyTargetTime } from '../../../helpers/formatting';
import { Conversation } from '../../../api/types';
import { useMessageStoppedBy } from '../use-message-stopped-by';
import { memo } from 'preact/compat';
import { checkIsTooLate } from './check-is-until-review';

type Props = {
    conversation: Pick<Conversation, 'lastMessageBody' | 'assistantTimestamp'>;
};

type TimeHelpers = {
    isTooLate: boolean;
    prettyDif: string;
};

export const AssistantReplyStatus = memo(({ conversation }: Props) => {
    const phrase = conversation.lastMessageBody
        ? useMessageStoppedBy(conversation.lastMessageBody)
        : '';

    const { prettyDif, isTooLate } = useMemo<TimeHelpers>(() => {
        if (conversation.assistantTimestamp) {
            const isTooLate = checkIsTooLate(conversation.assistantTimestamp);

            return {
                prettyDif: getPrettyTargetTime(
                    new Date(conversation.assistantTimestamp),
                ),
                isTooLate,
            };
        }
        return { prettyDif: '', isTooLate: false };
    }, [conversation.assistantTimestamp]);

    if (phrase) {
        return (
            <span>Assistant ignores messages with &quot;{phrase}&quot;</span>
        );
    }

    if (isTooLate) {
        return <span>Assistant waiting for review</span>;
    }

    return <span>Assistant will reply {prettyDif}</span>;
});
