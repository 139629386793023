import cc from 'classcat';
import { useEffect, useMemo } from 'preact/hooks';
import cloneDeep from 'lodash/fp/cloneDeep';
import styles from './Workflows.module.scss';
import WorkflowAside from '../../components/workflows/WorkflowAside';
import WorkflowHeader from '../../components/workflows/WorkflowHeader';
import WorkflowPlaceholder from '../../components/workflows/WorkflowPlaceholder';
import WorkflowPlot from '../../components/workflows/WorkflowPlot';
import { useWorkflowContext } from '../../components/workflows/WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../components/workflows/WorkflowProvider/reducer';
import WorkflowsList from '../../components/workflows/WorkflowsList';
import { useWorkflowsQuery } from '../../queries/workflows';
import { createNewWorkflow, findNode } from '../../components/workflows/utils';
import WorkflowStepConfigurator from '../../components/workflows/WorkflowStepConfigurator';
import Loading from '../../components/Loading/Loading';
import { WorkflowAsideSteps } from '../../components/workflows/WorkflowAside/WorkflowAsideSteps';
import { WorkflowAsideTriggers } from '../../components/workflows/WorkflowAside/WorkflowAsideTriggers';

interface WorkflowsProps {
    workflowId?: string;
}

export function Workflows({ workflowId }: WorkflowsProps) {
    const {
        state: { workflow, selectedStepId },
        dispatch,
    } = useWorkflowContext();
    const { workflows, isPending } = useWorkflowsQuery();

    useEffect(() => {
        if (workflowId === 'new') {
            dispatch({
                type: WorkflowReducerActionTypes.SET_WORKFLOW,
                payload: createNewWorkflow(),
            });
            return;
        }

        if (workflowId) {
            const selectedWorkflow = workflows.find(
                (workflow) => workflow.id === Number.parseInt(workflowId, 10),
            );

            if (selectedWorkflow) {
                dispatch({
                    type: WorkflowReducerActionTypes.SET_WORKFLOW,
                    payload: cloneDeep(selectedWorkflow),
                });
            }
        } else {
            dispatch({
                type: WorkflowReducerActionTypes.SET_WORKFLOW,
                payload: null,
            });
        }
    }, [workflowId, isPending]);

    const selectedStep = useMemo(() => {
        if (workflow && selectedStepId && selectedStepId !== 'TRIGGER') {
            return findNode(selectedStepId, workflow.spec.steps);
        }
        return null;
    }, [selectedStepId]);

    return (
        <div
            className={cc([
                styles['workflows'],
                {
                    [styles['workflows_with-aside']]: workflowId,
                },
            ])}
        >
            <section className={cc([styles['workflows__main']])}>
                <WorkflowHeader />
                {!isPending && !workflowId && workflows.length === 0 && (
                    <WorkflowPlaceholder />
                )}
                {!isPending && !workflowId && workflows.length > 0 && (
                    <WorkflowsList />
                )}
                {workflow && <WorkflowPlot />}
                {isPending && <Loading />}
            </section>
            {workflow && (
                <aside className={cc([styles['workflows__aside']])}>
                    {selectedStepId === 'TRIGGER' ? (
                        <WorkflowAside title="Trigger" showCloseButton>
                            <WorkflowAsideTriggers />
                        </WorkflowAside>
                    ) : selectedStep ? (
                        <WorkflowStepConfigurator step={selectedStep} />
                    ) : (
                        <WorkflowAside title="Workflow Items">
                            <WorkflowAsideSteps />
                        </WorkflowAside>
                    )}
                </aside>
            )}
        </div>
    );
}
