import { VNode } from 'preact';
import FaceGlassesIcon from '../../assets/icons/payment-flow/face-glasses.svg?react';
import FaceBabyIcon from '../../assets/icons/payment-flow/face-baby.svg?react';

export type PlanPeriod = 'monthly' | 'yearly';
export type PlanType = 'unlimited' | 'starter';
export const FreeTitle = 'Free';

export type PlanItem = {
    priceId: string;
    title: string;
    titleIcon: VNode;
    subtitle: string;
    price: number;
    features: string[];
    period: PlanPeriod;
    type: PlanType;
    isPopular?: boolean;
};

export const planList: PlanItem[] = [
    {
        priceId: import.meta.env.VITE_STRIPE_UNLIMITED_MONTHLY_PRICE_ID,
        title: 'Unlimited',
        titleIcon: <FaceGlassesIcon />,
        subtitle: 'for enterprises & large organizations',
        features: [
            'unlimited SMS',
            'Multiple numbers',
            'Bring your own number',
        ],
        price: 25,
        period: 'monthly',
        type: 'unlimited',
        isPopular: true,
    },
    {
        priceId: import.meta.env.VITE_STRIPE_STARTER_MONTHLY_PRICE_ID,
        title: 'Starter',
        titleIcon: <FaceBabyIcon />,
        subtitle: 'for individuals & small teams',
        features: [
            '50 SMS / month / user',
            '1 phone number / user',
            'bring your own number',
        ],
        price: 15,
        period: 'monthly',
        type: 'starter',
    },
    {
        priceId: import.meta.env.VITE_STRIPE_UNLIMITED_YEARLY_PRICE_ID,
        title: 'Unlimited',
        titleIcon: <FaceGlassesIcon />,
        subtitle: 'for enterprises & large organizations',
        features: [
            'unlimited SMS',
            'Multiple numbers',
            'Bring your own number',
        ],
        price: 20,
        period: 'yearly',
        type: 'unlimited',
        isPopular: true,
    },
    {
        priceId: import.meta.env.VITE_STRIPE_STARTER_YEARLY_PRICE_ID,
        title: 'Starter',
        titleIcon: <FaceBabyIcon />,
        subtitle: 'for individuals & small teams',
        features: [
            '50 SMS / month / user',
            '1 phone number / user',
            'bring your own number',
        ],
        price: 10,
        period: 'yearly',
        type: 'starter',
    },
];

export const plansMap: Record<string, PlanItem> = planList.reduce(
    (acc, plan) => ({ ...acc, [plan.priceId]: plan }),
    {},
);

export const getPlansForPeriodByType = (
    period: PlanPeriod,
): Record<PlanType, PlanItem> =>
    planList
        .filter((p) => p.period === period)
        .reduce(
            (acc, plan) => ({ ...acc, [plan.type]: plan }),
            {} as Record<PlanType, PlanItem>,
        );
