import { useCallback, useMemo, useState } from 'preact/hooks';
import { getMessageDraft, saveMessageDraft } from './utils';
import { Attachment, Conversation, PublicFile } from '../../api/types';
import { useSocket } from '../../websocket';
import { useInboxContext } from '../../contexts/InboxContext';
import { throttle } from 'lodash';
import {
    EditorData,
    MessageInput,
    MessageInputProps,
} from '../MessageInput/MessageInput';
import { withReact } from 'slate-react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { withTemplates } from '../MessageInput/components/Template/withTemplates';
import { Box } from '@mui/material';
import { withParagraphs } from '../MessageInput/components/Paragraph/withParagraph';
import { useCampaignV3Enabled } from '../../helpers/use-campaign-v3-enabled';

export type MessageFormData = {
    message: string;
    sendAt?: string;
    // @deprecated - use attachments
    files?: PublicFile[];
    attachments?: Attachment[];
};

export type MessageFormProps = {
    templatesAvailable?: boolean;
    initial?: MessageFormData | null;
    conversation?: Conversation;
    placeholder?: string;
    noAutofocus?: boolean;
    disabled?: boolean;
    onSend: (data: MessageFormData) => Promise<unknown>;
    onCancel?: () => void;
};

export function MessageForm({
    initial,
    conversation,
    placeholder,
    disabled,
    onSend,
    onCancel,
    templatesAvailable,
}: MessageFormProps) {
    const isCampaignV3 = useCampaignV3Enabled();
    const [editor] = useState(
        withTemplates(withParagraphs(withReact(withHistory(createEditor())))),
    );

    const { actions } = useInboxContext();
    const initialValue = useMemo<string | Descendant[]>(() => {
        if (initial?.message) {
            return initial.message;
        }

        if (conversation?.id) {
            return getMessageDraft(conversation.id, actions);
        }

        return '';
    }, [actions, conversation?.id, initial?.message]);

    const socket = useSocket();

    const saveDraft = useCallback(
        (message?: Descendant[]) => {
            if (conversation?.id && !initial?.message) {
                saveMessageDraft({
                    conversationId: conversation.id,
                    message,
                    actions,
                });
            }
        },
        [conversation?.id, initial],
    );

    const emitTyping = useCallback(
        throttle(
            () => {
                if (conversation?.id) {
                    socket.getSocket()?.emit('conversation/typing', {
                        id: conversation.id,
                    });
                }
            },
            5000,
            { leading: true, trailing: false },
        ),
        [conversation?.id],
    );

    const toolbar = useMemo(() => {
        const toolbarItems: MessageInputProps['toolbar'] = [
            'emojiPicker',
            'signature',
            'snippets',
            'schedule',
        ];

        if (templatesAvailable) {
            toolbarItems.push('templates');
        }

        return toolbarItems;
    }, [templatesAvailable]);

    const handleSend = useCallback(
        async (data: EditorData) => {
            if (data.sendAt) {
                // await for server response for scheduled messages
                await onSend(data);
            } else {
                onSend(data);
            }
            saveDraft();
        },
        [saveDraft, onSend],
    );

    return (
        <Box mb={6} ml={6} mr={6} position="relative">
            <MessageInput
                autoFocus
                editor={editor}
                placeholder={placeholder || 'Write a message...'}
                initialValue={initialValue}
                initialFiles={initial?.files}
                initialAttachments={initial?.attachments ?? []}
                sendAt={initial?.sendAt}
                onChange={emitTyping}
                onBlur={saveDraft}
                onSend={handleSend}
                onCancel={onCancel}
                disabled={disabled}
                withAiAssistant
                conversationId={conversation?.id}
                withFileUploader
                withSendButton
                withCancelButton={!!initial}
                toolbar={toolbar}
                isCampaignV3={isCampaignV3}
            />
        </Box>
    );
}
