import styles from './CreateEditCohort.module.scss';
import { useState } from 'preact/hooks';
import { ModalHeader } from './ModalHeader';
import { CreateCohortStep } from './CreateCohortStep';

type CreateEditCohortSteps = 'create' | 'edit';
type CreateEditCohortProps = {
    step: CreateEditCohortSteps;
};

export const CreateEditCohort = (props: CreateEditCohortProps) => {
    /*todo: review state usage*/
    const [stage] = useState<CreateEditCohortSteps>(props.step);
    return (
        <div className={styles.container}>
            <ModalHeader
                text={stage === 'create' ? 'Create a cohort' : 'Edit a Cohort'}
            />
            {stage === 'create' && <CreateCohortStep />}
        </div>
    );
};
