import { useEffect, useRef, useState } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { usePopper } from 'react-popper';
import cc from 'classcat';
import { Link } from 'preact-router/match';
import styles from './CampaignItem.module.scss';
import actionIcon from '../../assets/icons/icons-20/action.svg';
import CampaignItemActions from './CampaignItemActions';
import {
    useCampaignRemoveQuery,
    useCampaignRenameQuery,
} from '../../queries/campaigns';
import useOnKeyDown from '../../hooks/useOnKeyDown';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { CheckCircle, WarningCircle } from 'phosphor-react';
import Icon from '../../icons/Icon';
import { CampaignMeta, CampaignStatus } from '../../api/campaign.types';

export interface CampaignItemProps {
    campaign: CampaignMeta;
    onClick?: () => void;
}

export const CampaignItem = ({
    campaign: { id, name, status },
    onClick,
}: CampaignItemProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [areActionsVisible, setActionsVisible] = useState(false);
    const [currentName, setCurrentName] = useState<string>(name);
    const [isEditMode, setEditMode] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: removeCampaign } = useCampaignRemoveQuery(id);
    const { mutate: renameCampaign } = useCampaignRenameQuery(id);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    const handleRenameSave = () => {
        if (currentName.length > 0) {
            renameCampaign(currentName);
            setEditMode(false);
        }
    };

    const handleRenameDiscard = () => {
        setCurrentName(name);
        setEditMode(false);
    };

    useOnKeyDown(inputRef, 'Enter', handleRenameSave);
    useOnKeyDown(inputRef, 'Escape', handleRenameDiscard);
    useOnClickOutside(inputRef, handleRenameSave);

    // Set the popper hook
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null,
    );
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
        null,
    );
    const { styles: popperStyles, attributes } = usePopper(
        containerRef,
        popperElement,
        {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [105, 0],
                    },
                },
            ],
        },
    );

    return (
        <Link
            class={`clerk-reset-link ${styles['campaign-item']}`}
            activeClassName={styles['campaign-item_active']}
            path={`/campaigns/${id}/:rest*`}
            href={`/campaigns/${id}`}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={styles['campaign-item__icon_placeholder']}>
                <Icon
                    name="campaign"
                    className={styles['campaign-item__icon']}
                />
            </div>
            <div className={cc([styles['campaign-item__description']])}>
                <p className={cc([styles['campaign-item__name']])} title={name}>
                    {isEditMode ? (
                        <input
                            className={styles['campaign-item__name-editor']}
                            ref={inputRef}
                            value={currentName}
                            onChange={(
                                e: JSX.TargetedEvent<HTMLInputElement>,
                            ) => setCurrentName(e.currentTarget.value)}
                            spellCheck={false}
                        />
                    ) : (
                        name
                    )}
                </p>
            </div>
            <div
                className={styles['campaign-item__extra']}
                ref={setContainerRef}
            >
                {!isHovered && status === CampaignStatus.ERROR && (
                    <WarningCircle size={12} color="#D94A3D" weight="bold" />
                )}
                {!isHovered && status === CampaignStatus.OK && (
                    <CheckCircle size={12} color="#57CF78" weight="bold" />
                )}

                {isHovered && (
                    <button
                        className={styles['campaign-item__actions-btn']}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setActionsVisible(true);
                        }}
                        type="button"
                    >
                        <img
                            className={styles['campaign-item__actions-img']}
                            src={actionIcon}
                        />
                    </button>
                )}
                {areActionsVisible &&
                    createPortal(
                        <div
                            ref={setPopperElement}
                            style={popperStyles.popper}
                            {...attributes.popper}
                        >
                            <CampaignItemActions
                                onRenameClick={() => {
                                    setEditMode(true);
                                    setActionsVisible(false);
                                }}
                                onRemoveClick={() => {
                                    setActionsVisible(false);
                                    removeCampaign();
                                }}
                                onOutsideClick={() => setActionsVisible(false)}
                            />
                        </div>,
                        document.querySelector('#portal')!,
                    )}
            </div>
        </Link>
    );
};
