import { SxProps, Theme } from '@mui/material';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { purple } from '@mui/material/colors';

export const info: SxProps = {
    pl: 4,
    overflow: 'hidden',
    flex: '1 1 auto',
};

export const getBadge = (stopped: boolean = false): SxProps => ({
    '.MuiBadge-badge': {
        bgcolor: 'white',
        p: 0.5,
        color: stopped ? 'custom.primary.red' : 'secondary.main',
        width: 20,
        height: 20,
    },
});

export const getMessageStyle = (
    unread: boolean,
    assistant: boolean,
): SxProps => ({
    flex: '0 0 80%',
    mt: 1,
    display: '-webkit-box',
    lineClamp: '2' /* number of lines to show */,
    boxOrient: 'vertical',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color:
        (assistant && 'secondary.main') ||
        (unread && 'primary.dark') ||
        '#6c6f85',
});

export const container: SxProps = {
    display: 'flex',
    position: 'relative',
    height: 72,
    px: 4,
    py: 2,
};

export const popoverPaper: SxProps = {
    ml: 3,
    // width: 1,
    maxWidth: 310,
    borderRadius: 1.5,
    borderColor: 'custom.gray.input',
    background: 'white',
    pointerEvents: 'auto',
};

export const popover: SxProps = {
    pointerEvents: 'none',
};

export const staticPopoverProps: Partial<PopoverProps> = {
    sx: popover,
    slotProps: {
        paper: {
            sx: popoverPaper,
            elevation: 5,
        },
    },
    anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'center',
        horizontal: 'left',
    },
    disablePortal: true,
};

export const popoverContent: SxProps = {
    display: 'flex',
    alignItems: 'center',
    px: 2,
    py: 3,
    gap: 2,
};

export const popoverMessage: SxProps<Theme> = {
    width: 1,
    position: 'relative',
    alignItems: 'flex-start',
    p: 3,
    bgcolor: 'custom.gray.active',
    display: 'flex',
    flexDirection: 'column',
    transition: (theme) =>
        theme.transitions.create(['backgroundColor'], {
            duration: theme.transitions.duration.standard,
        }),
    gap: 1,
    ':hover, :focus-within': {
        bgcolor: 'primary.hover',
    },
    ':hover .cButtonBaseAdornment': {
        opacity: 1,
    },
    ':focus-within .cButtonBaseAdornment': {
        opacity: 1,
        color: 'custom.gray.super',
    },
    ':focus-within:hover .cButtonBaseAdornment': {
        color: 'info.main',
    },
};

export const popoverBody: SxProps = {
    overflow: 'hidden',
    textAlign: 'left',
    maxHeight: 42,
};

export const popoverAdornment: SxProps<Theme> = {
    position: 'absolute',
    right: 0,
    top: 0,

    opacity: 0,
    transition: (theme) =>
        theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.standard,
        }),

    display: 'flex',
    height: 1,
    width: 64,
    alignItems: 'center',
    justifyContent: 'flex-end',
    pr: 2.5,
    color: 'info.main',
    background:
        'linear-gradient(90deg, rgba(231, 232, 239, 0.00) 0%, #E7E8EF 31.25%)',
};

export const highlight: SxProps = {
    color: 'secondary.dark',
    bgcolor: purple[100],
    display: 'inline-flex',
    px: 0.5,
    borderRadius: 1,
};
export const recipient: SxProps = {
    color: 'primary.dark',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};
export const answerPreview: SxProps = {
    lineClamp: 7,
    boxOrient: 'vertical',
    WebkitLineClamp: 7,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    p: 3,
};
export const actionBar: SxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    p: 3,
    gap: 2,
};
export const square20: SxProps = {
    width: 20,
    height: 20,
};
