import { Column } from '../main/contacts-import/CsvImportWizard/utils';
import client from './http';
import { Integration, IntegrationSettings, TeamsPhoneResponse } from './types';

export const syncIntegration = ({ id }: { id: string }) =>
    client().get(`contacts-sync/sync/${id}`);

export const updateIntegration = ({
    id,
    isPublic,
    settings,
}: {
    id: string;
    isPublic?: boolean;
    settings?: IntegrationSettings;
}) =>
    client()
        .put(`integrations/${id}`, { public: isPublic, settings })
        .then((res) => res.data);

export const disconnectIntegration = ({ id }: { id: string }) =>
    client().delete(`integrations/${id}`);

export type ConnectIntegrationRequest = {
    source: string;
    code: string;
};

export const connectIntegration = ({
    source,
    code,
}: ConnectIntegrationRequest) =>
    client()
        .post(`integrations/setup/${source}`, { code: code })
        .then((res) => res.data);

export const getIntegrationRemoteSchema = ({ id }: { id: string }) =>
    client()
        .get<Column[]>(`integrations/${id}/remote-schema`)
        .then((res) => res.data);

export const getMicrosoftTeamsPhones = () =>
    client()
        .get<TeamsPhoneResponse>('microsoft-teams/phones')
        .then((res) => res.data);

export const listIntegrations = () =>
    client()
        .get<Integration[]>('integrations')
        .then((res) => res.data);
