import { forwardRef } from 'react';
import { useEffect, useRef } from 'preact/hooks';
import { Link } from 'preact-router';

export const AdapterLink = forwardRef(function AdaptLink(props, _ref) {
    const component = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        _ref = component.current?.base;
    }, [component.current?.base]);

    return <Link ref={component} {...props} />;
});
