import React from 'react';
import { Box, Button, Divider, Drawer, Grid, IconButton } from '@mui/material';
import * as styles from './styles';
import { useState } from 'preact/hooks';
import { MenuIcon } from './MenuIcon';
import { FullArrowLeftIcon } from './FullArrowLeftIcon';
import { Navigation } from './Navigation/Navigation';
import { legacyMobileBreakpoint } from '../../theme/breakpoints';
import { ContentHeader } from './ContentHeader';

export function SettingsLayout({
    children,
    title,
    description,
}: {
    children: React.ReactNode;
    title: string;
    description?: React.ReactNode;
}) {
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <Box sx={styles.layout}>
            <Box sx={styles.layoutHeader}>
                <IconButton
                    onClick={() => setMenuOpen(true)}
                    sx={styles.layoutHeaderMenuButton}
                >
                    <MenuIcon />
                </IconButton>
            </Box>

            <Drawer
                variant="permanent"
                open={isMenuOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={(theme) => ({
                    ...styles.sidebar,
                    [theme.breakpoints.down(legacyMobileBreakpoint)]: {
                        display: isMenuOpen ? 'block' : 'none',
                    },
                })}
            >
                <Box sx={styles.header}>
                    <Button
                        sx={styles.homeButton}
                        variant="text"
                        startIcon={<FullArrowLeftIcon size="20px" />}
                        href="/"
                    >
                        Home
                    </Button>
                </Box>
                <Divider />
                <Navigation onClick={() => setMenuOpen(false)} />
            </Drawer>

            <Box sx={styles.container}>
                <ContentHeader title={title} description={description} />

                <Grid container justifyContent="center">
                    <Box sx={styles.layoutContainer}>{children}</Box>
                </Grid>
            </Box>
        </Box>
    );
}
